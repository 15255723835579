import React, {useEffect, useState} from 'react';
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import * as snackbarActions from "../../Redux/Actions/SnackbarActions/SnackbarActions";
import PharmacySnackbar from "../../Utils/Snackbars/SnackbarUtil";
import * as manufacturerActions from "../../Redux/Actions/ManufacturerActions/ManufacturerActions";
import {history} from "../../Helpers/history"
import ReusableDashboardHeader from "../../Containers/Dasboard/ReusableDashboardHeader";
import Card from "@material-ui/core/Card";
import Label from "../../Utils/FormInputs/Label";
import TextField from "../../Utils/FormInputs/TextField";
import axios from "axios";
import config from "../../Helpers/config.json";
import {errorMessages} from "../../Helpers/ErrorMessages";
import Textarea from "../../Utils/FormInputs/Textarea";
import {RightAlignedContainer} from "../../Utils/styledComponents";
import {Container} from "../../Utils/styledComponents"
import {LoadingGif} from "../../Utils/Loader";
import DetailsDialog from "../../Utils/Dialog/DetailsDialog";
import InvoicePrintDialog from "../Invoice/InvoicePrintDialog";


const headData = [{name:'Product'},{name:'Batch#'},{name:'Quantity Sold'},{name:'Quantity Returned', isRequired:true},{name:'Unit Price'},
    {name:'Discount'},{name:'Total'},{name:'Return'}]

const batchHeadData = [{name:'Product'},{name:'Batch#'},{name:'Quantity Sold'},{name:'Quantity Returned'}]


const Return = ({actions, snackbars}) => {
    const [invoice_no, setInvoiceNo] = useState('');
    const [data, setData] = useState({items:{}, loading:'idle'});
    const [rows, setRows] = useState([]);
    const [totals, setTotals] = useState({total_discount: 0, grand_total_price: 0});
    const [state, setState] = useState({customer_name: '', date: '', customer_id: '', invoice_id: ''});
    const [details, setDetails] = useState('');
    const [submitted, setSubmitted] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState('idle');
    const [receipt_no, setReceiptNo] = useState('');
    const [openDialog, setOpenDialog] = useState(false);
    const [openConfirmation, setOpenConfirmation] = useState(false)
    const [returnedItems, setReturnedItems] = useState([])
    const {items,loading} = data



    const handleChangeInvoice = (event) => {
        setInvoiceNo(event.target.value)
    };

    const handleSearchInvoice = () => {
        if (invoice_no) {
            const formData = new FormData();
            formData.append('invoice_no', invoice_no)
            setData({...data, loading: 'loading'})
            axios.post(`${config.epharmUrl}/Creturn/invoice_return_form`, formData).then((res) => {
                const data = res.data;
                const dt = !data ? {} : data;
                setData({...data, loading: 'success',items: dt})
            }).catch(error => {
                errorMessages(error, null, actions)
                setData({...data, loading: 'error'})
            })
        }
    };

    useEffect(() => {
        const {invoice_all_data, customer_name, invoice_id, customer_id, date} = items;
        const invoiceArr = invoice_all_data ?? []
        const arr = invoiceArr.map(item => ({
            ...item, product_quantity: '', total: 0,
            rtn: false, discount: ''
        }))
        setState({
            customer_name: customer_name ?? '',
            invoice_id: invoice_id ?? '',
            customer_id: customer_id ?? '',
            date: date ?? ''
        })
        setRows(arr)
    }, [data])

    const handleChangeDetails = event => {
        setDetails(event.target.value)
    };

    const calculateTotal = (item) => {
        return +(item.product_quantity) * +item.rate;
    };

    const calculateDiscount = (item) => {
        let discount_value = !item.discount ? 0 : item.discount;
        let val = +(discount_value) / 100;
        return calculateTotal(item) * val;
    };

    const calculateTotalAmount = (arr) => {
        let total_discount = arr.reduce((acc, row) => {
            const discount = !row.discount ? 0 : row.discount;
            const product_quantity = !row.product_quantity ? 0 : row.product_quantity;
            const total = product_quantity * row.rate;
            const val = total * discount / 100;
            return acc + val
        }, 0);
        let grand_total_price = arr.reduce((acc, row) => {
            const dt = row.total ? row.total : 0
            return acc + dt;
        }, 0);
        setTotals({total_discount, grand_total_price});
    };

    const calculateFinalTotal = (item) => {
        return calculateTotal(item) - calculateDiscount(item);
    };


    const changeCheckValue = (e, index) => {
        const {checked} = e.target;
        const arr = rows.map((item, idx) => {
            if (idx === index) {
                return {...item, rtn: checked, total: calculateFinalTotal(item)}
            }
            return item
        })
        const itemsReturned = arr.filter((item)=>item.rtn)
        calculateTotalAmount(arr);
        setRows(arr)
        setReturnedItems(itemsReturned)
    };


    const handleChangeQuantity = (event, index) => {
        const {value} = event.target;
        const arr = rows.map((item, idx) => {
            if (idx === index) {
                return {
                    ...item,
                    product_quantity: value,
                    total: calculateFinalTotal({
                        rate: item.rate, product_quantity: value, discount: item.discount
                    })
                }
            }
            return item
        })
        calculateTotalAmount(arr);
        setRows(arr)
    };

    const handleChangeDiscount = (event, index) => {
        const {value} = event.target;
        const arr = rows.map((item, idx) => {
            if (idx === index) {
                return {
                    ...item,
                    discount: value,
                    total: calculateFinalTotal({
                        rate: item.rate,
                        product_quantity: item.product_quantity,
                        discount: value
                    })
                }
            }
            return item
        })
        calculateTotalAmount(arr);
        setRows(arr)
    };

    const handleChangeBatchId = (event, index) => {
        const {value} = event.target;
        const arr = returnedItems.map((item, idx) => {
            if (idx === index) {
                return {
                    ...item,
                    new_batch_id: value,
                }
            }
            return item
        })
        setReturnedItems(arr)
    };

    const handleBlurBatchId= (index) => {
        const arr = returnedItems.map((item, idx) => {
            if (idx === index) {
                return {
                    ...item,
                    isBatch: true,
                }
            }
            return item
        })
        setReturnedItems(arr)
    };

    const handleOpenConfirmation = () => {
        const required = rows.filter(item => item.rtn).every(row => row.product_quantity && row.product_quantity <= row.quantity)
        if(required){
            setOpenConfirmation(true)
        }

    }

    const handleCloseConfirmation = () => {
        setOpenConfirmation(false)
    }

    const handleCloseDialog = () => {
        setOpenDialog(false)
    }

    const handleCancel = () =>{
        history.push('/stockreturnlist')
    }

    const handleOpenReceipt = () =>{
        history.push(`/stockreturndetails/${receipt_no}`)
    }


    const handleSubmitReturn = (event) => {
        event.preventDefault();
        const {invoice_id, customer_id, date} = state
        const {total_discount, grand_total_price} = totals;
        const arr =  returnedItems.map(item=>({
            product_id:item.product_id, sold_qty: +item.quantity, product_quantity: +item.product_quantity,
            product_rate: +item.rate, total_price: +item.total, discount: +item.discount, batch_id:item.batch_id,
            tax:0
        }))
        setSubmitted(true)
        const isBatch = returnedItems?.every(item=>item.new_batch_id && item.batch_id === item.new_batch_id)
        const params = {invoice_id, customer_id, invoice_date:date, payment_type:'',total_discount, details, total_tax:0,
            grand_total_price, products:arr}
        if (isBatch) {
            setIsSubmitted('pending')
            axios.post(`${config.epharmUrl}/Creturn/return_invoice`, params).then(response => {
                const data = response.data;
                const dt = data ?? '';
                setIsSubmitted('resolved')
                actions.snackbarActions.successSnackbar('Product(s) successfully returned')
                setReceiptNo(dt)
                setOpenDialog(true)
            }).catch(err => {
                setIsSubmitted('rejected')
                errorMessages(err, null, actions)
            });
        }
    }




    const handleCloseBar = () => {
        actions.snackbarActions.hideSnackBar()
    };

    const isLoading = loading === 'loading'
    const isSuccess = loading === 'success'
    const isError = loading === 'error'

    const {customer_name} = state;
    const {total_discount, grand_total_price} = totals;
    const disabled = rows?.some(item => item.rtn);
    const isValidBatch = returnedItems?.every(item=>item.new_batch_id && item.batch_id === item.new_batch_id)
    const {message, variant, open} = snackbars;
    return (
        <div className='journals'>
            <ReusableDashboardHeader component='Customer Refund' heading="Refund"
                                     subHeading='Customer Refund' link={history.location.pathname}/>
            <PharmacySnackbar open={open} message={message} variant={variant}
                              handleCloseBar={handleCloseBar}/>
            <InvoicePrintDialog openDialog={openDialog} handleCloseDialog={handleCloseDialog}
                          message="Receipt" text='receipt'>
                    <RightAlignedContainer>
                        <button onClick={handleOpenReceipt} type="button" className="btn pharmacy-info-btn mr-3">Yes</button>
                        <button onClick={handleCancel} type="button" className="btn pharmacy-grey-btn ">No</button>
                    </RightAlignedContainer>
            </InvoicePrintDialog>
            <DetailsDialog openDialog={openConfirmation} handleClose={handleCloseConfirmation} title='Confirm Batch#' maxWidth='md'>
                <p>Please Enter the batch id(s) to continue</p>
                <table className="table table-bordered  custom-bordered-table">
                    <thead>
                    <tr>
                        {batchHeadData.map((item, index)=>(
                            <th>{item.name} {item.isRequired ? <span className='invalid-text'>*</span> : null}</th>
                        ))}
                    </tr>
                    </thead>
                    <tbody>
                    {returnedItems.map((row, index) => (
                        <tr key={index}>
                            <td>
                                {row.product_name}
                            </td>
                            <td>
                                <TextField submitted={submitted} type="text" value={row.new_batch_id} min="0"
                                           onChange={(e) => handleChangeBatchId(e,index)} onBlur={()=>handleBlurBatchId(index)}/>
                                {row.isBatch && row.batch_id !== row.new_batch_id ?
                                    <div className="invalid-text">Invalid batch id</div> : null}
                            </td>
                            <td>
                                {row.quantity}
                            </td>
                            <td>
                                {row.product_quantity}
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </table>
                <RightAlignedContainer>
                    <button type='button' onClick={handleSubmitReturn} disabled={!isValidBatch} className="btn btn-sm pharmacy-info-btn mr-3">Continue</button>
                    <button type="button" onClick={handleCloseConfirmation} className="btn btn-sm pharmacy-gray-btn">Cancel</button>
                </RightAlignedContainer>
            </DetailsDialog>
            <Container>
                <Card className="p-3">
                    <form autoComplete="off">
                        <Form.Group as={Row}>
                            <Label column sm={3} htmlFor="invoice_no" name="Invoice No." type/>
                            <Col sm={6}>
                                <TextField submitted={submitted} type="text" id="invoice_no" name="invoice_no" value={invoice_no}
                                           onChange={handleChangeInvoice} onBlur={handleSearchInvoice}/>
                                {submitted && !invoice_no ?
                                    <div className='invalid-text'>Invoice no. is required</div> : null}
                            </Col>
                        </Form.Group>
                        {isLoading ? <div className='text-center mt-3'><LoadingGif/></div>: null}
                        {isSuccess ? rows.length > 0 ?  <>
                            <Form.Group as={Row}>
                                <Label column sm={3} name="Customer Name"/>
                                <Col sm={6}>
                                    <TextField type="text" value={customer_name} readOnly/>
                                </Col>
                            </Form.Group>

                            <table className="table table-bordered  custom-bordered-table">
                                <thead>
                                <tr>
                                    {headData.map((item, index)=>(
                                        <th>{item.name} {item.isRequired ? <span className='invalid-text'>*</span> : null}</th>
                                    ))}
                                </tr>
                                </thead>
                                <tbody>
                                {rows.map((row, index) => (
                                    <tr key={index}>
                                        <td>
                                            {row.product_name}
                                        </td>
                                        <td>
                                            {row.batch_id}
                                        </td>
                                        <td>
                                            {row.quantity}
                                        </td>
                                        <td>
                                            <TextField submitted={submitted} type="number" value={row.product_quantity} min="0"
                                                       onChange={(e) => handleChangeQuantity(e, index)}/>
                                            {submitted && !row.product_quantity ?
                                                <div className="invalid-text">Quantity is required</div> :
                                                (row.product_quantity && row.product_quantity > row.quantity) ?
                                                    <div className="invalid-text">You can only return up
                                                        to {row.quantity}</div> : null}
                                        </td>
                                        <td>
                                            {row.rate}
                                        </td>
                                        <td>
                                            <TextField type="number" value={row.discount}
                                                       name="discount" min="0"
                                                       onChange={(e) => (handleChangeDiscount(e, index))}/>

                                        </td>
                                        <td>
                                            {row.total}
                                        </td>
                                        <td>
                                            <Form.Check type="checkbox"
                                                        vlaue={row.rtn}
                                                        name="rtn"
                                                        onChange={(e) => changeCheckValue(e, index)}
                                                        style={{transform: "scale(1)"}}
                                            />
                                        </td>
                                    </tr>
                                ))}
                                <tr>
                                    <td colSpan={4} rowSpan={2}>
                                        <Textarea name="details" value={details}
                                                  onChange={handleChangeDetails}/>
                                    </td>
                                    <td className="text-right">
                                        Total Deduction:
                                    </td>
                                    <td>
                                        {total_discount}
                                    </td>
                                    <td/>
                                </tr>
                                <tr>
                                    <td className="text-right">
                                        Total Return
                                    </td>
                                    <td>
                                        {grand_total_price}
                                    </td>
                                    <td/>
                                </tr>
                                </tbody>
                            </table>
                            <RightAlignedContainer>
                                <button type='button' disabled={!disabled} onClick={handleOpenConfirmation}
                                        className="btn pharmacy-btn btn-sm">Submit</button>
                            </RightAlignedContainer>
                        </>:<div className="text-center mt-3">No products purchased under invoice ${invoice_no}</div>:null}
                        {isError ? <div className="text-center mt-3">The server did not return a valid response</div>:null}

                    </form>
                </Card>
            </Container>


        </div>
    );

}

function mapStateToProps(state) {
    return {
        snackbars: state.snackbars,
        manufacturers: state.manufacturers
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            snackbarActions: bindActionCreators(snackbarActions, dispatch),
            manufacturerActions: bindActionCreators(manufacturerActions, dispatch)
        }

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Return);