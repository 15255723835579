import React from 'react';
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import * as snackbarActions from "../../Redux/Actions/SnackbarActions/SnackbarActions";
import PharmacySnackbar from "../../Utils/Snackbars/SnackbarUtil";
import ReusableDashboardHeader from "../../Containers/Dasboard/ReusableDashboardHeader";
import {history} from "../../Helpers/history";
import Card from '@material-ui/core/Card'
import {MedicineContainer, RightAlignedContainer} from "../../Utils/styledComponents";
import {ManufacturerForm} from "./ManufacturerForm";
import { useAddManufacturer } from './CustomHooks/useAddManufacturer';
import {ReusableProductsList} from "../../Utils/List/ReusableProductsList";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import {StyledRemoveIcon} from "../Stock/StockAdjustment";
import {BlockNavigationComponent} from "../../Utils/ReusableComponents/BlockNavigationComponent";


const AddManufacturer = ({actions, snackbars}) => {
    const {manufacturerProps,  handleCloseBar, addedSuppliers,  cancelAdd, addSupplier, handleRemoveSupplier,
        handleSubmitMultiple, isShowForm, addAnotherSupplier, isBlocking} = useAddManufacturer(actions)
    const {isPending} = manufacturerProps
    const {open, message, variant} = snackbars;
    return (
        <div className='journals'>
            <ReusableDashboardHeader component="Add Supplier" heading="Supplier"
                                     subHeading="Add Supplier" link={history.location.pathname}/>
            <div className="general-ledger-header">
                <h6 className='mt-2 mb-0'>Add Supplier</h6>
            </div>
            <BlockNavigationComponent
                when={isBlocking}
                shouldBlockNavigation={()=>{
                    return isBlocking;
                }}
                navigate={path=>history.push(path)}
            />
            <PharmacySnackbar open={open} message={message} variant={variant}
                              handleCloseBar={handleCloseBar}/>
            <MedicineContainer>
                {isShowForm || addedSuppliers.length === 0 ? <Card className="p-3 general-card">
                    <ManufacturerForm {...manufacturerProps}>
                        <div className='text-right'>
                            <button type="button" data-testid="submit_supplier" onClick={addSupplier} className="btn pharmacy-info-btn small-btn btn-sm px-3 mr-3">Add Supplier</button>
                            {addedSuppliers.length > 0 ? <button type="button" onClick={cancelAdd} className="btn pharmacy-btn-dismiss small-btn btn-sm px-3">Cancel</button>:null}
                        </div>
                    </ManufacturerForm>
                </Card>: null}
                <div className='my-3'>
                    {addedSuppliers.map((item, index)=>{
                        const primaryText = (
                            <span><strong>{item.manufacturer_name}</strong></span>
                        )
                        const secondaryText = (
                            <>
                                {item.mobile ? <span style={{display:'inline-block', marginRight:'12px'}}>Mobile: <strong>{item.mobile ? item.mobile : ''}</strong></span>:null}
                                {item.address ? <span style={{display:'inline-block', marginRight:'12px'}}>Address: <strong>{item.address ? item.address : ''}</strong></span>:null}
                                {item.details ? <span style={{display:'inline-block', marginRight:'12px'}}>Details: <strong>{item.details ? item.details : '' }</strong></span>: null}
                            </>
                        )
                        return(
                            <ReusableProductsList key={index} {...{primaryText, secondaryText}} button={
                                <>
                                    <Tooltip title='Delete'>
                                        <IconButton onClick={()=>handleRemoveSupplier(index)}>
                                            <StyledRemoveIcon/>
                                        </IconButton>
                                    </Tooltip>
                                </>
                            }/>
                        )
                    })}
                </div>
                {!isShowForm ?  <button type="button"  data-testid="add_another_supplier" onClick={addAnotherSupplier} className="btn pharmacy-info-btn btn-sm small-btn px-3">Add another supplier</button>:null}
                {addedSuppliers.length > 0 ?  <RightAlignedContainer>
                    <button type="submit" data-testid="save_supplier" onClick={handleSubmitMultiple} disabled={isPending} className="btn pharmacy-btn px-3 mt-4">{isPending ? 'Saving...':'Save'}</button>
                </RightAlignedContainer>: null}
            </MedicineContainer>
        </div>
    )

}

function mapStateToProps(state) {
    return {
        snackbars: state.snackbars,
    }

}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            snackbarActions: bindActionCreators(snackbarActions, dispatch)
        }
    }

}

export default connect(mapStateToProps, mapDispatchToProps)(AddManufacturer);