import {useEffect, useState} from "react";
import axios from "axios";
import config from "../../../Helpers/config.json";
import {errorMessages} from "../../../Helpers/ErrorMessages";
import {history} from "../../../Helpers/history";
import {useReusableNewSales} from "./useReusableNewSales";
import {validateCurrentDate} from "../../../Utils/ConvertDate";

export const useNewReceipt = (actions,invoice_number='') => {
    const user = JSON.parse(sessionStorage.getItem('user'));
    const singleUser = !user ? {} : user;
    const user_id = !singleUser.user_id ? 1 : singleUser.user_id;
    const [submitted, setSubmitted] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);
    const [invoice_id, setInvoiceId] = useState('');
    const [isSubmitted, setIsSubmitted] = useState('idle');
    const [payTypes, setPayTypes] = useState([])
    const [receiptData, setReceiptData] = useState({products:[], customer:{}, loading:'idle'});
    const {stateFunctions, stateVariables} = useReusableNewSales(actions,invoice_number, true)
    const {
        customer, rows,  customerDetails,
        totals, initialState, state, paytype
    } = stateVariables;
    const {
        setRows,setCustomer,setTotals,setCustomerDetails, setIsBlocking
    } = stateFunctions;
    const {customer_id} =  customer;


    useEffect(()=>{
        setCustomer({customer_name:'Walk-in Customer', customer_id:1})
    },[])

    useEffect(() => {
        axios.get(`${config.epharmUrl}/Cinvoice/payment_types`).then(res => {
            const data = res.data;
            const dt = !data.data ? [] : data.data;
            const arr = dt.map(item=>({
                value:item.id,
                text:item.payment_type_name
            })).filter(item=>item.text !== 'CREDIT')
            setPayTypes(arr);
        }).catch(error => {
            errorMessages(error, null, actions)
        })
    }, [])

    const handleCloseDialog = () => {
        setOpenDialog(false)
    };

    const retrieveReceiptDetails = (item)=>{
        const {receipt_number:recep_number, customer_id:cus_id} = item
        const formData = new FormData()
        formData.append('receipt_number', recep_number)
        formData.append('invoice_number', '')
        formData.append('customer_id', cus_id)
        setReceiptData({...receiptData, loading: 'loading'})
        axios.post(`${config.epharmUrl}/Cinvoice/view_receipt`, formData).then(response => {
            const dataRes = response.data;
            const dt = dataRes ? dataRes : {};
            const arr = dt.bill ? dt.bill : [];
            const customer_details = dt.customer_details ? dt.customer_details : {}
            const obj = arr[0] ? arr[0] : {}
            setReceiptData({...receiptData,products: arr,customer: {...customer_details,...obj}, loading: 'success'})
            setOpenDialog(true);
        }).catch(error => {
            errorMessages(error, null, actions)
            setReceiptData({...receiptData, loading: 'error'})
        })
    }

    const handleSaveReceipt = event => {
        event.preventDefault();
        const {paid_amount,n_total, due_amount} = totals
        const {credit_limit} = customerDetails
        const {customer_id} = customer
        const {invoice_date} = state
        setSubmitted(true)
        const arr = rows.length > 0 && rows.every(item => item.batch_id && item.product_name && item.product_quantity)
        const validate_invoice_date = validateCurrentDate(invoice_date)
        if (arr && paid_amount && paytype && paid_amount >= n_total && validate_invoice_date) {
            setIsSubmitted('pending')
            setIsBlocking(false)

            if (due_amount <= credit_limit || credit_limit === 0) {
                const data = rows.map(({product_id, batch_id, product_quantity, product_rate, related_product})=>({product_id, ...batch_id,
                    quantity:product_quantity, rate:product_rate, id:related_product}))
                const amount_paid = paid_amount > n_total  ? n_total : paid_amount
                axios.post(`${config.epharmUrl}/Cinvoice/insert_invoice`, {
                    payment_type: +paytype, customer_id, total_amount:n_total,amount_paid,
                    bill_details:data, invoice_date
                }, {headers: {user_id},}).then(response => {
                    const data = response.data ?? {};
                    const dt = data.data ? data.data : {}
                    const recep = dt.receipt ? dt.receipt : '';
                    const recep_id = recep.receipt_no ? recep.receipt_no : ''
                    const cus_id = dt.customer_id ? dt.customer_id : ''
                    retrieveReceiptDetails({receipt_number:recep_id, customer_id: cus_id})
                    actions.snackbarActions.successSnackbar("Receipt saved successfully!");
                    setIsSubmitted('resolved')
                    setSubmitted(false)
                    setRows(initialState)
                    setCustomer({customer_name:'Walk-in Customer', customer_id:cus_id,})
                    setTotals({grand_total_price:0,n_total:0, change:0, due_amount:0, paid_amount:'',previous_amount:0,total_discount_amount:0})
                    setCustomerDetails({credit_limit: 0})
                    setInvoiceId(recep_id)
                }).catch(error => {
                    errorMessages(error, null, actions)
                    setIsSubmitted('rejected')
                })
            } else {
                let msg = {
                    message: `The customer has reached their credit limit of
             ${credit_limit.toLocaleString()}. Please clear the balance before purchasing more items`, duration: 10000
                };
                actions.snackbarActions.infoSnackbar(msg)
            }
        }

    };

    const handleCloseSnackbar = () => {
        actions.snackbarActions.hideSnackBar();
    };

    const handleViewReceipt = () => {
        history.push(`/receipt_print/${customer_id}/${invoice_id}`)
    };

    const isPending = isSubmitted === 'pending'
    const isResolved = isSubmitted === 'resolved'
    const isRejected = isSubmitted === 'rejected'

    const addedState = {...stateVariables, payTypes}

    return { submitted,stateFunctions, stateVariables: addedState, openDialog, invoice_id,
        isPending, isResolved, isRejected, handleCloseDialog, handleSaveReceipt,
        handleCloseSnackbar,  handleViewReceipt, receiptData}
}