import React from 'react';
import {bindActionCreators} from "redux";
import * as snackbarActions from '../../../Redux/Actions/SnackbarActions/SnackbarActions'
import {connect} from "react-redux";
import PharmacySnackbar from "../../../Utils/Snackbars/SnackbarUtil";
import {ReusableRouteTabs} from "../../../Utils/Dialog/ReusableTabs";
import {Container} from "../../../Utils/styledComponents"
import { history } from '../../../Helpers/history';
import {ProductCustomerLedgerForm} from './ProductCustomerLedgerForm';
import {RouteLabels} from "../../../Utils/Tabs/RouteLabels";
import ReusableDashboardHeader from "../../../Containers/Dasboard/ReusableDashboardHeader";
import {useSetupProducts} from "./hooks/useSetupProducts";



const headData = [{name:'Product', width:'400', isRequired:true},{name:'Debit', isRequired:true},
    {name:'Credit', isRequired:true},{name:'Action', width:'100'}]

const SetupProducts = ({actions, snackbars}) => {
    const product_path = '/ledgers/setupproducts' || '/ledgers/ledgerlist/product'
    const components = [
        {label: 'Accounting Period', path: '/accounting/setup'},
        {label: 'Suppliers', path: '/ledgers/setupsuppliers'},
        {label: 'Customers', path: '/ledgers/setupcustomers'},
        {label: 'Products', path: product_path},
    ]

    const subLinks = [
        {label: 'Setup', route: '/ledgers/setupproducts'},
        {label: 'Ledgers', route: '/ledgers/ledgerlist/product'},
    ]
    const {productProps, closeSnackbar} = useSetupProducts(actions)

    const {open, variant, message} = snackbars;
    return (
        <div className='journals'>
            <ReusableDashboardHeader component='Product Ledgers' heading="Accounting"
                                     subHeading='product Ledgers' link={history.location.pathname}/>
            <PharmacySnackbar message={message} open={open} handleCloseBar={closeSnackbar} variant={variant}/>
            <Container>
                <ReusableRouteTabs value={product_path} tabHeading={components}/>
                <RouteLabels value={history.location.pathname} data={subLinks}/>
                <ProductCustomerLedgerForm {...{...productProps,headData}}/>
            </Container>
        </div>

    )
}

function mapStateToProps(state) {
    const {snackbars} = state;
    return {snackbars:snackbars}
}

function mapDispatchToProps(dispatch) {
    return {actions: {snackbarActions: bindActionCreators(snackbarActions, dispatch)}}
}

export default connect(mapStateToProps, mapDispatchToProps)(SetupProducts);