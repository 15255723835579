function formatAmount(amount) {
    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'UGX',
    });

    return formatter.format(amount)
}

function formatDigits(amount) {
    const formatter = new Intl.NumberFormat('en-US');

    return formatter.format(amount)
}
export {formatAmount, formatDigits}
