import React,{useState} from "react";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import IconButton from "@material-ui/core/IconButton";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import Collapse from "@material-ui/core/Collapse";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import {makeStyles} from "@material-ui/core/styles";
import {convertGmtTime} from "../../Utils/titleCaseFunction";
import axios from "axios";
import config from "../../Helpers/config.json";
import { formatDateTime } from "../../Utils/ConvertDate";
import { LoadingGif } from "../../Utils/Loader";
import { CenteredContainer } from "../../Utils/styledComponents";
import { errorMessages } from "../../Helpers/ErrorMessages";



const useRowStyles = makeStyles({
    root: {
        '& > *': {
            borderBottom: 'unset',
        },
    },
    h6:{
        fontSize:'14px'
    },
    approved: {
        color: "green"
    },
    purchased: {
        color: "#1d5dd3"
    },
    canceled: {
        color: '#D9512C'
    },
    pending: {
        color: "#F29339"
    }
      
});

export function CustomerRow({row, actions}) {
    const classes = useRowStyles();
    const [invoiceDetails,setInvoice] = useState([])
    const [loading, setLoading] = useState('idle')

    const [open, setOpen] = useState(false)

    const handleOpen = () =>{
        setOpen(!open)
        const formData = new FormData()
        formData.append('receipt_number', '')
        formData.append('invoice_number', row.invoice_number)
        formData.append('customer_id', row.customer_id)
        setLoading('loading')
        axios.post(`${config.epharmUrl}/Cinvoice/view_receipt`, formData).then(response => {
            const data = response.data;
            const dt = !data ? {}: data
            const arr = dt.bill ? dt.bill : [];
            setInvoice(arr)
            setLoading('success')
        }).catch((error) => {
            errorMessages(error, null, actions)
            setLoading('error')
        })
    
    }

    const styles = {
        1: classes.approved,
        3: classes.pending,
        4: classes.purchased
    }
    const text = {
        1: 'CLEARED',
        3: 'UNCLEARED',
        4: 'PARTIALLY CLEARED',
    }
    const isLoading = loading === 'loading'
    const isSuccess = loading === 'success'
    const isError = loading === 'error'

    return (
        <React.Fragment>
            <TableRow className={classes.root}>
                <TableCell component="th" scope="row">
                    {formatDateTime(row.date)}
                </TableCell>
                <TableCell>{row.invoice_number}</TableCell>
                <TableCell>{row.total_amount}</TableCell>
                <TableCell>
                    <span className={styles[row.status]}>{text[row.status]}</span>
                </TableCell>
                <TableCell>
                    <IconButton aria-label="expand row" size="small" onClick={handleOpen}>
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box margin={1}>
                            {isLoading ? <CenteredContainer><LoadingGif/></CenteredContainer>:null}
                            {isSuccess && invoiceDetails.length > 0 ?  <>
                                <Typography variant="h6" gutterBottom component="div" classes={{h6:classes.h6}}>
                                    Purchased Items
                                </Typography>
                                <table className="table-sm table table-bordered mpeke-customer-table">
                                    <thead>
                                    <tr>
                                        <td>Product Name</td>
                                        <td>Quantity</td>
                                        <td>Amount</td>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {invoiceDetails.map(item=>(
                                    <tr key={item.product_id}>
                                        <td>{item.product_name}</td>
                                        <td>{item.quantity}</td>
                                        <td>{item.total_amount}</td>
                                    </tr>
                                        ))}
                                
                                    </tbody>
                                </table>
                            </>: null}
                            {isError ? <CenteredContainer>The server did not return a valid response</CenteredContainer>:null}
                           
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
}