/** @jsx jsx */ //please do not remove this implementation because the css will not be loaded without it
import { jsx } from '@emotion/react'
import React from "react";
import { dateHypenStringConver} from "../../Utils/titleCaseFunction";
import {ItemsTable, ReceiptTemplate} from "../../Utils/Template/ReceiptTemplate";
import {formatAmount} from "../../Utils/formatNumbers";
import {Container} from "../../Utils/styledComponents"

const headData = [{value:'Product'},{value:'Batch#'},
    {value:'Expiry date'},{value:'Quantity'},{value:'Unit cost'},{value:'Total'}]

const PurchaseDetailsPrint = ({products, supplierDetails, loading}) => {

    const isLoading = loading === 'pending';
    const isSuccess = loading === 'success';
    const isError = loading === 'error';

    const customerDetails =(
        <tbody>
        <tr>
            <td>
                <td><span>Date: <strong>{supplierDetails?.final_date ? dateHypenStringConver(supplierDetails?.final_date) : ''}</strong></span></td>
            </td>
        </tr>
        <tr>
            <td>
                <span>Invoice#: <strong>{supplierDetails?.chalan_no}</strong></span>
            </td>

        </tr>
        <tr>
            <td>
                <span>Supplier Name: <strong>{supplierDetails?.manufacturer_name}</strong></span>
            </td>
        </tr>
        </tbody>
    )

    const itemDetails = (
        <ItemsTable headData={headData}>
            <tbody>
            {products?.map((info, index) => {
                return(
                    <tr key={index}>
                        <td><span>{info.product_name}</span></td>
                        <td><span>{info.batch_id}</span></td>
                        <td><span>{info.expeire_date}</span></td>
                        <td><span>{info.quantity}</span></td>
                        <td><span>{info.manufacturer_price}</span></td>
                        <td><span>{formatAmount(info.total_amount)}</span></td>
                    </tr>
                )
            })}
            </tbody>
        </ItemsTable>
    )

    return (
        <div>

            {isLoading ? <div className="text-center mt-5"><img src="/images/listPreloader.gif" alt="loader"
                                                                style={{width: '10%'}}/></div>:null}
            {isSuccess ?
                <Container>
                <ReceiptTemplate {...{customerDetails, itemDetails}}/>
               </Container> :null}
            {isError ? <div className="text-center">The server did not return a valid response</div>:null}
        </div>
    )

}



export {PurchaseDetailsPrint};
