import React from "react";
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPencilAlt} from "@fortawesome/free-solid-svg-icons/faPencilAlt";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import {makeStyles} from "@material-ui/core/styles";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import * as purchaseActions from "../../Redux/Actions/PurchaseActions/PurchaseActions";
import * as snackbarActions from "../../Redux/Actions/SnackbarActions/SnackbarActions";
import PharmacySnackbar from "../../Utils/Snackbars/SnackbarUtil";
import {dateStringTimeConvert,titleCase} from "../../Utils/titleCaseFunction";
import ReusableDashboardHeader from "../../Containers/Dasboard/ReusableDashboardHeader";
import Grid from "@material-ui/core/Grid";
import AddIcon from '@material-ui/icons/Add';
import {useMuiPagination} from "../../Containers/Dasboard/DashboardTables";
import {EnhancedTableHead, EnhancedTableToolbar, MuiPagination, MuiPaper} from "../../Utils/Tables/CustomTable";
import TableContainer from "@material-ui/core/TableContainer";
import SelectInput from "../../Utils/FormInputs/SelectInput";
import {usePurchaseOrders} from "./CustomHooks/usePurchaseOrders";
import CustomDialog from "../../Utils/Dialog/CustomDialog";
import Form from "react-bootstrap/Form";
import Label from "../../Utils/FormInputs/Label";
import Col from "react-bootstrap/Col";
import Textarea from "../../Utils/FormInputs/Textarea";
import {RightAlignedContainer} from "../../Utils/styledComponents";

import {CancelDialog} from "../../Utils/DeleteDialog";


export const useStyles = makeStyles((theme) => ({
    icon: {
        width: '.95rem',
    },
    approved: {
        color: "green"
    },
    canceled: {
        color: '#D9512C'
    },
    pending: {
        color: "#F29339"
    },
    purchased: {
        color: "#1d5dd3"
    },
    closed:{
        color: '#a30c0c'
    }
}));

const headData = [
    {id: 'sl', numeric: false, disablePadding: false, label: 'SL'},
    {id: 'purchaseDate', numeric: false, disablePadding: false, label: 'Date'},
    {id: 'orderNo', numeric: false, disablePadding: false, label: 'Purchase Order#'},
    {id: 'name', numeric: false, disablePadding: false, label: 'Supplier Name'},
    {id: 'tAmount', numeric: false, disablePadding: false, label: 'Total Amount'},
    {id: 'status', numeric: false, disablePadding: false, label: 'Status'},
    {id: 'action', numeric: false, disablePadding: false, label: 'Action'}
];


const ManagePurchaseOrder = ({actions, snackbars}) => {
    const {page, rowsPerPage, handleChangeRowsPerPage, handleChangePage} = useMuiPagination();
    const {totalAmount, purchaseOrders, loading, handleCloseBar, value,handleChangeValue,filteredList,
    search, handleChangeSearchValue,closeProps} = usePurchaseOrders(actions)
    const user = JSON.parse(sessionStorage.getItem('user'));
    const singleUser = !user ? {} : user;
    const permissions = !singleUser.permission ? {} : singleUser.permission;

    const add_lpo = !permissions.add_lpo ? {} : permissions.add_lpo;
    const edit_lpo = !permissions.edit_lpo ? {} : permissions.edit_lpo;
    const approve_lpo = !permissions.approve_lpo ? {} : permissions.approve_lpo;
    const cancel_lpo = !permissions.cancel_lpo ? {} : permissions.cancel_lpo;
    const classes = useStyles();
    const {open, message, variant} = snackbars;
    const {openCloseDialog, handleClosePurchaseOrderDialog, handleOpenCloseDialog, handleClosePurchaseOrder,
        reason, handleChangeReason,submitted:submittedDraft,isPendingClosedOrder} = closeProps
    return (
        <div className="journals">
            <ReusableDashboardHeader component="Purchase Orders" heading="Purchase Orders"
                                     subHeading="Purchase Orders" link="/managepurchaseorder"/>
            <div className="general-ledger-header mb-3">
                <Grid container spacing={0}>
                    <Grid item xs={6} md={8}>
                        <SelectInput value={value} onChange={handleChangeValue} defaultOption='All Purchase Orders'
                                     options={[{value: 0, text: 'Pending Approval'}, {value: 1, text: 'Approved'},
                                         {value: 2, text: 'Canceled'},{value: 3, text: 'Fulfilled'}]} style={{width: '25%'}}/>
                    </Grid>
                    <Grid item xs={6} md={4}>
                        <div className="text-right">
                            {(add_lpo.create || add_lpo.read || add_lpo.update || add_lpo.delete) ? <Link to='/addpurchaseorder'>
                                <button className='btn btn-sm pharmacy-info-btn py-1'><AddIcon
                                    classes={{root: classes.icon}}/>  New Purchase Order
                                </button>
                            </Link>:null}
                        </div>
                    </Grid>
                </Grid>
            </div>
            <CustomDialog  handleClose={handleClosePurchaseOrderDialog} open={openCloseDialog} title='Close purchase order'>
                <form onSubmit={handleClosePurchaseOrder}>
                    <Form.Group as={Col}>
                        <Label name='Please provide a reason for closing the purchase order' type/>
                        <Textarea submitted={submittedDraft} onChange={handleChangeReason} value={reason} rows={5}/>
                            {submittedDraft && !reason ? <div className='text-danger'>Please provide reason</div> : null}
                    </Form.Group>
                    <RightAlignedContainer>
                        <button type='submit' disabled={isPendingClosedOrder} className='btn btn-sm pharmacy-info-btn mr-4'>{isPendingClosedOrder ? 'Continuing....'  : 'Continue'}</button>
                        <button onClick={handleClosePurchaseOrderDialog} type='button' className='btn btn-sm pharmacy-gray-btn'>No</button>
                    </RightAlignedContainer>
                </form>
            </CustomDialog>
            <PharmacySnackbar open={open} message={message} variant={variant}
                              handleCloseBar={handleCloseBar}/>
            <div className="mui-tables">
                <MuiPaper>
                    <EnhancedTableToolbar title=''>
                        <Form.Control type="text" value={search ? titleCase(search) : ''}
                                      onChange={handleChangeSearchValue}
                                      className='form__control'
                                      placeholder="Search" style={{float: 'right'}}/>
                    </EnhancedTableToolbar>
                    <TableContainer>
                        <Table
                            aria-labelledby="tableTitle"
                            size='medium'
                            aria-label="enhanced table"
                        >
                            <EnhancedTableHead headCells={headData}/>
                            {loading ? <TableBody><TableRow>
                                <TableCell colSpan="7" align="center"><img src="/images/listPreloader.gif"
                                                                           alt="loader"
                                                                           style={{width: '5%'}}/></TableCell>
                            </TableRow></TableBody> : purchaseOrders.length > 0 ?
                                <TableBody>
                                    {filteredList(purchaseOrders).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        .map((d) => {
                                            const status_text={
                                                0:'PENDING APPROVAL',
                                                1:'APPROVED',
                                                2:'CANCELED',
                                                3:'FULFILLED',
                                                4:'CLOSED'

                                            }
                                            const status_style =  {
                                                0:classes.pending,
                                                1:classes.approved,
                                                2:classes.canceled,
                                                3:classes.purchased,
                                                4:classes.closed
                                            }
                                            const editButton = (
                                                ((edit_lpo.create || edit_lpo.read || edit_lpo.update || edit_lpo.delete)) ? <Link
                                                    to={{pathname: `/editpurchaseorder/${d.local_purchase_order_id}/${d.status}`}}>
                                                    <button
                                                        className="btn pharmacy-info-btn btn-sm mr-3 ">
                                                        <FontAwesomeIcon
                                                            icon={faPencilAlt}/>
                                                    </button>
                                                </Link>:null
                                            )
                                            const closeButton = (
                                                <button onClick={handleOpenCloseDialog(d.local_purchase_order_id)}
                                                    className="btn pharmacy-btn-dismiss btn-sm ">
                                                   Close
                                                </button>
                                            )
                                            const status_buttons = {
                                                0:editButton,
                                                1:closeButton,
                                                2:editButton,
                                            }
                                            return (
                                                <TableRow
                                                    hover
                                                    role="checkbox"
                                                    tabIndex={-1}
                                                    key={d.purchase_order_number}
                                                    className='mui-table-row'>
                                                    <TableCell component="th" scope="row" padding="default"
                                                               className='mui-table-cell'>
                                                        {d.sl}
                                                    </TableCell>
                                                    <TableCell>
                                                        {d.create_date ? dateStringTimeConvert(d.create_date) : ''}
                                                    </TableCell>
                                                    <TableCell>
                                                        <Link
                                                            to={{pathname: `/purchaseorderapproval/${d.local_purchase_order_id}/${d.status}`}}>
                                                            {d.purchase_order_number}</Link>
                                                    </TableCell>
                                                    <TableCell>
                                                        {d.manufacturer_name}
                                                    </TableCell>
                                                    <TableCell>
                                                        {d.grand_total}
                                                    </TableCell>
                                                    <TableCell
                                                        className={status_style[d.status]}>
                                                        {status_text[d.status]}
                                                    </TableCell>
                                                    <TableCell id="mainbuttons">
                                                        {status_buttons[d.status]}
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })}
                                    <TableRow>
                                        <TableCell colSpan={4} className="text-right">
                                            Total:
                                        </TableCell>
                                        <TableCell data-testid="total_purchase_order_amount">{totalAmount}</TableCell>
                                        <TableCell/>
                                        <TableCell/>
                                    </TableRow>
                                </TableBody>
                                : <TableBody><TableRow>
                                    <TableCell colSpan="7" align="center">No records found</TableCell>
                                </TableRow></TableBody>}
                        </Table>
                        <MuiPagination data={purchaseOrders} rowsPerPage={rowsPerPage} page={page}
                                   handleChangeRowsPerPage={handleChangeRowsPerPage}
                                   handleChangePage={handleChangePage} rows={[10, 20, 30, 40, 50]} colSpan={6}/>
                    </TableContainer>

                </MuiPaper>
            </div>
        </div>
    )


}

function mapStateToProps(state) {
    return {
        purchases: state.purchases,
        snackbars: state.snackbars
    }

}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            purchaseActions: bindActionCreators(purchaseActions, dispatch),
            snackbarActions: bindActionCreators(snackbarActions, dispatch)
        }
    }

}

export default connect(mapStateToProps, mapDispatchToProps)(ManagePurchaseOrder);
