/** @jsx jsx */
import React from 'react';
import { jsx } from '@emotion/react'
import {smallPadding, table, tableCellBorderBottom} from "../styles/tableStyles";
import {splitText} from "./titleCaseFunction";
import {useCompany} from "../Context/company-context";






function CompanyAddress({align="center"}){
    const {state} = useCompany();
    const { companyName, companyEmail, mobile, address, website, logo } = state;
    return (
        <table css={[table, smallPadding]}>
            {/* <tbody> */}
            <tr>
                <td align={align}> <img src={logo ? logo : '/images/mpeke3.png'} style={{width:"120px"}}
                                         alt="Mpeke"/></td>
            </tr>
            <tr>
                <td align={align}><span><strong>{companyName}</strong></span></td>
            </tr>
            <tr>
                <td align={align}><span>{address ? splitText(address) : address}</span></td>
            </tr>
            <tr>
                <td align={align}><span>Tel: {mobile}</span></td>
            </tr>
            {companyEmail ?  <tr>
                <td align={align}><span>Email: {companyEmail}</span></td>
            </tr>:null}
            {website ?<tr>
                <td align={align} css={[tableCellBorderBottom]}><span>Website: <a href={website} target='_blank'>{website}</a></span></td>
            </tr>:null}

            {/* </tbody> */}
        </table>
    );
}

export default CompanyAddress;
