import React from "react";
import { titleCase } from "../../Utils/titleCaseFunction";
import { CustomTooltip, CustomizedTick } from "./DashboardContent";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const dummy_sales_data = [
  {
    month: 'Jan',
    income: 4000,
    expenses: 2400,
  },
  {
    month: 'Feb',
    income: 3000,
    expenses: 1398,
  },
  {
    month: 'Mar',
    income: 2000,
    expenses: 9800,
  },
  {
    month: 'Apr',
    income: 2780,
    expenses: 3908,
  },
  {
    month: 'May',
    income: 1890,
    expenses: 4800,
  },
  {
    month: 'Jun',
    income: 2390,
    expenses: 3800,
  },
  {
    month: 'Jul',
    income: 3490,
    expenses: 4300,
  },
  {
    month: 'Aug',
    income: 4000,
    expenses: 2400,
  },
  {
    month: 'Sept',
    income: 3000,
    expenses: 1398,
  },
  {
    month: 'Oct',
    income: 2000,
    expenses: 9800,
  },
  {
    month: 'Nov',
    income: 2780,
    expenses: 3908,
  },
  {
    month: 'Dec',
    income: 4000,
    expenses: 2400,
  },
];

const renderColorfulLegendText = (value, entry) => {
  const { color } = entry;
  return <span style={{ color , fontSize:'10px'}}>{titleCase(value)}</span>;
};

/**
 * Function to return the facility perfomance chart
 * @param {*} param0 
 * @returns 
 */
export const FacilityPerformance = ({sales_analytics}) => {

  return (
    <div style={{marginTop:'0px'}} data-testid="dashboard-facility-performance">
      <div style={{marginBottom:'10px'}}>
        <p className="dashboard-card-font-color">Facility Performance</p>
        <div className="orange-short-border"></div>
      </div>
      <div style={{marginTop: '10px'}}>
        <ResponsiveContainer width={'99%'} height={300}>
          <LineChart
            width={480}
            height={300}
            data={sales_analytics ? sales_analytics : dummy_sales_data}
            margin={{
              top: 0,
              right: 15,
              left: 0,
              bottom: 0,
            }}
          >
            <CartesianGrid horizontal={false} />
            <XAxis dataKey="month" />
            <YAxis type="number" domain={['dataMin', 'dataMax']}  tick={<CustomizedTick />} />
            <Tooltip content={<CustomTooltip/>}/>
            <Legend verticalAlign="top" align="right" iconType={'circle'} formatter={renderColorfulLegendText}  height={36}/>
            <Line type="monotone" dataKey="expenses" stroke="#318DFF" activeDot={{ r: 4 }} strokeWidth={3} dot={false} />
            <Line type="monotone" dataKey="income" stroke="#F76B1D" activeDot={{ r: 4 }} strokeWidth={3} dot={false} />
          </LineChart>
        </ResponsiveContainer>
      </div>
    </div>
  )
}
