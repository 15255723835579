import * as React from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import styled from "@emotion/styled/macro";
import * as colors from '../../styles/colors'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {makeStyles} from "@material-ui/core";

const useStyles = makeStyles(()=>({
    icon:{
        width:'0.75em',
        height:'0.75em'
    }
}))

const ListContainer = styled.div({
    border:`1px solid ${colors.lightGray003}`,
    marginBottom:'10px',
    borderRadius:'8px',
    boxShadow:'0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)'
})
const ItemIcon = styled(ListItemIcon)({
    minWidth:'5px'
})

const StyledList = styled(List)({
    paddingTop:0,
    paddingBottom:0,

})

const ItemButton = styled(ListItem)({
    '&::-webkit-tap-highlight-color':{
        background: colors.base
    },
    ':hover':{
        background:colors.lightGray002
    },
    paddingLeft:'6px',
    paddingRight:'6px',
    background:'white',
    borderRadius:'8px 8px 0 0'
})

const ItemText = styled(ListItemText)({
    '& .MuiTypography-root':{
        fontSize:'14px',
        // fontWeight:700
    }

})

const StyledCell = styled(TableCell)({
    padding:'10px 13px',
    fontSize:'14px'
})

const HeadCell = styled(StyledCell)({
    borderTop:`1px solid ${colors.lightGray003}`,
    borderBottom:`1px solid ${colors.lightGray003}`,
    // background:colors.gray001,
    fontWeight:700,
    // textAlign:'center',
    width:'fit-content'
})
const DataCell = styled(StyledCell)({
    borderTop:`1px solid ${colors.lightGray003}`,
    borderBottom:0
    // textAlign:'center'
})




function ModuleTable({children,extraCell=null, headData=[]}) {
    return (
        <TableContainer>
            <Table aria-label="module table">
                <TableHead>
                    <TableRow>
                        {extraCell}
                        {headData.map((item, index)=>(
                            <HeadCell align={item.align} key={index}>{item.item} </HeadCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {children}
                </TableBody>
            </Table>
        </TableContainer>
    );
}


function CollapsibleList({handleOpen, children, open, name, button=null}) {
    const classes = useStyles()
    const item_name = (
        <span><strong>{name}</strong></span>
    )
    return (
        <ListContainer>
            <StyledList
                sx={{ width: '100%'}}
                component="nav"
                aria-labelledby="collapsible-list"
            >
                <div style={{display:'flex', justifyContent:'space-between', background:'white'}}>
                <ItemButton onClick={handleOpen} button>
                    <ItemIcon >
                        {open ? <ExpandMoreIcon className={classes.icon}/> : <ChevronRightIcon className={classes.icon}/>}
                    </ItemIcon>
                    <ItemText  primary={item_name}  />
                </ItemButton>
                {button}
                </div>

                <Collapse in={open} timeout="auto" unmountOnExit>
                    {children}
                </Collapse>
            </StyledList>
        </ListContainer>
    );
}
export {CollapsibleList, DataCell, ModuleTable, ItemText, HeadCell}
