import React from "react";
import Dialog from "@material-ui/core/Dialog";
import {DialogTitle, DialogContent, DialogActions} from "../../Utils/Dialog/pharmacyDialog";
import Slide from "@material-ui/core/Slide";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import * as userActions from "../../Redux/Actions/UserActions/UserActions";
import {removeTime} from "../../Utils/titleCaseFunction";
import {makeStyles} from "@material-ui/core/styles";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


export const useStyles = makeStyles(() => ({
    dialog: {
        maxWidth:'800px',
        borderRadius: '10px'
    }
}));

const ModelPopUp = ({openDialog, handleCloseDialog, expiredDrugs, outOfStock}) => {
    const classes = useStyles()
    return (
        <Dialog open={openDialog} TransitionComponent={Transition} maxWidth='md' fullWidth={true} classes={{paperWidthMd:classes.dialog}}>
            <DialogTitle onClose={handleCloseDialog}>
                <div className="text-center">
                    Out of Stock And Date Expired Medicine
                </div>
            </DialogTitle>
            <DialogContent>
                <div className="mb-3 ">
                    <h6 className="text-center">Date Expired Medicine</h6>
                    <div className="table-responsive">
                        <table className="table table-striped table-bordered">
                            <thead>
                            <tr>
                                <th>SL</th>
                                <th>Medicine Name</th>
                                <th>Batch ID</th>
                                <th>Expiry Date</th>
                            </tr>
                            </thead>
                            <tbody>
                            {expiredDrugs.length > 0 ?
                                expiredDrugs.map((drug, index) => {
                                    const count = index += 1;
                                    return (
                                        <tr key={index}>
                                            <td>{count}</td>
                                            <td>{drug.product_name}</td>
                                            <td>{drug.batch_id}</td>
                                            <td>{drug.expeire_date ? removeTime(drug.expeire_date):''}</td>
                                        </tr>
                                    )
                                })
                                : <tr>
                                    <td colSpan={4} className="text-center">No data available, all drugs up to
                                        date
                                    </td>
                                </tr>
                            }
                            </tbody>

                        </table>
                    </div>
                </div>
                <hr/>
                <div>

                    <h6 className="text-center">Out of Stock Medicine</h6>
                    <div className="table-responsive">
                        <table className="table table-bordered table-striped">
                            <thead>
                            <tr>
                                <th>SL</th>
                                <th>Medicine Name</th>
                                <th>Medicine Type</th>
                                <th>Unit</th>
                                <th>Reorder Level</th>
                                <th>Stock</th>
                            </tr>
                            </thead>
                            <tbody>
                            {outOfStock.length > 0 ?
                                outOfStock.map((stock, index) => {
                                    const count = index += 1;
                                    return (
                                        <tr key={index}>
                                            <td>{count}</td>
                                            <td>{stock.product_name}</td>
                                            <td>{!stock.product_model ? "" : stock.product_model}</td>
                                            <td>{!stock.unit ? "":stock.unit}</td>
                                            <td>{stock.reorder_level}</td>
                                            <td>{stock.stock}</td>
                                        </tr>
                                    )
                                }) : <tr>
                                    <td colSpan={6} className="text-center">No data available</td>
                                </tr>
                            }
                            </tbody>
                        </table>
                    </div>
                </div>
            </DialogContent>
            <DialogActions>
                <button className="btn pharmacy-btn" onClick={handleCloseDialog}> Close</button>
            </DialogActions>
        </Dialog>
    )
};

function mapStateToProps(state) {
    return {
        users: state.users
    }

}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            userActions: bindActionCreators(userActions, dispatch)
        }
    }

}

export default connect(mapStateToProps, mapDispatchToProps)(ModelPopUp);
