import React from "react";
import Dialog from "@material-ui/core/Dialog";
import { DialogActions,DialogTitle, DialogContent } from "../../../Utils/Dialog/pharmacyDialog"


const ExpensePrintDialog = ({openExpenseDialog, handleCloseExpenseDialog,handleClose, message,print, text}) => {

    return (
        <Dialog open={openExpenseDialog}>
            <DialogTitle onClose={handleCloseExpenseDialog}>
                <div className="text-center">
                    View {message}
                </div>
            </DialogTitle>
            <DialogContent>
                <div className="text-center">
                    <p style={{fontSize:"14px"}}>Would you like to view the {text ? text : 'invoice'}?</p>
                </div>
            </DialogContent>
            <DialogActions>
                <div className="text-right">
                    <button onClick={print} type="button" className="btn pharmacy-info-btn mr-3">Yes</button>
                    
                        <button type="button" onClick={handleClose} className="btn pharmacy-grey-btn ">No</button>
                    
                </div>
            </DialogActions>
        </Dialog>
        
    )

};
export default ExpensePrintDialog;