import React, {useEffect, useState} from 'react';

import {makeStyles} from '@material-ui/core/styles';

import {ReusableJournalTable} from "./ReusableJournalTable";

import { formatAmount } from '../../../Utils/formatNumbers';
import { RightAlignedContainer } from '../../../Utils/styledComponents';
import { AccountingDateFilter } from '../../../Utils/AccountingUtils/AccountingDateFilter';
import {exportPDF} from "../../../Utils/exportPDF";
import {dateConvert, formatDate, formatLocalTime} from '../../../Utils/ConvertDate';
import axios from "axios";
import config from "../../../Helpers/config.json";
import {errorMessages} from "../../../Helpers/ErrorMessages";


export const JournalList = ({actions,item,isResolved}) => {
    const current_date = new Date()
    const [journal, setJournal] = useState([]);
    const [loading, setLoading] = useState('idle');
    const [running_balance, setBalance] = useState(0)
    const [state, setState] = React.useState({from_date:dateConvert(), to_date:dateConvert()})
    const [submitted, setSubmitted] = React.useState(false);
    const [isSubmitted, setIsSubmitted] = React.useState('idle');
    const {from_date, to_date} =state;


    const {id, head_name,} = item;



    useEffect(()=>{

        if (id){
            const formData = new FormData();
            formData.append('ledger_id', id);
            formData.append('start_date', from_date);
            formData.append('end_date', to_date);
            setLoading('pending');
            axios.post(`${config.epharmUrl}/accounting/specific_journal`, formData).then(res => {
                const data = res.data;
                const dt = data ? data : {};
                const arr = dt.data ? dt.data : [];
                const list = arr.map(item=>({
                    id:item.id,
                    date:item.date_added,
                    details:item.memo,
                    ...item
                }))
                setJournal(list)
                setLoading('success')
            }).catch(err => {
                setLoading('error')
            })
        }
    },[id,isResolved])


    React.useEffect(()=>{
        if(!id){
            return
        }
        const formData = new FormData();
        formData.append('ledger_id', id);
        formData.append('start_date', from_date);
        formData.append('end_date', to_date);
        setLoading('pending')
        axios.post(`${config.epharmUrl}/accounting/specific_journal_balance`, formData).then(response=>{
            const res = response.data ? response.data:{};
            const dt = res.data ? res.data:{}
            const bal = dt.resultant_amount ? dt.resultant_amount : 0
            setBalance(bal)
            setLoading('success')
        }).catch(err=>{
            setLoading('error')
        })
    },[id,isResolved])


    const handleChange = (event) =>{
        const {value, name} = event.target;
        setState({...state,[name]:value})
    }

    const handleSubmit = (event) =>{
        event.preventDefault()
        const {id} = item;
        setSubmitted(true)
        const formData = new FormData();
        formData.append('ledger', id);
        formData.append('start_date', from_date);
        formData.append('end_date', to_date);
        if(from_date && to_date && to_date >= from_date){
            (async () => {
                    try {
                        const getJournals = axios.post(`${config.epharmUrl}/accounting/specific_journal`, formData);
                        const getJournalTotal = axios.post(`${config.epharmUrl}/accounting/specific_journal_balance`, formData);
                        const [journalData, journalBalanceData] = await Promise.all([getJournals, getJournalTotal]);
                        if(journalData?.data && journalBalanceData?.data){
                            setLoading('success')
                            setIsSubmitted('resolved')
                            //journal response
                            const res = journalData.data ? journalData.data:{};
                            const arr = res.data ? res.data : [];
                            const list = arr.map(item=>({
                                id:item.id,
                                date:item.date_added,
                                details:item.memo,
                                ...item
                            }))
                            setJournal(list)

                            //journal balance response
                            const balanceResponse = journalBalanceData.data ? journalBalanceData.data:{};
                            const dt = balanceResponse.data ? balanceResponse.data:{}
                            const bal = dt.resultant_amount ? dt.resultant_amount : 0
                            setBalance(bal)
                        }

                    } catch (error) {
                        errorMessages(error, null, actions)
                        setLoading('error')
                        setIsSubmitted('rejected')
                    }
                }
            )();
        }

    }


    const isLoading = loading === 'pending';
    const isSuccess = loading === 'success';
    const isError = loading === 'error';


    const isPending = isSubmitted === 'pending';

    const downloadPDF = () => {
        exportPDF(`${head_name} Journal(${formatLocalTime(current_date)})`, [['Date', "Voucher No.", "Voucher Type", "Memo", "Amount", "Transaction Type"]],
            journal.filter(item=>Boolean(item)).map((item) => [formatDate(item.date), item.voucher_no, item.voucher_type, item.details, formatAmount(item.entry_amount),item.entry_category]),
            `${head_name} Journal(${formatLocalTime(current_date)}).pdf`,[["","","","Running Balance",  formatAmount(running_balance),""]]);
    }

    return (
        <div>
            <div className="sub-journal-bar">
                <RightAlignedContainer>
                    <h5 data-testid="running_balance">Running Balance : <strong>{formatAmount(running_balance)}</strong></h5>
                </RightAlignedContainer>
            </div>
            <div className="pt-3">
                <div className='mx-3'>
                    <AccountingDateFilter {...{from_date,to_date, handleChange, submitted,handleSubmit,isPending}}/>
                </div>
                <RightAlignedContainer>
                    {journal.length > 0 ?  <button onClick={downloadPDF} className='btn btn-sm pharmacy-info-btn'>Download PDF</button>:null}
                </RightAlignedContainer>
                <ReusableJournalTable data={journal} {...{data:journal,isLoading, isSuccess, isError}}/>
            </div>

        </div>
    )
}
