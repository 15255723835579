import React, {useState} from 'react';
import CommonReturnList from "./CommonReturnList"
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import axios from "axios";
import config from "../../Helpers/config";
import {errorMessages} from "../../Helpers/ErrorMessages";
import * as snackbarActions from "../../Redux/Actions/SnackbarActions/SnackbarActions"
import {useReturnList} from "./CustomHooks/useReturnList";
import PharmacySnackbar from "../../Utils/Snackbars/SnackbarUtil";
import DeleteDialog from "../../Utils/DeleteDialog";
import {useExcelReports} from "../Invoice/CustomHooks/useExcelReports";



const headData = [
    {id: 'invoice', numeric: false, disablePadding: false, label: 'Invoice No'},
    {id: 'name', numeric: false, disablePadding: false, label: 'Customer Name'},
    {id: 'date', numeric: false, disablePadding: false, label: 'Date'},
    {id: 'quantity', numeric: false, disablePadding: false, label: 'Quantity'},
    {id: 'price', numeric: false, disablePadding: false, label: 'Unit Price'},
    {id: 'discount', numeric: false, disablePadding: false, label: 'Discount'},
    {id: 'total', numeric: false, disablePadding: false, label: 'Total Amount'},
    {id: 'action', numeric: false, disablePadding: false, label: 'Action'},
];
const CustomerReturnList = ({actions, snackbars}) => {
    const {state, data, total_count, loading, search_value, activePage,
        handleChangeSearchValue, handleChange, handleSubmitSearch, handlePageChange, handleCloseSnackbar,
        setData} = useReturnList(actions, 'customer')
    const [openDialog, setOpenDialog] = useState(false);
    const [invoice_id, setInvoiceId] = useState('')


    const handleOpenDialog = (_id) => {
        setOpenDialog(true);
        setInvoiceId(_id);
    };
    const handleCloseDialog = () => {
        setOpenDialog(false)
    };
    const handleDeleteReturn = () => {
        axios.post(`${config.epharmUrl}/Creturn/delete_retutn_invoice`, null, {params: {invoice_id}}).then(() => {
            const arr = data.filter(stock => stock.invoice_id !== invoice_id)
            actions.snackbarActions.successSnackbar('Successfully Deleted!');
            setData(arr)
            setOpenDialog(false)
        }).catch(error => {
            errorMessages(error, null, actions)
        })

    };
    const currentObj = {file_name:'Customer Returns Report', url:'Creturn/customer_return_excel_reports'}

    const {exportExcel} = useExcelReports(actions, currentObj)

    const {open, variant, message} = snackbars;
    const arr = data.map(item => ({
        return_id: item.return_id,
        id: item.invoice_id,
        name_id: item.customer_id,
        name: item.customer_name,
        date: item.date_return,
        total_amount: item.total_ret_amount,
        url: 'stockreturndetails',
        quantity: item.ret_qty,
        rate: item.product_rate,
        discount: item.total_deduct
    }))
    return (
        <CommonReturnList title='Customer Refund List' handleOpenDialog={handleOpenDialog}
                          handlePageChange={handlePageChange} search_value={search_value} loading={loading} data={arr}
                          handleChange={handleChange} headData={headData} state={state} total_count={total_count}
                          activePage={activePage} exportExcel={exportExcel}
                          handleChangeSearch={handleChangeSearchValue} handleSubmitSearch={handleSubmitSearch}>
            <PharmacySnackbar open={open} variant={variant} message={message}
                              handleCloseBar={handleCloseSnackbar}/>
            <DeleteDialog openDialog={openDialog} handleDelete={handleDeleteReturn} handleClose={handleCloseDialog}
                          message='customer return'/>
        </CommonReturnList>

    );

}

function mapStateToProps(state) {
    return {
        snackbars: state.snackbars
    }

}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            snackbarActions: bindActionCreators(snackbarActions, dispatch)
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomerReturnList);