import React from 'react';
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form"
import TextField from "../../Utils/FormInputs/TextField";
import {CustomSelect} from "../../Utils/FormInputs/SelectInput";
import Label from "../../Utils/FormInputs/Label"
import Textarea from "../../Utils/FormInputs/Textarea";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import {HtmlTooltip} from "../../Utils/Dialog/HtmlToolTip";


const headData = [{name:'Payment method', width:300}, {name:'Voucher Type', width:300}, {name:'Debit to', width:300},{name:'Description', width:300},{name:''}]


const AddPaymentForm = ({submitted,isPending, handleChange, handleSubmit, addRows, removeRow,
                            handleChangeAccount,handleChangeVoucherType,debitAccounts=[], rows=[], voucherTypes=[]}) => {

    return (
        <form onSubmit={handleSubmit} autoComplete="off" className='mt-2'>
            {/*<TableContainer>*/}
                <Table size='small'>
                    <TableHead>
                        <TableRow>
                            {headData.map(item=>(
                                <TableCell width={item.width}>{item.name}</TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map((item, index)=>{
                            return(
                                <TableRow key={index}>
                                    <TableCell>


                                        <TextField type="text" placeholder="Payment type" name="payment_type_name"
                                                   value={item.payment_type_name} onChange={(e)=>handleChange(e, index)} submitted={submitted}/>
                                        {(submitted && !item.payment_type_name) &&
                                            <div className="invalid-text">Please enter the payment method</div>}
                                    </TableCell>
                                    <TableCell>
                                        <HtmlTooltip arrow
                                                     title={<span>Specify whether payment types is attached to an invoice or receipt </span>}
                                                     id='payment_type_name' placement='top'>
                                        <CustomSelect value={item.voucher_type} onChange={value=>handleChangeVoucherType(value, index)}
                                                      options={voucherTypes} defaultOption='Select voucher type...' submitted={submitted}/>
                                        </HtmlTooltip>
                                        {(submitted && !item.voucher_type) &&
                                            <div className="invalid-text">Please select the voucher type</div>}
                                    </TableCell>
                                    <TableCell>
                                        <HtmlTooltip arrow
                                                     title={<span>Account to be debited</span>}
                                                     id='payment_type_name' placement='top'>
                                        <CustomSelect value={item.debit_ledger} onChange={value=>handleChangeAccount(value, index)}
                                                      options={debitAccounts} defaultOption='Select debit account...' submitted={submitted}/>
                                        </HtmlTooltip>
                                        {(submitted && !item.debit_ledger) &&
                                            <div className="invalid-text">Please select the debit account</div>}
                                    </TableCell>
                                    <TableCell>
                                        <Textarea type="text" placeholder="Write description..." name="description"
                                                  value={item.description} onChange={(e)=>handleChange(e, index)} />
                                    </TableCell>
                                    <TableCell>
                                        <button type='button' onClick={()=>removeRow(index)} className='btn btn-sm pharmacy-btn-dismiss'>Remove</button>
                                    </TableCell>
                                </TableRow>
                            )
                        })}
                        <TableRow>
                            <TableCell colSpan={headData.length}>
                                <button type='button' onClick={addRows} className='btn btn-sm pharmacy-gray-btn'>Add another line</button>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            {/*</TableContainer>*/}
            <div className='mt-3'>
                <button type="submit" disabled={isPending}
                        className='btn pharmacy-btn offset-md-5'>{isPending ? "Submitting..." : "Submit"}</button>
            </div>

        </form>
    )

}


const EditPaymentForm = ({state, submitted,debit_account,isPending, handleChange, handleSubmit,
                            handleChangeAccount,debitAccounts=[]}) => {

    const {payment_type, description} = state;
    return (
        <form onSubmit={handleSubmit} autoComplete="off" className='mt-2'>
            <Form.Group as={Col} controlId="paymentMethod">
                <Label name='Payment Method' type/>
                <TextField type="text" placeholder="Payment type" name="payment_type"
                           value={payment_type} onChange={handleChange} submitted={submitted}/>
                {(submitted && !payment_type) &&
                    <div className="invalid-text">Please enter the payment method</div>}
            </Form.Group>
            <Form.Group as={Col} controlId="debitTo">
                <Label name='Debit To' type/>
                <CustomSelect value={debit_account} onChange={handleChangeAccount}
                              options={debitAccounts} defaultOption='Select debit account...' submitted={submitted}/>
                {(submitted && !debit_account) &&
                    <div className="invalid-text">Please select the debit account</div>}

            </Form.Group>
            <Form.Group as={Col} controlId="description">
                <Label name='Description'/>
                <Textarea type="text" placeholder="Write description..." name="description"
                          value={description} onChange={handleChange} />
            </Form.Group>
            <button type="submit" disabled={isPending}
                    className='btn pharmacy-btn offset-md-5'>{isPending ? "Submitting..." : "Submit"}</button>
        </form>
    )

}





export {AddPaymentForm, EditPaymentForm};