import React from 'react';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as snackbarActions from "../../Redux/Actions/SnackbarActions/SnackbarActions";
import PharmacySnackbar from "../../Utils/Snackbars/SnackbarUtil";
import ReusableDashboardHeader from "../../Containers/Dasboard/ReusableDashboardHeader";
import Card from '@material-ui/core/Card';
import {useAddCustomer} from "./CustomHooks/useAddCustomer";
import {history} from "../../Helpers/history";
import {ColumnCustomerLayout} from "./CustomerForm";
import {MedicineContainer, RightAlignedContainer} from "../../Utils/styledComponents";
import {ReusableProductsList} from "../../Utils/List/ReusableProductsList";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import {StyledRemoveIcon} from "../Stock/StockAdjustment";
import {formatAmount} from "../../Utils/formatAmount";
import DetailsDialog from "../../Utils/Dialog/DetailsDialog";
import AddProvider from "./AddProvider";
import {BlockNavigationComponent} from "../../Utils/ReusableComponents/BlockNavigationComponent";

const AddCustomer = ({actions, snackbars}) => {
    const {open, variant, message} = snackbars;
    const { handleCloseBar, customerProps, providerProps, isBlocking
    } = useAddCustomer(actions);
    const {isShowForm, addCustomer, addedCustomers, handleSubmitMultiple,
        addAnotherCustomer, handleRemoveCustomer, cancelAdd, isPending,} = customerProps;
    const {openDialog:openCategory, handleCloseDialog:handleCloseCategory} = providerProps
    return (
        <div className='journals'>
            <ReusableDashboardHeader component= "Add Customer" heading="Customer"
                                     subHeading="Add Customer" link={history.location.pathname}/>
            <div className="general-ledger-header">
                <div className="row">
                    <div className="col-md-6">
                        <h6 className="mx-2 mt-2">Add Customer</h6>
                    </div>
                    {/* <div className="col-md-6 text-right">*/}
                    {/*    <CsvDialog message="Customer" openDialog={openDialog}*/}
                    {/*               handleCloseDialog={handleCloseDialog}*/}
                    {/*               handleChangeUpload={handleFileUpload} handleUploadCsv={fileUploader}>*/}
                    {/*        <a href="/csv/customer_csv_sample.csv" download>*/}
                    {/*            <button className="btn pharmacy-primary-btn">Download Sample File</button>*/}
                    {/*        </a>*/}
                    {/*    </CsvDialog>*/}
                    {/*    <button onClick={handleOpenDialog} type="button"*/}
                    {/*            className="btn pharmacy-info-btn mr-2">*/}
                    {/*        Upload CSV*/}
                    {/*    </button>*/}
                    {/*</div>*/}
                </div>
            </div>
            <PharmacySnackbar open={open} message={message} variant={variant}
                              handleCloseBar={handleCloseBar}/>
            <DetailsDialog title='New Category' openDialog={openCategory}
                           handleClose={handleCloseCategory}>
                <AddProvider {...providerProps}/>
            </DetailsDialog>
            <BlockNavigationComponent
                when={isBlocking}
                shouldBlockNavigation={()=>{
                    return isBlocking;
                }}
                navigate={path=>history.push(path)}
            />
            <MedicineContainer>
                {/*<RightAlignedContainer>*/}
                {/*    <div className="mb-2">*/}
                {/*        <button onClick={()=>handleOpenCategory('')} className="btn btn-sm pharmacy-info-btn">Quick Add  Category</button>*/}
                {/*    </div>*/}
                {/*</RightAlignedContainer>*/}
                    {isShowForm || addedCustomers.length === 0 ? <Card className="p-3 general-card">
                        <ColumnCustomerLayout {...customerProps}>
                            <div className='text-right'>
                                <button data-testid="save_customer" type="button" onClick={addCustomer} className="btn pharmacy-info-btn small-btn btn-sm px-3 mr-3">Add Customer</button>
                                {addedCustomers.length > 0 ? <button type="button" onClick={cancelAdd} className="btn pharmacy-btn-dismiss small-btn btn-sm px-3">Cancel</button>:null}
                            </div>
                        </ColumnCustomerLayout>
                    </Card>: null}
                    <div className='my-3'>
                        {addedCustomers.map((item, index)=>{
                            const primaryText = (
                                <span><strong>{item.customer_name}</strong> — {item.category_name ? `${item.category_name}` : ''}</span>
                            )
                            const secondaryText = (
                                <>
                                    {item.mobile ? <span style={{display:'inline-block', marginRight:'12px'}}>Mobile: <strong>{item.mobile ? item.mobile : ''}</strong></span>:null}
                                    {item.email ? <span style={{display:'inline-block', marginRight:'12px'}}>Email: <strong>{item.email ? item.email : '' }</strong></span>: null}
                                    {item.address ? <span style={{display:'inline-block', marginRight:'12px'}}>Address: <strong>{item.address ? item.address : ''}</strong></span>:null}
                                    {item.credit_limit ? <span>Credit Limit: <strong>{item.credit_limit ? formatAmount(item.credit_limit) : 0}</strong></span>:null}
                                </>
                            )
                            return(
                                <ReusableProductsList key={index} {...{primaryText, secondaryText}}button={
                                    <>
                                        <Tooltip title='Delete'>
                                            <IconButton onClick={()=>handleRemoveCustomer(index)}>
                                                <StyledRemoveIcon/>
                                            </IconButton>
                                        </Tooltip>
                                    </>
                                }/>
                            )
                        })}
                    </div>
                    {!isShowForm ?  <button type="button" data-testid="add_another_customer" onClick={addAnotherCustomer} className="btn pharmacy-info-btn btn-sm small-btn px-3">Add another customer</button>:null}
                    {addedCustomers.length > 0 ?  <RightAlignedContainer>
                        <button data-testid="submit_customers" type="submit" onClick={handleSubmitMultiple} disabled={isPending} className="btn pharmacy-btn px-3 mt-4">{isPending ? 'Saving...':'Save'}</button>
                    </RightAlignedContainer>: null}
            </MedicineContainer>
        </div>
    )

}

function mapStateToProps(state) {
    return {
        snackbars: state.snackbars,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            snackbarActions: bindActionCreators(snackbarActions, dispatch)
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddCustomer);