export const base = 'white'
export const text = '#1A1818'
export const gray = '#f1f2f7'
export const lightGray002 = '#fafafa'
export const lightGray003 = '#eee'
export const gray00 = '#c2c3c5'
export const gray001 = '#EEF1F6'
export const gray002 = '#898989'
export const gray003 = '#f9fafb'
export const gray02 = '#f7f7f7'
export const gray10 = '#f1f1f4'
export const gray20 = '#e4e5e9'
export const gray80 = '#6f7077'
export const gray90 = '#7e7f83'
export const gray100 = '#dee2e6'
export const indigo = '#3f51b5'
export const indigoDarken10 = '#364495'
export const indigoLighten80 = '#b7c1f8'
export const yellow = '#ffc107'
export const green = '#4caf50'
export const danger = '#ef5350'
export const orange = 'orange'
export const text01 = '#484449'
export const text02 = '#6c757d'
export const darkBlue = '#2c3136'
export const navyBlue = '#3e454c'
export const deepBlue = '#1f282d'
export const lightGray = '#b9b9b9'
export const muted = '#e5e5e5'
export const muted00 = '#fafafa'
export const red60 = '#e53e3e'
export const text00 = '#2b2a2a'
export const blue = '#3871B7'
export const blue00 = '#3fb8f1'
export const blue001 = '#3871B7'
export const orange00 = '#ff7907'
export const black = '#000'
export const black00 = '#313131'
export const black001 = '#918f8f'
export const muted001 = '#A6A6A7'
export const darkBlue001 = '#385AA8'
export const lightGray001 = '#BEC0C0'
export const pending = '#D9512C'
export const lightOrange = '#d9512c30'
export const lightGreen = '#0d891236'
export const progress = '#ea8b054a'
export const progressText = '#ea8b05'
export const gray500 = 'rgba(224, 224, 224, 1)'
export const rxgreen = '#1fa7b0'




