import React, {useRef} from 'react';
import {ReusableSalesAndPurchaseHeader} from "./ReusableSalesAndPurchaseHeader";
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Label from "../../Utils/FormInputs/Label";
import {CustomSelect} from "../../Utils/FormInputs/SelectInput";
import {useSalesReport} from "./CustomHooks/useSalesReport";
import {CustomPagination, EnhancedTableHead, MuiPagination, MuiPaper} from "../../Utils/Tables/CustomTable";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import {dateStringConvert, titleCase} from "../../Utils/titleCaseFunction";
import {Link} from "react-router-dom";
import {useMuiPagination} from "../../Containers/Dasboard/DashboardTables";
import TextField from "../../Utils/FormInputs/TextField";
import {ReusableSearchForm} from "./ReusableSearchForm";
import ReusableDashboardHeader from "../../Containers/Dasboard/ReusableDashboardHeader";
import {history} from "../../Helpers/history";
import {BackButton} from "../../Utils/FormInputs/BackButton";
import {Container} from "../../Utils/styledComponents"
import {useExcelReports} from "../Invoice/CustomHooks/useExcelReports";
import {bindActionCreators} from "redux";
import * as snackbarActions from "../../Redux/Actions/SnackbarActions/SnackbarActions";
import {connect} from "react-redux";
import {formatAmount} from "../../Utils/formatAmount";

const headData = [
    {id: 'sdate', numeric: false, disablePadding: false, label: 'Date'},
    {id: 'invoice', numeric: false, disablePadding: false, label: 'Invoice#'},
    {id: 'name', numeric: false, disablePadding: false, label: 'Customer'},
    {id: 'sold_by', numeric: false, disablePadding: false, label: 'Sold by'},
    {id: 'product', numeric: false, disablePadding: false, label: 'Product'},
    {id: 'quantity', numeric: false, disablePadding: false, label: 'Quantity Sold'},
    {id: 'unitPrice', numeric: false, disablePadding: false, label: 'Unit Price'},
    {id: 'quantity_purchased', numeric: false, disablePadding: false, label: 'Quantity Purchased'},
    {id: 'purchaseAmount', numeric: false, disablePadding: false, label: 'Purchase Amount'},
    {id: 'total', numeric: false, disablePadding: false, label: 'Sale Amount'},
    {id: 'profit', numeric: false, disablePadding: false, label: 'Profit/Loss'},
];


const AdvancedSalesReport = (props) => {
    const {
        filterType, handleChangeFilterType, loading, salesReport, totals, sortsArr, ordersArr, sortBy,
        orderBy, handleChangeOrderBy, handleChangeSortBy, rangeValue, handleChangeRangeValue, salesPersons,
        submitted, isSubmitted, handleSubmit, period, from_date, to_date, handleChangeDate,
        year, inputTypes, handleChangePeriod, handleChangeYear,isResolved, activePage, handleChangeActivePage,
        count,handleUpdateChangePeriod
    } = useSalesReport()
    const {actions} = props
    
    const csvRef = useRef();
    const csvData = salesReport.map(item=>({
        invoice:item.invoice,
        customer:item.customer_name,
        sales_person:`${item.first_name} ${item.last_name}`,
        product:item.product_name,
        quantity:item.quantity,
        unit_cost:item.purchase_price,
        unit_price:item.rate,
        total_purchase: item.total_purchase,
        total_price:item.total_price,
        profit:item.profit,
        discount:item.discount
    }))
    const headers = [{key: 'invoice', label: 'Invoice#'}, {key: 'customer', label: 'Customer'},
        {key: 'sales_person', label: 'Sales Person'},{key: 'product', label: 'Medicine'},
        {key: 'quantity', label: 'Quantity'},
        {key: 'unit_price', label: 'Unit Price'},{key: 'discount', label: 'Discount(%)'},{key: 'total_purchase', label: 'Purchase Amount'},
        {key: 'total_price', label: 'Sales Amount'}, {key: 'profit', label: 'Profit/Loss'}]
    const {min, max} = rangeValue;
    const {total_profit_amount, total_purchase, sales_amount, total_sales_amount} = totals
    const {page, rowsPerPage, handleChangeRowsPerPage, handleChangePage} = useMuiPagination();
    const periods = [
        {value: 'today', label: 'Today'},
        // {value: 'weekly', label: 'Weekly'},
        // {value: 'monthly', label: 'Monthly'},
        {value: 'custom', label: 'Custom'}
    ]

    const currentObj = {file_name:'Sales Report', url:'admin/todays_sales_excel_report', params:{start_date:from_date, end_date:to_date}}

    const {exportExcel} = useExcelReports(actions, currentObj)       

    return (
        <div className='journals'>
            <ReusableDashboardHeader component="Sales Report" heading="Reports"
                                     subHeading="Sales Report" link={history.location.pathname}/>
            <Container>
                <div className='mb-2'>
                    <BackButton text='Reports' to='/allreports'/>
                </div>
            <ReusableSalesAndPurchaseHeader handleSubmit={handleSubmit} exportExcel={exportExcel}
            csvData={csvData} fetchCsvData={()=>csvRef.current.link.click()} csvRef={csvRef} headers={headers}
            csvTitle='Sales Report'>
                <ReusableSearchForm handleChangePeriod={handleChangePeriod} period={period}
                                    inputType={inputTypes} from_date={from_date} year={year} to_date={to_date}
                                    handleChangeYear={handleChangeYear} handleChangeDate={handleChangeDate}
                                    isSubmitted={isSubmitted} submitted={submitted} periods={periods}/>
            </ReusableSalesAndPurchaseHeader>

                <div className="row col-lg-12">

                    <div className="col-lg-6">
                        <Form.Group as={Row}>
                            <Label name='Sales Person' sm={2} style={{marginTop: '10px'}}/>
                            <Col sm={6}>
                                <CustomSelect onChange={handleChangeFilterType} value={filterType}
                                              options={salesPersons}/>
                            </Col>
                        </Form.Group>
                    </div>
                    <div className="col-lg-6">
                        {/*{isResolved ?  */}
                            <p style={{marginTop:'0.4rem', fontSize:'17px'}}>Total Sales Amount: <strong>{formatAmount(total_sales_amount)}</strong> </p>
                            {/*// : null}*/}
                    </div>
                </div>
                <MuiPaper>
                    <div className="p-4 ml-2">
                        <div className="row">
                            <div className="col-md-3">
                                <Form.Group as={Row}>
                                    <Label name='Sort by:' sm={2} style={{marginTop: '10px'}}/>
                                    <Col sm={8}>
                                        <CustomSelect onChange={handleChangeSortBy} value={sortBy}
                                                      options={sortsArr}/>

                                    </Col>
                                </Form.Group>
                            </div>
                            {sortBy.value ? <div className="col-md-3">
                                <Form.Group as={Row}>
                                    <Label name='Order by:' sm={2} style={{marginTop: '10px'}}/>
                                    <Col sm={8}>
                                        <CustomSelect onChange={handleChangeOrderBy} value={orderBy}
                                                      options={ordersArr}/>
                                    </Col>
                                </Form.Group>
                            </div> : null}
                            {/*orderBy.value === 'range' ? <>
                                <div className="col-md-3">
                                    <Form.Group as={Row}>
                                        <Label name='Min' sm={2} style={{marginTop: '10px'}}/>
                                        <Col sm={8}>
                                            <TextField type='number' value={min} name='min'
                                                       onChange={handleChangeRangeValue}/>
                                        </Col>
                                    </Form.Group>
                                </div>
                                <div className="col-md-3">
                                    <Form.Group as={Row}>
                                        <Label name='Max' sm={2} style={{marginTop: '10px'}}/>
                                        <Col sm={8}>
                                            <TextField type='number' value={max} name='max'
                                                       onChange={handleChangeRangeValue}/>
                                        </Col>
                                    </Form.Group>
                                </div>
                            </> : null */}
                        </div>
                    </div>
                    {/*<EnhancedTableToolbar title=''/>*/}
                    <TableContainer>
                        <Table
                            aria-labelledby="tableTitle"
                            size='medium'
                            aria-label="enhanced table"
                        >
                            <EnhancedTableHead headCells={headData}/>
                            {loading ? <TableBody><TableRow>
                                <TableCell colSpan="11" align="center"><img src="/images/listPreloader.gif"
                                                                            alt="loader"
                                                                            style={{width: '5%'}}/></TableCell>
                            </TableRow></TableBody> : salesReport.length > 0 ?
                                <TableBody>
                                    {salesReport.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        .map((d, index) => {
                                            return (
                                                <TableRow
                                                    hover
                                                    role="checkbox"
                                                    tabIndex={-1}
                                                    key={index}
                                                    className='mui-table-row'>
                                                    <TableCell>
                                                        <span>{dateStringConvert(d.date)}</span>
                                                    </TableCell>
                                                    <TableCell component="th" scope="row" padding="default"
                                                               className='mui-table-cell'>
                                                               <span>
                                                                    <Link
                                                                        to={{pathname: `/invoice_print/${d.customer_id}/${d.invoice}/report`}}
                                                                        style={{color: "#09905c"}}>{d.receipt_number?d.receipt_number:d.invoice}</Link>
                                                                </span>

                                                    </TableCell>
                                                    <TableCell>
                                                        <span><Link to={{pathname: `/customerpurchases/${d.customer_id}`}}
                                                                    style={{color: "#09905c"}}>{titleCase(d.customer_name)}</Link></span>
                                                    </TableCell>
                                                    <TableCell>
                                                        {`${d.first_name ? d.first_name : ''} ${d.last_name ? d.last_name : ''}`}
                                                    </TableCell>
                                                    <TableCell>
                                                                <span>
                                                                    {/*<Link to={{pathname: `/medwise/${d.product_id}`}}*/}
                                                                    {/*      style={{color: "#09905c"}}>*/}
                                                                    {d.product_name}
                                                                    {/*</Link>*/}
                                                                </span>
                                                    </TableCell>
                                                    <TableCell>
                                                        <span>{d.quantity}</span>
                                                    </TableCell>
                                                    <TableCell>
                                                        <span>{d.rate}</span>
                                                    </TableCell>
                                                    <TableCell>
                                                        <span>{d.quantity_purchased || d.quantity_purchase}</span>
                                                    </TableCell>
                                                    <TableCell>
                                                        <span>{d.total_purchase}</span>
                                                    </TableCell>
                                                    <TableCell>
                                                        <span>{d.total_price}</span>
                                                    </TableCell>
                                                    <TableCell>
                                                        <span>{d.profit}</span>
                                                    </TableCell>

                                                </TableRow>
                                            );
                                        })}
                                    {/*{!isResolved ?  <TableRow>*/}
                                    {/*    <TableCell colSpan={8} align="right">Total Amount:</TableCell>*/}
                                    {/*    <TableCell>{total_purchase}</TableCell>*/}
                                    {/*    <TableCell>{sales_amount}</TableCell>*/}
                                    {/*    <TableCell>{total_profit_amount}</TableCell>*/}
                                    {/*</TableRow> :  null}*/}
                                </TableBody>
                                : <TableBody><TableRow>
                                    <TableCell colSpan="11" align="center">No records found</TableCell>
                                </TableRow></TableBody>}
                        </Table>
                    </TableContainer>
                    {isResolved ? <CustomPagination data={salesReport} handleChangeNextPage={handleChangeActivePage}
                                                    activePage={activePage} total_count={count} records={10}/>:

                        <MuiPagination data={salesReport} rowsPerPage={rowsPerPage} page={page}
                                   handleChangeRowsPerPage={handleChangeRowsPerPage}
                                   handleChangePage={handleChangePage} rows={[10, 20, 30, 40, 50]} colSpan={6}/>}
                </MuiPaper>
            </Container>
        </div>
    )
}
function mapStateToProps(state) {
    return {
        snackbars: state.snackbars
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            snackbarActions: bindActionCreators(snackbarActions, dispatch)
        }
    }

}
export default  connect(mapStateToProps, mapDispatchToProps)(AdvancedSalesReport);