/** @jsx jsx */ //please do not remove this implementation because the css will not be loaded without it
import { jsx, css } from '@emotion/react'
import React from "react";
import {dateStringTimeConvert} from "../../Utils/titleCaseFunction";
import {ItemsTable, ReceiptTemplate} from "../../Utils/Template/ReceiptTemplate";
import {textWeight,thUppercase} from "../../styles/tableStyles";
import * as colors from "../../styles/colors"
import {formatAmount, formatDigits} from "../../Utils/formatNumbers";

const titleText = css`
    margin: 0;
    color: ${colors.darkBlue001};
    font-size: 15px;
  @media print{
    font-size: 22px;
  }
    
`

const tableCell = css`
    border-top:1.4px solid ${colors.text00};
  padding-top: 16px !important;
`

const rightPadding = css`
    padding-right: 7rem;
`

const reasonTableCell = css`
  padding-top: 16px;
`

const wordBreak = css`
    word-break:break-word;
`


const headData = [{value:'Product'}, {value:'Batch#'}, {value:'Qty'},{value:'Price'},{value:'Total'}]

const ReusablePosTemplate = ({customer,products, user_name}) => {

    const isInvoice = customer?.invoice_details

    const customerDetails =(
        <tbody>
        <tr>
            <td style={{paddingRight:'7rem'}} css={[textWeight, thUppercase, rightPadding]} ><p css={[titleText]}>Invoice To:</p></td>
            <td css={[textWeight, thUppercase]}><p css={[titleText]}>Invoice Details:</p></td>
        </tr>
        <tr>
            <td ><span>Name: </span> <span css={[textWeight]}>{customer.customer_name}</span></td>
            <td ><span>Date: </span> <span css={[textWeight]}>{customer.date ? dateStringTimeConvert(customer.date) : ''}</span></td>
        </tr>
        <tr>
            <td ><span>Contact:</span> <span css={[textWeight]}>{customer.customer_mobile}</span></td>
            <td><span>Invoice#: </span> <span css={[textWeight]}>{customer.invoice_number}</span></td>

        </tr>
        <tr>
            <td><span>Address: </span> <span css={[textWeight]}>{customer.customer_address}</span></td>
            <td ><span>Account Name: </span> <span css={[textWeight]}>{customer.account_name}</span> </td>
        </tr>
        </tbody>
    )

    const itemDetails = (
        <>

            <ItemsTable headData={headData}>
                <tbody>
                {products.map((info, index) => {
                    const qty = info.quantity ? info.quantity : 0;
                    const rate = info.rate ? info.rate : 0;
                    return(
                        <tr key={index}>
                            <td css={[wordBreak]}><span>{info.product_name}</span></td>
                            <td css={[wordBreak]}><span>{info.batch_id}</span></td>
                            <td><span>{info.quantity}</span></td>
                            <td><span>{info.rate}</span></td>
                            <td><span>{formatDigits(+qty * +rate)}</span></td>
                        </tr>
                    )
                })}
                <tr>
                    <td css={[tableCell]} colSpan="5" ></td>
                </tr>

                <tr>
                    <td  colSpan="3" align="right"><span>Total Amount</span></td>
                    <td ><span><strong>{customer.total_amount ? formatDigits(customer.total_amount) : customer.total_amount}</strong></span></td>
                </tr>
                </tbody>
            </ItemsTable>
            {isInvoice ? <tr>
                <td css={[reasonTableCell]}><span>Reason for cancelling: </span> <span css={[textWeight]}>{customer?.invoice_details}</span></td>
            </tr> : null}
        </>
    )

    const footer = (
        <tbody>
        <tr>
            <td align='right'><span>Created by: <strong>{user_name}</strong></span></td>
        </tr>
        </tbody>
    )


    return (
        <ReceiptTemplate {...{customerDetails, itemDetails, footer}}/>

    )

}


export {ReusablePosTemplate};
