import React, {Component} from "react";
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPencilAlt} from "@fortawesome/free-solid-svg-icons/faPencilAlt";
import {faTrash} from "@fortawesome/free-solid-svg-icons/faTrash";
import {connect} from "react-redux";
import Paper from "@material-ui/core/Paper";
import {EnhancedPharmacyListHead, EnhancedToolbar, getSorting, stableSort, useStyles} from "../../../Utils/Tables/MuiTables";
import ReactToPdf from "react-to-pdf";
import ReactToPrint from "react-to-print";
import Table from "@material-ui/core/Table";
import FilterResults from "react-filter-search";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TablePagination from "@material-ui/core/TablePagination";
import DrugsAvailableTablePagination from "../../../Utils/Tables/MuiTablePagination";
import {compose} from "recompose";
import {withStyles} from "@material-ui/core/styles";
import PharmacySnackbar from "../../../Utils/Snackbars/SnackbarUtil";
import Dialog from "@material-ui/core/Dialog";
import {DialogContent, DialogActions, DialogTitle} from "../../../Utils/Dialog/pharmacyDialog";
import DashboardHeader from "../../../Containers/Dasboard/DashboardHeader";


class ManageDestination extends Component {
    constructor(props) {
        super(props);
        this.state = {
            term: "",
            order: 'asc',
            setOrder: 'asc',
            orderBy: 'person',
            selected: [],
            setSelected: [],
            page: 0,
            setPage: 0,
            dense: false,
            setDense: false,
            rowsPerPage: 5,
            category_id: '',
            openDialog: false
        };
        this.handler = this.handler.bind(this);
        this.ref = React.createRef();
    }

    handler(value) {
        this.setState({term: value})
    }

    handleRequestSort = (event, property) => {
        const orderBy = property;
        let order = 'desc';

        if (this.state.orderBy === property && this.state.order === 'desc') {
            order = 'asc';
        }

        this.setState({order, orderBy});
    };
    handleChangePage = (event, newPage) => {
        this.setState({page: newPage});
    };

    handleChangeRowsPerPage = (event) => {
        this.setState({rowsPerPage: event.target.value, page: 0});
    };

    componentDidMount() {
        // const {actions} = this.props;
        // actions.medicineActions.retrieveCategoryList();
    }

    handleCloseSnackbar = () => {
        const {actions} = this.props;
        actions.snackbarActions.hideSnackBar()

    };
    deleteCategory = category_id => {
        this.setState({openDialog: true, category_id: category_id})
    };

    handleDeleteCategory = () => {
        // const {category_id} = this.state;
        // const {actions} = this.props;
        // actions.medicineActions.deleteCategory(category_id);
        // this.setState({openDialog:false})

    };
    handleCloseDialog = () => {
        this.setState({openDialog: false});
    };

    render() {
        const managedesignationList = [
            {
                sl:1,
                designation:"Manager",
                details:""
            }
        ];
        const {order, dense, orderBy, selected, rowsPerPage, page, term, openDialog} = this.state;
        const {classes} = this.props;
        const emptyRows = rowsPerPage - Math.min(rowsPerPage, managedesignationList.length - page * rowsPerPage);
        const {open, message, variant} = this.props.snackbars;
        return (
            <div>
            <DashboardHeader hrm manageDesignation/>
            <div className="card styled-table-card p-3">
                <Dialog open={openDialog}>
                    <DialogTitle onClose={this.handleCloseDialog}>
                        <div className="text-center">
                            <strong>Delete Category</strong>
                        </div>
                    </DialogTitle>
                    <DialogContent>
                        <h6>Are you sure you want to delete this category ?</h6>
                    </DialogContent>
                    <DialogActions>
                        <button className="btn pharmacy-grey-btn mr-2" onClick={this.handleCloseDialog}>No, Keep
                            Category
                        </button>
                        <button className="btn pharmacy-btn-dismiss" onClick={this.handleDeleteCategory}>Yes, Delete
                            Category
                        </button>
                    </DialogActions>
                </Dialog>
                <PharmacySnackbar open={open} message={message} variant={variant}
                                  handleCloseBar={this.handleCloseSnackbar}/>
                 <h6>Manage Designation</h6>
                <hr/>
                <Paper className={`${classes.paper} table-responsive`}>
                    <EnhancedToolbar numSelected={selected.length} handler={this.handler} targetRef={this.ref}
                                     data={managedesignationList} managedesignation>
                        <ReactToPdf targetRef={this.componentRef} filename={"Category List.pdf"}
                        >
                            {({toPdf}) => (<button
                                className="btn pharmacy-btn mr-1 btn-sm " onClick={toPdf}>Pdf</button>)}
                        </ReactToPdf>
                        <ReactToPrint
                            trigger={() => <button
                                className="btn pharmacy-btn mr-1 btn-sm">Print</button>}
                            content={() => this.componentRef}
                        />
                    </EnhancedToolbar>
                    <div className={classes.tableWrapper} ref={this.ref}>
                        <Table
                            aria-labelledby="tableTitle"
                            size={dense ? 'small' : 'medium'}
                            // className={classes.table}
                            // id="section-to-print"
                            ref={el => (this.componentRef = el)}
                        >
                            <EnhancedPharmacyListHead
                                classes={classes}
                                numSelected={selected.length}
                                order={order}
                                orderBy={orderBy}
                                onRequestSort={this.handleRequestSort}
                                rowCount={managedesignationList.length}
                                managedesignation
                            />
                            <FilterResults
                                value={term}
                                data={managedesignationList}
                                renderResults={results => (
                                    <TableBody>
                                        {stableSort(results, getSorting(order, orderBy))
                                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                            .map((info, index) => {
                                                return (
                                                    <TableRow
                                                        hover
                                                        role="checkbox"
                                                        tabIndex={-1}
                                                        key={index}
                                                        className='mui-table-row'>
                                                        <TableCell component="th" scope="row" padding="default"
                                                                   className='mui-table-cell'>
                                                            <span>{info.sl}</span>
                                                        </TableCell>
                                                        <TableCell>{info.designation}</TableCell>
                                                        <TableCell>{info.details}</TableCell>
                                                        <TableCell id="mainbuttons">
                                                                <Link to={{
                                                                    pathname:`/editdesignation/${info.sl}`
                                                                }}>
                                                                    <button
                                                                        className="btn btn-sm pharmacy-info-btn mr-3">
                                                                        <FontAwesomeIcon
                                                                            icon={faPencilAlt}/></button>
                                                                </Link>

                                                                <button className="btn btn-sm pharmacy-btn-dismiss"
                                                                >
                                                                    <FontAwesomeIcon
                                                                        icon={faTrash}/></button>
                                                        </TableCell>
                                                    </TableRow>
                                                );
                                            })}
                                        {emptyRows > 0 && (
                                            <TableRow style={{height: 49 * emptyRows}}>
                                                <TableCell colSpan={6}/>
                                            </TableRow>
                                        )}
                                    </TableBody>
                                )}
                            />
                        </Table>
                    </div>
                    <TablePagination
                        rowsPerPageOptions={[5,10, 15, 25, 50, 100]}
                        count={managedesignationList.length}
                        rowsPerPage={rowsPerPage}
                        component="div"
                        page={page}
                        SelectProps={{
                            inputProps: {'aria-label': 'rows per page'},
                            native: true,
                        }}
                        onPageChange={this.handleChangePage}
                        onRowsPerPageChange={this.handleChangeRowsPerPage}
                        ActionsComponent={DrugsAvailableTablePagination}
                    />
                </Paper>
            </div>
            </div>

        )
    }

}

function mapStateToProps(state) {
    return {
        // medicines: state.medicines,
        snackbars: state.snackbars
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            // medicineActions: bindActionCreators(medicineActions, dispatch),
            //snackbarActions:bindActionCreators(snackbarActions, dispatch)
        }
    }
}

export default compose(connect(mapStateToProps, mapDispatchToProps), withStyles(useStyles))(ManageDestination);