import * as React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import {DialogContent, DialogTitle, DialogActions} from "./pharmacyDialog";
import CloseIcon from '@material-ui/icons/Close';
import {CircleButton} from "../styledComponents";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { makeStyles, useTheme } from '@material-ui/core/styles';


export const StyledCloseIcon = styled(CloseIcon)({
    width:'0.8em',
    height:'0.8em'
})



export const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0 }} {...other}>
            {children}
            {onClose ? (
                <CircleButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <StyledCloseIcon/>
                </CircleButton>
            ) : null}
        </DialogTitle>
    );
};

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};

const useStyles = makeStyles((theme) => ({
    paper: {
        margin: '22px',
        overflow:'initial'
    },
    scrollPaper:{
        alignItems:'flex-start'
    },
    content:{
        overflow:'initial',
    },
}));

function CustomDialog({open, handleClose,title, isInitial, children,maxWidth="sm", isOverflow=true, buttons=null, handlePrint=null}) {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const classes = useStyles();
    return (
        <Dialog
            // fullScreen={fullScreen}
            onClose={handleClose}
            aria-labelledby="customized-dialog-title"
            open={open}
            maxWidth={maxWidth}
            fullWidth={true}
            classes={{scrollPaper:classes.scrollPaper, paper: !isInitial ?  classes.paper : ''}}
        >
            <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                {handlePrint === null ?
                    <>{title}</> :
                    <div style={{display:'flex', justifyContent:'space-between', marginRight:'35px'}}>
                        <div>
                            {title}
                        </div>
                        <div>
                            <button onClick={handlePrint} className="btn pharmacy-amber-btn">Print</button>
                        </div>
                    </div>
                }
            </DialogTitle>
            <DialogContent className={isOverflow ? classes.content : ''}>
                {children}
            </DialogContent>
            <DialogActions>
                {buttons}
            </DialogActions>
        </Dialog>

    );
}
export default CustomDialog;
