/** @jsx jsx */ //please do not remove this implementation because the css will not be loaded without it
import React from "react";
import { jsx } from '@emotion/react'
import CompanyAddress from "../CompanyAddress";
import {table, textWeight, detailsTable, tableFooter, coloredThead, smallPadding} from '../../styles/tableStyles'

const ReceiptTemplate = ({customerDetails,itemDetails, footer}) => {
    return (
        <table border='0' width='100%'>
            <tbody>
                <tr>
                    <td>
            {/* <table css={[table]}> */}
                <CompanyAddress/>
                    <table css={[table,detailsTable, smallPadding]}>
                        {customerDetails}
                    </table>
                    {itemDetails}
                    <table css={[table, tableFooter]}>
                        {footer}
                    </table>
            {/* </table> */}
                    </td>
                </tr>
            </tbody>

        </table>
    )
};

function ItemsTable({children,headData=[],coloredTableHead= coloredThead}) {
    return(
        <table css={[table,detailsTable]}>
            <thead>
            <tr>
                {headData.map((item, index)=>(
                    <th css={[coloredThead,textWeight]} key={index}><span><strong>{item.value}</strong></span></th>
                ))}
            </tr>
            </thead>
            {children}
        </table>
    )
}

export {ReceiptTemplate,ItemsTable}
