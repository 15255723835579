import React, {useCallback, useEffect, useState} from 'react';
import axios from "axios";
import config from "../../../Helpers/config.json";
import { useCompany } from '../../../Context/company-context';
import { errorMessages } from '../../../Helpers/ErrorMessages';

export const usePaymentVoucherReceipt = (voucher_number, actions, entity_type) => {
    const [voucherDetails, setVoucherDetails] = useState({});
    const {state} = useCompany();
    useEffect(()=>{
        axios.get(`${config.epharmUrl}/accounting/vouchers/${voucher_number}`, {params:{entity_type}}).then(response => {
            const dt = response.data;
            const voucher_dets = dt.data ? dt.data : [];

            const voucher_date = voucher_dets[0]['date_added'];
            const voucher_type = voucher_dets[0]['voucher_type'];
            const vendor_name = voucher_dets[0]['name'] ? voucher_dets[0]['name'] : voucher_dets[0]['vendor_name'] ? voucher_dets[0]['vendor_name'] : '';
            let narrative = [];
            let total_voucher_amount = 0;
            voucher_dets.forEach(element => {
                total_voucher_amount += element['entry_amount'];
                narrative.push(element['entry_detail'])
            });
            const params = {
                total_voucher_amount,
                voucher_type,
                voucher_date,
                vendor_name,
                narrative
            }
            setVoucherDetails(params);
        }).catch(error => {
            errorMessages(error, null, actions);
        });
    },[])

    return {voucherDetails, state}
}

export const usePaymentVouchers = (actions) => {
    const [receiptDialog, setReceiptDialog] = useState(false);
    const [paymentVouchers, setPaymentVouchers] = useState([]);
    const [currentVoucherNumber, setCurrentVoucherNumber] = useState('');
    const [currentVoucherType, setCurrentVoucherType] = useState('');
    const [voucher_types, setVoucherTypes] = useState([]);
    const [loading, setLoading] = useState('idle');
    const [totalPageCount, setTotalPageCount] = useState(0);
    const [activePage, setActivePage] = useState(1);
    const [searchValue, setSearchValue] = useState('');
    const [filter_voucher_type, setFilterVoucherType] = useState('');
  
    const handleOpenReceiptDialog = (v_n, v_t) => {
        setCurrentVoucherNumber(v_n);
        setCurrentVoucherType(v_t);
        setReceiptDialog(true);
    }

    const handleCloseReceiptDialog = () => {
        setReceiptDialog(false);
        setCurrentVoucherNumber('');
        setCurrentVoucherType('');
    }

     useEffect(()=>{
        setLoading('loading')
        axios.get(`${config.epharmUrl}/accounting/vouchers/list`,{params: 
            {
                page_number:activePage, search:searchValue
            }}).then(response => {
            const dt = response.data;
            const res =  dt.data ?? {}
            const arr =  res.voucher_list ? res.voucher_list : []
            const total_count = res.total_count ? res.total_count : 0;
            setPaymentVouchers(arr);
            setTotalPageCount(total_count);
            setLoading('success');
        }).catch(error => {
            errorMessages(error, null, actions);
            setLoading('error');
        })

    }, [activePage, searchValue])



    const handleChange = (event) => {
        setSearchValue(event.target.value)
    };

    const handlePageChange = (pageNumber) => {
        setActivePage(pageNumber)
    };

    const handleChangeFilter = (value) =>{
        setFilterVoucherType(value)
        const formData = new FormData();
        formData.append('voucher_type', value.value);
        setLoading('loading');
        axios.post(`${config.epharmUrl}/accounting/vouchers/list`, formData).then(response => {
            const dt = response.data;
            const vouchersList = typeof(dt.data) === 'array' ? dt.data : [];
            setPaymentVouchers(vouchersList);
            setLoading('success');
        }).catch(error => {
            errorMessages(error, null, actions);
            setLoading('error');
        })
    }

    const isLoading = loading === 'loading'
    const isSuccess = loading === 'success'
    const isError = loading === 'error'

    return {
        receiptDialog, handleOpenReceiptDialog, paymentVouchers, handleCloseReceiptDialog, setCurrentVoucherNumber, setReceiptDialog,
        currentVoucherNumber, filter_voucher_type, handleChangeFilter, loading, voucher_types,
        totalPageCount,activePage, handlePageChange, handleChange, isLoading, isError, isSuccess,
        searchValue, currentVoucherType
    }
}