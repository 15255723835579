import React from "react";
import Dialog from "@material-ui/core/Dialog";
import {DialogContent, DialogTitle} from "./pharmacyDialog";
import { makeStyles } from "@material-ui/core";


const useStyles = makeStyles((theme) => ({
    paper:{
        overflow:'initial'
    }
}))

const ReusableDialog = ({openDialog, handleClose, message, handleSubmit, isSubmitted, children, width, formRef}) => {
    const classes = useStyles()
    return (
        <Dialog open={openDialog} maxWidth={width} fullWidth classes={{paper:classes.paper}}>
            <DialogTitle onClose={handleClose}>
                <div style={{fontSize: 15}} className="text-center">
                    {message}
                </div>
            </DialogTitle>
            <DialogContent classes={{root:classes.paper}}>
                <form onSubmit={handleSubmit} autoComplete="off" className="general-card" ref={formRef}>
                    {children}
                    <div className="text-center">
                        <button type="submit" className="btn btn-sm pharmacy-info-btn"
                                disabled={isSubmitted === 'pending'}>{isSubmitted === 'pending' ? 'Saving...' : 'Save'}</button>
                    </div>
                </form>
            </DialogContent>

        </Dialog>
    )

};
export default ReusableDialog;
