import React, { useState, useContext } from "react"

const SnackbarContext = React.createContext()
SnackbarContext.displayName = 'SnackbarContext'


function SnackbarProvider(props) {
    const [state, setState] = useState({open:false, variant:'', message:''})

    const handleSuccess = (message) =>{
        setState({open:true,variant:'success', message})
    }

    const handleInformation = (message) =>{
        setState({open:true,variant:'info', message})
    }

    const handleWarning = (message) =>{
        setState({open:true,variant:'warning', message})
    }

    const handleError = (message) =>{
        setState({open:true,variant:'error', message})
    }

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setState({...state, open:false})
    };

    const value = {state, handleSuccess,  handleInformation, handleWarning,handleError,
        handleClose}
    return <SnackbarContext.Provider value={value} {...props} />

}

function useSnackbar() {
    const context = useContext(SnackbarContext)
    if (context === undefined) {
        throw new Error(`useSnackbar must be used within a SnackbarProvider`)
    }
    return context
}

export {SnackbarProvider, useSnackbar}