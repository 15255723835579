import React, {useEffect, useState} from "react";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import PharmacySnackbar from "../../Utils/Snackbars/SnackbarUtil";
import {titleCase} from "../../Utils/titleCaseFunction";
import axios from 'axios';
import config from "../../Helpers/config.json"
import {errorMessages} from "../../Helpers/ErrorMessages";
import TextField from "../../Utils/FormInputs/TextField";
import {CustomSelect} from "../../Utils/FormInputs/SelectInput";
import ReusableDashboardHeader from "../../Containers/Dasboard/ReusableDashboardHeader";
import ReusableDialog from "../../Utils/Dialog/ReusableDialog";
import {EnhancedTableHead, EnhancedTableToolbar, MuiPagination, MuiPaper} from "../../Utils/Tables/CustomTable";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import FilterResults from "react-filter-search";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import * as snackbarActions from '../../Redux/Actions/SnackbarActions/SnackbarActions'
import {useMuiPagination} from "../../Containers/Dasboard/DashboardTables";


const AssignUserRole = ({actions, snackbars}) => {
    const [user_id, setUserId] = useState('');
    const [user_type, setUserType] = useState('');
    const [userRoles, setUserRoles] = useState([]);
    const [password, setPassword] = useState('');
    const [email, setEmail] = useState('');
    const [submitted, setSubmitted] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState('idle');
    const [loading, setLoading] = useState(false);
    const [users, setUsers] = useState([]);
    const [roles, setRoles] = useState([]);
    const [openDialog, setOpenDialog] = useState(false);
    const [term, setTerm] = useState('')
    const {page, rowsPerPage, handleChangeRowsPerPage, handleChangePage} = useMuiPagination();

    const dep = isSubmitted === 'resolved';
    useEffect(() => {
        setLoading(true)
        axios.get(`${config.epharmUrl}/Permission/user_roles_list`).then(res => {
            const data = res.data;
            const dt = data ? data : {};
            const list = dt.user_roles_list ? dt.user_roles_list : [];
            setUserRoles(list);
            setLoading(false)
        }).catch(err => {
            errorMessages(err, null, actions)
            setLoading(false)
        })
    }, [actions, dep])

    useEffect(() => {
        axios.get(`${config.epharmUrl}/User/manage_user`).then(response => {
            const data = response.data
            const dt = data ?? {};
            const list = dt.user_list ?? [];
            setUsers(list);
        }).catch(() => {
            //errorMessages(error, null, actions)
        })
    }, [])

    useEffect(() => {
        axios.get(`${config.epharmUrl}/Permission/role_list`).then(response => {
            const data = response.data
            const dt = data ?? {};
            const list = dt.user_list ?? [];
            setRoles(list);
        }).catch(() => {
            //errorMessages(error, null, actions)
        })
    }, [])

    const handleSearch = (value) =>{
        setTerm(value)
    }

    const handleOpenDialog = () => {
        setOpenDialog(true)
    }

    const handleCloseDialog = () => {
        setOpenDialog(false)
    }

    const handleChangeUser = (value) => {
        setUserId(value)
        axios.post(`${config.epharmUrl}/User/user_update_form`, null, {params: {user_id: value.value}}).then(response => {
            const data = response.data;
            const dt = data ? data : {};
            const {email: mail} = dt;
            setEmail(mail)
        }).catch(() => {
        })
    };

    const handleChangeRole = (value) => {
        setUserType(value)
    };

    const handleChangePassword = (event) => {
        setPassword(event.target.value)
    }

    const assignUserRole = (event) => {
        event.preventDefault();
        const formData = new FormData();
        const user = JSON.parse(sessionStorage.getItem('user'));
        const singleUser = !user ? {} : user;
        const created_by = singleUser.user_id ? singleUser.user_id : ''
        setSubmitted(true)
        if (user_id && user_type && password) {
            formData.append('user_id', user_id.value);
            formData.append('user_type', user_type.value);
            formData.append('create_by', created_by);
            formData.append('password', password);
            formData.append('email', email);
            setIsSubmitted('pending')
            axios.post(`${config.epharmUrl}/Permission/usercreate`, formData).then(res => {
                if (res.data === "saved_successfully") {
                    actions.snackbarActions.successSnackbar('User role assigned successfully');
                } else {
                    actions.snackbarActions.errorSnackbar('User has already been assigned a role');
                }
                setIsSubmitted('resolved')
                setOpenDialog(false)
            }).catch((error) => {
                errorMessages(error, null, actions)
                setIsSubmitted('rejected')
            })
        }
    };

    const handleCloseBar = () => {
        actions.snackbarActions.hideSnackBar();
    };

    const {open, message, variant} = snackbars;
    const allUsers = !users ? [] : users;
    const allRoles = !roles ? [] : roles;
    const user = JSON.parse(sessionStorage.getItem('user'));
    const singleUser = !user ? {} : user;
    const permissions = !singleUser.permission ? {} : singleUser.permission;
    const user_assign_role = !permissions.user_assign_role ? {} : permissions.user_assign_role;
    const headData = [
        {id: 'user', numeric: false, disablePadding: false, label: 'User'},
        {id: 'role', numeric: false, disablePadding: false, label: 'Role'},
    ];
    return (
        <div className='journals'>
            <ReusableDashboardHeader component='User Roles' heading="Settings"
                                     subHeading='User Roles' link='/assignuserrole'/>
            <div className="general-ledger-header">
                <div className="text-right">
                    {user_assign_role.create ? <button type='button' onClick={handleOpenDialog}
                                                       className='btn btn-sm pharmacy-info-btn'>Assign Role
                    </button> : null}
                </div>
            </div>
            <PharmacySnackbar open={open} message={message} variant={variant}
                              handleCloseBar={handleCloseBar}/>
            <ReusableDialog message='Assign Role' openDialog={openDialog}
                            handleSubmit={assignUserRole} isSubmitted={isSubmitted}
                            handleClose={handleCloseDialog}
                            width='sm'>
                <Form.Group as={Row}>
                    <Form.Label column sm="2" htmlFor="user">User <span
                        className="asterics text-danger">*</span></Form.Label>
                    <Col sm="9">
                        <CustomSelect submitted={submitted}
                            value={user_id} id='user'
                            onChange={handleChangeUser}
                            options={allUsers.map(user => ({
                                label: `${titleCase(user.first_name)} ${titleCase(user.last_name)}`,
                                value: user.user_id
                            }))}
                        />
                        {submitted && !user_id ?
                            <div className='invalid-text'>User is required</div> : null}
                    </Col>
                </Form.Group>
                <Form.Group as={Row}>
                    <Form.Label htmlFor="email" column sm="2">Email</Form.Label>
                    <Col sm="9">
                        <TextField type='email' value={email} readOnly id='email'/>
                    </Col>
                </Form.Group>

                <Form.Group as={Row}>
                    <Form.Label column sm="2" htmlFor='password'>Password<span
                        className="asterics text-danger">*</span></Form.Label>
                    <Col sm="9">
                        <TextField submitted={submitted} type='password' value={password} onChange={handleChangePassword}
                                   placeholder='Enter password' id='password'/>
                        {submitted && !password ?
                            <div className='invalid-text'>Password is required</div> : null}
                    </Col>
                </Form.Group>

                <Form.Group as={Row}>
                    <Form.Label column sm="2" htmlFor='role'>Role <span
                        className="asterics text-danger">*</span></Form.Label>
                    <Col sm="9">
                        <CustomSelect submitted={submitted}
                            value={user_type} id='role'
                            onChange={handleChangeRole}
                            options={allRoles.map(role => ({
                                label: titleCase(role.type),
                                value: role.id
                            }))}
                        />
                        {submitted && !user_type ?
                            <div className='invalid-text'>Role is required</div> : null}
                    </Col>
                </Form.Group>
            </ReusableDialog>
            <MuiPaper>
                <EnhancedTableToolbar title={''}>
                    <Form.Control type="text"
                                  onChange={event => handleSearch(event.target.value)}
                                  className='form__control'
                                  placeholder="Search" style={{float: 'right'}}/>
                </EnhancedTableToolbar>
                <TableContainer>
                    <Table
                        aria-labelledby="tableTitle"
                        aria-label="enhanced table"
                    >
                        <EnhancedTableHead headCells={headData}/>
                        {loading ? <TableBody><TableRow>
                            <TableCell colSpan="4" align="center"><img src="/images/listPreloader.gif"
                                                                       alt="loader"
                                                                       style={{width: '5%'}}/></TableCell>
                        </TableRow></TableBody> : userRoles.length > 0 ?
                            <FilterResults
                                value={term}
                                data={userRoles}
                                renderResults={results => (
                                    <TableBody>
                                        {results.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                            .map((d) => {
                                                return (
                                                    <TableRow
                                                        hover
                                                        role="checkbox"
                                                        tabIndex={-1}
                                                        key={d.user_roles_id}
                                                        className='mui-table-row'>
                                                        <TableCell>
                                                            <span>{`${titleCase(d.first_name)} ${titleCase(d.last_name)}`}</span>
                                                        </TableCell>
                                                        <TableCell>
                                                            <span>{d.type}</span>
                                                        </TableCell>
                                                    </TableRow>
                                                );
                                            })}
                                    </TableBody>
                                )}
                            />
                            : <TableBody><TableRow>
                                <TableCell colSpan="4" align="center">No records found</TableCell>
                            </TableRow></TableBody>}
                    </Table>
                </TableContainer>
                <MuiPagination data={userRoles} rowsPerPage={rowsPerPage} page={page}
                               handleChangeRowsPerPage={handleChangeRowsPerPage}
                               handleChangePage={handleChangePage} rows={[10, 20, 30, 50, 100]} colSpan={3}/>
            </MuiPaper>
        </div>
    )
}

function mapStateToProps(state) {
    return {
        snackbars: state.snackbars
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            snackbarActions: bindActionCreators(snackbarActions, dispatch)
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AssignUserRole);