import React from 'react';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Label from "../../../Utils/FormInputs/Label";
import {CustomSelect} from "../../../Utils/FormInputs/SelectInput";
import TextField from "../../../Utils/FormInputs/TextField";
import { ErrorMessage } from '../../../Utils/styledComponents';

export const ReusableNewCOA = ({accountType, defaultAccounts, handleChangeAccountType, submitted, handleChangeState,
                                    subAccounts, parentAccount, handleChangeParentAccount,
                                    entryType, handleChangeEntryType,
                                    entryTypes, state}) =>{     
    const {account_name, opening_balance, isSubAccount, balance_date, isTransactionAccount} = state;
    const accType = accountType ? accountType : {}
    const item = accType.value ? accType.value : ''
    const category_name = item.split('-')[2]
    const isExpense = category_name === 'EXPENDITURE'
    return(
        <div>
            <Form.Group as={Col}>
                <Label name='Account type' type />
                {/* <Col sm={8}> */}
                   <CustomSelect value={accountType} options={defaultAccounts}
                                  onChange={handleChangeAccountType} submitted={submitted}/>
                    {(submitted && !accountType) &&
                    <ErrorMessage>Account type is required</ErrorMessage>}
                {/* </Col> */}
            </Form.Group>
            <Form.Group as={Col}>
                <Label name='Account name' type/>
                {/* <Col sm={8}> */}
                    <TextField onChange={handleChangeState} value={account_name} name='account_name'
                               type='text' submitted={submitted}/>
                    {(submitted && !account_name) &&
                    <ErrorMessage>Account name is required</ErrorMessage>}
                {/* </Col> */}
            </Form.Group>
            {/* {(accountType && subAccounts.length > 0) ?  */}
            {/* <Form.Group as={Col}> */}
                {/* <Col sm={4}/> */}
                {/* <Col sm={8}> */}
                    {/* <Form.Check type='checkbox' label='Is sub-account' name='isSubAccount'
                                value={isSubAccount}
                                onChange={handleChangeState}/> */}
                {/* </Col> */}
            {/* </Form.Group>  */}
            {/* // : null} */}

            {/* {isSubAccount ?
                <> */}
                    <Form.Group as={Col}>
                        <Label name='Parent account' type />
                        {/* <Col sm={8}> */}
                            <CustomSelect value={parentAccount} onChange={handleChangeParentAccount}
                                          options={subAccounts} isDisabled={!(accountType)} submitted={submitted}/>
                            {(submitted && isSubAccount && !parentAccount) &&
                            <ErrorMessage>This field is required</ErrorMessage>}
                        {/* </Col> */}
                    </Form.Group>
                    <Form.Group as={Col}>
                        <Form.Check type='checkbox' label='Is transaction account' name='isTransactionAccount'
                                value={isTransactionAccount}
                                onChange={handleChangeState}/>
             
                    </Form.Group>
                    {isTransactionAccount && !isExpense ? <div className='row'>
                        <div className='col-lg-6'>
                            <Form.Group as={Col}>
                                <Label name='Opening balance' type/>
                                {/* <Col sm={8}> */}
                                    <TextField onChange={handleChangeState} value={opening_balance}
                                            name='opening_balance' type='number' disabled={!isTransactionAccount}/>
                                    {/* {(submitted && isTransactionAccount && !opening_balance) &&
                                    <ErrorMessage>This field  is required</ErrorMessage>} */}
                                {/* </Col> */}
                            </Form.Group>
                        </div>
                        <div className='col-lg-6'>
                            <Form.Group as={Col}>
                                <Label name='As of' type/>
                                {/* <Col sm={8}> */}
                                    <TextField onChange={handleChangeState} value={balance_date} submitted={submitted}
                                            name='balance_date' type='date' disabled={!isTransactionAccount}/>
                                    {(submitted && isTransactionAccount && !balance_date) &&
                                    <ErrorMessage>This field is required</ErrorMessage>}
                                {/* </Col> */}
                            </Form.Group>
                        </div>
                    </div>: null}
                    
                    {isTransactionAccount && !isExpense ? <Form.Group as={Col}>
                        <Label name='Entry type' type />
                        {/* <Col sm={8}> */}
                         <CustomSelect value={entryType} onChange={handleChangeEntryType} submitted={submitted}
                                          options={entryTypes} isDisabled={!isTransactionAccount}/>
                            {(submitted && isTransactionAccount && !entryType) &&
                            <ErrorMessage>This field is required</ErrorMessage>}
                        {/* </Col> */}
                    </Form.Group> : null}
                {/* </> : null} */}
        </div>
    )
}

