import React, {useCallback, useEffect, useState} from "react";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import * as snackbarActions from "../../../Redux/Actions/SnackbarActions/SnackbarActions"
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import PharmacySnackbar from "../../../Utils/Snackbars/SnackbarUtil";
import ReusableDashboardHeader from "../../../Containers/Dasboard/ReusableDashboardHeader";
import {history} from "../../../Helpers/history";
import {BackButton} from "../../../Utils/FormInputs/BackButton";
import {CenteredContainer, Container, RightAlignedContainer} from "../../../Utils/styledComponents"
import TableHead from '@material-ui/core/TableHead';
import {
    CustomPagination,
    EnhancedTableHead,
    EnhancedTableToolbar,
    MuiPagination
} from "../../../Utils/Tables/CustomTable";
import axios from "axios";
import config from "../../../Helpers/config.json";
import {errorMessages} from "../../../Helpers/ErrorMessages";
import {CustomTableCell, StickyHeadTable,CustomHeadCell} from "../../../Utils/Tables/StickyHeaderTable";
import {LoadingGif} from "../../../Utils/Loader";
import { MuiPaper } from "../../../Utils/Tables/CustomTable";
import { useExcelReports } from "../../Invoice/CustomHooks/useExcelReports";
import styled from "@emotion/styled/macro";
import * as colors from "../../../styles/colors"
import Grid from "@material-ui/core/Grid";
import Form from "react-bootstrap/Form"
import Col from "react-bootstrap/Col"
import Row from "react-bootstrap/Row"
import Label from "../../../Utils/FormInputs/Label";
import TextField from "../../../Utils/FormInputs/TextField";
import {dateConvert} from "../../../Utils/ConvertDate";
import {titleCase} from "../../../Utils/titleCaseFunction";
import {CustomSelect} from "../../../Utils/FormInputs/SelectInput";
import {makeStyles} from "@material-ui/core/styles";
import {formatDigits} from "../../../Utils/formatAmount";
import {useMuiPagination} from "../../../Containers/Dasboard/DashboardTables";

const BorderedCell = styled(CustomTableCell)({
    borderRight: `1px solid ${colors.gray500}`,
})

const useStyles =  makeStyles((theme)=>({
    borderCell:{
        borderRight: `1px solid ${colors.gray500}`
    }
}));



const headData = [
    {id: 'date', numeric: false, disablePadding: false, label: 'Particulars', isBorder:true},
    {id: 'opening_bal_qty', numeric: false, disablePadding: false, label: 'Quantity'},
    {id: 'opening_bal_rate', numeric: false, disablePadding: false, label: 'Rate'},
    {id: 'opening_bal_value', numeric: false, disablePadding: false, label: 'Value', isBorder: true},
    {id: 'quantity_in_bal_qty', numeric: false, disablePadding: false, label: 'Quantity'},
    {id: 'quantity_in_bal_rate', numeric: false, disablePadding: false, label: 'Rate'},
    {id: 'quantity_in_bal_value', numeric: false, disablePadding: false, label: 'Value', isBorder: true},
    {id: 'quantity_in_bal_qty', numeric: false, disablePadding: false, label: 'Quantity'},
    {id: 'quantity_in_bal_rate', numeric: false, disablePadding: false, label: 'Rate'},
    {id: 'quantity_in_bal_value', numeric: false, disablePadding: false, label: 'Value', isBorder: true},
    {id: 'quantity_out_qty', numeric: false, disablePadding: false, label: 'Quantity'},
    {id: 'quantity_out_rate', numeric: false, disablePadding: false, label: 'Rate'},
    {id: 'quantity_out_value', numeric: false, disablePadding: false, label: 'Value', isBorder: true},
    {id: 'quantity_in_bal_qty', numeric: false, disablePadding: false, label: 'Quantity'},
    {id: 'quantity_in_bal_rate', numeric: false, disablePadding: false, label: 'Rate'},
    {id: 'quantity_in_bal_value', numeric: false, disablePadding: false, label: 'Value', isBorder: true},
    {id: 'closing_bal_qty', numeric: false, disablePadding: false, label: 'Quantity'},
    {id: 'closing_bal_rate', numeric: false, disablePadding: false, label: 'Rate'},
    {id: 'closing_bal_value', numeric: false, disablePadding: false, label: 'Value'},
];

const parentHeadData = [
    {id: 'opening_stock', numeric: false, disablePadding: false, label: 'Opening Stock', colSpan:3},
    {id: 'quantity_in', numeric: false, disablePadding: false, label: 'Stock In',colSpan:6},
    {id: 'quantity_out', numeric: false, disablePadding: false, label: 'Stock Out',colSpan:6},
    {id: 'closing_stock', numeric: false, disablePadding: false, label: 'Closing Stock',colSpan:3},
];



const StockSummary = ({actions, snackbars}) => {
    const classes = useStyles()
    const storage = JSON.parse(sessionStorage.getItem('total_count'))
    const dataArr = JSON.parse(sessionStorage.getItem('stock'))
    const item_count = storage ? storage : 0
    const stockArr = dataArr ? dataArr : []
    const stockItems  = () => stockArr || []
    const count = item_count;  //() => +item_count || 0
    const [data, setData] = useState({loading:'idle', stockList:stockItems, total_count:count})
    const [activePage, setActivePage] = useState(1);
    const [recordsPerPage, setRecordsPerPage] =   useState(10)
    const [date, setDate] = useState({start_date:dateConvert(), end_date:dateConvert()})
    const [submitted, setSubmitted] = useState(false)
    const [isSubmitted, setIsSubmitted] = useState('idle')
    const [category_id, setCategoryId] = useState(null);
    const [categoryList, setCategories] = useState([])
    const [totalPages, setTotalPages] = useState(0)
    const [total_summary, setTotalSummary] = useState({total:{}, loadingTotal:'idle'})
    const {loading, stockList} = data;
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [searchValue, setSearchValue] = useState('')
    const {start_date, end_date} = date;

    const category = category_id?.value === '' ? {} : {category_id:category_id?.value}


    useEffect(() => {
        const unlisten = history.listen(()=>{
            sessionStorage.removeItem('stock')
            sessionStorage.removeItem('total_count')
        });

        return unlisten;

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const retrieveReport = useCallback(( st_dte, ed_dte, isSubmit=false) =>{
        setSubmitted(true)
        // if(s_date && en_date){
            isSubmit && setIsSubmitted('pending')
            setData({...data, loading: 'loading'})
            axios.get(`${config.epharmUrl}/Creport/stock_summary`,
                {params:{start_date: st_dte, end_date:ed_dte, page_number:activePage, ...category , search:searchValue}}
            ).then(response => {
                const res = response.data;
                const dt = !res ? {} : res;
                const arr = !dt.stock_query ? [] : dt.stock_query;
                const total_pages = dt.total_pages ? dt.total_pages : 0;
                const stock_count = dt.length_of_query ? dt.length_of_query : 0;
                const total_stock_summary = dt.total_summary ? dt.total_summary : 0
                if(activePage === 1){
                    sessionStorage.setItem('total_count', JSON.stringify(stock_count))
                    setData({ loading:'success', total_count:stock_count, stockList:arr})
                    sessionStorage.setItem('stock', JSON.stringify(arr))
                }else {
                    const itemsArr = arr;//[...stockItems(),...arr]
                    sessionStorage.setItem('stock', JSON.stringify(itemsArr))
                    setData({ loading:'success', total_count:count, stockList:itemsArr})
                }
                 isSubmit && setIsSubmitted('resolved')
                setTotalPages(total_pages)
                //setTotalSummary(total_stock_summary)
            }).catch(error => {
                errorMessages(error, null, actions)
                setData({...data, loading: 'error'})
                isSubmit && setIsSubmitted('rejected')
            })
        // }

    }, [activePage, category_id, searchValue])

    const retrieveTotals = useCallback(( st_dte, ed_dte) =>{
        setTotalSummary({...total_summary, loadingTotal: 'loading'})
        axios.get(`${config.epharmUrl}/Creport/stock_summary_totals`,
            {params:{start_date: st_dte, end_date:ed_dte, ...category, search:searchValue}}
        ).then(response => {
            const res = response.data;
            const dt = !res ? {} : res;
            const total_stock_summary = dt.total_summary ? dt.total_summary : 0
            setTotalSummary({total: total_stock_summary, loadingTotal: 'success'})
        }).catch(error => {
            errorMessages(error, null, actions)
            setTotalSummary({...total_summary, loadingTotal: 'error'})
        })

    }, [category_id,searchValue])

    useEffect(()=>{
        axios.get(`${config.epharmUrl}/Ccategory/manage_category`).then(res => {
            const data = res.data;
            const dt = data ? data : {};
            const categories = dt.category_list ? dt.category_list : []
            const arr = categories.map(category => ({
                value: category.category_id,
                label: titleCase(category.category_name)
            }))
            const categoryArr = [{value:'', label:'All'}, ...arr]
            setCategories(categoryArr)
        }).catch(error => {
            errorMessages(error, null,actions)
        })
    },[])


    useEffect(() => {
        retrieveTotals(start_date, end_date)
    }, [retrieveTotals])

    useEffect(() => {
        retrieveReport(start_date, end_date)
    }, [retrieveReport])

    const handleChangeDate = (event) =>{
        const {name, value} = event.target;
        setDate({...date,[name]:value})
    };

    const handleChange = (event) =>{
        setSearchValue(event.target.value)
    }

    const handleSubmit = (event) =>{
        event.preventDefault()
        setActivePage(1)
        setPage(0);
        retrieveReport(start_date, end_date, 'true')
        retrieveTotals(start_date, end_date)
    }


    const handleChangeActivePage = (new_page) =>{
        setActivePage(new_page + 1)
        //setRecordsPerPage(new_pg + 10)
        //setActivePage(prev=>prev + 1)
        //setRecordsPerPage(prev=>prev + 10)
    }

    const handleChangeCategory = (value) =>{
        setCategoryId(value)
        setActivePage(1)
        setPage(0)
    }

    const closeSnackbar = () =>{
        actions.snackbarActions.hideSnackBar();
    };

    const {total, loadingTotal} = total_summary

    const isLoading = loading === 'loading'
    const isSuccess = loading === 'success'
    const isError = loading === 'error'

    const isLoadingTotal = loadingTotal === 'loading'
    const isSuccessTotal = loadingTotal === 'success'
    const isErrorTotal = loadingTotal === 'error'

    const isPending = isSubmitted === 'pending'
    const isResolved = isSubmitted === 'resolved'
    // const isRejected = isSubmitted === 'rejected'

    const {open, variant, message} = snackbars;


    const currentObj = {file_name:'Stock Summary', url:'Creport/stock_summary_excel_export', params:{start_date, end_date, ...category}}

    const {exportExcel, isLoading:isLoadingExcel} = useExcelReports(actions,currentObj);



    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        handleChangeActivePage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        //setRecordsPerPage(parseInt(event.target.value, 10));
        setRowsPerPage(parseInt(event.target.value, 10));
        //setPage(0);
    };

    const totalHeadDataLabels = [
        {id: 'label_name', numeric: false, disablePadding: false, label: '', isBorder:true},
        {id: 'grand_total', numeric: false, disablePadding: false, label: '', isBorder:true,colSpan: 3},
        {id: 'stock_in_purchase', numeric: false, disablePadding: false, label: 'Purchases', isBorder:true, colSpan: 3},
        {id: 'stock_in_positive_adjustment', numeric: false, disablePadding: false, label: 'Positive Adjustments', isBorder:true, colSpan: 3},
        {id: 'stock_out_sale', numeric: false, disablePadding: false, label:'Sales', isBorder: true,colSpan: 3},
        {id: 'stock_out_negative_adjustment', numeric: false, disablePadding: false, label:'Negative Adjustments', isBorder: true,colSpan: 3},
        {id: 'closing_stock', numeric: false, disablePadding: false, label:'', isBorder: true,colSpan: 3}
    ];

    const totalHeadDataAmount = [
        {id: 'amount_name', numeric: false, disablePadding: false, label: '', isBorder:true},
        {id: 'amount_name', numeric: false, disablePadding: false, label: total?.total_opening_qty ? formatDigits(total?.total_opening_qty):0, isBorder:true},
        {id: 'opening_bal_total_amount', numeric: false, disablePadding: false, label: total?.total_opening_value ? formatDigits(total?.total_opening_value):0, colSpan:2,isBorder:true},
        {id: 'amount_name', numeric: false, disablePadding: false, label: total?.total_stock_in_qty ? formatDigits(total?.total_stock_in_qty):0, isBorder:true},
        {id: 'stock_in_purchase_amount', numeric: false, disablePadding: false, label: total?.total_stock_in_value ? formatDigits(total?.total_stock_in_value):0, isBorder: true,colSpan: 2},
        {id: 'amount_name', numeric: false, disablePadding: false, label:  total?.total_stock_in_adjusted_qty ? formatDigits(total?.total_stock_in_adjusted_qty):0, isBorder:true},
        {id: 'stock_in_purchase', numeric: false, disablePadding: false, label:  total?.total_stock_in_adjusted_value ? formatDigits(total?.total_stock_in_adjusted_value):0, isBorder:true, colSpan: 2},
        {id: 'amount_name', numeric: false, disablePadding: false, label: total?.total_stock_out_qty ? formatDigits(total?.total_stock_out_qty):0, isBorder:true},
        {id: 'stock_out_sale_amount', numeric: false, disablePadding: false, label: total?.total_stock_out_value ? formatDigits(total?.total_stock_out_value):0, isBorder:true, colSpan: 2},
        {id: 'amount_name', numeric: false, disablePadding: false, label: total?.total_stock_out_adjusted_qty ? formatDigits(total?.total_stock_out_adjusted_qty):0, isBorder:true},
        {id: 'stock_out_negative_adjustment_amount', numeric: false, disablePadding: false, label: total?.total_stock_out_adjusted_value ? formatDigits(total?.total_stock_out_adjusted_value):0, isBorder:true, colSpan: 2},
        {id: 'amount_name', numeric: false, disablePadding: false, label: total?.total_closing_qty ? formatDigits(total?.total_closing_qty):0, isBorder:true},
        {id: 'closing_stock_amount', numeric: false, disablePadding: false, label: total?.total_closing_value ? formatDigits(total?.total_closing_value):0, isBorder:true, colSpan: 2},
    ];


    const parentHead = (
        <TableRow>
            <CustomHeadCell/>
            {parentHeadData.map((column) => (
                    <CustomHeadCell
                        key={column.id}
                        align='center'
                        style={{ minWidth: column.minWidth }}
                        // className={{root:{}}}
                        colSpan={column.colSpan}

                    >
                        {column.label}
                    </CustomHeadCell>
                ))}
        </TableRow>
    )

    const totalHead = (
        <React.Fragment>
            <TableRow  aria-label="sticky table">
                {totalHeadDataLabels.map((column) => (
                    <CustomTableCell
                        aria-label="sticky table"
                        key={column.id}
                        align='center'
                        colSpan={column.colSpan}
                        style={{ minWidth: column.minWidth,top: 114,  paddingTop:'4px', paddingBottom:'4px' }}
                        className={column.isBorder ? classes.borderCell : null}
                    >
                        <strong>{column.label}</strong>
                    </CustomTableCell>
                ))}
            </TableRow>
            {isLoadingTotal ? <TableRow><CustomTableCell align='center' colSpan={headData.length}><strong>Loading totals....</strong></CustomTableCell></TableRow>: null}
            {isSuccessTotal ?  <TableRow  aria-label="sticky table">
                {totalHeadDataAmount.map((column) => (
                    <CustomTableCell
                        aria-label="sticky table"
                        key={column.id}
                        align='center'
                        colSpan={column.colSpan}
                        style={{ minWidth: column.minWidth,top: 114,  paddingTop:'4px', paddingBottom:'4px' }}
                        className={column.isBorder ? classes.borderCell : null}
                    >
                        <strong>{column.label}</strong>
                    </CustomTableCell>
                ))}
            </TableRow> :  null}
            {isErrorTotal ? <TableRow><CustomTableCell align='center' colSpan={headData.length}>The server did not return a valid response</CustomTableCell></TableRow>: null}
        </React.Fragment>

    )

    return (
        <div>
            <ReusableDashboardHeader component="Stock Summary" heading="Stock Summary"
                                     subHeading="Stock Summary" link={history.location.pathname}/>
            <Container>
                <div className='mb-3'>
                    <BackButton text='Reports' to='/allreports'/>
                </div>
                <div className="general-ledger-header mb-3">
                    <Grid container spacing={0}>
                        <Grid item xs={6} md={8}>
                            <form onSubmit={handleSubmit}>
                                <Form.Group as={Row} style={{marginBottom: '-1%'}}>
                                    <Col sm={4}>
                                        <Form.Group as={Row}>

                                            <Label name='From' column sm={2} style={{textAlign: 'right'}}/>
                                            <Col sm={10}>
                                                <TextField type='date' value={start_date} onChange={handleChangeDate}
                                                           name='start_date' submitted={submitted}/>
                                                {submitted && !start_date ? <div className='help-block'>From date is required</div>:null}
                                            </Col>
                                        </Form.Group>
                                    </Col>

                                    <Col sm={4}>
                                        <Form.Group as={Row}>
                                            <Label name='To' column sm={2}/>
                                            <Col sm={10}>
                                                <TextField type='date' name="end_date" value={end_date}
                                                           onChange={handleChangeDate} submitted={submitted}/>
                                                {submitted && !end_date ? <div className='help-block'>To date is required</div>:null}
                                            </Col>
                                        </Form.Group>
                                    </Col>
                                    <Col sm={1}>
                                        <button type='submit' disabled={isPending || isLoadingExcel} className='btn btn-sm pharmacy-btn-outline'>{isPending ? 'Filtering...' : 'Filter'}</button>
                                    </Col>
                                </Form.Group>
                            </form>
                        </Grid>

                        <Grid item xs={6} md={4}>
                        </Grid>
                    </Grid>
                </div>
                <PharmacySnackbar open={open} message={message} variant={variant} handleCloseBar={closeSnackbar}/>
                <div className='row'>
                    <div className='col-lg-6'>
                        <Form.Group as={Row}>
                            <Label name='Category' column sm={2}/>
                            <Col sm={10}>
                               <CustomSelect value={category_id} onChange={handleChangeCategory} options={categoryList}/>
                            </Col>
                        </Form.Group>
                    </div>
                    <div className='col-lg-6'>
                        <RightAlignedContainer>
                            <button type="button" onClick={exportExcel} disabled={isLoadingExcel} className="btn btn-sm pharmacy-info-btn mb-2">{ isLoadingExcel ? 'Exporting....':'Export to Excel'}</button>
                        </RightAlignedContainer>
                    </div>
                </div>
                <MuiPaper>
                    <EnhancedTableToolbar title=''>
                        <TextField type='text' placeholder='Search...' value={searchValue} onChange={handleChange}/>
                    </EnhancedTableToolbar>
                <TableContainer>
                        <Table
                            aria-labelledby="tableTitle"
                            size='medium'
                            aria-label="enhanced table"
                        >
                            <TableHead>
                                {parentHead}
                                {totalHead}
                                <TableRow  aria-label="sticky table">
                                {headData.map((column) => (
                                    <CustomTableCell
                                    aria-label="sticky table"
                                    key={column.id}
                                    align={column.align}
                                    style={{ minWidth: column.minWidth,top: 57}}
                                    className={column.isBorder ?  classes.borderCell : null}
                                    // classes={{stickyHeader:classes.stickyHeader}}
                                    // sx={{  }}
                                    >
                                    <strong>{column.label}</strong>
                                    </CustomTableCell>
                                ))}
                                </TableRow>

                            </TableHead>
                            <TableBody>
                                {isLoading && activePage === 1 ? <TableRow><TableCell align='center' {...{colSpan:headData.length}}><LoadingGif/></TableCell></TableRow>:null}
                                {isSuccess ? stockList.length > 0 ?
                                    <>
                                        {/*{stockList.length > 0 ? totalHead: null}*/}
                                        {stockList.slice(0, recordsPerPage).map((row, index) => {
                                            return (
                                                <TableRow  tabIndex={-1} key={index}>
                                                    <BorderedCell>{row.product_name}</BorderedCell>
                                                    <CustomTableCell>{row?.stock_details?.opening_stock?.quantity}</CustomTableCell>
                                                    <CustomTableCell>{formatDigits(row?.stock_details?.opening_stock?.rate)}</CustomTableCell>
                                                    <BorderedCell >{formatDigits(row?.stock_details?.opening_stock?.value)}</BorderedCell>
                                                    <CustomTableCell>{row?.stock_details?.stock_in?.quantity}</CustomTableCell>
                                                    <CustomTableCell>{formatDigits(row?.stock_details?.stock_in?.rate)}</CustomTableCell>
                                                    <BorderedCell >{formatDigits(row?.stock_details?.stock_in?.value)}</BorderedCell>
                                                    <CustomTableCell>{row?.stock_details?.stock_in?.adjusted_qty}</CustomTableCell>
                                                    <CustomTableCell>{formatDigits(row?.stock_details?.stock_in?.adjusted_rate)}</CustomTableCell>
                                                    <BorderedCell >{formatDigits(row?.stock_details?.stock_in?.adjusted_value)}</BorderedCell>
                                                    <CustomTableCell>{row?.stock_details?.stock_out?.quantity}</CustomTableCell>
                                                    <CustomTableCell>{formatDigits(row?.stock_details?.stock_out?.rate)}</CustomTableCell>
                                                    <BorderedCell >{formatDigits(row?.stock_details?.stock_out?.value)}</BorderedCell>
                                                    <CustomTableCell>{row?.stock_details?.stock_out?.adjusted_qty}</CustomTableCell>
                                                    <CustomTableCell>{formatDigits(row?.stock_details?.stock_out?.adjusted_rate)}</CustomTableCell>
                                                    <BorderedCell >{formatDigits(row?.stock_details?.stock_out?.adjusted_value)}</BorderedCell>
                                                    <CustomTableCell>{row?.stock_details?.closing_stock?.quantity}</CustomTableCell>
                                                    <CustomTableCell>{formatDigits(row?.stock_details?.closing_stock?.rate)}</CustomTableCell>
                                                    <BorderedCell>{formatDigits(row?.stock_details?.closing_stock?.value)}</BorderedCell>
                                                </TableRow>
                                            );
                                        })}
                                        {/*{emptyRows > 0 && (*/}
                                        {/*    <TableRow style={{ height: 43 * emptyRows }}>*/}
                                        {/*        <TableCell colSpan={headData.length} />*/}
                                        {/*    </TableRow>*/}
                                        {/*)}*/}
                                    </>:null : null}
                                {isSuccess && stockList.length === 0 ? <TableRow>
                                    <TableCell colSpan={headData.length} align="center">No records found</TableCell>
                                </TableRow>: null}
                                {isLoading && activePage !== 1 ? <TableRow>
                                    <TableCell colSpan={headData.length} align="center"><LoadingGif/></TableCell>
                                </TableRow>: null }
                                {/*isSuccess && stockList.length > 0  ? <TableRow>
                                    <TableCell colSpan={headData.length} align='center'>
                                        {activePage === totalPages ? 'All records loaded' :  <button className='btn btn-sm pharmacy-info-btn' onClick={handleChangeActivePage}>Load More</button>}
                                    </TableCell>
                                </TableRow>: null*/}
                                {isError ? <TableRow><TableCell align='center'  {...{colSpan:headData.length}}>The server did not return a valid response</TableCell></TableRow>:null}
                            </TableBody>
                        </Table>
                </TableContainer>
                {isSuccess ? <MuiPagination rowsPerPage={rowsPerPage} page={page} total_count={data.total_count}
                                   handleChangeRowsPerPage={handleChangeRowsPerPage} isTotalCount={true}
                                   handleChangePage={handleChangePage} rows={10} colSpan={6}/> :
                null}


                    {/*{isPending ? <CenteredContainer>*/}
                    {/*    <LoadingGif/>*/}
                    {/*</CenteredContainer>: null}*/}
                    {/*{isResolved ?*/}
                        {/*<StickyHeadTable {...{width:'100%',data:stockList, columns:headData.filter(item=>Boolean(item)),parentHeadData:parentHead, totalHead : stockList.length > 0 ? totalHead: null}}>
                            {isLoading && activePage === 1 ? <TableRow><TableCell align='center' {...{colSpan:headData.length}}><LoadingGif/></TableCell></TableRow>:null}
                            {stockList.length > 0 ?
                                <>
                                    {stockList.slice(0, recordsPerPage).map((row, index) => {
                                        return (
                                            <TableRow  tabIndex={-1} key={index}>
                                                <BorderedCell>{row.product_name}</BorderedCell>
                                                <CustomTableCell>{row?.stock_details?.opening_stock?.quantity}</CustomTableCell>
                                                <CustomTableCell>{formatDigits(row?.stock_details?.opening_stock?.rate)}</CustomTableCell>
                                                <BorderedCell >{formatDigits(row?.stock_details?.opening_stock?.value)}</BorderedCell>
                                                <CustomTableCell>{row?.stock_details?.stock_in?.quantity}</CustomTableCell>
                                                <CustomTableCell>{formatDigits(row?.stock_details?.stock_in?.rate)}</CustomTableCell>
                                                <BorderedCell >{formatDigits(row?.stock_details?.stock_in?.value)}</BorderedCell>
                                                <CustomTableCell>{row?.stock_details?.stock_out?.quantity}</CustomTableCell>
                                                <CustomTableCell>{formatDigits(row?.stock_details?.stock_out?.rate)}</CustomTableCell>
                                                <BorderedCell >{formatDigits(row?.stock_details?.stock_out?.value)}</BorderedCell>
                                                <CustomTableCell>{row?.stock_details?.closing_stock?.quantity}</CustomTableCell>
                                                <CustomTableCell>{formatDigits(row?.stock_details?.closing_stock?.rate)}</CustomTableCell>
                                                <BorderedCell>{formatDigits(row?.stock_details?.closing_stock?.value)}</BorderedCell>
                                            </TableRow>
                                        );
                                    })}
                                   
                                </>:null}
                            {isSuccess && stockList.length === 0 ? <TableRow>
                                <TableCell colSpan={headData.length} align="center">No records found</TableCell>
                            </TableRow>: null}
                            {isLoading && activePage !== 1 ? <TableRow>
                                <TableCell colSpan={headData.length} align="center"><LoadingGif/></TableCell>
                            </TableRow>: null }
                            {isSuccess && stockList.length > 0  ? <TableRow>
                                <TableCell colSpan={headData.length} align='center'>
                                    {activePage === totalPages ? 'All records loaded' :  <button className='btn btn-sm pharmacy-info-btn' onClick={handleChangeActivePage}>Load More</button>}
                                </TableCell>
                            </TableRow>: null}
                            {isError ? <TableRow><TableCell align='center'  {...{colSpan:headData.length}}>The server did not return a valid response</TableCell></TableRow>:null}
                        </StickyHeadTable> */}
                        {/*// : null}*/}

                    {/*{isRejected ? <CenteredContainer>
                        
                         /*{emptyRows > 0 && (*/
                                    /*    <TableRow style={{ height: 43 * emptyRows }}>*/
                                    /*        <TableCell colSpan={headData.length} />*/
                                    /*    </TableRow>*/
                                    /*)}
                        */}
                    {/*    <p>The server did not return a valid response</p>*/}
                    {/*</CenteredContainer>: null}*/}
                </MuiPaper>
          
            </Container>
        </div>
    )
};

function mapStateToProps(state) {
    return {snackbars: state.snackbars}
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            snackbarActions: bindActionCreators(snackbarActions, dispatch)
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(StockSummary);
