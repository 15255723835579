import {useEffect, useState} from 'react';
import {useDates} from "../../Accounting/Expenses/hooks/useDates";
import axios from 'axios';
import config from '../../../Helpers/config.json'
import {errorMessages} from "../../../Helpers/ErrorMessages";
import {useMuiPagination} from "../../../Containers/Dasboard/DashboardTables";

export const useManageBank = (actions) => {
    const banks = [{
        bank_id: 1,
        bank_name: 'Centenary',
        branch: 'Mapeera',
        account_number: '23',
        account_name: 'Medaid',
        opening_balance:0
    }]
    const CURRENT_YEAR = new Date().getFullYear();
    const [openDialog, setOpenDialog] = useState(false);
    const [period, setPeriod] = useState({value: 'date', label: 'Date'});
    const [date, handleChangeDate, setDate] = useDates();
    const [year, setYear] = useState(CURRENT_YEAR);
    const [data, setData] = useState(banks);
    const [state, setState] = useState({
        bank_name: '', branch: '', account_no: '',
        account_name: '', opening_balance: ''
    })
    const [submitted, setSubmitted] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState('idle');
    const [loading, setLoading] = useState(false);
    const {page, rowsPerPage, handleChangeRowsPerPage, handleChangePage} = useMuiPagination();


    const inputType = {
        'date': 'date',
        'weekly': 'week',
        'monthly': 'month',
        'custom': 'date'
    }

    const dep = isSubmitted === 'resolved'
    useEffect(() => {
        (async () => {
            setLoading(true)
            try {
                const res = await axios.get(`${config.epharmUrl}/accounting/banks`)
                let _data = res.data
                let dt = _data.data ?? [];
                setData(dt)
                setLoading(false)
            } catch (e) {
                // errorMessages(e, null, actions)
                setLoading(false)
            }
        })();
    }, [dep])


    const handleChange = (e) => {
        const {name, value} = e.target
        setState({...state, [name]: value})
    }

    const handleChangeYear = (event) => {
        setYear(event.target.value)
    }

    const handleChangePeriod = (value) => {
        setPeriod(value)
        setDate({from_date: '', to_date: ''})
    }

    const handleOpenDialog = () => {
        setOpenDialog(true)
    }

    const handleCloseDialog = () => {
        setOpenDialog(false)
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        const {account_name, branch, account_no, opening_balance, bank_name} = state
        const formData = new FormData()
        formData.append('bank_name', bank_name)
        formData.append('branch', branch)
        formData.append('account_number', account_no)
        formData.append('account_name', account_name)
        formData.append('opening_balance', opening_balance)
        setSubmitted(true);
        if (bank_name && account_name && account_no) {
            setIsSubmitted('pending')
            axios.post(`${config.epharmUrl}/accounting/bank/add`, formData).then(() => {
                actions.snackbarActions.successSnackbar('Bank added successfully')
                setOpenDialog(false)
                setIsSubmitted('resolved')
                setState({
                    bank_name: '', branch: '', account_no: '',
                    account_name: '', opening_balance: ''
                });
                setSubmitted(false);
            }).catch(err => {
                errorMessages(err, null, actions);
                setIsSubmitted('rejected')
            })
        }
    }


    const closeSnackbar = () => {
        actions.snackbarActions.hideSnackBar()
    }


    return {
        openDialog, handleCloseDialog, handleOpenDialog, period, handleChangePeriod, inputType, date,
        handleChangeDate, year, handleChangeYear, data, handleSubmit,
        state, handleChange, closeSnackbar, submitted, isSubmitted,
        page, rowsPerPage, handleChangeRowsPerPage, handleChangePage, loading,
    }
}