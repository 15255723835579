import React from 'react';
import {bindActionCreators} from "redux";
import * as snackbarActions from '../../../Redux/Actions/SnackbarActions/SnackbarActions'
import {connect} from "react-redux";
import PharmacySnackbar from "../../../Utils/Snackbars/SnackbarUtil";
import {ReusableRouteTabs} from "../../../Utils/Dialog/ReusableTabs";
import {Container} from "../../../Utils/styledComponents"
import { history } from '../../../Helpers/history';
import {RouteLabels} from "../../../Utils/Tabs/RouteLabels";
import ReusableDashboardHeader from "../../../Containers/Dasboard/ReusableDashboardHeader";
import {useSetUpSuppliers} from "./hooks/useSetupSuppliers";
import {SupplierSetUpForm} from "./SupplierSetUpForm";



const headData = [{name:'Supplier', isRequired:true},{name:'Debit', isRequired:true},{name:'Credit', isRequired:true},
    {name:'Action', width:'100'}]





const SetupSuppliers = ({actions, snackbars}) => {
    const supplier_path = '/ledgers/setupsuppliers' || '/ledgers/ledgerlist/supplier'
    const components = [
        {label: 'Accounting Period', path: '/accounting/setup'},
        {label: 'Suppliers', path: supplier_path},
        {label: 'Customers', path: '/ledgers/setupcustomers'},
        {label: 'Products', path: '/ledgers/setupproducts'},
    ]

    const subLinks = [
        {label: 'Setup', route:'/ledgers/setupsuppliers'},
        {label: 'Ledgers', route: '/ledgers/ledgerlist/supplier'},
    ]
    const {supplierProps, closeSnackbar} = useSetUpSuppliers(actions)

    const {open, variant, message} = snackbars;
    return (
        <div className='journals'>
            <ReusableDashboardHeader component='Product Ledgers' heading="Accounting"
                                     subHeading='product Ledgers' link={history.location.pathname}/>
            <PharmacySnackbar message={message} open={open} handleCloseBar={closeSnackbar} variant={variant}/>
            <Container>
                <ReusableRouteTabs value={supplier_path} tabHeading={components}/>
                <RouteLabels value={history.location.pathname} data={subLinks}/>
                <SupplierSetUpForm {...{...supplierProps, headData}}/>
            </Container>
        </div>

    )
}

function mapStateToProps(state) {
    const {snackbars} = state;
    return {snackbars:snackbars}
}

function mapDispatchToProps(dispatch) {
    return {actions: {snackbarActions: bindActionCreators(snackbarActions, dispatch)}}
}

export default connect(mapStateToProps, mapDispatchToProps)(SetupSuppliers);