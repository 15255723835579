import React from "react";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import * as snackbarActions from "../../Redux/Actions/SnackbarActions/SnackbarActions";
import PharmacySnackbar from "../../Utils/Snackbars/SnackbarUtil";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPencilAlt} from "@fortawesome/free-solid-svg-icons/faPencilAlt";
import {faTrash} from "@fortawesome/free-solid-svg-icons/faTrash";
import ReusableDashboardHeader from "../../Containers/Dasboard/ReusableDashboardHeader";
import DeleteDialog from "../../Utils/DeleteDialog";
import {EnhancedTableHead, EnhancedTableToolbar, MuiPagination, MuiPaper} from "../../Utils/Tables/CustomTable";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import {useMedicineUnit} from "./CustomHooks/useMedicineUnit";
import {UnitForm} from "./MedicineMetaComponents";
import DetailsDialog from "../../Utils/Dialog/DetailsDialog";
import { faFileUpload } from '@fortawesome/free-solid-svg-icons';
import CsvDialog from "../../Utils/CsvDialog";
import { Alert } from "@material-ui/lab";
import { CSVLink } from 'react-csv';

const headData = [
    {id: 'name', numeric: false, disablePadding: false, label: 'Unit Name'},
    {id: 'action', numeric: false, disablePadding: false, label: 'Action'},
];
const AddUnit = ({actions, snackbars}) => {
    const {openDelete, units, unitProps,csvProps,
        loading, page, rowsPerPage, handleChangeRowsPerPage, handleChangePage,
        handleOpenDelete, handleCloseDelete, deleteUnit, handleCloseSnackbar
    } = useMedicineUnit(actions)
    const {open, message, variant} = snackbars;
    const {openDialog, openEdit, handleCloseEdit, handleOpenEdit, handleCloseDialog, handleOpenDialog} = unitProps
    const {handleChangeUnitCsv, handleCloseCSVDialog,handleOpenCSVDialog, fetchData,
        handleUploadCsv, error_message, allUnits,csvdialog,csvLink} = csvProps
    return (
        <div>
            <div className='journals'>
            <CsvDialog openDialog={csvdialog} handleCloseDialog={handleCloseCSVDialog}
                handleUploadCsv={handleUploadCsv} handleChangeUpload={handleChangeUnitCsv}
                message="Units">
                     <span>
                <button onClick={fetchData}
                        className="btn pharmacy-primary-btn btn-sm">Download Sample File</button>
                {error_message !== '' ?
                    <div style={{marginTop:'10px'}}>
                        <Alert severity="error" style={{borderRadius:'10px'}}>{error_message}</Alert>
                    </div>
                    : null
                }
            <CSVLink
                data={allUnits}
                ref={csvLink}
                filename="Units.csv"
                className="hidden"
                    />
                    </span>
            </CsvDialog>
                <ReusableDashboardHeader component='Product Unit' heading="Products"
                                         subHeading='Product Unit' link='/addunit'/>
                <div className="general-ledger-header">
                    <div className="row-mr-4">
                        <div className="col-md-6">
                            <h6 className='mt-2 mb-0'>Units</h6>
                        </div>
                        <div className="row" >
                            <div className="text-right">
                                <button data-testid="add_unit" type='button' onClick={handleOpenDialog}
                                        className='btn btn-sm pharmacy-info-btn'>Add Unit
                                </button>
                            </div>
                            <div className="text-right" style={{marginBottom:'4px',paddingLeft:'20px'}}>
                            <button type="button" onClick={handleOpenCSVDialog} className="btn pharmacy-info-btn btn-sm mr-4" style={{marginBottom:'4px'}}>
                                    <FontAwesomeIcon icon={faFileUpload} /> Upload Unit
                            </button>
                            </div>
                        </div>
                    </div>
                </div>
                <PharmacySnackbar open={open} message={message} variant={variant}
                                  handleCloseBar={handleCloseSnackbar}/>
                <DeleteDialog handleClose={handleCloseDelete} openDialog={openDelete} message='unit'
                              handleDelete={deleteUnit}/>
                <DetailsDialog title='New Unit' openDialog={openDialog} handleClose={handleCloseDialog}>
                   <UnitForm {...unitProps}/>
                </DetailsDialog>
                <DetailsDialog title='Edit Unit' openDialog={openEdit} handleClose={handleCloseEdit}>
                    <UnitForm {...unitProps}/>
                </DetailsDialog>
                <div className="mui-tables">
                    <MuiPaper>
                        <EnhancedTableToolbar title={''}/>
                        <TableContainer>
                            <Table
                                aria-labelledby="tableTitle"
                                size='small'
                                aria-label="enhanced table"
                            >
                                <EnhancedTableHead headCells={headData}/>
                                {loading ? <TableBody><TableRow>
                                    <TableCell colSpan="2" align="center"><img src="/images/listPreloader.gif"
                                                                               alt="loader"
                                                                               style={{width: '5%'}}/></TableCell>
                                </TableRow></TableBody> : units.length > 0 ?
                                    <TableBody>
                                        {units.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                            .map((unit) => {
                                                return (
                                                    <TableRow
                                                        hover
                                                        role="checkbox"
                                                        tabIndex={-1}
                                                        key={unit.id}
                                                    >
                                                        <TableCell>{unit.unit_name}</TableCell>
                                                        <TableCell>
                                                            <button onClick={()=>handleOpenEdit(unit.id)} className="btn btn-sm pharmacy-info-btn mr-1">
                                                                    <FontAwesomeIcon icon={faPencilAlt}/></button>

                                                            <button className="btn btn-sm pharmacy-btn-dismiss"
                                                                    onClick={() => handleOpenDelete(unit.id)}>
                                                                <FontAwesomeIcon icon={faTrash}/></button>
                                                        </TableCell>
                                                    </TableRow>
                                                );
                                            })}
                                    </TableBody>
                                    : <TableBody><TableRow>
                                        <TableCell colSpan="2" align="center">No records found</TableCell>
                                    </TableRow></TableBody>}
                            </Table>
                        </TableContainer>
                        <MuiPagination data={units} rowsPerPage={rowsPerPage} page={page}
                                       handleChangeRowsPerPage={handleChangeRowsPerPage}
                                       handleChangePage={handleChangePage} rows={[10, 20, 30, 50 ,100]} colSpan={2}/>
                    </MuiPaper>
                </div>
            </div>
        </div>
    )
}

function mapStateToProps(state) {
    return {
        snackbars: state.snackbars
    }

}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            snackbarActions: bindActionCreators(snackbarActions, dispatch)
        }
    }

}

export default connect(mapStateToProps, mapDispatchToProps)(AddUnit);