import React from "react";
import TextField from "../../../Utils/FormInputs/TextField";
import {ErrorMessage} from "../../../Utils/styledComponents";
import {CustomSelect} from "../../../Utils/FormInputs/SelectInput";
import Label from "../../../Utils/FormInputs/Label";
import Form from "react-bootstrap/Form";

function NewExpenseItem({debitLedger, ledgers, creditLedger,  expenseItem, submitted,
                            isPending,  handleChangeExpenseItem, handleChangeCreditLedger, handleChangeDebitLedger,
                             submitNewExpense,creditLedgers}){
    return (
        <form onSubmit={submitNewExpense} autoComplete='off'>
            <Form.Group>
                <Label type name='Expense Item'/>
                <TextField  {...{submitted}} value={expenseItem} type="text" name="expenseItem"
                            onChange={handleChangeExpenseItem} placeholder="Enter expense item"/>
                {(submitted && !expenseItem) ? <ErrorMessage>Expense item is required</ErrorMessage>:null}
            </Form.Group>
            {/*<Form.Group>
                <Label  name='Debit Ledger' type/>
                <CustomSelect value={debitLedger} options={ledgers}
                              onChange={handleChangeDebitLedger} submitted={submitted}/>
                {(submitted && !debitLedger) ? <ErrorMessage>Debit ledger is required</ErrorMessage>:null}
            </Form.Group>
            <Form.Group>
                <Label  name='Credit Ledger' type/>
                <CustomSelect value={creditLedger} options={creditLedgers}
                              onChange={handleChangeCreditLedger} submitted={submitted}/>
                {(submitted && !creditLedger) ? <ErrorMessage>Credit ledger is required</ErrorMessage>:null}
            </Form.Group> */}

            <button type='submit' disabled={isPending}  className='btn btn-sm pharmacy-btn'>{isPending ? 'Submitting...':'Submit'}</button>
        </form>
    )
}
export {NewExpenseItem}