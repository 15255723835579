import React from 'react'
import {bindActionCreators} from "redux";
import * as snackbarActions from "../../../Redux/Actions/SnackbarActions/SnackbarActions";
import {connect} from "react-redux";
import {useEntityLedgers} from "./hooks/useEntityLedgers";
import ReusableDashboardHeader from "../../../Containers/Dasboard/ReusableDashboardHeader";
import CustomDialog from "../../../Utils/Dialog/CustomDialog";
import {EditSetupForm} from "./EditSetupForm";
import DeleteDialog from "../../../Utils/DeleteDialog";
import PharmacySnackbar from "../../../Utils/Snackbars/SnackbarUtil";
import {history} from "../../../Helpers/history";
import {Container} from "../../../Utils/styledComponents"
import {ReusableRouteTabs} from "../../../Utils/Dialog/ReusableTabs";
import {RouteLabels} from "../../../Utils/Tabs/RouteLabels";
import {EntityLedgerList} from "./EntityLedgerList";

const headData = [
    { id: 'supplier_name', label: 'Supplier',minWidth: 200 },
    { id: 'debit_ledger', label: 'Debit Ledger',minWidth: 200 },
    { id: 'credit_ledger', label: 'Credit Ledger',minWidth: 200 },
    { id: 'action', label: 'Action',minWidth: 200 },
]


function SupplierLedgers({actions, snackbars}) {


    const supplier_path =  '/ledgers/setupsuppliers' || '/ledgers/ledgerlist/supplier'
    const components = [
        {label: 'Accounting Period', path: '/accounting/setup'},
        {label: 'Suppliers', path: supplier_path},
        {label: 'Customers', path: '/ledgers/setupcustomers'},
        {label: 'Products', path: '/ledgers/setupproducts'},
    ]

    const subLinks = [
        {label: 'Setup', route:'/ledgers/setupsuppliers'},
        {label: 'Ledgers', route: '/ledgers/ledgerlist/supplier'},
    ]


    const {data, isLoading, isSuccess, isError, openDelete,handleCloseDelete,
        handleOpenDelete, handleDelete, editProps,closeSnackbar} = useEntityLedgers(actions, 'supplier')

    const {handleOpenEdit, openEdit, handleCloseEdit,} = editProps

    const arr = data.filter(item=>item.entity_type === 'supplier').map(item=>({
        ...item, name:item.manufacturer_name, id:item.id
    }))

    const {open, variant, message} = snackbars;
    return (
        <div>
            <ReusableDashboardHeader component="Supplier Ledgers" heading="Accounting"
                                     subHeading="Supplier ledgers" link={history.location.pathname}/>
            <Container>
                <CustomDialog open={openEdit} handleClose={handleCloseEdit} title='Edit Supplier'>
                    <EditSetupForm {...{...editProps, item_name:'Product Name'}}/>
                </CustomDialog>
                <DeleteDialog message='delete' openDialog={openDelete} handleClose={handleCloseDelete}
                              text="supplier" title={`Delete Supplier`}>
                    <button className='btn pharmacy-btn-dismiss btn-sm' onClick={handleDelete}>Delete</button>
                    <button className="btn pharmacy-grey-btn btn-sm" onClick={handleCloseDelete}>No</button>
                </DeleteDialog>
                <PharmacySnackbar message={message} open={open} handleCloseBar={closeSnackbar} variant={variant}/>
                <ReusableRouteTabs value={supplier_path} tabHeading={components}/>
                <RouteLabels value={history.location.pathname} data={subLinks}/>
                <EntityLedgerList {...{data:arr,isLoading, isError, isSuccess,handleOpenDelete,handleOpenEdit, headData}} />
            </Container>
        </div>

    )
}

function mapStateToProps(state) {
    const {snackbars} = state;
    return {snackbars:snackbars}
}

function mapDispatchToProps(dispatch) {
    return {actions: {snackbarActions: bindActionCreators(snackbarActions, dispatch)}}
}

export default connect(mapStateToProps, mapDispatchToProps)(SupplierLedgers)


