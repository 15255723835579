import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    // maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
    paddingTop: 0,
    paddingBottom: 0,
  },
  icon: {
    width: ".9em",
    height: ".9em",
  },
  iconHolder: {
    minWidth: "38px ",
  },
  listItem: {
    borderBottom: "1px solid #e8e8e8",
    paddingTop: "10px",
    paddingBottom: "10px",
    cursor: "pointer",
  },
  activeLi: {
    backgroundColor: "#f5f5f5",
  },
  primary: {
    fontSize: "0.99em",
  },
  secondary: {
    fontSize: "0.9em",
  },
  item: {
    borderRight: "1px solid #c7c6c7",
    borderLeft: "1px solid #c7c6c7",
    height: "100vh",
    overflow: "auto",
    "&::-webkit-scrollbar": {
      width: "5px",
      height: "5px",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#c7c6c7",
      "-webkit-border-radius": "10px",
      borderRadius: "10px",
    },
  },

  listText: {
    textAlign: "right",
  },
}));

function ReportsItemList({ handleClick, isActive, text, styles, item = {} }) {
  const classes = useStyles();
  const { days_left, date, status, related_purchase, amount } = item;
  return (
    <List className={classes.root}>
      <ListItem
        classes={{
          root: `${classes.listItem} ${isActive && classes.activeLi}`,
        }}
        key={item.id}
        onClick={handleClick}
      >
        {/*<ListItemIcon classes={{root:classes.iconHolder}}>*/}
        {/*    <Form.Check type='checkbox' checked={item.checked} onChange={e=>handleCheckItem(e, item.related_purchase)}/>*/}
        {/*</ListItemIcon>*/}
        <ListItemText
          primary={`${related_purchase} | ${date}`}
          secondary={
            status === "negative"
              ? `Payment overdue by ${days_left} days`
              : `Days left: ${days_left}`
          }
          classes={{
            primary: classes.primary,
            secondary: classes.secondary,
          }}
        />
        <ListItemText
          primary={amount}
          secondary={text[item.status]}
          classes={{
            root: classes.listText,
            primary: classes.primary,
            secondary: `${classes.secondary} ${styles[item.status]}`,
          }}
        />
      </ListItem>
    </List>
  );
}
export { ReportsItemList };
