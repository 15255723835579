import React, {Component} from "react";
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPencilAlt} from "@fortawesome/free-solid-svg-icons/faPencilAlt";
import {faTrash} from "@fortawesome/free-solid-svg-icons/faTrash";
import Paper from "@material-ui/core/Paper";
import {EnhancedToolbar,EnhancedPharmacyListHead, stableSort, getSorting, useStyles} from "../../../Utils/Tables/MuiTables";
import DrugsAvailableTablePagination from "../../../Utils/Tables/MuiTablePagination";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import FilterResults from 'react-filter-search';
import TablePagination from '@material-ui/core/TablePagination';
import {withStyles} from "@material-ui/core/styles";
import DashboardHeader from "../../../Containers/Dasboard/DashboardHeader";

const styles = theme => ({
    root: {
        width: "100%",
        overflowX: "auto"
    },
    table: {
        minWidth: 650
    },
    subMenuLink: {
        display: "block",
        color: "#757575",
        textDecoration: "none"
        // padding: theme.spacing.unit * 6,
    },
    itemStyle: {
        backgroundColor: "#f9f9f9"
    },
    itemOdd: {
        backgroundColor: "#fff"
    }
});

const data = [
    {
        sl1: 1,
        name: "Name 1",
        date: "Date 1",
        checkin: "Check In 1",
        checkout: "Check Out 1",
        stay: "yes"
    },
    {
        sl1: 2,
        name: "Name 2",
        date: "Date 2",
        checkin: "Check In 2",
        checkout: "Check Out 2",
        stay: "yes"
       
    },
    {
        sl1: 3,
        name: "Name 3",
        date: "Date 3",
        checkin: "Check In 3",
        checkout: "Check Out 3",
        stay: "yes"
        
    },

];

class ManageAttendance extends Component{
    constructor(props){
        super(props);
        this.state={
            term: "",
            order: 'asc',
            setOrder: 'asc',
            orderBy: 'person',
            selected: [],
            setSelected: [],
            page: 0,
            setPage: 0,
            dense: false,
            setDense: false,
            rowsPerPage: 5,
        };
        this.handler = this.handler.bind(this);
        this.ref = React.createRef();
    }
    handler(value) {
        this.setState({term: value})
    }
    handleRequestSort = (event, property) => {
        const orderBy = property;
        let order = 'desc';

        if (this.state.orderBy === property && this.state.order === 'desc') {
            order = 'asc';
        }

        this.setState({order, orderBy});
    };
    handleChangePage = (event, newPage) => {
        this.setState({page: newPage});
    };

    handleChangeRowsPerPage = (event) => {
        this.setState({rowsPerPage: event.target.value, page: 0});
    };
    render() {
        const {order, dense, orderBy, selected, rowsPerPage, page, term} = this.state;
        const {classes} = this.props;
        const emptyRows = rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage);
        return(
            <div>
            <DashboardHeader attendance manageAttendance/>
            <div className="mx-3 mt-5">
                <div className="card styled-table-card p-3">
                    <h6 className="ml-3 mt-2">Manage Attendance</h6>
                    <hr/>
                    <Paper className={`${classes.paper} table-responsive`}>
                        <EnhancedToolbar numSelected={selected.length} handler={this.handler} targetRef={this.ref} data={data} manageattendance/>
                        <div className={classes.tableWrapper} ref={this.ref}>
                            <Table
                                aria-labelledby="tableTitle"
                                size={dense ? 'small' : 'medium'}
                                // className={classes.table}
                                id="section-to-print"
                                >
                                <EnhancedPharmacyListHead
                                    classes={classes}
                                    numSelected={selected.length}
                                    order={order}
                                    orderBy={orderBy}
                                    onRequestSort={this.handleRequestSort}
                                    rowCount={data.length}
                                    manageattendance
                                />
                                <FilterResults
                                    value={term}
                                    data={data}
                                    renderResults={results => (
                                        <TableBody>
                                            {stableSort(results, getSorting(order, orderBy))
                                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                .map((d, index) => {
                                                    return (
                                                        <TableRow
                                                            hover
                                                            role="checkbox"
                                                            tabIndex={-1}
                                                            key={d.id}
                                                            className='mui-table-row'>
                                                            <TableCell component="th" scope="row" padding="default"
                                                                       className='mui-table-cell'>
                                                                <span>{d.sl1}</span>
                                                            </TableCell>
                                                            <TableCell>
                                                                <span>{d.name}</span>
                                                            </TableCell>
                                                            <TableCell>
                                                                <span>{d.date}</span>
                                                            </TableCell>
                                                            <TableCell>
                                                                <span>{d.checkin}</span>
                                                            </TableCell>
                                                            <TableCell>
                                                                <span>{d.checkout}</span>
                                                            </TableCell>
                                                            <TableCell>
                                                                <span>{d.stay}</span>
                                                            </TableCell>
                                                            <TableCell>
                                                                <span>
                                                                    <Link to="/editattendance"><button className="btn pharmacy-info-btn btn-sm mr-1 ">
                                                                        <FontAwesomeIcon icon={faPencilAlt}/>
                                                                    </button></Link>
                                                                    <button className="btn pharmacy-btn-dismiss btn-sm  ">
                                                                        <FontAwesomeIcon icon={faTrash}/>
                                                                    </button>
                                                                </span>
                                                            </TableCell>
                                                        </TableRow>
                                                    );
                                                })}
                                            {emptyRows > 0 && (
                                                <TableRow style={{height: 49 * emptyRows}}>
                                                    <TableCell colSpan={6}/>
                                                </TableRow>
                                            )}
                                        </TableBody>
                                    )}
                                />
                            </Table>
                        </div>
                        <TablePagination
                            rowsPerPageOptions={[ 5, 10, 25]}
                            count={data.length}
                            rowsPerPage={rowsPerPage}
                            component="div"
                            page={page}
                            SelectProps={{
                                inputProps: { 'aria-label': 'rows per page' },
                                native: true,
                            }}
                            onPageChange={this.handleChangePage}
                            onRowsPerPageChange={this.handleChangeRowsPerPage}
                            ActionsComponent={DrugsAvailableTablePagination}
                        />
                    </Paper>
                </div>
            </div>
            </div>
        )
    }

}
export default (withStyles(useStyles, styles) )(ManageAttendance);