import {useEffect, useState, useRef} from "react";
import {useMuiPagination} from "../../../Containers/Dasboard/DashboardTables";
import axios from "axios";
import config from "../../../Helpers/config.json";
import {errorMessages} from "../../../Helpers/ErrorMessages";
import {useAddUnit} from "./useAddUnit";


const useMedicineUnit = (actions) =>{
    const [unit_id, setUnitId] = useState('');
    const [openDelete, setOpenDelete] = useState(false);
    const [units, setUnits] = useState([]);
    const [loading, setLoading] = useState(false);
    const {page, rowsPerPage, handleChangeRowsPerPage, handleChangePage} = useMuiPagination();
    const [allUnits, setAllUnits] = useState([]);
    const [unitCsv, setUnitCsv] = useState(null);
    const [error_message, setErrorMessage] = useState('');
    const [csvdialog, setCSVDialog] = useState(false);
    const csvLink = useRef();
    const {unitProps,isResolved} = useAddUnit(actions)

    useEffect(() => {
        setLoading(true)
        axios.get(`${config.epharmUrl}/Cproduct/unit_list`).then(response => {
            const data = response.data
            const dt = data ? data : {};
            const list = dt.unit ? dt.unit : []
            setUnits(list);
            setLoading(false)
        }).catch(error => {
            //errorMessages(error, null, actions)
            setLoading(false);
        })
    }, [isResolved])


    const handleOpenDelete = (_id = '') => {
        setUnitId(_id)
        setOpenDelete(true)
    };

    const handleCloseDelete = () => {
        setOpenDelete(false)
    }

    const deleteUnit = () => {
        axios.get(`${config.epharmUrl}/Cproduct/delete_unit/${unit_id}`).then(() => {
            actions.snackbarActions.successSnackbar('Unit deleted successfully');
            const arr = units.filter(item => item.id !== unit_id)
            setUnits(arr)
            setOpenDelete(false)
        }).catch(error => {
            errorMessages(error, null, actions)
        })
    };


    const handleCloseSnackbar = () => {
        actions.snackbarActions.hideSnackBar()
    };

    const handleOpenCSVDialog = () => {
        setCSVDialog(true);
    };

    const handleCloseCSVDialog = () => {
        setCSVDialog(false);
        setErrorMessage('');
    };

    const fetchData = () => {
        axios.get(`${config.epharmUrl}/Cproduct/unit_csv`,{params:{download:true}, responseType: 'blob'}).then(response => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `Unit_Template.csv`);
            document.body.appendChild(link);
            link.click();
            setErrorMessage('');
        }).catch(err => {
            errorMessages(err, null, actions)
        })
    };

    const handleUploadCsv = (event) => {
        event.preventDefault();
        if (unitCsv) {
            const formData = new FormData();
            formData.append('upload_csv_file', unitCsv ? unitCsv : '');
            axios.post(`${config.epharmUrl}/Cproduct/uploadCsv_units`, formData).then((res) => {
                    actions.snackbarActions.successSnackbar('Unit csv uploaded Successfully');
                    //const data_arr = res.data?? [];
                    if (error_message) setErrorMessage('');
                    // setTimeout(() => {
                    //     history.push('/managemedicine');
                    //  }, 1000); 
                }).catch(error => {
                    const err_msg = (error.response && error.response.data) ? error.response.data.message.split('-')[1] : '';
                    setErrorMessage('Missing field in file: ' + err_msg)
            })
        } else {
            setErrorMessage('Please select a csv or excel file to upload');
        }
    };

    const handleChangeUnitCsv = event => {
        const file_extension = event.target.files[0].name.substr(event.target.files[0].name.length - 4);
        if (file_extension === ".csv" || file_extension === ".xls" || file_extension === "xlsx") {
            setUnitCsv(event.target.files[0]);
            if (error_message) setErrorMessage('');
        } else {
            event.target.value = null;
            setErrorMessage('Please upload only csv or excel (.xls or .xlsx) format');
        }
    };

    const csvProps = {handleChangeUnitCsv, handleCloseCSVDialog,handleOpenCSVDialog, fetchData,
        handleUploadCsv, error_message, allUnits,csvdialog,csvLink}

    return {unit_id,  openDelete, units, unitProps, csvProps,
        loading, page, rowsPerPage, handleChangeRowsPerPage, handleChangePage,
        handleOpenDelete, handleCloseDelete, deleteUnit, handleCloseSnackbar}
}
export {useMedicineUnit}