import * as types from "../actionTypes";
import axios from "axios";
import config from "../../../Helpers/config"
import {infoSnackbar, successSnackbar} from "../SnackbarActions/SnackbarActions";
import {history} from '../../../Helpers/history';
import {errorMessages} from "../../../Helpers/ErrorMessages";



function purchaseDetails(productPurchase) {
    return {
        type: types.RETRIEVE_PURCHASE_DETAILS,
        productPurchase
    }
}

function productsbyManufacturer(allProducts) {
    return {
        type: types.PRODUCTS_BY_MANUFACTURER,
        allProducts
    }
}

function singleproduct(product) {
    return {
        type: types.SINGLE_PURCHASE_PRODUCT,
        product
    }
}

function checkPurchase(purchaseList) {
    return {
        type: types.CHECK_PURCHASE_LIST,
        purchaseList
    }
}

function purchaseDelete(purchase_id) {
    return {
        type: types.DELETE_PURCHASE,
        purchase_id
    }
}

function purchaseForm(purchaseUpdate) {
    return {
        type: types.PURCHASE_UPDATE_FORM,
        purchaseUpdate
    }
}


function invoiceFormSubmit(formSubmit) {
    return {
        type: types.INVOICE_HTML_FORM,
        formSubmit
    }
}

function purchaseCount(purchase_count) {
    return {
        type: types.PURCHASE_COUNT,
        purchase_count
    }

}
function editPurchaseRequest(){
    return {
        type: types.EDITPURCH_REQUEST,
    }
}
function editPurchaseError(){
    return {
        type: types.EDITPURCH_ERROR,
    }
}
function editPurchaseSuccess(){
    return {
        type: types.EDITPURCH_SUCCESS,
    }
}

export function invoiceHtmlForm(purchase_id) {
    return function (dispatch) {
        return axios
            .get(config.epharmUrl + `/Cpurchase/invoice_html/${purchase_id}`)
            .then(response => {
                const res = response.data;
                const purchaseinfo = res ? res : {};
                dispatch(invoiceFormSubmit(purchaseinfo))
            }).catch(error => {
                errorMessages(error, dispatch, null)
            })
    }
}


export function purchaseUpdateForm(purchase_id) {
    return function (dispatch) {
        return axios.get(config.epharmUrl + "/Cpurchase/purchase_update_form", {params: {purchase_id}}).then(response => {
            const purchaseinfo = response.data;
            dispatch(purchaseForm(purchaseinfo));
        }).catch(error => {
            errorMessages(error, dispatch, null)
        })
    }
}

export function purchaseUpdate(formData) {
    return function (dispatch) {
        dispatch(editPurchaseRequest());
        const configuration = {
            headers: {'content-Type':'multipart/form-data'}
        };
        return axios.post(config.epharmUrl + '/Cpurchase/purchase_update', formData, configuration).then(() => {
            dispatch(editPurchaseSuccess());
            dispatch(successSnackbar('Purchase Update successful'));
            setTimeout(()=>{
                history.push('/managepurchase');
            },2000)
        }).catch(error => {
            dispatch(editPurchaseError());
            errorMessages(error, dispatch, null)

        })
    }
}

export function deletePurchase(purchase_id) {
    return function (dispatch) {
        return axios.get(config.epharmUrl + "/Cpurchase/delete_purchase", {params: {purchase_id}}).then(res => {
            dispatch(purchaseDelete(purchase_id));
            dispatch(successSnackbar('Purchase deleted successfully'))
        }).catch(error => {
            errorMessages(error, dispatch, null)

        })
    }
}

export function checkPurchaseList(formData) {
    return function (dispatch) {
        const configuration = {
            headers: {
                'content-Type': 'multipart/form-data',
                // user_id: 1
            }
        };
        return axios.post(config.epharmUrl + "/Cpurchase/CheckPurchaseList", formData, configuration).then(response => {
            const data = response.data.aaData;
            const purchase_count = response.data.iTotalRecords;
            dispatch(checkPurchase(data));
            dispatch(purchaseCount(purchase_count));
        }).catch(error => {
            errorMessages(error, dispatch, null)
        })
    }
}

export function productPurchaseDetails(purchase_id) {
    return function (dispatch) {
        return axios
            .get(config.epharmUrl + "/Cpurchase/purchase_details_data", {params: {purchase_id}})
            .then(response => {
                const data = response.data;
                const dt = data ? data : {};
                dispatch(purchaseDetails(dt));
            })
            .catch(error => {
                errorMessages(error, dispatch, null)
            })
    }
}

export function submitPurchase(formData) {
    return function (dispatch) {
        const configuration = {
            headers: {
                'content-Type': 'multipart/form-data'
            }
        };
        return axios.post(config.epharmUrl + '/Cpurchase/insert_purchase', formData,configuration)
            .then((response) => {
                // const purchase_id = response.data.purchase_id;
                dispatch(successSnackbar('Purchase Saved Successfully'));
                setTimeout(()=>{
                    history.push('/managepurchase');
                },2000);
            })
            .catch(error => {
                errorMessages(error, dispatch, null)
            })
    }
}

export function searchDrugbyManufacturer(product_name) {
    return function (dispatch) {
        if (product_name === '') {
            dispatch(productsbyManufacturer([]))
            return;
        }
        return axios.get(config.epharmUrl + '/Cpurchase/product_search_by_manufacturer',
            {params: {product_name}})
            .then((response) => {
                const data = response.data;
                const dt = !data ? [] : data;
                if (response.data === "No Medicine Found") {
                    dispatch(infoSnackbar('No Medicine Found'))
                } else {
                    dispatch(productsbyManufacturer(dt))
                }
            })
            .catch(error => {
                errorMessages(error, dispatch, null)
            })
    }
}

export function getProductInfo(product_id, manufacturer_id) {
    return function (dispatch) {
        return axios.get(config.epharmUrl + '/Cinvoice/retrieve_product_data', {
            params: {
                product_id,
                manufacturer_id
            }
        }).then((response) => {
            const data = response.data;
            dispatch(singleproduct(data))
        }).catch(error => {
            errorMessages(error, dispatch, null)
        })
    }
}

export function uploadCsv(formData) {
    return function (dispatch) {
        const configuration = {
            headers: {
                'content-Type': 'multipart/form-data'
            }
        };
        return axios.post(config.epharmUrl + "/Cpurchase/uploadCsv_Purchase", formData, configuration).then(() => {
            dispatch(successSnackbar('Upload Successful'));
            history.push('/managepurchase')
        }).catch(error => {
            errorMessages(error, dispatch, null)
        })

    }

}