import {useEffect, useState} from "react";
import axios from "axios";
import config from "../../../Helpers/config.json";
import {errorMessages} from "../../../Helpers/ErrorMessages";
import {history} from "../../../Helpers/history";
import {useAddProvider} from "./useAddProvider";

export const useAddCustomer = (actions, quickCustomer = null) => {

    const storage = JSON.parse(sessionStorage.getItem("customers"))
    const item = storage ? storage : []
    const customers = ()=> item  || [];

    const showForm = customers().length <= 0
    const [state, setState] = useState({customerName: '', customerEmail: '',
        mobile: '', address: '', previousBalance: '', credit_limit: ''});
    const [customer_category, setCustomerCategory] = useState(null)
    const [openDialog, setOpenDialog] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState('idle');
    const [customer, setCustomer] = useState({});
    const [openCustomerDialog, setOpenCustomerDialog] = useState(false);
    const [customer_id, setCustomerId] = useState(null);
    const [customer_categories, setCustomerCategories] = useState([]);
    const [addedCustomers, setAddedCustomers] = useState(customers());
    const [isShowForm, setIsShow] = useState(showForm);
    const {providerProps, isResolved:isResolvedProvider} = useAddProvider(actions);
    const [isBlocking, setIsBlocking] = useState(false)

    useEffect(() => {
        axios.get(`${config.epharmUrl}/Ccustomer/customer_category`).then(res => {
            const data = res.data;
            const dt = !data ? [] : data;
            const options = dt.map(item=>({value:item.id, label:item.category_name}))
            setCustomerCategories(options);
        }).catch(err => {
            errorMessages(err,null,actions)
        })
    }, [isResolvedProvider]);

    useEffect(() => {
        if (customer_id === '' || customer_id === null) {
            return
        }
        axios.post(`${config.epharmUrl}/Ccustomer/customer_update_form/${customer_id}`).then(response => {
            const data = response.data;
            const dt = data ?? {};
            setCustomer(dt)
        }).catch(error => {
            errorMessages(error, null, actions)
        })
    }, [customer_id])

    useEffect(() => {
        if (!customer_id){
            setState({
                customerName:'',
                customerEmail:  '',
                mobile: '',
                address:  '',
                previousBalance:  0,
                credit_limit: 0,
            })
            return
        }
        const {
            customer_name,
            customer_email,
            customer_mobile,
            customer_address,
            previous_balance,
            credit_limit,
            customer_category_id,
            category_name
        } = customer;
        setState({
            customerName: customer_name ?? '',
            customerEmail: customer_email ?? '',
            mobile: customer_mobile ?? '',
            address: customer_address ?? '',
            previousBalance: previous_balance ?? 0,
            credit_limit: credit_limit ?? 0,
            isEdit: !!customer_name
        })
        setCustomerCategory({value:customer_category_id, label:category_name})
    }, [customer, customer_id])

    const handleOpenCustomerDialog = (_id='') => {
        setOpenCustomerDialog(true);
        setCustomerId(_id)
    };

    const handleCloseCustomerDialog = () => {
        setOpenCustomerDialog(false)
    };

    const handleOpenDialog = () => {
        setOpenDialog(true)
    };
    const handleCloseDialog = () => {
        setOpenDialog(false)
    };

    const handleChange = event => {
        const {name, value} = event.target;
        setState({...state, [name]: value})
        setIsBlocking(true)
    };

    const handleChangeCategory = (value) =>{
        setCustomerCategory(value)
        setIsBlocking(true)
    }

    const addAnotherCustomer = () =>{
        setIsShow(true)
    }

    const cancelAdd = () =>{
        const show = addedCustomers.length <= 0
        setIsShow(show)
    }

    const addCustomer = () =>{
        const {customerName, customerEmail, mobile, address, credit_limit} = state;
        const limit = !credit_limit ? 0 : credit_limit;
        setSubmitted(true)
        if (customerName && customer_category && mobile) {
            const category = customer_category ? customer_category : {}
            const obj =   {customer_category_id:category.value ? +category.value : '', category_name: category.label ? category.label : '',
                customer_name:customerName, customerEmail, mobile, address,  credit_limit: +limit,}
            const arr = [...item, obj]
            sessionStorage.setItem('customers', JSON.stringify(arr))
            setAddedCustomers(arr)
            setIsShow(false)
            setSubmitted(false)
            setState({customerName: '', customerEmail: '',
                mobile: '', address: '', previousBalance: '', credit_limit: ''})
            setCustomerCategory(null)
        }
        setIsBlocking(true)
    }

    const handleRemoveCustomer = (idx) =>{
        const arr = addedCustomers.filter((_, index)=>idx !== index)
        sessionStorage.setItem('customers', JSON.stringify(arr))
        setAddedCustomers(arr)
        if(arr.length === 0){
            setIsShow(true)
        }
        setIsBlocking(true)
    }

    const postCustomerData = (jsonData, isMultiple=false) =>{
        const url = customer_id ? 'customer_update' : 'insert_customer'
        setIsBlocking(false)
        setIsSubmitted('pending')
        axios.post(`${config.epharmUrl}/Ccustomer/${url}`, jsonData)
            .then(() => {
                setIsSubmitted('resolved')
                actions.snackbarActions.successSnackbar(`Customer ${customer_id ? 'updated':'saved'} successfully!`);
                if(isMultiple){
                    history.push('/managecustomer/manage_customer')
                }
                sessionStorage.removeItem('customers')
                setOpenCustomerDialog(false)
            }).catch(error => {
            setIsSubmitted('rejected')
            errorMessages(error, null, actions)
        })
    }

    const handleSubmit = event => {
        event.preventDefault();
        const {customerName, customerEmail, mobile, address,  credit_limit} = state;
        const limit = !credit_limit ? 0 : credit_limit;
        setSubmitted(true)
        const category = customer_category ? customer_category : {}
        const obj =    {customer_category_id: category.value ? +category.value : '', category_name: category.label ? category.label : '',
            customer_name:customerName, customer_email:customerEmail, customer_mobile:mobile, customer_address:address,  credit_limit: limit,customer_id}
        if (customerName && customer_category && mobile) {
            const jsonData = customer_id ? {...obj, customer_id} : [obj]
            postCustomerData(jsonData)
        }
    };

    const handleSubmitMultiple = event => {
        event.preventDefault();
        const arr = addedCustomers.map(({mobile,address,customerEmail,...item})=>({...item,customer_mobile:mobile ?? '', customer_email:customerEmail ?? '',customer_address: address ?? ''}))
        postCustomerData(arr,true)
    };

    const handleCloseBar = () => {
        actions.snackbarActions.hideSnackBar();
    };

    const isPending = isSubmitted === 'pending'
    const isResolved = isSubmitted === 'resolved'

    const customerProps = {isSubmitted, submitted,state, handleChange, handleSubmit,customer_categories, customer_category,
        openCustomerDialog, handleCloseCustomerDialog,isPending, handleOpenCustomerDialog, isShowForm, addCustomer,
        addedCustomers, handleSubmitMultiple,addAnotherCustomer, handleRemoveCustomer, cancelAdd,handleChangeCategory}
    return { openDialog, customerProps, handleOpenDialog, handleCloseDialog, providerProps,
         handleCloseBar, isResolved, isBlocking}
}