import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import * as colors from  "../../styles/colors"
import styled from "@emotion/styled/macro";



const WINDOW_HEIGHT = window.innerHeight
const height = WINDOW_HEIGHT - 370

const WINDOW_WIDTH = window.innerWidth
const w = WINDOW_WIDTH - 282


const useStyles = (width) => makeStyles((theme)=>({
  root: {
    width:'100%',
    [theme.breakpoints.down('sm')]:{
      width: width ? width: w,
    },
    [theme.breakpoints.up('md')]:{
      width: width ? width: w,
    },
    [theme.breakpoints.up('lg')]:{
      width: width ? width: w,
    },
    [theme.breakpoints.up('xl')]:{
      width: width ? width: w,
    }
    
  },
  container: {
    maxHeight: height,
    [theme.breakpoints.down('sm')]:{
      width: width ? width: w,
    },
    [theme.breakpoints.up('md')]:{
      width: width ? width: w,
    },
    [theme.breakpoints.up('lg')]:{
      width: width ? width: w,
    },
    [theme.breakpoints.up('xl')]:{
      width: width ? width: w,
    }
  },
  tableCell:{
    paddingLeft:8,
    paddingRight:8,
    fontWeight:600
  },
  stickyHeader:{
    backgroundColor:'#fff',
    fontWeight:600
  },
  borderCell:{
    borderRight: `1px solid ${colors.gray500}`
  }
}));

const CustomTableCell = ({children, ...props}) =>{
  const classes = useStyles();
  return(<TableCell className={classes.tableCell} {...props}>{children}</TableCell>)
}

const CustomHeadCell = styled(CustomTableCell)({
  borderRight: `1px solid ${colors.gray500}`,
  borderTop: `1px solid ${colors.gray500}`,
  fontWeight:700
})

function StickyHeadTable({width, children, columns=[],  parentHeadData=null, totalHead=null, pagination=null, toolbar=null, testId=""}) {
  const classes = useStyles(width)();


  return (
        <TableContainer className={classes.container}>
          {toolbar}
          <Table stickyHeader aria-label="sticky table" data-testid={`${testId}`}>
            <TableHead>
              {parentHeadData}
              <TableRow  aria-label="sticky table">
                {columns.map((column) => (
                    <CustomTableCell
                        aria-label="sticky table"
                        key={column.id}
                        align={column.align}
                        style={{ minWidth: column.minWidth,top: parentHeadData === null ? 0: 57 }}
                        className={column.isBorder ?  classes.borderCell : null}
                        // classes={{stickyHeader:classes.stickyHeader}}
                        // sx={{  }}
                    >
                      <strong>{column.label}</strong>
                    </CustomTableCell>
                ))}
              </TableRow>
              {totalHead}
            </TableHead>
            <TableBody>
              {children}
            </TableBody>
          </Table>
          {pagination}
        </TableContainer>



  );
}
export {StickyHeadTable, CustomTableCell, CustomHeadCell};
