import React from 'react';
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form"
import TextField from "../../Utils/FormInputs/TextField";
import Textarea from "../../Utils/FormInputs/Textarea";
import Label from "../../Utils/FormInputs/Label";
import  {CustomSelect} from "../../Utils/FormInputs/SelectInput";

import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

const ReusableOverlay = ({children, tooltip, id='', placement='right'}) =>{
    return(
        <OverlayTrigger
            placement={placement}
            delay={{ show: 250, hide: 400 }}
            overlay={<Tooltip id={`${id}-tooltip}`}>
                {tooltip}
            </Tooltip>}
        >{children}</OverlayTrigger>
    )
}


const ColumnCustomerLayout = ({handleChangeCategory,submitted, state, handleChange,customer_category, children,customer_categories=[]}) => {
    const {customerName, customerEmail, mobile, address,  credit_limit, } = state;

    return (
        <form  autoComplete="off" >
            <div className='row'>
                <div className='col-lg-6'>
                    <Form.Group as={Col} data-testid="customer_category">
                        <Label name='Category' type/>
                        <CustomSelect  defaultOption='Select category...' name='customer_category'   value={customer_category} onChange={handleChangeCategory}
                                     options={customer_categories} submitted={submitted}/>
                        {(submitted && !customer_category) &&
                            <div className="invalid-text">Please enter the customer category</div>}

                    </Form.Group>
                    <Form.Group as={Col} controlId="formGridEmail">
                        <Label name='Customer Name' type/>
                        <TextField data-testid="customer_name" type="text" placeholder="Customer name" name="customerName"
                                   value={customerName} onChange={handleChange} submitted={submitted}/>
                        {(submitted && !customerName) &&
                            <div className="invalid-text">Please enter the customer name</div>}
                    </Form.Group>
                    <Form.Group as={Col} controlId="formGridAddress2">
                        <Label name='Customer Address' />
                        <Textarea data-testid="customer_address" as="textarea" name="address" value={address}
                                  onChange={handleChange}/>

                    </Form.Group>
                </div>
                <div className='col-lg-6'>
                    <Form.Group as={Col} controlId="formGridAddress1">
                        <Label name='Customer Mobile' type/>
                        <TextField data-testid="customer_mobile" type="text" name="mobile" value={mobile}
                                   onChange={handleChange} submitted={submitted}/>
                        {(submitted && !mobile) &&
                            <div className="invalid-text">Please enter the customer mobile number</div>}

                    </Form.Group>

                    <Form.Group as={Col} controlId="formGridPassword">
                        <Label name='Customer Email' />
                        <TextField data-testid="customer_email" type="email" placeholder="Email Address" name="customerEmail"
                                   value={customerEmail} onChange={handleChange}/>
                    </Form.Group>

                    <Form.Group as={Col} controlId="formGridCity">
                        <Label name='Credit Limit' />
                        <ReusableOverlay
                                    tooltip='Maximum amount of money for a credit purchase'
                                    id='credit-limit'
                                >
                        <TextField data-testid="credit_limit" type="number" name="credit_limit" value={credit_limit}
                                   onChange={handleChange}/>
                        </ReusableOverlay>

                    </Form.Group>
                </div>
            </div>
            {children}
        </form>

    )

}

const RowCustomerForm = ({handleSubmit,submitted, state, handleChange, customer_category, isSubmitted,handleChangeCategory,customer_categories=[]}) => {
    const {customerName, customerEmail, mobile, address, credit_limit} = state;

    return (
        <form onSubmit={handleSubmit} autoComplete="off" >
            <Form.Group as={Col} data-testid="customer_category">
                <Label name='Category' type/>
                <CustomSelect defaultOption='Select category...' name='customer_category'   value={customer_category} onChange={handleChangeCategory}
                              options={customer_categories} submitted={submitted}/>
                {(submitted && !customer_category) &&
                    <div className="invalid-text">Please enter the customer category</div>}
            </Form.Group>
            <Form.Group as={Col} controlId="formGridEmail">
                <Label name='Customer Name' type/>
                <TextField data-testid="customer_name" type="text" placeholder="Customer name" name="customerName"
                            value={customerName} onChange={handleChange} submitted={submitted}/>
                {(submitted && !customerName) &&
                    <div className="invalid-text">Please enter the customer name</div>}
            </Form.Group>
            <Form.Group as={Col} controlId="formGridAddress1">
                <Label name='Customer Mobile' type/>
                <TextField  data-testid="customer_mobile"type="text" name="mobile" value={mobile}
                           onChange={handleChange} submitted={submitted}/>
                {(submitted && !mobile) &&
                    <div className="invalid-text">Please enter the customer mobile number</div>}
            </Form.Group>
            <Form.Group as={Col} controlId="formGridPassword">
                <Label name='Customer Email' />
                <TextField data-testid="customer_email" type="email" placeholder="Email Address" name="customerEmail"
                           value={customerEmail} onChange={handleChange}/>
            </Form.Group>
                    <Form.Group as={Col} controlId="formGridAddress2">
                        <Label name='Customer Address' />
                        <Textarea data-testid="customer_address" as="textarea" name="address" value={address}
                                  onChange={handleChange}/>
                    </Form.Group>
                    <Form.Group as={Col} controlId="formGridCity">
                        <Label name='Credit Limit' />
                        <ReusableOverlay
                                    tooltip='Maximum amount of money for a credit purchase'
                                    id='credit-limit'
                                >
                        <TextField data-testid="credit_limit" type="number" name="credit_limit" value={credit_limit}
                                   onChange={handleChange}/>
                        </ReusableOverlay>

                    </Form.Group>
            <button data-testid="save_customer" type="submit" disabled={isSubmitted === 'pending'}
                    className={`btn pharmacy-btn offset-md-5 `}>{isSubmitted === 'pending' ? "Submitting..." : "Submit"}</button>
        </form>
    )
}

export {RowCustomerForm, ColumnCustomerLayout}
