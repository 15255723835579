import React from "react";
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPencilAlt} from "@fortawesome/free-solid-svg-icons/faPencilAlt";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Tooltip from '@material-ui/core/Tooltip';
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import * as snackbarActions from "../../Redux/Actions/SnackbarActions/SnackbarActions";
import {CustomPagination} from "../../Utils/Tables/CustomTable";
import PharmacySnackbar from "../../Utils/Snackbars/SnackbarUtil";
import {splitText, titleCase} from "../../Utils/titleCaseFunction";
import ReusableDashboardHeader from "../../Containers/Dasboard/ReusableDashboardHeader";
import TextField from "../../Utils/FormInputs/TextField";
import {EnhancedTableHead, EnhancedTableToolbar, MuiPaper} from "../../Utils/Tables/CustomTable";
import TableContainer from "@material-ui/core/TableContainer";
import {useManageManufacturer} from "./CustomHooks/useManageManufacturer";
import DeleteDialog from "../../Utils/DeleteDialog";
import FilterResults from 'react-filter-search';
import DetailsDialog from "../../Utils/Dialog/DetailsDialog";
import {ManufacturerForm} from "./ManufacturerForm";
import {history} from "../../Helpers/history";
import {RightAlignedContainer} from "../../Utils/styledComponents";
import {LoadingGif} from "../../Utils/Loader";
import {useExcelReports} from "../Invoice/CustomHooks/useExcelReports";


const ManageManufacturer = ({actions, snackbars}) => {
    const {
        activePage, manufacturers, total_count,
        openDelete,  loading,
        handleOpenDelete, handleCloseDelete,
        handleDeleteManufacturer, handler, handlePageChange,
        handleCloseBar, manufacturerProps, isSuccess, isLoading, isError
    } = useManageManufacturer(actions)
    const {handleCloseEdit,  openEdit, handleOpenEdit, isPending, handleSubmit} = manufacturerProps
    const currentObj = {file_name:'Suppliers', url:'Cmanufacturer/supplier_list_report'}

    const {exportExcel} = useExcelReports(actions, currentObj)
    const {open, message, variant} = snackbars;
    const user = JSON.parse(sessionStorage.getItem('user'));
    const singleUser = !user ? {} : user;
    const permissions = !singleUser.permission ? {} : singleUser.permission;
    const manage_manufacturer = !permissions.manage_manufacturer ? {} : permissions.manage_manufacturer;
    const headData = [
        {id: 'name', numeric: false, disablePadding: false, label: 'Supplier Name'},
        {id: 'address', numeric: false, disablePadding: false, label: 'Address'},
        {id: 'mobile', numeric: false, disablePadding: false, label: 'Mobile'},
        {id: 'details', numeric: false, disablePadding: false, label: 'Details'},
        (manage_manufacturer.create || manage_manufacturer.update || manage_manufacturer.delete)
            ? {id: 'action', numeric: false, disablePadding: false, label: 'Action'} :
            {id: 'nonaction', numeric: false, disablePadding: false, label: ''}
    ];
    return (
        <div className='journals'>
            <ReusableDashboardHeader component="Suppliers" heading="Supplier"
                                     subHeading="Suppliers" link="/managemanufacturer"/>
            <div className='general-ledger-header'>
                <div className='text-right'>
                    <button onClick={()=>history.push('/addmanufacturer')} className='btn btn-sm pharmacy-info-btn'>New Supplier
                    </button>
                </div>
            </div>
            <DetailsDialog openDialog={openEdit}  title='Edit Supplier' handleClose={handleCloseEdit}>
                <ManufacturerForm {...manufacturerProps}>
                    <RightAlignedContainer>
                        <button type="submit" onClick={handleSubmit} className="btn btn-sm pharmacy-btn">{isPending ? 'Saving...':'Save'}</button>
                    </RightAlignedContainer>
                </ManufacturerForm>
            </DetailsDialog>
            <PharmacySnackbar handleCloseBar={handleCloseBar} variant={variant} open={open} message={message}/>
            <DeleteDialog handleClose={handleCloseDelete} handleDelete={handleDeleteManufacturer}
                          openDialog={openDelete} message='supplier'/>
            <MuiPaper>
                <EnhancedTableToolbar title='Suppliers'>
                    <span className="btn-group" style={{flex: '1 1 100%'}}>
                        <span><button onClick={exportExcel} className="btn btn-sm pharmacy-btn mr-1">Excel</button></span>
                    </span>
                    <TextField type="text" data-testid="search_supplier" onChange={event => handler(event.target.value)}
                               placeholder="Search" style={{float: 'right'}}/>
                </EnhancedTableToolbar>
                <TableContainer>
                    <Table
                        aria-labelledby="tableTitle"
                        size='medium'
                        aria-label="enhanced table"
                    >
                        <EnhancedTableHead headCells={headData}/>
                            <TableBody>
                                {isLoading ?    <TableRow>
                                        <TableCell colSpan={headData.length} align="center"><LoadingGif/></TableCell>
                                    </TableRow>:null}
                                {isSuccess ? manufacturers.length > 0 ? 
                                    <>
                                    {manufacturers.slice(0, 10)
                                            .map((d, index) => (
                
                                                    <TableRow
                                                        hover
                                                        role="checkbox"
                                                        tabIndex={-1}
                                                        key={d.manufacturer_id}
                                                        className='mui-table-row'>
                                                        <TableCell data-testid={"supplier_name-"+index}>
                                                            {/*<Link to={{pathname: `/manufacturerledgerinfo/${d.manufacturer_id}`}}*/}
                                                            {/*    style={{color: "#09905c"}}>*/}
                                                            {titleCase(d.manufacturer_name)}
                                                            {/*</Link>*/}
                                                        </TableCell>
                                                        <TableCell data-testid={"supplier_address-"+index}>
                                                            <span>{splitText(d.address ?? '')}</span>
                                                        </TableCell>
                                                        <TableCell data-testid={"supplier_mobile-"+index}>
                                                            <span>{d.mobile}</span>
                                                        </TableCell>
                                                        <TableCell data-testid={"supplier_details-"+index}>
                                                            <span>{splitText(d.details ?? '')}</span>
                                                        </TableCell>
                                                        <TableCell id="mainbuttons">
                                                            <Link data-testid={"supplier_purchases-"+index}
                                                                to={{pathname: `/manufacturersales/${d.manufacturer_id}`}}>
                                                                <button style={{marginBottom: '4px'}}
                                                                        className="btn pharmacy-btn btn-sm mr-1 ">
                                                                    View Purchases
                                                                </button>
                                                            </Link>
                                                            <Tooltip title="Update"
                                                                     placement="left-start">
                                                                <button data-testid={"edit_supplier-"+index} style={{marginBottom: '4px'}} onClick={()=>handleOpenEdit(d.manufacturer_id)}
                                                                        className="btn pharmacy-info-btn btn-sm mr-1 ">
                                                                    <FontAwesomeIcon
                                                                        icon={faPencilAlt}/>
                                                                </button>
                                                            </Tooltip>
                                                            {/*{(manage_manufacturer.update || manage_manufacturer.delete) ?*/}
                                                            {/*    <span>*/}
                                                            {/*        /!* {(manage_manufacturer.delete) ?*/}
                                                            {/*            <Tooltip title="Delete"*/}
                                                            {/*                     placement="left-start">*/}
                                                            {/*                <button style={{marginBottom: '4px'}}*/}
                                                            {/*                        className="btn pharmacy-btn-dismiss btn-sm"*/}
                                                            {/*                        onClick={() => handleOpenDelete(d.manufacturer_id)}>*/}
                                                            {/*                    <FontAwesomeIcon*/}
                                                            {/*                        icon={faTrash}/></button>*/}
                                                            {/*            </Tooltip> : null} *!/*/}
                                                            {/*    </span> : null}*/}
                                                        </TableCell>
                                                    </TableRow>
                                          
                                            ))}
                                    </>
                                    :
                                    <TableRow>
                                    <TableCell colSpan={headData.length} align="center">No records found</TableCell>
                                    </TableRow>:null}
                                {isError ? <TableRow>
                                    <TableCell colSpan={headData.length} align="center">The server did not return a valid response</TableCell>
                                </TableRow>:null}
                            </TableBody>

                    </Table>
                </TableContainer>
                <CustomPagination data={manufacturers} records={10} activePage={activePage}
                                  total_count={total_count}
                                  handleChangeNextPage={handlePageChange}/>
            </MuiPaper>
        </div>
    )


}

function mapStateToProps(state) {
    return {
        snackbars: state.snackbars
    }

}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            snackbarActions: bindActionCreators(snackbarActions, dispatch)
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ManageManufacturer);