import React, {useEffect, useState} from 'react';
import axios from "axios";
import config from '../../../../Helpers/config.json';
import {errorMessages} from "../../../../Helpers/ErrorMessages";
import {useDates} from "../../Expenses/hooks/useDates";

export const useProfitLossStatement = (actions, url) => {
    const [data, setData] = useState(null);
    const [date, handleChangeDate] = useDates();
    const [loading, setLoading] = useState('idle')
    const [submitted, setSubmitted] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState('idle');
    const {from_date, to_date} = date;

    const formatData = (data={}) =>{
        let arr = []
        for (let item in data){
            let obj = {}
            if( typeof data[item] === "number"){
               const head_name = item.split('_').join(' ')
                const upper_case_name = head_name.toUpperCase()
                obj = {...obj ,head_name:upper_case_name, balance:data[item],
                    head_level:-1, isParent:true,children:[]}
            }
            if(typeof data[item] === "object"){
                obj = {...obj,
                    head_name: data[item]['category_name'],
                    head_level:-1,
                    children: data[item]['accounts'],
                    balance: data[item]['category_balance'],
                    isParent:true
                }
            }

            arr.push(obj)
        }

        return arr
    }

    const parseData = (items) =>{
        let orderedArr = [{head_name:'SALES'},{head_name:'COGS'},{head_name:'GROSS PROFIT'},
            {head_name:'EXPENSES'}, {head_name:'PROFIT BEFORE TAX'}]
        let arr = []
        for (let item =0; item < orderedArr.length; item++){
            let obj={}
            for (let row=0; row < items.length; row++){
                if(orderedArr[item].head_name === items[row].head_name){
                    obj = {
                        head_name:orderedArr[item].head_name, head_level:items[row].head_level, children:items[row].children,
                        balance:items[row].balance, isParent:items[row].isParent
                    }
                }
            }
            arr.push(obj)
        }
        return arr
    }

    useEffect(() => {
        setLoading('pending')
        axios.get(`${config.epharmUrl}/accounting/${url}`,{params:{start_date:from_date, end_date:to_date}}).then(res => {
            const data = res.data;
            const dt = data ? data : {};
            const dat = dt.data ? dt.data : {}
            const obj = formatData(dat)
            const arr = parseData(obj)
            setData(arr)
            setLoading('success')
        }).catch(err => {
            errorMessages(err, null, actions)
            setLoading('error')
        })

    }, [])





    const handleSubmit = (event) =>{
        setSubmitted(true)
        event.preventDefault()
        setLoading('pending')
        setIsSubmitted('pending')
        axios.get(`${config.epharmUrl}/accounting/${url}`, {params:{start_date:from_date, end_date:to_date}}).then(res => {
            const data = res.data;
            const dt = data ? data : {};
            const dat = dt.data ? dt.data : {}
            const obj = formatData(dat)
            const arr = parseData(obj)
            setData(arr)
            setLoading('success')
            setIsSubmitted('resolved')
        }).catch(err => {
            errorMessages(err, null, actions)
            setLoading('error')
            setIsSubmitted('rejected')
        })
    }

    const closeSnackbar = () => {
        actions.snackbarActions.hideSnackBar()
    }
    const isLoading = loading === 'pending';
    const isSuccess = loading === 'success';
    const isError = loading === 'error';

    const isPending = isSubmitted === 'pending';
    const isResolved = isSubmitted === 'resolved';
    const isRejected = isSubmitted === 'rejected';

    return {date,  data, handleChangeDate,  closeSnackbar, isLoading, isSuccess, isError,
        submitted,isPending, isRejected,  isResolved, handleSubmit}
}