import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import {makeStyles} from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import Collapse from "@material-ui/core/Collapse";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";


const useRowStyles = makeStyles({
    root: {
        '& > *': {
            borderBottom: 'unset',
        },
    },
    h6:{
        fontSize:'17.44px'
    },
    icon:{
       fontSize:'1.2rem'
    }
});



export default function CollapsibleTable({headData, children}) {
    return (
            <Table stickyHeader  aria-label="collapsible table">
                <TableHead>
                    <TableRow>
                        {headData.map((head,index)=>(
                            <TableCell key={index}>{head.name}</TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {children}
                </TableBody>
            </Table>
    );
}

export function CollapsibleRow({open,title, handleOpen, children,item, headData=[], tableData=null}) {
    const classes = useRowStyles();

    return (
        <React.Fragment>
            <TableRow className={classes.root}>
                <TableCell>
                    <IconButton aria-label="expand row" size="small" onClick={()=>handleOpen(item)}>
                        {item.open ? <KeyboardArrowUpIcon className={classes.icon}/> : <KeyboardArrowDownIcon className={classes.icon}/>}
                    </IconButton>
                </TableCell>
                {children}
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={item.open} timeout="auto" unmountOnExit>
                        <Box margin={1}>
                            <Typography variant="h6" gutterBottom component="div" classes={{h6:classes.h6}}>
                                <strong>{title}</strong>
                            </Typography>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        {headData.map((head,index)=>(
                                            <TableCell key={index}>{head.name}</TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {tableData}
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
}
