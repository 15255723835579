import React, {useCallback, useEffect, useRef, useState} from "react";
import {ReusableRouteTabs} from "../../Utils/Tabs/ReusableTabs";
import {history} from "../../Helpers/history";
import ReusableDashboardHeader from "../../Containers/Dasboard/ReusableDashboardHeader";
import {CustomPagination, EnhancedTableHead, EnhancedTableToolbar, MuiPaper} from "../../Utils/Tables/CustomTable";
import Form from "react-bootstrap/Form";
import {titleCase, dateStringTimeConvert} from "../../Utils/titleCaseFunction";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Tooltip from "@material-ui/core/Tooltip";
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEye} from "@fortawesome/free-solid-svg-icons/faEye";
import axios from "axios";
import config from "../../Helpers/config.json";
import {useSalesFilter} from "./CustomHooks/useSalesFilter";
import {InvoiceDateFilter} from "./InvoiceDateFilter";
import CloseIcon from "@material-ui/icons/Close";
import {makeStyles} from "@material-ui/core/styles";
import {errorMessages} from "../../Helpers/ErrorMessages";
import Label from "../../Utils/FormInputs/Label";
import Textarea from "../../Utils/FormInputs/Textarea";
import CustomDialog from "../../Utils/Dialog/CustomDialog";
import {LoadingGif} from "../../Utils/Loader";
import {CancelDialog} from "../../Utils/DeleteDialog";
import {SalesReceiptTemplate} from "./SalesReceiptTemplate";
import {bindActionCreators} from "redux";
import * as snackbarActions from "../../Redux/Actions/SnackbarActions/SnackbarActions";
import {connect} from "react-redux";
import PharmacySnackbar from "../../Utils/Snackbars/SnackbarUtil";
import { useExcelReports } from "./CustomHooks/useExcelReports";
import { usePrint } from "../../Utils/Template/usePrint";
import PrintOutlinedIcon from "@material-ui/icons/PrintOutlined";
import { errorSnackbar } from "../../Redux/Actions/SnackbarActions/SnackbarActions";
import { RightAlignedContainer } from "../../Utils/styledComponents";
import { formatDigits } from "../../Utils/formatAmount";
import { formatAmount } from "../../Utils/formatAmount";

const headData = [
    {id: 'sl', numeric: false, disablePadding: false, label: 'Sl'},
    {id: 'date', numeric: false, disablePadding: false, label: 'Date'},
    {id: 'invoice', numeric: false, disablePadding: false, label: 'Receipt#'},
    {id: 'name', numeric: false, disablePadding: false, label: 'Customer Name'},
    {id: 'total', numeric: false, disablePadding: false, label: 'Total Amount'},
    {id: 'status', numeric: false, disablePadding: false, label: 'Status'},
    {id: 'action', numeric: false, disablePadding: false, label: 'Action'}
]
const useStyles = makeStyles((theme) => ({
    icon: {
        width:'0.8em',
        height:'0.8em',
    },
    paid: {
        color: "#1d5dd3"
    },
    canceled:{
        color: '#a30c0c'
    }
}));

function SalesReceipts({actions, snackbars,match: {params}}) {
    const classes = useStyles()
    const storage = JSON.parse(sessionStorage.getItem('total_count'))
    const item_count = storage ? storage : 0
    const count = () => +item_count || 0

    const [term, setTerm] = useState('');
    const [activePage, setActivePage] = useState(1);
    const [totalAmount, setTotalAmount] = useState(0);
    const [total_Amount, setAmount] = useState(0);
    const [loading, setLoading] = useState('idle')
    const [salesReceipts, setSalesReceipts] = useState([])
    const [total_count, setTotalCount] = useState(count)
    const [submitted, setSubmitted] = useState(false)
    const [isSubmitted, setIsSubmitted] = useState('idle')
    const [isCanceled, setIsCanceled] = useState('idle')
    const [openInvoice, setOpenInvoice] = useState(false)
    const [openConfirmation, setOpenConfirmation] = useState(false)
    const [submit, setHasSubmitted] = useState(false);
    const [reason,setReason] = useState('')
    const [invoice_number, setInvoiceNumber] = useState('');
    const [openDialog, setOpenDialog] = useState(false);
    const [receiptData, setReceiptData] = useState({products:[], customer:{}, loading:'idle'});
    const {handlePrint, componentRef} = usePrint('')
    const {date, handleChange, filters} = useSalesFilter()
    const {filter_by, start_date, end_date} = date



    useEffect(()=>{
        setActivePage(1)
    },[filter_by])

    // const calculateTotalAmount = (arr) => {
    //     const sum = arr.reduce((a, invoice) => {
    //         return +(a + invoice.total_amount);
    //     }, 0);
    //     setTotalAmount(sum)
    // };

    const isInvoiceCanceled = isCanceled === 'resolved'
    const isCanceledPending = isCanceled === 'pending'
    const isResolved = isSubmitted === 'resolved'

    const retrieveReceipts = useCallback((start_date, end_date, page=activePage) =>{
        const isCustom = filter_by === 'custom' ? {start_date, end_date} : {}
        setLoading('loading')
        axios.get(`${config.epharmUrl}/Cinvoice/invoices`,{params:{category:'receipts', time_filter:filter_by,
                page_number:page, search:term, ...isCustom}}).then(response => {
            const res = response.data;
            const dt = !res.data ? {} : res.data;
            const arr = dt.invoices ? dt.invoices : {}
            const receiptArr = arr.invoice_list ? arr.invoice_list : []
            const t_amount =  arr.grand_total_amount ? arr.grand_total_amount : 0
            const total_amount =arr.total_amount ? arr.total_amount : 0
            const t_count = dt.total_records ? dt.total_records : 0;
            if(activePage === 1){
                sessionStorage.setItem('total_count', JSON.stringify(t_count))
                setTotalCount(t_count)
                
            }else {
               setTotalCount(count)
            }
            setTotalAmount(t_amount)
            setAmount(total_amount)
            setLoading('success')
            setSalesReceipts(receiptArr)
            // calculateTotalAmount(receiptArr)
        }).catch(error => {
            errorMessages(error, null, actions)
            setLoading('error')
        });
    },[activePage,  filter_by,isInvoiceCanceled, term])

    useEffect(() => {
        if (filter_by === 'custom' && !isResolved){
            return
        }
        retrieveReceipts(start_date, end_date)

    }, [retrieveReceipts])



    const handler = (event) => {
        setTerm(event.target.value)
    };

    const handleFilter = (event) =>{
        event.preventDefault()
        setSubmitted(true)
        if (start_date && end_date && start_date <= end_date){
            setIsSubmitted('resolved')
            retrieveReceipts(start_date, end_date)
        }
    }


    const handleOpenDialog = () => {
        setOpenDialog(true)
    };

    const handleCloseDialog = () => {
        setOpenDialog(false)
    };

    const handleChangeReason = (event) => {
        setReason(event.target.value)
    };

    const handleOpenPrint = (item)=>{
        const {invoice_number:inv_number, receipt_number:recep_number, customer_id:cus_id} = item
        const formData = new FormData()
        formData.append('receipt_number', recep_number)
        formData.append('invoice_number', '')
        formData.append('customer_id', cus_id)
        setReceiptData({...receiptData, loading: 'loading'})
        axios.post(`${config.epharmUrl}/Cinvoice/view_receipt`, formData).then(response => {
            const dataRes = response.data;
            const dt = dataRes ? dataRes : {};
            const arr = dt.bill ? dt.bill : [];
            const customer_details = dt.customer_details ? dt.customer_details : {}
            const obj = arr[0] ? arr[0] : {}
            setReceiptData({...receiptData,products: arr,customer: {...customer_details,...obj}, loading: 'success'})
            handlePrint()
        }).catch(error => {
            errorMessages(error, null, actions)
            setReceiptData({...receiptData, loading: 'error'})
        })
        setInvoiceNumber(inv_number);
    }

    const handleOpenInvoice = (item) => {
        const {invoice_number:inv_number, receipt_number:recep_number, customer_id:cus_id} = item
        const formData = new FormData()
        formData.append('receipt_number', recep_number)
        formData.append('invoice_number', '')
        formData.append('customer_id', cus_id)
        setReceiptData({...receiptData, loading: 'loading'})
        axios.post(`${config.epharmUrl}/Cinvoice/view_receipt`, formData).then(response => {
            const dataRes = response.data;
            const dt = dataRes ? dataRes : {};
            const arr = dt.bill ? dt.bill : [];
            const customer_details = dt.customer_details ? dt.customer_details : {}
            const obj = arr[0] ? arr[0] : {}
            setReceiptData({...receiptData,products: arr,customer: {...customer_details,...obj}, loading: 'success'})
        }).catch(error => {
            errorMessages(error, null, actions)
            setReceiptData({...receiptData, loading: 'error'})
        })
        setInvoiceNumber(inv_number);
        // setReceiptNumber(recep_number)
        setOpenInvoice(true)
    };

    const handleCloseInvoice = () => {
        setOpenInvoice(false)
    };

    const handleDelete = (is_draft=false) => {
        setHasSubmitted(true)
        if (reason) {

            setIsCanceled('pending')
            axios.post(`${config.epharmUrl}/Cinvoice/cancel_invoice`, {
                invoice_number: invoice_number,
                reason: reason
            }).then(() => {
                actions.snackbarActions.successSnackbar('Invoice canceled Successfully');
                setIsCanceled('resolved')
                setHasSubmitted(false)
                setReason('')
                setOpenDialog(false)
                setOpenInvoice(false)
                if (is_draft) {
                    sessionStorage.setItem(invoice_number, JSON.stringify(receiptData))
                    history.push(`/new-receipt/${invoice_number}`)
                }
                setOpenConfirmation(false)
            }).catch(error => {
                errorMessages(error, null, actions)
                setIsCanceled('rejected')
            })
        }

    }

    const handleOpenConfirmation = () =>{
        setHasSubmitted(true)
        if (reason) {
            setOpenConfirmation(true)
        }
    }

    const handleCloseConfirmation = () =>{
        setOpenConfirmation(false)
    }

    const handleSaveDraft = () =>{
        handleDelete(true)
    }

    const handleCancelInvoice = () =>{
        handleDelete()
    }

    /*const downloadPDF = () => {
        const allReceipts = !salesReceipts ? [] : salesReceipts;
        exportPDF("Invoice Report", [[ "Date", "Receipt#", "Customer Name", "Total Amount"]],
            allReceipts.map((receipt, index) => [receipt.date, receipt.invoice, receipt.customer_name,
                 receipt.total_amount]), "Receipts.pdf");
    }; */

    const handlePageChange = (pageNumber) => {
        setActivePage(pageNumber)
    };

    const {products, customer, loading:loadingInvoice} = receiptData



    const handleCloseSnackbar = () => {
        actions.snackbarActions.hideSnackBar();
    };

    const {variant, message, open} = snackbars;

    const isLoading = loading === 'loading'
    const isSuccess = loading === 'success'
    const isError = loading === 'error'

    const isLoadingInvoice = loadingInvoice === 'loading'
    const isSuccessInvoice = loadingInvoice === 'success'
    const isErrorInvoice = loadingInvoice === 'error'

    const isCustom = filter_by === 'custom' ? {start_date, end_date} : {};
    const currentObj = {file_name:'Sales', url:'Cinvoice/manage_sales_excel_report',
    params: {
        category:'receipts', time_filter: filter_by, ...isCustom
    }
    }

    const {exportExcel} = useExcelReports(actions, currentObj);

    const handleExcelExport = () => {
        if (salesReceipts?.length === 0) {
            actions.snackbarActions.errorSnackbar('There are no records to be downloaded');
        } else {
            exportExcel();
        }
    }

    const components = [{label: 'Receipts', path: '/sales-receipts'},
        {label: 'Invoices', path: '/manageinvoice'},
        {label: 'Cancelled Sales', path: '/cancelledsales'},
    ];

    const buttons = (
        <>
            <button className="btn pharmacy-info-btn  btn-sm mr-3" data-testid="continue-cancel"
                    onClick={handleOpenDialog}>Continue</button>
            <button className="btn pharmacy-grey-btn btn-sm"
                data-testid="no-cancel-button"
            onClick={handleCloseInvoice}>No</button>
        </>
    )

    const reasonForm = (
        <Form.Group>
            <Label name='Please enter reason for cancelling receipt' type/>
            <Textarea type="text" placeholder="please type reason here..." name="Reason" data-testid="reason-text-cancel"
                      value={reason} onChange={handleChangeReason} submitted={submit}/>
            {(submit && !reason) &&
                <div className="invalid-text">Please enter a reason</div>}

        </Form.Group>
    )

    return(
        <div>
            <ReusableDashboardHeader component='Manage Sales' heading="Sales"
                                     subHeading='Receipts' link={history.location.pathname}/>

            <PharmacySnackbar open={open} message={message} variant={variant}
                              handleCloseBar={handleCloseSnackbar}/>
            

            <div className='receipt-container'>
                <CustomDialog maxWidth='md' title='Cancel Receipt' open={openInvoice} handleClose={handleCloseInvoice} buttons={buttons}
                              isOverflow={false}>
                    {isLoadingInvoice ? <div className='text-center mt-5'><LoadingGif/></div>:null}
                    {isSuccessInvoice ? <SalesReceiptTemplate {...{customer, products}}/> : null}
                    {isErrorInvoice ? <div className='text-center mt-5'>The server did not return a valid response</div>:null}
                </CustomDialog>

                <CancelDialog openDialog={openDialog}
                              handleClose={handleCloseDialog}
                              heading='Cancel Receipt' message={reasonForm}>
                    <button className="btn pharmacy-info-btn  btn-sm mr-3" disabled={isCanceledPending}
                            data-testid="cancel_receipt"
                            onClick={handleOpenConfirmation}>{isCanceledPending ? 'Continuing...' : 'Cancel Receipt'}</button>
                    <button data-testid="dont_cancel_receipt" className="btn pharmacy-grey-btn btn-sm" onClick={handleCloseDialog}>Don't Cancel Receipt</button>

                </CancelDialog>

                <CancelDialog openDialog={openConfirmation}
                              handleClose={handleCloseConfirmation}
                              heading='Use invoice as draft'
                              message="Would you like to use this receipt as a draft?">
                    <button className="btn pharmacy-info-btn  btn-sm mr-3" data-testid="yes-draft"
                            onClick={handleSaveDraft}>Yes</button>
                    <button className="btn pharmacy-grey-btn btn-sm" onClick={handleCancelInvoice} data-testid="no-draft">No</button>
                </CancelDialog>
                <div className='mb-3'>
                <ReusableRouteTabs value={history.location.pathname} tabHeading={components}/>
                </div>
                <div style={{display:'none'}}>
                    <div ref={componentRef} className="card styled-pos-card">
                        <SalesReceiptTemplate {...{products, customer}}/>
                    </div>
                </div>
                <InvoiceDateFilter {...{filters,date, handleChange, handleFilter, submitted}}/>
                <MuiPaper>
                    <EnhancedTableToolbar title='Receipts'>
                        <span className="btn-group" style={{flex: '1 1 100%'}}>
                            <span>
                                <button onClick={handleExcelExport} className="btn btn-sm pharmacy-btn mr-1">Excel</button>
                            </span>
                        </span>
                        <Form.Control type="text" value={term ? titleCase(term) : ''}
                                      data-testid="search_list"
                                      onChange={handler}
                                      className='form__control'
                                      placeholder="Search" style={{float: 'right'}}/>

                    </EnhancedTableToolbar>
                    <div className="sub-journal-bar" style={{marginLeft:'0'}}>
                                <h6 data-testid="total_receipts">Total Receipts : <strong>{formatDigits(total_count)}</strong></h6>
                                
                                <h6 data-testid="running_balance">Total Amount : <strong>{formatAmount(totalAmount)}</strong></h6>
                           
                        </div>
                    <TableContainer>
                        <Table
                            aria-labelledby="tableTitle"
                            size='medium'
                            aria-label="enhanced table"
                        >
                            <EnhancedTableHead headCells={headData}/>

                                <TableBody>
                                    {isLoading ?  <TableRow><TableCell colSpan={headData.length} align="center"><img src="/images/listPreloader.gif"
                                                                 alt="loader" style={{width: '5%'}}/></TableCell></TableRow>:null}
                                    {isSuccess ? salesReceipts?.length > 0 ?
                                        <>
                                        {salesReceipts?.slice(0, 10)
                                        .map((info, index) => {
                                            const status_text={
                                                1:'PAID',
                                                9:'CANCELLED',

                                            }
                                            const status_style =  {
                                                1:classes.paid,
                                                9:classes.canceled
                                            }
                                            const sl = ((activePage - 1) * 10) + index + 1;
                                            return (
                                                <TableRow
                                                    hover
                                                    role="checkbox"
                                                    tabIndex={-1}
                                                    key={index}
                                                    className='mui-table-row'>
                                                    <TableCell data-testid={"sale_number-"+index}>
                                                        {sl}
                                                    </TableCell>
                                                    <TableCell data-testid={"sale_date-"+index}>
                                                        <span>{info.date_paid ? dateStringTimeConvert(info.date_paid) : ''}</span>
                                                    </TableCell>
                                                    <TableCell data-testid={"sale_number-"+index}>
                                                        <span>{info.receipt_number}</span>
                                                    </TableCell>
                                                    <TableCell data-testid={"customer_name-"+index}>
                                                        <span>{titleCase(info.customer_name)}</span>
                                                    </TableCell >

                                                    <TableCell data-testid={"total_amount-"+index}>
                                                        <span>{info.total_amount}</span>
                                                    </TableCell>
                                                    <TableCell
                                                        data-testid={"sale_status-"+index}
                                                        className={status_style[info.status]}>
                                                        {status_text[info.status]}
                                                    </TableCell>
                                                    <TableCell data-testid={"sale_date-"+index}>
                                                        <Tooltip title="View Receipt"
                                                                 placement="left-start">
                                                            <Link
                                                                to={{pathname: `/receipt_print/${info.customer_id}/${info.receipt_number}`}}>
                                                                <button
                                                                    data-testid={"view_sale-"+index}
                                                                    // style={{borderRadius:'50px'}}
                                                                    className="btn btn-sm pharmacy-btn icon-button-holder mr-3 mb-sm-2">
                                                                    <FontAwesomeIcon icon={faEye}/>
                                                                </button>
                                                            </Link>
                                                        </Tooltip>
                                                        <Tooltip title='Print'>
                                                            <button type='button' onClick={() => handleOpenPrint({
                                                                    receipt_number:info.receipt_number,
                                                                    customer_id: info.customer_id
                                                                })}
                                                                    className="btn btn-sm pharmacy-gray-btn icon-button-holder mb-sm-2">
                                                                <PrintOutlinedIcon classes={{root: classes.icon}}/>
                                                            </button>
                                                        </Tooltip>

                                                        {info.status === 1 ? <Tooltip title="Cancel Invoice"
                                                                 placement="left-start">
                                                            <button
                                                                data-testid={"cancel_sale-"+index}
                                                                onClick={() => handleOpenInvoice({
                                                                    receipt_number:info.receipt_number,
                                                                    invoice_number:info.invoice_number,
                                                                    customer_id: info.customer_id
                                                                })}
                                                                className="btn btn-sm pharmacy-gray-btn icon-button-holder mb-sm-2">
                                                                <CloseIcon className={classes.icon}/>
                                                            </button>
                                                        </Tooltip>: null}
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })}

                                    <TableRow>
                                        <TableCell colSpan={3} className="text-right">
                                            Total Amount Per Page:
                                        </TableCell>
                                        <TableCell data-testid="total_amount">{total_Amount}</TableCell>
                                        <TableCell/>
                                    </TableRow>
                                        </>:<TableRow>
                                            <TableCell colSpan={headData.length} align="center">No records found</TableCell>
                                        </TableRow>:null}
                                    {isError ? <TableRow>
                                        <TableCell colSpan={headData.length} align="center">The server did not return a valid response</TableCell>
                                    </TableRow>:null}
                                </TableBody>
                        </Table>
                    </TableContainer>
                    <CustomPagination data={salesReceipts} records={10} activePage={activePage} total_count={total_count}
                                          handleChangeNextPage={handlePageChange}/>

                </MuiPaper>
            </div>
        </div>
    )
}
function mapStateToProps(state) {
    return {
        snackbars: state.snackbars
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            snackbarActions: bindActionCreators(snackbarActions, dispatch)
        }
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(SalesReceipts)