import React, {useState} from 'react';
import Typography from '@material-ui/core/Typography';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import AppHeader from "./AppHeader";
import {appRoutes} from "../routes";
import {PrivateRoute} from "../PrivateRoute";
import {NavLink, Switch} from "react-router-dom";
import Collapse from '@material-ui/core/Collapse';
import ExpandMore from '@material-ui/icons/ExpandMore';
import {StyledListItemIcon} from "../Utils/List/StyledList";
import { useMainPageModules } from './mainPageModules';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import clsx from "clsx";
import MenuIcon from '@material-ui/icons/Menu';
import {useCompany} from "../Context/company-context";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar : {
    zIndex: theme.zIndex.drawer + 1,
    //borderBottom: theme.palette.type === "dark" ? "1px solid rgba(255, 255, 255, 0.12)" : "1px solid #DCDCDC",
    transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
    })
  },
  appBarShift: {
    /* [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },*/
    marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen
        }),
   // background: '#339999'
  },
  menuButton: {
    marginRight: theme.spacing(2),
    //[theme.breakpoints.up('sm')]: {
    //  display: 'none',
    //s}//,
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  toolbarApp:{
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(2, 1),
  },
  drawerPaper: {
    width: drawerWidth,
    overflowY: "scroll",
    '&::-webkit-scrollbar': {
        width: "4px"
    },
    '&::-webkit-scrollbar-thumb': {
        backgroundColor: "rgba(0,0,0,.2)"
    }
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(0),
    overflow:'initial'
  },
  logo: {
    display: "block",
    padding: 0,
    fontWeight: 300,
    transition: "width .3s ease-in-out"
},
img: {
    // height: "100px",
    maxWidth: "100px"
},
panelImg: {
    maxWidth: "80px",
    height: "80px",
    border: "2px solid #DCDCDC",
    borderRadius: "50%",
    marginTop: 5
},
info: {
    fontWeight: "500",
    margin: "5px 0",
    letterSpacing: "1px"
},
active: {
    color: "#000057",
    textDecoration: 'none',
    '&:hover': {
      color: "#009999  !important",
      textDecoration: 'none',
    }
},
nested: {
    paddingLeft: theme.spacing(4),
},
fontIcons: {
    fontSize: "14px"
},
text: {
    fontSize: "13.7px",
    fontWeight: 400,
    lineHeight: 1.5,
    letterSpacing: "0.00938em",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    color: "#1f1f1f",
},
toggleIcon: {
    fontSize: "14px",
    fontWeight: 600
},

listItem: {
    padding: 0
},

listItemIcon:{
    minWidth:'33px'
},


list: {
 // color: theme.palette.type === 'dark' ? '#ffffff' : '#b9b9b9',
  textDecoration: "none",
  '&:hover': {
      textDecoration: "none",
      color: "#blue",
      transition: ".5s ease",
      fontWeight: "500",
      borderLeft: "3px solid #d3d3d3"
  }
},
listMenu: {
 // color: theme.palette.type === 'dark' ? '#ffffff' : '#373737',
  textDecoration: "none",
  '&:hover': {
      textDecoration: "none",
      transition: ".5s ease",
      fontWeight: "500",
      borderLeft: "3px solid #373737",
     color: '#373737'
  },  
},
icon: {
  fontSize: "15px",
  minWidth: "40px",
  '&:hover': {
  }
},
popOverRoot: {
  pointerEvents: "none"
},

drawerOpen: {
  width: drawerWidth,
  transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
  })
},
drawerClose: {
  transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
  }),
  overflowX: "hidden",
  width: '50px'
},

scrollBar: {
  '&::-webkit-scrollbar': {
      width: "5px",
      height: "5px"
  },
  '&::-webkit-scrollbar-thumb': {
      "-webkit-border-radius": "10px",
      borderRadius: "10px",
  }
},

listHeight: {
  height: '45px',
},
textMenuHead: {
  fontSize: "0.8rem",
  fontWeight: 400,
  lineHeight: 1.5,
  letterSpacing: "0.00938em",
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
},
textMenuHeadStrike: {
  fontSize: "20rem",
  fontWeight: "bold",
  lineHeight: 1.5,
  letterSpacing: "0.00938em",
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  borderBottom: "2px solid #ffffff",
  color:"#2c3136",
},
textMenu: {
  fontSize: "0.78rem",
  fontWeight: 400,
  lineHeight: 1.5,
  letterSpacing: "0.00938em",
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap"
},

}));


const MainPage = (props) => {
  const { window } = props;
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = useState(true);
  const {arr} = useMainPageModules();
  const [items, setItems] = useState(()=>arr);
  const [anchorEl, setAnchorEl] = useState(null);
  const [isContextMenu, setContextMenu] = useState({});
  const [isShowSettings, setShowSettings] = useState(false);
  let currentlyHovering = false;

  const handleOpen = (index) => {
    if (open) {
      const itemsArr = items.map((item,idx)=>{
      if(index === idx){
          return {...item,isOpen:!item.isOpen}
      }else{
          return {...item, isOpen:false}
      }
      })
      setItems(itemsArr);
    }
  }

  const handleDrawerOpen = () => {
      setOpen(true);
  }

  const handleDrawerClose = () => {
      // change all items isOpen to false
      const items_arr = items.map(it => {
          return {...it, isOpen:false}
      })
      setItems(items_arr);
      setOpen(!open);
  }

  const handleClick = (event, item) => {
      event.preventDefault();
      if (!open) {
          if (anchorEl !== event.currentTarget) {
              setAnchorEl(event.currentTarget);
              const new_context = {};      
              Object.keys(isContextMenu).forEach(key => {
                new_context[key] = false;
              });
              new_context[item.module_name] = true;
              setContextMenu(new_context);
          }
      }
  }

  const handleHover = () => {
    currentlyHovering = true;
  }

  const handleClose = () => {
    setAnchorEl(null);
    const new_context = {};      
    Object.keys(isContextMenu).forEach(key => {
      new_context[key] = false;
    });
    setContextMenu(new_context);
  }

  const handleCloseHover = (item) => {
    currentlyHovering = false;
    setTimeout(() => {
      if (!currentlyHovering) {
        handleClose(item);
      }
    }, 50);
  }

  const toggleShowSettings = () => {
      setShowSettings(!isShowSettings)
  };
  
  const container = window !== undefined ? () => window().document.body : undefined;
  const {state} = useCompany();
  const {logo} = state

  return (
    <div className={classes.root} id="mainSideBar">
      <CssBaseline />
      <AppHeader position="fixed" appBar={clsx(classes.appBar, {[classes.appBarShift]: open})} color={'red'}/> 

      {/*  Left these because they catered to mobile phones? Not sure 
      <nav className={classes.drawer} aria-label="modules"> anchor={theme.direction === 'rtl' ? 'right' : 'left'}
        {/* The implementation can be swapped with js to avoid SEO duplication of links. *
                    container={container}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
        <Paper sx={{ display: { xs: "block", sm: "none" } }} implementation="css"> */}

          <Drawer
            variant="permanent"
            open={open}
            className={clsx(classes.drawer, {
              [classes.drawerOpen]: open,
              [classes.drawerClose]: !open
            })}
            classes={{
              paper: clsx(classes.scrollBar, {
                  [classes.drawerOpen]: open,
                  [classes.drawerClose]: !open
              })
            }}
          >

            <div>
              <List className={classes.listItem}>

              <div className={classes.toolbarApp}>
                  <div className={classes.logo}>
                    <span onContextMenu={toggleShowSettings}>
                        <img className={classes.img} src={logo ? logo : '/images/mpeke3.png'}
                        style={{width: "70px", margin: '-20% 0 -20% -60%'}} alt="mpeke"/>
                    </span>
                  </div>
                 
                  {!open ?  
                    <IconButton
                      style={{width: "30px", margin: '-20% 0 -20% -60%'}} 
                      color="inherit"
                      aria-label="open drawer"
                      edge="start"
                      onClick={handleDrawerOpen}
                      className={clsx(classes.menuButton, {[classes.hide]: open,})}
                    >
                      <MenuIcon />
                    </IconButton> 
                    : 
                    <IconButton onClick={handleDrawerClose} id="collapse-main-sidebar">
                    <ChevronLeftIcon className={classes.toggleIcon}/>
                    </IconButton>
                  }
              </div>

              <Divider/>

              {items.map((item, index)=>(  
                  <List className={classes.listItem} key={index}>

                      {item.modules.filter(it=>it.isVisible).length > 0 ?
                      <>

                        {isContextMenu[item.module_name] ?
                            <Menu
                                anchorOrigin={{vertical: 'center', horizontal: 'right'}}
                                transformOrigin={{vertical: 'center', horizontal: 'left'}}
                                id="sidepop-menu"
                                anchorEl={anchorEl}
                                open={Boolean(anchorEl)}
                                onClose={(event)=>handleClose(item)}
                                MenuListProps={{
                                onMouseEnter: handleHover,
                                onMouseLeave: (event)=> handleCloseHover(item),
                                style: { pointerEvents: "auto" }
                                }}
                                getContentAnchorEl={null}
                                PopoverClasses={{
                                root: classes.popOverRoot
                                }}
                            >
                                <MenuItem disabled={true} className={classes.menuColor}>
                                    <ListItemText style={{borderBottom: "2px solid #828282"}}>
                                        <Typography variant="body2" style={{ color: '#000000', lineHeight: 1.5, letterSpacing: "0.00938em",
                                        overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", fontWeight:'bold', 
                                        fontSize: "15px"}}>{item.module_name}</Typography> 
                                    </ListItemText>
                                </MenuItem>
                                {item.modules.map((mods, id) => (
                                    <div key={id}>
                                        <NavLink key={id} id='dashboard-sidebar' to={mods.route} style={{textDecoration:'none', color:'#091714'}}>
                                            <MenuItem  onClick={(event)=>handleClose(item)} classes={{root: classes.listMenu}}>
                                                <ListItemText classes={{root: classes.textMenu}} disableTypography={true}>{mods.text}</ListItemText>
                                            </MenuItem>
                                        </NavLink>
                                    </div>
                                ))}
                            </Menu> 
                        : null}

                        <ListItem button onClick={()=>handleOpen(index)} on className={classes.listHeight}>
                            <StyledListItemIcon
                            className={classes.icon}
                            aria-owns={anchorEl ? "sidepop-menu" : undefined}
                            aria-haspopup="true"
                            onClick={(event)=>handleClick(event, item)}
                            onMouseOver={(event)=>handleClick(event, item)}
                            onMouseLeave={(event)=>handleCloseHover(item)}
                            >
                                {'  '}
                                {item.icon}
                            </StyledListItemIcon>
                            
                            <ListItemText primary={item.module_name} classes={{root: classes.text}} disableTypography={true}/>
                            {open ? 
                            item.isOpen ? <ExpandMore className={classes.toggleIcon}/> :
                            <ChevronLeftIcon className={classes.toggleIcon}/> : null
                            }
                            
                        </ListItem>
                      </>
                      : item.modules.length === 0 && item.isVisible ?
                      <>
                        {isContextMenu[item.module_name] ?
                            <Menu
                                anchorOrigin={{vertical: 'center', horizontal: 'right'}}
                                transformOrigin={{vertical: 'center', horizontal: 'left'}}
                                id="sidepop-menu"
                                anchorEl={anchorEl}
                                open={Boolean(anchorEl)}
                                onClose={(event)=>handleClose(item)}
                                MenuListProps={{
                                onMouseEnter: handleHover,
                                onMouseLeave: (event)=> handleCloseHover(item),
                                style: { pointerEvents: "auto" }
                                }}
                                getContentAnchorEl={null}
                                PopoverClasses={{
                                root: classes.popOverRoot
                                }}
                            >
                                
                                <NavLink id='dashboard-sidebar' to={item.link} style={{textDecoration:'none', color:'#091714'}}>
                                    <MenuItem onClick={(event)=>handleClose(item)} classes={{root: classes.listMenu}}>
                                        <ListItemText classes={{root: classes.textMenuHead}} disableTypography={true}>{item.module_name}</ListItemText>
                                    </MenuItem>
                                </NavLink>
                            </Menu> 
                            : null
                        }
                          <NavLink to={item.link} className="side-links" activeClassName="side-active">
                              <ListItem button className={classes.list}>
                                  <StyledListItemIcon
                                  className={classes.icon}
                                  aria-owns={anchorEl ? "sidepop-menu" : undefined}
                                  aria-haspopup="true"
                                  onClick={(event)=>handleClick(event, item)}
                                  onMouseOver={(event)=>handleClick(event, item)}
                                  onMouseLeave={(event)=>handleCloseHover(item)}
                                  >
                                      {item.icon}
                                  </StyledListItemIcon>
                                  <ListItemText primary={item.module_name} classes={{root: classes.text}} disableTypography={true}/>
                              </ListItem>
                          </NavLink>
                      </>
                      : null}

                      <Collapse in={item.isOpen} timeout="auto" unmountOnExit>
                          {item.modules.map((nav, idx)=>(
                              <List component="div" disablePadding key={idx} className={classes.listItem}>
                                  {nav.isVisible ? <NavLink to={nav.route} color="inherit" className="navlink" activeClassName={classes.active}>
                                      <ListItem button className={classes.nested}>
                                          {nav.icon ? 
                                          <StyledListItemIcon>
                                              {nav.icon}
                                          </StyledListItemIcon>
                                          : null
                                          }
                                          <ListItemText primary={nav.text} classes={{root: classes.text}} disableTypography={true}/>
                                      </ListItem>
                                  </NavLink>:null}
                              </List>
                          ))}
                      </Collapse>
                  </List>
              ))}
                                  
              </List>
            </div>

          </Drawer>
       

      {/*} </Paper>
        <Paper sx={{ display: { xs: "none", sm: "block" } }} implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
          >
            // Add the side bars here, the App Drawer component was deleted because it messed up the anchorEl variable for the context menus
          </Drawer>
        </Paper>  
      </nav> */}

      <main className={classes.content}>
        <div className={classes.toolbar} />
        <Switch>
            {appRoutes.map((route, index) => {
                return (
                    <PrivateRoute key={index} exact={route.exact} path={route.path} component={route.component}/>
                )
            })}
        </Switch>
      </main>
    
    </div>
  );
}

export default MainPage;
