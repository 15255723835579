import React from 'react';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import {StickyHeadTable, CustomTableCell} from '../../../Utils/Tables/StickyHeaderTable';
import { LoadingGif } from '../../../Utils/Loader';
import { formatAmount } from '../../../Utils/formatNumbers';
import { formatDateTime } from '../../../Utils/ConvertDate';

const style = {
    border: {
        borderTop: '1px solid #606060',
        borderBottom: '1px solid #606060'
    },
    image: {
        width: 130,
        border: '1px solid #e4e4e4',
        borderRadius: '60px',
        background: '#e4e4e4'
    },
    p: {
        margin: 0
    },
    parent: {
        textAlign: 'center',
        marginTop: '5em'
    }
}


const headData = [
    { id: 'date', label: 'Date',minWidth: 200 },
    { id: 'voucher_no', label: 'Voucher No.',minWidth: 200 },
    { id: 'type', label: 'Voucher Type',minWidth: 200 },
    { id: 'memo', label: 'Memo',minWidth: 170},
    { id: 'amount', label: 'Amount',minWidth: 170},
    {id: 'transaction_type',label: 'Transaction Type',minWidth: 170},
  ]

export const ReusableJournalTable = ({isLoading, isSuccess, isError,data=[]}) => {
    const emptyRows = 10 - Math.min(10, data.length);
    return (
        <StickyHeadTable {...{data, columns:headData.filter(item=>Boolean(item)), width:'100%'}}>
        {isLoading ? <TableRow><TableCell align='center' colSpan={headData.length}><LoadingGif/></TableCell></TableRow>:null}
        {isSuccess ? data.length > 0 ? 
            <>
                {data.map((row, index) => {
                return (
                <TableRow  tabIndex={-1} key={index}>
                    <CustomTableCell data-testid={"transaction_time-"+index}>{formatDateTime(row.date)}</CustomTableCell>
                    <CustomTableCell data-testid={"voucher_no-"+index}>{row.voucher_no}</CustomTableCell>
                    <CustomTableCell data-testid={"voucher_type-"+index}>{row.voucher_type}</CustomTableCell>
                    <CustomTableCell data-testid={"memo-"+index}>{row.entry_detail}</CustomTableCell>
                    <CustomTableCell data-testid={"transaction_amount-"+index}>{row.entry_amount ? formatAmount(row.entry_amount) : null}</CustomTableCell>
                    <CustomTableCell data-testid={"transaction_type-"+index}>{row.entry_category}</CustomTableCell>
                </TableRow>
                );
        })}
            {emptyRows > 0 && (
            <TableRow style={{ height: 43 * emptyRows }}>
            <TableCell colSpan={headData.length} />
            </TableRow>
        )}
        </>:<TableRow><TableCell align='center'  colSpan={headData.length}>No records found</TableCell></TableRow>:null}
        {isError ? <TableRow><TableCell align='center'  colSpan={headData.length}>The server did not return a valid response</TableCell></TableRow>:null}
    </StickyHeadTable>
    )

}

export const NoTransaction = ({text}) => {
    return (
        <div style={style.parent}>
            <img src="/images/transaction.png" alt='transaction' style={style.image}/>
            <p className="text-center" style={style.p}>{`There are no ${text} available`}</p>
        </div>
    )
}


