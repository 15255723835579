import React, {useCallback, useEffect, useState} from "react";
import {Link} from "react-router-dom";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import Form from "react-bootstrap/Form";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Tooltip from '@material-ui/core/Tooltip';
import * as snackbarActions from "../../Redux/Actions/SnackbarActions/SnackbarActions";
import {titleCase, dateStringTimeConvert} from "../../Utils/titleCaseFunction";
//import {exportPDF} from "../../Utils/exportPDF";
import config from "../../Helpers/config";
import axios from "axios";
import {errorMessages} from "../../Helpers/ErrorMessages";
import {CustomPagination} from "../../Utils/Tables/CustomTable";
import ReusableDashboardHeader from "../../Containers/Dasboard/ReusableDashboardHeader";
import {EnhancedTableHead, EnhancedTableToolbar, MuiPaper} from "../../Utils/Tables/CustomTable";
import PharmacySnackbar from "../../Utils/Snackbars/SnackbarUtil";
import TableContainer from "@material-ui/core/TableContainer";
import {ReusableRouteTabs} from "../../Utils/Tabs/ReusableTabs";
import {history} from "../../Helpers/history";
import {formatAmount} from "../../Utils/formatNumbers";
import VisibilityIcon from '@material-ui/icons/Visibility';
import {makeStyles} from "@material-ui/core/styles";
import {useSalesFilter} from "./CustomHooks/useSalesFilter";
import {InvoiceDateFilter} from "./InvoiceDateFilter";
import {LoadingGif} from "../../Utils/Loader";
import {useExcelReports} from "./CustomHooks/useExcelReports";
import { usePrint } from "../../Utils/Template/usePrint";
import PrintOutlinedIcon from "@material-ui/icons/PrintOutlined";
import { SalesReceiptTemplate } from "./SalesReceiptTemplate";
import { ReusablePosTemplate } from "./ReusablePosTemplate";
import { formatDigits } from "../../Utils/formatAmount";


const useStyles = makeStyles((theme) => ({
    icon: {
        width:'0.8em',
        height:'0.8em',
    },
}));


const CancelledSales = ({actions, snackbars,props}) => {
    const storage = JSON.parse(sessionStorage.getItem('total_count'))
    const item_count = storage ? storage : 0
    const count = () => +item_count || 0
    const [term, setTerm] = useState('');
    const [activePage, setActivePage] = useState(1);
    const [totalAmount, setTotalAmount] = useState(0);
    const [total_Amount, setAmount] = useState(0)
    const [payment_type,setPayType] = useState(null)
    const [invoices, setInvoices] = useState([]);
    const [total_count, setTotalCount] = useState(count);
    const [loading, setLoading] = useState('idle');
    const [submitted, setSubmitted] = useState(false)
    const [invoice_id, setInvoiceId] = useState('');
    const [isSubmitted, setIsSubmitted] = useState('idle')
    const [data, setData] = useState({products:[], customer:{}, loading:'idle'});
    const {date, handleChange, filters} = useSalesFilter()
    const {filter_by, start_date, end_date} = date
    const {handlePrint, componentRef, getPageMargins} = usePrint('')

    useEffect(()=>{
        setActivePage(1)
    },[filter_by])
  

    // const calculateTotalAmount = (arr) => {
    //     const sum = arr.reduce((a, invoice) => {
    //         return +(a + invoice.total_amount);
    //     }, 0);
    //     setTotalAmount(sum)
    // };


    const isResolved = isSubmitted === 'resolved'


    const retrieveInvoices = useCallback((start_date='',end_date, page=activePage) => {
        const isCustom = filter_by === 'custom' ? {start_date, end_date} : {}
        setLoading('loading')
        axios.get(`${config.epharmUrl}/Cinvoice/invoices`,{params:{category:'cancelled', time_filter:filter_by,
                page_number:page, search:term, ...isCustom}}).then(response => {
            const data = response.data;
            const dt = !data ? {} : data;
            const dat = dt.data ? dt.data : {}
            const arr = dat.invoices ? dat.invoices : {}
            const invoicesArr = arr.invoice_list ? arr.invoice_list : []
            const t_amount =  arr.grand_total_amount ? arr.grand_total_amount : 0
            const total_amount =arr.total_amount ? arr.total_amount : 0
            const t_count = dat.total_records ? dat.total_records : 0;
            if(activePage === 1){
                sessionStorage.setItem('total_count', JSON.stringify(t_count))
                setTotalCount(t_count)
            }else {
                setTotalCount(count)
            }
            setInvoices(invoicesArr);
            setTotalAmount(t_amount)
            setAmount(total_amount)
            setLoading('success')
        }).catch(error => {
            errorMessages(error, null, actions)
            setLoading('error')
        });
    },[activePage,  filter_by, term])

    useEffect(() => {
        if (filter_by === 'custom' && !isResolved){
            return
        }
        retrieveInvoices(start_date, end_date)
    }, [retrieveInvoices])

    const handler = (event) => {
        setTerm(event.target.value)
    };

    const handleFilter = (event) =>{
        event.preventDefault()
        setSubmitted(true)
        if (start_date && end_date && start_date < end_date){
            setIsSubmitted('resolved')
            retrieveInvoices(start_date, end_date)
        }
    }

    const handleCloseSnackbar = () => {
        actions.snackbarActions.hideSnackBar();
    };


    const handlePageChange = (pageNumber) => {
        setActivePage(pageNumber)
    };

    const isCustom = filter_by === 'custom' ? {start_date, end_date} : {};
    const currentObj = {file_name:'Cancelled', url:'Cinvoice/manage_sales_excel_report',
        params: {
            category:'cancelled', time_filter: filter_by, ...isCustom
        }
    }

    const {exportExcel} = useExcelReports(actions, currentObj)

    const handleOpenPrint = (receipt_number, invoice_number, customer_id, payment_type) => {
      setPayType(payment_type); 
    
      const formData = new FormData();
    
      formData.append('receipt_number', '');
      formData.append('invoice_number', invoice_number);
      formData.append('customer_id', customer_id);
    
      setData({ ...data, loading: 'loading' });
      axios.post(`${config.epharmUrl}/Cinvoice/view_receipt`, formData)
        .then(response => {
          const dataRes = response.data;
          const dt = dataRes ? dataRes : {};
          const arr = dt.bill ? dt.bill : [];
          const customer_details = dt.customer_details ? dt.customer_details : {};
          const obj = arr[0] ? arr[0] : {};
    
          setData({
            ...data,
            products: arr,
            customer: { ...customer_details, ...obj },
            loading: 'success'
          });
    
          setLoading('success');
          handlePrint();
        })
        .catch(error => {
          errorMessages(error, null, actions);
          setData({ ...data, loading: 'error' });
        });
    
      setInvoiceId(invoice_number);
    };
    


    const handleExcelExport = () => {
        if (invoices?.length === 0) {
            actions.snackbarActions.errorSnackbar('There are no records to be downloaded');
        } else {
            exportExcel();
        }
    }

    const allInvoices = !invoices ? [] : invoices;
    const {variant, message, open} = snackbars;

    const headData = [{id: 'sl', numeric: false, disablePadding: false, label: 'Sl'},
        {id: 'invoice', numeric: false, disablePadding: false, label: 'Sales No.'},
        {id: 'name', numeric: false, disablePadding: false, label: 'Customer Name'},
        {id: 'date', numeric: false, disablePadding: false, label: 'Date'},
        {id: 'total', numeric: false, disablePadding: false, label: 'Total Amount'},
        {id: 'invoice_type', numeric: false, disablePadding: false, label: 'Sale Type'},
        {id: 'action', numeric: false, disablePadding: false, label: 'Action'}
    ];

    const components = [{label: 'Receipts', path: '/sales-receipts'},
        {label: 'Invoices', path: '/manageinvoice'},
        {label: 'Cancelled Sales', path: '/cancelledsales'},
    ];

    const {products, customer} = data
    const {first_name, last_name} = customer;
    const user_name = `${!first_name ? "" : first_name} ${!last_name ? "" : last_name}`;

    const classes = useStyles()
    const isLoading = loading === 'loading'
    const isSuccess = loading === 'success'
    const isError = loading === 'error'

    return (

        <div data-testid="manage_invoice">
            <ReusableDashboardHeader component="Manage Sales" heading="Sales"
                                     subHeading="Cancelled Sales" link="/cancelledsales"/>

            <div className="receipt-container">
                <ReusableRouteTabs value={history.location.pathname} tabHeading={components}/>
                <PharmacySnackbar open={open} message={message} variant={variant}
                                  handleCloseBar={handleCloseSnackbar}/>

                <div className=" my-3">
                    <InvoiceDateFilter {...{filters,date, handleChange, handleFilter, submitted}}/>
                </div>


                <div style={{display:'none'}}>
                    <div ref={componentRef} className="card styled-pos-card">
                        {payment_type === 'cash' ? (
                            <ReusablePosTemplate {...{ customer, products, user_name }} />
                        ) : (
                            <SalesReceiptTemplate {...{ products, customer }} />
                        )}

                    </div>

                </div>
                <MuiPaper>
                    <EnhancedTableToolbar title='Cancelled Sales'>
                             <span className="btn-group" style={{flex: '1 1 100%'}}>
                                 <span><button onClick={handleExcelExport} className="btn pharmacy-btn mr-1 btn-sm">Excel</button></span>
                            </span>
                        <Form.Control type="text" value={term ? titleCase(term) : ''}
                                      onChange={handler}
                                      className='form__control'
                                      placeholder="Search" style={{float: 'right'}}/>
                    </EnhancedTableToolbar>
                    <div className="sub-journal-bar" style={{marginLeft:'0'}}>
                                <h6 data-testid="total_receipts">Total Receipts : <strong>{formatDigits(total_count)}</strong></h6>
                                
                                <h6 data-testid="running_balance">Total Amount : <strong>{formatAmount(totalAmount)}</strong></h6>
                           
                        </div>
                     <TableContainer>
                        <Table
                            aria-labelledby="tableTitle"
                            size='medium'
                            aria-label="enhanced table"
                        >
                            <EnhancedTableHead headCells={headData}/>
                                <TableBody>
                                    {isLoading ? <TableRow>
                                            <TableCell colSpan={headData.length} align="center"><LoadingGif/></TableCell>
                                        </TableRow>:null}
                                    {isSuccess ? allInvoices.length > 0 ?
                                        <>
                                        {allInvoices.slice(0, 10)
                                        .map((info, index) => {
                                            const sl = ((activePage - 1) * 10) + index + 1;
                                            return (
                                                <TableRow
                                                    hover
                                                    role="checkbox"
                                                    tabIndex={-1}
                                                    key={index}
                                                    className='mui-table-row'>
                                                    <TableCell component="th" scope="row" padding="default"
                                                               className='mui-table-cell'>
                                                        <span>{sl}</span>
                                                    </TableCell>
                                                    <TableCell>
                                                        <span>{info.sales_no}</span>
                                                    </TableCell>
                                                    <TableCell>
                                                        <span>{titleCase(info.customer_name)}</span>
                                                    </TableCell>
                                                    <TableCell>
                                                        <span>{info.date ? dateStringTimeConvert(info.date) : ''}</span>
                                                    </TableCell>
                                                    <TableCell>
                                                        <span>{formatAmount(info.total_amount)}</span>
                                                    </TableCell>
                                                    <TableCell>
                                                        <span>{info.payment_type === 2 ? 'INVOICE' : 'RECEIPT'}</span>
                                                    </TableCell>
                                                    {info.receipt_number || info.invoice_number ?
                                                        <TableCell id="mainbuttons">
                                                            <Tooltip title="View Cancelled Sales"
                                                                     placement="left-start">
                                                                <Link
                                                                    to={
                                                                        {pathname: info.receipt_number ?
                                                                            `/receipt_print/${info.customer_id}/${info.receipt_number}/${true}` :
                                                                            `/invoice_print/${info.customer_id}/${info.invoice_number}/${true}` 
                                                                        }
                                                                    }>
                                                                    <button
                                                                        className="btn btn-sm pharmacy-btn icon-button-holder mr-3 mb-sm-2">
                                                                        <VisibilityIcon className={classes.icon}/>
                                                                    </button>
                                                                </Link>
                                                            </Tooltip>
                                                            <Tooltip title='Print'>
                                                                    <button
                                                                    type='button'
                                                                    onClick={() =>
                                                                        handleOpenPrint( info.receipt_number,info.invoice_number,info.customer_id, info.payment_type)
                                                                    }
                                                                    className="btn btn-sm pharmacy-gray-btn icon-button-holder mb-sm-2"
                                                                    >
                                                                    <PrintOutlinedIcon classes={{root: classes.icon}}/>
                                                                    </button>

                                                        </Tooltip>
                
                                                        </TableCell>
                                                    : null }
                                                        
                                                </TableRow>
                                            );
                                        })}
                                    <TableRow>
                                        <TableCell colSpan={4} className="text-right">
                                            Total Amount Per Page:
                                        </TableCell>
                                        <TableCell data-testid="totalAmount">{formatAmount(total_Amount)}</TableCell>
                                        <TableCell/>
                                    </TableRow>
                                    </> : <TableRow>
                                            <TableCell colSpan={headData.length} align="center">No records found</TableCell>
                                        </TableRow> : null}
                                    {isError ? <TableRow>
                                        <TableCell colSpan={headData.length} align="center">The server did not return a valid response</TableCell>
                                    </TableRow> : null}
                                </TableBody>
                        </Table>
              
                        <CustomPagination data={allInvoices} records={10} activePage={activePage} total_count={total_count}
                                      handleChangeNextPage={handlePageChange}/>
                     </TableContainer>

                </MuiPaper>
            </div>
        </div>
    )


}

function mapStateToProps(state) {
    return {
        snackbars: state.snackbars
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            snackbarActions: bindActionCreators(snackbarActions, dispatch)
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CancelledSales);
