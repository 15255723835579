import * as React from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import styled from "@emotion/styled/macro";
import * as colors from '../../styles/colors'




const ListContainer = styled.div({
    marginBottom:'10px',
    boxShadow:'0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)'
})
const ItemIcon = styled(ListItemIcon)({
    minWidth:'5px'
})

const StyledList = styled(List)({
    paddingTop:0,
    paddingBottom:0,

})

const ItemButton = styled(ListItem)({
    '&::-webkit-tap-highlight-color':{
        background: colors.base
    },
    ':hover':{
        background:colors.base
    },
    paddingLeft:'6px',
    paddingRight:'6px',
    background:colors.base
})

const ItemText = styled(ListItemText)({
    '& .MuiTypography-root':{
        fontSize:'14px',
    },
    '& .MuiListItemText-secondary':{
        fontSize: '12px'
    }

})

const RightItemText = styled(ItemText)({
    display:'flex',
    justifyContent:'flex-end'

})










function ReusableProductsList({button, primaryText, secondaryText}) {
    return (
        <ListContainer>
            <StyledList
                sx={{ width: '100%'}}
                component="nav"
                aria-labelledby="collapsible-list"
            >
                <ItemButton  button>
                    <ItemText  primary={primaryText}  secondary={secondaryText}/>
                    {button}
                </ItemButton>
            </StyledList>
        </ListContainer>
    );
}
export {ReusableProductsList,  ItemText}
