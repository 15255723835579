/** @jsx jsx */ //please do not remove this implementation because the css will not be loaded without it
import { css } from '@emotion/react'
import styled from "@emotion/styled/macro"
import * as colors from "./colors"

const P = styled.p({
    marginBottom:0,
})

const table = css`
    width: 100%;
    font-size:14px;
    margin-bottom:0.5rem;
`

const tableFooter = css`
    margin-top: 1.5rem;
`

const heading = css`
  font-size:16px;
  margin: 0;
`

const tableMargin = css`
    margin-bottom: 1.5rem;
`

const borderlessTableFooter = css`
    width: 100%;
    font-size:14px;
    margin-bottom:0.5rem;
`

const tableBordered = css`
    td,th{
        border:1px solid black;
        padding:0.3rem;
    } 
 
`
const borderTable = css`
    border-top:2px solid black;
    border-bottom:2px solid black;
`

const printCell = css`
    @media print{
        display:none;
    }
`

const rightCell = css`
    @media print{
        text-align:right;
    }
`

const thinBorderTable =  css`
    border:1px solid black;
    padding:0.3rem;
`

const coloredHead = css`
      background-color: ${colors.lightGray001};
`

const coloredThead = css`
  background-color: ${colors.gray};
  @media print{
    border-bottom: 2px solid black;
    background-color: ${colors.base};
  }
`

const coloredInvoice = css`
  background-color: ${colors.blue};
  color: ${colors.base};
`

const textWeight = css`
    font-weight:700;
`

const detailsTable = css`
    td,th{
        padding:0.5rem;
    } 
`
const borderedThead = css`
    th{
        border-top:2px solid black;
        border-bottom:2px solid black;
        
    }
`

const thinborderedThead = css`
    border-top:1px solid black;
    border-bottom:1px solid black;
    padding:0.2rem;
`

const smallUpperCase = css`
    font-weight:300;
    font-size:15px;
`
const smallTable = css`
    td{
      padding:0.1rem;
    }
`

const smallPadding = css`
  td{
  padding:0.2rem;
}
  th{
    padding:0.2rem;
  }
`

const tableCell = css`
    border-top:2px solid black;
`

const tableCellBorderBottom = css`
    border-bottom:2px solid black;
`
const thBorderTop = css`
    th{
        border-top:2px solid black;
    }
`
const thBorderBottom = css`
    th{
        border-bottom:2px solid black;
        text-transform: uppercase;
    }
    
`

const borderedCell = css`
  tr:last-child td{
    border-bottom:1.4px solid black;
  };
`

const paddingCell = css`
  tr:last-child td{
    padding-bottom: 1.5rem;
  };
  tr:first-child td{
    padding-top: 1rem;
  }
`

const thThinBorderBottom = `
    th{
        border-bottom:1px solid black;
    }
    
`

const thUppercase = `
    text-transform: uppercase;
`

export {P,table,tableCell,textWeight, thBorderBottom, thBorderTop, borderTable,borderedThead, smallUpperCase,borderlessTableFooter,
    detailsTable,thUppercase,thinBorderTable,tableBordered,tableCellBorderBottom,thThinBorderBottom, thinborderedThead,
    coloredHead, coloredThead, borderedCell, smallTable, tableMargin, heading, smallPadding, coloredInvoice, paddingCell, tableFooter,
    printCell, rightCell}
