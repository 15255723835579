import React from 'react'
import { bindActionCreators } from 'redux';
import ReusableDashboardHeader from '../../Containers/Dasboard/ReusableDashboardHeader';
import {history} from "../../Helpers/history";
import PharmacySnackbar from '../../Utils/Snackbars/SnackbarUtil';
import * as snackbarActions from "../../Redux/Actions/SnackbarActions/SnackbarActions";
import {connect} from "react-redux";
import {EnhancedTableHead, EnhancedTableToolbar, MuiPaper, MuiPagination } from '../../Utils/Tables/CustomTable';
import Table from "@material-ui/core/Table";
import { TableBody, TableCell, TableRow } from '@material-ui/core';
import { useManageProvider } from './CustomHooks/useManageProviders';
import AddProvider from './AddProvider';
import CustomDialog from '../../Utils/Dialog/CustomDialog';
import TableContainer from "@material-ui/core/TableContainer";





const headData = [
    {id: 'name', numeric: false, disablePadding: false, label: 'Category Name'},
    {id: 'action', numeric: false, disablePadding: false, label: 'Action'},
    
];

 const ManageProvider = ({actions, snackbars }) => {

    const { providers,   handleCloseBar,
        providerProps, isLoading, isSuccess, isError, page, rowsPerPage, handleChangePage,
        handleChangeRowsPerPage
    } = useManageProvider(actions);
     const allProviders = !providers ? [] : providers;
     const {variant, message, open} = snackbars;
    const {openDialog, handleOpenDialog, handleCloseDialog, is_new_provider} = providerProps
    
  return (
    <div className='journals'>
     <ReusableDashboardHeader component="Customer Categories" heading="Customer Categories"
         subHeading="Customer  categories" link={history.location.pathname}/>
          <div className='mui-tables mt-3'>
            <CustomDialog handleClose={handleCloseDialog} open={openDialog} title="Add Customer Category">
                  <AddProvider {...providerProps}/>
            </CustomDialog>

            <div  align="right">
            <button onClick={()=>handleOpenDialog('', 'new')} type="button"
                    className="btn pharmacy-info-btn mr-2 mb-2">
                Add Customer Category
            </button>
            </div>
               
                 <PharmacySnackbar open={open} message={message} variant={variant} handleCloseBar={handleCloseBar} />
                
                <MuiPaper>
                    <EnhancedTableToolbar title="Customer Categories">
                             
                        {/*<Form.Control type="text" */}
                        {/*              value={searchValue ? titleCase(searchValue) : ''}*/}
                        {/*              onChange={handleChange}*/}
                        {/*              className='form__control'*/}
                        {/*              placeholder="Search" style={{float: 'right'}}/>*/}
                    </EnhancedTableToolbar>
                    <TableContainer>


                        <Table
                            aria-labelledby="tableTitle"
                            size='medium'
                            aria-label="enhanced table"
                        >
                            <EnhancedTableHead headCells={headData}/>
                                <TableBody>
                                    {isLoading ? <TableRow>
                                        <TableCell colSpan={headData.length}align="center"><img src="/images/listPreloader.gif"
                                                                                   alt="loader"
                                                                                   style={{width: '5%'}}/></TableCell>
                                    </TableRow> : null}
                                    {isSuccess ? allProviders.length > 0 ? allProviders.slice(0, 10).map((d, index) => {
                                        return (
                                            <TableRow
                                                role="checkbox"
                                                tabIndex={-1}
                                                key={index}
                                               >

                                                <TableCell>
                                                    <span>{d.category_name}</span>
                                                </TableCell>
                                                <TableCell>
                                                    <button onClick={()=>handleOpenDialog(d.id)} className='btn btn-sm pharmacy-info-btn'>Edit</button>
                                                </TableCell>
                                               
                                            </TableRow>);
                                    }):<TableRow><TableCell colSpan={headData.length} align='center'>No records found</TableCell></TableRow>:null}
                                    {isError ? <TableRow> <TableCell colSpan={headData.length} align='center'>The server did not return a valid response</TableCell></TableRow>:null}
                                </TableBody>
                        </Table>
                    </TableContainer>
                    <MuiPagination colSpan={headData.length} data={allProviders} handleChangePage={handleChangePage}
                                   page={page} handleChangeRowsPerPage={handleChangeRowsPerPage} rows={allProviders.length} rowsPerPage={rowsPerPage}/>
                </MuiPaper>
            </div>
                

    </div>
  )
}

function mapStateToProps(state) {
    return {
        snackbars: state.snackbars
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            snackbarActions: bindActionCreators(snackbarActions, dispatch)
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ManageProvider);
