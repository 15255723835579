import {useEffect, useState} from "react";
import axios from "axios";
import config from "../../../Helpers/config.json";
import {errorMessages} from "../../../Helpers/ErrorMessages";
import {convert, formatDate, removeTime} from "../../../Utils/titleCaseFunction";
import { v4 as uuidv4 } from 'uuid';

export const useEditPurchaseData = (actions, purchase_id) => {
    const [purchase, setPurchase] = useState({});
    const [products, setProducts] = useState([])

    useEffect(() => {
        if (!purchase_id) {
            return
        }

        const storage = JSON.parse(sessionStorage.getItem(purchase_id))
        const item = storage ? storage : {}
        const supplier_details = item['supplierDetails'] ? item['supplierDetails'] : {}
        const product_details = item['products'] ? item['products'] : []
        const productObj = product_details[0] ? product_details[0] : {}
        setPurchase({
            payment_type: supplier_details?.paytype,
            invoice_no: supplier_details?.chalan_no,
            purchase_date: supplier_details?.purchase_date ? formatDate(supplier_details?.purchase_date) : '',
            details: supplier_details?.purchase_details,
            manufacturer_name: {
                manufacturer_name: !supplier_details?.manufacturer_name ? '' :
                    supplier_details?.manufacturer_name,
                manufacturer_id: !productObj?.manufacturer_id? '' :
                    productObj?.manufacturer_id,

            },
            grand_total: productObj?.grand_total_amount
        })
        const data = product_details.map(item => ({
            ...item,
            product_name: item.product_name,
            product_id: item.product_id,
            batch_id: item.batch_id,
            expiry_date: item.expeire_date ? removeTime(item.expeire_date) : '',
            total_product: item.remaining_stock,
            product_quantity: item.quantity,
            product_rate: item.manufacturer_price,
            total_price: item.total_amount,
            discount: item.discount,
            expiry_limit:item.expiry_limit ? item.expiry_limit : 0,
            isValidBatch:null,
            disableExpiry:false,
            uuid_id: uuidv4()
        }))
        setProducts(data)
    }, [purchase_id])


    return {purchase, products}
}

export const usePurchaseOrderData = (actions, local_purchase_order_id) => {
    const [orderData, setOrderData] = useState([]);
    const [manufacturer, setManufacturer] = useState({});

    useEffect(() => {
        if (!local_purchase_order_id) {
            return
        }
        axios.get(config.epharmUrl + "/Cpurchaseorder/purchaseoder_update_form", {params: {local_purchase_order_id}}).then(response => {
            const info = response.data;
            const dt = info ? info : {}
            const all_purchase =  dt.product_list ? dt.product_list :[];
            setManufacturer({value:dt?.manufacturer_id, label:dt?.manufacturer_name})
            const data = all_purchase.map(item => ({
                ...item,
                product_name: item.product_name,
                product_id: item.product_id,
                batch_id: '',
                expiry_date: '',
                total_product: 0,
                product_quantity: item.quantity,
                product_rate: item.rate,
                total_price: item.quantity * item.rate,
                discount: 0,
                disableExpiry:true,
                isValidBatch: null,
                uuid_id: uuidv4()
            }))
            setOrderData(data)
        }).catch(error => {
            errorMessages(error, null, actions)

        })
    }, [])
    return {orderData, manufacturer}
}