import Tooltip from '@material-ui/core/Tooltip';
import { withStyles, } from '@material-ui/core';

const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
        maxWidth: 220,
        fontSize: '13px',
    },
}))(Tooltip);

export {HtmlTooltip}
