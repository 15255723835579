import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';

const WINDOW_WIDTH = window.innerWidth;
const width  = WINDOW_WIDTH - 750;

const useStyles = makeStyles(theme=>({
  list: {
    width: width,
    padding:'16px',
    [theme.breakpoints.down('sm')]:{
      width: '400px',
    },
    [theme.breakpoints.up('md')]:{
      width: '600px',
    },
    [theme.breakpoints.up('lg')]:{
      width: '800px',
    },
    [theme.breakpoints.up('xl')]:{
      width: '1000px',
    }

  },
  drawerHeader: {
    display: 'flex',
    flexDirection:'column'
  },
  drawerTitle:{
    flex: '0 0 auto',
    margin: 0,
    padding: '8px',
    borderBottom:'1px solid #dfdfdf'
  },
  iconButton:{
    top: '12px',
    color: '#9e9e9e',
    right: '8px',
    padding: 0,
    position: 'absolute'
  },
  title:{
    paddingLeft:'15px'
  }
}));

function AccountingDrawer({open, handleClose, title, children, width=null}) {
  const classes = useStyles();


  return (
          <Drawer anchor='right' open={open} onClose={handleClose}>
            <div className={classes.drawerHeader}>
              <div className={classes.drawerTitle}>
                <Typography variant="h6" className={classes.title}>{title}</Typography>
                <IconButton className={classes.iconButton} onClick={handleClose}>
                  <CloseIcon />
                </IconButton>
              </div>
            </div>
            <div className={width ? width : classes.list}>
            {children}
            </div>
          </Drawer>
  );
}
export {AccountingDrawer};
