import React from 'react';
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import DashboardHeader from "../../Containers/Dasboard/DashboardHeader";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as snackbarActions from "../../Redux/Actions/SnackbarActions/SnackbarActions";
import PharmacySnackbar from "../../Utils/Snackbars/SnackbarUtil";
import {titleCase} from "../../Utils/titleCaseFunction";
import Card from "@material-ui/core/Card";
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faList} from "@fortawesome/free-solid-svg-icons/faList";
import {useManageUsers} from "./CustomHooks/useManageUsers";
import Row from "react-bootstrap/Row";
import Label from "../../Utils/FormInputs/Label";
import TextField from "../../Utils/FormInputs/TextField";


const EditUser = ({actions, snackbars, match: {params}}) => {
    const {state,  submitted, isSubmitted, handleChange, handleSubmit, formRef,handleCloseSnackbar} = useManageUsers(actions,params)
    const {first_name, last_name, email, gender, date_of_birth} = state;
    const {open, variant, message} = snackbars;
    return (
        <div className='journals'>
            <DashboardHeader settings editSettingsUser/>
            <div className='general-ledger-header'>
                <div className="row">
                    <div className="col-lg-6">
                        <h6 style={{marginTop: '0.5rem'}}>Edit User</h6>
                    </div>
                    <div className="col-lg-6">
                        <div className="text-right">
                            <Link to="/manageusers" style={{textDecoration: "none", color: "inherit"}}>
                                <button type="button" className="btn mr-2 pharmacy-info-btn btn-sm">
                                    <FontAwesomeIcon icon={faList}/> Manage Users
                                </button>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
            <div className="styled-component">
                <Card className="p-3">
                    <PharmacySnackbar open={open} variant={variant} message={message}
                                      handleCloseBar={handleCloseSnackbar}/>
                    <form ref={formRef} onSubmit={handleSubmit} autoComplete="off">
                        <Form.Group as={Row}>
                            <Label type name='First Name' sm={2} column/>
                            <Col sm={7}>
                                <TextField submitted={submitted} type="text" value={titleCase(first_name)} name="first_name"
                                           onChange={handleChange}
                                           placeholder="First name"/>
                                {(submitted && !first_name) &&
                                <div className="invalid-text">Please enter the your first name</div>}
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                            <Label type name='Last Name' sm={2} column/>
                            <Col sm={7}>
                                <TextField submitted={submitted} type="text" value={titleCase(last_name)} name="last_name"
                                           onChange={handleChange}
                                           placeholder="Last Name"/>
                                {(submitted && !last_name) &&
                                <div className="invalid-text">Please enter your last name</div>}
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                            <Label type name='Email' sm={2} column/>
                            <Col sm={7}>
                                <TextField submitted={submitted} type="email" value={email} name="email"
                                           onChange={handleChange}
                                           placeholder="admin@example.org"/>
                                {(submitted && !email) &&
                                <div className="invalid-text">Please enter your email</div>}
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                            <Label  name='Date of Birth' sm={2} column/>
                            <Col sm={7}>
                                <TextField type="date" value={date_of_birth} name="date_of_birth"
                                           onChange={handleChange}
                                           placeholder="Date of birth"/>
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                            <Label type name='Gender' sm={2} column/>
                            <Col sm={7}>
                                <Form.Check type="radio" inline label="Female" name="gender" value="female"
                                            checked={gender === "female"} id="female"
                                            onChange={handleChange}/>
                                <Form.Check type="radio" inline label="Male" id="male" name="gender"
                                            value="male"
                                            checked={gender === "male"} onChange={handleChange}/>
                                {(submitted && !gender) &&
                                <div className="invalid-text">Please select gender</div>}
                            </Col>
                        </Form.Group>
                        <div className="text-center">
                            <button type="submit" disabled={isSubmitted === 'pending'}
                                    className="btn pharmacy-btn px-4"> {isSubmitted === 'pending' ? "Saving..." : "Save"}</button>
                        </div>
                    </form>

                </Card>
            </div>
        </div>
    )

}

function mapStateToProps(state) {
    return {
        snackbars: state.snackbars
    }

}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            snackbarActions: bindActionCreators(snackbarActions, dispatch)
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(EditUser);