import React, {useCallback, useEffect, useRef, useState} from 'react';
import {useDates} from "../../Accounting/Expenses/hooks/useDates";
import axios from "axios";
import config from "../../../Helpers/config.json";
import {errorMessages} from "../../../Helpers/ErrorMessages";
import {exportPDF} from "../../../Utils/exportPDF";
import {copyToClipboard} from "../../../Utils/copyToClipboard";
import {convert} from "../../../Utils/titleCaseFunction";
import {history} from "../../../Helpers/history";
import {dateConvert} from "../../../Utils/ConvertDate";


export const useManagePurchase = (actions) => {
    const storage = JSON.parse(sessionStorage.getItem('total_count'))
    const item_count = storage ? storage : 0
    const count = () => +item_count || 0
    const [purchases, setPurchases] = useState([]);
    const [totalCount, setTotalCount] = useState(count);
    const [activePage, setActivePage] = useState(1);
    const [search, setSearch] = useState('');
    const [loading, setLoading] = useState('idle');
    const [totalAmount, setAmount] = useState(0);
    const [openDialog, setOpenDialog] = useState(false);
    const [purchase_id, setPurchaseId] = useState('');
    const [isSubmitted, setisSubmitted] = useState('idle');
    const [loadingPurchase, setLoadingPurchase] = useState('idle')
    const [productPurchase, setProductPurchase] = useState({supplierDetails:{}, products:[]})
    const [draftDialog, setDraftDialog] = useState(false)
    const [date, setDate] = useState({from_date:'', to_date:''});
    const isResolved = isSubmitted === 'resolved';

    const {from_date, to_date} = date;
    const csvLink = useRef();
    const componentRef = useRef();

    const user = JSON.parse(sessionStorage.getItem('user'));
    const singleUser = !user ? {} : user;
    const user_id = !singleUser.user_id ? '' :singleUser.user_id;


    // const calculateTotalAmount = (arr) => {
    //     const sum = arr.reduce((a, purchase) => {
    //         return +(a + purchase.total_amount);
    //     }, 0);
    //     setAmount(sum)
    // };

    const retrievePurchases = useCallback((start_date, end_date)=>{
        setLoading('idle')
        axios.get(`${config.epharmUrl}/Cpurchase/purchases`, {params:{page_number:activePage, start_date, end_date}}).then(response => {
            const res = response.data;
            const dt = res.data ? res.data : {}
            const data = dt.purchases ? dt.purchases : [];
            const t_amount = data.grand_total_amount ? data.grand_total_amount :{}
            const p_list = data.purchase_list ? data.purchase_list : []
            const purchase_count = dt.total_records ? dt.total_records : 0;
            if(activePage === 1){
                sessionStorage.setItem('total_count', JSON.stringify(purchase_count))
                setTotalCount(purchase_count);
            }else {
                setTotalCount(count)
            }
            setPurchases(p_list);

            setAmount(t_amount);
            setLoading('success');
            setisSubmitted('idle');
        }).catch(error => {
            errorMessages(error, null, actions)
            setLoading('error')
        })
    },[activePage])

    useEffect(() => {
      retrievePurchases(from_date, to_date)
    }, [retrievePurchases, isResolved]);


    const handleChangeDate = (event) =>{
        const {value, name} = event.target;
        setDate({...date,[name]:value})
    }


    const handleChangeSearch = (event) => {
        setSearch(event.target.value)
        if(event.target.value !== ''){
            axios.get(`${config.epharmUrl}/Cpurchase/purchase_list_search`, {params:{search:event.target.value}}).then(response => {
                const res = response.data;
                const dt = res ? res : []
                setPurchases(dt);
                // calculateTotalAmount(dt)
            }).catch(error => {
                errorMessages(error, null, actions)
                setLoading('error')
            })
        }else {
            retrievePurchases(from_date, to_date)
        }

    }
    
    const handleOpenDialog = id => {
        setPurchaseId(id);
        setOpenDialog(true);
        setLoadingPurchase('loading')
        axios.get(`${config.epharmUrl}/Cpurchase/purchase_details_data`,
            {params: {purchase_id:id}}).then(response => {
                const dataRes = response.data;
                const dt = dataRes ? dataRes : {};
                const products = !dt.purchase_all_data ? [] : dt.purchase_all_data
                setProductPurchase({supplierDetails:dt, products})
                setLoadingPurchase('success')
            }).catch((err) => {
           errorMessages(err, null, actions)
            setLoadingPurchase('error')
        })

    };

    const handleOpenDraftDialog = () =>{
        setDraftDialog(true);
    }

    const handleDeletePurchase = (is_draft = false) => {
        axios.post(`${config.epharmUrl}/Cpurchase/delete_purchase`,  {purchase_id, user_roles_id:user_id}).then(() => {
            actions.snackbarActions.successSnackbar('Purchase canceled successfully');
            setisSubmitted('resolved');
            setDraftDialog(false);
            if(is_draft){
                sessionStorage.setItem(purchase_id, JSON.stringify(productPurchase))
                history.push(`/editpurchase/${purchase_id}`)
            }
        }).catch((error) => {
            errorMessages(error, null, actions)
        });
    };

    const handleCancelPurchase = () =>{
        handleDeletePurchase(true)
    }

    const handleCancelDraft = () =>{
        handleDeletePurchase();
        handleCloseDialog();
    }

    const handleCloseDraftDialog = () =>{
        setDraftDialog(false)
    }

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };


   const handleCloseBar = () => {
        actions.snackbarActions.hideSnackBar();
    };

    const downloadPdf = () => {
        exportPDF("Purchase Report", [["SL", "Invoice NO", "Purchase ID", "Manufacturer Name", "Date", "Amount"]],
            purchases.map((purchase, index) => [index += 1, purchase.chalan_no, purchase.purchase_id,
                purchase.manufacturer_name, purchase.purchase_date ? convert(purchase.purchase_date) : '', purchase.total_amount]), "Purchase.pdf");
    };
    
    const copyTable = () => {
        copyToClipboard(actions, 10, componentRef)
    };

    const fetchData = () => {
        csvLink.current.link.click()
    };

    const handleSearch = (event) =>{
        event.preventDefault();
        if (from_date && to_date){
            retrievePurchases(from_date, to_date)
            // setSubmitted(!submitted)
        }
    }

    const handleChangePage = (page) => {
        setActivePage(page)
    }


    const isLoading = loading === 'loading'
    const isSuccess = loading === 'success'
    const isError = loading === 'error'

    return {purchases, totalCount, activePage, search, from_date, to_date,
        handleChangeDate,totalAmount,loading, openDialog,handleOpenDialog,
        handleCloseDialog, handleDeletePurchase, handleCloseBar, downloadPdf,
        copyTable, fetchData, handleSearch, handleChangePage, handleChangeSearch,
        isSuccess, isError, isLoading, productPurchase, loadingPurchase, purchase_id, draftDialog,
        handleCloseDraftDialog, handleOpenDraftDialog,handleCancelPurchase, handleCancelDraft}
}