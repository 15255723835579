import React from 'react';
import PrintIcon from "@material-ui/icons/Print";
import { RightAlignedContainer } from '../../../Utils/styledComponents';
import {makeStyles} from "@material-ui/core/styles";

export const useAccountingStyles = makeStyles((theme) => ({
    icon:{
        width:'0.9em',
        height:'0.9em'
    },
    trialBalance:{
        width:'90%',
        maxWidth:'1200px',
        margin:'0 auto'
    },
    item:{
        display:'flex',
        justifyContent:'flex-end'
    },
    body1: {
        fontSize: '0.98em'
    },
    customSelect:{
        width:'50%',
    }
}));


const PrintPdfButtons = ({handlePrint, children}) =>{

    const classes = useAccountingStyles();
    return (
        <RightAlignedContainer>
            {/* <div className="btn-group mt-2"> */}
              
                <button onClick={handlePrint }
                        className='btn btn-sm btn-default'>
                    <PrintIcon classes={{root: classes.icon}}/></button>
                {children}
            {/* </div> */}
        </RightAlignedContainer>
    )
}
export {PrintPdfButtons}