import React from 'react';
import ReusableDashboardHeader from "../../Containers/Dasboard/ReusableDashboardHeader";
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
import SelectInput from "../../Utils/FormInputs/SelectInput";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import {makeStyles} from "@material-ui/core/styles";
import * as snackbarActions from '../../Redux/Actions/SnackbarActions/SnackbarActions';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {usePurchaseOrders} from "./CustomHooks/usePurchaseOrders";
import {dateStringTimeConvert} from "../../Utils/titleCaseFunction";
import PictureAsPdfOutlinedIcon from '@material-ui/icons/PictureAsPdfOutlined';
import PrintOutlinedIcon from '@material-ui/icons/PrintOutlined';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import {usePurchaseOrderDetails} from "./CustomHooks/usePurchaseOrderDetails";
import {ReusablePurchaseOrderDetails} from "./ReusablePurchaseOrderDetails";
import PrintDialog from "../../Utils/Dialog/PrintDialog";
import ReusableDialog from "../../Utils/Dialog/ReusableDialog";
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Label from "../../Utils/FormInputs/Label";
import Textarea from "../../Utils/FormInputs/Textarea";
import {Link} from 'react-router-dom'
import PharmacySnackbar from "../../Utils/Snackbars/SnackbarUtil";
import GetAppIcon from '@material-ui/icons/GetApp';
import {usePrint} from "../../Utils/Templates/usePrint";
import {useExcelReports} from "../Invoice/CustomHooks/useExcelReports";
import {CancelDialog} from "../../Utils/DeleteDialog";
import CustomDialog from "../../Utils/Dialog/CustomDialog";
import {RightAlignedContainer} from "../../Utils/styledComponents";


export const printStyles = () => {
    return '' +
        '<style>' +
        'table {' +
        'width:100%;' +
        'border-collapse:collapse;' +
        '}' +
        'table.vendor-address-table th, table.vendor-address-table td {' +
        'padding:.3rem;' +
        'font-size:1.2rem;' +
        '}' +
        'table.vendor-table thead tr{' +
        'background-color: #1290a4;' +
        'color: #fff;' +
        'text-transform: uppercase;' +
        ' -webkit-print-color-adjust: exact; ' +
        '}' +
        'table.vendor-table th, table.vendor-table td {' +
        'padding:.5rem;' +
        'font-size:1.2rem;' +
        '}' +
        'table td p {' +
        'margin:0;' +
        '}' +
        '</style>';
}

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        // maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
        paddingTop: 0,
        paddingBottom: 0
    },
    icon: {
        width: '.9em',
        height: '.9em'
    },
    iconHolder: {
        minWidth: '38px '
    },
    listItem: {
        borderBottom: '1px solid #e8e8e8',
        paddingTop: '10px',
        paddingBottom: '10px',
        cursor: 'pointer'
    },
    activeLi: {
        backgroundColor: '#f5f5f5'
    },
    primary: {
        fontSize: '0.99em'
    },
    secondary: {
        fontSize: '0.9em'
    },
    item: {
        borderRight: '1px solid #c7c6c7',
        borderLeft: '1px solid #c7c6c7',
        height: '100vh',
        overflow: "auto",
        '&::-webkit-scrollbar': {
            width: "5px",
            height: "5px"
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#c7c6c7',
            "-webkit-border-radius": "10px",
            borderRadius: "10px",
        }
    },
    pItem: {
        borderLeft: '1px solid #c7c6c7',
    },
    rounded: {
        borderRadius: 0
    },
    listText: {
        textAlign: 'right'
    },
    approved: {
        color: "green"
    },
    purchased: {
        color: "#1d5dd3"
    },
    canceled: {
        color: '#D9512C'
    },
    pending: {
        color: "#F29339"
    },
    closed:{
        color: '#a30c0c'
    }
}));
const PurchaseOrderApproval = ({actions, snackbars, match}) => {
    const classes = useStyles();
    const {local_purchase_order_id, lpo_status: st} = match.params;
    const user = JSON.parse(sessionStorage.getItem('user'));
    const singleUser = !user ? {} : user;
    const permissions = !singleUser.permission ? {} : singleUser.permission;
    const add_lpo = !permissions.add_lpo ? {} : permissions.add_lpo;
    const edit_lpo = !permissions.edit_lpo ? {} : permissions.edit_lpo;
    const approve_lpo = !permissions.approve_lpo ? {} : permissions.approve_lpo;
    const cancel_lpo = !permissions.cancel_lpo ? {} : permissions.cancel_lpo;
    const {
        sub_total, downloadPdf, productList, details, cancel, confirmation, isSubmitted, submitted,
        arr, loading: approvalLoading, instructions, pdfRef, id, handleClick, handleConfirm, handleCloseConfirmation,
        handleOpenConfirmation, handleCancel, handleChangeReason, status, approved_by, prepared_by,
        handleOpenCancel, handleCloseCancel, reason, closeSnackbar, setId, closeProps
    } = usePurchaseOrderDetails(match, actions)
    const {openCloseDialog, handleClosePurchaseOrderDialog, handleOpenCloseDialog, handleClosePurchaseOrder,
        reason:closeReason, handleChangeReason:changeReason, submitted:submittedDraft,isPendingClosedOrder, isClosedOrder} = closeProps
    const {purchaseOrders, loading, value, handleChangeValue, filteredList} = usePurchaseOrders(actions, status, setId,  isClosedOrder)
    const {open, variant, message} = snackbars;
    const {lpo_status, lpo_id} = id;
    const {handlePrint, componentRef} = usePrint('')



    const currentObj = {file_name:'Local Purchase Order', url:'Creport/purchase_order_excel', params:{purchase_order_id:lpo_id}}

    const {exportExcel} = useExcelReports(actions,currentObj)

    const approveButton = (
        (approve_lpo.create || approve_lpo.read || approve_lpo.update || approve_lpo.delete) ?
        <button type='button' onClick={handleOpenConfirmation}
                className="btn btn-sm pharmacy-info-btn mr-2">Approve
        </button>: null
    )
    const cancelButton = (
        (cancel_lpo.create || cancel_lpo.read || cancel_lpo.update || cancel_lpo.delete) &&
        <button type='button' onClick={handleOpenCancel}
                className="btn btn-sm pharmacy-grey-btn mr-2">Cancel
        </button>
    )
    const purchaseButton = (
        (add_lpo.create || add_lpo.read || add_lpo.update || add_lpo.delete) ?
            <Link to={{pathname: `/addpurchase/${lpo_id}`}}>
                <button type='button'
                        className="btn btn-sm pharmacy-btn-outline mr-2">Make Purchase
                </button>
            </Link>: null
    )

    const closeButton = (
        (cancel_lpo.create || cancel_lpo.read || cancel_lpo.update || cancel_lpo.delete) ?
                <button type='button' onClick={handleOpenCloseDialog(lpo_id)}
                        className="btn btn-sm pharmacy-btn-dismiss mr-2">Close
                </button> : null
    )

    const purchaseOrderButton = (
        (add_lpo.create || add_lpo.read || add_lpo.update || add_lpo.delete) ?
            <Link to={{pathname: `/editpurchaseordertemplate/${lpo_id}/0/new`}}>

                <button type='button'
                        className="btn btn-sm pharmacy-btn-outline">Use as Template
                </button>
            </Link>: null
    )

    const status_button = {
        0: <>
            {approveButton}
            {cancelButton}
        </>,
        1: <>
            {purchaseButton}
            {closeButton}
        </>,
        3:<>
            {purchaseOrderButton}
        </>
    }
    return (
        <div className='journals'>
            <ReusableDashboardHeader component="Purchase Orders" heading="Purchase Orders"
                                     subHeading="Purchase Orders"
                                     link={`/purchaseorderapproval/${local_purchase_order_id}/${st}`}/>
            <PharmacySnackbar open={open} message={message} variant={variant} handleCloseBar={closeSnackbar}/>
            <PrintDialog message='Approve Purchase Order' openDialog={confirmation}
                         handleClose={handleCloseConfirmation} handleCancel={handleCloseConfirmation}
                         handleClick={handleConfirm} next='Approve' preview='No'
                         text='Are you sure you want to approve this purchase order?'/>
            <ReusableDialog openDialog={cancel} handleClose={handleCloseCancel} isSubmitted={isSubmitted}
                            width='sm' message='Reason for Canceling Purchase order' handleSubmit={handleCancel}>
                <Form.Group as={Row}>
                    <Label name='Reason' type sm='2' column/>
                    <Col sm={8}>
                        <Textarea onChange={handleChangeReason} value={reason} rows={5}/>
                        {submitted && !reason ? <div className='text-danger'>Please provide reason</div> : null}
                    </Col>
                </Form.Group>
            </ReusableDialog>
            <CustomDialog  handleClose={handleClosePurchaseOrderDialog} open={openCloseDialog} title='Close purchase order'>
                <form onSubmit={handleClosePurchaseOrder}>
                    <Form.Group as={Col}>
                        <Label name='Please provide a reason for closing the purchase order' type />
                        <Textarea submitted={submittedDraft} onChange={changeReason} value={closeReason} rows={5}/>
                            {submittedDraft && !closeReason ? <div className='text-danger'>Please provide reason</div> : null}

                    </Form.Group>
                    <RightAlignedContainer>
                        <button type='submit' disabled={isPendingClosedOrder} className='btn btn-sm pharmacy-info-btn mr-4'>{isPendingClosedOrder ? 'Continuing....'  : 'Continue'}</button>
                        <button onClick={handleClosePurchaseOrderDialog} type='button' className='btn btn-sm pharmacy-gray-btn'>No</button>
                    </RightAlignedContainer>

                </form>
            </CustomDialog>
            <Card elevation={0} classes={{root: classes.rounded}}>
                <Grid container spacing={0}>
                    <Grid item xs={6} md={4} classes={{item: classes.item}}>
                        <div className="top-bar all-journals">
                            <Grid container spacing={1}>
                                <Grid item xs={12} md={8}>
                                    <SelectInput value={value} onChange={handleChangeValue}
                                                 defaultOption='All Purchase Orders'
                                                 options={[{value: 0, text: 'Pending Approval'}, {
                                                     value: 1,
                                                     text: 'Approved'
                                                 },
                                                     {value: 2, text: 'Canceled'}]}/>
                                </Grid>
                                <Grid item xs={12} md={4}>
                                </Grid>
                            </Grid>
                        </div>
                        {loading ? <div className='text-center mt-5'><img src="/images/listPreloader.gif"
                                                                          style={{width: '20%'}}
                                                                          alt="loader"/></div> :
                            purchaseOrders.length > 0 ?
                                filteredList(purchaseOrders).map(item => {
                                    const status_text={
                                        0:'PENDING APPROVAL',
                                        1:'APPROVED',
                                        2:'CANCELED',
                                        3:'FULFILLED',
                                        4:'CLOSED'

                                    }
                                    const status_style =  {
                                        0:classes.pending,
                                        1:classes.approved,
                                        2:classes.canceled,
                                        3:classes.purchased,
                                        4:classes.closed
                                    }
                                    return (
                                    <List className={classes.root} key={item.local_purchase_order_id}>
                                        <ListItem
                                            classes={{root: `${classes.listItem} ${item.local_purchase_order_id === id.lpo_id && classes.activeLi}`}}
                                            onClick={(() => handleClick(item))}>
                                            <Grid container spacing={1} data-testid="purchase_order_item">
                                                <Grid item xs={12} md={7}>
                                                    <ListItemText primary={item.manufacturer_name}
                                                                  secondary={`${item.purchase_order_number} | ${item.create_date ? dateStringTimeConvert(item.create_date) : ''}`}
                                                                  classes={{
                                                                      primary: classes.primary,
                                                                      secondary: classes.secondary
                                                                  }}/>
                                                </Grid>
                                                <Grid item xs={12} md={5}>
                                                    <ListItemText 
                                                        primary={`UGX ${item.grand_total ? item.grand_total.toLocaleString() : ''}`}
                                                        secondary={status_text[item.status]}
                                                        classes={{
                                                            root: classes.listText,
                                                            primary: classes.primary,
                                                            secondary: `${classes.secondary} ${status_style[item.status]}`
                                                        }}/>
                                                </Grid>
                                            </Grid>
                                        </ListItem>
                                    </List>
                                )}) : <div className='text-center mt-3'>No records found</div>}

                    </Grid>
                    <Grid item xs={6} md={8} classes={{item: classes.pItem}}>
                        {approvalLoading ? <div className='text-center mt-5'><img src="/images/listPreloader.gif"
                                                                                  style={{width: '10%'}}
                                                                                  alt="loader"/></div> : <div>
                            <div className="top-bar">
                                <div className="text-right">
                                    <div className='btn-group'>
                                        {(edit_lpo.create || edit_lpo.read || edit_lpo.update || edit_lpo.delete) && !(lpo_status === 1 || lpo_status === 3 || lpo_status === 4) ?
                                            <Link to={{pathname: `/editpurchaseorder/${lpo_id}/${lpo_status}`}}>
                                                <button type='button'
                                                        className='btn btn-sm pharmacy-grey-btn'>
                                                    <EditOutlinedIcon classes={{root: classes.icon}}/>
                                                </button>
                                            </Link> : null}
                                        <button type='button' onClick={downloadPdf}
                                                className='btn btn-sm pharmacy-grey-btn'>
                                            <PictureAsPdfOutlinedIcon classes={{root: classes.icon}}/>
                                        </button>
                                        <button type='button' onClick={exportExcel}
                                                className='btn btn-sm pharmacy-grey-btn'>
                                           <GetAppIcon style={{width:'0.78em', height:'0.78em'}}/>Excel
                                        </button>
                                        <button type='button'
                                                onClick={handlePrint}
                                                className='btn btn-sm pharmacy-grey-btn'>
                                            <PrintOutlinedIcon classes={{root: classes.icon}}/>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            {lpo_status !== 2 ?
                                <div className="sub-journal-bar approve-purchase-order">
                                    <div className="text-right">
                                        {status_button[lpo_status]}
                                    </div>
                            </div> : null}
                            <div className="m-2" ref={componentRef}>
                                <ReusablePurchaseOrderDetails details={details} instructions={instructions} arr={arr}
                                                              pdfRef={pdfRef} productList={productList}
                                                              sub_total={sub_total} approved_by={approved_by}
                                                              prepared_by={prepared_by}/>
                            </div>
                        </div>}
                    </Grid>
                </Grid>
            </Card>

        </div>
    )
}

function mapStateToProps(state) {

    return {
        snackbars: state.snackbars
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            snackbarActions: bindActionCreators(snackbarActions, dispatch)
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PurchaseOrderApproval);