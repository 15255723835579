import React, {Component} from "react";
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faList} from "@fortawesome/free-solid-svg-icons/faList";
import {faPlus} from "@fortawesome/free-solid-svg-icons/faPlus";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
import Paper from "@material-ui/core/Paper";
import {EnhancedToolbar,EnhancedPharmacyListHead, stableSort, getSorting, useStyles} from "../../Utils/Tables/MuiTables";
import DrugsAvailableTablePagination from "../../Utils/Tables/MuiTablePagination";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import FilterResults from 'react-filter-search';
import TablePagination from '@material-ui/core/TablePagination';
import {withStyles} from "@material-ui/core/styles";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {compose} from "recompose";
import * as supplierActions from "../../Redux/Actions/SupplierActions/SupplierActions";
import DashboardHeader from "../../Containers/Dasboard/DashboardHeader";
import Select from "react-select";

const styles = theme => ({
    root: {
        width: "100%",
        overflowX: "auto"
    },
    table: {
        minWidth: 650
    },
    subMenuLink: {
        display: "block",
        color: "#757575",
        textDecoration: "none"
        // padding: theme.spacing.unit * 6,
    },
    itemStyle: {
        backgroundColor: "#f9f9f9"
    },
    itemOdd: {
        backgroundColor: "#fff"
    }
});


class MainSupplierLedger extends Component{
    constructor(props){
        super(props);
        this.state={
            term: "",
            order: 'asc',
            setOrder: 'asc',
            orderBy: 'person',
            selected: [],
            setSelected: [],
            page: 0,
            setPage: 0,
            dense: false,
            setDense: false,
            rowsPerPage: 5,
            openTable:true,
            openLedger:false
        };
        this.handler = this.handler.bind(this);
    }

    handleSearch = (event) =>{
        event.preventDefault();
        this.setState(prevState =>({
            openLedger: !prevState.openLedger, openTable: !prevState.openTable
        }))
    };
    handler(value) {
        this.setState({term: value})
    }
    handleRequestSort = (event, property) => {
        const orderBy = property;
        let order = 'desc';

        if (this.state.orderBy === property && this.state.order === 'desc') {
            order = 'asc';
        }

        this.setState({order, orderBy});
    };
    handleChangePage = (event, newPage) => {
        this.setState({page: newPage});
    };

    handleChangeRowsPerPage = (event) => {
        this.setState({rowsPerPage: event.target.value, page: 0});
    };
    render() {
        const {order, dense, orderBy, selected, rowsPerPage, page, term} = this.state;
        const {classes,_id,ledgerData, from_date, to_date,openLedger,supplier_value,
            handleChange,handleChangeSupplier,submitLedger,supplierList, supplierLedgerInfo} = this.props;
            const singleSupplier  = !supplierLedgerInfo.supplier ? {} : supplierLedgerInfo.supplier;
            const supplier_name = !singleSupplier.supplier_name ? "" : singleSupplier.supplier_name;
            const address = !singleSupplier.address ? "" : singleSupplier.address
        const emptyRows = rowsPerPage - Math.min(rowsPerPage, ledgerData.length - page * rowsPerPage);
        const print_date = new Date().toLocaleString();
        return(
            <div>
                <DashboardHeader supplier supplierLedger/>
            <div className="card my-3 p-3">
                <div className="pos-buttons">
                    <Link to="/addsupplier" style={{textDecoration:"none", color:"inherit"}}>
                        <button type="button" className="btn mr-2 pharmacy-info-btn">
                            <FontAwesomeIcon icon={faPlus} /> Add Supplier</button>
                    </Link>
                     <Link to="/managesupplier">
                    <button type="button" className="btn pharmacy-primary-btn mr-2">
                        <FontAwesomeIcon icon={faList} /> Manage Supplier</button>
                     </Link>
                </div>

                <div className="card mt-4">
                    <div className="m-3">
                         <Form id="ledger_form" onSubmit={submitLedger} autoComplete="off">
                        <Form.Group as={Row}>
                            <Form.Label column sm={4}>Select Supplier</Form.Label>
                            <Col sm={6}>
                                <Select
                                    value={supplier_value}
                                    options={
                                        supplierList.map(supplier=>({
                                            value:supplier.supplier_id,
                                            label:supplier.supplier_name
                                        }))}
                                    onChange={handleChangeSupplier}
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                            <Form.Label column sm={4}>From</Form.Label>
                            <Col sm={6}>
                                <Form.Control type="date" value={from_date} name="from_date" onChange={handleChange}/>
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                            <Form.Label column sm={4}>To</Form.Label>
                            <Col sm={6}>
                                <Form.Control type="date" value={to_date} name="to_date" onChange={handleChange}/>
                            </Col>
                        </Form.Group>
                        <button  className="btn pharmacy-btn px-4 offset-md-5">Search</button>
                    </Form>
                    </div>
                </div>
                <div className="card mt-4 p-2">
                    <h6 className="ml-3 mt-2">Supplier Ledger</h6>
                    <hr/>
                    {openLedger &&
                    <div className="text-center">
                        <h5>{supplier_name}</h5>
                        <h6>{address}</h6>
                        <h6> Print Date: {print_date}</h6>
                    </div>}
                    <Paper className={`${classes.paper} table-responsive`}>
                        <EnhancedToolbar numSelected={selected.length} handler={this.handler} ledger2/>
                        <div className={classes.tableWrapper}>
                            <Table
                                aria-labelledby="tableTitle"
                                size={dense ? 'small' : 'medium'}
                                // className={classes.table}
                            >
                                <EnhancedPharmacyListHead
                                    classes={classes}
                                    numSelected={selected.length}
                                    order={order}
                                    orderBy={orderBy}
                                    onRequestSort={this.handleRequestSort}
                                    rowCount={ledgerData.length}
                                    ledger2
                                />
                                <FilterResults
                                    value={term}
                                    data={ledgerData}
                                    renderResults={results => (
                                        <TableBody>
                                            {stableSort(results, getSorting(order, orderBy))
                                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                .map((d, index) => {
                                                    return (
                                                        <TableRow
                                                            hover
                                                            role="checkbox"
                                                            tabIndex={-1}
                                                            key={index}
                                                            className='mui-table-row'>
                                                            <TableCell component="th" scope="row" padding="default"
                                                                       className='mui-table-cell'>
                                                                <span>{d.date}</span>
                                                            </TableCell>
                                                            <TableCell>
                                                                <span>{d.description}</span>
                                                            </TableCell>
                                                            <TableCell>
                                                                <span><Link to={{pathname:`/manufacturerinvoice/${_id}`}}
                                                                            style={{color:"#09905c"}}>{d.transaction_id}</Link></span>
                                                            </TableCell>
                                                            <TableCell>
                                                                <span>{!d.deposit_no ? '' : d.deposit_no}</span>
                                                            </TableCell>
                                                            <TableCell>
                                                                <span>{d.debit}</span>
                                                            </TableCell>
                                                            <TableCell>
                                                                <span>{d.credit}</span>
                                                            </TableCell>
                                                            <TableCell>
                                                                <span>{d.balance}</span>
                                                            </TableCell>
                                                        </TableRow>
                                                    );
                                                })}
                                            {/*{emptyRows > 0 && (*/}
                                            {/*    <TableRow style={{height: 49 * emptyRows}}>*/}
                                            {/*        <TableCell colSpan={6}/>*/}
                                            {/*    </TableRow>*/}
                                            {/*)}*/}
                                        </TableBody>
                                    )}
                                />
                            </Table>
                        </div>
                        <TablePagination
                            rowsPerPageOptions={[ 5, 10, 25]}
                            count={ledgerData.length}
                            rowsPerPage={rowsPerPage}
                            component="div"
                            page={page}
                            SelectProps={{
                                inputProps: { 'aria-label': 'rows per page' },
                                native: true,
                            }}
                            onPageChange={this.handleChangePage}
                            onRowsPerPageChange={this.handleChangeRowsPerPage}
                            ActionsComponent={DrugsAvailableTablePagination}
                        />
                    </Paper>
                </div>
            </div>
        </div>
        )
    }

}
function mapStateToProps(state) {
    return{
        suppliers:state.suppliers
    }

}

function mapDispatchToProps(dispatch) {
    return {
        actions:{
            supplierActions:bindActionCreators(supplierActions,dispatch)
        }
    }
}
export default compose(connect(mapStateToProps, mapDispatchToProps),withStyles(useStyles, styles) )(MainSupplierLedger);