import axios from "axios";
import config from "../../../Helpers/config.json";
import {useEffect, useState} from "react";
import {errorMessages} from "../../../Helpers/ErrorMessages";
import {history} from "../../../Helpers/history";

export const useAddRole = (actions, match) => {
    const {id, role_name} = match;
    const [role_id, setRoleId] = useState('');
    const [roles, setRoles] = useState([]);
    const [submitted, setSubmitted] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState('idle');
    const [roleUpdate, setRoleUpdate] = useState([])
    const [isBlocking, setIsBlocking] = useState(false)


    useEffect(() => {
        axios.get(`${config.epharmUrl}/Permission/retrieve_all_roles`).then(res => {
            const data = res.data;
            const dt = !data ? {} : data;
            const list = !dt.role_list ? [] : dt.role_list;
            const ls = list.map(item => ({
                check: false,
                open:true,
                title: item.module_name,
                content: item.params.map(item=>({...item, checkedRow:false}))
            }))
            setRoles(ls)
        }).catch(err => {
            errorMessages(err, null, actions)
        })
    }, [])

    
    useEffect(() =>{
        if (!id){
            return
        }
        axios.get(`${config.epharmUrl}/Permission/edit_role`, {params: {id}}).then(res => {
            const dt = res.data;
            const roles = dt ? dt :{};
            setRoleUpdate(roles)
        }).catch(error => {
            errorMessages(error, null, actions)
        })
        
    },[id])

    const  parseRoles = (role_details) =>{
        let arr= [];
        for(let i in role_details.module){
            let content = []
            for(let m in role_details.role_detail){
                if(role_details.role_detail[m].module_name === role_details.module[i].name){
                    let myObj = {create: role_details.role_detail[m].create,
                        delete: role_details.role_detail[m].delete,
                        sub_module_id: role_details.role_detail[m].fk_module_id,
                        id: role_details.role_detail[m].id, sub_module_name: role_details.role_detail[m].name,
                        read: role_details.role_detail[m].read,update: role_details.role_detail[m].update}
                    content.push(myObj)
                }
            }
            const arr1 = content.some(item=>item.create || item.delete || item.read || item.update)
            let obj = {
                title:role_details.module[i].name,
                open:arr1,
                content:content
            }
            arr.push(obj)
        }
        return arr
    }

    const handleOpen = (idx) =>{
        const itemsArr = roles.map((item, index)=>{
            if(index === idx){
                return {...item,open:!item.open}
            }else{
                return item
            }
        })
        setRoles(itemsArr)
    }


    useEffect(()=>{
        if (!id){
            return;
        }
        const role_permissions = parseRoles(roleUpdate)
        setRoleId(role_name)
        setRoles(role_permissions)
    },[id, roleUpdate, role_name])



    const handleChange = (event) => {
        const {value} = event.target;
        setRoleId(value)
        setIsBlocking(true)
    };

    const handleChangeCheckAll = (event, index) => {
        const {checked} = event.target;
        const arr = roles.map((item, idx) => {
            if (index === idx) {
                return {
                    ...item, check: checked,
                    content: item.content.map(it => {
                        return {...it, create: checked, read: checked, update: checked, delete: checked,checkedRow:checked}
                    })
                }
            }
            return item
        })
        setIsBlocking(true)
        setRoles(arr)
    }

    const handleCheckRow = (event, index,  ind) => {
        const {checked} = event.target;
        const arr = roles.map((item, idx) => {
            if (index === idx) {
                return {
                    ...item,
                    content: item.content.map((it, i) => {
                        if (i === ind) {
                            return {...it, create: checked, read: checked, update: checked, delete: checked,checkedRow:checked}
                        }
                        return it
                    })
                }
            }
            return item
        })
        setRoles(arr)
        setIsBlocking(true)
    };

    const handleChangeCheck = (event, index,  ind) => {
        const {checked, name} = event.target;
        const arr = roles.map((item, idx) => {
            if (index === idx) {
                return {
                    ...item,
                    content: item.content.map((it, i) => {
                        if (i === ind) {
                            return {...it, [name]: checked}
                        }
                        return it
                    })
                }
            }
            return item
        })
        setRoles(arr)
        setIsBlocking(true)
    };

    const submitRole = (event) => {
        event.preventDefault();
        const formData = new FormData();
        if (id){
            formData.append('rid',id);
        }
        formData.append('role_id', role_id);
        setSubmitted(true)
        const arr = roles.some(item=>item.content.some(row => +row.create || +row.read || +row.update || +row.delete))
        if (role_id && arr) {
            setIsSubmitted('pending')
            setIsBlocking(false)
            roles.forEach(it=>{
                it.content.forEach(item => {
                    formData.append('fk_module_id', item.sub_module_id);
                    formData.append('create', +(item.create));
                    formData.append('read', +(item.read));
                    formData.append('update', +(item.update));
                    formData.append('delete', +(item.delete));
                })
            })
            const url = id ? 'update' : 'create'
            axios.post(`${config.epharmUrl}/Permission/${url}`, formData).then(() => {
                setIsSubmitted('resolved')
                actions.snackbarActions.successSnackbar('Role added successfully!');
                history.push('/rolelist')
            }).catch(error => {
                setIsSubmitted('rejected')
                errorMessages(error, null, actions)
            })
        } else {
            window.scrollTo(0, 0);
        }


    };
    const handleCloseBar = () => {
        actions.snackbarActions.hideSnackBar()
    };

    return {
        role_id, roles, submitted, isSubmitted,handleCheckRow,
        handleChange, handleChangeCheckAll, handleChangeCheck, submitRole, handleCloseBar,
        handleOpen, isBlocking
    }
}