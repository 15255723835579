import {useEffect,  useState, useRef} from "react";
import axios from "axios";
import config from "../../../Helpers/config.json";
import {errorMessages} from "../../../Helpers/ErrorMessages";

 const useAddCategory = (actions) =>{
    const [category_name, setCategoryName] = useState('');
    const [category_id, setCategoryId] = useState('');
    const [openDialog, setOpenDialog] = useState(false);
    const [openEdit, setOpenEdit] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState('idle');
    const [category, setCategory] = useState({});
    

    useEffect(() => {
        if (!category_id) {
            return;
        }
        axios.post(`${config.epharmUrl}/Ccategory/category_update_form`, null, {params: {category_id}}).then(response => {
            const data = response.data;
            const dt = data ? data : {}
            setCategory(dt)
        }).catch(error => {
            //errorMessages(error, null, actions)
        })
    }, [category_id])

    useEffect(() => {
        if(!category_id){
            return
        }
        const {category_name: name} = category;
        setCategoryName(name)
    }, [category, category_id])


    const handleChange = event => {
        const {value} = event.target;
        setCategoryName(value)
    };


    const handleSubmit = event => {
        event.preventDefault();
        const formData = new FormData();
        if (category_id) {
            formData.append('category_id', category_id);
            formData.append('status', 1);
        }
        formData.append('category_name', category_name);
        setSubmitted(true)
        if (category_name) {
            setIsSubmitted('pending');
            const url = category_id ? 'category_update' : 'insert_category'
            axios.post(`${config.epharmUrl}/Ccategory/${url}`, formData).then(() => {
                actions.snackbarActions.successSnackbar(`Category ${category_id ? 'updated':'added'}  successfully`)
                setIsSubmitted('resolved');
                setOpenDialog(false);
                setCategoryName('');
                setOpenEdit(false)
                setSubmitted(false)
            }).catch(error => {
                //errorMessages(error, null, actions)
                actions.snackbarActions.errorSnackbar("Category already inserted")
                setIsSubmitted('rejected')
            })
        }
    };

    const handleOpenEdit = (_id ) => {
        setCategoryId(_id)
        setOpenEdit(true)
    };

    const handleCloseEdit = () => {
        setOpenEdit(false)
        setCategoryName('');
    }


    const handleOpenDialog = () => {
        setOpenDialog(true)
        setCategoryId('')
    }

    const handleCloseDialog = () => {
        setOpenDialog(false)
    };

    

    const isPending = isSubmitted === 'pending'
    const isResolved = isSubmitted === 'resolved'

    const categoryProps = {category_name,openDialog,openEdit, handleOpenDialog, submitted,
        handleCloseDialog, handleCloseEdit, handleOpenEdit, isPending, handleSubmit, handleChange}

    return {isResolved, categoryProps}
}

export {useAddCategory}