import React from 'react'
import { bindActionCreators } from 'redux';
import ReusableDashboardHeader from '../../Containers/Dasboard/ReusableDashboardHeader';
import {history} from "../../Helpers/history";
import PharmacySnackbar from '../../Utils/Snackbars/SnackbarUtil';
import * as snackbarActions from "../../Redux/Actions/SnackbarActions/SnackbarActions";
import {connect} from "react-redux";
import {EnhancedTableHead, EnhancedTableToolbar, MuiPaper, MuiPagination } from '../../Utils/Tables/CustomTable';
import Table from "@material-ui/core/Table";
import { TableBody, TableCell, TableRow } from '@material-ui/core';
import {AddPaymentForm, EditPaymentForm} from "./AddPaymentForm";
import {usePaymentTypes} from "./CustomHooks/usePaymentTypes";
import CustomDialog from '../../Utils/Dialog/CustomDialog';
import TableContainer from "@material-ui/core/TableContainer";





const headData = [
    {id: 'name', numeric: false, disablePadding: false, label: 'Payment Type'},
    {id: 'description', numeric: false, disablePadding: false, label: 'Description'},
    {id: 'account', numeric: false, disablePadding: false, label: 'Debit Account'},
    {id: 'action', numeric: false, disablePadding: false, label: 'Action'},

];

const PaymentTypesList = ({actions, snackbars }) => {

    const { paymentTypes,   handleCloseBar,
        paymentProps, isLoading, isSuccess, isError, page, rowsPerPage, handleChangePage,
        handleChangeRowsPerPage} = usePaymentTypes(actions);
    const {variant, message, open} = snackbars;
    const {openDialog, handleOpenDialog, handleCloseDialog, openEdit, handleOpenEditDialog, handleCloseEditDialog} = paymentProps

    return (
        <div className='journals'>
            <ReusableDashboardHeader component="Settings" heading="Payment Methods"
                                     subHeading="Payment Methods" link={history.location.pathname}/>
            <div className='mui-tables mt-3'>
                <CustomDialog handleClose={handleCloseDialog} open={openDialog} title="Add Payment Method" maxWidth='md'>
                    <AddPaymentForm {...paymentProps}/>
                </CustomDialog>

                <CustomDialog handleClose={handleCloseEditDialog} open={openEdit} title="Edit Payment Method">
                    <EditPaymentForm {...paymentProps}/>
                </CustomDialog>

                <div  align="right">
                    <button onClick={()=>handleOpenDialog('')} type="button"
                            className="btn pharmacy-info-btn mr-2 mb-2">
                        Add Payment Method
                    </button>
                </div>

                <PharmacySnackbar open={open} message={message} variant={variant} handleCloseBar={handleCloseBar} />
                <MuiPaper>
                    <EnhancedTableToolbar title="Payment Methods">

                        {/*<Form.Control type="text" */}
                        {/*              value={searchValue ? titleCase(searchValue) : ''}*/}
                        {/*              onChange={handleChange}*/}
                        {/*              className='form__control'*/}
                        {/*              placeholder="Search" style={{float: 'right'}}/>*/}
                    </EnhancedTableToolbar>
                    <TableContainer>
                        <Table
                            aria-labelledby="tableTitle"
                            size='medium'
                            aria-label="enhanced table"
                        >
                            <EnhancedTableHead headCells={headData}/>
                            <TableBody>
                                {isLoading ? <TableRow>
                                    <TableCell colSpan={headData.length}align="center"><img src="/images/listPreloader.gif"
                                                                                            alt="loader"
                                                                                            style={{width: '5%'}}/></TableCell>
                                </TableRow> : null}
                                {isSuccess ? paymentTypes.length > 0 ? paymentTypes.slice(0, 10).map((d, index) => {
                                    return (
                                        <TableRow
                                            role="checkbox"
                                            tabIndex={-1}
                                            key={index}
                                        >
                                            <TableCell>
                                                <span>{d.payment_type_name}</span>
                                            </TableCell>
                                            <TableCell>
                                                <span>{d.description}</span>
                                            </TableCell>
                                            <TableCell>
                                                <span>{d.category_name}</span>
                                            </TableCell>
                                            <TableCell>
                                                {!d.default ?  <button onClick={()=>handleOpenEditDialog(d.id)} className='btn btn-sm pharmacy-info-btn'>Edit</button>:null}
                                            </TableCell>

                                        </TableRow>);
                                }):<TableRow><TableCell colSpan={headData.length} align='center'>No records found</TableCell></TableRow>:null}
                                {isError ? <TableRow> <TableCell colSpan={headData.length} align='center'>The server did not return a valid response</TableCell></TableRow>:null}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <MuiPagination colSpan={headData.length} data={paymentTypes} handleChangePage={handleChangePage}
                                   page={page} handleChangeRowsPerPage={handleChangeRowsPerPage} rows={paymentTypes.length} rowsPerPage={rowsPerPage}/>
                </MuiPaper>
            </div>


        </div>
    )
}

function mapStateToProps(state) {
    return {
        snackbars: state.snackbars
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            snackbarActions: bindActionCreators(snackbarActions, dispatch)
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PaymentTypesList);
