import React, {useEffect, useState} from 'react';
import axios from "axios";
import config from "../../../../Helpers/config.json";
import {errorMessages} from "../../../../Helpers/ErrorMessages";
import {history} from "../../../../Helpers/history";



export const useSetUpSuppliers = (actions, component) => {
    const [suppliers, setSuppliers] = useState([]);
    const [accounts, setAccounts] = useState([]);
    const [submitted, setSubmitted] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState('idle');
    const [rows, setRows] = useState([ {id:'',name:'',credit_account:'', debit_account:''}])



    useEffect(() => {
        axios.get(`${config.epharmUrl}/accounting/chart/journals`).then(res => {
            const data = res.data;
            const dt = data ? data : {};
            const arr = dt.data ? dt.data : []
            const list = arr.map(item => ({
                value: item.id,
                label: item.head_name
            }))
            setAccounts(list)
        }).catch(err => {
            errorMessages(err, null, actions)
        })
    }, [])



    useEffect(() => {
        axios.get(`${config.epharmUrl}/Cmanufacturer/retrieve_all_manufacturers`).then(res => {
            const data = res.data;
            const dt = !data ? {} : data;
            const arr = !dt.total_manufacturers ? [] : dt.total_manufacturers
            const arr_data = arr.map(item=>({
                ...item,
                value: item.manufacturer_id,
                label: item.manufacturer_name
            }))
            setSuppliers(arr_data)

        }).catch((err) => {
            errorMessages(err, null, actions);

        })
    }, []);


    const handleChangeDebitAccount = (val, index) => {
        const arr = rows.map((item, ind) => {
            if (ind === index) {
                return {...item, debit_account: val}
            }
            return item
        })
        setRows(arr)
    }

    const handleChangeCreditAccount = (val, index) => {
        const arr = rows.map((item, ind) => {
            if (ind === index) {
                return {...item, credit_account: val}
            }
            return item
        })
        setRows(arr)
    }

    const handleAddRow = () =>{
        const obj = {id:'',
        name:'',
        credit_account:'',
        debit_account:''}
        setRows([...rows, obj])
    }

    const handleRemoveRow = (index) =>{
        const arr = rows.filter((_,ind)=>ind !== index)
        setRows(arr)
    }


    const handleChangeSupplier = (val, index) => {
        const arr = rows.map((item, ind) => {
            if (ind === index) {
                return {...item, id: val}
            }
            return item
        })
        setRows(arr)
    }



    const handleSubmit = (event) => {
        event.preventDefault();
        setSubmitted(true);
        const data = rows.map(item=>{
            const credit  = item.credit_account ? item.credit_account : {};
            const credit_value = credit.value ? credit.value : ''
            const debit  = item.debit_account ? item.debit_account : {};
            const debit_value = debit.value ? debit.value : ''
            const supplier = item.id ? item.id : {};
            const id = supplier.value ? supplier.value : ''
            return{
            entity_id: +id,
            credit_ledger:+credit_value,
            debit_ledger :+debit_value,
            entity_type:'supplier',
            default:0
         }
        })
        setIsSubmitted('pending');
        axios.post(`${config.epharmUrl}/accounting/entity_behaviour/add`,
         data).then(() => {
            actions.snackbarActions.successSnackbar('Entry made successfully')
            setIsSubmitted('resolved')
            const arr = rows.map(item=>({
                ...item,id:'',name:'',credit_account:'', debit_account:'',
            }))
            setRows(arr)
            history.push('/ledgers/ledgerlist/supplier')
        }).catch(err => {
            errorMessages(err, null, actions)
            setIsSubmitted('rejected')
        })
     
    }


    const closeSnackbar = () =>{
        actions.snackbarActions.hideSnackBar()
    }


    const isPending = isSubmitted === 'pending'
    const isResolved = isSubmitted === 'resolved'
    const isRejected = isSubmitted === 'rejected'

    const supplierProps = {entities:suppliers,  rows, handleAddRow,
        accounts, submitted, handleChangeCreditAccount, handleChangeDebitAccount, handleSubmit,
        isPending, isRejected, isResolved, handleRemoveRow,handleChangeEntity:handleChangeSupplier}


    return {supplierProps, closeSnackbar}
}