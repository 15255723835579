import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import "bootstrap/dist/css/bootstrap.min.css";
import "@fortawesome/fontawesome-free";
import "@fortawesome/fontawesome-svg-core";
import "@fortawesome/free-solid-svg-icons";
import App from './Containers/App';
import * as serviceWorker from './serviceWorker';
import {Provider} from "react-redux";
import configureStore from "./Redux/createStore";
import {Router} from "react-router-dom"
import {history} from "./Helpers/history";
import {AppProviders} from "./Context";

import { QueryClient, QueryClientProvider } from 'react-query'


const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        retry: false
      }
    }
  });


const store = configureStore();


ReactDOM.render(
    <Provider store={store}>
        <QueryClientProvider client={queryClient}>
            <Router history={history}>
                <App/>
            </Router>
        </QueryClientProvider>
    </Provider>
    ,document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
