import Form from "react-bootstrap/Form";
import TextField from "../../Utils/FormInputs/TextField";
import Textarea from "../../Utils/FormInputs/Textarea";
import React from "react";



function ManufacturerReturnForm({rows, details,submitted,  isPending,handleChangeState,
                                    disabled,  handleChangeDiscount, handleChangeQuantity, handleChangeDetails,
                                    handleSubmitReturn,  changeCheckValue, supplierDetails=null, headData=[]}) {

    return (
        <form  onSubmit={handleSubmitReturn} autoComplete='off'>
            {supplierDetails}
            <table className="table table-bordered table-sm custom-bordered-table">
                <thead>
                <tr>
                    {headData.map((item, index)=>(
                        <th key={index}>{item.name}</th>
                    ))}
                </tr>
                </thead>
                <tbody>
                {rows.map((row, index) => (
                    <tr key={index}>
                        <td>
                            {row.product_name}
                        </td>
                        <td>
                            {row.batch_id}
                        </td>
                        <td>
                            {row.purchase_quantity}
                        </td>
                        <td>
                            {row.available_quantity}
                        </td>
                        <td>
                            <TextField submitted={submitted && row.rtn} type="number" value={row.product_quantity}
                                       onChange={(e) => handleChangeQuantity(e, index)}/>
                            {submitted && row.rtn &&  !row.product_quantity ?
                                <div className="invalid-text">Quantity is required</div> :
                                (row.product_quantity && row.product_quantity > row.purchase_quantity) ?
                                    <div className="invalid-text">You can only return up
                                        to {row.purchase_quantity}</div> : null}
                        </td>
                        <td>
                            {row.rate}
                        </td>
                        <td>
                            <TextField type="number" value={row.discount}
                                       name="discount"
                                       onChange={(e) => (handleChangeDiscount(e,index))}/>

                        </td>
                        <td>
                            {row.total}
                        </td>
                        <td>
                            <Form.Check type="checkbox"
                                        vlaue={row.rtn}
                                        name="rtn"
                                        onChange={(e) => changeCheckValue(e,index)}
                                        style={{transform: "scale(1)"}}
                            />
                        </td>
                    </tr>
                ))}
                <tr>
                    <td colSpan={headData.length} rowSpan={2}>
                        <Textarea  name="details" value={details}
                                   onChange={handleChangeDetails}/>
                        <div className="mt-4">
                            <Form.Check type="radio" name='radio'
                                        value={+(0)}
                                        defaultChecked onChange={handleChangeState}
                                        label="Return to Supplier"/>
                            <Form.Check type="radio" name='radio'
                                        value={+(1)} onChange={handleChangeState}
                                        label="Wastage"/>
                            <Form.Check type="radio" name='radio'
                                        value={+(2)} onChange={handleChangeState}
                                        label="Damage"/>
                        </div>

                    </td>
                </tr>

                </tbody>
            </table>
            <div className='text-right'>
                <button disabled={!(isPending || disabled)}
                        className="btn pharmacy-btn btn-sm">{isPending ? 'Submitting...' : 'Submit'}</button>
            </div>

        </form>
    )
}
export {ManufacturerReturnForm}