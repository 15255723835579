import {useEffect, useRef, useState} from 'react';
import { useDates } from '../../Accounting/Expenses/hooks/useDates';
import axios from 'axios';
import config from '../../../Helpers/config.json'
import {errorMessages} from "../../../Helpers/ErrorMessages";
import {useMuiPagination} from "../../../Containers/Dasboard/DashboardTables";
import {convDate} from "../../../Utils/titleCaseFunction";
import {exportPDF} from "../../../Utils/exportPDF";

export const useBankTransaction = (actions) => {
    const CURRENT_YEAR = new Date().getFullYear();
    const [openDialog, setOpenDialog] = useState(false);
    const [period, setPeriod] = useState({value: 'date', label: 'Date'});
    const [date, handleChangeDate, setDate] = useDates();
    const [year, setYear] = useState(CURRENT_YEAR);
    const [transactions, setTransactions] = useState([]);
    const [state, setState] = useState({amount: '', charges: '', transaction_date:''});
    const [banks, setBanks] = useState([]);
    const [bank, setBank] = useState('');
    const [accounts, setAccounts] = useState([]);
    const [account, setAccount] = useState('');
    const [transaction, setTransaction] = useState('');
    const [submitted, setSubmitted] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState('idle');
    const [loading, setLoading] = useState('idle');
    const {page, rowsPerPage, handleChangeRowsPerPage, handleChangePage} = useMuiPagination();
    const csvRef = useRef();

    const headers = [{key: 'date', label: 'Date'}, {key: 'receipt_no', label: 'Receipt No.'},
        {key: 'expense_type_name', label: 'Expense'},{key: 'amount', label: 'Amount'}]


    const inputType = {
        'date': 'date',
        'weekly': 'week',
        'monthly': 'month',
        'custom': 'date'
    }
    useEffect(() => {
        (async () => {
            try {
                const res = await axios.get(`${config.epharmUrl}/accounting/banks`)
                let _data = res.data
                let dt = _data.data ?? [];
                let arr = dt.map(i => ({label: i.bank_name, value: i.id}))
                setBanks(arr)
            } catch (e) {
                // errorMessages(e, null, actions)
            }
        })();
    }, [])

    useEffect(() => {
        (async () => {
            try {
                const formData = new FormData();
                formData.append('bank_id', bank.value)
                const res = await axios.post(`${config.epharmUrl}/accounting/bank/accounts`, formData)
                let _data = res.data
                let dt = _data.data ?? [];
                let arr = dt.map(i => ({label: i.account_name, value: i.id}))
                setAccounts(arr)
            } catch (e) {
                // errorMessages(e, null, actions)
            }
        })();
    }, [bank])

    const dep = isSubmitted === 'resolved'
    useEffect(() => {
        (async () => {
            setLoading('pending')
            try {
                const res = await axios.get(`${config.epharmUrl}/accounting/transactions`)
                let _data = res.data
                let dt = _data.data ?? [];
                const arr = dt.map(({date,bank, account_name, bank_name,transaction,amount,charges})=>({
                    date:convDate(date),
                    bank, account_name, bank_name,transaction,amount,charges
                }))
                setTransactions(arr)
                setLoading('resolved')
            } catch (e) {
                // errorMessages(e, null, actions)
                setLoading('rejected')
            }
        })();
    }, [dep])


    const handleChangeBank = (value) => {
        setBank(value)
    }

    const handleChangeAccount = (value) => {
        setAccount(value)
    }

    const handleChangeTransaction = (value) => {
        setTransaction(value)
    }

    const handleChange = (e) => {
        const {name, value} = e.target
        setState({...state, [name]: value})
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        const {transaction_date, amount, charges} = state
        const formData = new FormData()
        formData.append('bank_id', bank.value)
        formData.append('account_id', account.value)
        formData.append('transaction', transaction.value)
        formData.append('date', date)
        formData.append('charges', charges)
        formData.append('amount', amount)
        setSubmitted(true);
        if (bank && account && transaction && transaction_date && charges && amount) {
            setIsSubmitted('pending')
            axios.post(`${config.epharmUrl}/accounting/bank/add_transaction`, formData).then(() => {
                actions.snackbarActions.successSnackbar('Transaction recorded successfully')
                setOpenDialog(false)
                setIsSubmitted('resolved')
                setBank('');
                setAccount('')
                setTransaction('')
                setState({date: '',amount: '', charges:''});
                setSubmitted(false);
            }).catch(err => {
                errorMessages(err, null, actions);
                setIsSubmitted('rejected')
            })
        }
    }


    const handleChangeYear = (event) => {
        setYear(event.target.value)
    }

    const handleChangePeriod = (value) => {
        setPeriod(value)
        setDate({from_date: '', to_date: ''})
    }

    const handleOpenDialog = () => {
        setOpenDialog(true)
    }

    const handleCloseDialog = () => {
        setOpenDialog(false)
    }
    const closeSnackbar = () =>{
        actions.snackbarActions.hideSnackBar()
    }

    const downloadPDF = () => {
        exportPDF("Expense Report", [["Date", "Receipt No.", "Expense", "Amount",]],
            transactions.map(exp => [exp.date,exp.receipt_no, exp.expense_type_name,exp.amount]), "Expense Report.pdf",
            )
    };


    return {openDialog, handleCloseDialog, handleOpenDialog, period, handleChangePeriod, inputType, date,
        handleChangeDate, year, handleChangeYear,state,handleChange,closeSnackbar, submitted,isSubmitted,
        page, rowsPerPage, handleChangeRowsPerPage, handleChangePage, loading, csvRef,headers,
        downloadPDF, bank, banks, account,accounts,transactions, transaction,handleChangeAccount, handleChangeBank,
    handleChangeTransaction, handleSubmit}
}