import React, {useEffect,  useState} from 'react';
import {useDates} from "../../Expenses/hooks/useDates";
import axios from "axios";
import config from "../../../../Helpers/config.json";
import {errorMessages} from "../../../../Helpers/ErrorMessages";


export const useSummaries = (actions, url) => {
    const [data, setData] = useState({arrData:{}, loading:'idle'})
    const [submitted, setSubmitted] = useState('idle');
    const [date, handleChangeDate] = useDates();
    const {from_date, to_date} = date;
    const {loading} = data


    const isSubmitted = submitted === 'resolved'

    useEffect(() => {
        setData({...data, loading: 'loading'})
        axios.get(`${config.epharmUrl}/${url}`).then(response => {
            const res = response.data;
            const dt = res ? res : {}
            setData({...data, creditors: dt,loading: 'success'})
        }).catch(error => {
            errorMessages(error, null, actions)
            setData({...data, loading: 'error'})
        })
    }, [isSubmitted])



    const handleSearch = (event) =>{
        event.preventDefault();
        if (from_date && to_date){
            setSubmitted('resolved')
        }
    }

    const handleCloseBar = () => {
        actions.snackbarActions.hideSnackBar();
    };

    const isLoading = loading === 'loading'
    const isSuccess = loading === 'success'
    const isError = loading === 'error'

    return {data, from_date, to_date, handleChangeDate, isLoading, isSuccess, isError, handleCloseBar,  handleSearch}
}