import React from 'react'
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {ReusableNewCOA} from "./NewCOAccount";
import {useChartOfAccounts} from "./hooks/useChartOfAccounts";
import { Container, RightAlignedContainer } from '../../../Utils/styledComponents';
import { AccountingDrawer } from '../../../Utils/AccountingUtils/AccountingDrawer';
import ReusableDashboardHeader from "../../../Containers/Dasboard/ReusableDashboardHeader";
import PharmacySnackbar from "../../../Utils/Snackbars/SnackbarUtil";
import * as snackbarActions from '../../../Redux/Actions/SnackbarActions/SnackbarActions'
import COATable from "./COATable";
import { LoadingGif } from '../../../Utils/Loader';
import { NoTransaction } from './ReusableJournalTable';




const ChartOfAccounts = ({actions, snackbars}) => {
    const {open, variant, message} = snackbars;
    const {closeSnackbar, openDialog, handleOpenDialog, handleCloseDialog,  coaProps, retrieveCategoryAccounts,
         accounts,isLoadingData, isSuccessData, isErrorData} = useChartOfAccounts(actions);
    const {isPending, handleSubmit} = coaProps;

    return (
        <div className='journals'>
            <ReusableDashboardHeader component="Chart of Accounts" heading="Accounting"
                           subHeading="Chart of accounts" link="/accounting/coa"/>
            <PharmacySnackbar message={message} open={open} handleCloseBar={closeSnackbar} variant={variant}/>
            <AccountingDrawer open={openDialog} title='Create Account' handleClose={handleCloseDialog}>
                <form onSubmit={handleSubmit} autoComplete="off" className="general-card">
                    <ReusableNewCOA {...coaProps}/>
                    <div style={{marginLeft:'15px'}}>
                        <button type="submit" className="btn btn-sm pharmacy-info-btn"
                            disabled={isPending}>{isPending ? 'Submitting...' : 'Submit'}</button>
                    </div>
                </form>
            </AccountingDrawer>
            <Container>
                <RightAlignedContainer>
                    <div className="text-right">
                            <button type='button' className='btn btn-sm pharmacy-info-btn mb-2'
                                    onClick={handleOpenDialog}>New Account</button>
                        </div>
                </RightAlignedContainer>

                {isLoadingData ? <div className='text-center mt-5'>
                    <LoadingGif/>
                </div>:null}
                {isSuccessData ?
                    <COATable  {...{data:accounts, clickCategory:retrieveCategoryAccounts}}/>: <NoTransaction text='accounts'/>}
                {isErrorData ? <div className="mt-3 text-center">The server did not return a valid response</div>:null}
            </Container>
        </div>
    );
}

function mapStateToProps(state) {
    const {snackbars} = state;
    return {snackbars}
}

function mapDispatchToProps(dispatch) {
    return {actions: {snackbarActions: bindActionCreators(snackbarActions, dispatch)}}
}

export default connect(mapStateToProps, mapDispatchToProps)(ChartOfAccounts);
