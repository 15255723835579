import React from 'react';
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Label from "../../Utils/FormInputs/Label";
import TextField from "../../Utils/FormInputs/TextField";
import { SelectItem } from '../../Utils/FormInputs/AutoCompleteInput';


export const ReusableSearchForm = ({period, handleChangePeriod, year, handleChangeYear, handleChangeDate,
                                       from_date, to_date, inputType, isSubmitted, submitted, periods}) =>{
    const years = [{value: '2020', text: '2020'}, {value: '2021', text: '2021'}, {
        value: '2022',
        text: '2022'
    }, {value: '2023', text: '2023'},
        {value: '2024', text: '2024'}, {value: '2025', text: '2025'}, {value: '2026', text: '2026'},
        {value: '2027', text: '2028'}, {value: '2029', text: '2029'}, {value: '2030', text: '2030'}] 

    const val = period?.value
    return (
            <>
            <Label name='Select Period ' type htmlFor='selectPeriod' />
            <Form.Group as={Row} style={{marginBottom: period === 'today' ? '0' :  '-1%', display:"flex",alignItems:"center"}}>

                <Col sm={3}>
                    <SelectItem onChange={handleChangePeriod}  label_text="label"
                                value={period}   value_text="value"
                                options={periods}
                        />
                </Col>
                {period?.value !== 'today' ? <>
                    {period?.value === 'yearly' ?
                        <Col sm={3}>
                            <SelectItem onChange={handleChangeYear} label_text='text'
                                value={year} 
                                options={years}
                            value_text='value'
                            />
                            {submitted && !year ?
                            <div className='invalid-text'>Year is
                                required</div> : null}
                        </Col> :
                        <Col sm={4}>
                            <Form.Group as={Row}>
                                {period?.value === 'custom' ?
                                    <Label name='From' column sm={2}
                                           style={{textAlign: 'right'}}/> : null}
                                <Col sm={10}>
                                    <TextField type={inputType[val]}
                                               onChange={handleChangeDate}
                                               value={from_date} name='from_date'/>
                                    {submitted && !from_date ?
                                        <div className='invalid-text'>This value is
                                            required</div> : null}
                                </Col>
                            </Form.Group>
                        </Col>
                    }
                </> : null}
                {period?.value === 'custom' ? <Col sm={4}>
                    <Form.Group as={Row}>
                        <Label name='To' column sm={2}/>
                        <Col sm={10}>
                            <TextField type='date' value={to_date} onChange={handleChangeDate}
                                       name='to_date'/>
                            {submitted && !to_date ?
                                <div className='invalid-text'>To date is required</div> : null}
                        </Col>
                    </Form.Group>
                </Col> : null}
                {(period && period?.value !== 'today') ? <Col sm={1}>
                    <button type='submit' disabled={isSubmitted === 'pending'}
                            className='btn btn-sm pharmacy-btn-outline'>{isSubmitted === 'pending' ? 'Filtering...':'Filter'}
                    </button>
                </Col> : null}
            </Form.Group>
    </>
    )
}