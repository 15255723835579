import React from "react";
import { CustomTooltip, CustomizedTick, MonthPickerComponent } from "./DashboardContent";
import SelectInput from "../../Utils/FormInputs/SelectInput";
import TextField from "../../Utils/FormInputs/TextField";
import { titleCase } from "../../Utils/titleCaseFunction";
import { AreaChart, Area, XAxis, YAxis, Tooltip, ResponsiveContainer,  Legend, CartesianGrid } from 'recharts';

const renderColorfulLegendText = (value, entry) => {
  const { color } = entry;
  const new_value = value.replaceAll('_', ' ');
  return <span style={{ color , fontSize:'10px'}}>{titleCase(new_value)}</span>;
};

/**
 * Function that renders the Progress Report for the Dashboard
 * @param {*} param0 
 * @returns 
 */
export const ProgressReport = ({period, handleChangeDate, handleChangePeriod, progressReport,
  handleChangeYear, years, year, inputType, from_date}) => {

    return (
      <div>
        <div style={{display:'flex', flexWrap: 'wrap', justifyContent:'space-between', paddingRight: '0px'}}>
          <div>
            <p className="dashboard-card-font-color">Progress Report</p>
            <div className="orange-short-border"></div>
          </div>

          <div>
            <SelectInput value={period} onChange={handleChangePeriod} className="progress-no-border-dropdown progress-dashboard-dropdown"
                options={[
                  { value: 'yearly', text: 'Yearly'},
                  {value: 'monthly', text: 'Monthly'}
                ]}
            />
          </div>

          {period !== 'today' ? 
            <>
              {period === 'yearly' ?
                <div>
                    <SelectInput options={years} value={year}  className="progress-no-border-dropdown progress-dashboard-dropdown"
                      onChange={handleChangeYear}/>
                </div> 
                : period === 'monthly' ?
                  <MonthPickerComponent
                    class_name="progress-no-border-dropdown progress-dashboard-dropdown"
                    handleChange={handleChangeDate} name='from_date'
                  />
                :
                <div>
                    <div style={{display:'flex', justifyContent: 'space-evenly'}}>
                      {period === 'custom' ?
                          <div style={{display:'flex', alignItems:'center'}}>
                            <div style={{fontSize:'10px', fontWeight:'600', margin:'5px'}}>From</div>
                          </div>
                          : null}
                        <TextField type={inputType[period]}  className="progress-no-border-dropdown progress-dashboard-dropdown"
                          onChange={handleChangeDate} value={from_date} name='from_date'/>
                    </div>
                </div>
              }
            </> 
            : 
          null}

        </div>

        <div style={{marginTop:'5px'}}>
          <ResponsiveContainer width={'99%'} height={325}>
            <AreaChart width={480} height={300} data={progressReport} margin={{ top: 10, right: 0, left: 0, bottom: 0 }}>
              <CartesianGrid vertical={false} />
              <XAxis dataKey="date" />
              <YAxis type="number" domain={['dataMin', 'dataMax']}  tick={<CustomizedTick />} />
              <Tooltip content={<CustomTooltip/>} />
              <Area type="monotone" dataKey="total_purchases" stroke="#D00000" strokeWidth={4} fillOpacity={0.2} fill="#D00000" />
              <Area type="monotone" dataKey="total_sales" stroke="#3B4CB8" strokeWidth={4} fillOpacity={0.2} fill="#3B4CB8" />
              <Legend verticalAlign="top" align="right" iconType={'circle'} formatter={renderColorfulLegendText}  height={36}/>
            </AreaChart>
          </ResponsiveContainer>
        </div>
      </div>
    )
}
