import React from "react";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "@material-ui/core/Card";
import TextField from "../../Utils/FormInputs/TextField";
import { titleCase } from "../../Utils/titleCaseFunction";
import Textarea from "../../Utils/FormInputs/Textarea";
import PharmacySnackbar from "../../Utils/Snackbars/SnackbarUtil";
import ReusableDashboardHeader from "../../Containers/Dasboard/ReusableDashboardHeader";
import {CameraAlt} from "@material-ui/icons";
import IconButton from "@material-ui/core/IconButton";
import {useCompany} from "../../Context/company-context";
import {useSnackbar} from "../../Context/snackbar-context";


const CompanyDetails = (props) => {
  const {state, submitted, isSubmitted, filename, inputRef,
    handleChange, handleSubmit,  handleImageSelection} = useCompany(props);
  const {state:snackbarState, handleClose} = useSnackbar(props)
  const { companyName, companyEmail, mobile, address, website } = state;
  const {open, variant, message} = snackbarState

  return (
      <div>
        <ReusableDashboardHeader
            component="Company Details"
            heading="Settings"
            subHeading="Company Details"
            link="/companydetails"
        />
        <div className=" styled-component">
          <PharmacySnackbar
              open={open}
              message={message}
              variant={variant}
              handleCloseBar={handleClose}
          />
          <Card className="p-3">
            <form autoComplete="off" className="mt-2" onSubmit={handleSubmit}>
              
              <div className='image-container mb-3'>
                <div className="edit-image company-image">
                   {filename ?  <img src={filename} alt="company"/>:<span>Upload company logo</span>}
                </div>
                <div className="icon-button">
                  <IconButton size="small" className="icon-holder" onClick={()=>inputRef.current.click()}><CameraAlt/></IconButton>
                </div>
              </div>
              <input type='file' ref={inputRef} onChange={handleImageSelection} style={{display:'none'}}/>
              <Form.Group as={Row} controlId="formGridEmail">
                <Form.Label column sm={2}>
                  Company Name<span className="text-danger asterics ml-1">*</span>
                </Form.Label>
                <Col sm={7}>
                  <TextField
                      type="text"
                      placeholder="Company name"
                      name="companyName"
                      value={titleCase(companyName)}
                      onChange={handleChange}
                  />
                  {submitted && !companyName && (
                      <div className="invalid-text">
                        Please enter the company name
                      </div>
                  )}
                </Col>
              </Form.Group>

              <Form.Group as={Row} controlId="formGridPassword">
                <Form.Label sm={2} column>
                  Company Email
                </Form.Label>
                <Col sm={7}>
                  <TextField
                      type="email"
                      placeholder="Email Address"
                      name="companyEmail"
                      value={companyEmail}
                      onChange={handleChange}
                  />
                  {/*{submitted && !companyEmail && (*/}
                  {/*    <div className="invalid-text">*/}
                  {/*      Please enter the company email*/}
                  {/*    </div>*/}
                  {/*)}*/}
                </Col>
              </Form.Group>
              <Form.Group as={Row} controlId="formGridAddress1">
                <Form.Label sm={2} column>
                  Company Mobile
                </Form.Label>
                <Col sm={7}>
                  <TextField
                      type="text"
                      name="mobile"
                      value={mobile}
                      placeholder="Company mobile"
                      onChange={handleChange}
                  />
                  {submitted && !mobile && (
                      <div className="invalid-text">
                        Please enter the company mobile
                      </div>
                  )}
                </Col>
              </Form.Group>

              <Form.Group as={Row} controlId="formGridAddress2">
                <Form.Label sm={2} column>
                  Company Address
                </Form.Label>
                <Col sm={7}>
                  <Textarea
                      as="textarea"
                      name="address"
                      value={address}
                      placeholder="Company address"
                      onChange={handleChange}
                  />
                  {submitted && !address && (
                      <div className="invalid-text">
                        Please enter the company address
                      </div>
                  )}
                </Col>
              </Form.Group>
              <Form.Group as={Row} controlId="formGridCity">
                <Form.Label sm={2} column>
                  Website
                </Form.Label>
                <Col sm={7}>
                  <TextField
                      type="website"
                      name="website"
                      value={website}
                      placeholder="website"
                      onChange={handleChange}
                      min="0"
                  />
                </Col>
              </Form.Group>
                <button
                    type="submit"
                    disabled={isSubmitted === "pending"}
                    className={`btn pharmacy-btn offset-md-5 mt-3 `}
                >
                  {isSubmitted === "pending" ? "Updating..." : "Update"}
                </button>
            </form>
          </Card>
        </div>
      </div>
  );
};



export default CompanyDetails;
