import React from 'react'
import {CustomSelect} from "../../../Utils/FormInputs/SelectInput";


export function SupplierSetUpForm({rows, entities, handleChangeCreditAccount, handleChangeDebitAccount, handleSubmit,
     isPending,  handleAddRow, handleRemoveRow, handleChangeEntity, accounts,   headData=[]}) {
  return (
        <form onSubmit={handleSubmit} autoComplete='off'>
            <table className="table table-bordered">
                <thead>
                    <tr>
                        {headData.map((item, index)=>(
                            <td key={index} width='100'>{item.name} {item.isRequired ? <span className="help-block">*</span>:null}</td>
                        ))}
                    </tr>
                </thead>
                <tbody>
                {rows.map((item, index) => (
                    <tr key={index}>
                        <td>
                        <CustomSelect value={item.id} options={entities}
                                            onChange={value => handleChangeEntity(value, index)}/>
                        </td>
                        <td>
                            <CustomSelect value={item.debit_account} options={accounts}
                                            onChange={value => handleChangeDebitAccount(value, index)}/>
                        </td>
                        <td>
                            <CustomSelect value={item.credit_account} options={accounts}
                                            onChange={value => handleChangeCreditAccount(value, index)}/>
                        </td>
                        <td>
                            <button type='button' onClick={()=>handleRemoveRow(index)} className='btn btn-sm pharmacy-btn-dismiss'>Remove</button>
                        </td>
                    </tr>
                ))}
                <tr>
                    <td colSpan={4}> <button type='button' onClick={handleAddRow} className='btn btn-sm pharmacy-gray-btn'>Add another line</button></td>
                
                </tr>
                </tbody>
            </table>
            <button type='submit' disabled={isPending}  className='btn btn-sm pharmacy-btn'>{isPending ? 'Submitting...':'Submit'}</button>
        </form>
  )
}

