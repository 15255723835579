import React from 'react';
import {bindActionCreators} from "redux";
import * as snackbarActions from '../../../Redux/Actions/SnackbarActions/SnackbarActions'
import {connect} from "react-redux";
import PharmacySnackbar from "../../../Utils/Snackbars/SnackbarUtil";
import {ReusableRouteTabs} from "../../../Utils/Dialog/ReusableTabs";
import {Container} from "../../../Utils/styledComponents"
import { history } from '../../../Helpers/history';
import {ProductCustomerLedgerForm} from './ProductCustomerLedgerForm';
import {useSetupCustomers} from "./hooks/useSetupCustomers";
import {RouteLabels} from "../../../Utils/Tabs/RouteLabels";
import ReusableDashboardHeader from "../../../Containers/Dasboard/ReusableDashboardHeader";




const headData = [{name:'Customer', width:'400', isRequired:true},{name:'Debit', isRequired:true},
    {name:'Credit', isRequired:true},{name:'Action', width:'100'}]
const SetupCustomers = ({actions, snackbars}) => {
    const customer_path = '/ledgers/setupcustomers' || '/ledgers/ledgerlist/customer'
    const components = [
        {label: 'Accounting Period', path: '/accounting/setup'},
        {label: 'Suppliers', path: '/ledgers/setupsuppliers'},
        {label: 'Customers', path: customer_path},
        {label: 'Products', path: '/ledgers/setupproducts'},
    ]

    const subLinks = [
        {label: 'Setup', route: '/ledgers/setupcustomers'},
        {label: 'Ledgers', route: '/ledgers/ledgerlist/customer'},
    ]
    const {customerProps, closeSnackbar} = useSetupCustomers(actions)

    const {open, variant, message} = snackbars;
    return (
        <div className='journals'>
            <ReusableDashboardHeader component='Customer Ledgers' heading="Accounting"
                                     subHeading='Customer Ledgers' link={history.location.pathname}/>
            <PharmacySnackbar message={message} open={open} handleCloseBar={closeSnackbar} variant={variant}/>
            <Container>
                <ReusableRouteTabs value={customer_path} tabHeading={components}/>
                <RouteLabels value={history.location.pathname} data={subLinks}/>
                <ProductCustomerLedgerForm {...{...customerProps, headData}}/>
            </Container>
        </div>

    )
}

function mapStateToProps(state) {
    const {snackbars} = state;
    return {snackbars:snackbars}
}

function mapDispatchToProps(dispatch) {
    return {actions: {snackbarActions: bindActionCreators(snackbarActions, dispatch)}}
}

export default connect(mapStateToProps, mapDispatchToProps)(SetupCustomers);