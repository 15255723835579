import React, {useState,useRef,useEffect} from "react";
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import axios from 'axios';
import config from "../../Helpers/config";
import {errorMessages} from "../../Helpers/ErrorMessages";
import * as snackbarActions from "../../Redux/Actions/SnackbarActions/SnackbarActions";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import PharmacySnackbar from "../../Utils/Snackbars/SnackbarUtil";
import {history} from "../../Helpers/history"
import ReusableDashboardHeader from "../../Containers/Dasboard/ReusableDashboardHeader";


const ChangePassword = ({actions,snackbars}) => {
    const [state,setState] =useState({
        email:'',
        old_password:'',
        new_password:'',
        repassword:''
    });
    const [submitted,setSubmitted] = useState(false);
    const [submitting,setSubmitting] = useState(false);

    const passwordForm = useRef();


    useEffect(() => {
        const user = JSON.parse(sessionStorage.getItem('user'));
        const user_obj = !user ? {} : user;
        const user_email = !user_obj.user_email ? "" : user_obj.user_email;
        if(user){
            setState({...state,email:user_email})
        }
        
    }, [])

    const handleChange = (event) =>{
        const {name,value} = event.target;
        setState({...state,[name]:value})
    };
    const handleSubmit = (event) =>{
        event.preventDefault();
        if(state.email && state.old_password && state.new_password && state.repassword){
            setSubmitting(true);
            const formData = new FormData(passwordForm.current);
            axios.post(`${config.epharmUrl}/admin/change_password`, formData).then(response=>{
                setSubmitting(false)
                actions.snackbarActions.successSnackbar('Password updated successfully')
                setTimeout(()=>{
                    history.push('/login')
                },2000)
            }).catch(error=>{
                errorMessages(error,null,actions);
                setSubmitting(false)
            });
        }else{
            setSubmitted(true);

        }


    };
    const handleCloseSnackbar = () => {
        actions.snackbarActions.hideSnackBar();
    };
    const {open,variant,message,} = snackbars;
    return (
        <div>
            <ReusableDashboardHeader component='Settings' heading="Settings"
                                     subHeading="Change Password" link={history.location.pathname}/>
            <div className="card styled-change-password p-3">
                <h6>Change Password</h6>
                <hr/>
                <PharmacySnackbar open={open} variant={variant} message={message} handleCloseBar={handleCloseSnackbar}/>
                <Form ref={passwordForm} onSubmit={handleSubmit}>
                    <Form.Row>
                        <Form.Group as={Col}>
                            <Form.Label>Email<span className="asterics">*</span></Form.Label>
                            <Form.Control type="email" name="email" value={state.email} onChange={handleChange}/>
                            {(submitted && !state.email) && <div className="invalid-text">Please enter your email address</div>}
                        </Form.Group>

                        <Form.Group as={Col}>
                            <Form.Label>Old Password<span className="asterics">*</span></Form.Label>
                            <Form.Control type="password" name="old_password" value={state.old_password} onChange={handleChange}/>
                            {(submitted && !state.old_password) && <div className="invalid-text">Please enter your old password</div>}
                        </Form.Group>
                    </Form.Row>

                    <Form.Row>
                        <Form.Group as={Col}>
                            <Form.Label>New Password<span className="asterics">*</span></Form.Label>
                            <Form.Control type="password" name="new_password" value={state.new_password} onChange={handleChange}/>
                            {(submitted && !state.new_password) && <div className="invalid-text">Please enter your new password</div>}
                        </Form.Group>
                        <Form.Group as={Col}>
                            <Form.Label>Re-Type Password<span className="asterics">*</span></Form.Label>
                            <Form.Control type="password" name="repassword" value={state.repassword} onChange={handleChange}/>
                            {(submitted && !state.repassword) ? <div className="invalid-text">Please re-type the password</div>:
                                (state.new_password !== state.repassword) && <div className="invalid-text">The two passwords don't match</div>}
                        </Form.Group>
                    </Form.Row>
                    <div className="text-center">
                    <button type="submit" disabled={!!submitting} className="btn pharmacy-btn px-4">{submitting ? "Submitting....":"Submit"}</button>
                    </div>
                </Form>
            </div>
        </div>

    )
};
function mapStateToProps(state) {
    return {
        snackbars:state.snackbars
    }

}
function mapDispatchToprops(dispatch) {
    return {
        actions:{
            snackbarActions:bindActionCreators(snackbarActions,dispatch)
        }
    }

}
export default connect(mapStateToProps,mapDispatchToprops)(ChangePassword);