import React, {useEffect, useState} from "react"
import axios from "axios";
import config from "../../../../Helpers/config.json";
import {errorMessages} from "../../../../Helpers/ErrorMessages";
import {history} from "../../../../Helpers/history";
import {convertDate, dateConvert} from "../../../../Utils/ConvertDate";

function useRecordSupplierPayment(actions, obj) {
    const {products,invoice_no, payable_id, pay_details} = obj
    const [state, setState] = useState({payment_date:dateConvert(), payment_amount:0, notes:'',invoice_no:''})
    const [paid_through, setPaidThrough] = useState('')
    const [payment_method, setPaymentMethod] = useState('')
    const [accounts, setAccounts] = useState([])
    const [payTypes, setPayTypes] = useState([])
    const [openDialog, setOpenDialog] = useState(false)
    const [submitted, setSubmitted] = useState(false)
    const [isSubmitted, setIsSubmitted] = useState('idle')
    const [receiptDialog, setReceiptDialog] = useState(false)
    const [openAccountsPayableDialog, setOpenAccountsPayableDialog] = useState(false);
    const [voucher_id,setVoucherId] = useState("")

    useEffect(() => {
        axios.get(`${config.epharmUrl}/accounting/ledgers_under_category`).then(res => {
            const data = res.data;
            const dt = data ? data : {};
            const arr = dt.data ? dt.data : []
            const list = arr.map(item => ({
                value: item.id,
                label: item.head_name
            }))
            setAccounts(list)
        }).catch(err => {
            errorMessages(err, null, actions)
        })
    }, [])


    // useEffect(() => {
    //     axios.get(`${config.epharmUrl}/Cinvoice/payment_types`).then(res => {
    //         const data = res.data;
    //         const dt = !data.data ? [] : data.data;
    //         const arr = dt.map(item=>({
    //             value:item.id,
    //             label:item.payment_type_name
    //         }))
    //         setPayTypes(arr);
    //     }).catch(error => {
    //         errorMessages(error, null, actions)
    //     })
    // }, [])

    const calculateSubTotal = (arr) => {
        return arr.reduce((a, item) => {
            return a + item.total_amount
        }, 0)
    }

    useEffect(()=>{
      
        if (products && products.length === 0 && pay_details){
            return
        }

        const details = pay_details ? pay_details : {}
        const balance = details.balance ? details.balance : 0

        const total_amount = calculateSubTotal(products)
        setState({...state, invoice_no, payment_amount: balance ? balance : total_amount})


    },[products, invoice_no])
 
    const handleChange = (event) =>{
        const {name, value} = event.target
        setState({...state,[name]:value})
    }

    const handleChangePaidThrough = (value) =>{
        setPaidThrough(value)
    }

    const handleChangePaymentMethod = (value) =>{
        setPaymentMethod(value)
    }

    const handleOpenDialog = () =>{
        setOpenDialog(true)
    }

    const handleCloseDialog = () =>{
        setOpenDialog(false)
    }

    const handleCloseAccountsDialog = () => {
        setOpenAccountsPayableDialog(false)
    };

    const handleClose = () => {
        setOpenDialog(false)
        setOpenAccountsPayableDialog(false)
    }
    const handleOpenReceiptDialog = () => {
        setReceiptDialog(true);
        setOpenAccountsPayableDialog(false)
    
    }

    const handleCloseReceiptDialog = () => {
        setReceiptDialog(false);
        setOpenDialog(false)
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        setSubmitted(true);
        const {payment_amount, payment_date}= state
        const credit_ledger = paid_through ? paid_through : {}
        if (payment_amount && payment_date && paid_through) {
            const data = {payable_id:payable_id,amount_paid:payment_amount,credit_ledger:credit_ledger.value ? credit_ledger.value:''}
            setIsSubmitted('pending')
            axios.post(`${config.epharmUrl}/Cmanufacturer/supplier_payables/payment`, data).then((response) => {
                const data = response.data ?? {};
                const dt = data.data ? data.data : {}
                const vou_id = dt.voucher_number ? dt.voucher_number : '';  //getting voucher_number instead of voucher_id
                actions.snackbarActions.successSnackbar('Payment made successfully')
                setIsSubmitted('resolved')
                setSubmitted(false)
                setVoucherId(vou_id)
                setOpenAccountsPayableDialog(true)
                setPaidThrough(null)
                // setState({payment_date: ''})
            }).catch(err => {
                errorMessages(err, null, actions)
                setIsSubmitted('rejected')
            })
        }
    }

    const handlePrint = () => {
        history.push(`/receipt_print`)
    };

    const isPending = isSubmitted === 'pending'
    const isResolved = isSubmitted === 'resolved'

    const paymentProps =  {state, payment_method, paid_through, payTypes, accounts,openDialog, handleCloseDialog, handleOpenDialog, openAccountsPayableDialog,
    handleChangePaymentMethod, handleChangePaidThrough, handleChange, submitted, isPending, handleSubmit, handleCloseAccountsDialog,handleClose,actions,
    handleCloseReceiptDialog,handleOpenReceiptDialog, receiptDialog, voucher_id, handlePrint}

    return {paymentProps,isResolved}
}
export {useRecordSupplierPayment}