import {useEffect, useState} from "react";
import axios from "axios";
import config from "../../../Helpers/config.json";
import {errorMessages} from "../../../Helpers/ErrorMessages";

export const useAddPaymentTypes = (actions) => {
    const voucherTypes = [{value:'receipt', label:'Receipt'},{value:'invoice', label:'Invoice'}]
    const [state, setState] = useState({payment_type: '', description:''});
    const [openDialog, setOpenDialog] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState('idle');
    const [debit_account, setDebitAccount] = useState('');
    const [paymentUpdate, setPaymentUpdate] = useState({})
    const [payment_id, setPaymentId] = useState('')
    const [debitAccounts, setDebitAccounts] = useState([])
    const [rows, setRows] = useState([{voucher_type:{value:'receipt', label:'Receipt'}}])
    const [openEdit, setOpenEdit] = useState(false)

    useEffect(() => {
        axios.get(`${config.epharmUrl}/accounting/ledgers_under_category`).then(response => {
            const data = response.data;
            const dt = data.data ? data.data :  [];
            const arr = dt.map(item=>({
                value:item.id,
                label:item.head_name
            }))
            setDebitAccounts(arr)
        }).catch(error => {
            errorMessages(error, null, actions)
        })
    }, [])

    useEffect(() => {
        if(!payment_id){
            return
        }
        const formData = new FormData()
        formData.append('payment_type_id',payment_id)
        axios.post(`${config.epharmUrl}/Cinvoice/payment_type`, formData).then(response => {
            const data = response.data;
            const dt = data.data ? data.data : {};
            setPaymentUpdate(dt)
        }).catch(error => {
            errorMessages(error, null, actions)
        })

    }, [payment_id])

    useEffect(() => {
        if(!payment_id){
            setState({payment_type: '', description: ''})
            setDebitAccount('')
            return
        }
        const {
            payment_type_name,
            description,id, debit_ledger
        } = paymentUpdate;
        setState({payment_type:payment_type_name, description})
        setDebitAccount({value:id, label:debit_ledger})
    }, [paymentUpdate, payment_id])



    const handleOpenDialog = (id='') => {
        setOpenDialog(true)
        setPaymentId(id)
    };

    const handleCloseDialog = () => {
        setOpenDialog(false)
    };

    const handleOpenEditDialog = (id='') => {
        setOpenEdit(true)
        setPaymentId(id)
    };

    const handleCloseEditDialog = () => {
        setOpenEdit(false)
    };

    const handleChangeAccount = (value, idx) =>{
        const arr = rows.map((item, index)=>{
            if(index === idx){
                return {...item, debit_ledger:value}
            }
            return item
        })
        setRows(arr)
    }

    const handleChangeVoucherType = (value, idx) =>{
        const arr = rows.map((item, index)=>{
            if(index === idx){
                return {...item, voucher_type:value}
            }
            return item
        })
        setRows(arr)
    }

    const handleChangeDebitAccount = (value) =>{
        setDebitAccount(value)

    }

    const handleChange = (event, idx) => {
        const {name, value} = event.target;
        const arr = rows.map((item, index)=>{
            if(index === idx){
                return {...item, [name]:value}
            }
            return item
        })
        setRows(arr)
    };

    const addRows = () =>{
        const row = {payment_type_name:'', description:'', debit_ledger:''}
        setRows([...rows, row])
    }

    const removeRow = (idx) =>{
        const arr = rows.filter((_, index)=>index !== idx)
        setRows(arr)
    }

    const handleSubmit = event => {
        event.preventDefault();
        const {payment_type, description} = state;
        setSubmitted(true)
        const arrRequired = rows.every(item=>item.payment_type_name && item.debit_ledger)
        if (arrRequired) {
            setIsSubmitted('pending')
            const url = payment_id ? 'payment_type_update' : 'payment_types'
            const arr = rows.map(item=>{
                const debit = item.debit_ledger ? item.debit_ledger : {}
                const voucher_type = item.voucher_type ? item.voucher_type : {}
                return {
                    ...item, debit_ledger:debit.value ? debit.value : '',
                    voucher_type:voucher_type.value ? voucher_type.value : '',
                    description: item.description ? item.description : ''
                }

            })
            const data = payment_id ? [{payment_type_name:payment_type, description, debit_ledger: debit_account.value ? debit_account.value : ''}]: arr
            axios.post(`${config.epharmUrl}/Cinvoice/${url}`, data)
                .then(() => {
                    setIsSubmitted('resolved')
                    setOpenDialog(false)
                    actions.snackbarActions.successSnackbar('Payment type added successfully')
                    const clearedRows = rows.map(item=>({...item,payment_type_name:'', description:'', debit_ledger:''}))
                    setRows(clearedRows)
                    setSubmitted(false)
                }).catch(error => {
                setIsSubmitted('rejected')
                errorMessages(error, null, actions)
            })
        }

    };
    const isPending = isSubmitted === 'pending'
    const isResolved = isSubmitted === 'resolved'
    const paymentProps = {state, openDialog, submitted,debit_account,debitAccounts,isPending,
         handleOpenDialog, handleCloseDialog,  handleChange, handleSubmit,  handleChangeAccount,
        handleChangeDebitAccount, rows, addRows, removeRow,openEdit, handleCloseEditDialog,
        handleOpenEditDialog, handleChangeVoucherType, voucherTypes
    }
    return {paymentProps,  isResolved}
}