import React from 'react';
import {ReusableSalesAndPurchaseHeader} from "./ReusableSalesAndPurchaseHeader";
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Label from "../../Utils/FormInputs/Label";
import {EnhancedTableHead, MuiPagination, MuiPaper} from "../../Utils/Tables/CustomTable";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import {convDate, dateStringConvert, titleCase} from "../../Utils/titleCaseFunction";
import {useMuiPagination} from "../../Containers/Dasboard/DashboardTables";
import {usePurchaseReport} from "./CustomHooks/usePurchaseReport";
import {ReusableSearchForm} from "./ReusableSearchForm";
import ReusableDashboardHeader from "../../Containers/Dasboard/ReusableDashboardHeader";
import {history} from "../../Helpers/history";
import {Container} from "../../Utils/styledComponents"
import {BackButton} from "../../Utils/FormInputs/BackButton";
import { AutoCompleteControlled } from '../../Utils/FormInputs/AutoCompleteInput';
import {useExcelReports} from "../Invoice/CustomHooks/useExcelReports";
import {bindActionCreators} from "redux";
import * as snackbarActions from "../../Redux/Actions/SnackbarActions/SnackbarActions";
import {connect} from "react-redux";
import {formatAmount} from "../../Utils/formatAmount";

const headData = [
    {id: 'pdate', numeric: false, disablePadding: false, label: 'Date'},
    {id: 'purchaseId', numeric: false, disablePadding: false, label: 'Invoice#'},
    {id: 'name', numeric: false, disablePadding: false, label: 'Supplier Name'},
    {id: 'product', numeric: false, disablePadding: false, label: 'Product Name'},
    {id: 'quantity', numeric: false, disablePadding: false, label: 'Quantity'},
    {id: 'price', numeric: false, disablePadding: false, label: 'Unit Cost'},
    {id: 'total', numeric: false, disablePadding: false, label: 'Total Amount'},
];


const AdvancedPurchaseReport = (props) => {
    const {actions} = props;
    const {
        supplier, suppliers, handleChangeSupplier, period, from_date, to_date, handleChangeDate,
        year, inputTypes, handleChangePeriod, handleChangeYear, purchaseReport, loading,
        purchase_amount, submitted, isSubmitted, handleSubmit, downloadPDF,
        today
    } = usePurchaseReport()


    const {page, rowsPerPage, handleChangeRowsPerPage, handleChangePage} = useMuiPagination();
    
    const periods = [
        {value: 'today', label: 'Today'},
        // {value: 'weekly', label: 'Weekly'},
        // {value: 'monthly', label: 'Monthly'},
        {value: 'custom', label: 'Custom'}]

    const currentObj = {file_name:'Purchase Report', url:'admin/todays_purchase_excel_report',
     params:{start_date:period.value === "today" ? today:from_date, end_date:period.value === "today" ? today:to_date, manufacturer_id:supplier.value ? supplier.value : ''}}

    const {exportExcel} = useExcelReports(actions, currentObj)
    return (
        <div className='journals'>
            <ReusableDashboardHeader component="Purchase Report" heading="Reports"
                                     subHeading="Purchase Report" link={history.location.pathname}/>
            <Container>
                <div className='mb-2'>
                    <BackButton text='Reports' to='/allreports'/>
                </div>
                <ReusableSalesAndPurchaseHeader downLoadPdf={downloadPDF} handleSubmit={handleSubmit}  exportExcel={exportExcel}
                                                 csvTitle='Purchase Report'>
                    <ReusableSearchForm handleChangePeriod={handleChangePeriod} period={period}
                                        inputType={inputTypes} from_date={from_date} year={year} to_date={to_date}
                                        handleChangeYear={handleChangeYear} handleChangeDate={handleChangeDate}
                                        isSubmitted={isSubmitted} submitted={submitted} periods={periods}/>
                </ReusableSalesAndPurchaseHeader>
                <MuiPaper>
                    <div className="p-4 ml-2">
                        <div className="row">
                            <div className="col-md-3">
                                <Form.Group as={Row}>
                                    <Label name='Supplier:' sm={2} style={{marginTop: '10px'}}/>
                                    <Col sm={8}>
                                        <AutoCompleteControlled testId="select_supplier" options={suppliers} handleChange={handleChangeSupplier} value_text='value' label_text='label'
                                          medicine_value={supplier} placeholder='Select Supplier...' />
                                    </Col>
                                </Form.Group>
                            </div>
                        </div>
                    </div>
                    {/*<EnhancedTableToolbar title=''/>*/}
                    <div className="sub-journal-bar" style={{marginLeft:'0'}}>
                                <h6 data-testid="running_balance">Total Purchase Amount : <strong>{formatAmount(purchase_amount)}</strong></h6>
                           
                        </div>
                    <TableContainer>
                        <Table
                            aria-labelledby="tableTitle"
                            size='medium'
                            aria-label="enhanced table"
                        >
                            <EnhancedTableHead headCells={headData}/>
                            {loading ? <TableBody><TableRow>
                                <TableCell colSpan="7" align="center"><img src="/images/listPreloader.gif"
                                                                           alt="loader"
                                                                           style={{width: '5%'}}/></TableCell>
                            </TableRow></TableBody> : purchaseReport.length > 0 ?
                                <TableBody>
                                    {purchaseReport.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        .map((d, index) => {
                                            return (
                                                <TableRow
                                                    hover
                                                    role="checkbox"
                                                    tabIndex={-1}
                                                    key={index}
                                                    className='mui-table-row'>
                                                    <TableCell>
                                                        <span>{dateStringConvert(d.purchase_date)}</span>
                                                    </TableCell>
                                                    <TableCell component="th" scope="row" padding="default"
                                                               className='mui-table-cell'>

                                                        <span>{d.chalan_no}</span>
                                                    </TableCell>
                                                    <TableCell>
                                                        <span>{titleCase(d.manufacturer_name)}</span>
                                                    </TableCell>
                                                    <TableCell>
                                                        <span>{d.product_name}</span>
                                                    </TableCell>
                                                    <TableCell>
                                                        <span>{d.quantity}</span>
                                                    </TableCell>
                                                    <TableCell>
                                                        <span>{d.manufacturer_price}</span>
                                                    </TableCell>
                                                    <TableCell>
                                                        <span>{d.total_amount}</span>
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })}

                                    <TableRow>
                                        <TableCell colSpan={6} align="right">Total Purchase Amount:</TableCell>
                                        <TableCell>{purchase_amount}</TableCell>
                                    </TableRow>
                                </TableBody>
                                : <TableBody><TableRow>
                                    <TableCell colSpan="7" align="center">No records found</TableCell>
                                </TableRow></TableBody>}
                        </Table>
                    </TableContainer>
                    <MuiPagination data={purchaseReport} rowsPerPage={rowsPerPage} page={page}
                                   handleChangeRowsPerPage={handleChangeRowsPerPage}
                                   handleChangePage={handleChangePage} rows={[10, 20, 30, 40, 50]} colSpan={6}/>
                </MuiPaper>
            </Container>

        </div>
    )
}
function mapStateToProps(state) {
    return {
        snackbars: state.snackbars
    }

}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            snackbarActions: bindActionCreators(snackbarActions, dispatch)
        }
    }

}
export default connect(mapStateToProps, mapDispatchToProps)(AdvancedPurchaseReport);