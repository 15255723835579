import React from "react";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import * as snackbarActions from "../../../Redux/Actions/SnackbarActions/SnackbarActions";
import PharmacySnackbar from "../../../Utils/Snackbars/SnackbarUtil";
import ReusableDashboardHeader from "../../../Containers/Dasboard/ReusableDashboardHeader";
import {useMuiPagination} from "../../../Containers/Dasboard/DashboardTables";
import {EnhancedTableHead, EnhancedTableToolbar, MuiPagination, MuiPaper} from "../../../Utils/Tables/CustomTable";
import TableContainer from "@material-ui/core/TableContainer";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Label from "../../../Utils/FormInputs/Label";
import TextField from "../../../Utils/FormInputs/TextField";
import {history} from "../../../Helpers/history";
import {formatAmount} from "../../../Utils/formatNumbers";
import {formatDateTime, formatDate} from "../../../Utils/ConvertDate";
import {BackButton} from "../../../Utils/FormInputs/BackButton";
import {useSummaries} from "./hooks/useSummaries";



const headData = [
    {id: 'date', numeric: false, disablePadding: false, label: 'Date'},
    {id: 'customer', numeric: false, disablePadding: false, label: 'Customer Name'},
    {id: 'transaction_type', numeric: false, disablePadding: false, label: 'Transaction Type'},
    {id: 'status', numeric: false, disablePadding: false, label: 'Status'},
    {id: 'total', numeric: false, disablePadding: false, label: 'Total'},
    {id: 'balance_due', numeric: false, disablePadding: false, label: 'Balance'},
];


const ReceivablesSummary = ({actions, snackbars}) => {
    const {page, rowsPerPage, handleChangeRowsPerPage, handleChangePage} = useMuiPagination();
    const {data, from_date, to_date, handleChangeDate, isLoading, isSuccess, isError, handleCloseBar,  handleSearch} = useSummaries(actions,'Cinvoice/receivables')
    const {arrData} = data
    const debtors = arrData.data ? arrData.data : []

    const emptyRows = rowsPerPage - Math.min(rowsPerPage, debtors.length - page * rowsPerPage);

    const {open, message, variant} = snackbars;
    return (
        <div className="journals">
            <ReusableDashboardHeader component="Receivables Summary" heading="Accounts Receivable"
                                     subHeading="Receivables Summary" link={history.location.pathname}/>

            <div className="general-ledger-header mb-3">
                <div className='mb-3'>
                    <BackButton text='Reports' to='/allreports'/>
                </div>
                <form onSubmit={handleSearch}>
                    <Form.Group as={Row} style={{marginBottom: '-1%'}}>
                        <Col sm={4}>
                            <Form.Group as={Row}>

                                <Label name='From' column sm={2} style={{textAlign: 'right'}}/>
                                <Col sm={10}>
                                    <TextField type='date' onChange={handleChangeDate}
                                               value={from_date} name='from_date'/>
                                </Col>
                            </Form.Group>
                        </Col>

                        <Col sm={4}>
                            <Form.Group as={Row}>
                                <Label name='To' column sm={2}/>
                                <Col sm={10}>
                                    <TextField type='date' value={to_date} onChange={handleChangeDate}
                                               name='to_date'/>
                                </Col>
                            </Form.Group>
                        </Col>
                        <Col sm={1}>
                            <button type='submit' className='btn btn-sm pharmacy-btn-outline'>Filter</button>
                        </Col>
                    </Form.Group>
                </form>
            </div>
            <PharmacySnackbar open={open} message={message} variant={variant}
                              handleCloseBar={handleCloseBar}/>
            <div className="mui-tables">
                <MuiPaper>
                    <EnhancedTableToolbar title=''/>
                    <TableContainer>
                        <Table
                            aria-labelledby="tableTitle"
                            size='medium'
                            aria-label="enhanced table"
                        >
                            <EnhancedTableHead headCells={headData}/>
                            <TableBody>
                                {isLoading ? <TableRow>
                                    <TableCell colSpan={headData.length} align="center"><img src="/images/listPreloader.gif"
                                                                                             alt="loader"
                                                                                             style={{width: '5%'}}/></TableCell>
                                </TableRow>:null}
                                {isSuccess ? debtors.length > 0 ?
                                    <>
                                        {debtors.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                            .map((d, index) => {
                                                return (
                                                    <TableRow
                                                        hover
                                                        role="checkbox"
                                                        tabIndex={-1}
                                                        key={d.id}
                                                        className='mui-table-row'>
                                                        <TableCell component="th" scope="row" padding="default"
                                                                   className='mui-table-cell'>
                                                            {d.date_added ? formatDateTime(d.date_added) : ''}
                                                        </TableCell>
                                                        {/*<TableCell>*/}
                                                        {/*    <Link*/}
                                                        {/*        to={{pathname: `/creditdetails/${d.id}`}}>*/}
                                                        {/*        {d.id}</Link>*/}
                                                        {/*</TableCell>*/}
                                                        <TableCell>
                                                            {/*<Link to={{pathname: `/creditdetails/${d.id}`}}>*/}
                                                            {d.customer_name}
                                                            {/*</Link>*/}
                                                        </TableCell>
                                                        <TableCell>
                                                            {d.expected_date ? formatDate(d.expected_date) : d.expected_date}
                                                        </TableCell>
                                                        <TableCell>
                                                            {d['Total Amount'] ? formatAmount(d['Total Amount']):d['Total Amount']}
                                                        </TableCell>
                                                        <TableCell/>
                                                        <TableCell/>
                                                    </TableRow>
                                                );
                                            })}
                                        {emptyRows > 0 && (
                                            <TableRow style={{ height: 43 * emptyRows }}>
                                                <TableCell colSpan={headData.length} />
                                            </TableRow>
                                        )}
                                    </>: <TableRow>
                                        <TableCell colSpan={headData.length} align="center">No records found</TableCell>
                                    </TableRow>:null}
                                {isError ? <TableRow>
                                    <TableCell colSpan={headData.length} align="center">The server did not return a valid response</TableCell>
                                </TableRow>:null}
                            </TableBody>

                        </Table>
                        <MuiPagination data={debtors} rowsPerPage={rowsPerPage} page={page}
                                       handleChangeRowsPerPage={handleChangeRowsPerPage}
                                       handleChangePage={handleChangePage} rows={[10, 20, 30, 40, 50]} colSpan={6}/>
                    </TableContainer>

                </MuiPaper>
            </div>
        </div>
    )


}

function mapStateToProps(state) {
    return {
        snackbars: state.snackbars
    }

}

function mapDispatchToProps(dispatch) {
    return {
        actions: {

            snackbarActions: bindActionCreators(snackbarActions, dispatch)
        }
    }

}

export default connect(mapStateToProps, mapDispatchToProps)(ReceivablesSummary);