import React, {useState} from 'react';
import CommonReturnList from "./CommonReturnList";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import axios from "axios";
import config from "../../Helpers/config";
import {errorMessages} from "../../Helpers/ErrorMessages";
import * as snackbarActions from "../../Redux/Actions/SnackbarActions/SnackbarActions"
import {useReturnList} from "./CustomHooks/useReturnList";
import PharmacySnackbar from "../../Utils/Snackbars/SnackbarUtil";
import DeleteDialog from "../../Utils/DeleteDialog";
import {useExcelReports} from "../Invoice/CustomHooks/useExcelReports";

const headData = [
    {id: 'invoice', numeric: false, disablePadding: false, label: 'Purchase#'},
    {
        id: 'name',
        numeric: false,
        disablePadding: false,
        label: 'Supplier Name'
    },
    {id: 'date', numeric: false, disablePadding: false, label: 'Date'},
    {id: 'quantity', numeric: false, disablePadding: false, label: 'Quantity'},
    {id: 'cost', numeric: false, disablePadding: false, label: 'Unit Cost'},
    {id: 'discount', numeric: false, disablePadding: false, label: 'Discount'},
    {id: 'total', numeric: false, disablePadding: false, label: 'Total Amount'},
    {id: 'action', numeric: false, disablePadding: false, label: 'Action'},
];
const ManufacturerReturnList = ({actions, snackbars, match:{params}}) => {
    const {component} = params;
    const {
        state, data, total_count, loading, search_value, activePage,
        handleChangeSearchValue, handleChange, handleSubmitSearch, handlePageChange, handleCloseSnackbar,
        setData
    } = useReturnList(actions, component)
    const [openDialog, setOpenDialog] = useState(false);
    const [purchase_id, setPurchaseId] = useState('')


    const handleOpenDialog = (_id) => {
        setOpenDialog(true);
        setPurchaseId(_id);
    };
    const handleCloseDialog = () => {
        setOpenDialog(false)
    };
    const handleDeleteReturn = () => {
        axios.post(`${config.epharmUrl}/Creturn/delete_retutn_purchase`, null, {params: {purchase_id}}).then(() => {
            const arr = data.filter(stock => stock.purchase_id !== purchase_id)
            actions.snackbarActions.successSnackbar('Successfully Deleted!');
            setData(arr)
            setOpenDialog(false)
        }).catch(error => {
            errorMessages(error, null, actions)
        })

    };

    const {open, variant, message} = snackbars;
    const arr = data.map(item => ({
        return_id: item.return_id,
        id: item.purchase_id,
        name_id: item.manufacturer_id,
        name: item.manufacturer_name,
        date: item.date_return,
        total_amount: item.total_ret_amount,
        url: 'manufacturerinv',
        quantity: item.ret_qty,
        rate: item.product_rate,
        discount: item.total_deduct
    }))
    const title = {
        supplier:'Supplier Return List',
        damages:'Damages',
        wastages:'Wastage'
    }
    const return_type ={
        supplier:0,
        damages:1,
        wastages:2
    }

    const file_name = {
        supplier:'Supplier Returns',
        damages:'Damage Report',
        wastages:'Wastage Report'
    }

    const currentObj = {file_name:file_name[component], url:'Creturn/supplier_return_excel_reports', params:{return_type:return_type[component]}}

    const {exportExcel} = useExcelReports(actions, currentObj)

    return (
        <CommonReturnList title={title[component]} handleOpenDialog={handleOpenDialog}
                          handlePageChange={handlePageChange} search_value={search_value} loading={loading} data={arr}
                          handleChange={handleChange} headData={headData} state={state} total_count={total_count}
                          activePage={activePage} exportExcel={exportExcel} 
                          handleChangeSearch={handleChangeSearchValue} handleSubmitSearch={handleSubmitSearch}>
            <PharmacySnackbar open={open} variant={variant} message={message}
                              handleCloseBar={handleCloseSnackbar}/>
            <DeleteDialog openDialog={openDialog} handleDelete={handleDeleteReturn} handleClose={handleCloseDialog}
                          message='return'/>
        </CommonReturnList>
    );

}

function mapStateToProps(state) {
    return {
        snackbars: state.snackbars
    }

}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            snackbarActions: bindActionCreators(snackbarActions, dispatch)
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ManufacturerReturnList);