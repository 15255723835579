import React from 'react';

export function titleCase(str) {
    if (str && str !== ''){
        var splitStr = str.toLowerCase().split(' ');
        for (var i = 0; i < splitStr.length; i++) {
            splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1).toLowerCase();
        }
        return splitStr.join(' ');
    }
    return '';
    // return str.charAt(0).toUpperCase() + str.substr(1).toLowerCase()
}

export const splitText = (txt) =>{
    const nt = txt ? txt : '';
    return nt.split('\n').map(text=><p style={{margin:0}}>{text}</p>)
}

export function convert(str) {
    let date = new Date(str);
    let curdate = date.getDate();
    curdate = curdate.toString().length < 2? `0${curdate}`:curdate.toString();
  return `${date.getFullYear() }-${date.getMonth() + 1}-${curdate}`
}

// takes in the format "day - month(in three letter words) - year"
export const dateHypenStringConver = (dt) => {
    const date_arr = dt.split(' - ');
    const day = date_arr[0];
    const month = date_arr[1];
    const year = date_arr[2];
    // create a new date object
    const new_date = new Date(`${month} ${day}, ${year}`);
    return new_date.toDateString();
}

export const convertMonth = (month) =>{
    const months = {'01':'January', '02':'February','03':'March','04':'April','05':'May',
        '06':'June', '07':'July','08':'August','09':'September','10':'October','11':'November','12':'December'};
    const mths = month.split('-')[1];
    const yr = month.split('-')[0];
    return `${months[mths]}, ${yr}`
}

export const convertDate = () =>{
    let today = new Date();
    return today.getFullYear() + '-' + ('0' + (today.getMonth() + 1)).slice(-2) + '-' + ('0' + today.getDate() ).slice(-2);
};

export const formatDate = (str) =>{
    let today = new Date(str);
    return today.getFullYear() + '-' + ('0' + (today.getMonth() + 1)).slice(-2) + '-' + ('0' + today.getDate() ).slice(-2);
};


export const addDays = (str,number_days) =>{
    let today = new Date(str);
    let lastWeek = new Date(today.getFullYear(), today.getMonth(), today.getDate() + number_days);
    return  lastWeek.getFullYear() + '-' + ('0' + (lastWeek.getMonth() + 1)).slice(-2) + '-' + ('0' + lastWeek.getDate()).slice(-2);
};

export const subtractDays = (str,number_days) =>{
    let today = new Date(str);
    let lastWeek = new Date(today.getFullYear(), today.getMonth(), today.getDate() - number_days);
    return  lastWeek.getFullYear() + '-' + ('0' + (lastWeek.getMonth() + 1)).slice(-2) + '-' + ('0' + lastWeek.getDate()).slice(-2);
};

export const convDate = (date) => {
    let today = new Date(date);
    return today.getFullYear() + '-' + ('0' + (today.getMonth() + 1)).slice(-2) + '-' + ('0' + today.getDate()).slice(-2)
}

export const dateStrokeConvert = (date) =>{
    let today = new Date(date);
    return ('0' + today.getDate()).slice(-2) + '/' + ('0' + (today.getMonth() + 1)).slice(-2) + '/' + today.getFullYear()
}

export const convertGmtTime = (date) =>{
    let  dt = new Date(date).toUTCString();
    dt = dt.split(' ').slice(0, 5).join(' ');
    return dt
}

export const removeTime = (date) =>{
    return date.split(' ')[0]
}

export const dateStringConvert = (date) => {
    let myDate = new Date(date);
    return myDate.toDateString();
}

export const dateStringTimeConvert = (date) => {
    let new_date = date.split('GMT').shift();
    let myDate = new Date(new_date);
    const time_now  = new Intl.DateTimeFormat('default', { 
        hour24: true,
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric'
    }).format(myDate);  
    return `${myDate.toDateString()}, ${time_now}`;
}