import  {useEffect, useState} from "react";
import axios from "axios";
import config from "../../../Helpers/config.json";
import {errorMessages} from "../../../Helpers/ErrorMessages";
import {useAddProvider} from "./useAddProvider";

export const useManageProvider = (actions) => {
    const [providers, setProviders] = useState([]);
    const [loading, setLoading] = useState('idle');
    const [searchValue, setSearchValue] = useState('');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const {providerProps, isResolved} = useAddProvider(actions)


    useEffect(() => {
        setLoading('loading')
        axios.get(`${config.epharmUrl}/Ccustomer/customer_category`).then(res => {
            const providers = res.data;
            const dt = !providers ? [] : providers;
            setProviders(dt);
            setLoading('success');
        }).catch(error => {
            errorMessages(error, null, actions)
            setLoading('error')
        })
    }, [isResolved])


    const handleSearch = (searchValue) => {
        setSearchValue(searchValue)
    };


    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };



    const handleCloseBar = () => {
        actions.snackbarActions.hideSnackBar();
    };




   const isLoading = loading === 'loading';
   const isSuccess = loading === 'success';
   const isError = loading === 'error'

    return { providers, loading, searchValue, handleSearch,   handleCloseBar,
        providerProps, isLoading, isSuccess, isError, page, rowsPerPage, handleChangePage,
        handleChangeRowsPerPage
       }
}