import React, {useEffect, useState} from 'react';
import axios from "axios";
import config from "../../../../Helpers/config.json";
import {errorMessages} from "../../../../Helpers/ErrorMessages";
import {titleCase} from "../../../../Utils/titleCaseFunction";
import {history} from "../../../../Helpers/history";


export const useSetupProducts = (actions) => {
    const [products, setProducts] = useState([]);
    const [rows, setRows] = useState([{id:'',name:'',isError:false,
        credit_account:'', debit_account:'', isOpen:false}])
    const [accounts, setAccounts] = useState([]);
    const [submitted, setSubmitted] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState('idle');


    useEffect(() => {
        axios.get(`${config.epharmUrl}/accounting/chart/journals`).then(res => {
            const data = res.data;
            const dt = data ? data : {};
            const arr = dt.data ? dt.data : []
            const list = arr.map(item => ({
                value: item.id,
                label: item.head_name
            }))
            setAccounts(list)
        }).catch(err => {
            errorMessages(err, null, actions)
        })
    }, [])


    const handleAddRow = () =>{
        const obj = {id:'',
        name:'',
        credit_account:'',
        debit_account:'',isOpen:false, isError:false}
        setRows([...rows, obj])
    }

    const handleRemoveRow = (index) =>{
        const arr = rows.filter((_,ind)=>ind !== index)
        setRows(arr)
    }


    const retrieveProducts = (event, idx) => {
        const productCopy = [...rows];
        if (productCopy[idx]) {
            productCopy[idx].name = event.target.value;
            if (event.target.value !== '') {
                axios.get(`${config.epharmUrl}/Cpurchase/product_search_by_manufacturer`, {params:{product_name:titleCase(event.target.value)}}).then(res => {
                    const resp = res.data;
                    const data = !resp ? [] : resp;
                    if (data[0] === "No Product Found") {
                        const val = [{value: "", label: "No Medicine Found"}]
                        setProducts(val)
                    } else {
                        setProducts(data);
                    }
                }).catch(error => {
                    errorMessages(error, null, actions)
                });
                productCopy[idx].isOpen = true;
                productCopy[idx].isError = false;
            } else {
                productCopy[idx].isError = false;
                productCopy[idx].isOpen = false;
                setProducts([]);
            }
        } else {
            productCopy[idx].isOpen = false;
        }
        setRows(productCopy)
    };

    const clickProduct = (event, idx) => {
        const productCopy = [...rows];
        const formData = new FormData();
        formData.append('product_id', event.value);
        if (productCopy[idx]) {
            productCopy[idx].name = event.label;
            productCopy[idx].id = event.value;
            productCopy[idx].isOpen = false;
        }
        setRows(productCopy)
    };


    const handleChangeDebitAccount = (val, index) => {
        const arr = rows.map((item, ind) => {
            if (ind === index) {
                return {...item, debit_account: val}
            }
            return item
        })
        setRows(arr)
    }

    const handleChangeCreditAccount = (val, index) => {
        const arr = rows.map((item, ind) => {
            if (ind === index) {
                return {...item, credit_account: val}
            }
            return item
        })
        setRows(arr)
    }



    const handleSubmit = (event) => {
        event.preventDefault();
        setSubmitted(true);
        const data = rows.map(item=>{
            const credit  = item.credit_account ? item.credit_account : {};
            const credit_value = credit.value ? credit.value : ''
            const debit  = item.debit_account ? item.debit_account : {};
            const debit_value = debit.value ? debit.value : '';
            return{
            entity_id: +item.id,
            credit_ledger:+credit_value,
            debit_ledger :+debit_value,
            entity_type:'product',
            // default:0
         }
        })
        setIsSubmitted('pending');
        axios.post(`${config.epharmUrl}/accounting/entity_behaviour/add`,
         data).then(() => {
            actions.snackbarActions.successSnackbar('Entry made successfully')
            setIsSubmitted('resolved')
            const arr = rows.map(item=>({
                ...item,
                id:'',name:'',isError:false,credit_account:'', debit_account:'', isOpen:false
            }))
            setRows(arr)
            setSubmitted(false)
            history.push('/ledgers/ledgerlist/supplier')
        }).catch(err => {
            errorMessages(err, null, actions)
            setIsSubmitted('rejected')
        })
     
    }

    const closeSnackbar = () =>{
        actions.snackbarActions.hideSnackBar()
    }


    const isPending = isSubmitted === 'pending'
    const isResolved = isSubmitted === 'resolved'
    const isRejected = isSubmitted === 'rejected'

    
    const productProps = {rows, accounts, submitted,
    handleChangeCreditAccount, handleChangeDebitAccount, handleSubmit,
    isPending, isRejected, isResolved, handleAddRow, handleRemoveRow,
        retrieveItems:retrieveProducts, clickItem: clickProduct,items:products, }

    return {productProps, closeSnackbar}
}