import React, {useCallback, useEffect, useState} from "react";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import {bindActionCreators} from "redux";
import * as snackbarActions from "../../Redux/Actions/SnackbarActions/SnackbarActions"
import {CustomPagination} from "../../Utils/Tables/CustomTable";
import {exportPDF} from "../../Utils/exportPDF";
import PharmacySnackbar from "../../Utils/Snackbars/SnackbarUtil";
import axios from "axios";
import config from "../../Helpers/config";
import {errorMessages} from "../../Helpers/ErrorMessages";
import {titleCase} from "../../Utils/titleCaseFunction";
import {EnhancedTableHead, EnhancedTableToolbar, MuiPaper} from "../../Utils/Tables/CustomTable";
import Form from "react-bootstrap/Form";
import ReusableDashboardHeader from "../../Containers/Dasboard/ReusableDashboardHeader";
import {history} from "../../Helpers/history";
//import {CSVLink} from "react-csv";
import {connect} from "react-redux";
import { useRef } from "react";
import {BackButton} from "../../Utils/FormInputs/BackButton";
import {Container} from "../../Utils/styledComponents"
import {Link} from "react-router-dom";
import {useExcelReports} from "../Invoice/CustomHooks/useExcelReports";
import {LoadingGif} from "../../Utils/Loader";


const headData = [
    {id: 'sl', numeric: false, disablePadding: false, label: 'SL'},
    {id: 'medicine_name', numeric: false, disablePadding: false, label: 'Product Name'},
    {id: 'generic_name', numeric: false, disablePadding: false, label: 'Generic Name'},
    {id: 'strength', numeric: false, disablePadding: false, label: 'Strength'},
    {id: 'reorderlevel', numeric: false, disablePadding: false, label: 'Reorder Level'},
    {id: 'stock', numeric: false, disablePadding: false, label: 'Stock'},
];


const OutOfStockReport = ({actions, snackbars}) => {
    const storage = JSON.parse(sessionStorage.getItem('total_count'))
    const item_count = storage ? storage : 0
    const count = () => +item_count || 0
    const [outOfStock, setOutOfStock] = useState([]);
    const [stockCount, setStockCount] = useState(count);
    const [activePage, setActivePage] = useState(1);
    const [searchValue, setSearchValue] = useState('');
    const [loading, setLoading] = useState('idle');



    useEffect(()=>{
        setLoading('loading')
        axios.get(`${config.epharmUrl}/Creport/checkStockOutList`, {params:{page_number:activePage,  search: searchValue}}).then(response => {
            const res = response.data;
            const dt = res ? res : {};
            const data = dt.data ? dt.data : {};
            const arr = data.products ? data.products : []
            const p_count = data.total_records ? data.total_records : 0;
            if(activePage === 1){
                sessionStorage.setItem('total_count', JSON.stringify(p_count))
                setStockCount(p_count);
            }else {
                setStockCount(count);
            }
            setOutOfStock(arr);
            setLoading('success');
        }).catch(error => {
            errorMessages(error, null, actions);
            setLoading('error')
        })
    }, [activePage, searchValue])



    const handleChange = (event) => {
        setSearchValue(event.target.value);
    };

    const handleCloseBar = () => {
        actions.snackbarActions.hideSnackBar()
    };


    const handlePageChange = (pageNumber) => {
        setActivePage(pageNumber)
    };





    const currentObj = {file_name:'Out_Of_Stock Report', url:'Creport/out_of_stock_excel_report'}

    const {exportExcel} = useExcelReports(actions, currentObj)


    const outOfStockList = !outOfStock ? [] : outOfStock
    const isLoading = loading === 'loading'
    const isSuccess = loading === 'success'
    const isError = loading === 'error'
    const {message, variant, open} = snackbars;
    return (
        <div>
            <ReusableDashboardHeader component='Out of Stock' heading="Stock"
                                     subHeading='Out of stock' link={history.location.pathname}/>
            <PharmacySnackbar open={open} message={message} variant={variant}
                              handleCloseBar={handleCloseBar}/>
            <Container>
                <div className='mb-2'>
                    <BackButton text='Reports' to='/allreports'/>
                </div>
            <div className='text-right m-3'>
               <Link to='/addpurchaseorder/outofstock'>
                   <button className='btn btn-sm pharmacy-info-btn'>Make Purchase Order</button>
               </Link>
            </div>
            <MuiPaper>
                <EnhancedTableToolbar title='Out of Stock'>
                             <span className="btn-group" style={{flex: '1 1 100%'}}>
                                 <span>
                                    <button className="btn btn-sm pharmacy-btn mr-1" onClick={exportExcel}>Excel</button>
                                </span>
                            </span>
                    <Form.Control type="text" value={searchValue ? titleCase(searchValue) : ''}
                                  onChange={handleChange}
                                  className='form__control'
                                  placeholder="Search" style={{float: 'right'}}/>
                </EnhancedTableToolbar>
                <Table
                    aria-labelledby="tableTitle"
                    // size='medium'
                    aria-label="enhanced table"
                >
                    <EnhancedTableHead headCells={headData}/>
                        <TableBody>
                            {isLoading ? <TableRow>
                                    <TableCell colSpan={headData.length} align="center">
                                        <LoadingGif/>
                                    </TableCell>
                                </TableRow> : null}
                            {isSuccess ? outOfStockList.length > 0 ?  outOfStockList.slice(0, 10).map((d, index) => {
                               const sl = ((activePage - 1) * 10) + index + 1;
                                return (
                                    <TableRow
                                        hover
                                        role="checkbox"
                                        tabIndex={-1}
                                        key={index}
                                        className='mui-table-row'>
                                        <TableCell component="th" scope="row" padding="default"
                                                   className='mui-table-cell'>
                                            {sl}
                                        </TableCell>
                                        <TableCell>
                                            {titleCase(d.product_name)}
                                        </TableCell>
                                        <TableCell>
                                            {titleCase(d.generic_name)}
                                        </TableCell>
                                        <TableCell>
                                            {d.strength}
                                        </TableCell>
                                        <TableCell>
                                            {d.reorder_level}
                                        </TableCell>
                                        <TableCell>
                                            {d.stock}
                                        </TableCell>
                                    </TableRow>
                                );
                            }) : <TableRow><TableCell align='center' colSpan={headData.length}>No records found</TableCell></TableRow>: null}
                            {isError ?<TableRow>
                                <TableCell colSpan={headData.length} align="center">The server did not return a valid response</TableCell>
                            </TableRow>: null}
                        </TableBody>
                </Table>
                <CustomPagination data={outOfStockList} records={10} activePage={activePage} total_count={stockCount}
                                  handleChangeNextPage={handlePageChange}/>
            </MuiPaper>
            </Container>
        </div>
    )


}

function mapStateToProps(state) {
    return {
        snackbars: state.snackbars
    }

}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            snackbarActions: bindActionCreators(snackbarActions, dispatch)
        }
    }

}

export default connect(mapStateToProps, mapDispatchToProps)(OutOfStockReport);