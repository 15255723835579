import React, {useEffect, useState} from 'react';
import axios from "axios";
import config from "../../../../Helpers/config.json";
import {errorMessages} from "../../../../Helpers/ErrorMessages";


export const useEditLedgers = (actions, component) => {
    const [debit, setDebit] = useState('')
    const [credit, setCredit] = useState('')
    const [submitted, setSubmitted] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState('idle');
    const [openEdit, setOpenEdit] = useState(false)
    const [editId, setEditId] = useState('')
    const [editData, setEditData] = useState({})
    const [credit_ledger, setLedger] = useState('')
    const [product, setProduct] = useState('')
    const [accounts, setAccounts] = useState([])



    useEffect(() => {
        axios.get(`${config.epharmUrl}/accounting/chart/journals`).then(res => {
            const data = res.data;
            const dt = data ? data : {};
            const arr = dt.data ? dt.data : []
            const list = arr.map(item => ({
                value: item.id,
                label: item.head_name
            }))
            setAccounts(list)
        }).catch(err => {
            errorMessages(err, null, actions)
        })
    }, [])


    useEffect(() => {
        if(!editId){
            return
        }
        const formData = new FormData()
        formData.append('id', editId)
        axios.post(`${config.epharmUrl}/accounting/entity_behaviour/entity_update_form`, formData).then(res => {
            const data = res.data;
            const dt = data ? data : {};
            const arr = dt.data ? dt.data : {}
            const ledger = arr.credit_ledger ? arr.credit_ledger : ''
            setEditData(arr)
            setLedger(ledger)
        }).catch(err => {
            errorMessages(err, null, actions)
        })
    }, [editId])

    useEffect(()=>{
        const {credit_ledger:credit,debit_ledger:debit, product_name,
            credit_ledger_id,debit_ledger_id} = editData;
        if(!credit_ledger){
            return
        }
        setCredit({value:credit_ledger_id, label:credit})
        setDebit({value:debit_ledger_id, label:debit})
        setProduct(product_name)

    },[credit_ledger])



    const handleChangeDebit = (val) => {
        setDebit(val)
    }

    const handleChangeCredit = (val) => {
        setCredit(val)
    }


    const handleOpenEdit = (id) =>{
        setOpenEdit(true)
        setEditId(id)
    }

    const handleCloseEdit = () =>{
        setOpenEdit(false)
    }
    const handleEditLedger = (event) => {
        event.preventDefault();
        setSubmitted(true);
        const credit_val  = credit ? credit: {};
        const credit_value = credit_val.value ? credit_val.value : ''
        const debit_val  = debit ? debit : {};
        const debit_value = debit_val.value ? debit_val.value : '';
        const formData = new FormData();
        formData.append('id',editId)
        formData.append('credit_ledger',credit_value)
        formData.append('debit_ledger',debit_value)
        formData.append('entity_type',component)
        setIsSubmitted('pending');
        axios.post(`${config.epharmUrl}/accounting/entity_behaviour/edit`,formData).then(() => {
            actions.snackbarActions.successSnackbar('Entry edit successfully')
            setIsSubmitted('resolved')
            // setCredit('')
            // setDebit('')
            setSubmitted(false)
            setOpenEdit(false)
        }).catch(err => {
            errorMessages(err, null, actions)
            setIsSubmitted('rejected')
        })
     
    }



    const isPendingEdit = isSubmitted === 'pending'
    const isResolvedEdit = isSubmitted === 'resolved'
    const isRejectedEdit = isSubmitted === 'rejected'

    const editProps = {editData, credit, debit,handleChangeCredit, handleChangeDebit,openEdit, handleCloseEdit, handleOpenEdit,
        handleEditLedger, isPendingEdit, isRejectedEdit, isResolvedEdit, submitted, value:product, accounts
    }


    return {editProps, isResolvedEdit}
}