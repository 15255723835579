import React, {useState, useEffect} from 'react';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Label from "../../../Utils/FormInputs/Label"
import axios from 'axios';
import config from '../../../Helpers/config.json';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as snackbarActions from '../../../Redux/Actions/SnackbarActions/SnackbarActions'
import PharmacySnackbar from "../../../Utils/Snackbars/SnackbarUtil";
import {errorMessages} from "../../../Helpers/ErrorMessages";
import { Container } from '../../../Utils/styledComponents';
import { ReusableRouteTabs } from '../../../Utils/Dialog/ReusableTabs';
import { history } from '../../../Helpers/history';
import {extractDate, getActualMonthValue, convDate} from '../../../Utils/ConvertDate';
import TextField from '../../../Utils/FormInputs/TextField';
import Calendar from "rc-year-calendar";
import styled from "@emotion/styled/macro";
import * as mq from "../../../styles/media-queries";
import ReusableDashboardHeader from "../../../Containers/Dasboard/ReusableDashboardHeader";

const StyledTextField = styled(TextField)({
    [mq.medium]:{
      width:'200px'
    },
    [mq.large]:{
        width:'200px'
    },
    [mq.small]:{
        width:'165px'
    },
    borderRadius:'0.7rem', 
});

const formatMonthYear = (date) => {
    return date.getFullYear() + "/" + getActualMonthValue(date.getMonth());
} 

const findEndFinancialDate = (date) => {

    const dateValue = new Date(date);
    let endd = new Date(dateValue.setMonth(dateValue.getMonth() + 12));
    endd = new Date(endd.setDate(endd.getDate() - 1));
    return endd;    
}

const AccountingPeriod = ({ actions, snackbars}) => {
    // const components = [
    //     {label: 'Accounting Period', path: '/accounting/setup'},
    //     {label: 'Suppliers', path: '/ledgers/setupsuppliers'},
    //     {label: 'Customers', path: '/ledgers/setupcustomers'},
    //     {label: 'Products', path: '/ledgers/setupproducts'},
    // ]

    
    const [submitted, setSubmitted] = useState(false)
    const [isSubmitted, setIsSubmitted] = useState('idle')

    const closeSnackbar = () => {
        actions.snackbarActions.hideSnackBar()
    }

    const {open, type, message, variant} = snackbars;

    const [start_date, setStartDate] = useState('');

    const [end_date, setEndDate] = useState('');

    const [acc_period_present, setAccPeriodPresent] = useState(false);
    
    const [dataSource, setStartDataSource] = useState([{color: '', id:0, startDate:'', endDate:''}]);

    const [accPeriodDataSource, setAccPeriodDataSource] = useState([{id:0, startDate:'', endDate:''}]);

    const isPending = (isSubmitted === 'pending') || (dataSource[0].startDate === '') 

    // check if there is an accounting period present
    useEffect(()=>{
        axios.get(`${config.smsUrl}/accounting/settings/status`).then(res=>{
            const data = res.data;
            const dt = data ?? {};
            const st = dt.message ?? ''
            setAccPeriodPresent(st)

            if (st === false) {
                setAccPeriodPresent(false);
            } else {
                // an accounting period is present
                setAccPeriodPresent(true);
                //set the start and end dates
                setStartDate(extractDate(st.start_date));
                setEndDate(extractDate(st.end_date));
            }
        }).catch(err=>console.log(err))
    },[])


    const handleSubmit = (event) => {
        event.preventDefault()
        const formData = new FormData();
        setSubmitted(true)

        // check call to be made whenever a submisison is done
        // one should not be able to make another call ? of the same kind?
        if (dataSource[0].startDate !== '') {
            formData.append('start_date', convDate(dataSource[0].startDate));
            formData.append('end_date',  convDate(dataSource[0].endDate));
            formData.append('year', new Date().getFullYear());
            formData.append('financial_year', `${formatMonthYear(dataSource[0].startDate)}-${formatMonthYear(dataSource[0].endDate)}`)

            setIsSubmitted('pending');

            axios.post(`${config.smsUrl}/accounting/accounting_period/add`, formData).then(res=> {
                const data = res.data;

                // what to do  with the returned response.
                // check if it is actually there.
                // disable the change of the start and end date
                // and onchage of the css line thingies on the calendar
                setAccPeriodPresent(data.data)
                
                const newDataSource = data.data ? dataSource : accPeriodDataSource;
                setAccPeriodDataSource(newDataSource)

                setIsSubmitted('resolved');
                setSubmitted(false);
            }).catch(err => {
                setIsSubmitted('rejected');
                errorMessages(err, null, actions)
            })
        }

    };

    const handleClickedDate = (event) => {

        const dateReceived = event.date;
        setStartDate(extractDate(dateReceived));

        const endddate = findEndFinancialDate(dateReceived);
        setEndDate(extractDate(endddate));

        let newPeriod = [{id:0, startDate:new Date(dateReceived), endDate:new Date(endddate)}];
        setStartDataSource(newPeriod)
    }

    return (
        <div>
            <div className='journals'>
                <ReusableDashboardHeader component="Accounting Period" heading="Accounting"
                                         subHeading="Accounting period" link="/accounting/setup"/>

            </div>
            <PharmacySnackbar message={message} open={open} handleCloseBar={closeSnackbar} variant={variant}/>
            <Container>

                {/*<ReusableRouteTabs value={history.location.pathname} tabHeading={components}/>*/}

                {acc_period_present ? 
                    <h5 style={{paddingLeft:"15px",paddingTop:"10px", color:'black'}}>Your accounting period is shown below</h5>
                : null}

                <form autoComplete='off' onSubmit={handleSubmit} className="form-responsive">
                    {/*<div className='row pt-3'>*/}
                    <Form.Group as={Col} >
                        <Label name='Accounting period'/>
                        <StyledTextField type='text' name='accounting_period'  value="12 months" disabled/> 
                    </Form.Group>
                    <Form.Group as={Col} >
                        <Label name='Start date'/>
                        <StyledTextField type='text' name='start_date'  value={start_date} disabled/> 
                    </Form.Group>
                    <Form.Group as={Col}>
                        <Label name='End date'/>
                        <StyledTextField type='text'  name='end_date' value={end_date} disabled/>
                    </Form.Group>
                    {!acc_period_present ?
                        <Form.Group as={Col}>
                            <button disabled={isPending}  type='submit' className='btn btn-sm pharmacy-info-btn'>Submit</button>
                        </Form.Group>
                        :
                        null
                    }
                    {/*</div>*/}
                  
                </form>    

                {!acc_period_present && isPending ?
                    <h5 style={{paddingLeft:"15px",paddingTop:"10px", color:'red'}}>Please select the start date for your accounting period</h5>
                    : !acc_period_present && !isPending ?
                    <h5 style={{paddingLeft:"15px",paddingTop:"10px", color:'grey'}}>Please select the start date for your accounting period</h5>
                    : null
                }

                {!acc_period_present ?
                    <div>
                        <Row>
                            <Col>
                                <Calendar displayHeader={true} dataSource={dataSource} onDayClick={(event) => handleClickedDate(event)}/>
                            </Col>             
                        </Row>    
                    </div>
                    :  null
                }

                </Container>

        </div>
    )
}

function mapStateToProps(state) {
    const {snackbars} = state;
    return {snackbars}
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            snackbarActions: bindActionCreators(snackbarActions, dispatch)
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AccountingPeriod);