import React, {useEffect, useState} from 'react';
import axios from 'axios';
import config from '../../../../Helpers/config.json'
import {errorMessages} from "../../../../Helpers/ErrorMessages";
import {useNewCOAccount} from "../../COA/hooks/useNewCOAccount";
import {dateConvert} from "../../../../Utils/ConvertDate";

export const useNewExpenseItem = (actions) => {
    const [openDialog, setOpenDialog] = useState(false);
    const [ledgers, setLedgers] = useState([]);
    const [creditLedgers, setCreditLedgers] = useState([]);
    const [debitLedger, setDebitLedger] = useState(null);
    const [creditLedger, setCreditLedger] = useState(null);
    const [expenseItem, setExpenseItem] = useState('');
    const [submitted, setSubmitted] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState('idle');
    const [openEdit, setOpenEdit] = useState(false)
    const [id, setId] = useState('')
    const [editObj, setEditObj] = useState({})
    const balance_date = dateConvert()

    const { openDialog:openLedger, handleOpenDialog:handleOpenLedger, handleCloseDialog:handleCloseLedger,
         coaProps} = useNewCOAccount(actions, balance_date)

    // const {isResolved:isLedgerResolved} = coaProps;

    useEffect(() => {
        axios.get(`${config.epharmUrl}/accounting/ledgers_under_category`).then(res => {
            const data = res.data;
            const dt = data ? data : {};
            const arr = dt.data ? dt.data : []
            const list = arr.map(item => ({
                value: item.id,
                label: item.head_name
            }))
            setCreditLedgers(list)
        }).catch(err => {
            errorMessages(err, null, actions)
        })
    }, [])


    useEffect(() => {
        (async () => {
            try {
                const res = await axios.get(`${config.epharmUrl}/accounting/chart/journals`)
                let _data = res.data
                let dt = _data.data ?? [];
                let arr = dt.map(i => ({label: i.head_name, value: i.id}))
                setLedgers(arr)
            } catch (e) {
                // errorMessages(e, null, actions)
            }
        })();
    }, [])


    useEffect(() => {
        if(!id){
            return
        }
        (async () => {
            const formData = new FormData()
            formData.append('expense_id', id)
            try {
                const res = await axios.post(`${config.epharmUrl}/accounting/single_expense_type`,formData)
                let _data = res.data
                let dt = _data.data ?? {};
                setEditObj(dt)
            } catch (e) {
                // errorMessages(e, null, actions)
            }
        })();
    }, [id])

    useEffect(()=>{
        if(!id){
            return
        }
        setExpenseItem(editObj?.expense_type_name)
        setDebitLedger({value:editObj?.debit_ledger, label:editObj?.debit_ledger_name})
        setCreditLedger({value:editObj?.credit_ledger, label:editObj?.credit_ledger_name})

    },[id, editObj])



    const handleChangeExpenseItem = (event) => {
        const {value} = event.target
        setExpenseItem(value)
    }

    const handleChangeDebitLedger = (value) => {
        setDebitLedger(value)
    }

    const handleChangeCreditLedger = (value) => {
        setCreditLedger(value)
    }



    const handleOpenDialog = () =>{
        setOpenDialog(true)
    }

    const handleCloseDialog = () =>{
        setOpenDialog(false)
    }

    const handleOpenEdit = (_id) =>{
        setOpenEdit(true)
        setId(_id)
    }

    const handleCloseEdit = () =>{
        setOpenEdit(false)
    }

    const submitNewExpense = (event) =>{
        event.preventDefault();
        const formData = new FormData()
        const debit = debitLedger ? debitLedger : {}
        const credit = creditLedger ? creditLedger : {}
        if(id){
            formData.append('expense_id', id)
        }
        formData.append('expense_type_name', expenseItem)
       // formData.append('debit_ledger', debit.value ? debit.value : '')
       // formData.append('credit_ledger', credit.value ? credit.value : '')
        setSubmitted(true);
        if (expenseItem) { // && debit?.value && credit?.value) {
            const url = id ? 'update' : 'add'
            setIsSubmitted('pending')
            axios.post(`${config.epharmUrl}/accounting/expenses_types/${url}`, formData).then(() => {
                actions.snackbarActions.successSnackbar(`Expense item ${ id ? 'edit' : 'added'} successfully`)
                setOpenDialog(false)
                setIsSubmitted('resolved')
                setExpenseItem(null)
                setDebitLedger(null)
                setCreditLedger(null)
                setSubmitted(false)
                setOpenEdit(false)
            }).catch(err => {
                errorMessages(err, null, actions);
                setIsSubmitted('rejected')
            })
        }

    }

    const isPending = isSubmitted === 'pending'
    const isResolved = isSubmitted === 'resolved'
    const isRejected = isSubmitted === 'rejected'

    const ledgerProps = {...coaProps, openLedger, handleCloseLedger, handleOpenLedger}

    const itemProps = {openDialog, debitLedger, ledgers, creditLedger,  expenseItem, submitted,creditLedgers,
        isPending, isRejected, isResolved,openEdit, handleChangeExpenseItem, handleChangeCreditLedger, handleChangeDebitLedger,
        handleOpenDialog, handleCloseDialog, submitNewExpense,  handleCloseEdit, handleOpenEdit, editObj, ledgerProps
    }

    return {itemProps}
}
