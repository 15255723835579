import React, {useEffect, useRef, useState} from "react";
import axios from "axios";
import config from "../../../Helpers/config.json";
import {errorMessages} from "../../../Helpers/ErrorMessages";
import {history} from "../../../Helpers/history";

export const useAddManufacturer = (actions) => {
    const storage = JSON.parse(sessionStorage.getItem("suppliers"))
    const item = storage ? storage : []
    const suppliers = ()=> item  || [];

    const showForm = suppliers().length <= 0
    const [id, setId] = useState('')
    const [state, setState] = useState({
        manufacturer_name: '',
        mobile: '',
        address: '',
        details: '',
        previous_balance: '',
    });
    const [manufacturer, setManufacturer] = useState({})
    const [submitted, setSubmitted] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState('idle');
    const [openDialog, setOpenDialog] = useState(false)
    const [openEdit, setOpenEdit] = useState(false)
    const [addedSuppliers, setAddedSuppliers] = useState(suppliers())
    const [isShowForm, setIsShow] = useState(showForm)
    const [isBlocking, setIsBlocking] = useState(false)
    const form = useRef();


    useEffect(() => {
        if (!id) {
            return;
        }
        axios.get(`${config.epharmUrl}/Cmanufacturer/manufacturer_update_form`,
            {params: {manufacturer_id: id}}).then(response => {
            const data = response?.data;
            setManufacturer(data)
        }).catch(error => {
            // errorMessages(error, null, actions)
        })
    }, [id])

    useEffect(() => {
        const {manufacturer_name, mobile, address, details, previous_balance} = manufacturer;
        setState({manufacturer_name, mobile, address, details, previous_balance});
    }, [manufacturer])


    const handleOpenDialog = () => {
        setOpenDialog(true)
    };

    const handleCloseDialog = () => {
        setOpenDialog(false)
    };

    const handleOpenEdit = (_id) => {
        setId(_id)
        setOpenEdit(true)

    };

    const handleCloseEdit = () => {
        setOpenEdit(false)
    };


    const handleChange = (event) => {
        const {name, value} = event.target;
        setState({...state, [name]: value})
        setIsBlocking(true)
    }

    const addAnotherSupplier = () =>{
        setIsShow(true)
    }

    const cancelAdd = () =>{
        const show = addedSuppliers.length <= 0
        setIsShow(show)
    }

    const addSupplier = () =>{
        const {manufacturer_name, mobile, address, details} = state;
        setSubmitted(true)
        if (manufacturer_name && mobile) {
            const obj =   {manufacturer_name,
                address : address ?? '', mobile, details : details ?? ''}
            const arr = [...item, obj]
            sessionStorage.setItem('suppliers', JSON.stringify(arr))
            setAddedSuppliers(arr)
            setIsShow(false)
            setSubmitted(false)
            setState({
                manufacturer_name: '',
                mobile: '',
                address: '',
                details: '',
                previous_balance: '',
            });
        }
        setIsBlocking(true)
    }

    const handleRemoveSupplier = (idx) =>{
        const arr = addedSuppliers.filter((_, index)=>idx !== index)
        sessionStorage.setItem('suppliers', JSON.stringify(arr))
        setAddedSuppliers(arr)
        if(arr.length === 0){
            setIsShow(true)
        }
        setIsBlocking(true)
    }

    const submitData = (jsonData, isMultiple=false) =>{
        const url = id ? 'manufacturer_update' : 'insert_manufacturer'
        setIsSubmitted('pending')
        setIsBlocking(false)
        axios.post(`${config.epharmUrl}/Cmanufacturer/${url}`, jsonData)
            .then(() => {
                setIsSubmitted('resolved')
                setSubmitted(false);
                actions.snackbarActions.successSnackbar(`Supplier ${id ? 'updated':'saved'} successfully!`);
                setState({
                    manufacturer_name: '',
                    mobile: '',
                    address: '',
                    details: '',
                });
                if(isMultiple){
                    history.push('/managemanufacturer')
                }
                sessionStorage.removeItem('suppliers')
                setOpenEdit(false)
                setOpenDialog(false)
            }).catch(error => {
            setIsSubmitted('rejected')
            errorMessages(error, null, actions)
        })
    }



    const handleSubmit = event => {
        event.preventDefault();
        const {manufacturer_name, mobile, address, details} = state;
        setSubmitted(true);
        if (manufacturer_name && mobile ) {
            const updateData = {manufacturer_id:id, oldname:manufacturer_name ?? ''}
            const jsonData =id ? {  manufacturer_name,
                    address : address ?? '',
                    mobile,
                    details : details ?? '',
                    ...updateData} : [{  manufacturer_name,
                address : address ?? '',
                mobile,
                details : details ?? '',
                }] 
            submitData(jsonData)
        }
    };

    const handleSubmitMultiple = event => {
        event.preventDefault();
        submitData(addedSuppliers,true)
    };

    const handleCloseBar = () => {
        actions.snackbarActions.hideSnackBar();
    };



    const isPending = isSubmitted === 'pending'
    const isResolved = isSubmitted === 'resolved'


    const manufacturerProps = {state, submitted,handleSubmit, handleChange, isPending,form,
        openDialog, handleOpenDialog, openEdit, handleOpenEdit, handleCloseDialog, handleCloseEdit}

    return {manufacturerProps, isResolved, handleCloseBar, addedSuppliers, showForm, cancelAdd, addSupplier, handleRemoveSupplier,
    handleSubmitMultiple, isShowForm, addAnotherSupplier, isBlocking}
}