import React, { useEffect,  useState} from "react";
import {Link} from "react-router-dom";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {CustomPagination} from "../../Utils/Tables/CustomTable";
import {exportPDF} from "../../Utils/exportPDF";
import * as snackbarActions from "../../Redux/Actions/SnackbarActions/SnackbarActions"
import axios from "axios";
import config from "../../Helpers/config";
import {errorMessages} from "../../Helpers/ErrorMessages";
import {titleCase, removeTime} from "../../Utils/titleCaseFunction";
import ReusableDashboardHeader from "../../Containers/Dasboard/ReusableDashboardHeader";
import {history} from "../../Helpers/history";
import {ReusableRouteTabs} from "../../Utils/Tabs/ReusableTabs";
import {EnhancedTableHead, EnhancedTableToolbar, MuiPaper} from "../../Utils/Tables/CustomTable";
import Form from "react-bootstrap/Form";
import PharmacySnackbar from "../../Utils/Snackbars/SnackbarUtil";
import styled from "@emotion/styled/macro";
import {useExcelReports} from "../Invoice/CustomHooks/useExcelReports";




const headData = [
    {id: 'sl', numeric: false, disablePadding: false, label: 'Sl'},
    {id: 'medicineName', numeric: false, disablePadding: false, label: 'Product Name'},
    {id: 'strength', numeric: false, disablePadding: false, label: 'Strength'},
    {id: 'bactchId', numeric: false, disablePadding: false, label: 'Batch ID'},
    {id: 'expiryDate', numeric: false, disablePadding: false, label: 'Expiry Date'},
    {id: 'inQuantity', numeric: false, disablePadding: false, label: 'In Quantity'},
    {id: 'soldQuantity', numeric: false, disablePadding: false, label: 'Sold Quantity'},
    {id: 'stock', numeric: false, disablePadding: false, label: 'Stock'},
];


const StockBatchWise = ({actions, snackbars}) => {
    const [stockBatch, setStockBatch] = useState({data:[], stockCount:0, loading:'idle', total_stock_value:0});
    const [activePage, setActivePage] = useState(1);
    const [searchValue, setSearchValue] = useState('');

    useEffect(() => {
        const formData = new FormData();
        formData.append("draw", 1);
        formData.append("columns[0][data]", "sl");
        formData.append("columns[0][name]", "");
        formData.append("columns[0][searchable]", "true");
        formData.append("columns[0][orderable]", false);
        formData.append("columns[0][search][value]", "");
        formData.append("columns[0][search][regex]", false);
        formData.append("columns[1][data]", "product_name");
        formData.append("columns[1][name]", "");
        formData.append("columns[1][searchable]", true);
        formData.append("columns[1][orderable]", true);
        formData.append("columns[1][search][value]", "");
        formData.append('columns[1][search][regex]', false);
        formData.append("columns[2][data]", "strength");
        formData.append("columns[2][name]", "");
        formData.append("columns[2][searchable]", true);
        formData.append("columns[2][orderable]", false);
        formData.append("columns[2][search][value]", "");
        formData.append("columns[2][search][regex]", false);
        formData.append("columns[3][data]", "batch_id");
        formData.append('columns[3][name]', "");
        formData.append("columns[3][searchable]", true);
        formData.append("columns[3][orderable]", false);
        formData.append("columns[3][search][value]", "");
        formData.append("columns[3][search][regex]", false);
        formData.append("columns[4][data]", "expeire_date");
        formData.append("columns[4][name]", "");
        formData.append("columns[4][searchable]", true);
        formData.append("columns[4][orderable]", false);
        formData.append("columns[4][search][value]", "");
        formData.append("columns[4][search][regex]", false);
        formData.append("columns[5][data]", "inqty");
        formData.append("columns[5][name]", "");
        formData.append("columns[5][searchable]", true);
        formData.append("columns[5][orderable]", false);
        formData.append("columns[5][search][value]", "");
        formData.append("columns[5][search][regex]", false);
        formData.append("columns[6][data]", "outqty");
        formData.append("columns[6][name]", "");
        formData.append("columns[6][searchable]", true);
        formData.append("columns[6][orderable]", false);
        formData.append("columns[6][search][value]", "");
        formData.append("columns[6][search][regex]", false);
        formData.append("columns[7][data]", "stock");
        formData.append("columns[7][name]", "");
        formData.append("columns[7][searchable]", true);
        formData.append("columns[7][orderable]", false);
        formData.append("columns[7][search][value]", "");
        formData.append("columns[7][search][regex]", false);
        formData.append("order[0][column]", 1);
        formData.append("order[0][dir]", "asc");
        formData.append("start", activePage);
        formData.append("length", 10);
        formData.append("search[value]", searchValue);
        formData.append("search[regex]", false);
        setStockBatch({...stockBatch, loading: 'loading'})
        axios.post(`${config.epharmUrl}/Creport/checkbatchstock`, formData).then(res => {
            const data = res.data;
            const dt = data ?? {};
            const arr = dt.aaData ?? [];
            const stock_count = dt.iTotalRecords ?? 0;
            const stock_value = dt.total_stock_value ?? 0;
            setStockBatch({...stockBatch, stockCount: stock_count, data: arr, loading: 'success', total_stock_value: stock_value})
        }).catch(error => {
            errorMessages(error, null, actions)
            setStockBatch({...stockBatch,loading: 'error'})
        })
    }, [activePage, searchValue])

    const handleChange = (event) => {
        setSearchValue(event.target.value);
    };

    // const exportExcel = () => {
    //     axios.get(`${config.epharmUrl}/Creport/stock_batch_wise_report`, {params:{download:true},responseType: 'blob'}).then(response=>{
    //         const url = window.URL.createObjectURL(new Blob([response.data]));
    //         const link = document.createElement('a');
    //         link.href = url;
    //         link.setAttribute('download', 'Stock Batchwise Report.xlsx');
    //         document.body.appendChild(link);
    //         link.click();
    //     }).catch(err=>{
    //         errorMessages(err, null,actions)

    //     })
    // }

    const downloadPDF = () => {
        const {data} = stockBatch
        const batchStock = !data ? [] : data;
        exportPDF("Stock Report(Batch Wise)", [["SL", "Medicine Name", "Strength",
                "Batch ID", "Expiry Date", "InQuantity", "Sold Quantity",
                "Stock"]],
            batchStock.map((stock, index) => [index += 1, stock.product_name, stock.strength,
                stock.batch_id, stock.expeire_date, stock.inqty, stock.outqty,
                stock.Stock]), "Stock Report.pdf");
    };

    const handleCloseBar = () => {
        actions.snackbarActions.hideSnackBar()
    };
    

    const handlePageChange = (pageNumber) => {
        setActivePage(pageNumber)
    };

    const currentObj = {file_name:'Stock Batchwise', url:'Creport/stock_batch_wise_report'}

    const {exportExcel} = useExcelReports(actions, currentObj)


    const {data, stockCount, total_stock_value, loading} = stockBatch

    const isLoading = loading === 'loading'
    const isSuccess = loading === 'success'
    const isError = loading === 'error'


    const batchStock = !data ? [] : data;
    const {open, message, variant} = snackbars;
    const components = [{label: 'Stock Report', path: '/stockreport'},
        {label: 'Stock Report(Batch Wise)', path: '/stockbatchwise'}]
    return (
        <div className='journals'>
            <ReusableDashboardHeader component='Stock Report(Batch Wise)' heading="Stock"
                                     subHeading='Stock Report(Batch Wise)' link={history.location.pathname}/>
            <ReusableRouteTabs value={history.location.pathname} tabHeading={components}/>
            <PharmacySnackbar open={open} message={message} variant={variant}
                              handleCloseBar={handleCloseBar}/>
            <div className="mt-4">
                {/*<Container>*/}
                {/*    <RightAligned>*/}
                {/*        <Paragraph>Stock Value : <strong>{formatAmount(total_stock_value)}</strong></Paragraph>*/}
                {/*    </RightAligned>*/}
                {/*</Container>*/}
                <MuiPaper>
                    <EnhancedTableToolbar title=''>
                             <span className="btn-group" style={{flex: '1 1 100%'}}>
                            <span>
                                <button onClick={exportExcel} className="btn btn-sm pharmacy-btn mr-1">Excel</button>
                            </span>

                        {/*<span><button className="btn btn-sm pharmacy-btn mr-1"*/}
                        {/*              onClick={downloadPDF}>Pdf</button></span>*/}
                            </span>
                        <Form.Control type="text" value={searchValue ? titleCase(searchValue) : ''}
                                      onChange={handleChange}
                                      className='form__control'
                                      placeholder="Search" style={{float: 'right'}}/>
                    </EnhancedTableToolbar>
                    <Table
                        aria-labelledby="tableTitle"
                        size='medium'
                        aria-label="enhanced table"
                    >
                        <EnhancedTableHead headCells={headData}/>
                            <TableBody>
                                {isLoading ? <TableRow>
                                        <TableCell colSpan={headData.length} align="center"><img src="/images/listPreloader.gif"
                                                                                                 alt="loader"
                                                                                                 style={{width: '5%'}}/></TableCell>
                                    </TableRow>:null}
                                {isSuccess ? batchStock.length > 0 ? batchStock.slice(0, 10)
                                    .map((d, index) => {
                                        return (
                                            <TableRow
                                            hover
                                            role="checkbox"
                                            tabIndex={-1}
                                            key={index}
                                            className='mui-table-row'>
                                            <TableCell component="th" scope="row" padding="default"
                                                       className='mui-table-cell'>
                                                <span>{d.sl}</span>
                                            </TableCell>
                                            <TableCell>
                                                <span data-testid={"product_name-"+index}><Link
                                                    to={{pathname: `/medicinedetails/${d.product_id}`}}
                                                    style={{color: "#09905c"}}>{titleCase(d.product_name)}</Link></span>
                                            </TableCell>
                                            <TableCell>
                                                <span data-testid={"strength-"+index}>{d.strength}</span>
                                            </TableCell>
                                            <TableCell>
                                                <span data-testid={"batch_id-"+index}>{d.batch_id}</span>
                                            </TableCell>
                                            <TableCell>
                                                <span data-testid={"expiry_date-"+index}>{d.expeire_date ? removeTime(d.expeire_date) : ''}</span>
                                            </TableCell>
                                            <TableCell>
                                                <span data-testid={"inqty-"+index}>{d.inqty}</span>
                                            </TableCell>
                                            <TableCell>
                                                <span data-testid={"outqty-"+index}>{d.outqty}</span>
                                            </TableCell>
                                            <TableCell>
                                                <span data-testid={"stock-"+index}>{d.stock}</span>
                                            </TableCell>
                                        </TableRow>
                                        );
                                    }): <TableRow>
                                    <TableCell colSpan={headData.length} align="center">No records found</TableCell>
                                </TableRow>:null}
                                {isError ? <TableRow>
                                    <TableCell colSpan={headData.length} align="center">The server did not return a valid response</TableCell>
                                </TableRow> : null}
                            </TableBody>
                    </Table>
                    <CustomPagination data={batchStock} records={10} activePage={activePage} total_count={stockCount}
                                      handleChangeNextPage={handlePageChange}/>
                </MuiPaper>
            </div>
        </div>
    )


}

function mapStateToProps(state) {
    return {
        snackbars: state.snackbars
    }

}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            snackbarActions: bindActionCreators(snackbarActions, dispatch)
        }
    }

}

export default connect(mapStateToProps, mapDispatchToProps)(StockBatchWise);