import React, {useEffect, useState} from 'react';
import axios from 'axios';
import config from '../../../../Helpers/config.json'
import {errorMessages} from "../../../../Helpers/ErrorMessages";
import {convDate} from "../../../../Utils/ConvertDate";
import {useRecordExpenses} from "./useRecordExpense";


export const useExpenses = (actions, component) => {
    const [expenses, setExpenses] = useState([]);
    const [state, setState] = useState({start_date: '', end_date: ''});
    const [loading, setLoading] = useState('idle');
    const [submitted, setSubmitted] = useState(false)
    const [isSubmitted, setIsSubmitted] = useState('idle')
    const [openCancel, setOpenCancel] = useState(false)
    const [canceledExpense, setCanceledExpense] = useState({})
    const [activePage, setActivePage] = useState(1);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [searchValue, setSearchValue] = useState('')
    const [total_count, setTotalCount] = useState(0)

    const {expenseProps} = useRecordExpenses(actions)
    const {isResolved:isExpenseResolved} = expenseProps

    const isCanceled = isSubmitted === 'resolvedCancel'




    useEffect(() => {
        (async () => {
            setLoading('pending')
            try {
                const status = {
                    'active':1,
                    'canceled': 9
                }
                const res = await axios.get(`${config.epharmUrl}/accounting/expenses`, {params:{status:status[component], page:activePage, search:searchValue}})
                let _data = res.data
                let dt = _data.data ?? {};
                const expensesArr = dt.results ? dt.results : []
                const t_count = dt.total_count
                const arr = expensesArr.map(({date,receipt_no, expense_type_name, amount,expense_id, debit_ledger_name, credit_ledger_name, ...rest})=>({
                    ...rest,
                    date:convDate(date),  debit_ledger_name, credit_ledger_name,
                    receipt_no,expense_type_name,amount,expense_id,
                }))
                setExpenses(arr)
                setTotalCount(t_count)
                setLoading('success')
            } catch (e) {
                // errorMessages(e, null, actions)
                setLoading('error')
            }
        })();
    }, [isExpenseResolved, component, isCanceled, activePage, searchValue])



    const handleChange = (e) => {
        const {name, value} = e.target
        setState({...state, [name]: value})
    }

    const handleOpenCancel = (obj) =>{
        setOpenCancel(true)
        setCanceledExpense(obj)

    }

    const handleCloseCancel = () =>{
        setOpenCancel(false)
    }

    const handleCancelExpense = () =>{
        const {related_expense_type, credit_ledger, debit_ledger,receipt_no, amount, entity_behavior_id} = canceledExpense
        const dataArr =[{
                related_expense_type, receipt_no, amount,
                debit_ledger, credit_ledger, entity_behaviour_id:entity_behavior_id
            }]
        setIsSubmitted('pendingCancel')
        axios.post(`${config.epharmUrl}/accounting/expenses/cancel`, dataArr).then(() => {
            actions.snackbarActions.successSnackbar('Expense canceled successfully')
            setSubmitted(false)
            setOpenCancel(false)
            setIsSubmitted('resolvedCancel')
        }).catch(err => {
            errorMessages(err, null, actions);
            setIsSubmitted('rejectedCancel')

        })

    }

    // const handleChangeActivePage = (new_page) =>{
    //     setActivePage(new_page + 1)
    // }

    const handleChangeSearch = (event) =>{
        setSearchValue(event.target.value)
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        setActivePage(newPage + 1)
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
    };




    const handleSubmit = (e) => {
        e.preventDefault()
        const {start_date, end_date} = state
        const formData = new FormData()
        formData.append('start_date', start_date)
        formData.append('end_date', end_date)
        setSubmitted(true);
        if (start_date && end_date) {
            setIsSubmitted('pending')
            axios.post(`${config.epharmUrl}/accounting/expenses`, formData).then((res) => {
                let _data = res.data
                let dt = _data.data ?? [];
                const arr = dt.map(({date,receipt_no, expense_type_name, amount,expense_id, debit_ledger_name, credit_ledger_name})=>({
                    date:convDate(date), debit_ledger_name, credit_ledger_name,
                    receipt_no,expense_type_name,amount,expense_id
                }))
                setExpenses(arr)
                setIsSubmitted('resolved')
            }).catch(err => {
                errorMessages(err, null, actions);
                setIsSubmitted('rejected')
            })
        }
    }


    const closeSnackbar = () =>{
        actions.snackbarActions.hideSnackBar()
    }

    const isPending = isSubmitted === 'pending'
    const isResolved = isSubmitted === 'resolved'
    const isRejected = isSubmitted === 'rejected'

    const isLoading = loading === 'pending'
    const isSuccess = loading === 'success'
    const isError = loading === 'error'


    return {expenses, state, submitted, isPending, isResolved, isRejected, isLoading, isSuccess,
    isError,handleSubmit, handleChange, closeSnackbar, expenseProps, openCancel, canceledExpense,
        handleCancelExpense, handleOpenCancel, handleCloseCancel, activePage, page, rowsPerPage,
    searchValue, handleChangeSearch, handleChangeRowsPerPage, handleChangePage, total_count}
}
