import React from 'react'
import {StickyHeadTable, CustomTableCell} from '../../../Utils/Tables/StickyHeaderTable';
import { dateConvert} from '../../../Utils/ConvertDate';
import axios from 'axios';
import config from "../../../Helpers/config.json";
import * as snackbarActions from '../../../Redux/Actions/SnackbarActions/SnackbarActions';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PharmacySnackbar from "../../../Utils/Snackbars/SnackbarUtil";
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { LoadingGif } from '../../../Utils/Loader';
import { formatAmount } from '../../../Utils/formatNumbers';
import {BackButton, DataExportButton} from '../../../Utils/Buttons/DataExportationButton';
import {AccountingMiniHeader,LedgerParagraph} from "../../../Utils/Menu/MiniSubHeader";
import { DateTimeFilter } from '../../../Utils/ReusableComponents/DateTimeFilter';
import {RightAlignedContainer} from "../../../Utils/styledComponents"
import ReusableDashboardHeader from "../../../Containers/Dasboard/ReusableDashboardHeader";
import {history} from "../../../Helpers/history";
import {EnhancedTableToolbar, MuiPagination, MuiPaper} from "../../../Utils/Tables/CustomTable";
import TextField from "../../../Utils/FormInputs/TextField";
import {useExcelReports, usePdfReports} from "../../Invoice/CustomHooks/useExcelReports";

const headData = [
    { id: 'product', label: 'Product',minWidth: 200 },
    { id: 'amount', label: 'Amount',minWidth: 200 },
]



function ViewStockJournalTransactions({actions, snackbars, match:{params}}) {
    const {account} = params;
    const storage = JSON.parse(sessionStorage.getItem('total_stock'))
    const total_stock_amount = storage ? storage : 0
    const stock_amount = () => +total_stock_amount || 0
    const [state, setState] = React.useState({start_date:'', end_date:''})
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [activePage, setActivePage] = React.useState(1)
    const [transactions, setTransactions] = React.useState([]);
    const [total_count, setTotalCount] = React.useState(0)
    const [loading, setLoading] = React.useState('idle')
    const [balance, setBalance] = React.useState(stock_amount)
    const [searchValue, setSearchValue] = React.useState('')
    const [submitted, setSubmitted] = React.useState(false);
    const [isSubmitted, setIsSubmitted] = React.useState('idle');
    const {start_date, end_date} =state;



    const retrieveData = React.useCallback((from_date='', to_date='', isSubmit=false)=>{
        setLoading('pending')
        isSubmit && setIsSubmitted('pending')
        axios.get(`${config.epharmUrl}/accounting/specific_journal_stock`, {params:{page:activePage,
                search:searchValue, start_date:from_date, end_date:to_date}}).then(response=>{
            const res = response.data ? response.data:{};
            const obj = res.data ? res.data : {};
            const arr = obj.stock_acc_list ? obj.stock_acc_list : [];
            const t_count = obj.total_count ? obj.total_count : 0
            const total_stock = obj.total_stock_value ? obj.total_stock_value : 0
            if(activePage === 1){
                sessionStorage.setItem('total_stock', JSON.stringify(total_stock))
                setBalance(total_stock)
            }else {
                setBalance(stock_amount)
            }

            setTransactions(arr)
            setTotalCount(t_count)
            setLoading('success')
            isSubmit && setIsSubmitted('resolved')
        }).catch(err=>{
            setLoading('error')
            isSubmit && setIsSubmitted('rejected')
        })

    },[activePage, searchValue])

    React.useEffect(()=>{
        retrieveData(start_date,end_date)
    },[retrieveData])


    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        setActivePage(newPage + 1)
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const handleChangeSearch = (event) =>{
        const {value} = event.target;
        setSearchValue(value)
        setActivePage(1)
        setPage(0)
    }

    const handleChange = (event) =>{
        const {value, name} = event.target;
        setState({...state,[name]:value})
    }

    const handleSubmit = (event) =>{
        event.preventDefault()
        setSubmitted(true)
        setActivePage(1)
        setPage(0)
        if(start_date && end_date && end_date >= start_date){
            retrieveData(start_date, end_date,true)
        }

    }

    const closeSnackbar = () => {
        actions.snackbarActions.hideSnackBar()
    };

    const isLoading = loading === 'pending';
    const isSuccess = loading === 'success';
    const isError = loading === 'error';

    const isPending = isSubmitted === 'pending';
    const {open, variant, message} = snackbars;




    const colSpan = headData.length

    const name = account.split('-')[1]

    const {exportExcel, isLoading:isLoadingExcel} = useExcelReports(actions,{url:'accounting/specific_journal_stock_excel',
        file_name:`${name} Account Details`, params:{start_date, end_date}})
    const {exportPDF, isLoading:isLoadingPdf} = usePdfReports(actions,{url:'accounting/specific_journal_stock_pdf',
        file_name:`${name} Account Details`,  params:{start_date, end_date}})

    const reusableTable = (
        <MuiPaper>
            <EnhancedTableToolbar title=''>
                <TextField type='text' placeholder='Search...' value={searchValue} onChange={handleChangeSearch}/>
            </EnhancedTableToolbar>
            <StickyHeadTable {...{width:'100%',page,rowsPerPage,handleChangePage,handleChangeRowsPerPage
                ,data:transactions, columns:headData.filter(item=>Boolean(item))}}>
                {isLoading ? <TableRow><TableCell align='center' {...{colSpan}}><LoadingGif/></TableCell></TableRow>:null}
                {isSuccess ? transactions.length > 0 ?
                    <>
                        {transactions.map((row, index) => {
                            return (
                                <TableRow  tabIndex={-1} key={index}>
                                    <CustomTableCell>{row.head_name}</CustomTableCell>
                                    <CustomTableCell>{formatAmount(row.res_amount)}</CustomTableCell>
                                </TableRow>
                            );
                        })}
                        {/*{emptyRows > 0 && (*/}
                        {/*    <TableRow style={{ height: 43 * emptyRows }}>*/}
                        {/*        <TableCell colSpan={headData.length} />*/}
                        {/*    </TableRow>*/}
                        {/*)}*/}
                    </>:<TableRow><TableCell align='center'  {...{colSpan}}>No records found</TableCell></TableRow>:null}
                {isError ? <TableRow><TableCell align='center'  {...{colSpan}}>The server did not return a valid response</TableCell></TableRow>:null}
            </StickyHeadTable>
            <MuiPagination rowsPerPage={rowsPerPage} page={page} total_count={total_count}
                           handleChangeRowsPerPage={handleChangeRowsPerPage} isTotalCount={true}
                           handleChangePage={handleChangePage} rows={10} colSpan={headData.length}/>
        </MuiPaper>

    )
    return (
        <div className='journals'>
            <ReusableDashboardHeader component="Stock Details" heading="Accounting"
                                     subHeading="Stock Details" link={history.location.pathname}/>
            <PharmacySnackbar variant={variant} open={open} message={message} handleCloseBar={closeSnackbar}/>

            <div className='mx-3 mt-2'>
                <BackButton to="/accounting/coa" text="Chart of Accounts"/>
                <div className='mt-3 row'>
                    <div className='col-lg-9'>
                        <DateTimeFilter {...{start_date,end_date, handleChange, submitted,handleSubmit,isPending,type:'date'}}/>
                    </div>
                    <div className='col-lg-3'>
                        <RightAlignedContainer>
                            {transactions.length > 0 ?
                                <div>
                                    <DataExportButton exportCsv={exportExcel} downLoadPdf={exportPDF} isPending={isLoadingExcel || isLoadingPdf}/>
                                </div> :null}
                        </RightAlignedContainer>
                    </div>

                </div>
                <div className='row'>
                    <div className='col-lg-6'>
                        <AccountingMiniHeader title={account}/>
                    </div>
                    <div className='col-lg-6'>
                        <RightAlignedContainer>
                            <LedgerParagraph variant='p1'>Running Balance: <strong>{formatAmount(balance)}</strong></LedgerParagraph>
                        </RightAlignedContainer>
                    </div>
                </div>

                {reusableTable}
            </div>
        </div>
    )
}
function mapStateToProps(state) {
    return {snackbars: state.snackbars}
}

function mapDispatchToProps(dispatch) {
    return {actions: {snackbarActions: bindActionCreators(snackbarActions, dispatch)}}
}

export default connect(mapStateToProps, mapDispatchToProps)(ViewStockJournalTransactions);
