import React from "react";
import CustomDialog from "./CustomDialog";
import {ErrorMessage, RightAlignedContainer} from "../styledComponents";
import TextField from "../FormInputs/TextField";
import Form from 'react-bootstrap/Form'
import Label from "../FormInputs/Label"

function UploadCsvDialog({openCsvDialog,handleCloseCsvDialog, title, fileUploader,downloadTemplate,
                       handleFileUpload, submitted, isPending, selectedFile=false }) {
    return (

        <CustomDialog open={openCsvDialog} handleClose={handleCloseCsvDialog} title={title}>
            <RightAlignedContainer>
                <button onClick={downloadTemplate} className="btn btn-sm pharmacy-btn-outline">Download template File</button>
            </RightAlignedContainer>
            <form onSubmit={fileUploader}>
                <Form.Group>
                    <Label style={{paddingLeft:'8px'}} type name='Select file'/>
                    <TextField style={{padding:'5px 10px'}} submitted={submitted} type='file' onChange={handleFileUpload}/>
                    {submitted && !selectedFile ? <ErrorMessage>Please choose file</ErrorMessage>:null}
                </Form.Group>
                <button style={{marginLeft:'8px', display:'inline-block'}} type='submit' disabled={isPending} className="btn btn-sm pharmacy-btn">{isPending ? 'Saving...':'Save'}</button>
            </form>
        </CustomDialog>
    )

}
export {UploadCsvDialog}