import React, {useRef} from "react";
import ReusableDashboardHeader from "../../Containers/Dasboard/ReusableDashboardHeader";
import {ReusablePeriodFilter} from "../../Utils/EpharmHeadings/ReusablePeriodFilter";
import {EnhancedTableHead, EnhancedTableToolbar, MuiPagination, MuiPaper} from "../../Utils/Tables/CustomTable";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import ReusableDialog from "../../Utils/Dialog/ReusableDialog";
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Label from "../../Utils/FormInputs/Label";
import {CustomSelect} from "../../Utils/FormInputs/SelectInput";
import TextField from "../../Utils/FormInputs/TextField";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import * as snackbarActions from '../../Redux/Actions/SnackbarActions/SnackbarActions'
import PharmacySnackbar from "../../Utils/Snackbars/SnackbarUtil";
//import {CSVLink} from "react-csv";
import {printFunctionality} from "../../Utils/printFunctionality";
import { useBankTransaction } from "./CustomHooks/useBankTransaction";

const headData = [
    {id: 'date', numeric: false, disablePadding: false, label: 'Date'},
    {id: 'bank', numeric: false, disablePadding: false, label: 'Bank'},
    {id: 'account', numeric: false, disablePadding: false, label: 'Account No.'},
    {id: 'transaction', numeric: false, disablePadding: false, label: 'Transaction'},
    {id: 'amount', numeric: false, disablePadding: false, label: 'Amount'},
    {id: 'charges', numeric: false, disablePadding: false, label: 'Charges'}
];

const BankTransaction = ({actions, snackbars}) => {
    const {openDialog, handleCloseDialog, handleOpenDialog, period, handleChangePeriod, inputType, date,
        handleChangeDate, year, handleChangeYear,state,handleChange,closeSnackbar, submitted,isSubmitted,
        page, rowsPerPage, handleChangeRowsPerPage, handleChangePage, loading, csvRef,headers,
        downloadPDF, bank, banks, account,accounts,transactions, transaction,handleChangeAccount, handleChangeBank,
    handleChangeTransaction,handleSubmit} = useBankTransaction(actions)
    const printRef = useRef();
    const {from_date, to_date} = date
    const {open, variant, message} = snackbars;
    const {transaction_date, amount, charges} = state
    return (
        <div className='journals'>
            <ReusableDashboardHeader component="Bank Transactions" heading="Bank"
                                     subHeading="Bank Transactions" link="/banktransactions"/>
            <ReusablePeriodFilter period={period} handleChangePeriod={handleChangePeriod} inputType={inputType}
                                  from_date={from_date} to_date={to_date} handleChangeDate={handleChangeDate}
                                  year={year}
                                  handleChangeYear={handleChangeYear} handleFilter={() => {
            }}>
                <div className='text-right'>
                    <button onClick={handleOpenDialog} className='btn btn-sm pharmacy-info-btn'>New Transaction</button>
                </div>
            </ReusablePeriodFilter>
        
            <ReusableDialog handleSubmit={handleSubmit} openDialog={openDialog}
                            handleClose={handleCloseDialog} message='Record Transaction' isSubmitted={isSubmitted}>
                
                <Form.Group as={Row}>
                    <Label name='Date.' type column sm={2}/>
                    <Col sm={8}>
                        <TextField type='date' value={transaction_date} name='transaction_date' onChange={handleChange}/>
                        {submitted && !transaction_date ? <div className='invalid-text'>Date is required</div> : null}
                    </Col>
                </Form.Group>
                <Form.Group as={Row}>
                    <Label name='Bank' type column sm={2}/>
                    <Col sm={8}>
                        <CustomSelect value={bank} onChange={handleChangeBank} options={banks}/>
                        {submitted && !bank ? <div className='invalid-text'>Bank is required</div> : null}
                    </Col>
                </Form.Group>
                <Form.Group as={Row}>
                    <Label name='Account#' type column sm={2}/>
                    <Col sm={8}>
                        <CustomSelect value={account} onChange={handleChangeAccount} options={accounts}/>
                        {submitted && !account ? <div className='invalid-text'>Account is required</div> : null}
                    </Col>
                </Form.Group>
                 <Form.Group as={Row}>
                 <Label name='Transaction' type column sm={2}/>
                 <Col sm={8}>
                     <CustomSelect value={transaction} onChange={handleChangeTransaction} options={[{value:'Deposit', label:'Deposit'},
                    {value:'Withdraw', label:'Withdraw'}]}/>
                     {submitted && !transaction ? <div className='invalid-text'>Transaction is required</div> : null}
                 </Col>
             </Form.Group>
                <Form.Group as={Row}>
                    <Label name='Amount' type column sm={2}/>
                    <Col sm={8}>
                        <TextField type='number' value={amount} name='amount' onChange={handleChange}/>
                        {submitted && !amount ? <div className='invalid-text'>Amount is required</div> : null}
                    </Col>
                </Form.Group>
                <Form.Group as={Row}>
                    <Label name='Charge' type column sm={2}/>
                    <Col sm={8}>
                        <TextField type='number' value={charges} name='charges' onChange={handleChange}/>
                        {submitted && !charges ? <div className='invalid-text'>Charge is required</div> : null}
                    </Col>
                </Form.Group>
            </ReusableDialog>
            <div className="mui-tables">
                <div className="text-right">
                    <div className='btn-group  mb-2'>
                        <button className='btn btn-sm pharmacy-grey-btn'
                                onClick={() => csvRef.current.link.click()}>CSV
                        </button>
                        <button className='btn btn-sm pharmacy-grey-btn' onClick={downloadPDF}>PDF</button>
                        <button className='btn btn-sm pharmacy-grey-btn'
                                onClick={() => printFunctionality(printRef.current)}>Print
                        </button>
                    </div>
                </div>
                {/*<CSVLink
                    data={transactions}
                    filename={`Bank Transactions.csv`}
                    className="hidden"
                    headers={headers}
                    ref={csvRef}
                    target="_blank"
                /> */}
            </div>
            <PharmacySnackbar handleCloseBar={closeSnackbar} variant={variant} open={open} message={message}/>
            <MuiPaper>
                <EnhancedTableToolbar title='Transactions'/>
                <TableContainer>
                    <Table
                        aria-labelledby="tableTitle"
                        size='medium'
                        aria-label="enhanced table"
                    >
                        <EnhancedTableHead headCells={headData}/>
                        {loading === 'pending' ? <TableBody><TableRow>
                            <TableCell colSpan="5" align="center"><img src="/images/listPreloader.gif"
                                                                       alt="loader"
                                                                       style={{width: '5%'}}/></TableCell>
                        </TableRow></TableBody> : transactions.length > 0 ?
                            <TableBody>
                                {transactions.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((d, index) => {
                                        return (
                                            <TableRow
                                                hover
                                                role="checkbox"
                                                tabIndex={-1}
                                                key={d.bank_transaction_id}
                                                className='mui-table-row'>
                                                <TableCell component="th" scope="row" padding="default"
                                                           className='mui-table-cell'>
                                                    {d.date}
                                                </TableCell>
                                                <TableCell>
                                                    {d.bank_name}
                                                </TableCell>
                                                <TableCell>
                                                    {d.accouunt_name}
                                                </TableCell>
                                                <TableCell>
                                                    {d.transaction}
                                                </TableCell>
                                                <TableCell>
                                                    {d.amount}
                                                </TableCell>
                                                <TableCell>
                                                    {d.charge}
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                            </TableBody>
                            : <TableBody><TableRow>
                                <TableCell colSpan="5" align="center">No records found</TableCell>
                            </TableRow></TableBody>}
                    </Table>
                </TableContainer>
                <MuiPagination data={transactions} rowsPerPage={rowsPerPage} page={page}
                               handleChangeRowsPerPage={handleChangeRowsPerPage}
                               handleChangePage={handleChangePage} rows={[10, 20, 30, 40, 50]} colSpan={3}/>
            </MuiPaper>
            <div ref={printRef} style={{display: 'none'}}>
                <table className='table table-borderless profile-table  table-sm'>
                    <thead>
                    <tr>
                        <th align='left'>Date</th>
                        <th align='left'>Bank</th>
                        <th align='left'>Account</th>
                        <th align='left'>Transaction</th>
                        <th align='left'>Amount</th>
                        <th align='left'>Charges</th>
                    </tr>
                    </thead>
                    <tbody>
                    {transactions.map(item => (
                        <tr key={item.bank_transaction_id}>
                            <td>{item.date}</td>
                            <td>{item.bank_name}</td>
                            <td>{item.account_name}</td>
                            <td>{item.transaction}</td>
                            <td>{item.amount}</td>
                            <td>{item.charge}</td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>
        </div>
    )
}

function mapStateToProps(state) {
    const {snackbars} = state;
    return {snackbars}
}

function mapDispatchToProps(dispatch) {
    return {actions: {snackbarActions: bindActionCreators(snackbarActions, dispatch)}}
}

export default connect(mapStateToProps, mapDispatchToProps)(BankTransaction)