/** @jsx jsx */ //please do not remove this implementation because the css will not be loaded without it
import { jsx, css } from '@emotion/react'
import React from "react";
import {ItemsTable, ReceiptTemplate} from "../../../Utils/Template/ReceiptTemplate";
import { formatDigits} from "../../../Utils/formatNumbers";
import {formatDateTime} from "../../../Utils/ConvertDate";

const headData = [{value:'Expense Item'}, {value:'Debit Ledger'},{value:'Credit Ledger'},{value:'Receipt No.'},{value:'Amount'}]



const CancelExpense = ({expense}) => {


    const expenseDetails =(
        <tbody>
        <tr>
            <td>
                <td data-testid="expense_date"><span>Date: <strong>{expense.date ? expense.date : ''}</strong></span></td>
            </td>

        </tr>
        </tbody>
    )

    const itemDetails = (
        <ItemsTable headData={headData}>
            <tbody>
            <tr >
                <td  data-testid={"expense-item"}><span>{expense.expense_type_name}</span></td>
                <td  data-testid={"debit-ledger"}><span>{expense.debit_head_name}</span></td>
                <td data-testid={"credit-ledger"}><span>{expense.credit_head_name}</span></td>
                <td data-testid={"receipt-number"}><span>{expense.receipt_no}</span></td>
                <td data-testid={"amount"}><span>{formatDigits(expense.amount)}</span></td>
            </tr>

            </tbody>
        </ItemsTable>
    )

    return (
        <ReceiptTemplate {...{customerDetails:expenseDetails, itemDetails}}/>
    )

}


export {CancelExpense};
