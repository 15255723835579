import Form from "react-bootstrap/Form";
import Label from "../../Utils/FormInputs/Label";
import Col from "react-bootstrap/Col";
import TextField from "../../Utils/FormInputs/TextField";
import React from "react";


function UnitForm({submitted, unit_name, handleChange, handleSubmit, isPending}) {
    return (
        <form onSubmit={handleSubmit}>
            <Form.Group as={Col}>
                <Label  type name='Unit Name'/>
                <TextField data-testid="unit_name" submitted={submitted} type="text" value={unit_name} name="unit_name"
                               onChange={handleChange}/>
                    {submitted && !unit_name ? <div className="invalid-text">Unit name is required</div> : null}
            </Form.Group>
            <div className="text-center">
                <button data-testid="submit_unit" type="submit" className="btn btn-sm pharmacy-info-btn"
                        disabled={isPending}>{isPending ? 'Saving...' : 'Save'}</button>
            </div>
        </form>
    )

}

function TypeForm({submitted, type_name, handleChange, handleSubmit, isPending}) {
    return (
        <form onSubmit={handleSubmit}>
            <Form.Group as={Col}>
                <Label  type name='Type Name' htmlFor='typeName'/>
                <TextField data-testid="type_name" submitted={submitted} type="text" value={type_name} name="typeName"
                               onChange={handleChange}/>
                    {submitted && !type_name ? <div className="invalid-text">Type name is required</div> : null}
            </Form.Group>
            <div className="text-center">
                <button data-testid="submit_type" type="submit" className="btn btn-sm pharmacy-info-btn"
                        disabled={isPending}>{isPending ? 'Saving...' : 'Save'}</button>
            </div>
        </form>
    )

}

function CategoryForm({submitted, category_name, handleChange, handleSubmit, isPending}) {
    return (
        <form onSubmit={handleSubmit}>
            <Form.Group as={Col}>
                <Label type  name='Category Name'/>
                <TextField data-testid="category_name" submitted={submitted} type="text" value={category_name} name="category_name"
                               onChange={handleChange}/>
                    {submitted && !category_name ? <div className="invalid-text">Category name is required</div> : null}
            </Form.Group>
            <div className="text-center">
                <button data-testid="submit_category" type="submit" className="btn btn-sm pharmacy-info-btn"
                        disabled={isPending}>{isPending ? 'Saving...' : 'Save'}</button>
            </div>
        </form>
    )

}

export {UnitForm, TypeForm, CategoryForm}