import React, {Component} from 'react';
import {Col, Form, Row} from "react-bootstrap";
import {CameraAlt} from "@material-ui/icons";
import IconButton from "@material-ui/core/IconButton";
import DashboardHeader from "../../Containers/Dasboard/DashboardHeader";
import axios from 'axios';
import config from "../../Helpers/config"
import {errorMessages} from "../../Helpers/ErrorMessages";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as snackbarActions from "../../Redux/Actions/SnackbarActions/SnackbarActions";
import PharmacySnackbar from "../../Utils/Snackbars/SnackbarUtil";
import {titleCase} from "../../Utils/titleCaseFunction";
import {history} from "../../Helpers/history"

class EditUserProfile extends Component {
    constructor() {
        super();
        this.state = {
            imageUrl:'',
            imageFile:'',
            user:{},
            first_name:'',
            last_name:'',
            user_name:'',
            submitted:false,
            
        }
        this.updateForm = React.createRef();
    }


    retrieveEditDetails = () =>{
        const {actions} = this.props;
        const user = JSON.parse(sessionStorage.getItem('user'));
        const singleUser = !user ? {} : user;
        const user_id = !singleUser.user_id ? "" : singleUser.user_id;
        axios.post(`${config.epharmUrl}/admin/edit_profile`,null,{headers:{user_id:user_id}}).then(response=>{
            const data = response.data;
            this.setState({user:data})
        }).catch(error=>{
            errorMessages(error, null,actions)
        })
    };
    componentDidMount() {
        this.retrieveEditDetails()
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        const {user} = this.state;
        if(this.state.user !== prevState.user){
            this.setState({
                first_name:user.first_name,
                last_name:user.last_name,
                user_name:user.email,
                imageFile:user.logo
            })
        }
    }

    handleSubmitUser = (event) =>{
        event.preventDefault();
        const {imageFile} = this.state;
        const {actions} = this.props;
        const formData = new FormData(this.updateForm.current);
        formData.append('logo',imageFile);
        const user = JSON.parse(sessionStorage.getItem('user'));
        const singleUser = !user ? {} : user;
        const user_id = !singleUser.user_id ? "" : singleUser.user_id;
        this.setState({submitted:true});
        axios.post(`${config.epharmUrl}/admin/update_profile`,formData,{headers:{user_id:user_id}}).then(response=>{
            // const data = response.data;
            actions.snackbarActions.successSnackbar('User profile updated successfully');
            history.push('/')
            this.setState({submitted:false});
        }).catch(error=>{
            errorMessages(error, null,actions)
            this.setState({submitted:false});
        })
    };
    handleChange = event =>{
        this.setState({
            imageUrl:URL.createObjectURL(event.target.files[0]),
            imageFile:event.target.files[0]
        })
    };
    handleCloseSnackbar = () => {
        const {actions} = this.props; 
        actions.snackbarActions.hideSnackBar();
    };
    handleChangeFields = (event) =>{
        const {name,value} = event.target;
        this.setState({[name]:value})
    };
    render() {
        const {imageUrl,first_name,last_name,user_name,submitted} = this.state;
        const {open,variant,message} = this.props.snackbars;
        return (
            <div>
            <DashboardHeader user editUser/>
                {/*<div className="col ">*/}
                <PharmacySnackbar open={open} variant={variant} message={message} handleCloseBar={this.handleCloseSnackbar}/>
                    <div className="card user-edit-card ">
                        <div className="edit-header"/>

                        <div className="edit-image">
                            <img src={!imageUrl ? "/images/profile.png" : imageUrl} alt="user"/>
                        </div>
                        <div className="icon-button">
                        <IconButton size="small" className="icon-holder" onClick={()=>this.input.click()}><CameraAlt/></IconButton>
                        </div>
                        <div className="edit-data">
                            <Form onSubmit={this.handleSubmitUser} autoComplete="off" ref={this.updateForm}>
                                <Form.Group as={Row}>
                                    <Form.Label column sm="2">First Name</Form.Label>
                                    <Col sm="8">
                                        <Form.Control type="text" name="first_name" value={titleCase(first_name)} onChange={this.handleChangeFields}/>
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row}>
                                    <Form.Label column sm="2">Last Name</Form.Label>
                                    <Col sm="8">
                                        <Form.Control type="text" name="last_name" value={titleCase(last_name)} onChange={this.handleChangeFields}/>
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row}>
                                    <Form.Label column sm="2">Email</Form.Label>
                                    <Col sm="8">
                                        <Form.Control type="email" name="user_name" value={user_name} onChange={this.handleChangeFields}/>
                                    </Col>
                                </Form.Group>
                                <input type="file" ref={el =>this.input =el} style={{display:"none"}} onChange={this.handleChange}/>
                                <button type="submit" disabled={!!submitted} className="btn pharmacy-btn mt-4 ">{submitted ? "Updating....":"Update Profile"}</button>
                            </Form>
                        </div>
                    </div>
                {/*</div>*/}
            </div>
        );
    }
}

function mapStateToProps(state) {
    return{
        snackbars:state.snackbars
    }

}
function mapDispatchToProps(dispatch) {
    return{
        actions:{
            snackbarActions:bindActionCreators(snackbarActions,dispatch)
        }
    }

}

export default connect(mapStateToProps,mapDispatchToProps)(EditUserProfile);