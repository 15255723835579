import {useEffect, useRef, useState} from "react";
import {convertDate, titleCase} from "../../../Utils/titleCaseFunction";
import axios from "axios";
import config from "../../../Helpers/config.json";
import {errorMessages} from "../../../Helpers/ErrorMessages";
import {history} from "../../../Helpers/history";

function useAddStockAdjustment(actions, params={}) {

    const reasons = [{value:'Positive Adjustment for', text:'Positive Adjustment for'}, {value:'Negative Adjustment for', text:'Negative Adjustment for'},
        {value:'Expiry', text:'Expiry'},{value:'Damage', text:'Damage'},
        {value:'Wastage', text:'Wastage'}]
    const {product_id:prdt_id, product_name:prdt_name} = params
    const [products, setProducts] = useState([])
    const [product, setProduct] = useState({product_name:'', product_id:prdt_id, selected_product:prdt_name})
    const [showProduct, setShowProduct] = useState(false)
    const [purchaseDate, setPurchaseDate] = useState(convertDate);
    const [stockCsv, setStockCsv] = useState(null);
    const [openDialog, setOpenDialog] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState('idle');
    const [allStock, setAllStock] = useState([])
    const [drugs, setDrugs] = useState([])
    const [loading, setLoading] = useState('idle')
    const [adjustment_id, setAdjustmentId] = useState('')
    const [openReport, setOpenReport] = useState(false)
    const [state, setState] = useState({reason:'',description:'', positive_reason:''});
    const [isBlocking, setIsBlocking] = useState(false)
    const csvLink = useRef();
    const {product_id, selected_product} = product

    useEffect(()=>{
        if(!product_id){
            return
        }
        const formData = new FormData()
        formData.append('product_id',product_id)
        setLoading('loading')

        axios.post(`${config.epharmUrl}/Creport/retrieve_stock_data`,  formData).then(response => {
            const data = response.data;
            const dt = !data ? {} : data;
            const arr = dt.stock_data ? dt.stock_data : [];
            const zero_arr = dt.zero_stock_data ? dt.zero_stock_data : [];

            const batchData = arr.map(item=>({
                ...item,
                batch_id:item.batch_id,
                expiry_date:item.expeire_date,
                stock:item.remaining_stock,
                physical_stock:'',
                quantity:'',
                adjustment_type:'',
                reason:'',
                unit_cost:item.manufacturer_price,
                isBatch:true
            })) 

            const zero_batchData = zero_arr.map(item=>({
                ...item,
                batch_id:item.batch_id,
                expiry_date:item.expeire_date,
                stock:item.remaining_stock,
                physical_stock:'',
                quantity:'',
                adjustment_type:'',
                reason:'',
                unit_cost:item.manufacturer_price,
                isBatch:true
            })) 

            // const allBatches = arr.length > 0 ? batchData : [{
            //     batch_id:'',
            //     expiry_date:'',
            //     stock:'',
            //     physical_stock:'',
            //     quantity:'',
            //     unit_cost:'',
            //     isBatch:false,
            //
            // }]
            // const limit = arr[0] ? arr[0] : {}
         
            const expiry_limit = dt.expiry_limit ? dt.expiry_limit:0
            const obj = {
                product_name:selected_product, 
                product_id:product_id,expiry_limit, open:true, 
                batches:[ ...zero_batchData, ...batchData]
            }

            setLoading('success');
            setProducts([...products,obj]);
        }).catch(error => {
            errorMessages(error, null, actions)
            setLoading('error')
        });

    },[product_id])

    const retrieveExpiry = (rowsCopy,b_id, product_idx, idx) => {
        axios.get(`${config.epharmUrl}/Cinvoice/retrieve_product_batch_id`, {
            params: {batch_id: b_id, product_id: products[product_idx]['product_id']}
        }).then(response => {
            const data = response.data;
            const dt = data ? data : {};
            if (data) {
                if (dt.expire_date) {
                    rowsCopy[idx]['expiry_date'] = dt.expire_date;
                    rowsCopy[idx]['disableExpiry'] = true;
                } else {
                    rowsCopy[idx]['expiry_date'] = '';
                    rowsCopy[idx]['disableExpiry'] = false;
                }
                const arr = products.map((item, index)=>{
                    if(index === product_idx){
                        return {...item, batches:rowsCopy}
                    }
                    return item
                })
                setProducts(arr)

            }
        }).catch(error => {
            rowsCopy[idx]['expiry_date'] = '';
            rowsCopy[idx]['disableExpiry'] = false;
            const arr = products.map((item, index)=>{
                if(index === product_idx){
                    return {...item, batches:rowsCopy}
                }
                return item
            })
            setProducts(arr)
            errorMessages(error, null, actions)
        });
        setIsBlocking(true)

    }


    const handleChange = (event) =>{
        const {name, value} = event.target
        setState({...state, [name]:value})
        setIsBlocking(true)
    }

    const handleOpen = (idx) =>{
        const itemsArr = products.map((item, index)=>{
            if(index === idx){
                return {...item,open:!item.open}
            }else{
                return item
            }
        })
        setProducts(itemsArr)
    }

    const handleRetrieveDrugs = (event) => {

        const val = event.target ? event.target.value : event;
        setProduct({...product,product_name:val})
        let tmp = val.trim();
        if (tmp !== '') {
            axios.get(`${config.epharmUrl}/Cinvoice/autocompleteproductsearch`, {params: {product_name: titleCase(tmp)}}).then(response => {
                const drugs = response.data;
                const dt = !drugs ? [] : drugs;
                setDrugs(dt)
            }).catch(error => {
                errorMessages(error, null, actions)
            })
            setShowProduct(true)
        }else {
            setShowProduct(false)
        }
        setIsBlocking(true)
    };

    const handleClickDrug = (event) => {
        setProduct({product_id: event.value, product_name:'', selected_product: event.innerText})
        setShowProduct(false)
        setIsBlocking(true)
    };

    const handleAddBatch = (idx) =>{
        const obj =  {  batch_id:'',
            expiry_date:'',
            stock:0,
            physical_stock:'',
            quantity:'',
            unit_cost:'',
            isBatch:false,
            disableExpiry:true
        }
        const arr = products.map((item, index)=>{
            if(index === idx){
                return {...item, batches:[...item.batches,obj]}
            }
            return item
        })
        setProducts(arr)
        setIsBlocking(true)

    }

    const handleRemoveProduct = (index) =>{
        const arr = products.filter((_, idx) => index !== idx)
        setProducts(arr)
        setIsBlocking(true)
    }

    const handleRemoveBatch = (idx, batch_idx) =>{
        const arr = products.map((item, index)=>{
            if(index === idx){
                const arr = item.batches.filter((_, batch_index)=>batch_index !== batch_idx)
                return {...item, batches:arr}
            }
            return item
        })
        setProducts(arr)
        setIsBlocking(true)
    }

    const handleChangeItem = (event,idx, batch_idx, item_stock='') =>{
        const {name, value} = event.target
        const arr = products.map((item, index)=>{
            if(index === idx){
                const arr = item.batches.map((batch, batch_index)=>{
                    if (batch_index === batch_idx){
                        if (name === 'physical_stock') {
                            if (parseInt(value) > parseInt(item_stock)) {
                                return {
                                    ...batch, 
                                    adjustment_type:'I',
                                    [name]:value,
                                }
                            } else if (parseInt(value) < parseInt(item_stock)) {
                                return {
                                    ...batch, 
                                    adjustment_type:'D',
                                    [name]:value,
                                }
                            } else {
                                return {
                                    ...batch, [name]:value, adjustment_type:''
                                }
                            }
                        } else {
                            return {...batch, [name]:value}
                        }
                    }
                    return batch
                })
                return {...item, batches:arr}
            }
            return item
        })
        setProducts(arr)
        setIsBlocking(true)
    }


    const handleChangeDate = (event) =>{
        setPurchaseDate(event.target.value)
        setIsBlocking(true)
    }


    const handleOpenDialog = () => {
        setOpenDialog(true)
    };

    const handleCloseDialog = () => {
        setOpenDialog(false)
    };

    const handleUploadCsv = (event) => {
        event.preventDefault();
        const formData = new FormData();
        formData.append('upload_csv_file', stockCsv ? stockCsv : '');
        axios.post(`${config.epharmUrl}/Creport/upload_adjustment_CSV`, formData)
            .then(() => {
                actions.snackbarActions.successSnackbar('Stock csv uploaded Successfully');
                setTimeout(() => {
                    history.push('/stockreport')
                }, 2000);
            }).catch(error => {
            errorMessages(error, null, actions);
        })
    };

    const handleChangePurchaseCsv = event => {
        if (event.target.files[0].name.substr(event.target.files[0].name.length - 4) === ".csv") {
            setStockCsv(event.target.files[0])
        } else {
            event.target.value = null;
            actions.snackbarActions.errorSnackbar('Please upload only csv format')
        }

    };



    const handleCloseReport = () =>{
        setOpenReport(false)
    }

    const handleViewReport = () =>{
        history.push(`/stock-adjustment-report/${adjustment_id}`)
    }

    const handleCancelView = () =>{
        history.push('/stock-adjustment-list')
    }



    const handleSubmit = event => {
        event.preventDefault();
        const user = JSON.parse(sessionStorage.getItem('user'));
        const singleUser = !user ? {} : user;
        const user_roles_id = !singleUser.user_id ? "" : singleUser.user_id;

        const {description} = state
        setSubmitted(true)
        /*const data = products.reduce((r, a) => r.concat(a.batches.map(b => ({product_id: a.product_id,...b}))), []);
        const old_stock = data.filter(item=>item.isBatch).filter(item=>item.physical_stock !== '').map(({physical_stock, batch_id, product_id})=>({batch_id,product_id, physical_stock: +physical_stock}))
        // const new_stock = data.filter(item=>!item.isBatch).map(({physical_stock, isBatch, stock, unit_cost, ...item})=>({...item,product_id, quantity: +physical_stock, unit_cost:+unit_cost}))
        const arr = data.filter(item=>!item.isBatch).every(item => item.batch_id && item.expiry_date && item.unit_cost && item.physical_stock !== '');
        // const oldArr = data.filter(item=>item.isBatch).some(item => item.physical_stock !== '');
        // const isRequired = oldArr && new_stock.length === 0
        if (arr && reason) { */

        let details = [];

            let check_batch_stock = false;
            const details_array = products.filter((it) => {
                return it.batches.length > 0;
            });

            for (var x = 0; x < details_array.length; x++) {
                const item = details_array[x];

                const batch_array = item.batches.map((ex) => {

                    if ((check_batch_stock === false && !ex.physical_stock && !ex.adjustment_type)
                     || (parseInt(ex.physical_stock) >= parseInt(ex.stock) && (ex.adjustment_type !== '' && ex.adjustment_type === 'D'))
                     || (parseInt(ex.physical_stock) <= parseInt(ex.stock) && ex.adjustment_type === 'I')
                     ) {
                        check_batch_stock = true;
                    }

                    return {
                        batch_id:ex.batch_id,
                        product_id : item.product_id,
                        stock:ex.stock,
                        adjustment_type:ex.adjustment_type,
                        reason:ex.reason,
                        physical_stock: parseInt(ex.physical_stock),
                        unit_cost:ex.unit_cost,
                        purchase_detail_id:ex.purchase_detail_id
                    }
                });

                details = [...details, ...batch_array]

            }

            
            let dt = {
                user_roles_id,
                // reason : positive_reason === '' ? reason : (reason + ' ' + positive_reason),
                description,
                //date : purchase_date,
                details
            };

        if ((check_batch_stock === false)) {
            setIsSubmitted('pending')
            setIsBlocking(false)
            //axios.post(`${config.epharmUrl}/Creport/save_adjustment`,{user_id,old_stock, new_stock:[], reason, description})
            axios.post(`${config.epharmUrl}/Creport/save_adjustment`,dt)
                .then((res) => {
                    const data = res?.data?.data
                    const _id = data[0] ? data[0] : {};
                    const adj_id = _id.adjustment_id ? _id.adjustment_id : ''
                    actions.snackbarActions.successSnackbar('Stock adjusted Successfully');
                    setIsSubmitted('resolved')
                    setAdjustmentId(adj_id)
                    // setOpenReport(true)
                    history.push('/stock-adjustment-list')
                }).catch(error => {
                errorMessages(error, null, actions);
                setIsSubmitted('rejected')
            })
        }
    };


    const fetchData = () => {
        axios.get(`${config.epharmUrl}/Creport/getStock`).then(response => {
            const data = response.data;
            const dt = !data ? {} : data;
            const stock_list = !dt.stock ? [] : dt.stock
            setAllStock(stock_list)
            csvLink.current.link.click()
        }).catch(err => {
            errorMessages(err, null, actions)
        })
    };



    const handleCloseBar = () => {
        actions.snackbarActions.hideSnackBar();
    };

    const isPending = isSubmitted === 'pending'
    const isResolved = isSubmitted === 'resolved'
    const isRejected = isSubmitted === 'rejected'

    const isLoading = loading === 'loading'
    const isSuccess = loading === 'success'
    const isError = loading === 'error'

    return {products, product,drugs, showProduct,handleOpen, handleClickDrug, handleRetrieveDrugs,csvLink,
        purchaseDate,isResolved, isPending, isRejected, submitted, handleAddBatch, handleRemoveBatch,
        handleRemoveProduct, handleChangeItem, handleChangePurchaseCsv, handleChangeDate, handleOpenDialog,
        fetchData, openDialog,allStock, handleCloseBar, handleCloseDialog,handleSubmit,handleUploadCsv,
    isLoading, isSuccess, isError,retrieveExpiry, state, handleChange, reasons, openReport, handleViewReport,
    handleCloseReport, handleCancelView, isBlocking}
}
export {useAddStockAdjustment}