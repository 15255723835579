import React from 'react'
import {TableRow, TableCell} from '@material-ui/core';
import {bindActionCreators} from "redux";
import * as snackbarActions from '../../../Redux/Actions/SnackbarActions/SnackbarActions'
import {connect} from "react-redux";
import PharmacySnackbar from "../../../Utils/Snackbars/SnackbarUtil";
import {CustomTableCell, StickyHeadTable} from "../../../Utils/Tables/StickyHeaderTable";
import {LoadingGif} from "../../../Utils/Loader";
import {history} from "../../../Helpers/history";
import {ReusableRouteTabs} from "../../../Utils/Dialog/ReusableTabs";
import {RightAlignedContainer,  ExpenseContainer} from "../../../Utils/styledComponents";
import CustomDialog from "../../../Utils/Dialog/CustomDialog";
import {useExpenseItems} from "./hooks/useExpenseItems";
import {NewExpenseItem} from "./NewExpenseItem";
import {ReusableNewCOA} from "../COA/NewCOAccount";
import ReusableDashboardHeader from "../../../Containers/Dasboard/ReusableDashboardHeader";
import { MuiPaper } from '../../../Utils/Tables/CustomTable';

const headData = [{id: 'expense_item', numeric: false, disablePadding: false, label: 'Expense Item'},
   // {id: 'debit_ledger', numeric: false, disablePadding: false, label: 'Debit Ledger'},
   // {id: 'credit_ledger', numeric: false, disablePadding: false, label: 'Credit Ledger'},
  //  {id: 'action', numeric: false, disablePadding: false, label: 'Action'},
];

const components = [
    {label: 'Expenses', path: '/accounting/expenses/active'},
    {label: 'Canceled Expenses', path: '/accounting/expenses/canceled'},
    {label: 'Expense Items', path: '/accounting/expense-items'}
]

const ExpenseItems = ({actions, snackbars}) => {
    const {expensesItems,  isLoading, isSuccess, isError, closeSnackbar, itemProps} = useExpenseItems(actions);

    const {openDialog, handleOpenDialog, handleCloseDialog, ledgerProps, openEdit, handleCloseEdit} = itemProps;
    const {openLedger, handleOpenLedger, handleCloseLedger, handleSubmit, isPending} = ledgerProps


    const {open, variant, message} = snackbars


    const emptyRows = 10 - Math.min(10, expensesItems.length);

    const reusableTable = (
        <MuiPaper>
            <StickyHeadTable {...{width:'100%'
                ,data:expensesItems, columns:headData.filter(item=>Boolean(item))}}>
                {isLoading ? <TableRow><TableCell align='center' colSpan={headData.length}><LoadingGif/></TableCell></TableRow>:null}
                {isSuccess ? expensesItems.length > 0 ?
                    <>
                        {expensesItems.map((row) => {
                            return (
                                <TableRow  tabIndex={-1} key={row.id}>
                                    <CustomTableCell>{row.expense_type_name}</CustomTableCell>
                                    {/*<CustomTableCell>{row.debit_ledger_name}</CustomTableCell>
                                    <CustomTableCell>{row.credit_ledger_name}</CustomTableCell>
                                    <CustomTableCell>
                                        <button onClick={()=>handleOpenEdit(row.id)} className='pharmacy-info-btn btn btn-sm'>Edit</button>
                                    </CustomTableCell> */}
                                </TableRow>
                            );
                        })}
                        {emptyRows > 0 && (
                            <TableRow style={{ height: 43 * emptyRows }}>
                                <TableCell colSpan={headData.length} />
                            </TableRow>
                        )}
                    </>:<TableRow><TableCell align='center'  colSpan={headData.length}>No records found</TableCell></TableRow>:null}
                {isError ? <TableRow><TableCell align='center'  colSpan={headData.length}>The server did not return a valid response</TableCell></TableRow>:null}
            </StickyHeadTable>
        </MuiPaper>
    
    )
    return (
        <div className='journals'>
            <ReusableDashboardHeader component="Expense Items" heading="Accounting"
                                     subHeading="Expense items" link={history.location.pathname}/>
            <ExpenseContainer>
                <RightAlignedContainer>
                    <div className='mt-3 mx-3'>
                        <button className='btn btn-sm pharmacy-info-btn' onClick={handleOpenDialog}>New Expense Item</button>
                    </div>
                </RightAlignedContainer>
                <CustomDialog open={openDialog} handleClose={handleCloseDialog} title='New Expense Item' maxWidth='sm'>
                    {/*<RightAlignedContainer>
                        <div className='mb-2'>
                            <button className='btn btn-sm pharmacy-info-btn' onClick={handleOpenLedger}>Quick Add Ledger</button>
                        </div>
                    </RightAlignedContainer> */}
                    <NewExpenseItem {...itemProps}/>
                </CustomDialog>

                <CustomDialog open={openEdit} handleClose={handleCloseEdit} title='Edit Expense Item' maxWidth='sm'>
                    <RightAlignedContainer>
                        <div className='mb-2'>
                            <button className='btn btn-sm pharmacy-info-btn' onClick={handleOpenLedger}>Quick Add Ledger</button>
                        </div>
                    </RightAlignedContainer>
                    <NewExpenseItem {...itemProps}/>
                </CustomDialog>

                <CustomDialog open={openLedger} handleClose={handleCloseLedger} title='New Ledger Account' maxWidth='md'>
                    <form onSubmit={handleSubmit} autoComplete="off" className="general-card">
                        <ReusableNewCOA {...{...ledgerProps, isExpense:true}}/>
                        <button type="submit" className="btn btn-sm pharmacy-info-btn"
                                disabled={isPending}>{isPending ? 'Submitting...' : 'Submit'}</button>
                    </form>
                </CustomDialog>
                <ReusableRouteTabs value={history.location.pathname} tabHeading={components}/>
                <PharmacySnackbar variant={variant} handleCloseBar={closeSnackbar} open={open} message={message}/>
                {reusableTable}
            </ExpenseContainer>
        </div>
    )
}

function mapStateToProps(state) {
    const {snackbars} = state;
    return {snackbars}
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            snackbarActions: bindActionCreators(snackbarActions, dispatch),
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ExpenseItems);