import {useEffect, useRef, useState} from "react";
import {useMuiPagination} from "../../../Containers/Dasboard/DashboardTables";
import axios from "axios";
import config from "../../../Helpers/config.json";
import {errorMessages} from "../../../Helpers/ErrorMessages";
import {exportPDF} from "../../../Utils/exportPDF";
import {copyToClipboard} from "../../../Utils/copyToClipboard";
import {useAddCategory} from "./useAddCategory";

export const useMedicineCategory = (actions) =>{
    const [product_category_id, setCategoryId] = useState('');
    const [openDelete, setOpenDelete] = useState(false);
    const [categories, setCategories] = useState([]);
    const [loading, setLoading] = useState(false);
    const [term, setTerm] = useState('')
    const [allCategories, setAllCategories] = useState([]);
    const [categoryCsv, setCategoryCsv] = useState(null);
    const [error_message, setErrorMessage] = useState('');
    const [csvdialog, setCSVDialog] = useState(false);
    const csvLink = useRef();
    const {page, rowsPerPage, handleChangeRowsPerPage, handleChangePage} = useMuiPagination();
    const componentRef = useRef();
    const {isResolved, categoryProps} = useAddCategory(actions)

    useEffect(() => {
        setLoading(true)
        axios.get(`${config.epharmUrl}/Ccategory/manage_category`).then(response => {
            const data = response.data
            const dt = data ? data : {};
            const list = dt.category_list ? dt.category_list : []
            setCategories(list);
            setLoading(false)
        }).catch(error => {
            //errorMessages(error, null, actions)
            setLoading(false);
        })
    }, [isResolved])


    const handleSearch = (value) =>{
        setTerm(value)
    }

    const handleOpenDelete = (_id = '') => {
        setCategoryId(_id)
        setOpenDelete(true)
    };

    const handleCloseDelete = () => {
        setOpenDelete(false)
    }

    const deleteCategory = () => {
        axios.post(`${config.epharmUrl}/Ccategory/category_delete`, null, {params: {category_id:product_category_id}}).then(() => {
            actions.snackbarActions.successSnackbar('Category deleted successfully');
            const arr = categories.filter(item => item.category_id !== product_category_id)
            setCategories(arr)
            setOpenDelete(false)
        }).catch(error => {
            errorMessages(error, null, actions)
        })
    };


    const downloadPDF = () => {
        exportPDF("Medicine Categories Report", [["Sl", "Category Name"]],
            categories.map((med, index) => [index += 1, med.category_name]), "Medicine Categories.pdf");
    };
    const copyTable = () => {
        copyToClipboard(actions, 10, componentRef)
    };

    const handleCloseSnackbar = () => {
        actions.snackbarActions.hideSnackBar()
    };

    const handleOpenCSVDialog = () => {
        setCSVDialog(true);
    };

    const handleCloseCSVDialog = () => {
        setCSVDialog(false);
        setErrorMessage('');
    };

    const fetchData = () => {
        axios.get(`${config.epharmUrl}/Cproduct/category_csv`,{params:{download:true}, responseType: 'blob'}).then(response => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `Category_Template.csv`);
            document.body.appendChild(link);
            link.click();
            setErrorMessage('');
        }).catch(err => {
            errorMessages(err, null, actions)
        })
    };

    const handleUploadCsv = (event) => {
        event.preventDefault();
        if (categoryCsv) {
            const formData = new FormData();
            formData.append('upload_csv_file', categoryCsv ? categoryCsv : '');
            axios.post(`${config.epharmUrl}/Cproduct/uploadCsv_category`, formData).then((res) => {
                    actions.snackbarActions.successSnackbar('Category csv uploaded Successfully');
                    //const data_arr = res.data?? [];
                    if (error_message) setErrorMessage('');
                    // setTimeout(() => {
                    //     history.push('/managemedicine');
                    //  }, 1000); 
                }).catch(error => {
                    const err_msg = (error.response && error.response.data) ? error.response.data.message.split('-')[1] : '';
                    setErrorMessage('Missing field in file: ' + err_msg)
            })
        } else {
            setErrorMessage('Please select a csv or excel file to upload');
        }
    };

    const handleChangeCategoryCsv = event => {
        const file_extension = event.target.files[0].name.substr(event.target.files[0].name.length - 4);
        if (file_extension === ".csv" || file_extension === ".xls" || file_extension === "xlsx") {
            setCategoryCsv(event.target.files[0]);
            if (error_message) setErrorMessage('');
        } else {
            event.target.value = null;
            setErrorMessage('Please upload only csv or excel (.xls or .xlsx) format');
        }
    };

    const csvProps = {handleChangeCategoryCsv, handleCloseCSVDialog,handleOpenCSVDialog, fetchData,
        handleUploadCsv, error_message, allCategories,csvdialog,csvLink}

    return { openDelete, categories, loading,  page, rowsPerPage, handleChangeRowsPerPage, handleChangePage,
         handleOpenDelete, handleCloseDelete, deleteCategory, categoryProps, csvProps,
         handleCloseSnackbar,downloadPDF,copyTable,componentRef, term, handleSearch}
}