import React, { useCallback, useEffect, useState } from "react";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import * as snackbarActions from "../../../Redux/Actions/SnackbarActions/SnackbarActions";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import PharmacySnackbar from "../../../Utils/Snackbars/SnackbarUtil";
import ReusableDashboardHeader from "../../../Containers/Dasboard/ReusableDashboardHeader";
import { history } from "../../../Helpers/history";
import TextField from "../../../Utils/FormInputs/TextField";
import { BackButton } from "../../../Utils/FormInputs/BackButton";
import { Container } from "../../../Utils/styledComponents";
import axios from "axios";
import config from "../../../Helpers/config.json";
import { errorMessages } from "../../../Helpers/ErrorMessages";
import {
  CustomTableCell,
  StickyHeadTable,
} from "../../../Utils/Tables/StickyHeaderTable";
import { LoadingGif } from "../../../Utils/Loader";
import { formatDateTime } from "../../../Utils/ConvertDate";
import { formatAmount } from "../../../Utils/formatNumbers";
import {
  CustomPagination,
  EnhancedTableToolbar,
  MuiPaper,
} from "../../../Utils/Tables/CustomTable";
import { useSalesFilter } from "../../Invoice/CustomHooks/useSalesFilter";
import { InvoiceDateFilter } from "../../Invoice/InvoiceDateFilter";
import {
  useExcelReports,
  usePdfReports,
} from "../../Invoice/CustomHooks/useExcelReports";

const headData = [
  // { id: "date", numeric: false, disablePadding: false, label: "Date" },
  {
    id: "customer_name",
    numeric: false,
    disablePadding: false,
    label: "Customer Name",
  },
  {
    id: "total_debt",
    numeric: false,
    disablePadding: false,
    label: "Total Debt",
  },
  // {id: 'action', numeric: false, disablePadding: false, label: 'Action'},
];

const AccountReceivables = ({ actions, snackbars, match: { params } }) => {
  const filters = [
    { value: "today", text: "Today" },
    { value: "yesterday", text: "Yesterday" },
    { value: "this_week", text: "This Week" },
    { value: "this_month", text: "This Month" },
    { value: "custom", text: "Custom" },
  ];

  const storage = JSON.parse(sessionStorage.getItem("total_count"));
  const item_count = storage ? storage : 0;
  const count = () => +item_count || 0;
  const [data, setData] = React.useState({ debtors: [], loading: "idle" });
  const [submitted, setSubmitted] = React.useState(false);
  const [isSubmitted, setIsSubmitted] = React.useState("idle");
  const [searchValue, setSearchValue] = useState("");
  const [totalPageCount, setTotalPageCount] = useState(0);
  const [activePage, setActivePage] = useState(1);
  const [date, setDate] = useState({
    start_date: "",
    end_date: "",
    filter_by: "today",
  });
  const { debtors, loading } = data;
  const { component } = params;

  const isCustomer = component === "customer";

  const { filter_by, start_date, end_date } = date;

  const isResolved = isSubmitted === "resolved";

  const retrieveDebtors = useCallback(
    (from_date, to_date) => {
      const isCustom =
        filter_by === "custom"
          ? { start_date: from_date, end_date: to_date }
          : {};
      setData({ ...data, loading: "pending" });
      axios
        .get(`${config.epharmUrl}/Cinvoice/debtors`, {
          params: {
            page_number: activePage,
            search: searchValue,
            time_filter: filter_by,
            ...isCustom,
          },
        })
        .then((res) => {
          //   console.log(res);
          const data = res.data;
          const dt = data ? data : {};
          const arr = dt.data ? dt.data : {};

          const page_count = arr.total_records ? arr.total_records : 0;
          const arrData = arr.debtors ? arr.debtors : [];

          const dataArr = arrData.map((item) => ({
            ...item,
            id: item.customer_id,
          }));
          if (activePage === 1) {
            sessionStorage.setItem("total_count", JSON.stringify(page_count));
            setTotalPageCount(page_count);
          } else {
            setTotalPageCount(count);
          }
          setData({ debtors: dataArr, loading: "success" });
        })
        .catch((err) => {
          errorMessages(err, null, actions);
          setData({ ...data, loading: "error" });
        });
    },
    [activePage, searchValue, filter_by]
  );

  useEffect(() => {
    if (filter_by === "custom" && !isResolved) {
      return;
    }
    retrieveDebtors(start_date, end_date);
  }, [retrieveDebtors]);

  const handleChangeSearch = (event) => {
    setSearchValue(event.target.value);
  };

  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setDate({ ...date, [name]: value });
    setIsSubmitted("idle");
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setSubmitted(true);
    if (start_date && end_date && start_date < end_date) {
      setIsSubmitted("resolved");
      retrieveDebtors(start_date, end_date);
    }
  };

  const closeSnackbar = () => {
    actions.snackbarActions.hideSnackBar();
  };

  const isLoading = loading === "pending";
  const isSuccess = loading === "success";
  const isError = loading === "error";

  const { open, variant, message } = snackbars;
  const emptyRows = 10 - Math.min(10, debtors.length);

  const pagination = (
    <CustomPagination
      data={debtors}
      records={10}
      activePage={activePage}
      total_count={totalPageCount}
      handleChangeNextPage={handlePageChange}
    />
  );

  const currentObj = {
    file_name: "Customer_Balance",
    url: "Cinvoice/debtors_download",
    params: {
      file_type: 1,
      download: true,
      start_date,
      end_date,
      time_filter: filter_by,
    },
  };

  const { exportExcel } = useExcelReports(actions, currentObj);

  const { exportPDF } = usePdfReports(actions, {
    url: "Cinvoice/debtors_download",
    file_name: "Customer_Balance",
    params: {
      file_type: 2,
      download: true,
      start_date,
      end_date,
      time_filter: filter_by,
    },
  });

  return (
    <div>
      <ReusableDashboardHeader
        component="Debtors"
        heading="Debtors"
        subHeading="Debtors"
        link={history.location.pathname}
      />
      <Container>
        {!isCustomer ? (
          <div className="mb-3">
            <BackButton text="Reports" to="/allreports" />
          </div>
        ) : null}
        {/* <div className="general-ledger-header mb-3">
                      <InvoiceDateFilter {...{filters,date, handleChange, handleFilter:handleSubmit, submitted}}/>
                </div> */}
        <PharmacySnackbar
          open={open}
          message={message}
          variant={variant}
          handleCloseBar={closeSnackbar}
        />
        <InvoiceDateFilter
          {...{
            filters,
            date,
            handleChange,
            handleFilter: handleSubmit,
            submitted,
          }}
        />
        <MuiPaper>
          <EnhancedTableToolbar>
            <span>
              <button
                className="btn btn-sm pharmacy-btn mr-4"
                onClick={exportExcel}
                data-testid="excel-button"
              >
                Excel
              </button>
            </span>
            <span>
              <button
                className="btn btn-sm pharmacy-info-btn mr-4"
                onClick={exportPDF}
                data-testid="pdf-button"
              >
                Pdf
              </button>
            </span>
            <TextField
              type="text"
              placeholder="search..."
              value={searchValue}
              onChange={handleChangeSearch}
              data-testid="search-text"
            />
          </EnhancedTableToolbar>
          <StickyHeadTable
            {...{
              width: "100%",
              data: debtors,
              columns: headData.filter((item) => Boolean(item)),
              testId: "debtors-table",
            }}
          >
            {isLoading ? (
              <TableRow>
                <TableCell align="center" {...{ colSpan: headData.length }}>
                  <LoadingGif />
                </TableCell>
              </TableRow>
            ) : null}
            {isSuccess ? (
              debtors.length > 0 ? (
                <>
                  {debtors.map((row, index) => {
                    console.log("debtors", row);
                    return (
                      <TableRow tabIndex={-1} key={index}>
                        {/* <CustomTableCell>
                          {formatDateTime(row.date)}
                        </CustomTableCell> */}
                        <CustomTableCell>
                          <Link
                            to={{
                              pathname: `/invoicereportdetails/${row.customer_id}/${row.customer_name}`,
                            }}
                          >
                            {row.customer_name}
                          </Link>
                        </CustomTableCell>
                        <CustomTableCell>
                          {row["Total Debt"]
                            ? formatAmount(row["Total Debt"])
                            : row["Total Debt"]}
                        </CustomTableCell>
                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && totalPageCount <= 0 && (
                    <TableRow style={{ height: 43 * emptyRows }}>
                      <TableCell colSpan={headData.length} />
                    </TableRow>
                  )}
                </>
              ) : (
                <TableRow>
                  <TableCell align="center" {...{ colSpan: headData.length }}>
                    No records found
                  </TableCell>
                </TableRow>
              )
            ) : null}
            {isError ? (
              <TableRow>
                <TableCell align="center" {...{ colSpan: headData.length }}>
                  The server did not return a valid response
                </TableCell>
              </TableRow>
            ) : null}
          </StickyHeadTable>
          {totalPageCount > 0 ? pagination : null}
        </MuiPaper>
      </Container>
    </div>
  );
};

function mapStateToProps(state) {
  return { snackbars: state.snackbars };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      snackbarActions: bindActionCreators(snackbarActions, dispatch),
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AccountReceivables);
