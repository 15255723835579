import React from 'react';
import {
    CustomPagination,
    EnhancedTableHead,
    EnhancedTableToolbar,
    MuiPaper
} from "../../Utils/Tables/CustomTable";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import {dateStringConvert, titleCase} from "../../Utils/titleCaseFunction";
import ReusableDashboardHeader from "../../Containers/Dasboard/ReusableDashboardHeader";
import {history} from "../../Helpers/history";
import {Container} from "../../Utils/styledComponents";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {bindActionCreators} from "redux";
import {faEye} from "@fortawesome/free-solid-svg-icons/faEye";
import * as snackbarActions from "../../Redux/Actions/SnackbarActions/SnackbarActions";
import {connect} from "react-redux";
import CustomDialog from '../../Utils/Dialog/CustomDialog';
import { usePaymentVouchers, usePaymentVoucherReceipt } from './CustomHooks/usePaymentVouchers';
import { usePrint } from '../../Utils/Templates/usePrint';
import CompanyAddress from '../../Utils/CompanyAddress';
import TextField from "../../Utils/FormInputs/TextField"
import {LoadingGif} from "../../Utils/Loader";

const headData = [
    {id: 'sl', numeric: false, disablePadding: false, label: 'Sl'},
    {id: 'date', numeric: false, disablePadding: false, label: 'Date'},
    {id: 'voucher_number', numeric: false, disablePadding: false, label: 'Voucher #'},
    {id: 'voucher_type', numeric: false, disablePadding: false, label: 'Voucher Type'},
    {id: 'vendor_name', numeric: false, disablePadding: false, label: 'Vendor / Supplier Name'},
    {id: 'action', numeric: false, disablePadding: false, label: 'Action'},
];

export const PaymentVoucherReceipt = ({voucher_no, actions, entity_type}) => {

    const { voucherDetails } = usePaymentVoucherReceipt(voucher_no, actions, entity_type);

    const {
        voucher_date,
        voucher_type,
        total_voucher_amount,
        paid_by,
        vendor_name,
        narrative,
    } = voucherDetails;

    return (
        <div style={{borderRadius:'20px', padding:'20px'}}>
            
            {/** Company Details */}
            <CompanyAddress/>
            <h5 style={{textAlign:'center', fontWeight:'600', marginBottom:'20px'}}>PAYMENT VOUCHER</h5>

            {/** Payment mode and general details */}
            <div style={{display:'flex'}}>



                <div style={{width: '50%'}}>
                <div style={{width:'80%'}}>
                    <table className='payment-voucher-table mb-2 right-align-table'>
                        <tbody>
                            <td style={{width:'40%', fontWeight:'600'}}>DATE:</td>
                            <td>{dateStringConvert(voucher_date)}</td>
                        </tbody>
                    </table>
              
                    <table className='payment-voucher-table mb-2 right-align-table'>
                        <tbody>
                            <td style={{width:'40%', fontWeight:'600'}}>PV NUMBER:</td>
                            <td>{voucher_no}</td>
                        </tbody>
                    </table>

                    <table className='payment-voucher-table mb-2 right-align-table'>
                        <tbody>
                            <td style={{width:'40%', fontWeight:'600'}}>VOUCHER TYPE:</td>
                            <td>{voucher_type}</td>
                        </tbody>
                    </table>
                    </div>

                </div>


                <div style={{width:'50%'}}>
                    <div style={{width:'80%'}}>
                    {/*<h6 style={{fontWeight:'600'}}>{`PAYMENT MODE (TICK APPROPRIATE)`}</h6>

                    <table style={{width:'100%'}}>
                        <tbody>
                            <tr>
                                <td style={{border:'2px solid black', padding:'5px', fontWeight:'600', width:'30%'}}>CASH</td>
                                <td style={{border:'2px solid black', padding:'5px', fontWeight:'600'}}>CHEQUE</td>
                            </tr>
                            <tr>
                                <td></td>
                                <td style={{border:'2px solid black', padding:'5px', fontWeight:'600'}}>CHEQUE NO:</td>
                            </tr>                       
                        </tbody>
                    </table> */}

                    <table className='payment-voucher-table mb-2' style={{width:'100%'}} >
                        {/*<thead>
                            <th colSpan={2} style={{fontWeight:'600'}}>DOMINATING CURRENCY:</th>
                        </thead> */}
                        <tbody>
                            <td style={{fontWeight:'600', width:'40%'}}>AMOUNT IN FIGURES:</td>
                            <td style={{textAlign:'right'}}><span>{parseInt(total_voucher_amount).toLocaleString("en-US")}</span></td>
                        </tbody>
                    </table>


                   
                    <table className='payment-voucher-table mb-2 right-align-table'>
                        <tbody>
                            <td style={{width:'40%', fontWeight:'600'}}>BRANCH NAME:</td>
                            <td></td>
                        </tbody>
                    </table>
                    </div>
                </div> 

            </div>

            <table style={{width:'100%', marginTop:'25px'}}>
                <tbody>
                    <tr>
                        <td style={{width:'15%', fontWeight:'600'}}>{'PAYEE / VENDOR NAME:'}</td>
                        <td style={{borderBottom:'2px solid black'}}>{vendor_name}</td>
                    </tr>
                </tbody>
            </table>

            {/** Narrative */}
            <h6 style={{fontWeight:'600', marginTop:'30px'}}>NARRATIVE / DESCRIPTION FOR THE PAYMENT:</h6>
            {narrative?.length > 3 ? 
                narrative.map((description) => (
                    <p style={{borderBottom:'2px solid black', marginTop:'30px'}}>{description}</p>
                ))
            : narrative?.length > 0 ?
                <>
                    <p style={{borderBottom:'2px solid black', marginTop:'30px'}}>{narrative[0]}</p>
                    <p style={{borderBottom:'2px solid black', marginTop:'30px'}}>{narrative[1] ? narrative[1] : ''}</p>
                    <p style={{borderBottom:'2px solid black', marginTop:'30px'}}>{narrative[2] ? narrative[2] : ''}</p>
                </>
            :
                <>
                    <p style={{borderBottom:'2px solid black', marginTop:'30px'}}></p>
                    <p style={{borderBottom:'2px solid black', marginTop:'30px'}}></p>
                    <p style={{borderBottom:'2px solid black', marginTop:'30px'}}></p>
                </>
            }
            

            {/** Names and Signatures */}
            {/*<table className='payment-voucher-table-borderless' style={{width:'100%', marginTop:'60px'}}>
                <thead>
                    <tr>
                        <th></th>
                        <th style={{fontWeight:'600'}}>PREPARED BY / PAID BY:</th>
                        <th style={{fontWeight:'600'}}>APPROVED BY:</th>
                        <th style={{fontWeight:'600'}}>RECEIVED BY:</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td style={{fontWeight:'600'}}>NAME:</td>
                        <td>{paid_by}</td>
                        <td></td>
                        <td></td>
                    </tr>
                    <tr>
                        <td style={{fontWeight:'600'}}>SIGNATURE:</td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>
                    <tr>
                        <td style={{fontWeight:'600'}}>DATE:</td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>
                </tbody>
            </table> */}
        </div>
    )
}


const PaymentVouchers = (props) => {
    const {actions} = props;

    const {
        receiptDialog, handleOpenReceiptDialog, handleCloseReceiptDialog,
        currentVoucherNumber, paymentVouchers, totalPageCount, activePage,
        handlePageChange, handleChange, isLoading, isError, isSuccess,
        searchValue, currentVoucherType
    } = usePaymentVouchers(actions);

    const {handlePrint, fontSize, componentRef} = usePrint('');

    return (
        <div className='journals'>
            <ReusableDashboardHeader component="Payment Vouchers" heading="Accounting"
                                     subHeading="Payment Vouchers" link={history.location.pathname}/>
            <CustomDialog isOverflow={false} maxWidth={"lg"}  open={receiptDialog} title={'PAYMENT VOUCHER'}
                handleClose={handleCloseReceiptDialog} handlePrint={handlePrint}>
                <div ref={componentRef} style={{padding:'16px 30px'}}>
                    <style>
                        {fontSize()}
                    </style>
                    <PaymentVoucherReceipt voucher_no={currentVoucherNumber} actions={actions} entity_type={currentVoucherType}/>
                </div>
            </CustomDialog>

            <Container>
                <MuiPaper>
                    {/*
                    <div className="p-4 ml-2">
                        <div className="row">
                            <div className="col-md-4">
                                <Form.Group as={Row}>
                                    <Label name='Filter by Voucher Type:' sm={4} style={{marginTop: '10px'}}/>
                                    <Col sm={7}>
                                        <AutoCompleteControlled options={voucher_types} handleChange={handleChangeFilter} value_text='value' label_text='label'
                                          medicine_value={filter_voucher_type} placeholder='Select Voucher Type...' />
                                    </Col>
                                </Form.Group>
                            </div>
                        </div>
                    </div> 
                    */}

                    <EnhancedTableToolbar>
                        <TextField type='text' placeholder='Search...' onChange={handleChange} value={searchValue}/>
                    </EnhancedTableToolbar>

                    <TableContainer>
                        <Table
                            aria-labelledby="tableTitle"
                            size='medium'
                            aria-label="enhanced table"
                        >
                            <EnhancedTableHead headCells={headData}/>
                                <TableBody>
                                    {isLoading ? <TableRow><TableCell colSpan={headData.length} align='center'>
                                        <LoadingGif/>
                                        </TableCell></TableRow>: null}
                                    {isSuccess ? paymentVouchers.length > 0 ?  paymentVouchers.slice(0, 10)
                                        .map((d, index) => {
                                            const sl = (activePage-1) * 10 + index + 1 ;
                                            return (
                                                <TableRow
                                                    hover
                                                    role="checkbox"
                                                    tabIndex={-1}
                                                    key={index}
                                                    className='mui-table-row'>
                                                    <TableCell>{sl}</TableCell>
                                                    <TableCell data-testid={"payment_date-"+index}>
                                                        <span>{dateStringConvert(d.date_added)}</span>
                                                    </TableCell>
                                                    <TableCell data-testid={"voucher_id-"+index}>
                                                        <span>{d.voucher_id}</span>
                                                    </TableCell>
                                                     <TableCell data-testid={"voucher_type-"+index}>
                                                        <span>{d.voucher_type}</span>
                                                    </TableCell>
                                                    <TableCell data-testid={"supplier-"+index} component="th" scope="row" padding="default"
                                                               className='mui-table-cell'>
                                                        <span>{titleCase(d.name ? d.name : d.vendor_name ? d.vendor_name :  '')}</span>
                                                    </TableCell>                                
                                                    <TableCell>
                                                        <button data-testid={"view_payment_voucher-"+index} style={{borderRadius:'50px'}}
                                                            onClick={()=>handleOpenReceiptDialog(d.voucher_id, d.voucher_type)}
                                                            className="btn btn-sm pharmacy-gray-btn mr-1">
                                                            <FontAwesomeIcon icon={faEye}/>
                                                        </button>
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        }) : <TableRow>
                                        <TableCell colSpan={headData.length} align='center'>No records found</TableCell>
                                    </TableRow>: null}
                                    {isError ? <TableRow>
                                        <TableCell colSpan={headData.length} align='center'>The server did not return a valid response</TableCell>
                                    </TableRow>:null}
                                </TableBody>
                        </Table>
                    </TableContainer>
                    <CustomPagination data={paymentVouchers} activePage={activePage} total_count={totalPageCount}
                    records={10} handleChangeNextPage={handlePageChange}/>
                </MuiPaper>
            </Container>
        </div>
    )
}

function mapStateToProps(state) {
    return {
        snackbars: state.snackbars
    }

}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            snackbarActions: bindActionCreators(snackbarActions, dispatch)
        }
    }

}

export default connect(mapStateToProps, mapDispatchToProps)(PaymentVouchers);