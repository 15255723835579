/* eslint-disable no-unused-expressions */
import React, {Component} from "react";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faTrash} from '@fortawesome/free-solid-svg-icons/faTrash';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Button from 'react-bootstrap/Button';
import InputGroup from 'react-bootstrap/InputGroup'
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as invoiceActions from "../../Redux/Actions/InvoiceActions/PosInvoiceActions";
import Dialog from "@material-ui/core/Dialog";
import {DialogContent, DialogTitle} from "../../Utils/Dialog/pharmacyDialog";
import Slide from "@material-ui/core/Slide";
import * as customerActions from "../../Redux/Actions/CustomerActions/CustomerActions";
import PharmacySnackbar from "../../Utils/Snackbars/SnackbarUtil";
import * as snackbarActions from "../../Redux/Actions/SnackbarActions/SnackbarActions";
import {convertDate} from "../../Utils/titleCaseFunction";
import InvoicePrintDialog from "./InvoicePrintDialog";
import {Link} from "react-router-dom"
import { RightAlignedContainer } from "../../Utils/styledComponents";



function Transition(props) {
    return <Slide direction="up" {...props} />;
}

export class GUIPosInvoiceForm extends Component {
    constructor() {
        super();
        this.state = {
            showNewCustomer: false,
            manualCode: '',
            invoiceDate: convertDate(),
            payeeName: '',
            customer_other_address: '',
            paymentMethod: '',
            openDialog: false,
            bank_id:'',
            email: '',
            mobile: '',
            address: '',
            previous_balance: '',

        };
    }


    handleChangeCustomer = event => {
        const {name, value} = event.target;
        this.setState({[name]: value})
    };
    submitNewCustomer = event => {
        event.preventDefault();
        const {actions} = this.props;
        const formData = new FormData(this.customerForm);
        actions.customerActions.instantCustomer(formData);
        this.setState({
            customer_address: '',
            customer_mobile: '',
            customer_email: '',
            customer_name: '',
            previous_balance: ''
        })

    };
    handleOpenDialog = () => {
        this.setState(prevState => ({
            openDialog: !prevState.openDialog
        }))
    };
    handleCloseDialog = () => {
        this.setState({openDialog: false})
    };
    handleChangeFormFields = event => {
        const {name, value} = event.target;
        this.setState({[name]: value});

    };
    handleCloseSnackbar = () => {
        const {actions} = this.props;
        actions.snackbarActions.hideSnackBar();

    };

    render() {
        const {manualCode, openDialog, customer_name, email, mobile, address, previous_balance} = this.state;
        const {open, variant, message} = this.props.snackbars;
        const {rows, total_discount, grand_total_price, change, n_total,openInvoice,handleCloseInvoiceDialog,
            paid_amount, invdcount, total_tax, due_amount, handleDeleteRow, handleChangeBatch,handlePrint,
            handleChangeQuantity, handleChangeDiscount, handleChangeBalance,handleSubmitInvoice,submitted,
            selectCustomer, previous, showCustomers, handleRetrieveCustomers, handleClickCustomer,customers
        } = this.props;
        return (
            <div style={{marginBottom: "20px"}} id="gui-pos-invoice-form">
                <div className="card">
                    <div className="m-2">
                        <Dialog
                            onClose={this.handleCloseDialog}
                            aria-labelledby="customized-dialog-title"
                            open={openDialog}
                            style={{width: "100%"}}
                            TransitionComponent={Transition}
                        >
                            <DialogTitle id="customized-dialog-title" onClose={this.handleCloseDialog}>
                                <h6 style={{fontSize: 16, marginBottom: -10}} className="text-center">Add Customer</h6>
                            </DialogTitle>
                            <DialogContent style={{width: "500px"}}
                                           className="text-center">
                                <div className="my-3">
                                    <Form onSubmit={this.submitNewCustomer} ref={el => this.customerForm = el} autoComplete="off">
                                        <Form.Group as={Row} controlId="formGridEmail">
                                            <Form.Label column sm="3">Customer Name</Form.Label>
                                            <Col sm="6">
                                                <Form.Control type="text" value={customer_name} name="customer_name"
                                                              onChange={this.handleChangeCustomer}/>
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} controlId="formGridPassword">
                                            <Form.Label column sm="3">Customer Email</Form.Label>
                                            <Col sm="6">
                                                <Form.Control type="email" value={email} name="email"
                                                              onChange={this.handleChangeCustomer}/>
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} controlId="formGridPassword">
                                            <Form.Label column sm="3">Customer Mobile</Form.Label>
                                            <Col sm="6">
                                                <Form.Control type="text" value={mobile} name="mobile"
                                                              onChange={this.handleChangeCustomer}/>
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} controlId="formGridEmail">
                                            <Form.Label column sm="3">Customer Address</Form.Label>
                                            <Col sm="6">
                                                <Form.Control as="textarea" value={address}
                                                              name="address"
                                                              onChange={this.handleChangeCustomer}/>
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} controlId="formGridPassword">
                                            <Form.Label column sm="3">Previous Balance</Form.Label>
                                            <Col sm="6">
                                                <Form.Control type="text" value={previous_balance}
                                                              name="previous_balance"
                                                              onChange={this.handleChangeCustomer}/>
                                            </Col>
                                        </Form.Group>
                                        <div className="float-right">
                                            <button className="btn pharmacy-btn-dismiss mr-2"
                                                    onClick={this.handleCloseDialog}>Close
                                            </button>
                                            <button className="btn pharmacy-btn">Save Customer</button>
                                        </div>
                                    </Form></div>
                            </DialogContent>
                        </Dialog>
                        <PharmacySnackbar open={open} variant={variant} message={message}
                            handleCloseBar={this.handleCloseSnackbar}/>
                        <InvoicePrintDialog openDialog={openInvoice} handleCloseDialog={handleCloseInvoiceDialog}
                                             message="Invoice">
                                <RightAlignedContainer>
                                    <button onClick={handlePrint} type="button" className="btn pharmacy-info-btn mr-3">Yes</button>
                                    <Link to="/manageinvoice">
                                        <button type="button" className="btn pharmacy-grey-btn ">No</button>
                                    </Link>
                                </RightAlignedContainer>                
                        </InvoicePrintDialog>
                        <Form onSubmit={handleSubmitInvoice}  autoComplete="off">
                            <Form.Row>
                                <Form.Group as={Col} controlId="formGridEmail">
                                    <Form.Label>Bar Code or QR Scan here</Form.Label>
                                    <Form.Control type="text" placeholder="Bar Code or QRcode scan here"/>
                                </Form.Group>


                                <Form.Group as={Col} controlId="formGridPassword">
                                    <Form.Label>Manual input bar Code</Form.Label>
                                    <Form.Control type="text" placeholder="Manual Input Bar code"
                                                   value={manualCode} name="manualCode"
                                                  onChange={this.handleChangeFormFields}/>
                                </Form.Group>
                                <InputGroup>

                                </InputGroup>
                            </Form.Row>
                            <Form.Row>
                                <Form.Group as={Col} controlId="formGridAddress1">
                                    <Form.Label>Customer Name/Phone</Form.Label>
                                    <Form.Control type="text" value={selectCustomer}
                                                  onChange={handleRetrieveCustomers}/>
                                    {showCustomers &&
                                    <Form.Control as="select" multiple className="width-max-content">
                                        {customers.map((customer, index) => (
                                            <option key={index}
                                                    value={customer.value}
                                                    onClick={handleClickCustomer}>{customer.label}</option>))}
                                    </Form.Control>}
                                </Form.Group>
                                <Form.Group as={Col}>
                                    <Form.Label/><br/>
                                    <ButtonGroup size="md"><Button
                                        onClick={this.handleOpenDialog}>+</Button></ButtonGroup>
                                </Form.Group>
                            </Form.Row>

                            <div className="table-responsive">
                                <table className="table table-bordered table-sm">
                                    <thead>
                                    <tr>
                                        <td width="15%">Item Information</td>
                                        <td>Batch</td>
                                        <td>Ava.Qty</td>
                                        <td>Expiry</td>
                                        <td>Qty</td>
                                        <td>Price(UGX)</td>
                                        <td>Discount%</td>
                                        <td>Total</td>
                                        <td>Action</td>

                                    </tr>
                                    </thead>
                                    <tbody>
                                    {rows.map((item, index) => {
                                        const product = !item.product_details ? {} :item.product_details;
                                        return(
                                        <tr key={index}>
                                            <td width="20%">
                                                <Form.Control type="text"
                                                              value={product.product_name}
                                                              name="product_name"/>
                                                <Form.Control type="text"
                                                              value={product.product_id}
                                                              name="product_id"
                                                              style={{display: "none"}}/>
                                            </td>
                                            <td width="15%">
                                                <Form.Control as="select" value={item.batch_id} name="batch_id"
                                                              onChange={(e)=>handleChangeBatch(e,index)}>
                                                    <option>Select...</option>
                                                    {item.batch && item.batch.map((bat, idx) => (
                                                        <option key={idx} value={bat.batch_id}>{bat.batch_id}</option>
                                                    ))}
                                                </Form.Control>
                                            </td>
                                            <td width="5%">
                                                <Form.Control type="text" value={item.available_quantity}
                                                              name="available_quantity" disabled={true}
                                            />
                                                
                                            </td>
                                            <td>
                                                <Form.Control type="text" value={item.expire_date} name="expire_date" disabled={true}/>
                                            </td>
                                            <td>
                                                <Form.Control type="text" value={item.product_quantity}
                                                              name="product_quantity"
                                                              onChange={(e) => handleChangeQuantity(e, index)}/>
                                            </td>
                                            <td>
                                                <Form.Control type="text" value={product.price} disabled/>
                                            </td>
                                            <td>
                                                <Form.Control type="number"
                                                              value={product.discount}
                                                              name="discount"
                                                              onChange={(e) => handleChangeDiscount(e, index)}/>
                                                <Form.Control type="text" value={product.discount_type}
                                                              name="discount_type" style={{display: "none"}}/>
                                            </td>
                                            <td>
                                                <Form.Control type="text"
                                                              // disabled={true}
                                                              value={item.total_price}
                                                              name="total_price" disabled={true}/>
                                            </td>
                                            <td>
                                                <button type="button" className="btn btn-outline-danger btn-sm"
                                                        onClick={() => handleDeleteRow(index)}>
                                                    <FontAwesomeIcon icon={faTrash}/>
                                                </button>
                                            </td>
                                        </tr>

                                        )})}

                                    </tbody>
                                </table>
                            </div>
                            <div>
                                <Form.Group as={Row} style={{display: "none"}}>
                                    <Form.Label column sm="5" className="text-right">Invoice
                                        Discount:</Form.Label>
                                    <Col sm="4">
                                        <Form.Control type="text" value={invdcount} name="invdcount"/>
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row}>
                                    <Form.Label column sm="5" className="text-right">Total Discount:</Form.Label>
                                    <Col sm="4">
                                        <Form.Control type="text" disabled value={total_discount} name="total_discount"/>
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} style={{display: "none"}}>
                                    <Form.Label column sm="5" className="text-right">Total Tax:</Form.Label>
                                    <Col sm="4">
                                        <Form.Control type="text" value={total_tax} name="total_tax"/>
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row}>
                                    <Form.Label column sm="5" className="text-right">Previous:</Form.Label>
                                    <Col sm="4">
                                        <Form.Control type="text" value={previous} name="previous" disabled={true}/>
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row}>
                                    <Form.Label column sm="5" className="text-right"> Grand Total:</Form.Label>
                                    <Col sm="4">
                                        <Form.Control type="text" value={grand_total_price} disabled name="grand_total_price"/>
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row}>
                                    <Form.Label column sm="5" className="text-right">Net
                                        Total</Form.Label>
                                    <Col sm="4">
                                        <Form.Control type="text" value={n_total} name="n_total" disabled/>
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row}>
                                    <Form.Label column sm="5" className="text-right">Paid
                                        Amount</Form.Label>
                                    <Col sm="4">
                                        <Form.Control type="text" value={paid_amount}
                                                      name="paid_amount" onChange={handleChangeBalance}/>
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row}>
                                    <Form.Label column sm="5" className="text-right">Due</Form.Label>
                                    <Col sm="4">
                                        <Form.Control type="text" value={due_amount} name="due_amount" disabled/>
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row}>
                                    <Form.Label column sm="5" className="text-right">Change:</Form.Label>
                                    <Col sm="4">
                                        <Form.Control type="text" value={change} name="change" disabled/>
                                    </Col>
                                </Form.Group>
                            </div>
                            <div className="text-right">
                            <button className="btn pharmacy-btn btn-sm" disabled={!!submitted}>{submitted ? 'Submitting...':'Submit Invoice'}</button>
                            </div>

                        </Form>
                    </div>
                </div>

            </div>

        )

    }

}

function mapStateToProps(state) {
    return {
        invoices: state.invoices,
        snackbars: state.snackbars
    }

}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            invoiceActions: bindActionCreators(invoiceActions, dispatch),
            customerActions: bindActionCreators(customerActions, dispatch),
            snackbarActions: bindActionCreators(snackbarActions, dispatch)
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(GUIPosInvoiceForm);
