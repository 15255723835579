import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPencilAlt} from "@fortawesome/free-solid-svg-icons/faPencilAlt";
import {faTrash} from "@fortawesome/free-solid-svg-icons/faTrash";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import FilterResults from 'react-filter-search';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as snackbarActions from "../../Redux/Actions/SnackbarActions/SnackbarActions";
import PharmacySnackbar from "../../Utils/Snackbars/SnackbarUtil";
import DeleteDialog from "../../Utils/DeleteDialog";
import {history} from "../../Helpers/history"
import {titleCase} from "../../Utils/titleCaseFunction";
import ReusableDashboardHeader from "../../Containers/Dasboard/ReusableDashboardHeader";
import ReusableDialog from "../../Utils/Dialog/ReusableDialog";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Label from "../../Utils/FormInputs/Label";
import Col from "react-bootstrap/Col";
import TextField from "../../Utils/FormInputs/TextField";
import {EnhancedTableHead, EnhancedTableToolbar, MuiPagination, MuiPaper} from "../../Utils/Tables/CustomTable";
import TableContainer from "@material-ui/core/TableContainer";
import {useManageUsers} from "./CustomHooks/useManageUsers";



const ManageUsers = ({actions, snackbars}) => {
    const {
        state, openDialog, openDelete, users, submitted, isSubmitted,
        loading, page, rowsPerPage, handleChangeRowsPerPage, handleChangePage, handleChange,
        handleSubmit, handleOpenDelete, handleCloseDelete, deleteUser, handleOpenDialog,
        handleCloseDialog, handleCloseSnackbar, term, handleSearch, formRef
    } = useManageUsers(actions)
    const {first_name, last_name, email, gender, date_of_birth} = state;
    const {open, message, variant} = snackbars;
    const user = JSON.parse(sessionStorage.getItem('user'));
    const singleUser = !user ? {} : user;
    const permissions = !singleUser.permission ? {} : singleUser.permission;
    const manage_users = !permissions.manage_users ? {} : permissions.manage_users;
    const add_user = !permissions.add_user ? {} : permissions.add_user;
    const headData = [
        {id: 'name', numeric: false, disablePadding: false, label: 'Name'},
        {id: 'email', numeric: false, disablePadding: false, label: 'Email'},
        {id: 'status', numeric: false, disablePadding: false, label: 'Status'},
        (manage_users.create || manage_users.update || manage_users.delete)
            ? {id: 'action', numeric: false, disablePadding: false, label: 'Action'} :
            {id: 'nonaction', numeric: false, disablePadding: false, label: ''},
    ];
    return (
        <div className='journals'>
            <ReusableDashboardHeader component='Users' heading="Settings"
                                     subHeading='Users' link='/manageusers'/>
            <div className="general-ledger-header">
                <div className="row">
                    <div className="col-md-6">
                        <h6 className='mt-2 mb-0'>Users</h6>
                    </div>
                    <div className="col-md-6">
                        <div className="text-right">
                            {add_user.create ? <button type='button' onClick={handleOpenDialog}
                                                       className='btn btn-sm pharmacy-info-btn'>Add User
                            </button> : null}
                        </div>
                    </div>
                </div>
            </div>
            <PharmacySnackbar open={open} message={message} variant={variant}
                              handleCloseBar={handleCloseSnackbar}/>
            <DeleteDialog handleClose={handleCloseDelete} openDialog={openDelete} message='user'
                          handleDelete={deleteUser}/>
            <ReusableDialog message='New User' openDialog={openDialog}
                            handleSubmit={handleSubmit} isSubmitted={isSubmitted}
                            handleClose={handleCloseDialog} formRef={formRef}
                            width='sm'>
                <Form.Group as={Row}>
                    <Label type name='First Name' sm={3} column/>
                    <Col sm={7}>
                        <TextField submitted={submitted} type="text" value={titleCase(first_name)} name="first_name"
                                   onChange={handleChange}
                                   placeholder="First name"/>
                        {(submitted && !first_name) &&
                        <div className="invalid-text">Please enter the your first name</div>}
                    </Col>
                </Form.Group>
                <Form.Group as={Row}>
                    <Label type name='Last Name' sm={3} column/>
                    <Col sm={7}>
                        <TextField submitted={submitted} type="text" value={titleCase(last_name)} name="last_name"
                                   onChange={handleChange}
                                   placeholder="Last Name"/>
                        {(submitted && !last_name) &&
                        <div className="invalid-text">Please enter your last name</div>}
                    </Col>
                </Form.Group>
                <Form.Group as={Row}>
                    <Label type name='Email' sm={3} column/>
                    <Col sm={7}>
                        <TextField submitted={submitted} type="email" value={email} name="email"
                                   onChange={handleChange}
                                   placeholder="admin@example.org"/>
                        {(submitted && !email) &&
                        <div className="invalid-text">Please enter your email</div>}
                    </Col>
                </Form.Group>
                <Form.Group as={Row}>
                    <Label name='Date of Birth' sm={3} column/>
                    <Col sm={7}>
                        <TextField type="date" value={date_of_birth} name="date_of_birth"
                                   onChange={handleChange}
                                   placeholder="Date of birth"/>
                    </Col>
                </Form.Group>
                <Form.Group as={Row}>
                    <Label type name='Gender' sm={3} column/>
                    <Col sm={7}>
                        <Form.Check type="radio" inline label="Female" name="gender" value="female"
                                    checked={gender === "female"} id="female"
                                    onChange={handleChange}/>
                        <Form.Check type="radio" inline label="Male" id="male" name="gender"
                                    value="male"
                                    checked={gender === "male"} onChange={handleChange}/>
                        {(submitted && !gender) &&
                        <div className="invalid-text">Please select gender</div>}
                    </Col>
                </Form.Group>
            </ReusableDialog>
            <MuiPaper>
                <EnhancedTableToolbar title={''}>
                    <Form.Control 
                                data-testid="search_users"
                                type="text"
                                  onChange={event => handleSearch(event.target.value)}
                                  className='form__control'
                                  placeholder="Search" style={{float: 'right'}}/>
                </EnhancedTableToolbar>
                <TableContainer>
                    <Table
                        aria-labelledby="tableTitle"
                        aria-label="enhanced table"
                    >
                        <EnhancedTableHead headCells={headData}/>
                        {loading ? <TableBody><TableRow>
                            <TableCell colSpan="4" align="center"><img src="/images/listPreloader.gif"
                                                                       alt="loader"
                                                                       style={{width: '5%'}}/></TableCell>
                        </TableRow></TableBody> : users.length > 0 ?
                            <FilterResults
                                value={term}
                                data={users}
                                renderResults={results => (
                                    <TableBody>
                                        {results.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                            .map((d, index) => {
                                                return (
                                                    <TableRow
                                                        hover
                                                        role="checkbox"
                                                        tabIndex={-1}
                                                        key={d.user_id}
                                                        className='mui-table-row'>
                                                        <TableCell data-testid={"user_name-"+index}>
                                                            <span>{`${titleCase(d.first_name)} ${titleCase(d.last_name)}`}</span>
                                                        </TableCell>
                                                        <TableCell data-testid={"user_email-"+index}>
                                                            <span>{d.email}</span>
                                                        </TableCell>
                                                        <TableCell data-testid={"user_status-"+index}>
                                                            <span>{d.status === 1 ? 'Active' : 'InActive'}</span>
                                                        </TableCell>
                                                        <TableCell>
                                                                    <span>
                                                                        {manage_users.update &&
                                                                        <button data-testid={"edit_user-"+index} disabled={d.user_type === 1}
                                                                                onClick={() => {
                                                                                    history.push(`/edituser/${d.user_id}`)
                                                                                }}
                                                                                className="btn pharmacy-info-btn btn-sm mr-1 ">
                                                                            <FontAwesomeIcon icon={faPencilAlt}/>
                                                                        </button>}

                                                                        {manage_users.delete &&
                                                                        <button data-testid={"delete_user-"+index} disabled={d.user_type === 1}
                                                                                onClick={() => handleOpenDelete(d.user_id)}
                                                                                className="btn pharmacy-btn-dismiss btn-sm  ">
                                                                            <FontAwesomeIcon icon={faTrash}/>
                                                                        </button>}

                                                                    </span>
                                                        </TableCell>
                                                    </TableRow>
                                                );
                                            })}
                                    </TableBody>
                                )}
                            />
                            : <TableBody><TableRow>
                                <TableCell colSpan="4" align="center">No records found</TableCell>
                            </TableRow></TableBody>}
                    </Table>
                </TableContainer>
                <MuiPagination data={users} rowsPerPage={rowsPerPage} page={page}
                               handleChangeRowsPerPage={handleChangeRowsPerPage}
                               handleChangePage={handleChangePage} rows={[10, 20, 30, 50, 100]} colSpan={3}/>
            </MuiPaper>
        </div>
    )


}

function mapStateToProps(state) {
    return {
        snackbars: state.snackbars
    }

}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            snackbarActions: bindActionCreators(snackbarActions, dispatch)
        }

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ManageUsers);
