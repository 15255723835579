import React, {useEffect, useState} from 'react';
import axios from 'axios';
import config from '../../../../Helpers/config.json';
import {useNewCOAccount} from "./useNewCOAccount"

const useChartOfAccounts = (actions) =>{

    const { openDialog, handleOpenDialog, handleCloseDialog,  coaProps} = useNewCOAccount(actions)
    const [accounts, setAccounts] = useState({})
    const [loading, setIsLoading] = useState('idle')
    const [expanded, setExpanded] = React.useState([]);


    useEffect(()=>{
        setIsLoading('loading')
        axios.get(`${config.epharmUrl}/accounting/chart/accounting_categories`).then(res=>{
            const data = res.data;
            const dt = data ?? {};
            const arr = dt.data ?? [];
            const items = arr.map(item=>({
                ...item,
                head_code: item.category_code,
                head_name:item.category_name,
                is_children: true,
                is_final_level:false,
                is_category:true
            }));
            const initialData = {root:items}
            setAccounts(initialData)
            setIsLoading('success')
        },err=>{
            setIsLoading('error')
        })
    },[])


    const retrieveCategoryAccounts = ( event, nodeId) =>{
        const expandingNodes = nodeId.filter(x => !expanded.includes(x));
        setExpanded(nodeId)
        if (expandingNodes[0]) {
            const _id = expandingNodes[0]
            const id = _id.split('_')[0]
            const is_category = _id.split('_')[2]
            setAccounts({...accounts,[_id]:{loading:'loading'}})
            const url = is_category !== 'undefined' ? 'category' : 'account'
            axios.get(`${config.epharmUrl}/accounting/sub_accounts/${url}/${id}`).then(res => {
                const data = res.data;
                const dt = data ?? [];
                setAccounts({...accounts, [_id]: {loading:'success',children:dt}})
            }, err => {
                setAccounts({...accounts,[_id]:{loading:'error'}})
            })
        }
    }


    const closeSnackbar = () => {
        actions.snackbarActions.hideSnackBar()
    }

    const isLoadingData  = loading === 'loading'
    const isSuccessData = loading === 'success'
    const isErrorData = loading === 'error'


    return {closeSnackbar, openDialog, handleOpenDialog, handleCloseDialog,  coaProps, retrieveCategoryAccounts,
        accounts,isLoadingData, isSuccessData, isErrorData}
}
export {useChartOfAccounts,}