import { useState, useEffect } from "react";
import axios from "axios";
import config from "../../../Helpers/config";
import { errorMessages } from "../../../Helpers/ErrorMessages";
import { CallToActionTwoTone } from "@material-ui/icons";
import { useMuiPagination } from "../../../Containers/Dasboard/DashboardTables";

export const useProductLog = (props) => {
    const [productLog, setProductLog] = useState([]);
    const [loading, setLoading] = useState(false);
    const [sortBy,  setSortBy] = useState({value: 'ALL', label: 'All'});
    const {product_id} = props.match.params;
    const {actions} = props;
    const {page, rowsPerPage, handleChangeRowsPerPage, handleChangePage} = useMuiPagination();
    const [state, setState] = useState({term: "",
    order: 'asc',
    orderBy: 'person',
    pages: [],
    pg: 1,
    records: 10,
    productList: [],
    search_value: '',
    _count: 0,
    stockPrint: [],
   })

   const action = sortBy.value.toString()

    useEffect(() => {

        axios.post(`${config.epharmUrl}/Creport/product_log`, {product_id: product_id, log_type: "SINGLE", action: action} ).then(res => {
            const data = res.data;
            setProductLog(data);
            setLoading(false);
        }).catch(err => {
        });
    }, [product_id, action ]);

    const handleInputChange = (value) => {
        setSortBy(value);
        
    };

    return { productLog, loading, handleInputChange, sortBy, state, page, rowsPerPage, handleChangeRowsPerPage, handleChangePage };
}
