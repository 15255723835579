import React, {useEffect, useState} from 'react'
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import * as snackbarActions from "../../../Redux/Actions/SnackbarActions/SnackbarActions"
import PharmacySnackbar from "../../../Utils/Snackbars/SnackbarUtil";
import {MedicineContainer, ProfitContainer} from '../../../Utils/styledComponents';
import {BalanceSheetData,  CustomCell} from '../../../Utils/AccountingUtils/CoaList';
import { usePrint } from '../../../Utils/Templates/usePrint';
import { BackButton } from '../../../Utils/Buttons/DataExportationButton';
import {PrintPdfButtons} from "./PrintPdfButtons";
import Card  from '@material-ui/core/Card';
import { formatDate } from '../../../Utils/ConvertDate';
import { AccountingDateFilter } from '../../../Utils/AccountingUtils/AccountingDateFilter';
import {NoTransaction} from "../COA/ReusableJournalTable";
import { LoadingGif } from '../../../Utils/Loader';
import TableRow from "@material-ui/core/TableRow";
import TableHead from '@material-ui/core/TableHead';
import ReusableDashboardHeader from "../../../Containers/Dasboard/ReusableDashboardHeader";
import {history} from "../../../Helpers/history";
import {useDates} from "../Expenses/hooks/useDates";
import axios from "axios";
import config from "../../../Helpers/config.json";
import {errorMessages} from "../../../Helpers/ErrorMessages";
import {ReportsTreeView} from "../../../Utils/AccountingUtils/ReportsTreeView";
import {useCompany} from "../../../Context/company-context";


const BalanceSheet = ({actions, snackbars}) => {

    const [data, setData] = useState(null);
    const [date, handleChangeDate] = useDates();
    const [loading, setLoading] = useState('idle')
    const [submitted, setSubmitted] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState('idle');
    const {from_date, to_date} = date;

    const formatData = (data={}) =>{
        let arr = []
        for (let item in data){
            const obj = {
                head_name: item,
                head_level:-1,
                children: data[item]
            }
            arr.push(obj)
        }
        return arr
    }

    useEffect(() => {
        const formData = new FormData();
        formData.append('opening_date', from_date);
        formData.append('closing_date', to_date)
        setLoading('pending')
        axios.get(`${config.epharmUrl}/accounting/reports/get_balance_sheet`).then(res => {
            const data = res.data;
            const dt = data ? data : {};
            const dat = dt.data ? dt.data : {}
            const obj = formatData(dat)
            setData(obj)
            setLoading('success')
        }).catch(err => {
            errorMessages(err, null, actions)
            setLoading('error')
        })

    }, [])



    const handleSubmit = (event) =>{
        setSubmitted(true)
        event.preventDefault()
        setLoading('pending')
        setIsSubmitted('pending')
        axios.get(`${config.epharmUrl}/accounting/reports/get_balance_sheet`, {params:{start_date:from_date, end_date:to_date}}).then(res => {
            const data = res.data;
            const dt = data ? data : {};
            const dat = dt.data ? dt.data : {}
            const obj = formatData(dat)
            setData(obj)
            setLoading('success')
            setIsSubmitted('resolved')
        }).catch(err => {
            errorMessages(err, null, actions)
            setLoading('error')
            setIsSubmitted('rejected')
        })
    }

    const closeSnackbar = () => {
        actions.snackbarActions.hideSnackBar()
    }

    const isLoading = loading === 'pending';
    const isSuccess = loading === 'success';
    const isError = loading === 'error';

    const isPending = isSubmitted === 'pending';



    const {open, variant, message} = snackbars;


    const balanceSheet = data ? data : [];

    const {componentRef, handlePrint} = usePrint( `Balance Sheet(${to_date})`)

    const {state} = useCompany();
    const {logo} = state

    const header = (
        <TableHead>
            <TableRow>
                <CustomCell><strong></strong></CustomCell>
                <CustomCell align='center'><strong>Amount(UGX)</strong></CustomCell>
            </TableRow>
        </TableHead>
    )

    const printDoc = (
        <div  ref={componentRef}>
            <ProfitContainer>
                <table className="table table-sm table-borderless prescription-table">
                    <tr>
                        <td align="center"><img style={{marginTop:20,width:'100px'}}  src={logo} alt="mpeke logo"/></td>
                    </tr>
                    <tr>
                        <td align='center'><h5><strong>Balance Sheet</strong></h5></td>
                    </tr>
                    <tr>
                        <td align='center'><h6><strong>{formatDate(to_date)}</strong></h6></td>
                    </tr>
                </table>
                <BalanceSheetData  {...{ data:balanceSheet, isPrint:true,header}}/>
                <p style={{textAlign: "left", fontStyle: "italic"}}  className="mpeke-footer">Powered by Mara Scientific</p>
            </ProfitContainer>
        </div>
    )


    return (
        <div className='journals'>
            <ReusableDashboardHeader component='Balance Sheet' heading="Accounting"
                                     subHeading='Balance Sheet' link={history.location.pathname}/>
            <PharmacySnackbar message={message} open={open} handleCloseBar={closeSnackbar} variant={variant}/>
            <MedicineContainer>
                <div className='my-3'>
                    <BackButton to='/allreports' text='Reports'/>
                </div>
                <AccountingDateFilter {...{from_date,to_date, handleChange:handleChangeDate,
                    submitted,handleSubmit,isPending}}/>
                {isLoading ? <div className='text-center mt-5'>
                    <LoadingGif/>
                </div>:null}
                {isSuccess ? balanceSheet.length > 0 ?
                    <Card>
                        <div style={{padding:'8px 16px', borderBottom:'1px solid rgba(224, 224, 224, 1)'}}>
                            <PrintPdfButtons   handlePrint={handlePrint}/>
                        </div>
                        <div className='p-3'>
                            <div  id="balance-sheet-pdf" style={{overflow: "hidden"}}>
                                <table className="table table-sm table-borderless prescription-table">
                                    <thead>
                                    <tr>
                                        <td align="center"><img style={{marginTop:20,width:'100px'}}  src={logo} alt="mpeke logo"/></td>
                                    </tr>
                                    </thead>
                                    <tr>
                                        <td align='center'><h5><strong>Balance Sheet</strong></h5></td>
                                    </tr>
                                    <tr>
                                        <td align='center'><h6><strong>{formatDate(to_date)}</strong></h6></td>
                                    </tr>
                                </table>
                                <ReportsTreeView  {...{ data:balanceSheet, reports:true,header, start_date:from_date, end_date:to_date}}/>
                                <p style={{textAlign: "center", fontStyle: "italic"}}>Powered by Mara Scientific</p>
                            </div>
                        </div>
                    </Card>: <NoTransaction text='transactions'/>: null}
                {isError ? <div className="mt-3 text-center">The server did not return a valid response</div>:null}
                <div style={{display:'none'}}>
                    {printDoc}
                </div>
            </MedicineContainer>
        </div>
    );
}

function mapStateToProps(state) {
    const {snackbars} = state;
    return {snackbars}
}

function mapDispatchToProps(dispatch) {
    return {actions: {snackbarActions: bindActionCreators(snackbarActions, dispatch)}}
}

export default connect(mapStateToProps, mapDispatchToProps)(BalanceSheet);
