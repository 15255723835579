import Form from "react-bootstrap/Form";
import Label from "../../Utils/FormInputs/Label";
import Col from "react-bootstrap/Col";
import TextField from "../../Utils/FormInputs/TextField";
import {titleCase} from "../../Utils/titleCaseFunction";
import Textarea from "../../Utils/FormInputs/Textarea";
import React from "react";

function ManufacturerForm({submitted, state, handleChange, children}) {
    const {manufacturer_name, address, mobile, details} = state;

    return (
        <form autoComplete='off'>
            <Form.Group as={Col}>
                <Label name='Supplier Name' htmlFor='supplierName' type/>
                <TextField data-testid="supplier_name" submitted={submitted} type="text" value={titleCase(manufacturer_name)} id='supplierName'
                               name="manufacturer_name" onChange={handleChange}/>
                    {(submitted && !manufacturer_name) ?
                        <div className="invalid-text">Supplier name is required</div> : null}
            </Form.Group>

            <Form.Group as={Col}>
                <Label name='Mobile' type htmlFor='mobile'/>
                    <TextField data-testid="supplier_mobile" submitted={submitted}  type="text" value={mobile} id='mobile'
                               name="mobile" onChange={handleChange}/>
                    {(submitted && !mobile) ?
                        <div className="invalid-text">Mobile number is required</div> : null}
            </Form.Group>

            <Form.Group as={Col}>
                <Label name='Address'   htmlFor='address' />
                    <Textarea data-testid="supplier_address" value={address} id='address'
                              name="address" onChange={handleChange}/>
                    {/*{(submitted && !mobile) ?*/}
                    {/*    <div className="invalid-text">Address is required</div> : null}*/}
            </Form.Group>

            <Form.Group as={Col}>
                <Label name='Details' htmlFor='details'/>
                <Textarea data-testid="supplier_details" value={details} id='details'
                              name="details" onChange={handleChange}/>
                {/*{(submitted && !details) ?*/}
                {/*    <div className="invalid-text">Details is required</div> : null}*/}
            </Form.Group>
            {children}
        </form>
    )
}
export  {ManufacturerForm}

