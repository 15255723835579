import React from 'react';
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faList} from "@fortawesome/free-solid-svg-icons/faList";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import * as snackbarActions from "../../Redux/Actions/SnackbarActions/SnackbarActions";
import PharmacySnackbar from "../../Utils/Snackbars/SnackbarUtil";
import ReusableDashboardHeader from "../../Containers/Dasboard/ReusableDashboardHeader";
import {history} from "../../Helpers/history";
import Card from '@material-ui/core/Card';
import {useAddMedicine} from "./CustomHooks/useAddMedicine";
import {AddProductForm} from "./AddProductForm";
import {RightAlignedContainer, Container} from "../../Utils/styledComponents";
import {ReusableProductsList} from "../../Utils/List/ReusableProductsList";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import {StyledRemoveIcon} from "../Stock/StockAdjustment";
import DetailsDialog from "../../Utils/Dialog/DetailsDialog";
import CsvDialog from "../../Utils/CsvDialog";
import { CSVLink } from 'react-csv';
import {CategoryForm, TypeForm, UnitForm} from "./MedicineMetaComponents";
import {faPlus} from "@fortawesome/free-solid-svg-icons/faPlus";
import { faFileUpload } from '@fortawesome/free-solid-svg-icons';
import Alert from '@material-ui/lab/Alert';
import {BlockNavigationComponent} from "../../Utils/ReusableComponents/BlockNavigationComponent";

const  AddMedicine  = ({actions, match,snackbars}) => {
    const {product_id} = match.params;
    const {productProps, handleCloseSnackbar, csvDialogProps, isBlocking} = useAddMedicine(actions,product_id)
    const {addProduct, addedProducts, isSubmitted, handleSaveMedicine, addAnotherProduct, isShowForm,handleRemoveProduct, cancelAdd,
    handleSaveSingleProduct,unitProps, categoryProps,typeProps} = productProps;
    const {
        handleChangeProductCsv, handleUploadCsv, csvdialog, handleOpenCSVDialog, 
        handleCloseCSVDialog, fetchData, allProducts, csvLink, error_message
    } = csvDialogProps;
    const { openDialog, handleCloseDialog, handleOpenDialog} = unitProps;
    const { openDialog:openCategory, handleCloseDialog:closeCategoryDialog, handleOpenDialog:openCategoryDialog} = categoryProps;
    const { openDialog:openType, handleCloseDialog:closeTypeDialog, handleOpenDialog:openTypeDialog} = typeProps;

    const isPending = isSubmitted === 'pending';
    const {open, variant, message} = snackbars;
    const title = product_id ? 'Edit Product' : 'Add Product';

    const addProducts = (
        <>
            {isShowForm || addedProducts.length === 0 ? <Card className="p-3 general-card">
                <AddProductForm {...productProps}>
                    <div className='text-right'>
                        <button type="button" data-testid="submit_product" onClick={addProduct} className="btn pharmacy-info-btn small-btn btn-sm px-3 mr-3">Add product</button>
                        {addedProducts.length > 0 ? <button type="button" onClick={cancelAdd} className="btn pharmacy-btn-dismiss small-btn btn-sm px-3">Cancel</button>:null}
                    </div>
                </AddProductForm>

            </Card>: null}
            <div className='my-3'>
                {addedProducts.map((item, index)=>{
                    const primaryText = (
                        <span><strong>{item.product_name}</strong> {item.strength ? `${item.strength}` : ''}</span>
                    )
                    const secondaryText = (
                        <>
                            {item.unit ? <span style={{display:'inline-block', marginRight:'12px'}}>Unit: <strong>{item.unit ? item.unit : ''}</strong></span>:null}
                            {item.category_name ? <span style={{display:'inline-block', marginRight:'12px'}}>Category: <strong>{item.category_name ? item.category_name : '' }</strong></span>: null}
                            {item.type_name ? <span style={{display:'inline-block', marginRight:'12px'}}>Type: <strong>{item.type_name ? item.type_name : ''}</strong></span>:null}
                        </>
                    )
                    return(
                        <ReusableProductsList key={index} {...{primaryText, secondaryText}}button={
                            <>
                                <Tooltip title='Delete'>
                                    <IconButton onClick={()=>handleRemoveProduct(index)}>
                                        <StyledRemoveIcon/>
                                    </IconButton>
                                </Tooltip>
                            </>
                        }/>
                    )
                })}
            </div>
            {!isShowForm ?  <button data-testid="add_another_product"  type="button" onClick={addAnotherProduct} className="btn pharmacy-info-btn btn-sm small-btn px-3">Add another product</button>:null}
            {addedProducts.length > 0 ?  <RightAlignedContainer>
                <button type="submit" data-testid="save_product" onClick={handleSaveMedicine} disabled={isPending} className="btn pharmacy-btn px-3 mt-4">{isPending ? 'Saving...':'Save'}</button>
            </RightAlignedContainer>: null}
        </>
    )

    const editProduct = (
        <Card className="p-3 general-card">
            <AddProductForm {...productProps}>
                <RightAlignedContainer>
                    <button type="submit" data-testid="submit_product" onClick={handleSaveSingleProduct} disabled={isPending} className="btn pharmacy-btn px-3 mt-4">{isPending ? 'Saving...':'Save'}</button>
                </RightAlignedContainer>
            </AddProductForm>

        </Card>
    )

    return (
        <div className='journals'>
            <CsvDialog openDialog={csvdialog} handleCloseDialog={handleCloseCSVDialog}
                       handleUploadCsv={handleUploadCsv} handleChangeUpload={handleChangeProductCsv}
                       message="Products">
                    <span>
                        <button onClick={fetchData}
                                className="btn pharmacy-primary-btn btn-sm">Download Sample File</button>
                        {error_message !== '' ?
                            <div style={{marginTop:'10px'}}>
                                <Alert severity="error" style={{borderRadius:'10px'}}>{error_message}</Alert>
                            </div>
                            : null
                        }
                    <CSVLink
                        data={allProducts}
                        ref={csvLink}
                        filename="Products.csv"
                        className="hidden"
                    />
                    </span>
            </CsvDialog>
            <BlockNavigationComponent
                when={isBlocking}
                shouldBlockNavigation={()=>{
                    return isBlocking;
                }}
                navigate={path=>history.push(path)}
            />
            <ReusableDashboardHeader component={title} heading="Products"
                                     subHeading={title} link={history.location.pathname}/>
            <div className="general-ledger-header">
                <div className="row">
                    <div className="col-md-3">
                        <h6 className='mt-2 mb-0'>{title}</h6>
                    </div>
                    <div className="col-md-9">
                        <div className="text-right">
                            <Link to="/managemedicine">
                                <button type="button" className="btn  pharmacy-btn-outline">
                                    <FontAwesomeIcon icon={faList}/> Manage Products
                                </button>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
            <div className="add-medicine-card">
                <DetailsDialog title='New Unit' openDialog={openDialog} handleClose={handleCloseDialog}>
                    <UnitForm {...unitProps}/>
                </DetailsDialog>
                <DetailsDialog title='New Category' openDialog={openCategory} handleClose={closeCategoryDialog}>
                    <CategoryForm {...categoryProps}/>
                </DetailsDialog>
                <DetailsDialog title='New Product Type' openDialog={openType} handleClose={closeTypeDialog}>
                    <TypeForm {...typeProps}/>
                </DetailsDialog>
                <PharmacySnackbar open={open} variant={variant} message={message}
                                  handleCloseBar={handleCloseSnackbar}/>
                <Container>
                <div className="text-right mb-3">
                    <button type="button" onClick={handleOpenCSVDialog} className="btn pharmacy-info-btn btn-sm mr-4" style={{marginBottom:'4px'}}>
                        <FontAwesomeIcon icon={faFileUpload} /> Upload Products
                    </button>
                    <button data-testid="quick_add_unit" type="button" onClick={handleOpenDialog} className="btn pharmacy-btn btn-sm mr-4"style={{marginBottom:'4px'}} >
                        <FontAwesomeIcon icon={faPlus}/> Quick Add Unit
                    </button>
                    <button data-testid="quick_add_category" type="button" onClick={openCategoryDialog} className="btn pharmacy-info-btn btn-sm mr-4" style={{marginBottom:'4px'}}>
                        <FontAwesomeIcon icon={faPlus}/> Quick Add Category
                    </button>
                    <button data-testid="quick_add_type" type="button" onClick={openTypeDialog} className="btn pharmacy-btn btn-sm" style={{marginBottom:'4px'}}>
                        <FontAwesomeIcon icon={faPlus}/> Quick Add Product Type
                    </button>
                </div>
                    {product_id ? editProduct : addProducts}
                </Container>
            </div>
        </div>
    )

}

function mapStateToProps(state) {
    return {
        snackbars: state.snackbars,
    }

}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            snackbarActions: bindActionCreators(snackbarActions, dispatch),
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddMedicine);