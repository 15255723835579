import React from 'react';
import { Form } from 'react-bootstrap';
import ReusableDashboardHeader from '../../Containers/Dasboard/ReusableDashboardHeader'
import { history } from '../../Helpers/history'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col'
import { EnhancedTableHead, EnhancedTableToolbar, MuiPaper} from "../../Utils/Tables/CustomTable";
import Label from "../../Utils/FormInputs/Label";
import  { CustomSelect } from "../../Utils/FormInputs/SelectInput";
import  { useProductLog } from "./hooks/useProductLog";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import {formatDate, titleCase, dateStringTimeConvert} from '../../Utils/titleCaseFunction';
import { Container } from '../../Utils/styledComponents';
import { MuiPagination } from '../../Utils/Tables/CustomTable';

const headData = [
    {id: 'sl', numeric: false, disablePadding: false, label: 'Sl'},
    {id: 'date_created', numeric: false, disablePadding: false, label: 'Date'},
    {id: 'medicine_name', numeric: false, disablePadding: false, label: 'Product Name'},
    {id: 'quantity', numeric: false, disablePadding: false, label: 'Quantity'},
    {id: 'batch_no', numeric: false, disablePadding: false, label: 'Batch No.'},
    {id: 'action', numeric: false, disablePadding: false, label: 'Action'},
    {id: 'done_by', numeric: false, disablePadding: false, label: 'Done By'},
];

export const ProductLog = (props) => {
    const { 
        productLog, loading, handleInputChange, sortBy, handleSubmit, state,
        page, rowsPerPage, handleChangeRowsPerPage, handleChangePage
    } = useProductLog(props);

    return (
        <div className='journals'>
            <ReusableDashboardHeader component='Product Log' heading="Stock"
            subHeading='Product Log' link={history.location.pathname}/>
            <Container>
                <div className='general-ledger-header pb-0'>
                    <form onSubmit={handleSubmit} >
                        <div className="row">
                            <div className="col-md-4">
                                <Form.Group as={Row}>
                                    <Label name='Sort by Action' sm={5} column/>
                                    <Col sm={7}>
                                        <CustomSelect onChange={handleInputChange} value={sortBy} 
                                                        options={[{value: 'choose', label: 'Select...'},
                                                            {value: 'ALL', label: 'All'}, {
                                                                value: 'ADJUSTED',
                                                                label: 'Adjusted',  
                                                            }
                                                            ]}/>
                                    </Col>
                                </Form.Group>
                            </div>
                            
                        </div>
                    </form>
                </div>
                {/* <PharmacySnackbar open={open} message={message} variant={variant}
                                    handleCloseBar={this.handleCloseBar}/> */}
                <MuiPaper>
                    <EnhancedTableToolbar/>
                    <Table
                        aria-labelledby="tableTitle"
                        size='medium'
                        aria-label="enhanced table"
                    >
                        <EnhancedTableHead headCells={headData}/>
                        {loading ? <TableBody><TableRow>
                            <TableCell colSpan="6" align="center"><img src="/images/listPreloader.gif"
                                                                        alt="loader"
                                                                        style={{width: '5%'}}/></TableCell>
                        </TableRow></TableBody> : productLog.length > 0 ?
                            <TableBody>
                                {productLog.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((d, index) => {
                                        const sl = (page * rowsPerPage) + index + 1;
                                        return (
                                                <TableRow
                                                hover
                                                role="checkbox"
                                                tabIndex={-1}
                                                key={index}
                                                className='mui-table-row'>
                                                <TableCell>{sl}</TableCell>
                                                <TableCell>
                                                    <span>
                                                        {dateStringTimeConvert(d.created_at)}
                                                    </span>
                                                </TableCell>
                                                <TableCell>
                                                    <span>
                                                        {titleCase(d.product_name)}
                                                    </span>
                                                </TableCell>
                                                <TableCell>
                                                    <span>{d.quantity}</span>
                                                </TableCell>
                                                <TableCell>
                                                    <span>{d.related_batch}</span>
                                                </TableCell>
                                                <TableCell>
                                                    <span>{d.actions}</span>
                                                </TableCell>
                                                <TableCell>
                                                    <span>{`${d.first_name ? d.first_name : ''} ${d.last_name ? d.last_name : ''}`}</span>
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                            </TableBody>

                            : <TableBody><TableRow>
                                <TableCell colSpan="6" align="center">No records found</TableCell>
                            </TableRow></TableBody>}
                    </Table>
                    <MuiPagination data={productLog} rowsPerPage={rowsPerPage} page={page}
                                handleChangeRowsPerPage={handleChangeRowsPerPage}
                                handleChangePage={handleChangePage} rows={[10, 20, 30, 50, 100]} colSpan={7}/>
                </MuiPaper>
            </Container>
        </div>
    )
}
