import * as React from 'react';
import { useReactToPrint } from 'react-to-print';
import { makeStyles } from "@material-ui/core/styles";

const getPageMargins = () => {
    return `
    @media print {
      width : 205mm;
      margin-left: 100px;
    }
    `;
  };
const fontSize = () =>{
  return `@media print { 
    table td span, table th span{
      font-size:14pt;
    }
  }`;
}

const a4Styles = makeStyles({
  centreItems: {
      display: 'flex',
      justifyContent: 'center'
  },
  emergencyListView: {
    display: 'flex',
    justifyContent: 'center',
  //  width: '100%'
  }
});

function usePrint(title) {
    const componentRef = React.useRef();
    const handlePrint = useReactToPrint({
      content: () => componentRef.current,
      documentTitle: title
    });

    return {componentRef, handlePrint, getPageMargins,fontSize, a4Styles}  
}
export {usePrint}