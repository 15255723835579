import React, {useEffect, useState} from "react";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import * as snackbarActions from "../../Redux/Actions/SnackbarActions/SnackbarActions"
import ReusableDashboardHeader from "../../Containers/Dasboard/ReusableDashboardHeader";
import {history} from "../../Helpers/history";
import axios from "axios";
import config from "../../Helpers/config.json";
import {errorMessages} from "../../Helpers/ErrorMessages";
import ReusableTabs, {TabPanel} from "../../Utils/Tabs/ReusableTabs";
import {MedicineDetailsReport} from "./ProductDetails";

const salesHeadData = [
    {id: 'date', numeric: false, disablePadding: false, label: 'Date'},

    {id: 'invoiceId', numeric: false, disablePadding: false, label: 'Invoice No'},
    {id: 'customerName', numeric: false, disablePadding: false, label: 'Customer Name'},
    {id: 'quantity', numeric: false, disablePadding: false, label: 'Quantity'},
    {id: 'salePrice', numeric: false, disablePadding: false, label: 'Sale price'},
    {id: 'amount', numeric: false, disablePadding: false, label: 'Total Amount'},
];

const purchaseHeadData = [
    {id: 'date', numeric: false, disablePadding: false, label: 'Date '},

    {id: 'invoiceId', numeric: false, disablePadding: false, label: 'Invoice No'},
    {id: 'manufName', numeric: false, disablePadding: false, label: 'Supplier Name'},
    {id: 'quantity', numeric: false, disablePadding: false, label: 'Quantity'},
    {id: 'purchasePrice', numeric: false, disablePadding: false, label: 'Purchase price'},
    {id: 'amount', numeric: false, disablePadding: false, label: 'Total Amount'},
];


const MedicineDetails = ({actions,  match: {params}}) => {
    const {product_id} = params;
    const [term, setTerm] = useState('');
    const [productDetails, setProductDetails] = useState({});
    const [value, setValue] = React.useState(0);
    const [loading, setLoading] = useState(false);
    const tabHeading = ['Sales', 'Purchases']

    useEffect(() => {
        setLoading(true)
        axios.get(`${config.epharmUrl}/Cproduct/product_details`, {params: {product_id:parseInt(product_id)}})
            .then(response => {
                const data = response.data;
                const dt = data ? data : {}
                setProductDetails(dt)
                setLoading(false)
            }).catch(error => {
            errorMessages(error, null, actions)
            setLoading(false)
        })
    }, [product_id])

    

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const details = !productDetails ? {} : productDetails;
    const purchases = !details.purchaseData ? [] : details.purchaseData;
    const sales = !details.salesData ? [] : details.salesData;
    const salesList = sales.map(item=>({
        date:item.date,
        invoice:item.status == 1?item.receipt_number:item.invoice_number,
        invoice_id: item.invoice_number,
        id:item.customer_id,
        quantity:item.quantity,
        rate:item.rate,
        total_amount:item.total_price,
        name:item.customer_name
    }))

    const purchaseList = purchases.map(item=>({
        date:item.date_added,
        invoice:item.chalan_no,
        invoice_id: item.purchase_id,
        id:item.manufacturer_id,
        quantity:item.quantity,
        rate:item.manufacturer_price,
        total_amount:item.total_amount,
        name:item.manufacturer_name
    }))
    return (
        <div className='journals'>
            <ReusableDashboardHeader component='Product Details' heading="Products"
                                     subHeading='Product Details' link={history.location.pathname}/>
            <ReusableTabs value={value} handleChange={handleChange} tabHeading={tabHeading}>
                <TabPanel value={value} index={0}>
                    <MedicineDetailsReport title='Sale(s)' headData={salesHeadData} data={salesList} productDetails={details}
                                           urls={{invoice_url:'invoicedetails',details_url:'customerpurchases'}} loading={loading} term={term}/>
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <MedicineDetailsReport title='Purchase(s)' headData={purchaseHeadData} data={purchaseList} productDetails={details}
                                           urls={{invoice_url:'manufacturerinvoice',details_url:'manufdetails'}} loading={loading} term={term}/>
                </TabPanel>
            </ReusableTabs>

        </div>
    )


}

function mapStateToProps(state) {
    return {
        snackbars: state.snackbars
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            snackbarActions: bindActionCreators(snackbarActions, dispatch)
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MedicineDetails);
