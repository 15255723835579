import {useState} from "react";
import axios from "axios";
import config from "../../../Helpers/config.json";
import {errorMessages} from "../../../Helpers/ErrorMessages";

function useCloseLpo(actions) {
    const [openCloseDialog, setOpenCloseDialog] = useState(false)
    const [id, setId] = useState('')
    const [isClosed, setIsClosed] = useState('idle')
    const [submitted, setSubmitted] = useState(false)
    const [reason, setReason] = useState('')

    const user = JSON.parse(sessionStorage.getItem('user'));
    const singleUser = !user ? {} : user;
    const user_id = !singleUser.user_id ? '' :singleUser.user_id;

    const handleOpenCloseDialog = (purchase_order_id) =>()=>{
        setId(purchase_order_id)
        setOpenCloseDialog(true)
    }

    const handleClosePurchaseOrderDialog = () =>{
        setOpenCloseDialog(false)
    }

    const handleClosePurchaseOrder = (event) =>{
        event.preventDefault()
        const formData = new FormData();
        formData.append('local_purchase_order_id',id)
        formData.append('user_id',user_id)
        formData.append('reason',reason)
        formData.append('status',4)
        setSubmitted(true)
        if(reason){
            setIsClosed('pending')
            axios.post(`${config.epharmUrl}/Cpurchaseorder/cancel_order`,formData).then(()=>{
                actions.snackbarActions.successSnackbar('Purchase order closed successfully ')
                setOpenCloseDialog(false)
                setIsClosed('success')
            }).catch(err=>{
                errorMessages(err, null, actions)
                setIsClosed('error')
            })
        }

    }

    const handleChangeReason = (event) =>{
        setReason(event.target.value)
    }

    const isClosedOrder = isClosed === 'success'
    const isPendingClosedOrder = isClosed === 'pending'


    const closeProps = {openCloseDialog, reason, handleClosePurchaseOrderDialog, handleOpenCloseDialog,
        handleClosePurchaseOrder, handleChangeReason, submitted,isPendingClosedOrder, isClosedOrder}
    return {isClosedOrder, closeProps}

}
export {useCloseLpo}