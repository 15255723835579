import {useEffect, useState} from "react";
import axios from "axios";
import config from "../../../Helpers/config.json";
import {errorMessages} from "../../../Helpers/ErrorMessages";

export const useAddProvider = (actions) => {
    const providerTypes = [{value:'WALK IN CUSTOMER', text:'WALK IN CUSTOMER'},
        {value:'INSURANCE', text:'INSURANCE'},
        {value:'ORGANIZATION', text:'ORGANIZATION'},
        ];
    const [state, setState] = useState({providerName: '', providerType: '',
        mobile: ''});
    const [openDialog, setOpenDialog] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState('idle');
    const [provider, setProvider] = useState({});
    const [provider_id, setProviderId] = useState('');
    const [is_new_provider, setNewProvider] = useState(false);

    useEffect(() => {
        if(!provider_id){
            return
        }
        axios.get(`${config.epharmUrl}/Ccustomer/customer_category/${provider_id}`).then(response => {
            const data = response.data;
            const dt = data ?? {};
            setProvider(dt)
        }).catch(error => {
            errorMessages(error, null, actions)
        })

    }, [provider_id])

    useEffect(() => {
       if(!provider_id){
           setState({providerName: '', providerType: '',})
           return
       }
        const {
            category_name,
            category_type,
        } = provider;
        setState({
            providerName: category_name,
            providerType: category_type,

        })
    }, [provider, provider_id])


 

    const handleOpenDialog = (id='', is_new) => {
        setOpenDialog(true)
        setProviderId(id)
        if (is_new === 'new') {
            setNewProvider(true);
            setState({providerName: '', providerType: '',
            mobile: ''});
        }
    };
    const handleCloseDialog = () => {
        setOpenDialog(false)
    };

    const handleChange = event => {
        const {name, value} = event.target;
        setState({...state, [name]: value})
    };

    const handleSubmit = event => {
        event.preventDefault();
        const {providerName} = state;
        setSubmitted(true)
        if (providerName) {
            setIsSubmitted('pending')
            const formData = new FormData();
            if(provider_id){
                formData.append('id', provider_id);
            }
            formData.append('category_name', providerName);
            formData.append('category_type', providerName);
            const url = provider_id ? 'customer_category_update' : 'add_customer_category'
            axios.post(`${config.epharmUrl}/Ccustomer/${url}`, formData)
                .then(() => {
                    setIsSubmitted('resolved')
                    setOpenDialog(false)
                }).catch(error => {
                setIsSubmitted('rejected')
                errorMessages(error, null, actions)
            })
        }

    };
    const handleCloseBar = () => {
        actions.snackbarActions.hideSnackBar();
    };
    const isPending = isSubmitted === 'pending'
    const isResolved = isSubmitted === 'resolved'
    const isRejected = isSubmitted === 'rejected'
    const providerProps = {state, openDialog, submitted,providerTypes, is_new_provider,
        isSubmitted,  handleOpenDialog, handleCloseDialog,  handleChange, handleSubmit, handleCloseBar}
    return {providerProps, isRejected, isResolved, isPending}
}