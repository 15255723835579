import React, {useState} from 'react';
import {Form} from "react-bootstrap";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import axios from 'axios';
import PharmacySnackbar from '../../Utils/Snackbars/SnackbarUtil';
import config from "../../Helpers/config.json";
import * as snackbarActions from "../../Redux/Actions/SnackbarActions/SnackbarActions";

const useManualBackup = (props) => {
    const {actions} = props;
 
    const [state, setState] = useState( {
        username: '',
        password: '',
        revealPassword: false,
    })

    const togglePassword = () => {
        const {revealPassword} = state;
        setState({revealPassword: !revealPassword})
    };

    const handleChange = (event) => {
        const {name, value} = event.target;
        setState({...state, [name]: value})
    };

    const handleLogin = (event) => {
        event.preventDefault();
        const {actions} = props;
        const {username, password} = state;
        const formData = new FormData();
        formData.append('username',username);
        formData.append('password', password);
        if(username && password){
            axios.post(`${config.epharmUrl}/admin/do_login`, formData).then(response=>{
                const res = response.status ? response.status : 404;
                if (res === 200) {
                    // make the back up call
                        axios.get(`${config.epharmUrl}/admin/backup`, { responseType: 'blob' }).then(response => {
                            // Create a link to download the file
                            const url = window.URL.createObjectURL(new Blob([response.data]));
                            const now = new Date();
                            const formattedDate = now.toLocaleString('en-US', { year: 'numeric', month: '2-digit', 
                                day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit' }).replace(/[\s/:]/g, '_');
                            const file_name = 'database_backup' + '_' + formattedDate.replace(',', '') + '.sql';
                            const link = document.createElement('a');
                            link.href = url;
                            link.setAttribute('download', file_name);

                            // Append the link to the document body
                            document.body.appendChild(link);

                            // Click the link to download the file
                            link.click();

                            // Remove the link from the document body
                            document.body.removeChild(link);
                            actions.snackbarActions.successSnackbar('Backup file downloaded Successfully');
                        }).catch(err => {
                            actions.snackbarActions.errorSnackbar('Error when downloading backup file');
                        });
                } else {
                    // complain
                    actions.snackbarActions.errorSnackbar('Error when checking credentials');
                }
            }).catch(err=>{
                actions.snackbarActions.errorSnackbar('Username or password is incorrect');
            })
        }

    };

    const handleCloseBar = () => {
        actions.snackbarActions.hideSnackBar();
    };

    return {
        state, handleChange, handleLogin, handleCloseBar
    }
}


const ManualBackup = (props) => {

    const {
        state, handleChange, handleLogin, handleCloseBar
    } = useManualBackup(props);

    const {open, message, variant} = props.snackbars;
    const {username, password} = state;
    const {loading} = props.users;

    return (
            <div className="styled-login">
                <PharmacySnackbar open={open} message={message} variant={variant}
                                  handleCloseBar={handleCloseBar}/>
                <div className="card" style={{borderRadius:'15px', boxShadow: '3px 3px 3px 3px rgba(1, 0.1, 0.1, 0.1)', maxWidth:'600px'}}>
                    <div style={{padding:'20px'}}>
                        <div className="text-center styled-content mb-3">
                            <h4 style={{
                                color:'#389081',
                                fontSize: 18,
                                marginTop: 10,
                                fontWeight: 600,
                                fontFamily: "Roboto"
                            }}>To do a manual backup, confirm user credentials</h4>
                        </div>
                        <Form onSubmit={handleLogin} autoComplete="off">
                            <Form.Group style={{marginLeft: '30px', marginRight:'30px'}}>
                                <Form.Label style={{fontWeight:'500'}}>Email</Form.Label>
                                <Form.Control id="useremail" type="email" value={username} name="username"
                                    onChange={handleChange} style={{borderRadius:'10px'}}/>
                            </Form.Group>
                            <Form.Group style={{marginLeft: '30px', marginRight:'30px'}}>
                                <Form.Label style={{fontWeight:'500'}}>Password</Form.Label>
                                <Form.Control id="userpassword" type="password" value={password} name="password"
                                autocomplete="off"
                                    onChange={handleChange} style={{borderRadius:'10px'}}/>
                            </Form.Group>
                            <div className="text-center">
                                <button id="login_btn" disabled={!!loading} className="btn pharmacy-btn px-5 mt-2"
                                        style={{width: "35%", fontSize:'600', borderRadius:'15px'}}>{loading ? "Confirming...":"Confirm"}</button>
                            </div>
                        </Form>
                    </div>
                </div>
            </div>
        )
}



function mapStateToProps(state) {
    return {
        snackbars: state.snackbars,
        users:state.users
    }

}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            snackbarActions: bindActionCreators(snackbarActions, dispatch)
        }
    }

}

export default connect(mapStateToProps, mapDispatchToProps)(ManualBackup);
