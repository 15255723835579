import React, {useEffect, useRef, useState} from "react";
import axios from "axios";
import config from "../../../Helpers/config.json";
import {errorMessages} from "../../../Helpers/ErrorMessages";
import {history} from "../../../Helpers/history";
import {exportPDF} from "../../../Utils/exportPDF";
import {useAddManufacturer} from "./useAddManufacturer";

export const useManageManufacturer = (actions, match = {}) => {
    const storage = JSON.parse(sessionStorage.getItem('total_count'))
    const item_count = storage ? storage : 0
    const count = () => +item_count || 0
    const [term, setTerm] = useState('');
    const [activePage, setActivePage] = useState(1);
    const [manufacturers, setManufacturers] = useState([]);
    const [manufacturer_id, setSupplierId] = useState('');
    const [total_count, setTotalCount] = useState(count);
    const [openDelete, setOpenDelete] = useState(false);
    const [loading, setLoading] = useState('idle');
    const {isResolved, manufacturerProps} = useAddManufacturer(actions)


    useEffect(() => {
        setLoading('loading')
        axios.get(`${config.epharmUrl}/Cmanufacturer/suppliers`,{params:{page_number:activePage}}).then(response => {
            const data = response.data;
            const dt  = data.data ?? {};
            const manuf = dt.suppliers ? dt.suppliers :  [];
            const supplier_count = dt.total_records ? dt.total_records : 0;
            if(activePage === 1){
                sessionStorage.setItem('total_count', JSON.stringify(supplier_count))
                setTotalCount(supplier_count)
            }else {
                setTotalCount(count)
            }
            setManufacturers(manuf);
            setLoading('success')
        }).catch(error => {
            // errorMessages(error, null, actions)
            setLoading('error')
        })
    }, [activePage, isResolved])


    const handleOpenDelete = (_id) => {
        setOpenDelete(true);
        setSupplierId(_id)
    };

    const handleCloseDelete = () => {
        setOpenDelete(false)
    };

    const handleDeleteManufacturer = () => {
        axios.post(`${config.epharmUrl}/Cmanufacturer/manufacturer_delete`, {params: {manufacturer_id}}).then(() => {
            setManufacturers(data => data.filter(supplier => supplier.manufacturer_id !== manufacturer_id))
            actions.snackbarActions.successSnackbar('Supplier Deleted Successfully!');
        }).catch(error => {
            errorMessages(error, null, actions)
        });
    };

    const handler = (value) => {
        const params = {'search' : value};
        //setTerm(value)
        axios.get(`${config.epharmUrl}/Cmanufacturer/suppliers/search`, {params}).then((res) => {
            const dt = res.data;
            const sups = dt.data ?? []; 
            setManufacturers(sups);

        }).catch(error => {
            errorMessages(error, null, actions)
        });

    }

    const handlePageChange = (pageNumber) => {
        setActivePage(pageNumber)
    };



    const downloadPDF = () => {
        const manufs = manufacturers ?? [];
        exportPDF("Supplier Report", [["SL", "Supplier Name", "Address", "Mobile", "Details"]],
            manufs.map((manufacturer, index) => [index += 1, manufacturer.manufacturer_name, manufacturer.address,
                manufacturer.mobile, manufacturer.details]), "Suppliers.pdf")
    };

    const handleCloseBar = () => {
        actions.snackbarActions.hideSnackBar();
    };

    const isLoading = loading === 'loading'
    const isSuccess = loading === 'success'
    const isError = loading === 'error'

    return {
        term, activePage, manufacturers, total_count,
        openDelete,  loading,
        handleOpenDelete, handleCloseDelete,
        handleDeleteManufacturer, handler, handlePageChange,
        handleCloseBar, downloadPDF,
        manufacturerProps,
        isSuccess, isLoading, isError
    }

}