import React from "react";
import TextField from "../../../Utils/FormInputs/TextField";
import {ErrorMessage} from "../../../Utils/styledComponents";
import {CreatableCustomSelect, CustomSelect} from "../../../Utils/FormInputs/SelectInput";
import ExpensePrintDialog from './ExpensePrintDialog';
import Textarea from "../../../Utils/FormInputs/Textarea";

function NewExpense({   expenses, expenseItems, submitted, handleChangeExpenseItem,openExpenseDialog, 
                        handleCloseExpenseDialog, handlePrint, handleClose,actions,
                        handleAddExpense,  handleChangeExpense, handleSubmitExpense,
                        handleRemoveExpense, isPending,
                        creditLedgers, ledgers, handleCreateExpenseItem
                    }){
    return (
        <div>
        <ExpensePrintDialog text='expense' openExpenseDialog={openExpenseDialog} handleCloseExpenseDialog={handleCloseExpenseDialog}
         print={handlePrint} handleClose={handleClose} actions={actions} message="Expenses"/>
         <div>
        <form onSubmit={handleSubmitExpense} autoComplete='off'>
            <table className="table table-bordered">
                <tr>
                    <td width='400px'>Narration<span className="help-block">*</span></td>
                    <td>Debit Ledger<span className="help-block">*</span></td>
                    <td>Credit Ledger<span className="help-block">*</span></td>
                    <td>Vendor Name<span className="help-block">*</span></td>
                    <td>Amount<span className="help-block">*</span></td>
                    <td width='100px'>Action</td>
                </tr>
                <tbody>
                {expenses.map((item, index) => (
                    <tr key={index}>
                        <td>
                            <CreatableCustomSelect
                                onChange={value => handleChangeExpenseItem(value, index)}
                                options={expenseItems}
                                value={item.expense_item}
                                submitted={submitted}
                                onCreateOption={(inputValue)=>handleCreateExpenseItem(inputValue, index)}
                            />
                            {(submitted && !item.expense_item) ? <ErrorMessage>Expense item is required</ErrorMessage>:null}
                        </td>
                        <td>
                            <CustomSelect value={item.debit_ledger} options={ledgers} name="debit_ledger"
                                onChange={(e) =>handleChangeExpense({name:'debit_ledger',  ...e}, index)} submitted={submitted}/>
                            {(submitted && !item.debit_ledger) ? <ErrorMessage>Debit ledger is required</ErrorMessage>:null}
                        </td>
                        <td>
                            <CustomSelect value={item.credit_ledger} options={creditLedgers} name="credit_ledger"
                                onChange={(e) => handleChangeExpense({name:'credit_ledger', ...e}, index)} submitted={submitted}/>
                            {(submitted && !item.credit_ledger) ? <ErrorMessage>Credit ledger is required</ErrorMessage>:null}
                        </td>
                        <td>
                            <Textarea  {...{submitted}} value={item.receipt_no} type="text" name="receipt_no"
                                        onChange={(e) => handleChangeExpense(e, index)} placeholder="Enter vendor name"/>
                            {(submitted && !item.receipt_no) ? <ErrorMessage>Vendor Name is required</ErrorMessage>:null}
                        </td>
                        <td>
                            <TextField  {...{submitted}} value={item.amount} type="number" name="amount" min='0'
                                        onChange={(e) => handleChangeExpense(e, index)} placeholder="Input amount"/>
                            {(submitted && !item.amount) ? <ErrorMessage>Amount is required</ErrorMessage>:null}
                        </td>
                        <td>
                            <button type='button' onClick={()=>handleRemoveExpense(index)} className='btn btn-sm pharmacy-btn-dismiss'>Remove</button>
                        </td>
                    </tr>
                ))}
                <tr>
                    <td colSpan={6}> <button type='button' onClick={handleAddExpense} className='btn btn-sm pharmacy-gray-btn'>Add another line</button></td>
                </tr>
                </tbody>
            </table>
            <button type='submit' disabled={isPending} className='btn btn-sm pharmacy-btn'>{isPending ? 'Submitting...' : 'Submit'}</button>
        </form>
        </div>
        </div>
    )
}
export {NewExpense}