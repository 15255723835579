import {useEffect, useState} from "react";
import axios from "axios";
import config from "../../../Helpers/config.json";
import {errorMessages} from "../../../Helpers/ErrorMessages";

const useAddMedicineType = (actions) =>{
    const [type_name, setTypeName] = useState('');
    const [medicine_type_id, setTypeId] = useState('');
    const [openDialog, setOpenDialog] = useState(false);
    const [openEdit, setOpenEdit] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState('idle');
    const [medicineType, setType] = useState({});


    useEffect(() => {
        if (!medicine_type_id) {
            return;
        }
        axios.post(`${config.epharmUrl}/Cproduct/type_update_form`, null, {params: {type_id: medicine_type_id}}).then(response => {
            const data = response.data;
            const dt = data ? data : {}
            setType(dt)
        }).catch(error => {
            //errorMessages(error, null, actions)
        })
    }, [medicine_type_id])

    useEffect(() => {
        if(!medicine_type_id){
            return
        }
        const {type_name: name} = medicineType;
        setTypeName(name)
    }, [medicineType, medicine_type_id])

    const handleChange = event => {
        const {value} = event.target;
        setTypeName(value)
    };
    const handleSubmit = event => {
        event.preventDefault();
        const formData = new FormData();
        if (medicine_type_id) {
            formData.append('type_id', medicine_type_id);
            formData.append('status', 1);
        }
        formData.append('type_name', type_name);
        setSubmitted(true)
        if (type_name) {
            setIsSubmitted('pending');
            const url = medicine_type_id ? 'type_update' : 'insert_type'
            axios.post(`${config.epharmUrl}/Cproduct/${url}`, formData).then(() => {
                actions.snackbarActions.successSnackbar('Medicine type added successfully')
                setIsSubmitted('resolved')
                setOpenDialog(false)
                setOpenEdit(false)
                setTypeName('')
                setSubmitted(false)
            }).catch(error => {
                const err_msg = error.response.data ? error.response.data : '';
                const msg = !err_msg ? '' : err_msg.error_message ? err_msg.error_message : '';
                if (msg === '') {
                    errorMessages(error, null, actions);
                } else {
                    actions.snackbarActions.errorSnackbar("Medicine type already inserted")
                }
                setIsSubmitted('rejected');
            })
        }
    };

    const handleOpenEdit = (_id ) => {
        setTypeId(_id)
        setOpenEdit(true)
    };

    const handleCloseEdit = () => {
        setOpenEdit(false)
        setTypeName('')
        setSubmitted(false);
    }

    const handleOpenDialog = () => {
        setOpenDialog(true)
    }

    const handleCloseDialog = () => {
        setOpenDialog(false);
        setSubmitted(false);
    };

    const isPending = isSubmitted === 'pending'
    const isResolved = isSubmitted === 'resolved'

    const typeProps = {type_name, submitted, openDialog, openEdit, handleOpenDialog, handleCloseDialog, handleSubmit, handleCloseEdit,
    handleOpenEdit, isPending, handleChange}

    return {isResolved, typeProps}
}

export {useAddMedicineType}