import React, {useEffect, useState} from 'react';
import {usePeriodsFilter} from "./usePeriodsFilter";
import axios from "axios";
import config from "../../../Helpers/config.json";
import {errorMessages} from "../../../Helpers/ErrorMessages";
import {convDate, convertDate} from "../../../Utils/titleCaseFunction";
import {exportPDF} from "../../../Utils/exportPDF";


export const useSalesReport = (actions) => {
    const filters = [{value: 'sales_person', label: 'Sales Person'}, {value: 'sales_account', label: 'Sales Account'}]
    const accounts = [{value: 1, label: 'Cash Account'}, {value: 2, label: 'Credit Account'}];
    const sortsArr = [{value: 'quantity', label: 'Quantity'}, {value: 'total_sale', label: 'Sales Amount'},
        {value: 'profit', label: 'Profit'}];
    const ordersArr = [{value: 'asc', label: 'Ascending'},
        {value: 'desc', label: 'Descending'}]
    const {period, from_date, to_date, handleChangeDate,
        year, inputTypes, handleChangePeriod, handleChangeYear} = usePeriodsFilter()
    const [filterBy, setFilter] = useState({value: '', label: 'Select filter...'});
    const [filterType, setFilterType] = useState('');
    const [sortBy, setSortBy] = useState({value: '', label: 'Select...'});
    const [orderBy, setOrderBy] = useState({value: '', label: 'Select...'});
    const [rangeValue, setRangeValue] = useState({min: 0, max: 0})
    const [salesPersons, setPersons] = useState([]);
    const [loading, setLoading] = useState(false);
    const [salesReport, setSalesReport] = useState([]);
    const [count, setCount] = useState(0)
    const [activePage, setActivePage] = useState(1)
    const [submitted, setSubmitted] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState('idle');
    const [totals, setTotals] = useState({total_purchase: 0, sales_amount: 0, total_profit_amount: 0, total_sales_amount:0})
    const label = {
        'sales_person': 'Sales Person',
        'sales_account': 'Sales Account'
    }


    const get_period_value = period['value'] ? period['value'] : '';
    const isPeriodToday = get_period_value === 'today';

    useEffect(() => {
        axios.get(`${config.epharmUrl}/admin/get_users_with_roles`).then(res => {
            const data = res.data;
            const dt = !data ? [] : data;
            const arr = dt.map(person => ({
                value: person.user_id,
                label: `${person.first_name ? person.first_name : ''} ${person.last_name ? person.last_name : ''}`
            }))
            setPersons(arr)
        }).catch(err => {
            // errorMessages(err, null, actions)
        })
    }, [])

    const calculateTotalProfit = (arr) => {
        return arr.reduce((a, sale) => {
            return a + sale.profit
        }, 0)
    };

    const calculateTotalPurchase = (arr) => {
        return arr.reduce((a, sale) => {
            return a + sale.total_purchase
        }, 0)
    };

    useEffect(() => {
        setLoading(true)
        axios.get(`${config.epharmUrl}/admin/todays_sales_report`).then(response => {
            const dt = response.data;
            const sales = dt ? dt : {};
            const data = sales.sales_report ?? [];
            const amount = sales.sales_amount ?? 0;
            setSalesReport(data);
            setLoading(false);
            setTotals({
                total_purchase: calculateTotalPurchase(data),
                total_profit_amount: calculateTotalProfit(data),
                sales_amount: amount, total_sales_amount: amount
            })
        }).catch(error => {
            // errorMessages(error, null, actions);
            setLoading(false)

        })
    }, [])





    useEffect(()=>{
        if ((!from_date || !orderBy.value) && (period.value !== 'custom' && period.value !== 'today')
            || (!from_date || !to_date && period.value === 'custom') || !orderBy.value
        ) {
            return;
        }

        const today = convertDate()
        const formData = new FormData();
        formData.append('from_date', period.value === "today" ? today:from_date);
        formData.append('to_date', period.value === "today" ? today:to_date);
        formData.append('sort_by', sortBy.value);
        formData.append('order_by', orderBy.value);
        // if ((period.value === 'custom' && from_date && to_date)){
            setLoading(true)
            axios.post(`${config.epharmUrl}/admin/retrieve_datewise_Salereports`, formData).then(response => {
                const dt = response.data;
                const sales = dt ? dt : {};
                const data = sales.sales_report ?? [];
                const amount = sales.sales_amount ?? 0;
                setSalesReport(data);
                setTotals({
                    total_purchase: calculateTotalPurchase(data),
                    total_profit_amount: calculateTotalProfit(data),
                    sales_amount: amount, total_sales_amount: amount
                })
                setLoading(false);
            }).catch(error => {
                // errorMessages(error, null, actions);
                setLoading(false)
            })
        // }
    },[orderBy, from_date, to_date])


    const handleChangeFilterBy = (value) => {
        setFilter(value)
    }



    const handleChangeFilterType = (value) => {
        setFilterType(value)
        const today = convertDate()
        const formData = new FormData();
        formData.append('from_date', period.value === "today" ? today:from_date);
        formData.append('to_date', period.value === "today" ? today:to_date);
        formData.append('user_id', value.value ? value.value : '');
        setLoading(true)
        if (
            (period.value === 'custom' && from_date && to_date) || (period.value === 'today')
        ) {
            axios.post(`${config.epharmUrl}/admin/retrieve_dateWise_SalesReports/${activePage}`, formData).then(response => {
                const dt = response.data;
                const sales = dt ? dt : {};
                const data = sales.sales_report ?? [];
                const amount = sales.sales_amount ?? 0;
                const total_sales_amount = sales.total_sales_amount ?? 0
                setSalesReport(data);
                setTotals({
                    total_purchase: calculateTotalPurchase(data),
                    total_profit_amount: calculateTotalProfit(data),
                    sales_amount: amount, total_sales_amount
                })
                setLoading(false);
            }).catch(error => {
                // errorMessages(error, null, actions);
                setLoading(false)
            })
        }
    }

    const handleChangeSortBy = (value) => {
        setSortBy(value)
        setOrderBy({value: '', label:'Select...'})
        setRangeValue({min: 0,max: 0})
    }
    const handleChangeOrderBy = (value) => {
        setOrderBy(value)
        setRangeValue({min: 0,max: 0})
    }

    const handleChangeRangeValue = (event) => {
        const {name, value} = event.target;
        setRangeValue({...rangeValue, [name]: value})
    }

    const retrieveFilteredData = (page) =>{
        setSubmitted(true)
        const today = convertDate()
        if (
            (period.value === 'custom' && from_date && to_date) || (period.value === 'monthly' && from_date) ||
            (period.value === 'yearly' && year) || (period.value === 'today') || (period.value === 'weekly' && from_date)
        ){
            const formData = new FormData();
            formData.append('from_date', period.value === "today" ? today:period.value === 'yearly' ? year:from_date);
            formData.append('to_date', period.value === "today" ? today:to_date);
            formData.append('user_id', filterBy.value ? filterBy.value : '');
            setIsSubmitted('pending')
            setLoading(true)
            axios.post(`${config.epharmUrl}/admin/retrieve_dateWise_SalesReports/${page}`, formData).then(response => {
                const dt = response.data;
                const sales = dt ? dt : {};
                const data = sales.sales_report ?? [];
                const total_count = sales.total_count ?? 0
                const amount = sales.sales_amount ?? 0;
                const total_sales_amount = sales.total_sales_amount ?? 0
                console.log(total_sales_amount)
                setSalesReport(data);
                setLoading(false);
                setTotals({
                    total_purchase: calculateTotalPurchase(data),
                    total_profit_amount: calculateTotalProfit(data),
                    sales_amount: amount,total_sales_amount
                })
                setCount(total_count)
                setIsSubmitted('resolved')
            }).catch(error => {
                // errorMessages(error, null, actions);
                setIsSubmitted('rejected')
                setLoading(false)

            })
        }
    }

    const handleChangeActivePage = (page) =>{
        setActivePage(page)
        retrieveFilteredData(page)
    }

    const handleSubmit = (event) =>{
        event.preventDefault();
        retrieveFilteredData(activePage)

    }
    const downloadPDF = () => {
        const {sales_amount,total_profit_amount,total_purchase} = totals;
        exportPDF("Sales Report", [["Invoice#", "Sales Date", "Customer", "Product",
                "Quantity Sold",  "Sell Price", "Discount(%)", "Purchase Amount", "Sale Amount", "Profit/Loss"]],
            salesReport.map(sale => [sale.invoice, convDate(sale.date), sale.customer_name, sale.product_name,
                sale.quantity,  sale.rate, sale.discount, sale.total_purchase, sale.total_price, sale.profit]), "Sales Report.pdf",
            [["", "", "", "", "", "", "Total  Amount", total_purchase, sales_amount, total_profit_amount]])
    };

    const filterData = {
        'sales_person': salesPersons,
        'sales_account': accounts
    }

    const isResolved = isSubmitted === 'resolved'

    const handleUpdateChangePeriod = (e) => {
        setSubmitted(false);
        handleChangePeriod(e);
    }

    return {
        filterBy, handleChangeFilterBy, filters, label, filterType, handleChangeFilterType,
        filterData, loading, salesReport, totals, sortsArr, ordersArr, sortBy, orderBy,
        handleChangeOrderBy, handleChangeSortBy, rangeValue, handleChangeRangeValue, salesPersons,
        submitted,isSubmitted,handleSubmit, downloadPDF,period, from_date, to_date, handleChangeDate,
        year, inputTypes, handleChangePeriod, handleChangeYear, isResolved, activePage, handleChangeActivePage,
        count,handleUpdateChangePeriod
    }
}