import React from 'react';
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Label from "../../Utils/FormInputs/Label";
import TextField from "../../Utils/FormInputs/TextField";
import Grid from "@material-ui/core/Grid";
import Dropdown from "react-bootstrap/Dropdown";



export const ReusableSalesAndPurchaseHeader = ({handleSubmit, children, exportExcel}) => {
       

    return (
        <div className="general-ledger-header mb-3">
            <div>
                <Grid container spacing={0}>
                    <Grid item xs={6} md={8}>
                        <form onSubmit={handleSubmit}>
                            {children}
                        </form>
                    </Grid>
                    <Grid item xs={6} md={2}/>
                    <Grid item xs={6} md={2}>
                        <div className='export-dropdown text-right'>
                            <Dropdown align='start'>
                                <Dropdown.Toggle variant="outline-secondary" id="dropdown-basic">
                                    Export As
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    {/*<Dropdown.Item onClick={downLoadPdf} style={{fontSize: '.85em'}}>PDF</Dropdown.Item>*/}
                                    <Dropdown.Item onClick={exportExcel}
                                                   style={{fontSize: '.85em'}}>Excel</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    </Grid>
                </Grid>
            </div>
        </div>
    )
}

export const CustomPurchaseSalesHeader = ({
                                              handleChangeDate,
                                              from_date,
                                              exportExcel,
                                              to_date,
                                              submitted,
                                              isSubmitted,
                                              handleFilter
                                          }) => {
    return (
        <div className="general-ledger-header mb-3">

            <Grid container spacing={0}>
                <Grid item xs={6} md={8}>
                    <form onSubmit={handleFilter}>
                        <Form.Group as={Row} style={{marginBottom: '-1%'}}>
                            <Col sm={4}>
                                <Form.Group as={Row}>
                                    <Label name='From' column sm={2} style={{textAlign: 'right'}}/>
                                    <Col sm={10}>
                                        <TextField type='date' onChange={handleChangeDate}
                                                   value={from_date} name='from_date'/>
                                        {(submitted && !from_date) ?
                                            <div className='invalid-text'>From date is required</div> : null}
                                    </Col>
                                </Form.Group>
                            </Col>

                            <Col sm={4}>
                                <Form.Group as={Row}>
                                    <Label name='To' column sm={2}/>
                                    <Col sm={10}>
                                        <TextField type='date' value={to_date} onChange={handleChangeDate}
                                                   name='to_date'/>
                                        {(submitted && !to_date) ?
                                            <div className='invalid-text'>To date is required</div> : null}
                                    </Col>
                                </Form.Group>
                            </Col>
                            <Col sm={1}>
                                <button type='submit' disabled={isSubmitted === 'pending'}
                                        className='btn btn-sm pharmacy-btn-outline'>{isSubmitted === 'pending' ? 'Filtering' : 'Filter'}</button>
                            </Col>
                        </Form.Group>
                    </form>
                </Grid>
                <Grid item xs={6} md={2}/>

                <Grid item xs={6} md={2}>
                    <div className='export-dropdown text-right'>
                        <Dropdown align='start'>
                            <Dropdown.Toggle variant="outline-secondary" id="dropdown-basic">
                                Export As
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                {/*<Dropdown.Item onClick={downLoadPdf} style={{fontSize: '.85em'}}>PDF</Dropdown.Item>*/}
                                <Dropdown.Item onClick={exportExcel} style={{fontSize: '.85em'}}>Excel</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                </Grid>
            </Grid>

        </div>
    )
}