
import { jsx } from '@emotion/react'
import React, {useEffect, useState} from "react";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import * as snackbarActions from "../../Redux/Actions/SnackbarActions/SnackbarActions";
import {convertGmtTime,  titleCase} from "../../Utils/titleCaseFunction";
import axios from "axios";
import config from "../../Helpers/config.json";
import {errorMessages} from "../../Helpers/ErrorMessages";
import PharmacySnackbar from "../../Utils/Snackbars/SnackbarUtil";
import {ItemsTable, ReceiptTemplate} from "../../Utils/Template/ReceiptTemplate";
import {tableCell} from "../../styles/tableStyles";
import ReusableDashboardHeader from "../../Containers/Dasboard/ReusableDashboardHeader";
import {history} from "../../Helpers/history";
import Card from "@material-ui/core/Card";
import {usePrint} from "../../Utils/Template/usePrint";


const headData = [{value:'Medicine'},{value:'Qty'},{value:'Price'},{value:'Discount(%)'},{value:'Total'}]
const InvoiceDetails = ({actions,snackbars, match: {params}}) => {
    const {invoice_id} = params;
    const [invoice, setInvoice] = useState({});
    const [medicines, setMedicines] = useState([]);
    const [loading, setLoading] = useState('idle')

    useEffect(() => {
        setLoading('pending')
        axios.get(`${config.epharmUrl}/Cinvoice/invoice_inserted_data`, {params: {invoice_id}}).then(response => {
            const data = response.data;
            const dt = data ? data : {};
            const meds = data.invoice_all_data ?? [];
            setMedicines(meds)
            setInvoice(dt)
            setLoading('resolved')
        }).catch(error => {
            errorMessages(error, null, actions)
            setLoading('rejected')
        })
    }, [invoice_id])

    const handleCloseSnackbar = () => {
        actions.snackbarActions.hideSnackBar()
    }

    const {user_first_name, user_last_name, customer_name: name,
        previous, subTotal_ammount, total_amount, paid_amount, due_amount, invoice_no, final_date
    } = invoice;
    const user_name = titleCase(`${!user_first_name ? "" : user_first_name} ${!user_last_name ? "" : user_last_name}`);


    const {variant, message, open} = snackbars;

    const arr = medicines.map(item => ({
        amount: item.total_price, id: item.product_id, ...item
    }))
    const isLoading = loading === 'pending';
    const isSuccess = loading === 'resolved';
    const isError = loading === 'rejected';

    const {handlePrint, fontSize,componentRef} = usePrint('')


    const customerDetails =(
        <tbody>
        <tr>
            <td align="center">
                <span><strong>{titleCase(name)}</strong></span>
            </td>
        </tr>
        <tr>
            <td align="center">
                <span>Date:{final_date ? convertGmtTime(final_date) : ''}</span></td>
        </tr>
        </tbody>
    )

    const itemDetails = (
        <ItemsTable headData={headData}>
            <tbody>
            {arr && arr.map((info, index) => (
                <tr key={index}>
                    <td><span>{titleCase(info.product_name)}</span></td>
                    <td><span>{info.quantity}</span></td>
                    <td><span>{info.rate}</span></td>
                    <td><span>{info.discount}</span></td>
                    <td><span>{info.total_price}</span></td>
                </tr>
            ))}

            <tr>
                <td css={[tableCell]} colSpan="4" align="right"><span>Sub Total</span></td>
                <td css={[tableCell]}><span><strong>{subTotal_ammount}</strong></span></td>
            </tr>
            <tr>
                <td  colSpan="4" align="right"><span>Previous</span></td>
                <td><span><strong>{previous}</strong></span></td>
            </tr>
            <tr>
                <td   colSpan="4" align="right"><span>Grand Total</span></td>
                <td><span><strong>{total_amount}</strong></span></td>
            </tr>
            <tr>
                <td colSpan="4" align="right"><span>Paid AMount</span></td>
                <td><span><strong>{paid_amount}</strong></span></td>
            </tr>
            <tr>
                <td colSpan="4" align="right"><span>Due Amount</span></td>
                <td><span><strong>{due_amount}</strong></span></td>
            </tr>
            </tbody>
        </ItemsTable>
    )

    const footer = (
        <tbody>
        <tr>
            <td><span>Receipt No.: {invoice_no}</span></td>
            <td align='right'><span>User: {user_name}</span></td>
        </tr>
        </tbody>
    )
    return (
        <div>
            <PharmacySnackbar open={open} message={message} variant={variant}
                              handleCloseBar={handleCloseSnackbar}/>
            <ReusableDashboardHeader component="Invoice Receipt" heading="Invoice"
                                     subHeading="Invoice Receipt" link={history.location.pathname}/>
            {isLoading ? <div className="text-center mt-5"><img src="/images/listPreloader.gif"alt="loader"
                                                                style={{width: '10%'}}/></div>:isSuccess ? <div className="invoice-card">
                <div className="text-right mb-2">
                    <button onClick={handlePrint} className="btn pharmacy-info-btn">Print</button>
                </div>
                <Card className="p-3">
                    <div ref={componentRef} style={{padding:'16px 30px'}}>
                        <style>
                            {fontSize()}
                        </style>
                        <ReceiptTemplate {...{customerDetails, itemDetails, footer}}/>
                    </div>
                </Card>
            </div>: isError ? <div className="text-center">The server did not return a valid response</div>:null}
        </div>

    )

}

function mapStateToProps(state) {
    return {
        snackbars: state.snackbars
    }

}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            snackbarActions: bindActionCreators(snackbarActions, dispatch)
        }
    }

}

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceDetails);
