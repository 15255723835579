import React, {useEffect, useState} from 'react';
import { fade, makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import NotificationsIcon from '@material-ui/icons/Notifications';
import SettingsIcon from "@material-ui/icons/Settings";
import {history} from "../Helpers/history";
import ScheduleOutlinedIcon from "@material-ui/icons/ScheduleOutlined";
import AddShoppingCartOutlinedIcon from "@material-ui/icons/AddShoppingCartOutlined";
import {Link} from "react-router-dom";
import PersonOutlineOutlined from "@material-ui/icons/PersonOutlineOutlined";
import SettingsOutlined from "@material-ui/icons/SettingsOutlined";
import PowerSettingsNewOutlined from "@material-ui/icons/PowerSettingsNewOutlined";
import axios from "axios";
import config from "../Helpers/config.json";
import {connect} from 'react-redux';
import {bindActionCreators} from "redux";
import Badge from '@material-ui/core/Badge';
import * as snackbarActions from "../Redux/Actions/SnackbarActions/SnackbarActions";
import {BootstrapTooltip} from "../Utils/BootstrapTooltip";


const useStyles = makeStyles((theme) => ({
    grow: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        display: 'none',
        [theme.breakpoints.up('sm')]: {
            display: 'block',
        },
    },
    search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: fade(theme.palette.common.white, 0.15),
        '&:hover': {
            backgroundColor: fade(theme.palette.common.white, 0.25),
        },
        marginRight: theme.spacing(2),
        marginLeft: 0,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing(3),
            width: 'auto',
        },
    },
    searchIcon: {
        padding: theme.spacing(0, 2),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    inputRoot: {
        color: 'inherit',
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: '20ch',
        },
    },
    sectionDesktop: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'flex',
        },
    },
    sectionMobile: {
        display: 'flex',
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
    menuItem:{
        minHeight:48
    }
}));

function AppHeader({actions, children, ...props}) {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [expired, setExpired] = React.useState(null);
    const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
    const [expired_items, setExpiredItems] = useState(0);
    const [out_of_stock, setOutOfStock] = useState(0);

    const isMenuOpen = Boolean(anchorEl);
    const isExpiredOpen = Boolean(expired);
    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

    // useEffect(()=>{
    //     (async ()=>{
    //         try {
    //             const getExpiredDrugs = axios.get(`${config.epharmUrl}/User/expired_products`);
    //             const getOutOfStock = axios.get(`${config.epharmUrl}/User/out_of_stock`);
    //             const [expiredResponse, outOfStockResponse] = await Promise.all([getExpiredDrugs, getOutOfStock]);
    //             const expired = expiredResponse.data;
    //             const dt = !expired ? {} : expired;
    //             const exp = dt.expired_products ? dt.expired_products : [];
    //             const stock = outOfStockResponse.data ? outOfStockResponse.data : [];
    //             setExpiredItems(exp.length);
    //             setOutOfStock(stock.length);
    //         } catch (error) {
    //             // errorMessages(error, null, actions)
    //         }
    //     })()
    //
    // },[])

    const handleProfileMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMobileMenuClose = () => {
        setMobileMoreAnchorEl(null);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
        handleMobileMenuClose();
    };

    // const handleMobileMenuOpen = (event) => {
    //     setMobileMoreAnchorEl(event.currentTarget);
    // };
    const handleExpiredMenuOpen = (event) => {
        setExpired(event.currentTarget);
    };
    const handleExpiredMenuClose = () => {
        setExpired(null);
        handleMobileMenuClose();
    };
    const handleExpiredItems  = () =>{
        history.push('/expired_drugs');
        setExpired(null)
    };
    const handleOutOfStockItems  = () =>{
        history.push('/out_of_stock');
        setExpired(null)
    };

    const user = JSON.parse(sessionStorage.getItem('user'));
    const singleUser = !user ? {} : user;
    const user_name = !singleUser.user_name ? "" : singleUser.user_name;
    const menuId = 'primary-search-account-menu';
    const renderMenu = (
        <Menu
            anchorEl={anchorEl}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            id={menuId}
            keepMounted
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={isMenuOpen}
            onClose={handleMenuClose}
        >
            <Link to="/edit_user_profile" style={{color: "#616771", textDecoration: "none"}}>
                <MenuItem onClick={handleMenuClose} className="mui-menu-item">
                    <PersonOutlineOutlined/>
                    <span className="ml-3"> {user_name}</span>
                </MenuItem></Link>
            <Link to="/changepassword" style={{color: "#616771", textDecoration: "none"}}>
                <MenuItem style={{color: "#616771"}} onClick={handleMenuClose} className="mui-menu-item">
                    <SettingsOutlined/>
                    <span className="ml-3">Change Password</span>
                </MenuItem></Link>
            <Link id="logout-link" to="/login" style={{color: "#616771", textDecoration: "none"}}>
                <MenuItem className="mui-menu-item">
                    <PowerSettingsNewOutlined/>
                    <span className="ml-3">Logout</span>
                </MenuItem></Link>
        </Menu>
    );
    const expiredId = "expired-menu";
    const renderExpiredMenu = (
        <Menu
            anchorEl={expired}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            id={expiredId}
            keepMounted
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={isExpiredOpen}
            onClose={handleExpiredMenuClose}
        >
            <MenuItem classes={{root:classes.menuItem}} style={{color: "#616771"}} onClick={handleExpiredItems} className="mui-menu-item">
                <Badge badgeContent={expired_items} color="secondary"><ScheduleOutlinedIcon/>
                    <span className="ml-3">Expired Items</span></Badge>
            </MenuItem>
            <MenuItem classes={{root:classes.menuItem}} style={{color: "#616771"}} onClick={handleOutOfStockItems} className="mui-menu-item">
                <Badge badgeContent={out_of_stock} color="secondary"><AddShoppingCartOutlinedIcon />
                    <span className="ml-3">Out of Stock Items</span></Badge>
            </MenuItem>
        </Menu>
    );

    const mobileMenuId = 'primary-search-account-menu-mobile';
    const renderMobileMenu = (
        <Menu
            anchorEl={mobileMoreAnchorEl}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            id={mobileMenuId}
            keepMounted
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={isMobileMenuOpen}
            onClose={handleMobileMenuClose}
        >
            <MenuItem style={{color: "#616771"}} onClick={handleExpiredMenuOpen} className="mui-menu-item">
                <IconButton
                    aria-label="account of current user"
                    aria-controls="primary-search-account-menu"
                    aria-haspopup="true"
                    color="inherit"
                >
                    <NotificationsIcon />
                </IconButton>
                <p>Expired &amp; Out of Stock Items</p>
            </MenuItem>
            {/*<MenuItem style={{color: "#616771"}}>*/}
            {/*    <IconButton  color="inherit">*/}
            {/*        <MessageIcon/>*/}
            {/*    </IconButton>*/}
            {/*    <p>Messages</p>*/}
            {/*</MenuItem >*/}
            <MenuItem onClick={handleProfileMenuOpen} style={{color: "#616771"}} className="mui-menu-item">
                <IconButton
                    aria-label="account of current user"
                    aria-controls="primary-search-account-menu"
                    aria-haspopup="true"
                    color="inherit"
                >
                    <SettingsIcon />
                </IconButton>
                <p>User Profile</p>
            </MenuItem>
        </Menu>
    );

    return (
        <div >
            <AppBar style={{backgroundColor:'#1fa7b0'}}  {...props}>
                <Toolbar>
                    {children}
                    <div className={classes.grow} />
                    <div className={classes.sectionDesktop}>
                        <BootstrapTooltip title='Expired items'><IconButton
                            aria-label="account of current user"
                            aria-controls={expiredId}
                            aria-haspopup="true"
                            onClick={handleExpiredMenuOpen}
                            color="inherit"
                        >
                            <Badge badgeContent={expired_items} color="secondary">
                                <NotificationsIcon/></Badge>
                        </IconButton></BootstrapTooltip>
                        <IconButton
                            edge="end"
                            aria-label="account of current user"
                            aria-controls={menuId}
                            aria-haspopup="true"
                            onClick={handleProfileMenuOpen}
                            color="inherit"
                            id="system-settings-btn"
                        >
                            <SettingsIcon />
                        </IconButton>
                    </div>
                    {/*
                    <div className={classes.sectionMobile}>
                        <IconButton
                            aria-label="show more"
                            aria-controls={mobileMenuId}
                            aria-haspopup="true"
                            onClick={handleMobileMenuOpen}
                            color="inherit"
                        >
                            <MenuIcon />
                        </IconButton>
                    </div>
                    */}
                </Toolbar>
            </AppBar>
            {renderMobileMenu}
            {renderExpiredMenu}
            {renderMenu}
        </div>
    );
}
function mapStateToProps(state) {
    return {
        snackbars:state.snackbars
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            snackbarActions:bindActionCreators(snackbarActions, dispatch)
        }
    }

}
export default connect(mapStateToProps, mapDispatchToProps)(AppHeader)
