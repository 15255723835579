/** @jsx jsx */ //please do not remove this implementation because the css will not be loaded without it
import { jsx } from '@emotion/react'
import React, {useEffect, useState} from "react";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import axios from "axios";
import config from "../../Helpers/config.json";
import {errorMessages} from "../../Helpers/ErrorMessages";
import * as snackbarActions from '../../Redux/Actions/SnackbarActions/SnackbarActions'
import ReusableDashboardHeader from "../../Containers/Dasboard/ReusableDashboardHeader";
import {history} from "../../Helpers/history";
import PharmacySnackbar from "../../Utils/Snackbars/SnackbarUtil";
import Card from "react-bootstrap/Card";
import {usePrint} from "../../Utils/Template/usePrint";
import {BackButton} from "../../Utils/Buttons/DataExportationButton";
import {SalesReceiptTemplate} from "./SalesReceiptTemplate";



const ReceiptPrint = ({actions, snackbars, match: {params}}) => {
    const [data, setData] = useState({products:[], customer:{}});
    const [loading, setLoading] = useState('idle')
    const {invoice_id, customer_id, is_cancelled} = params;

    useEffect(() => {
        setLoading('pending')
        const formData = new FormData()
        formData.append('receipt_number', invoice_id)
        formData.append('invoice_number', '')
        formData.append('customer_id', customer_id)
        axios.post(`${config.epharmUrl}/Cinvoice/view_receipt`, formData).then(response => {
            const data = response.data;
            const dt = data ? data : {};
            const arr = dt.bill ? dt.bill : [];
            const customer_details = dt.customer_details ? dt.customer_details : {}
            const obj = arr[0] ? arr[0] : {}
            setData({products: arr,customer: {...customer_details,...obj}})
            setLoading('resolved')
        }).catch(error => {
            errorMessages(error, null, actions)
            setLoading('rejected')
        })
    }, [invoice_id])

    const handleCloseSnackbar = () => {
        actions.snackbarActions.hideSnackBar();
    };

    const isLoading = loading === 'pending';
    const isSuccess = loading === 'resolved';
    const isError = loading === 'rejected';

    const {variant, message, open} = snackbars;
    const {products, customer} = data

    const {handlePrint, componentRef, getPageMargins, fontSize} = usePrint('')


    return (
        <div>
            <ReusableDashboardHeader component="Receipt" heading="Sales"
                                     subHeading="Receipt" link={history.location.pathname}/>
            <PharmacySnackbar open={open} variant={variant} message={message} handleCloseBar={handleCloseSnackbar}/>

            {isLoading ? <div className="text-center mt-5"><img src="/images/listPreloader.gif" alt="loader"
                                                                style={{width: '10%'}}/></div>:isSuccess ?
                <div className="pos-invoice-style">
                    <div className='row'>
                        <div className='col-lg-6'>
                            <div className='mt-3'>
                                {is_cancelled ?
                                <BackButton to='/cancelledsales' text='Cancelled Sales'/>
                                :
                                <BackButton to='/sales-receipts' text='Receipts'/>
                                }
                            </div>
                        </div>
                        <div className='col-lg-6'>
                            <div className="text-right mr-5">
                                <button onClick={handlePrint} className="btn pharmacy-info-btn mb-2 mr-2">Print</button>
                            </div>
                        </div>
                    </div>
                    <div ref={componentRef} className="card styled-pos-card">
                        <SalesReceiptTemplate {...{products, customer}}/>
                    </div>
                    </div> : isError ? <div className="text-center">The server did not return a valid response</div>:null}
        </div>
    )

}

function mapStateToProps(state) {
    return {
        snackbars: state.snackbars
    }

}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            snackbarActions: bindActionCreators(snackbarActions, dispatch)
        }
    }

}

 export default connect(mapStateToProps, mapDispatchToProps)(ReceiptPrint);
