import React from 'react'
import {StickyHeadTable, CustomTableCell} from '../../../Utils/Tables/StickyHeaderTable';
import { dateConvert} from '../../../Utils/ConvertDate';
import axios from 'axios';
import config from "../../../Helpers/config.json"
import * as snackbarActions from '../../../Redux/Actions/SnackbarActions/SnackbarActions';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PharmacySnackbar from "../../../Utils/Snackbars/SnackbarUtil";
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { LoadingGif } from '../../../Utils/Loader';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { formatDateTime, formatLocalTime } from '../../../Utils/ConvertDate';
import { formatAmount, formatDigits } from '../../../Utils/formatNumbers';
import { BackButton } from '../../../Utils/Buttons/DataExportationButton';
import {AccountingMiniHeader,LedgerParagraph} from "../../../Utils/Menu/MiniSubHeader";
import { DateTimeFilter } from '../../../Utils/ReusableComponents/DateTimeFilter';
import {RightAlignedContainer} from "../../../Utils/styledComponents"
import {exportPDF} from "../../../Utils/exportPDF";
import ReusableDashboardHeader from "../../../Containers/Dasboard/ReusableDashboardHeader";
import {history} from "../../../Helpers/history";

const headData = [
    { id: 'date', label: 'Date',minWidth: 200 },
    { id: 'voucher_no', label: 'Voucher No.',minWidth: 200 },
    { id: 'type', label: 'Voucher Type',minWidth: 200 },
    {id: 'description',label: 'Memo',minWidth: 170},
    {id: 'amount',label: 'Amount',minWidth: 170},
    {id: 'transaction_type',label: 'Transaction Type',minWidth: 170},
  ]



function ViewJournalTransactions({actions, snackbars, match:{params}}) {
    const {account, transaction_id, start_date:s_date, end_date:e_date} = params;
    const from_date = s_date ? s_date : dateConvert()
    const to_date = e_date ? e_date : dateConvert()
    const current_date = new Date()
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [state, setState] = React.useState({start_date:from_date, end_date:to_date})
    const [tranasactions, setTransactions] = React.useState([]);
    const [submitted, setSubmitted] = React.useState(false);
    const [isSubmitted, setIsSubmitted] = React.useState('idle');
    const [loading, setLoading] = React.useState('idle')
    const [balance, setBalance] = React.useState(0)
    const [entry_category, setEntryCategory] = React.useState('')
    const {start_date, end_date} =state;



      const retrieveData = React.useCallback((from_date='', to_date='', isSubmit=false)=>{
        const formData = new FormData();

        formData.append('ledger_id',   transaction_id);
        formData.append('start_date',  from_date);
        formData.append('end_date',   to_date);
        setLoading('pending')
        isSubmit && setIsSubmitted('pending')
          axios.post(`${config.epharmUrl}/accounting/specific_journal`, formData).then(response=>{
            const res = response.data ? response.data:{};
            const arr = res.data ? res.data : [];
            const list = arr.map(item=>({
                id:item.id,
                date:item.date_added,
                details:item.memo,
               ...item
            }))
            setTransactions(list)
            setLoading('success')
            isSubmit && setIsSubmitted('resolved')
        }).catch(err=>{
            setLoading('error')
            isSubmit && setIsSubmitted('rejected')
        })

    },[])

    const retrieveTotalAmount = React.useCallback((from_date='', to_date='', isSubmit=false)=>{
        const formData = new FormData();
        formData.append('ledger_id', transaction_id);
        formData.append('start_date', from_date);
        formData.append('end_date', to_date);
        setLoading('pending')
        isSubmit && setIsSubmitted('pending')
        axios.post(`${config.epharmUrl}/accounting/specific_journal_balance`, formData).then(response=>{
            const res = response.data ? response.data:{};
            const dt = res.data ? res.data:{}
            const bal = dt.resultant_amount ? dt.resultant_amount : 0
            const category =  dt.entry_category ? dt.entry_category : ''
            setBalance(bal)
            setEntryCategory(category)
            setLoading('success')
            isSubmit && setIsSubmitted('resolved')
        }).catch(err=>{
            setLoading('error')
            isSubmit && setIsSubmitted('rejected')
        })

    },[])

    React.useEffect(()=>{
      retrieveTotalAmount(start_date, end_date)
    },[retrieveTotalAmount])
    

    React.useEffect(()=>{
        retrieveData(start_date,end_date)
    },[retrieveData])
  
    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };
  
    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(+event.target.value);
      setPage(0);
    };

    const handleChange = (event) =>{
        const {value, name} = event.target;
        setState({...state,[name]:value})
    }

    const handleSubmit = (event) =>{
        event.preventDefault()
        setSubmitted(true)
        if(start_date && end_date && end_date >= start_date){
            retrieveData(start_date, end_date,true)
            retrieveTotalAmount(start_date, end_date, true)
        }

    }

    const name = account.split('-')[1]

    const downloadPDF = () => {
        exportPDF(`${name} Journal(${formatLocalTime(current_date)})`, [['Date', "Voucher No.", "Voucher Type", "Memo", "Amount", "Transaction Type"]],
        tranasactions.filter(item=>Boolean(item)).map((item) => [formatDateTime(item.date), item.voucher_no, item.voucher_type, item.details, formatAmount(item.entry_amount),item.entry_category]),
        `${name} Journal(${formatLocalTime(current_date)}).pdf`,[["","","","Running Balance",  formatAmount(balance),""]]);
    }



    const closeSnackbar = () => {
        actions.snackbarActions.hideSnackBar()
    };

    const isLoading = loading === 'pending';
    const isSuccess = loading === 'success';
    const isError = loading === 'error';
    
    const isPending = isSubmitted === 'pending';
    // const isResolved = isSubmitted === 'resolved';
    // const isRejected = loading === 'rejected';
    const {open, variant, message} = snackbars;
    const emptyRows = rowsPerPage - Math.min(rowsPerPage, tranasactions.length - page * rowsPerPage);

    const theme = useTheme();
    const isExtraLarge = useMediaQuery(theme.breakpoints.up('xl'));
    const isMedium = useMediaQuery(theme.breakpoints.up('md'));
    const isLarge = useMediaQuery(theme.breakpoints.up('lg'));

    const colSpan = isExtraLarge ? 12 : isMedium ? 6 : isLarge ? 10 : headData.length
   

    // const pagination = (
    //     <Table>
    //         <TableRow>
    //             <TableCell colSpan={3}  align='right'><strong>Total: </strong></TableCell>
    //             <TableCell></TableCell>
    //             <TableCell></TableCell>
    //         </TableRow>
    //     </Table>
    // )

    const reusableTable = (
        <StickyHeadTable {...{width:'100%',page,rowsPerPage,handleChangePage,handleChangeRowsPerPage
        ,data:tranasactions, columns:headData.filter(item=>Boolean(item))}}>
            {isLoading ? <TableRow><TableCell align='center' {...{colSpan}}><LoadingGif/></TableCell></TableRow>:null}
            {isSuccess ? tranasactions.length > 0 ? 
                <>
                    {tranasactions.map((row, index) => {
                    return (
                    <TableRow  tabIndex={-1} key={index}>
                        <CustomTableCell>{formatDateTime(row.date)}</CustomTableCell>
                        <CustomTableCell>{row.voucher_no}</CustomTableCell>
                        <CustomTableCell>{row.voucher_type}</CustomTableCell>
                        <CustomTableCell>{row.entry_detail}</CustomTableCell>
                        <CustomTableCell>{row.entry_amount   ? formatAmount(row.entry_amount): row.entry_amount}</CustomTableCell>
                        <CustomTableCell>{row.entry_category}</CustomTableCell>
                    </TableRow>
                    );
            })}
                {emptyRows > 0 && (
                <TableRow style={{ height: 43 * emptyRows }}>
                <TableCell colSpan={headData.length} />
                </TableRow>
            )}
            </>:<TableRow><TableCell align='center'  {...{colSpan}}>No records found</TableCell></TableRow>:null}
            {isError ? <TableRow><TableCell align='center'  {...{colSpan}}>The server did not return a valid response</TableCell></TableRow>:null}
        </StickyHeadTable>
    )
    const title = s_date ? 'Profit & Loss':'Chart of Accounts';
    const route = s_date ? '/accounting/accreports/plstatement' : '/accounting/coa'
  return (
    <div className='journals'>
        <ReusableDashboardHeader component="Transactions" heading="Accounting"
                                 subHeading="Transactions" link={history.location.pathname}/>
        <PharmacySnackbar variant={variant} open={open} message={message} handleCloseBar={closeSnackbar}/>
       
        <div className='mx-3 mt-2'>
            <BackButton to={route} text={title}/>
            <div className='mt-3'>
                <DateTimeFilter {...{start_date,end_date, handleChange, submitted,handleSubmit,isPending,type:'date'}}/>                
            </div>
            <RightAlignedContainer>
                {tranasactions.length > 0 ?  <button onClick={downloadPDF} className='btn btn-sm pharmacy-info-btn mb-3'>Download PDF</button>:null}
            </RightAlignedContainer>

            <div className='row'>
                <div className='col-lg-6'>
                    <AccountingMiniHeader title={account}/>
                </div>
                <div className='col-lg-6'>
                    <RightAlignedContainer>
                        <LedgerParagraph variant='p3'>Running Balance: <strong>{formatAmount(balance)}</strong> { balance > 0 ?  entry_category : ''}</LedgerParagraph>
                    </RightAlignedContainer>
                </div>
            </div>
   
            {reusableTable}
        </div>
    </div>
  )
}
function mapStateToProps(state) {
    return {snackbars: state.snackbars}
}

function mapDispatchToProps(dispatch) {
    return {actions: {snackbarActions: bindActionCreators(snackbarActions, dispatch)}}
}

export default connect(mapStateToProps, mapDispatchToProps)(ViewJournalTransactions);
