import React from "react"
import Form from "react-bootstrap/Form";


const TextField = ({type,value,name,onChange, submitted=false, disabled, ...rest}) => {
    const isSubmitted = submitted && !value;

    return (
        <Form.Control style={{border: isSubmitted ? '1px solid red':'', fontSize:'13px'}}   type={type} value={value} name={name} onChange={(e)=>onChange(e)}
                       className='form__control' disabled={disabled} {...rest}/>
    )
};
export default TextField;
