import React, { useCallback, useEffect, useState } from "react";
import axios from "axios";
import config from "../../../../Helpers/config.json";
import { errorMessages } from "../../../../Helpers/ErrorMessages";

export const useAccountsPayable = (actions) => {
  const [data, setData] = useState({ creditors: [], loading: "idle" });
  const [submitted, setSubmitted] = useState(false);
  const [totalPageCount, setTotalPageCount] = useState(0);
  const [activePage, setActivePage] = useState(1);
  const [searchValue, setSearchValue] = useState("");
  const [status, setStatus] = useState("all");

  const [date, setDate] = useState({ from_date: "", to_date: "" });
  const { from_date, to_date } = date;
  const { loading } = data;

  const retrievePayables = useCallback(
    (start_date, end_date) => {
      setData({ ...data, loading: "loading" });
      axios
        .get(`${config.epharmUrl}/Cmanufacturer/supplier_payables`, {
          params: {
            page_number: activePage,
            search: searchValue,
            start_date,
            end_date,
          },
        })
        .then((response) => {
          const res = response.data;

          console.log("creditors", res);

          const dt = res ? res : {};
          const dat = dt.data ? dt.data : {};
          const arr = dat.supplier_payables_list
            ? dat.supplier_payables_list
            : [];
          const total_count = dat.total_count ? dat.total_count : 0;
          setTotalPageCount(total_count);
          setData({ ...data, creditors: arr, loading: "success" });
        })
        .catch((error) => {
          errorMessages(error, null, actions);
          setData({ ...data, loading: "error" });
        });
    },
    [activePage, searchValue, status]
  );

  useEffect(() => {
    retrievePayables(from_date, to_date);
  }, [retrievePayables]);

  const handleChangeDate = (event) => {
    const { name, value } = event.target;
    setDate({ ...date, [name]: value });
  };

  const handleChange = (event) => {
    setSearchValue(event.target.value);
  };

  const handleChangeStatus = (event) => {
    setStatus(event.target.value);
  };

  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
  };

  const handleFilter = (event) => {
    event.preventDefault();
    setSubmitted(true);
    if (from_date && to_date) {
      retrievePayables(from_date, to_date);
    }
  };

  const handleCloseBar = () => {
    actions.snackbarActions.hideSnackBar();
  };

  const isLoading = loading === "loading";
  const isSuccess = loading === "success";
  const isError = loading === "error";

  return {
    data,
    from_date,
    to_date,
    handleChangeDate,
    isLoading,
    isSuccess,
    isError,
    activePage,
    handleCloseBar,
    handleFilter,
    totalPageCount,
    submitted,
    searchValue,
    handleChange,
    handlePageChange,
    status,
    handleChangeStatus,
  };
};
