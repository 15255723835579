import React from 'react';
import {CustomSelect} from "../../../Utils/FormInputs/SelectInput";
import {ErrorMessage} from "../../../Utils/styledComponents"
import Form from 'react-bootstrap/Form';
import TextField from '../../../Utils/FormInputs/TextField';




function ProductCustomerLedgerForm({rows, handleChangeCreditAccount, handleChangeDebitAccount, handleSubmit,
    isPending,  handleAddRow, handleRemoveRow, retrieveItems, clickItem,items,submitted, accounts, fieldName='', headData=[]}) {
  return (
        <form onSubmit={handleSubmit} autoComplete='off'>
            <table className="table table-bordered">
                <tr>
                    {headData.map((item, index)=>(
                        <td key={index} width={item.width}>{item.name}{item.isRequired ? <span className="help-block">*</span>: null}</td>
                    ))}
                </tr>
                <tbody>
                    {rows.map((item, index) => (
                    <tr key={index}>
                        <td>
                        <TextField  {...{submitted}} value={item.name} type="text" name="name"
                                        onChange={(e) => retrieveItems(e, index)} placeholder={`Enter ${fieldName} name`}/>
                            {(submitted && !item.name) ? <ErrorMessage>{fieldName} name is required</ErrorMessage>:
                            item.isError && <ErrorMessage>Please enter a valid {fieldName}</ErrorMessage>}
                            {item.isOpen &&
                            <Form.Control as="select" className="drug-ul prescription-ul" multiple>
                                {items.map((val, idx) => (
                                    <option key={idx} value={val.value} className="drug-li "
                                            onClick={() => clickItem({value:val.value,label:val.label}, index)}>{val.label}</option>
                                ))}
                                </Form.Control>}

                        </td>
                        <td>
                            <CustomSelect value={item.debit_account} options={accounts}
                                            onChange={value => handleChangeDebitAccount(value, index)}/>

                        </td>
                        <td>
                            <CustomSelect value={item.credit_account} options={accounts}
                                            onChange={value => handleChangeCreditAccount(value, index)}/>

                        </td>
                        <td>
                            <button type='button' onClick={()=>handleRemoveRow(index)} className='btn btn-sm pharmacy-btn-dismiss'>Remove</button>
                        </td>
                    </tr>
                ))}
                <tr>
                    <td colSpan={4}> <button type='button' onClick={handleAddRow} className='btn btn-sm pharmacy-gray-btn'>Add another line</button></td>
                    
                </tr>
                </tbody>
            </table>
            <button type='submit' disabled={isPending}  className='btn btn-sm pharmacy-btn'>{isPending ? 'Submitting...':'Submit'}</button>
        </form>
  )
}

export {ProductCustomerLedgerForm}