import React, {Component} from 'react';
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faWindowRestore} from "@fortawesome/free-solid-svg-icons/faWindowRestore";
import {faFax} from "@fortawesome/free-solid-svg-icons/faFax";
import {faPen} from "@fortawesome/free-solid-svg-icons/faPen";
import {faPlus} from "@fortawesome/free-solid-svg-icons/faPlus";
import {faTrash} from "@fortawesome/free-solid-svg-icons/faTrash";
import Table from "react-bootstrap/Table";
import Tooltip from "@material-ui/core/Tooltip";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import * as invoiceActions from "../../Redux/Actions/InvoiceActions/PosInvoiceActions";
import * as snackbarActions from "../../Redux/Actions/SnackbarActions/SnackbarActions";
import PharmacySnackbar from "../../Utils/Snackbars/SnackbarUtil";
import Dialog from "@material-ui/core/Dialog";
import {DialogTitle, DialogActions, DialogContent} from "../../Utils/Dialog/pharmacyDialog";
import ReusableDashboardHeader from "../../Containers/Dasboard/ReusableDashboardHeader";
import {history} from "../../Helpers/history";


export class TodayInvoiceReport extends Component {
    state = {
        openDialog: false,
        invoice_id: '',
    };

    componentDidMount() {
        const {actions} = this.props;
        actions.invoiceActions.retrieveGuiPosData();
        // this.getTotalAmount()
    }

    handleCloseBar = () => {
        const {actions} = this.props;
        actions.snackbarActions.hideSnackBar();
    };

    handleOpenDialog = invoice_id => {
        this.setState({invoice_id: invoice_id, openDialog: true})
    };

    handleCloseDialog = () => {
        this.setState({openDialog: false})
    };

    handleDeleteInvoice = () => {
        const {actions} = this.props;
        const {invoice_id} = this.state;
        actions.invoiceActions.deleteSpecificInvoice(invoice_id)
    };

    render() {
        const {guiPos} = this.props.invoices;
        const {open, message, variant} = this.props.snackbars;
        const {openDialog} = this.state;
        const todayInvoices = !guiPos.todays_invoice ? [] : guiPos.todays_invoice;
        const total_invoice_amount = !guiPos.total_invoice_amount ? 0 : guiPos.total_invoice_amount;
        return (
            <div id="todays-invoice-report">
                <ReusableDashboardHeader component="Today's Report" heading="Invoice"
                                         subHeading="Today's sales report" link={history.location.pathname}/>
                <div className="pos-buttons text-right">
                    <Link to="/guipos" style={{textDecoration: "none", color: "inherit"}}>
                        <button type="button" className="btn mr-2 btn-sm pharmacy-info-btn">
                            <FontAwesomeIcon icon={faPlus}/> GUI Invoice
                        </button>
                    </Link>

                </div>

                <div className="card p-3 m-2">
                    <PharmacySnackbar open={open} message={message} variant={variant}
                                      handleCloseBar={this.handleCloseBar}/>
                    <div className="p-2">
                        <Dialog open={openDialog}>
                            <DialogTitle onClose={this.handleCloseDialog}>
                                <div className="text-center">
                                    <strong>Delete Invoice</strong>
                                </div>
                            </DialogTitle>
                            <DialogContent>
                                <h6>Are you sure you want to delete this invoice ?</h6>
                            </DialogContent>
                            <DialogActions>
                                <button className="btn pharmacy-grey-btn mr-2" onClick={this.handleCloseDialog}>No, Keep
                                    Invoice
                                </button>
                                <button className="btn pharmacy-btn-dismiss" onClick={this.handleDeleteInvoice}>Yes,
                                    Delete Invoice
                                </button>
                            </DialogActions>
                        </Dialog>

                        <Table bordered hover style={{marginLeft: "15px", marginTop: "4px"}}>
                            <thead>
                            <tr>
                                <th>SL.</th>
                                <th>Invoice No</th>
                                <th>Invoice ID</th>
                                <th>Customer Name</th>
                                <th>Date</th>
                                <th>Total Amount ($)</th>
                                <th>Action</th>
                            </tr>
                            </thead>
                            <tbody>
                            {todayInvoices.map((invoice, index) => {
                                const counter = index += 1;
                                return (
                                    <tr key={index}>
                                        <td>{counter}</td>
                                        <td>{invoice.invoice}</td>
                                        <td>{invoice.invoice_id}</td>
                                        <td>{invoice.customer_name}</td>
                                        <td>{invoice.date}</td>
                                        <td>{invoice.total_amount}</td>
                                        <td>
                                            <Tooltip title="Invoice" placement="left-start">
                                                <Link to={{pathname: `/invoicedetails/${invoice.invoice_id}`}}>
                                                    <button className="btn btn-sm pharmacy-btn mr-1"><FontAwesomeIcon
                                                        icon={faWindowRestore}/></button>
                                                </Link>
                                            </Tooltip>
                                            <Tooltip title="POS Invoice" placement="left-start">
                                                <Link to={{pathname: `/posdetails/${invoice.invoice_id}`}}>
                                                    <button className="btn btn-sm pharmacy-amber-btn mr-1">
                                                        <FontAwesomeIcon
                                                            icon={faFax}/></button>
                                                </Link>
                                            </Tooltip>
                                            <Link to={{pathname: `/invoice_update_form/${invoice.invoice_id}`}}>
                                                <button className="btn btn-sm pharmacy-info-btn mr-1"><FontAwesomeIcon
                                                    icon={faPen}/></button>
                                            </Link>
                                            <button className="btn btn-sm pharmacy-btn-dismiss"
                                                    onClick={() => this.handleOpenDialog(invoice.invoice_id)}>
                                                <FontAwesomeIcon
                                                    icon={faTrash}/></button>
                                        </td>
                                    </tr>)
                            })}
                            <tr>
                                <td colSpan={5} className="text-right">Total:</td>
                                <td>{total_invoice_amount}</td>
                                <td/>
                            </tr>
                            </tbody>
                        </Table>

                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        invoices: state.invoices,
        snackbars: state.snackbars
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            invoiceActions: bindActionCreators(invoiceActions, dispatch),
            snackbarActions: bindActionCreators(snackbarActions, dispatch)
        }
    }

}

export default connect(mapStateToProps, mapDispatchToProps)(TodayInvoiceReport);