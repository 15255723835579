import React from 'react';
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import SelectInput from "../../Utils/FormInputs/SelectInput";
import Label from "../../Utils/FormInputs/Label";
import TextField from "../../Utils/FormInputs/TextField";



const InvoiceDateFilter = ({filters, handleChange,handleFilter, date,isPending, submitted}) =>{
    const {filter_by, start_date, end_date} = date
    return (
        <form onSubmit={handleFilter}>
            <Form.Group as={Row}>
                <Col sm={3}>
                    <SelectInput value={filter_by} onChange={handleChange} options={filters} name='filter_by'/>
                </Col>
                {filter_by === 'custom' ?  <>
                <Col sm={4}>
                    <Row>
                        <Col sm={2}>
                            <Label name='From' type/>
                        </Col>
                        <Col sm={10}>
                            <TextField type='date'
                                       onChange={handleChange}
                                       value={start_date} name='start_date' submitted={submitted}/>
                            {submitted && !start_date ?
                                <div className='invalid-text'>From date is required</div> : submitted && start_date > end_date ?
                                    <div className='invalid-text'>From date should be less than to date</div> : null}
                        </Col>
                    </Row>
                </Col>
                <Col sm={4}>
                    <Row>
                        <Col sm={2}>
                            <Label name='To' type/>
                        </Col>
                        <Col sm={10}>
                            <TextField type='date' value={end_date} onChange={handleChange}
                                       name='end_date' submitted={submitted}/>
                            {submitted && !end_date ?
                                <div className='invalid-text'>To date is required</div> : null}
                        </Col>
                    </Row>
                </Col>
                <Col sm={1}>
                    <button type='submit' disabled={isPending}  data-testid="filter-button"
                            className='btn btn-sm pharmacy-btn-outline'>{isPending ? 'Filtering...':'Filter'}
                    </button>
                </Col>
                </>:null}
            </Form.Group>
        </form>

    )
}
export {InvoiceDateFilter}