import moment from 'moment'

function formatDateTime(date){
    return moment.utc(date).format('MMMM Do YYYY, h:mm a')
}

function formatLocalTime(date){
    return moment(date).format('MMMM Do YYYY, h:mm a')
}

function today(){
    return moment().format('Do MMMM YYYY')
}

function todayTime(){
    return moment().format('MMMM Do YYYY, h:mm a')
}

function formatCurrentDate(date){
    return moment(date).format()
}

function formatDate(date) {
    return moment(date).format('Do MMMM YYYY')
}

export {formatDateTime, today, formatCurrentDate,formatDate, todayTime, formatLocalTime};

export const convertDateStrokeTime = (date) => {
    const today = moment(date.slice(0,-3)).toDate();
    return ('0' + today.getDate()).slice(-2) + '/' + ('0' + (today.getMonth() + 1)).slice(-2) + '/' + today.getFullYear() + ' ' + today.getHours() + ':' + ('0' + today.getMinutes()).slice(-2);
}

export const convertDate = () => {
    let today = new Date();
    // return today.getFullYear() + '-' + ('0' + (today.getMonth() + 1)).slice(-2) + '-' + ('0' + today.getDate() ).slice(-2);
    return ('0' + today.getDate()).slice(-2) + '-' + ('0' + (today.getMonth() + 1)).slice(-2) + '-' + today.getFullYear();

};

export const dateConvert = () => {
    let today = new Date();
    return today.getFullYear() + '-' + ('0' + (today.getMonth() + 1)).slice(-2) + '-' + ('0' + today.getDate()).slice(-2)
};

export const convDate = (date) => {
    let today = new Date(date);
    return today.getFullYear() + '-' + ('0' + (today.getMonth() + 1)).slice(-2) + '-' + ('0' + today.getDate()).slice(-2)
}

export const dateTimeConvert = () => {
    let today = new Date();
    return today.getFullYear() + '-' + ('0' + (today.getMonth() + 1)).slice(-2) + '-' + ('0' + today.getDate()).slice(-2) + 'T' + today.getHours() + ':' + ('0' + today.getMinutes()).slice(-2) + ':' + ('0' + today.getSeconds()).slice(-2);
};

export const gmtConvert = (date) => {
    let myDate = new Date(date);
    return new Date(myDate.toLocaleDateString());
}

export const dateStringConvert = (date) => {
    let myDate = new Date(date);
    return myDate.toDateString();
}

export const gmtDateConvert = (date) => {
    let today = new Date(date);
    return ('0' + today.getDate()).slice(-2) + '-' + ('0' + (today.getMonth() + 1)).slice(-2) + '-' + today.getFullYear()
}

export const dateStrokeConvert = (date) => {
    let today = new Date(date);
    return ('0' + today.getDate()).slice(-2) + '/' + ('0' + (today.getMonth() + 1)).slice(-2) + '/' + today.getFullYear()
}

export const convertGmtTime = (date) => {
    let today = new Date(date)
    const dt = new Date().toString();
    const arr = dt.split(' ');
    return ('0' + today.getDate()).slice(-2) + '/' + ('0' + (today.getMonth() + 1)).slice(-2) + '/' + today.getFullYear() + ' ' + arr.slice(4, 5).join(' ');
}

export const removeGmt =  (date) =>{
    const arr = date.split(' ');
    return arr.slice(0, 5).join(' ')
}

export const extractTime = (date) => {
    let time = new Date(date).toString();
    time = time.split(' ').slice(4, 5).join(' ');
    return time
}
export const extractDate = (date) => {
    return new Date(date).toDateString()
}

export const getCurrentDate = () => {
    let curr = new Date(); // get current date
    let first = curr.getDate(); // First day is the day of the month - the day of the week
    let last = first + 6; // last day is the first day + 6

    let firstDay = convDate(new Date(curr.setDate(first)));
    let lastDay = convDate(new Date(curr.setDate(last)));
   return {firstDay, lastDay}
}

export const convertMonth = (month) =>{
    const months = {'01':'January', '02':'February','03':'March','04':'April','05':'May',
    '06':'June', '07':'July','08':'August','09':'September','10':'October','11':'November','12':'December'};
    const mths = month.split('-')[1];
    const yr = month.split('-')[0];
    return `${months[mths]}, ${yr}`
}

export const convertMonthToString = (month) =>{
    const months = {'0':'January', '1':'February','2':'March','3':'April','4':'May',
    '5':'June', '6':'July','7':'August','8':'September','9':'October','10':'November','11':'December'};
    return `${months[month]}`
}

export const getActualMonthValue = (month) => {
    const months = {'0':'01', '1':'02','2':'03','3':'04','4':'05',
    '5':'06', '6':'07','7':'08','8':'09','9':'10','10':'11','11':'12'};
    return `${months[month]}`
}

export const validateCurrentDate = (value) =>{
    const current_date = new Date()
    const date_selected = new Date(value)
    const formatted_current_date = formatCurrentDate(current_date).split('T').slice(0,1).join('')
    const formatted_selected_date = formatCurrentDate(date_selected).split('T').slice(0,1).join('')
    return formatted_selected_date <= formatted_current_date
}