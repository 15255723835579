import {useEffect, useState,useRef} from "react";
import axios from "axios";
import config from "../../../Helpers/config.json";
import {errorMessages} from "../../../Helpers/ErrorMessages";


const useAddUnit = (actions) =>{
    const [unit_name, setUnitName] = useState('');
    const [unit_id, setUnitId] = useState('');
    const [openDialog, setOpenDialog] = useState(false);
    const [openEdit, setOpenEdit] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState('idle');
    const [unit, setUnit] = useState({});
    

    useEffect(() => {
        if (!unit_id) {
            return;
        }
        const formData = new FormData();
        formData.append('id', unit_id);
        formData.append('unit_name', '');
        formData.append('status', '');
        axios.post(`${config.epharmUrl}/Cproduct/unit_form`, formData).then(response => {
            const data = response.data;
            const dt = data ? data : {}
            const un = dt.unit ? dt.unit : {}
            setUnit(un)
        }).catch(error => {
            errorMessages(error, null, actions);
        })
    }, [unit_id])

    useEffect(() => {
        if (!unit_id){
            return
        }
        const {unit_name:name} = unit
        setUnitName(name)
    }, [unit, unit_id])

    const handleChange = event => {
        const {value} = event.target;
        setUnitName(value)
    };
    const handleSubmit = event => {
        event.preventDefault();
        const formData = new FormData();
        if (unit_id) {
            formData.append('id', unit_id);
        }
        formData.append('unit_name', unit_name);
        formData.append('status', 1);
        setSubmitted(true)
        if (unit_name) {
            setIsSubmitted('pending');
            axios.post(`${config.epharmUrl}/Cproduct/unit_form`, formData).then(() => {
                actions.snackbarActions.successSnackbar('unit added successfully')
                setIsSubmitted('resolved')
                setOpenDialog(false)
                setUnitName('')
                setOpenEdit(false)
                setSubmitted(false)
            }).catch(error => {
                //errorMessages(error, null, actions)
                actions.snackbarActions.errorSnackbar("Unit already inserted")
                setIsSubmitted('rejected')
            })
        }
    };

    const handleOpenEdit = (_id) => {
        setUnitId(_id)
        setOpenEdit(true)
    };

    const handleCloseEdit = () => {
        setOpenEdit(false)
        setUnitName('')
    }


    const handleOpenDialog = () => {
        setOpenDialog(true)
    }

    const handleCloseDialog = () => {
        setOpenDialog(false)
    };



    const isPending = isSubmitted === 'pending'
    const isResolved = isSubmitted === 'resolved'

   
    const unitProps = {unit_name, openDialog, handleCloseDialog, handleOpenDialog, submitted, isPending,
    openEdit, handleChange, handleSubmit, handleOpenEdit, handleCloseEdit}

    return {unitProps, isResolved}
}
export {useAddUnit}