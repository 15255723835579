import {useEffect, useRef, useState} from "react";
import axios from "axios";
import config from "../../../Helpers/config.json";
import {exportPDF} from "../../../Utils/exportPDF";
import { convertDate } from "../../../Utils/titleCaseFunction";

/**
 * Hook for ManufacturersSalesDetails
 * @param {*} match 
 * @returns 
 */
export const useManufacturerSalesDetails = (match = {}) => {
    const [sales, setSales] = useState([]);
    const [total_count, setTotalCount] = useState(0);
    const [term, setTerm] = useState('');
    const [activePage, setActivePage] = useState(1);
    const [loading, setLoading] = useState(false);
    const [manufacturer_name, setManufacturerName] = useState('');
    const [filter_dates, setFilterDates] = useState({start_date : convertDate(), end_date: convertDate()});
    const [submitted, setSubmitted] = useState(false);
    const componentRef = useRef();
    const manufacturer_id = match.params['manufacturer_id'];
    const isPending = submitted;

    // get the initial list, no need for specifying a date here
    useEffect(() => {
        setLoading(true);
        const formD = new FormData();
        formD.append('manufacturer_id',manufacturer_id);

        axios.post(`${config.epharmUrl}/Cmanufacturer/manufacturer_sales_details_all/${activePage}`, formD).then(response => {
            const data = response.data;
            const dt = !data ? {} : data;
            const ct = !dt.total_count ? {} : dt.total_count;
            const count = ct.count ? ct.count : 0;
            const arr = !dt.sales_info ? [] : dt.sales_info;
            setSales(arr);
            setTotalCount(count);
            setLoading(false);
            setManufacturerName(dt.manufacturer_name ? dt.manufacturer_name : '');
        }).catch(error => {
            setLoading(false);
        })
    }, [activePage])

    // hook for the search component
    const handler = (event) => {
        const {value} = event.target;
        setTerm(value);
        event.preventDefault();

        const formD = new FormData();
        formD.append('manufacturer_id',manufacturer_id);
        setLoading(true);

        if (value) {
            formD.append('search', value);
        }

        axios.post(`${config.epharmUrl}/Cmanufacturer/manufacturer_sales_details_all/${activePage}`, formD).then(response => {
            const data = response.data;
            const dt = !data ? {} : data;
            const ct = !dt.total_count ? {} : dt.total_count;
            const count = ct.count ? ct.count : 0;
            const arr = !dt.sales_info ? [] : dt.sales_info;
            setSales(arr);
            setTotalCount(count);
            setLoading(false);
            setManufacturerName(dt.manufacturer_name ? dt.manufacturer_name : '');
            setSubmitted(false);
        }).catch(error => {
            // errorMessages(error, null, actions)
            setLoading(false);
            setSubmitted(false);
        })

    }

    const handlePageChange = (pageNumber) => {
        setActivePage(pageNumber);
    };

    const data = !sales ? [] : sales;
    const csv_dt = data.map((item) => {
        const {discount, status, id, ...new_dict} = item;
        return new_dict;
    })

    const downloadPDF = () => {
        const manufs = data ?? [];
        exportPDF(`Sales by Supplier : ${manufacturer_name}`, [["SL", "Purchase Date", "Invoice Number", "Medicine Name", 
            "Batch ID", "Expiry Date", "Quantity", "Purchase Price", "Total Price"]],
            manufs.map((manufacturer, index) => [index += 1, manufacturer.purchase_date, manufacturer.invoice_no, manufacturer.product_name,
                manufacturer.batch_id, manufacturer.expeire_date, manufacturer.quantity, manufacturer.manufacturer_price,
                manufacturer.total_amount]), `Supplier-${manufacturer_name}.pdf`)
    };

    // hook to handle change of the date filters
    const handleChange = (event) => {
        const {name, value} = event.target;
        setFilterDates({...filter_dates, [name] : value});
    }

    // hook for the submission of the date filters
    const handleSubmit = (event) => {
        event.preventDefault();
        setLoading(true);
        const formD = new FormData();
        formD.append('manufacturer_id',manufacturer_id);
        formD.append('start_date', filter_dates['start_date']);
        formD.append('end_date', filter_dates['end_date']);
        setSubmitted(true);
        axios.post(`${config.epharmUrl}/Cmanufacturer/manufacturer_sales_details_all/${activePage}`, formD).then(response => {
            const data = response.data;
            const dt = !data ? {} : data;
            const ct = !dt.total_count ? {} : dt.total_count;
            const count = ct.count ? ct.count : 0;
            const arr = !dt.sales_info ? [] : dt.sales_info;
            setSales(arr);
            setTotalCount(count);
            setLoading(false);
            setManufacturerName(dt.manufacturer_name ? dt.manufacturer_name : '');
            setSubmitted(false);
        }).catch(error => {
            setLoading(false);
            setSubmitted(false);
        })
    }

    const {start_date, end_date} = filter_dates;

    return {
        sales, total_count, term, activePage, loading, manufacturer_name, componentRef, manufacturer_id, handleChange,
        handler, data, csv_dt, downloadPDF, handlePageChange, submitted, handleSubmit, isPending, start_date, end_date, 
    }

}