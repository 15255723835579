import {useEffect, useState} from "react";
import {dateConvert} from "../../../../Utils/ConvertDate";
import axios from "axios";
import config from "../../../../Helpers/config.json";
import {errorMessages} from "../../../../Helpers/ErrorMessages";

function useReceiveDebtorsPayments(actions, obj) {
    const [state, setState] = useState({payment_method:'', payment_date:dateConvert()})
    const [invoices, setInvoices] = useState([])
    const [isFull, setIsFull] = useState(false)
    const [amountReceived, setAmountReceived] = useState(0)
    const [customer, setCustomer] = useState({customer_name:'', due_amount:'', total_amount:0})
    const [submitted, setSubmitted] = useState(false)
    const [isSubmitted, setIsSubmitted] = useState('idle')
    const [openDialog, setOpenDialog] = useState(false)
    const [accounts, setAccounts] = useState([])
    const [debitAccount, setDebitAccount] = useState('')
    const {customer_id, customer_name:cus_name, data} = obj
    const {due_amount} = customer



    useEffect(() => {
        axios.get(`${config.epharmUrl}/accounting/ledgers_under_category`).then(res => {
            const data = res.data;
            const dt = data ? data : {};
            const arr = dt.data ? dt.data : []
            const list = arr.map(item => ({
                value: item.id,
                label: item.head_name
            }))
            setAccounts(list)
        }).catch(err => {
            errorMessages(err, null, actions)
        })
    }, [])


    const calculateTotal = (name='',arr=[]) =>{
        const total = arr.reduce((a, item)=>{
            const due_amount = item.due_amount ? item.due_amount : item.total_amount
            return +a + +due_amount
        },0)
        setCustomer({customer_name:name, due_amount:total})
    }

    useEffect(()=>{
        if(!data && cus_name){
            return
        }
        const arr = data?.map(item=>({
            ...item,
            payment:'',
            isFullAmount:false
        }))
        calculateTotal(cus_name, data)
        setInvoices(arr)
    },[data, cus_name])



    const handleOpenDialog = () =>{
        setOpenDialog(true)
    }

    const handleCloseDialog = () =>{
        setOpenDialog(false)
    }

    const handleChange = (event) =>{
        const {name, value} = event.target;
        setState({...state, [name]:value})
    }

    const handleIsFullBill = (event) =>{
        setIsFull(event.target.checked)
        const full_amount = event.target.checked ? due_amount : ''
        const arr = invoices.map(item=>({
            ...item,
            payment: event.target.checked ? item.total_amount: ''
        }))
        setAmountReceived(full_amount)
        setInvoices(arr)

    }
    const handleChangeReceived = (event) =>{
        setAmountReceived(event.target.value)
    }

    const calculateTotalAmount = (arr=[]) =>{
        const total = arr.reduce((a, item)=>{
            return a + +item.payment
        }, 0)
        setCustomer({...customer, total_amount:total})
    }

    const handleChangePayment = (event,inv_id) =>{
        const {value} = event.target
        const arr = invoices.map(item=>{
            if (item.invoice_id === inv_id){
                return {...item, payment:value}
            }
            return item
        })
        calculateTotalAmount(arr)
        setInvoices(arr)
    }

    const handleChangeDebitAccount = (value) =>{
        setDebitAccount(value)
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        setSubmitted(true);
        if (due_amount) {
            setIsSubmitted('pending')
            axios.post(`${config.epharmUrl}/Cinvoice/clear_debt`, {paid_amount:amountReceived,customer_id,
            debit_to:debitAccount?.value}).then(() => {
                actions.snackbarActions.successSnackbar('Debt cleared successfully')
                setIsSubmitted('resolved')
                setSubmitted(false)
                setAmountReceived(0)
                setDebitAccount(null)
                setOpenDialog(false)
            }).catch(err => {
                errorMessages(err, null, actions)
                setIsSubmitted('rejected')
            })
        }
    }





    const isPending = isSubmitted === 'pending'
    const isResolved = isSubmitted === 'resolved'

    const paymentProps = {state, isPending, submitted, isFull, amountReceived, customer, openDialog,invoices,
    handleChangePayment, handleChange, handleChangeReceived, handleCloseDialog, handleOpenDialog, handleIsFullBill,
    handleSubmit, debitAccount, accounts, handleChangeDebitAccount}

    return {paymentProps, isResolved}

}
export {useReceiveDebtorsPayments}