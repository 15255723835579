import React, {useCallback, useEffect, useState} from "react";
import {Link} from "react-router-dom";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import Form from "react-bootstrap/Form";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Tooltip from '@material-ui/core/Tooltip';
import * as snackbarActions from "../../Redux/Actions/SnackbarActions/SnackbarActions";
import {titleCase, dateStringTimeConvert} from "../../Utils/titleCaseFunction";
import {CancelDialog} from "../../Utils/DeleteDialog";
import config from "../../Helpers/config";
import axios from "axios";
import {errorMessages} from "../../Helpers/ErrorMessages";
import {CustomPagination} from "../../Utils/Tables/CustomTable";
import ReusableDashboardHeader from "../../Containers/Dasboard/ReusableDashboardHeader";
import {EnhancedTableHead, EnhancedTableToolbar, MuiPaper} from "../../Utils/Tables/CustomTable";
import PharmacySnackbar from "../../Utils/Snackbars/SnackbarUtil";
import TableContainer from "@material-ui/core/TableContainer";
import {ReusableRouteTabs} from "../../Utils/Tabs/ReusableTabs";
import {history} from "../../Helpers/history";
import {formatAmount} from "../../Utils/formatNumbers";
import VisibilityIcon from '@material-ui/icons/Visibility';
import CloseIcon from '@material-ui/icons/Close';
import {makeStyles} from "@material-ui/core/styles";
import {useSalesFilter} from "./CustomHooks/useSalesFilter";
import {InvoiceDateFilter} from "./InvoiceDateFilter";
import {LoadingGif} from "../../Utils/Loader";
import CustomDialog from "../../Utils/Dialog/CustomDialog";
import {ReusablePosTemplate} from "./ReusablePosTemplate";
import {useExcelReports} from "./CustomHooks/useExcelReports";
import Textarea from "../../Utils/FormInputs/Textarea";
import Label from "../../Utils/FormInputs/Label";
import PrintOutlinedIcon from "@material-ui/icons/PrintOutlined";
import { usePrint } from "../../Utils/Template/usePrint";
import { formatDigits } from "../../Utils/formatAmount";


const useStyles = makeStyles((theme) => ({
    icon: {
        width:'0.8em',
        height:'0.8em',
    },
}));


const ManageInvoice = ({actions, snackbars}) => {
    const storage = JSON.parse(sessionStorage.getItem('total_count'))
    const item_count = storage ? storage : 0
    const count = () => +item_count || 0
    const [term, setTerm] = useState('');
    const [activePage, setActivePage] = useState(1);
    const [openDialog, setOpenDialog] = useState(false);
    const [invoice_id, setInvoiceId] = useState('');
    const [totalAmount, setTotalAmount] = useState(0);
    const [total_Amount, setAmount] = useState(0)
    const [invoices, setInvoices] = useState([]);
    const [total_count, setTotalCount] = useState(count);
    const [loading, setLoading] = useState('idle');
    const [submitted, setSubmitted] = useState(false)
    const [isSubmitted, setIsSubmitted] = useState('idle')
    const {date, handleChange, filters} = useSalesFilter()
    const {filter_by, start_date, end_date} = date
    const [isCanceled, setIsCanceled] = useState('idle')
    const [data, setData] = useState({products:[], customer:{}, loading:'idle'});
    const [openInvoice, setOpenInvoice] = useState(false)
    const [openConfirmation, setOpenConfirmation] = useState(false)
  
    const [submit, setHasSubmitted] = useState(false);
    const [reason,setReason] = useState('')
    const {handlePrint, componentRef, getPageMargins} = usePrint("")
    useEffect(()=>{
        setActivePage(1)
    },[filter_by])

    // const calculateTotalAmount = (arr) => {
    //     const sum = arr.reduce((a, invoice) => {
    //         return +(a + invoice.due_amount);
    //     }, 0);
    //     setTotalAmount(sum)
    // };


    const isInvoiceCanceled = isCanceled === 'resolved'
    const isCanceledPending = isCanceled === 'pending'
    const isResolved = isSubmitted === 'resolved'


    const retrieveInvoices = useCallback((start_date='', end_date, page=activePage) => {
        const isCustom = filter_by === 'custom' ? {start_date, end_date} : {}
        setLoading('loading')
        axios.get(`${config.epharmUrl}/Cinvoice/invoices`,{params:{category:'invoices', time_filter:filter_by,
                page_number:page, search:term, ...isCustom}}).then(response => {
            const data = response.data;
            const dt = !data ? {} : data;
            const dat = dt.data ? dt.data : {}
            const arr = dat.invoices ? dat.invoices : {}
            const invoicesArr = arr.invoice_list ? arr.invoice_list : []
            const t_amount =  arr.grand_total_amount ? arr.grand_total_amount : 0
            const total_amount =arr.total_amount ? arr.total_amount : 0
            const t_count = dat.total_records ? dat.total_records : 0;
            if(activePage === 1){
                sessionStorage.setItem('total_count', JSON.stringify(t_count))
                setTotalCount(t_count)
            }else {
                setTotalCount(count)
            }
            setInvoices(invoicesArr);
            setTotalAmount(t_amount)
            setAmount(total_amount)
            setLoading('success')
        }).catch(error => {
            errorMessages(error, null, actions)
            setLoading('error')
        });
    },[activePage,  filter_by,isInvoiceCanceled, term])

    useEffect(() => {
        if (filter_by === 'custom' && !isResolved){
            return
        }
        retrieveInvoices(start_date, end_date)
    }, [retrieveInvoices])

    const handler = (event) => {
        setTerm(event.target.value)
    };

    const handleFilter = (event) =>{
        event.preventDefault()
        setSubmitted(true)
        if (start_date && end_date && start_date < end_date){
            setIsSubmitted('resolved')
            retrieveInvoices(start_date, end_date)
        }
    }

    const handleCloseSnackbar = () => {
        actions.snackbarActions.hideSnackBar();
    };

    const handleOpenDialog = (_id) => {
        setInvoiceId(_id);
        setOpenDialog(true)
    };

    const handleCloseDialog = () => {
        setOpenDialog(false)
    };

    const handleChangeReason = (event) => {
        setReason(event.target.value)
    };

    const handleOpenInvoice = (invoice_number, cus_id) => {
        const formData = new FormData()
        formData.append('receipt_number', '')
        formData.append('invoice_number', invoice_number)
        formData.append('customer_id', cus_id)
        setData({...data, loading: 'loading'})
        axios.post(`${config.epharmUrl}/Cinvoice/view_receipt`, formData).then(response => {
            const dataRes = response.data;
            const dt = dataRes ? dataRes : {};
            const arr = dt.bill ? dt.bill : [];
            const customer_details = dt.customer_details ? dt.customer_details : {}
            const obj = arr[0] ? arr[0] : {}
            setData({...data,products: arr,customer: {...customer_details,...obj}, loading: 'success'})
            setLoading('success')

        }).catch(error => {
            errorMessages(error, null, actions)
            setData({...data, loading: 'error'})
        })
        setInvoiceId(invoice_number);
        setOpenInvoice(true)
    };

    const handleOpenPrint = (invoice_number, customer_id) => {
        const formData = new FormData()
        formData.append('receipt_number', '')
        formData.append('invoice_number', invoice_number)
        formData.append('customer_id', customer_id)
        setData({...data, loading: 'loading'})
        axios.post(`${config.epharmUrl}/Cinvoice/view_receipt`, formData).then(response => {
            const dataRes = response.data;
            const dt = dataRes ? dataRes : {};
            const arr = dt.bill ? dt.bill : [];
            const customer_details = dt.customer_details ? dt.customer_details : {}
            const obj = arr[0] ? arr[0] : {}
            setData({...data,products: arr,customer: {...customer_details,...obj}, loading: 'success'})
            setLoading('success')
            handlePrint()
        }).catch(error => {
            errorMessages(error, null, actions)
        })
        setInvoiceId(invoice_number);
    
    };

    const handleCloseInvoice = () => {
        setOpenInvoice(false)
    };

    const handleDelete = (is_draft=false) => {
        setHasSubmitted(true)
        if (reason) {

            setIsCanceled('pending')
            axios.post(`${config.epharmUrl}/Cinvoice/cancel_invoice`, {
                invoice_number: invoice_id,
                reason: reason
            }).then(() => {
                actions.snackbarActions.successSnackbar('Invoice canceled Successfully');
                setIsCanceled('resolved')
                setHasSubmitted(false)
                setReason('')
                setOpenDialog(false)
                setOpenInvoice(false)
                if (is_draft) {
                    sessionStorage.setItem(invoice_id, JSON.stringify(data))
                    history.push(`/createinvoice/new-invoice/${invoice_id}`)
                }
                setOpenConfirmation(false)
            }).catch(error => {
                errorMessages(error, null, actions)
                setIsCanceled('rejected')
            })
        }

    }

    const handleOpenConfirmation = () =>{
        setHasSubmitted(true)
        if (reason) {
            setOpenConfirmation(true)
        }
    }

    const handleCloseConfirmation = () =>{
        setOpenConfirmation(false)
    }

    const handleSaveDraft = () =>{
        handleDelete(true)
    }

    const handleCancelInvoice = () =>{
        handleDelete()
    }

    const handlePageChange = (pageNumber) => {
        setActivePage(pageNumber)
    };

    const isCustom = filter_by === 'custom' ? {start_date, end_date} : {};
    const currentObj = {file_name:'Invoices', url:'Cinvoice/manage_sales_excel_report',
        params: {
            category:'invoices', time_filter: filter_by, ...isCustom
        }
    }

    const {exportExcel} = useExcelReports(actions, currentObj)

    const handleExcelExport = () => {
        if (invoices?.length === 0) {
            actions.snackbarActions.errorSnackbar('There are no records to be downloaded');
        } else {
            exportExcel();
        }
    }

    const allInvoices = !invoices ? [] : invoices;
    const {variant, message, open} = snackbars;

    const headData = [{id: 'sl', numeric: false, disablePadding: false, label: 'Sl'},
        {id: 'invoice', numeric: false, disablePadding: false, label: 'Invoice#'},
        {id: 'name', numeric: false, disablePadding: false, label: 'Customer Name'},
        {id: 'date', numeric: false, disablePadding: false, label: 'Date'},
        {id: 'total', numeric: false, disablePadding: false, label: 'Total Amount'},
        {id: 'amount_due', numeric: false, disablePadding: false, label: 'Amount Due'},
        {id: 'action', numeric: false, disablePadding: false, label: 'Action'}];
        //{id: 'nonaction', numeric: false, disablePadding: false, label: ''}];

    const components = [{label: 'Receipts', path: '/sales-receipts'},
        {label: 'Invoices', path: '/manageinvoice'},
        {label: 'Cancelled Sales', path: '/cancelledsales'},
    ];

    const classes = useStyles()

    const {products, customer, loading:loadingInvoice} = data
    const {first_name, last_name} = customer;
    const user_name = `${!first_name ? "" : first_name} ${!last_name ? "" : last_name}`;


    const isLoading = loading === 'loading'
    const isSuccess = loading === 'success'
    const isError = loading === 'error'

    const isLoadingInvoice = loadingInvoice === 'loading'
    const isSuccessInvoice = loadingInvoice === 'success'
    const isErrorInvoice = loadingInvoice === 'error'

    const buttons = (
        <>
            <button className="btn pharmacy-info-btn  btn-sm mr-3"
                    onClick={() => handleOpenDialog(invoice_id)}>Continue</button>
            <button className="btn pharmacy-grey-btn btn-sm" onClick={handleCloseInvoice}>No</button>
        </>
    )

    const reasonForm = (
        <Form.Group>
            <Label name='Please enter reason for cancelling invoice' type/>
            <Textarea type="text" placeholder="please type reason here..." name="Reason"
                      value={reason} onChange={handleChangeReason} submitted={submit}/>
            {(submit && !reason) &&
                <div className="invalid-text">Please enter a reason</div>}

        </Form.Group>
    )
    return (

        <div data-testid="manage_invoice">
            <ReusableDashboardHeader component="Manage Sales" heading="Sales"
                                     subHeading="Invoices" link="/manageinvoice"/>

            <div className="receipt-container">
                <ReusableRouteTabs value={history.location.pathname} tabHeading={components}/>
                <PharmacySnackbar open={open} message={message} variant={variant}
                                  handleCloseBar={handleCloseSnackbar}/>
                <CustomDialog maxWidth='md' title='Cancel Invoice' open={openInvoice} handleClose={handleCloseInvoice} buttons={buttons}
                isOverflow={false}>
                    {isLoadingInvoice ? <div className='text-center mt-5'><LoadingGif/></div>:null}
                    {isSuccessInvoice ? <ReusablePosTemplate {...{customer, products, user_name}}/> : null}
                    {isErrorInvoice ? <div className='text-center mt-5'>The server did not return a valid response</div>:null}
                </CustomDialog>

                <CancelDialog openDialog={openDialog}
                              handleClose={handleCloseDialog}
                              heading='Cancel Invoice' message={reasonForm}>
                    <button className="btn pharmacy-info-btn  btn-sm mr-3" disabled={isCanceledPending}
                            onClick={handleOpenConfirmation}>{isCanceledPending ? 'Continuing...' : 'Cancel Invoice'}</button>
                    <button className="btn pharmacy-grey-btn btn-sm" onClick={handleCloseDialog}>Don't Cancel Invoice</button>
               
                </CancelDialog>

                <CancelDialog openDialog={openConfirmation}
                              handleClose={handleCloseConfirmation}
                              heading='Use invoice as draft'
                              message="Would you like to use this invoice as a draft?">
                    <button className="btn pharmacy-info-btn  btn-sm mr-3"
                            onClick={handleSaveDraft}>Yes</button>
                    <button className="btn pharmacy-grey-btn btn-sm" onClick={handleCancelInvoice}>No</button>
                </CancelDialog>
                <div className=" my-3">
                    <InvoiceDateFilter {...{filters,date, handleChange, handleFilter, submitted}}/>
                </div>
                <div style={{display:'none'}}>
                    <div ref={componentRef} className="card styled-pos-card">
                        {getPageMargins}
                        <ReusablePosTemplate {...{customer, products, user_name}}/>
                    </div>
                </div>
                <MuiPaper>
                    <EnhancedTableToolbar title='Invoices'>
                             <span className="btn-group" style={{flex: '1 1 100%'}}>
                                 <span><button onClick={handleExcelExport} className="btn pharmacy-btn mr-1 btn-sm">Excel</button></span>
                            </span>
                        <Form.Control type="text" value={term ? titleCase(term) : ''}
                                      data-testid="search_list"
                                      onChange={handler}
                                      className='form__control'
                                      placeholder="Search" style={{float: 'right'}}/>
                    </EnhancedTableToolbar>
                    <div className="sub-journal-bar" style={{marginLeft:'0'}}>
                                <h6 data-testid="total_receipts">Total Receipts : <strong>{formatDigits(total_count)}</strong></h6>
                                
                                <h6 data-testid="running_balance">Total Amount : <strong>{formatAmount(totalAmount)}</strong></h6>
                           
                        </div>
                     <TableContainer>
                        <Table
                            aria-labelledby="tableTitle"
                            size='medium'
                            aria-label="enhanced table"
                        >
                            <EnhancedTableHead headCells={headData}/>
                                <TableBody>
                                    {isLoading ? <TableRow>
                                            <TableCell colSpan={headData.length} align="center"><LoadingGif/></TableCell>
                                        </TableRow>:null}
                                    {isSuccess ? allInvoices.length > 0 ?
                                        <>
                                        {allInvoices.slice(0, 10)
                                        .map((info, index) => {
                                            const sl = ((activePage - 1) * 10) + index + 1;
                                            return (
                                                <TableRow
                                                    hover
                                                    role="checkbox"
                                                    tabIndex={-1}
                                                    key={index}
                                                    className='mui-table-row'>
                                                    <TableCell
                                                        component="th" scope="row" padding="default"
                                                               className='mui-table-cell'>
                                                        <span>{sl}</span>
                                                    </TableCell>
                                                    <TableCell data-testid={"sale_number-"+index}>
                                                        <span>{info.invoice_number}</span>
                                                    </TableCell>
                                                    <TableCell data-testid={"customer_name-"+index}>
                                                        <span>{titleCase(info.customer_name)}</span>
                                                    </TableCell>
                                                    <TableCell data-testid={"sale_date-"+index}>
                                                        <span>{info.date ? dateStringTimeConvert(info.date) : ''}</span>
                                                    </TableCell>
                                                    <TableCell data-testid={"total_amount-"+index}>
                                                        <span>{formatAmount(info.total_amount)}</span>
                                                    </TableCell>
                                                    <TableCell data-testid={"due_amount-"+index}>
                                                        <span>{formatAmount(info.due_amount)}</span>
                                                    </TableCell>
                                                    {/*{(manage_invoice.create || manage_invoice.update ||*/}
                                                    {/*    manage_invoice.delete) ?*/}
                                                        <TableCell id="mainbuttons">
                                                            <Tooltip title="View POS Invoice"
                                                                     placement="left-start">
                                                                <Link
                                                                    to={{pathname: `/invoice_print/${info.customer_id}/${info.invoice_number}`}}>
                                                                    <button data-testid={"view_sale-"+index} className="btn btn-sm pharmacy-btn icon-button-holder mr-3 mb-sm-2">
                                                                        <VisibilityIcon className={classes.icon}/>
                                                                    </button>
                                                                </Link>
                                                            </Tooltip>
                                                            <Tooltip title='Print'>
                                                            <button type='button' onClick={() => handleOpenPrint(
                                                                    info.invoice_number,info.customer_id )}
                                                                    className="btn btn-sm pharmacy-gray-btn icon-button-holder mb-sm-2">
                                                                <PrintOutlinedIcon classes={{root: classes.icon}}/>
                                                            </button>
                                                        </Tooltip>
                                                             <Tooltip title="Cancel Invoice"
                                                             placement="left-start">
                                                                <button
                                                                    data-testid={"cancel_sale-"+index}
                                                                    onClick={() => handleOpenInvoice(info.invoice_number, info.customer_id)}
                                                                    className="btn btn-sm pharmacy-gray-btn icon-button-holder mb-sm-2">
                                                                    <CloseIcon className={classes.icon}/>
                                                                </button> 
                                                            </Tooltip>
                                                        </TableCell>

                                                </TableRow>
                                            );
                                        })}
                                    <TableRow>
                                        <TableCell colSpan={5} className="text-right" style={{fontWeight:'700'}}>
                                            Total Amount Per Page: 
                                        </TableCell>
                                        <TableCell data-testid="totalAmount" style={{fontWeight:'700'}}>{formatAmount(total_Amount)}</TableCell>
                                        <TableCell/>
                                    </TableRow>
                                    </> : <TableRow>
                                            <TableCell colSpan={headData.length} align="center">No records found</TableCell>
                                        </TableRow> : null}
                                    {isError ? <TableRow>
                                        <TableCell colSpan={headData.length} align="center">The server did not return a valid response</TableCell>
                                    </TableRow> : null}
                                </TableBody>
                        </Table>
                        <CustomPagination data={allInvoices} records={10} activePage={activePage} total_count={total_count}
                                      handleChangeNextPage={handlePageChange}/>
                     </TableContainer>
                </MuiPaper>
            </div>
        </div>
    )


}

function mapStateToProps(state) {
    return {
        snackbars: state.snackbars
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            snackbarActions: bindActionCreators(snackbarActions, dispatch)
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ManageInvoice);
