import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import Pagination from "react-js-pagination";
import {CenteredContainer} from "../styledComponents";


const useStyles = makeStyles((theme) => ({
    root: {
        flexShrink: 0,
        marginLeft: theme.spacing(5),
    },
    toolbarRoot: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(1),
    },
    title: {
        flex: '1 1 100%',
        fontSize: '1.09rem'
    },
    tableRoot: {
        width: '100%',
    },
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
    },
    table: {
        minWidth: 750,
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
    spacer:{
        flex:'1 1 100%'
    }
}));

export function EnhancedTableHead({order, orderBy,  headCells=[],onRequestSort=()=>{}}) {
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    const classes = useStyles()

    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? 'right' : 'left'}
                        padding={headCell.disablePadding ? 'none' : 'default'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            // onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

export function TablePaginationActions({count, page, rowsPerPage, onChangePage}) {
    const classes = useStyles();
    const theme = useTheme();

    const handleFirstPageButtonClick = (event) => {
        onChangePage(event, 0);
    };

    const handleBackButtonClick = (event) => {
        onChangePage(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
        onChangePage(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
        onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
        <div className={classes.root}>
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label="first page"
            >
                {theme.direction === 'rtl' ? <LastPageIcon/> : <FirstPageIcon/>}
            </IconButton>
            <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
                {theme.direction === 'rtl' ? <KeyboardArrowRight/> : <KeyboardArrowLeft/>}
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="next page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowLeft/> : <KeyboardArrowRight/>}
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="last page"
            >
                {theme.direction === 'rtl' ? <FirstPageIcon/> : <LastPageIcon/>}
            </IconButton>
        </div>
    );
}

TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onChangePage: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
};


export const EnhancedTableToolbar = ({title, children}) => {
    const classes = useStyles();
    return (
        <Toolbar className={clsx(classes.toolbarRoot)}>
            <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
                {title}
            </Typography>
            {/*<div className="col col-md-4 col-sm-3" style={{minWidth: 200}}>*/}
            <div className={classes.spacer}/>
                {children}
            {/*</div>*/}
        </Toolbar>
    );
};

export const CustomPagination = ({activePage, records, total_count, handleChangeNextPage, data = []}) => {
    return (
        <CenteredContainer>

                    {data.length > 0 && <Pagination
                        prevPageText='Prev'
                        nextPageText='Next'
                        firstPageText='First'
                        lastPageText='Last'
                        itemClass="page-item"
                        linkClass="page-link"
                        activePage={activePage}
                        itemsCountPerPage={records}
                        totalItemsCount={total_count}
                        pageRangeDisplayed={5}
                        onChange={handleChangeNextPage}
                    />}

        </CenteredContainer>
    )
}

export const MuiPagination = ({rowsPerPage,
                                  page,
                                  handleChangePage,
                                  handleChangeRowsPerPage,
                                  colSpan,
                                  data = [],
                                  rows = [],
                                  isTotalCount=false,
                                  total_count=0
                              }) => {
    return (
        <TablePagination
            rowsPerPageOptions={rows}
            colSpan={colSpan}
            count={isTotalCount ? total_count : data.length}
            rowsPerPage={rowsPerPage}
            page={page}
            SelectProps={{
                inputProps: {'aria-label': 'rows per page'},
                native: true,
            }}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            ActionsComponent={TablePaginationActions}
            />
    )
}

export const MuiPaper = ({...props}) =>{
    const classes = useStyles();
    return <Paper className={classes.paper}  {...props}/>
}

