import React from 'react';
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form"
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import * as purchaseActions from "../../Redux/Actions/PurchaseActions/PurchaseActions"
import * as manufacturerActions from "../../Redux/Actions/ManufacturerActions/ManufacturerActions";
import * as invoiceActions from "../../Redux/Actions/InvoiceActions/PosInvoiceActions";
import * as snackbarActions from "../../Redux/Actions/SnackbarActions/SnackbarActions";
import PharmacySnackbar from "../../Utils/Snackbars/SnackbarUtil";
import {titleCase} from "../../Utils/titleCaseFunction";
import CsvDialog from "../../Utils/CsvDialog";
//import {CSVLink} from "react-csv";
import TextField from "../../Utils/FormInputs/TextField";
import Textarea from "../../Utils/FormInputs/Textarea";
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
import {Link} from "react-router-dom";
import {useAddPurchase} from "./CustomHooks/useAddPurchase";
import ReusableDashboardHeader from "../../Containers/Dasboard/ReusableDashboardHeader";
import PrintDialog from '../../Utils/Dialog/PrintDialog';
import InputGroup from 'react-bootstrap/InputGroup';
import DetailsDialog from "../../Utils/Dialog/DetailsDialog";
import {AddProductForm} from "../Medicine/AddProductForm";
import {ErrorMessage, RightAlignedContainer} from "../../Utils/styledComponents";
import Label from "../../Utils/FormInputs/Label"
import {formatAmount} from "../../Utils/formatAmount";
import {AutoCompleteControlled, AutoCompleteMedicine } from '../../Utils/FormInputs/AutoCompleteInput';
import {ManufacturerForm} from "../Manufacturer/ManufacturerForm";
import {CategoryForm, TypeForm, UnitForm} from "../Medicine/MedicineMetaComponents";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus} from "@fortawesome/free-solid-svg-icons/faPlus";
import Alert from "@material-ui/lab/Alert";
import {useCustomMediaQuery} from "../../Utils/hooks/useCustomMediaQuery";
import {validateCurrentDate} from "../../Utils/ConvertDate";
import {BlockNavigationComponent} from "../../Utils/ReusableComponents/BlockNavigationComponent";
import {history} from "../../Helpers/history";

const AddPurchase = ({actions, manufacturers, purchases, snackbars, match}) => {
    const {local_purchase_order_id, purchase_id} = match.params;
    const {
        rows, manufacturer_name: name, state, grand_total, openDialog, submitted,
        isSubmitted,  handleChangeValue, purchaseCsv,
        handleSubmitPurchase, handleChangeManufacturer, handleConfirmPurchase,
        handleChange, retrieveExpiry, handleAddRow, handleRemoveSpecificRow, handleSearchProduct, retrieveProductData,
        handleChangeQuantity, handleChangePrice, handleCloseBar, handleOpenDialog, handleCloseDialog, handleUploadCsv,
        handleChangePurchaseCsv, fetchData,  submitCsv,handleChangeBatchId, purchaseConfirmDialog,
        handleCloseConfirmDialog,handleChangeExpiryLimit, productProps, openProductDialog,
        handleOpenProductDialog, handleCloseProductDialog, manufacturerProps, handleChangeAmount, isBlocking
    } = useAddPurchase(actions, {local_purchase_order_id, purchase_id});
    const {purchase_date, invoice_no, details,expected_payment_date} = state;
    const {handleSaveSingleProduct, isPending} = productProps
    const {all_manufacturers} = manufacturers;
    const manufs = !all_manufacturers ? [] : all_manufacturers;
    const {allProducts} = purchases;
    const manufProducts = !allProducts ? [] : allProducts;
    const {open, message, variant} = snackbars;
    const title = purchase_id ? 'Edit Purchase' : 'New Purchase';
    const route = purchase_id ? `/editpurchase/${purchase_id}` :
        local_purchase_order_id ? `/addpurchase/${local_purchase_order_id}`:'/addpurchase';

    const {openDialog:openSupplier, handleCloseDialog:handleCloseSupplier, handleOpenDialog:handleOpenSupplier} = manufacturerProps
    const {unitProps, categoryProps, typeProps} = productProps
    const { openDialog:openUnit, handleCloseDialog:handleCloseUnit, handleOpenDialog:handleOpenUnit} = unitProps
    const { openDialog:openCategory, handleCloseDialog:closeCategoryDialog, handleOpenDialog:openCategoryDialog} = categoryProps
    const { openDialog:openType, handleCloseDialog:closeTypeDialog, handleOpenDialog:openTypeDialog} = typeProps

    const {handleSubmit, isPending:isPendingManufacturer} = manufacturerProps

    const isDesktop = useCustomMediaQuery('(min-width:1351px)');

    const validate_purchase_date = validateCurrentDate(purchase_date)


    return (
        <div className='journals'>
            <ReusableDashboardHeader component={title} heading={title}
                                     subHeading={title} link={route}/>
            <CsvDialog openDialog={openDialog} handleCloseDialog={handleCloseDialog} purchase
                       handleUploadCsv={handleUploadCsv} handleChangeUpload={handleChangePurchaseCsv}
                       message="Purchase" submitted={submitCsv} purchaseCsv={purchaseCsv}>
                    <span>
                        <button onClick={fetchData}
                                className="btn pharmacy-primary-btn">Download Sample File</button>
                        {/*<CSVLink
                        data={allPurchases}
                        ref={csvLink}
                        filename="Purchases.csv"
                        className="hidden"
                    /> */}
                    </span>
            </CsvDialog>

            <BlockNavigationComponent
                when={isBlocking}
                shouldBlockNavigation={()=>{
                    return isBlocking;
                }}
                navigate={path=>history.push(path)}
            />

            <PrintDialog message='Approve Purchase' openDialog={purchaseConfirmDialog}
                         handleClose={handleCloseConfirmDialog} handleCancel={handleCloseConfirmDialog}
                         handleClick={handleConfirmPurchase}  preview='No'
                         text='Are you sure you want to approve this purchase?'/>


            <DetailsDialog title='New Unit' openDialog={openUnit} handleClose={handleCloseUnit}>
                <UnitForm {...unitProps}/>
            </DetailsDialog>
            <DetailsDialog title='New Category' openDialog={openCategory} handleClose={closeCategoryDialog}>
                <CategoryForm {...categoryProps}/>
            </DetailsDialog>
            <DetailsDialog title='New Product Type' openDialog={openType} handleClose={closeTypeDialog}>
                <TypeForm {...typeProps}/>
            </DetailsDialog>
            <DetailsDialog openDialog={openProductDialog} maxWidth='lg' handleClose={handleCloseProductDialog} title='New Product'>
                <div className="text-right mb-5">
                    <button data-testid="quick_add_unit" type="button" onClick={handleOpenUnit} className="btn  pharmacy-info-btn btn-sm mr-4">
                        <FontAwesomeIcon icon={faPlus}/> Quick Add Unit
                    </button>
                    <button data-testid="quick_add_category" type="button" onClick={openCategoryDialog} className="btn  pharmacy-info-btn btn-sm mr-4">
                        <FontAwesomeIcon icon={faPlus}/> Quick Add Category
                    </button>
                    <button data-testid="quick_add_type" type="button" onClick={openTypeDialog} className="btn  pharmacy-info-btn btn-sm">
                        <FontAwesomeIcon icon={faPlus}/> Quick Add Product Type
                    </button>
                </div>
                <AddProductForm {...productProps}>
                    <RightAlignedContainer>
                        <button data-testid="submit_product" type="submit" onClick={handleSaveSingleProduct} disabled={isPending} className="btn pharmacy-btn px-3 mt-4">{isPending ? 'Saving...':'Save'}</button>
                    </RightAlignedContainer>
                </AddProductForm>
            </DetailsDialog>

            <DetailsDialog openDialog={openSupplier}  title='New Supplier' handleClose={handleCloseSupplier}>
                <ManufacturerForm {...manufacturerProps}>
                    <div className="text-center">
                        <button data-testid="save_supplier" type="button" onClick={handleSubmit} disabled={isPendingManufacturer}
                                className="btn pharmacy-info-btn small-btn btn-sm px-3 mr-3">{isPendingManufacturer ? 'Saving...': 'Save'}</button>
                    </div>
                </ManufacturerForm>
            </DetailsDialog>

            <div className="general-ledger-header">
                <Grid container spacing={0}>
                    <Grid item xs={6} md={5}>
                        {!(purchase_id || local_purchase_order_id) ?
                            <button className="btn pharmacy-info-btn btn-sm mx-3" onClick={handleOpenDialog}>Upload CSV
                                Purchase
                            </button> : null}
                    </Grid>
                    <Grid item xs={6} md={7}>
                        <div className="text-right">
                            <button onClick={handleOpenProductDialog}
                                    data-testid="quick_add_product"
                                    className='btn btn-sm pharmacy-info-btn mr-4'>Quick Add Product</button>
                            <button onClick={handleOpenSupplier}
                                    data-testid="quick_add_supplier"
                                    className='btn btn-sm pharmacy-info-btn mr-4'>Quick Add Supplier</button>
                            <Link to='/managepurchase'>
                                <button className='btn btn-sm
                                data-testid="manage_purchases"
                                pharmacy-info-btn'>Purchases</button>
                            </Link>
                        </div>
                    </Grid>
                </Grid>
            </div>
            <div className="mui-tables">
                <Card className="p-3">
                    <PharmacySnackbar open={open} message={message} variant={variant}
                                      handleCloseBar={handleCloseBar}/>
                    <form  autoComplete="off" role='invoice_form' onKeyDown={(e) => { e.key === 'Enter' && e.preventDefault() }}>
                        <div className='row'>
                            <div className='col-lg-6'>
                                <Form.Group as={Col}>
                                    <Form.Label>Supplier<span
                                        className="text-danger asterics ml-1">*</span></Form.Label>
                                    <AutoCompleteControlled options={manufs} submitted={submitted}
                                                            testId="supplier"
                                                            handleChange={handleChangeManufacturer}
                                                            errorMsg="Please select a supplier" medicine_value={name}
                                                            value_text='manufacturer_id' label_text='manufacturer_name' />
                                </Form.Group>
                                <Form.Group as={Col}>
                                    <Form.Label>Invoice No<span
                                        className="text-danger asterics ml-1">*</span></Form.Label>
                                    <TextField submitted={submitted}
                                               data-testid="invoice_no"
                                               type="text" name="invoice_no" value={invoice_no}
                                               onChange={handleChangeValue}
                                    />
                                    {(submitted && !invoice_no) &&
                                        <ErrorMessage>Please enter the invoice number</ErrorMessage>}

                                </Form.Group>
                                <Form.Group as={Col}>
                                    <Label name='Expected Payment Date' type/>
                                    <TextField submitted={submitted} type="date" name="expected_payment_date"
                                               data-testid="expected_payment_date"
                                               value={expected_payment_date}
                                               onChange={handleChangeValue}
                                    />
                                    {(submitted && !expected_payment_date) &&
                                        <ErrorMessage>Please enter the payment date</ErrorMessage>}
                                </Form.Group>
                            </div>
                            <div className='col-lg-6'>
                                <Form.Group as={Col}>
                                    <Form.Label>Purchase Date<span
                                        className="text-danger asterics ml-1">*</span></Form.Label>
                                    <TextField name="purchase_date"
                                               data-testid="purchase_date"
                                               value={purchase_date} type="date"
                                               onChange={handleChangeValue}
                                    />
                                    {(submitted && !purchase_date) &&
                                        <ErrorMessage>Please select a purchase date</ErrorMessage>}
                                    {validate_purchase_date === false ? <ErrorMessage>Please select a valid date, you selected a date in the future</ErrorMessage>: null}
                                </Form.Group>
                                <Form.Group as={Col}>
                                    <Form.Label>Details</Form.Label>
                                    <Textarea as="textarea"
                                              data-testid="purchase_details"
                                              name="details" value={titleCase(details)}
                                              onChange={handleChangeValue}/>
                                </Form.Group>
                            </div>
                        </div>
                        {submitted && rows.length <=0 ? <div className='mb-2'>
                            <Alert severity="error">Please add a product before submitting</Alert>
                        </div> :  null}
                        <div className="table-responsive">
                            <table className="table table-bordered p-0 smallFontSize add-purchase-table">
                                <thead>
                                <tr>
                                    <td  width='200'>Product<span
                                        className="text-danger asterics ml-1">*</span>
                                    </td>
                                    <td width='150' >Batch#<span className="text-danger asterics ml-1">*</span>
                                    </td>
                                    <td width='100' >Expiry Date<span className="text-danger asterics ml-1">*</span>
                                    </td>
                                    <td width='150' >Expiry Limit</td>
                                    <td width='150' >Stock</td>
                                    <td width='180' >Quantity<span className="text-danger asterics ml-1">*</span>
                                    </td>
                                    <td width='200'>Unit Cost</td>
                                    <td  width='200'>Total</td>
                                    <td width='50'>Action</td>
                                </tr>
                                </thead>
                                <tbody>
                                {rows.map((item, idx) => {
                                    const daysLeft = Math.floor((Date.parse(item.expiry_date) - Date.now()) / (24 * 60 * 60 * 1000));
                                    return (

                                        <tr id="addr1" key={item.uuid_id}>
                                            <td>
                                                <AutoCompleteMedicine
                                                    testId={"product-"+idx}
                                                    options={manufProducts} submitted={submitted}  idx={idx} handleChange={handleSearchProduct} label_text="label"
                                                    handleProductData={retrieveProductData} medicine_value={{label:item.product_name, value: item.product_id, generic_name: item.generic_name}} value_text='value' />
                                            </td>
                                            <td>

                                                <TextField submitted={submitted} type='text' name="batch_id"
                                                           data-testid={"batch_id-"+idx}
                                                           value={item.batch_id}
                                                           style={{fontSize:'11px'}}
                                                           onChange={(e) => handleChangeBatchId(e, idx)}
                                                           onBlur={() => retrieveExpiry(item.batch_id, idx)}/>
                                                {(submitted && !rows[idx].batch_id) ?
                                                    <ErrorMessage>Please enter the batch Id</ErrorMessage> : item.isValidBatch ?
                                                        <ErrorMessage>Batch id should contain only letters or numbers</ErrorMessage> : null }


                                            </td>
                                            <td>
                                                <TextField submitted={submitted && !item.disableExpiry} type="date" name="expiry_date"
                                                           disabled={item.disableExpiry}
                                                           data-testid={"expiry_date-"+idx}
                                                           value={item.expiry_date}
                                                           onChange={(e) => handleChange(e, idx)}
                                                           style={isDesktop ?  {fontSize:'11px'} : {width:'118px',fontSize:'11px'}}/>

                                                {daysLeft <= item.expiry_limit ?
                                                    <ErrorMessage>Date entered is within the product's expiry limit</ErrorMessage>
                                                    : null}
                                                {(submitted && !item.expiry_date && !item.disableExpiry) ?
                                                    <ErrorMessage>Please select the expiry
                                                        date</ErrorMessage> :
                                                    (submitted && (!item.expiry_date > purchase_date)) &&
                                                    <ErrorMessage>Expiry date should greater than
                                                        purchase date</ErrorMessage>}

                                            </td>
                                            <td>
                                                <InputGroup className="mb-3">
                                                    <TextField type="number"
                                                               name="expiry_limit"
                                                               data-testid={"expiry_limit-"+idx}
                                                               disabled={!item.product_id}
                                                               value={item.expiry_limit}
                                                               onChange={(e) => handleChangeExpiryLimit(e, idx)}
                                                               style={{fontSize:'11px'}}
                                                    />
                                                    <InputGroup.Text style={{fontSize:'11px'}} className='form__input__text' id="expiry_limit">days</InputGroup.Text>
                                                </InputGroup>

                                            </td>
                                            <td>
                                                <TextField type="text" disabled={true}
                                                           data-testid={"total_product-"+idx}
                                                           name="total_product" placeholder="0.00"
                                                           value={item.total_product}
                                                           style={{fontSize:'11px'}}/>
                                            </td>
                                            <td>
                                                <TextField type="number" submitted={submitted && !item.disableQuantity}
                                                           data-testid={"product_quantity-"+idx}
                                                           name="product_quantity"
                                                           disabled={item.disableQuantity}
                                                           value={item.product_quantity}
                                                           onChange={(e) => handleChangeQuantity(e, idx)}
                                                           placeholder="0.00"
                                                           style={{fontSize:'11px'}}
                                                />
                                                {item.product_quantity > item.maximum_stock && item.maximum_stock > 0 ?
                                                    <ErrorMessage>Quantity Exceeds Maximum Stock</ErrorMessage>
                                                    : null}
                                                {(submitted && !item.product_quantity && !item.disableQuantity) &&
                                                    <ErrorMessage>Please enter the product
                                                        quantity</ErrorMessage>}
                                                <Form.Control type="hidden"
                                                              value={!item.discount ? 0 : item.discount}
                                                              name="discount"/>
                                            </td>
                                            <td>
                                                <TextField type="number" submitted={submitted && !item.disableUnitCost}
                                                           data-testid={"product_rate-"+idx}
                                                           name="product_rate"
                                                           disabled={item.disableUnitCost}
                                                           onChange={(e) => handleChangePrice(e, idx)}
                                                           value={item.product_rate}
                                                           placeholder="0.00"
                                                           style={{fontSize:'11px'}}
                                                />
                                                {(submitted && !item.product_rate && !item.disableUnitCost) &&
                                                    <ErrorMessage>Unit cost is required</ErrorMessage>}
                                            </td>

                                            <td>
                                                <TextField
                                                    type="number"
                                                    disabled={item.product_quantity <= 0}
                                                    onChange={(e) => handleChangeAmount(e, idx)}
                                                    data-testid={"total_price-"+idx}
                                                    name="total_price"
                                                    value={item.total_price}
                                                    placeholder="0.00"
                                                    style={{fontSize:'11px'}}
                                                />
                                            </td>
                                            <td>
                                                <button type="button" className="btn pharmacy-btn-dismiss btn-sm small-btn"
                                                        data-testid={"delete-"+idx}
                                                        onClick={handleRemoveSpecificRow(item.uuid_id)}
                                                >
                                                    Delete
                                                </button>
                                            </td>
                                        </tr>
                                    )})
                                }
                                <tr>
                                    <td colSpan={2}>
                                        <button type="button" className="btn pharmacy-grey-btn btn-sm"
                                                data-testid="add_row"
                                                onClick={handleAddRow}>Add New Item
                                        </button>
                                    </td>
                                    <td colSpan={5} className="text-right">
                                        Grand Total:
                                    </td>
                                    <td colSpan={2}>
                                        <TextField type="text" disabled={true} name='grand_total'
                                                   data-testid="grand_total"
                                                   value={grand_total ? formatAmount(grand_total) : grand_total}/>
                                    </td>
                                </tr>
                                </tbody>

                            </table>
                            <button type="submit" className="btn pharmacy-btn" onClick={handleSubmitPurchase}
                                    data-testid="submit_purchase"
                                    disabled={isSubmitted === 'pending' || isSubmitted === 'resolved'}>{isSubmitted === 'pending' ? 'Saving...' : 'Save'}
                            </button>
                        </div>
                    </form>
                </Card>
            </div>
        </div>
    );

}

function mapStateToProps(state) {

    return {
        manufacturers: state.manufacturers,
        invoices: state.invoices,
        purchases: state.purchases,
        snackbars: state.snackbars
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            manufacturerActions: bindActionCreators(manufacturerActions, dispatch),
            invoiceActions: bindActionCreators(invoiceActions, dispatch),
            purchaseActions: bindActionCreators(purchaseActions, dispatch),
            snackbarActions: bindActionCreators(snackbarActions, dispatch)
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddPurchase);
