import React from "react";
import ReusableDashboardHeader from "../../../Containers/Dasboard/ReusableDashboardHeader";
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import * as snackbarActions from "../../../Redux/Actions/SnackbarActions/SnackbarActions";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PharmacySnackbar from "../../../Utils/Snackbars/SnackbarUtil";
import { useAccountsPayablesDetails } from "./hooks/useAccountsPayablesDetails";
import { LoadingGif } from "../../../Utils/Loader";
import { formatDate, formatDateTime } from "../../../Utils/ConvertDate";
import { formatAmount } from "../../../Utils/formatAmount";
import { history } from "../../../Helpers/history";
import { ProductsPurchased } from "../../Purchase/ProductsPurchased";
import CompanyAddress from "../../../Utils/CompanyAddress";
import { AccountingDrawer } from "../../../Utils/AccountingUtils/AccountingDrawer";
import { SingleSupplierPayment } from "./SingleSupplierPayment";
import { BackButton } from "../../../Utils/FormInputs/BackButton";
import { ReportsItemList } from "./ItemList";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import { DataCell } from "../../../Utils/List/CollapsibleList";
import styled from "@emotion/styled/macro";
import { usePrint } from "../../../Utils/Template/usePrint";
import PrintOutlinedIcon from "@material-ui/icons/PrintOutlined";
import Tooltip from "@material-ui/core/Tooltip";
import { PayablesDetailsPrint } from "./PayablesDetailsPrint";
import { CustomPagination } from "../../../Utils/Tables/CustomTable";
import TextField from "../../../Utils/FormInputs/TextField";

const useStyles = makeStyles((theme) => ({
  drawerWidth: {
    width: 600,
    padding: "16px",
  },
  table: {
    // width:'40%',
    marginTop: "20px",
  },
  paragraph: {
    fontSize: "15px",
    display: "inline-block",
    marginBottom: "0.8rem",
    marginRight: "16px",
  },
  icon: {
    width: ".9em",
    height: ".9em",
  },
  iconHolder: {
    minWidth: "38px ",
  },
  item: {
    borderRight: "1px solid #c7c6c7",
    borderLeft: "1px solid #c7c6c7",
    height: "100vh",
    overflow: "auto",
    "&::-webkit-scrollbar": {
      width: "5px",
      height: "5px",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#c7c6c7",
      "-webkit-border-radius": "10px",
      borderRadius: "10px",
    },
  },
  pItem: {
    borderLeft: "1px solid #c7c6c7",
  },
  rounded: {
    borderRadius: 0,
  },
  listText: {
    textAlign: "right",
  },
  approved: {
    color: "green",
  },
  purchased: {
    color: "#1d5dd3",
  },
  canceled: {
    color: "#D9512C",
  },
  pending: {
    color: "#F29339",
  },
}));

const StyledCell = styled(DataCell)({
  border: 0,
});
const AccountsPayablesDetails = ({ actions, snackbars, match }) => {
  const classes = useStyles();
  const {
    suppliers,
    products,
    invoice,
    isErrorProducts,
    isErrorSuppliers,
    isLoadingSuppliers,
    isLoadingProduct,
    isSuccessSuppliers,
    isSuccessProducts,
    handleClickSupplier,
    closeSnackbar,
    pay_details,
    paymentProps,
    supplier_name,
    totalPageCount,
    searchValue,
    activePage,
    handlePageChange,
    handleChangeSearch,
  } = useAccountsPayablesDetails(actions, match);
  const { supplierData } = suppliers;
  const { productData } = products;
  const { open, variant, message } = snackbars;
  // const bulkPayment = productData.filter(item=>item.checked).length > 0
  const { openDialog, handleOpenDialog, handleCloseDialog, state } =
    paymentProps;
  const { invoice_no } = state;
  const details = pay_details ? pay_details : {};
  const balance = details.balance ? details.balance : 0;
  const paid_amount = details.paid_amount ? details.paid_amount : 0;
  const grand_total = details.grand_total_amount
    ? details.grand_total_amount
    : 0;
  const purchase_date = details.purchase_date ? details.purchase_date : "";

  const { handlePrint, componentRef, getPageMargins } = usePrint("");

  const purchaseInfo = {
    balance,
    paid_amount,
    grand_total,
    purchase_date: formatDate(purchase_date),
    invoice_number: invoice_no,
    supplier: supplier_name,
  };

  return (
    <div className="journals">
      <ReusableDashboardHeader
        component="Accounts Payable"
        heading="Bills"
        subHeading="Bills"
        link={history.location.pathname}
      />
      <PharmacySnackbar
        open={open}
        message={message}
        variant={variant}
        handleCloseBar={closeSnackbar}
      />
      <AccountingDrawer
        open={openDialog}
        title={`Payment for Invoice# ${invoice_no}`}
        handleClose={handleCloseDialog}
        width={classes.drawerWidth}
      >
        <SingleSupplierPayment {...paymentProps} />
      </AccountingDrawer>
      <Card elevation={0} classes={{ root: classes.rounded }}>
        <Grid container spacing={0}>
          <Grid item xs={6} md={4} classes={{ item: classes.item }}>
            <div className="top-bar">
              <div className="row">
                <div className="col-lg-6">
                  <BackButton text="Payables" to="/creditors/supplier" />
                </div>
                <div className="col-lg-6">
                  <TextField
                    type="text"
                    placeholder="Search..."
                    value={searchValue}
                    onChange={handleChangeSearch}
                  />
                </div>
                <p
                  style={{
                    marginBottom: "0px",
                    marginLeft: "20px",
                    textTransform: "uppercase",
                    fontWeight: "bold",
                  }}
                >
                  {supplierData[0]?.manufacturer_name}
                </p>
              </div>

              {/*<button disabled={!bulkPayment} className='btn btn-sm pharmacy-info-btn'>Record Bulk Payment</button>*/}
            </div>
            {isLoadingSuppliers ? (
              <div className="text-center">
                <LoadingGif />
              </div>
            ) : null}
            {isSuccessSuppliers ? (
              supplierData.length > 0 ? (
                <>
                  {supplierData.slice(0, 10).map((item) => {
                    // console.log("supplier item", item);
                    const isActive = item.isActive === "active";
                    const styles = {
                      0: classes.pending,
                      1: classes.approved,
                      2: classes.canceled,
                      3: classes.purchased,
                    };
                    const text = {
                      0: "PENDING APPROVAL",
                      1: "APPROVED",
                      2: "CANCELED",
                      3: "FULFILLED",
                    };

                    const itemObj = {
                      days_left: item.days_left,
                      status: item.status,
                      related_purchase: item.related_purchase,
                      date: item.purchase_date
                        ? formatDateTime(item.purchase_date)
                        : item.purchase_date,
                      amount: item["Total Amount"]
                        ? formatAmount(item["Total Amount"])
                        : item["Total Amount"],
                    };
                    return (
                      <ReportsItemList
                        styles={styles}
                        isActive={isActive}
                        handleClick={() => handleClickSupplier(item)}
                        text={text}
                        item={itemObj}
                      />
                    );
                  })}
                  {totalPageCount > 10 ? (
                    <CustomPagination
                      records={10}
                      handleChangeNextPage={handlePageChange}
                      data={supplierData}
                      total_count={totalPageCount}
                      activePage={activePage}
                    />
                  ) : null}
                </>
              ) : (
                <div className="text-center mt-3">No records found</div>
              )
            ) : null}
            {isErrorSuppliers ? (
              <div className="text-center mt-3">
                The server did not return a valid response
              </div>
            ) : null}
          </Grid>
          <Grid item xs={6} md={8} classes={{ item: classes.pItem }}>
            {isLoadingProduct ? (
              <div className="text-center mt-5">
                <LoadingGif />
              </div>
            ) : null}

            {isSuccessProducts ? (
              productData.length > 0 ? (
                <>
                  <div className="top-bar">
                    <div className="row">
                      <div className="col-lg-6">
                        <p
                          style={{
                            fontSize: "18px",
                            marginTop: "4px",
                            marginBottom: 0,
                          }}
                        >
                          Invoice#: <strong>{invoice}</strong>
                        </p>
                      </div>
                      <div className="col-lg-6">
                        <div className="text-right">
                          <div className="btn-group">
                            <Tooltip title="Print">
                              <button
                                type="button"
                                onClick={handlePrint}
                                className="btn btn-sm pharmacy-grey-btn"
                              >
                                <PrintOutlinedIcon
                                  classes={{ root: classes.icon }}
                                />
                              </button>
                            </Tooltip>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="text-right">
                    <button
                      data-testid="record_payment"
                      type="button"
                      style={{ marginTop: "0.6rem" }}
                      onClick={handleOpenDialog}
                      className="btn btn-sm pharmacy-info-btn mr-2"
                    >
                      Record Payment
                    </button>
                  </div>
                  <div className="m-2">
                    <div style={{ display: "none" }}>
                      <div ref={componentRef}>
                        {getPageMargins}
                        <PayablesDetailsPrint
                          {...{ products: productData, purchaseInfo }}
                        />
                      </div>
                    </div>

                    <CompanyAddress />
                    <ProductsPurchased products={productData} />

                    <Table className={classes.table}>
                      <TableBody>
                        <TableRow>
                          <StyledCell />
                          <StyledCell />
                          <StyledCell />
                          <StyledCell />
                          <StyledCell />
                          <StyledCell />
                          <StyledCell align="right">Total Amount</StyledCell>
                          <StyledCell data-testid="total_amount">
                            <strong>{formatAmount(grand_total)}</strong>
                          </StyledCell>
                        </TableRow>
                        <TableRow>
                          <StyledCell />
                          <StyledCell />
                          <StyledCell />
                          <StyledCell />
                          <StyledCell />
                          <StyledCell />
                          <StyledCell align="right">Payment Made</StyledCell>
                          <StyledCell data-testid="paid_amount">
                            <strong>{formatAmount(paid_amount)}</strong>
                          </StyledCell>
                        </TableRow>
                        <TableRow>
                          <StyledCell />
                          <StyledCell />
                          <StyledCell />
                          <StyledCell />
                          <StyledCell />
                          <StyledCell />
                          <StyledCell align="right">Amount Due</StyledCell>
                          <StyledCell data-testid="amount_due">
                            <strong>{formatAmount(balance)}</strong>
                          </StyledCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </div>
                </>
              ) : (
                <div className="text-center mt-5">No records found</div>
              )
            ) : null}
            {isErrorProducts ? (
              <div className="text-center mt-5">
                The server did not return a valid response
              </div>
            ) : null}
          </Grid>
        </Grid>
      </Card>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    snackbars: state.snackbars,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      snackbarActions: bindActionCreators(snackbarActions, dispatch),
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AccountsPayablesDetails);
