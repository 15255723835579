import React, {useCallback, useEffect, useState} from 'react';
import axios from "axios";
import config from "../../../../Helpers/config.json";
import {errorMessages} from "../../../../Helpers/ErrorMessages";
import {useReceiveDebtorsPayments} from "./useReceiveDebtorsPayments";
import { useSalesFilter } from "../../../Invoice/CustomHooks/useSalesFilter";


export const useDebtorsReportDetails = (actions, match) => {
    const {customer_id, customer_name} = match.params;
    const storage = JSON.parse(sessionStorage.getItem('total_count'))
    const item_count = storage ? storage : 0
    const count = () => +item_count || 0
    const [data, setData] = useState({invoices:[], loading:'idle', total_count:0})
    const [allDebtors, setAllDebtors] = React.useState({debtors:[], loading:'idle'});
    const [customer, setCustomer] = useState({id:customer_id, name:customer_name})
    const [activePage, setActivePage] = useState(1)
    const [searchValue, setSearchValue] = useState('');
    const [totalPageCount, setTotalPageCount] = useState(count);
    const [submitted, setSubmitted] = useState(false)
    const [invoice_item_id, setInvoiceItemId] = useState('')
    
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const {invoices,loading:loadingInvoices} = data
    const {loading:loadingDebtors} = allDebtors
    const {id, name} = customer
    const obj = {customer_id:id, customer_name:name, data:invoices}
    const {paymentProps, isResolved} = useReceiveDebtorsPayments(actions, obj)

    function sortArr(arr=[]) {
        return arr.sort((a, b)=>new Date(b.date) - new Date(a.date))
    }

  
    const {date, handleChange, filters} = useSalesFilter()
    const {filter_by, start_date, end_date} = date

    const retrieveDebtors = useCallback((from_date, to_date)=>{
        const isCustom = filter_by === 'custom' ? {start_date:from_date, end_date:to_date} : {}
        setAllDebtors({...allDebtors, loading: 'pending'})
        axios.get(`${config.epharmUrl}/Cinvoice/debtors`,{params:{page_number:activePage, search:searchValue,time_filter:filter_by,...isCustom}}).then(res => {
            const response = res.data;
            const dt = response ? response : {};
            const arr = dt.data ? dt.data : []
            const page_count = arr.total_records ? arr.total_records : 0;
            const arrData = arr.debtors ? arr.debtors : [];
            const activeItems = arrData.map(item=>({
                ...item, id:item.customer_id
            })).map(item=>{
                if(item.id === +id){
                    return {...item, isActive:'active'}
                }
                return item
            })
            if(activePage === 1){
                sessionStorage.setItem('total_count', JSON.stringify(page_count))
                setTotalPageCount(page_count);
            }else {
                setTotalPageCount(count);
            }
            setAllDebtors({debtors: activeItems, loading: 'success'})
        }).catch(err => {
            errorMessages(err, null, actions)
            setAllDebtors({...allDebtors, loading: 'error'})

        })

    },[isResolved, activePage, searchValue, filter_by])

    const retrieveProducts = (_id) =>{
        return  axios.get(`${config.epharmUrl}/Cinvoice/single_bill`,{params:{bill_id: _id}})
     }

    useEffect(() => {
        if (filter_by === 'custom'){
            return
        }
        retrieveDebtors(start_date, end_date)
    }, [retrieveDebtors])

    useEffect(() => {
        if(!id ){
            return
        }

        setData({...data, loading: 'loading'})
        axios.get(`${config.epharmUrl}/Cinvoice/unpaid_invoices`, {params:{customer_id:id}}).then(res => {
            const data = res.data;
            const dt = !data ? {} : data;
            const arr = !dt.data ? [] : dt.data;
            const obj = arr[0] ? arr[0] : {}
            const _id = obj.id ? obj.id : ''
            if(_id){
                retrieveProducts(_id).then(res => {
                    const prodData = res.data;
                    const dtProd = !prodData ? {} : prodData;
                    const prodArr = !dtProd.data ? [] : dtProd.data;
                    const arrData = arr.map((row, index)=>{
                        return {...row, open:index === 0, products:prodArr}
                    })
                    setData({invoices: arrData,  loading: 'success'})
                }).catch(err => {
                    errorMessages(err,null,actions)
                })
            }else {
                setData({invoices: arr,  loading: 'success'})
            }
        }).catch(err => {
            errorMessages(err,null,actions)
            setData({...data, loading: 'error'})
        })
    }, [id, isResolved]);

    const handleChangeSearch = (event) => {
        setSearchValue(event.target.value)

    };

    const handleSubmit = (event) =>{
        event.preventDefault()
        setSubmitted(true)
        if (start_date && end_date && start_date < end_date){
         
            retrieveDebtors(start_date, end_date)
        }
    }


    const handleClickCustomer = (item) =>{
        const {id:_id, customer_name} = item
        const {debtors} = allDebtors
        const arr = debtors.map(item=>{
            if (item.id === _id  ){
                return {...item,  isActive:'active'}
            }
            return {...item,isActive:'inactive', count:0}
        })

        setCustomer({id:_id, name: customer_name})
        setAllDebtors({...allDebtors, debtors: arr})
    }





    const handleOpen = (item) => {
        const {id:item_id} = item;
        console.log(item_id)
        retrieveProducts(item_id).then(res => {
            const prodData = res.data;
            const dtProd = !prodData ? {} : prodData;
            const prodArr = !dtProd.data ? [] : dtProd.data;
            const arrData = invoices.map((row)=>{
                if(row.id === item_id){
                    return {...row, open:!row.open, products:prodArr}
                }
                return row
               
            })
            setData({...data,invoices: arrData})
        }).catch(err => {
            errorMessages(err,null,actions)
           
        })
        setInvoiceItemId(item_id)
    }


    const handleChangeActivePage = (page) =>{
        setActivePage(page)
    }

    const closeSnackbar = () =>{
        actions.snackbarActions.hideSnackBar()
    }


    //invoices
    const isLoadingInvoices = loadingInvoices === 'loading'
    const isSuccessInvoices = loadingInvoices === 'success'
    const isErrorInvoices = loadingInvoices === 'error'

    //debtors
    const isLoadingDebtors = loadingDebtors === 'loading'
    const isSuccessDebtors = loadingDebtors === 'success'
    const isErrorDebtors = loadingDebtors === 'error'

    return {data,activePage,  handleOpen,closeSnackbar, handleClickCustomer, isLoadingDebtors,isLoadingInvoices,
    isSuccessDebtors, isSuccessInvoices, isErrorDebtors, isErrorInvoices, allDebtors, id, customer, paymentProps,
    handleChangeSearch, handleChangeActivePage, date, handleChange, filters,submitted, handleSubmit,totalPageCount}
}