import React from "react";
import  {history} from "../../Helpers/history";

function RouteLabels({value, data = []}) {
    return (
        <div className='general-ledger-header inpatient-billing-header mb-3'>
            {data.map((item, index)=>(
                <a key={index} className={`billing-span btn btn-sm ${item.route === value ? 'active-billing-span':''}`}
                   onClick={()=>history.push(item.route)}>{item.label}</a>
            ))}
        </div>
    )
}
export {RouteLabels}