import React, {useEffect, useState} from 'react';
import axios from 'axios';
import config from '../../../../Helpers/config.json'
// import {errorMessages} from "../../../Helpers/ErrorMessages";
import {useNewExpenseItem} from "./useNewExpenseItem";


export const useExpenseItems = (actions) => {
    const [expensesItems, setExpensesItems] = useState([]);
    const [loading, setLoading] = useState('idle');
    const {itemProps} = useNewExpenseItem(actions)
    const {isResolved} = itemProps


    useEffect(() => {
        (async () => {
            setLoading('pending')
            try {
                const res = await axios.get(`${config.epharmUrl}/accounting/expense_types`)
                let _data = res.data
                let dt = _data.data ?? [];
                setExpensesItems(dt)
                setLoading('success')
            } catch (e) {
                // errorMessages(e, null, actions)
                setLoading('error')
            }
        })();
    }, [isResolved])



    const closeSnackbar = () =>{
        actions.snackbarActions.hideSnackBar()
    }



    const isLoading = loading === 'pending'
    const isSuccess = loading === 'success'
    const isError = loading === 'error'



    return {expensesItems,  isLoading, isSuccess, isError, closeSnackbar, itemProps}
}
