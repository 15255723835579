import React from "react";
import Dialog from "@material-ui/core/Dialog";
import {DialogActions,DialogTitle,DialogContent} from "./Dialog/pharmacyDialog";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

const CsvDialog = ({openDialog, handleCloseDialog,handleUploadCsv,submitted,purchaseCsv,
  handleChangeUpload,message,children }) =>{
    return(
        <Dialog open={openDialog} fullWidth={true}>
            <DialogTitle onClose={handleCloseDialog}>
                <div className="text-center">
                    Upload {message} CSV 
                </div>
            </DialogTitle>
            <DialogContent>
                <div className="">
                    <div className="text-right p-1">
                        {children}
                    </div>
                    <hr/>
                    <div className="p-3">
                    <Form onSubmit={handleUploadCsv}>
                      <Form.Group as={Row} >
                        <Form.Label column sm="3" className="text-right">
                          Upload file<span className="text-danger asterics ml-1">*</span>
                        </Form.Label>
                        <Col sm="9">
                        <div className="p-1">
                          <Form.Control type="file" onChange={handleChangeUpload}/>
                          {(submitted && !purchaseCsv) && <div className="invalid-text">Please upload a file</div>}
                        </div>
                        </Col>
                      </Form.Group>
                      <div className="text-right">
                      <button type="submit" className="btn btn-sm pharmacy-btn mr-3">Submit</button>
                      <button type="button" className="btn btn-sm pharmacy-btn-dismiss" onClick={handleCloseDialog}>Close</button>
                      </div>
                    </Form>
                    </div>
                </div>
            </DialogContent>
            <DialogActions>

            </DialogActions>
        </Dialog>
    )

};
export default CsvDialog;