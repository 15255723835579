import React from "react";
import Dialog from "@material-ui/core/Dialog";
import {DialogActions, DialogTitle, DialogContent} from "../../Utils/Dialog/pharmacyDialog";



const InvoicePrintDialog = ({openDialog, handleCloseDialog, message,  text, children}) => {
    return (
        <Dialog open={openDialog}>
            <DialogTitle onClose={handleCloseDialog}>
                <div className="text-center">
                    View {message}
                </div>
            </DialogTitle>
            <DialogContent>
                <div className="text-center">
                    <p style={{fontSize:"14px"}}>Would you like to view the {text ? text : 'invoice'}?</p>
                </div>
            </DialogContent>
            <DialogActions>
                {children}
            </DialogActions>
        </Dialog>
    )

};
export default InvoicePrintDialog;
