import React from "react";
import Dialog from "@material-ui/core/Dialog";
import {DialogTitle, DialogContent, DialogActions} from "./pharmacyDialog";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles(theme=>({
    root:{
        padding:'12px'
    },
    paper:{
        overflow:'initial'
    },
    scrollpaper: {
        alignItems: 'baseline',
    },
    normalpaper: {
        borderRadius:'15px'
    }
}))

const DetailsDialog = ({openDialog, handleClose, title, children, maxWidth='sm', buttons=null,isTop=false}) =>{
    const classes = useStyles()
    return(
        <Dialog open={openDialog} maxWidth={maxWidth} fullWidth
            classes={{
            scrollPaper: isTop ? classes.scrollpaper : '',
            paper: classes.normalpaper
          }}
        >
            <DialogTitle onClose={handleClose} classes={{root:classes.root}}>
                <div className="text-center">
                    {title}
                </div>
            </DialogTitle>
            <DialogContent className={classes.paper}>
                {children}
            </DialogContent>
            <DialogActions>
                {buttons}
            </DialogActions>
        </Dialog>
    )

};
export default DetailsDialog;