import React, {Fragment, useState} from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Select from "@material-ui/core/Select";
import MenuItem from '@material-ui/core/MenuItem';
import InputBase from '@material-ui/core/InputBase';
import Popper from "@material-ui/core/Popper";
import { titleCase } from '../titleCaseFunction';
import {useCustomMediaQuery} from "../hooks/useCustomMediaQuery";
import Checkbox from '@material-ui/core/Checkbox';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';

const styledBy = (property, mapping) => (props) => mapping[props[property]];

// Styles for the AutoComplete component
const styles = {
  root: {
    backgroundColor: 'white',   
    '& .MuiInputBase-root' : {
      fontSize: '12px',
      borderRadius: `10px`,
      padding: '10px',
      height: '34px',
      border: styledBy('color', {
        default: 'none',
        error: '1px solid red',
      }),
    },
    '& .MuiAutocomplete-inputRoot .MuiAutocomplete-input' : {
      padding:'0px'
    },
  },
  root_multiple: {
    backgroundColor: 'white',   
    '& .MuiInputBase-root' : {
      fontSize: '12px',
      borderRadius: `10px`,
      padding: '10px',
      border: styledBy('color', {
        default: 'none',
        error: '1px solid red',
      }),
    },
    '& .MuiAutocomplete-inputRoot .MuiAutocomplete-input' : {
      padding:'0px'
    },
  }
};

const AutoCompleteTextField = withStyles(styles)(({ classes, color, is_multiple=false, ...other }) => (
  <TextField className={is_multiple ? classes.root_multiple :  classes.root} {...other} />
));

// Styles for the Select component
const input_styles = {
  input: {
    backgroundColor: 'white',
    border: styledBy('color', {
      default: '1px solid #ced4da',
      error: '1px solid red',
      placeholder: '1px solid #ced4da',
    }),
    color: styledBy('color', {
      default: 'black',
      placeholder: 'gray',
    }),
    fontSize: '11px',
    borderRadius: `10px`,
    padding: '10px',
    height: '34px',
    width: '100%',
    '&:focus': {
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
  },
};

const SelectInput = withStyles(input_styles)(({ classes, color, ...other }) => (
  <InputBase className={classes.input} {...other} />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    fontSize: '11px',
    '&:focus': {
      backgroundColor: '#d3dbf2',
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem);

// styles for the menu dropdown
const useStyles = makeStyles((theme) => ({
  paper: {
    color: '#586069',
    fontSize: 12,
    background: 'white',
    boxShadow: '0 3px 12px rgba(27,31,35,.15)',
  },
  option: {
    minHeight: 'auto',
    padding: 8,
    '&[data-focus="true"]': {
     backgroundColor: '#d3dbf2'
    },
  },
  popper: {
    border: '1px solid rgba(27,31,35,.15)',
    boxShadow: '0 3px 12px rgba(27,31,35,.15)',
    borderRadius: 10,
    zIndex: theme.zIndex.modal,
    fontSize: 12,
    background:'white',
    top: '0px',
    marginTop: 5,
    marginBottom: 5,
  },
  selectMenu: {
    marginTop:'4px',
    height:'200px',
    overflow:'scroll'
  },
}));

const inValidateValue = (med_value, autocomplete_value, value_text) => {

   const isValueMed = (!med_value) ? true : typeof med_value === 'string' ? false :
      value_text ? !med_value[`${value_text}`] : !med_value.value ;

    const isAutoCompleteValue = (!autocomplete_value) ? true : typeof med_value === 'string' ? false :
    value_text ? !autocomplete_value[`${value_text}`] : !autocomplete_value.value;
    
    // if both are empty
    return isValueMed && isAutoCompleteValue ;
}

// styles for the max width popper
const popper_styles = (theme) => ({
  popper: {
    maxWidth: "max-content",
    border: '1px solid rgba(27,31,35,.15)',
    boxShadow: '0 3px 12px rgba(27,31,35,.15)',
    borderRadius: 10,
    zIndex: theme.zIndex.modal,
    fontSize: 12,
    background:'white',
    top: '0px',
    marginTop: 5,
    marginBottom: 5,
  }
});

const PopperComponent = function (props) {
  return <Popper {...props} style={popper_styles.popper} placement="bottom-start" />;
};

const icon = <RadioButtonUncheckedIcon style={{fontSize:'15px'}}/>;
const checkedIcon = <CheckCircleIcon style={{fill:'green', fontSize:'15px'}}/>;

// component to use when clicking on multiple controlled options
export const AutoCompleteMultiple = ({options, placeholder='', selectedOptions=[], handleChange, name, submitted}) => {
  const classes = useStyles();
  return (
    <Autocomplete
      multiple
      options={options}
      onChange={handleChange}
      disableCloseOnSelect
      getOptionSelected={(option, value) => {
        return value.label  ===  option.label; 
      }} 
      getOptionLabel={(option) => option.label}
      renderOption={(option, { selected }) => (
        <React.Fragment>
          <Checkbox
            icon={icon}
            checkedIcon={checkedIcon}
            checked={selected}
          />
          {option.label}
        </React.Fragment>
      )}
      value={selectedOptions}
      classes={{
        option: classes.option,
        paper: classes.paper,
      }}
      renderInput={(params) => (
        <AutoCompleteTextField  {...params} variant="outlined" name={name} 
            color={(submitted && (selectedOptions.length === 0)) ? 'error' : "default"} 
            placeholder={placeholder} is_multiple={true}
          /> 
      )}
    />

  );
}

// uncontrolled  -> the options, the change everytime, so they can be undefined or not
// also the component is handled by the DOM, not react, hence the inputvalue element
export const AutoCompleteUnControlled = ({ options, idx, handleChange, handleProductData, submitted, placeholder="", name="",
  medicine_value={}, label_text="", value_text="", id="",testId="", errorMsg=""}) => {
  
  const [value, setValue] = useState(medicine_value);
  const [inputValue, setInputValue] = useState('');
  const classes = useStyles();

  return (
    <div>
        <Autocomplete  
          id={id}
          data-testid={testId}
          value={value || null}
          inputValue={inputValue}
          onChange={(event, newValue) => {
              if (newValue === null) {
                setValue('');
              } else {
                setValue(newValue);
              }
              if (!handleProductData) {
                handleChange(newValue);
              } else {
                if(idx !== null)  {
                  handleProductData({
                    innerText: !newValue ? '' : newValue.label ? newValue.label : label_text ? newValue[`${label_text}`] : "",
                    value: !newValue ? '' : newValue.value ? newValue.value : value_text ? newValue[`${value_text}`] : "",
                }, idx);
               } else {
                handleProductData({
                  innerText: !newValue ? '' : newValue.label ? newValue.label : label_text ? newValue[`${label_text}`] : "",
                  value: !newValue ? '' : newValue.value ? newValue.value : value_text ? newValue[`${value_text}`] : "",
                });
               }
              }
          }}

          getOptionSelected={(option, value) => {
              return value_text === '' ? (option.value === value.value) : (option[`${value_text}`] === value[`${value_text}`])}} 

          getOptionLabel={(option) => {
            if (typeof(option) === 'string') {
              // not a dictionary
              return option;
            }
              return option ? option.label ? 
                option.label : label_text ?
                option[`${label_text}`] !== null ?
                option[`${label_text}`]  : "" : "" : ""; 
          }}

          onInputChange={(event, newInputValue) => {
            setInputValue(newInputValue);
            if (idx !== null) {
              handleChange(newInputValue,idx, (newInputValue === ''));
            } else {
              handleChange(newInputValue);
            }
          }} 
          
          options={options}
          classes={{
            option: classes.option,
            paper: classes.paper,
          }}

          PopperComponent={PopperComponent}

          renderInput={(params) => 
          <AutoCompleteTextField  {...params} variant="outlined" name={name} data-testid={testId}
          color={(medicine_value === null && !submitted) ? 'default' : 
          (submitted && (inValidateValue(medicine_value, value, value_text))) ? 'error' : "default"} 
              placeholder={placeholder}/>}
        /> 
        {(medicine_value === null && !submitted) ? null : 
          (submitted && (inValidateValue(medicine_value, value, value_text)))  ?
          <div className="invalid-text">{errorMsg}</div>
        : null}
    </div>
  );
}

// This component is like AutoCompleteUncontrolled by it caters for the medicine
// when one types its generic products
export const AutoCompleteMedicine = ({ options, idx, handleChange, handleProductData, submitted, placeholder="", name="", sub_idx,
  medicine_value={}, id="", errorMsg="", valid_error_check, errorMsg_Valid, testId="", ...rest}) => {
  
  const [value, setValue] = useState(medicine_value);
  const [inputValue, setInputValue] = useState('');
  const classes = useStyles();

  const isDesktop = useCustomMediaQuery('(min-width:1351px)');


  return (
    <Fragment>

        <Autocomplete
          fullWidth
          id={id}
          data-testid={testId}
          value={value || null}
          inputValue={inputValue}
          onChange={(e, newValue) => {
              if (newValue === null) {
                setValue('');
              } else {
                setValue(newValue);
              }
              if (!handleProductData) {
                handleChange(newValue);
              } else {
                if(idx !== null && !sub_idx)  {
                  handleProductData({
                    innerText: !newValue ? '' : newValue.label ? newValue.label : "",
                    value: !newValue ? '' : newValue.value ? newValue.value : "",
                    generic_name: !newValue ? '' : newValue.generic_name ? newValue.generic_name : "",
                  }, idx);
               } else if(idx !== null && sub_idx !== null)  {
                  handleProductData({
                    innerText: !newValue ? '' : newValue.label ? newValue.label : "",
                    value: !newValue ? '' : newValue.value ? newValue.value : "",
                    generic_name: !newValue ? '' : newValue.generic_name ? newValue.generic_name : "",
                  }, idx, sub_idx);
                } else {
                handleProductData({
                  innerText: !newValue ? '' : newValue.label ? newValue.label : "",
                  value: !newValue ? '' : newValue.value ? newValue.value : "",
                  generic_name: !newValue ? '' : newValue.generic_name ? newValue.generic_name : "",
                });
               }
              }
          }}

          filterOptions = {(options, state) => {
            const state_value = state.inputValue;
            let newOptions = [];
                       
            options.forEach((element) => {
 
              const get_option_value =  element.label?? ""; 
              const isValuesCorrect = get_option_value.toLowerCase().includes(titleCase(state_value).toLowerCase())
              || get_option_value.includes(state_value);

              const hasGenericName = element['generic_name']??"";
              const isGenericOption = hasGenericName?.includes(titleCase(state_value)) 
              || hasGenericName?.includes(state_value);

              if (isValuesCorrect || isGenericOption) {
                newOptions.push(element);
              }
                
            });
            
            return newOptions;
          }} 

          getOptionSelected={(option, value) => {
              return value.value  ===  option.value; 
          }} 

          getOptionLabel={(option) => {
            if (typeof(option) === 'string') {
              // not a dictionary
              return option;
            }
            return option ? option.label ? option.label : "" : ""; 
          }}

          onInputChange={(e, newInputValue) => {
            setInputValue(newInputValue);
            if (idx !== null) {
              handleChange(newInputValue, idx);
            } else {
              handleChange(newInputValue);
            }
          }}
          
          options={options}
          classes={{
            option: classes.option,
            paper: classes.paper,
          }}

          PopperComponent={PopperComponent}

          renderInput={(params) => 
          <AutoCompleteTextField  {...params} variant="outlined" name={name} data-testid={testId}
            color={(medicine_value === null && !submitted) ? 'default' : 
            (submitted && (inValidateValue(medicine_value, value, ''))) ? 'error' : "default"} 
            {...rest}
            placeholder={placeholder}
            style={isDesktop ? {width: 300 } : {}}
            />}
            
          /> 

        {(medicine_value === null && !submitted) ? null : 
          (submitted && (inValidateValue(medicine_value, value, '')))  ?
          <div className="invalid-text">{errorMsg}</div>
        : null}
        {valid_error_check ?
          <div className="invalid-text">{errorMsg_Valid}</div>
          : null
        }
    </Fragment>
  );
}

// controlled -> the options are set and they can't change randomly
export const AutoCompleteControlled = ({ options, idx, handleChange, handleProductData, submitted, placeholder="", name="",
  medicine_value={}, label_text="", value_text="", id="", errorMsg="", testId=""}) => {

  const [value, setValue] = useState(medicine_value);  
  const classes = useStyles();

  return (
    <div>
        <Autocomplete
          id={id}
          data-testid={testId}
          value={medicine_value ? medicine_value :  value ? value : null }
          onChange={(event, newValue) => {
              if (newValue === null) {
                setValue('');
              } else {
                setValue(newValue);
              }
              if (!handleProductData) {
                handleChange(newValue);
              } else {
                if(idx !== null)  {
                  handleProductData({
                    innerText: !newValue ? '' : newValue.label ? newValue.label : label_text ? newValue[`${label_text}`] : "",
                    value: !newValue ? '' : newValue.value ? newValue.value : value_text ? newValue[`${value_text}`] : "",
                }, idx);
               } else {
                handleProductData({
                  innerText: !newValue ? '' : newValue.label ? newValue.label : label_text ? newValue[`${label_text}`] : "",
                  value: !newValue ? '' : newValue.value ? newValue.value : value_text ? newValue[`${value_text}`] : "",
              });
               }
              }
          }}

          getOptionSelected={(option, value) => {
            return value_text === '' ? (option.value === value.value) : (option[`${value_text}`] === value[`${value_text}`])}} 

          getOptionLabel={(option) => {
            if (!option) {return "";}
            if (typeof(option) === 'string') {
              return option;
            }
            if (label_text) {
              if (option[`${label_text}`]) {
                return option[`${label_text}`];
              } else {
                return "";
              }
            }
            return option ? option.label ? option.label : "" : "";
          }}
          
          options={options}
          classes={{
            option: classes.option,
            paper: classes.paper,
            //popper: classes.popper
          }}

          PopperComponent={PopperComponent}

          renderInput={(params) => 
          <AutoCompleteTextField  {...params} variant="outlined" name={name} 
            color={(medicine_value === null && !submitted) ? 'default' : 
            (submitted && (inValidateValue(medicine_value, value, value_text))) ? 'error' : "default"} 
            placeholder={placeholder} />}
          /> 

         {(medicine_value === null && !submitted) ? null : 
          (submitted && (inValidateValue(medicine_value, value, value_text)))  ?
          <div className="invalid-text">{errorMsg}</div>
        : null}
    </div>
  );
}

export const SelectItem = ({ value, options, idx, onChange, submitted, id="", errorMsg="", name="", label_text="", value_text="", placeholder="", testId=""}) => {
  const classes = useStyles();

  return (
    <div>
        <Select
          id={id}
          displayEmpty
          data-testid = {testId}
          value={typeof value === 'string' ? value :  value.value ? value.value : value_text ? value[`${value_text}`] : ""}
          onChange={(idx !== null) ? (e) => onChange(e, idx) : (e) => onChange(e)}
          input={
            <SelectInput name={name} 
            color={(!submitted && value === '') ? 'placeholder' :
            (submitted && value === '') ? 'error' : "default"} />}
          MenuProps={{
            getContentAnchorEl: null,
            anchorOrigin: {
                vertical: "bottom",
                horizontal: "left"
            },
            classes:{
              paper: classes.selectMenu,
            }
          }}
        >
          {placeholder !==  '' ?
            <StyledMenuItem disabled value="">
              {placeholder}
            </StyledMenuItem>
            : null
          }
           {options?.length > 0 ?
                options?.map((item, index) => (
                    <StyledMenuItem key={index} data-testid={id+index}
                        value={item.value ? `${item.value}` : value_text ? item[`${value_text}`] : item}
                        data-key={item.value ? `${item.value}` : value_text ? item[`${value_text}`] : item}>
                          {item.text ? item.text : label_text ? item[`${label_text}`] : item}
                    </StyledMenuItem>
            )) :
            <StyledMenuItem value={null}>None...</StyledMenuItem>
            }
        </Select>
         {submitted && value === '' ?
            <div className="invalid-text">{errorMsg}</div>
          : null}
    </div>
  );
}