import {useState} from "react";

function useSalesFilter() {
    const filters = [{value:'today', text:'Today'},{value:'yesterday', text:'Yesterday'},
        {value:'this_week', text:'This Week'},{value:'this_month', text:'This Month'},{value:'custom', text:'Custom'}]
    const [date, setDate] = useState({start_date:'', end_date:'',filter_by:'today'})

    const handleChange = (event) =>{
        const {name, value} = event.target;
        setDate({...date,[name]:value})
    }

    return {date, filters, handleChange}
    
}
export {useSalesFilter}