import React, {useEffect, useState} from 'react';
import axios from "axios";
import config from '../../../../Helpers/config.json';
import {errorMessages} from "../../../../Helpers/ErrorMessages";
import { useMutation,  useQueryClient } from 'react-query';



export const useNewCOAccount = (actions) =>{
    const [accountType, setAccountType] = useState('');
    const [state, setState] = useState({account_name:'', account_code:'', description:'',
        isSubAccount:false, opening_balance:0, balance_date:'', isTransactionAccount:false});
    const [parentAccount,setParentAccount] = useState('');
    const [submitted, setSubmitted] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState('idle');
    const [defaultAccounts, setDefaultAccount] = useState([]);
    const [subAccounts, setSubAccounts] = useState([]);
    const [entryType, setEntryType] = useState('');
    const [openDialog, setOpenDialog] = useState(false);
    const [accountCategories, setAccountCategories] = useState([])
    const [category, setCategory] = useState({category_id:''});

    const queryClient = useQueryClient();


    useEffect(()=>{
        axios.get(`${config.epharmUrl}/accounting/chart/accounting_categories`).then(res=>{
            const data = res.data;
            const dt = data ?? {};
            const arr = dt.data ?? [];
            const items = arr.map(item=>({
                value:`${item.id}-${item.category_code}-${item.category_name}`,
                label:`${item.category_code} - ${item.category_name}`
            }));
            const category = arr[0] ? arr[0] : {};
            const _id = category.id ? category.id : '';
            setCategory({...category, category_id:_id})
            setDefaultAccount(items)
            setAccountCategories(arr)
        },err=>console.log(err))
    },[])




    const retrieveSubAccount = (id) =>{
        axios.get(`${config.epharmUrl}/accounting/non_transaction_ledgers`, {params:{category_id:id}}).then(res=>{
            const data = res.data;
            const dt = data ?? {};
            const arr = dt.data ?? [];
            const items = arr.map(item=>({
                value:item.id,
                label:`${item.head_code} - ${item.head_name}`
            }))
            setSubAccounts(items)
        },err=>console.log(err))
    }

    const handleChangeAccountType = (value) =>{
        setAccountType(value)
        const val = value ? value : {};
        const item = val.value ? val.value : '';
        const id = item.split('-')[0]
        retrieveSubAccount(id)
    }

    const handleChangeState = (event) =>{
        const {value,checked,type,name} = event.target;
        const val = type === 'checkbox' ? checked : value
        setState({...state,[name]:val})
    }

    const handleChangeParentAccount = (value) =>{
        setParentAccount(value)
    }

    const handleChangeEntryType = (value) =>{
        setEntryType(value)
    }

    const handleOpenDialog = () =>{
        setOpenDialog(true)
    }

    const handleCloseDialog = () => {
      setOpenDialog(false)
    }

    const submitAccount =  async () =>{
        const {account_name, opening_balance, balance_date, isTransactionAccount} = state;
        const isTransaction = isTransactionAccount ? 1 : 0
        const formData = new FormData();
        const val = accountType ? accountType : {};
        const item = val.value ? val.value : '';
        const category_id = item.split('-')[0]
        const entry_type = entryType ? entryType : {};
        const entry_value = entry_type.value ? entry_type.value : ''
        formData.append('account_category',category_id);
        formData.append('account_name', account_name);
        formData.append('parent_id', parentAccount.value);
        formData.append('opening_balance', opening_balance ? opening_balance : 0);
        formData.append('entry_type', entry_value);
        formData.append('as_of', balance_date ? balance_date : '');
        formData.append('is_final_level', isTransaction);
        // axios.post(`${config.epharmUrl}/accounting/chart/sub_accounts/add`, formData).then(()=>{
        //     actions.snackbarActions.successSnackbar('Account created successfully')
        //     setOpenDialog(false)
        //     setEntryType('')
        //     setAccountType('')
        //     setParentAccount('')
        //     setState({account_name:'', account_code:'', description:'',
        //         isSubAccount:false, opening_balance:0})
        //     setSubmitted(false)
        //     setIsSubmitted('resolved')
        // }).catch(err=>{
        //     errorMessages(err, null,actions);
        //     setIsSubmitted('rejected')
        // })

       return  await axios.post(`${config.epharmUrl}/accounting/chart/sub_accounts/add`, formData)
    }

  // const isLedgerResolved = isSubmitted === 'resolved';

    const {isLoading:isPending, isError:isRejected, isSuccess:isResolved, mutate:postAccounData} = useMutation(submitAccount,{
        onSuccess:()=>{
            queryClient.invalidateQueries({queryKey: 'chart-of-accounts'});
            queryClient.invalidateQueries({queryKey: 'fetch-journals'});
            queryClient.invalidateQueries({queryKey: 'ledgers-under-category'});
            actions.snackbarActions.successSnackbar('Account created successfully')
            setOpenDialog(false)
            setEntryType('')
            setAccountType('')
            setParentAccount('')
            setState({account_name:'', account_code:'', description:'', isSubAccount:false, opening_balance:0})
            setSubmitted(false)
        },
        onError:(err)=>{
            errorMessages(err, null,actions);
        },
    })

    const handleSubmit = (event) =>{
        event.preventDefault();
        const {account_name, balance_date, isTransactionAccount} = state;
        setSubmitted(true);
        const val = accountType ? accountType : {};
        const item = val.value ? val.value : '';
        const category_name = item.split('-')[2]
        const isExpense = category_name === 'EXPENDITURE'
        const entry_type = entryType ? entryType : {};
        const entry_value = entry_type.value ? entry_type.value : ''
        const isTransact = (!isExpense && entry_value && balance_date) || (isExpense && !entry_value && !balance_date);
        if (accountType && account_name &&((isTransactionAccount && isTransact) || !isTransactionAccount)){
            postAccounData()
            // setIsSubmitted('pending')
            // submitAccount()
        }
    }


    const entryTypes = [{value: 'DEBIT', label: 'DEBIT'}, {value: 'CREDIT', label: 'CREDIT'}]

    // const isResolvedQuery = isSubmitted === 'success'
    // const isPending = isSubmitted === 'pending'
    // const isRejected = isSubmitted === 'rejected'


    const coaProps = {accountType, defaultAccounts, handleChangeAccountType, submitted, handleChangeState,
         subAccounts, parentAccount, handleChangeParentAccount,handleSubmit,
        entryType, handleChangeEntryType, entryTypes,
        isPending, isRejected, isResolved,state, isSubmitted,
    }

    return { openDialog, handleOpenDialog, handleCloseDialog,  coaProps, isResolved, 
        accountCategories, setAccountCategories, category, setCategory, setIsSubmitted}
}