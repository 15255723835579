import React from 'react';
import Dropdown from "react-bootstrap/Dropdown";
//import {CSVLink} from "react-csv";
import {Link} from 'react-router-dom'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { makeStyles } from '@material-ui/core/styles';



const useStyles =  makeStyles((theme)=>({
    root: {
        fontSize:'14px',
    }
}));


const BackButton = ({to, text}) =>{
    const classes  =  useStyles()
    return (
        <Link to={to}>
            <ArrowBackIosIcon classes={{root:classes.root}}/>Back to {text}</Link>
    )
}


const DataExportButton = ({downLoadPdf, exportCsv, isPending}) => {
    return (
        <>
            <Dropdown align='start'>
                <Dropdown.Toggle variant="outline-secondary" id="dropdown-basic" disabled={isPending}>
                    {isPending ? 'Exporting...' : 'Export As'}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                    <Dropdown.Item onClick={downLoadPdf} style={{fontSize: '.85em'}}>PDF</Dropdown.Item>
                    <Dropdown.Item style={{fontSize: '.85em'}} onClick={exportCsv}>Excel</Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
        </>

    )
}
export {BackButton, DataExportButton}
