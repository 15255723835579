import React from 'react';
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form"
import TextField from "../../Utils/FormInputs/TextField";


const AddProvider = ({state,   submitted, isSubmitted, handleChange, handleSubmit}) => {

    const {providerName} = state;
    return (
        <form onSubmit={handleSubmit} autoComplete="off" className='mt-2'>
            {/*<Form.Group as={Col} controlId="formGridEmail">*/}
            {/*    <Form.Label >Provider Type<span*/}
            {/*        className="text-danger asterics ml-1">*</span></Form.Label>*/}
            {/*    <SelectInput value={providerType} onChange={handleChange} name='providerType'*/}
            {/*                 options={providerTypes} defaultOption='Select provider type...' submitted={submitted}/>*/}

            {/*    {(submitted && !providerType) &&*/}
            {/*        <div className="invalid-text">Please select the provider type</div>}*/}

            {/*</Form.Group>*/}
            <Form.Group as={Col} controlId="formGridEmail">
                <Form.Label >Category Name<span
                    className="text-danger asterics ml-1">*</span></Form.Label>

                    <TextField type="text" placeholder="Category name" name="providerName"
                               value={providerName} onChange={handleChange} submitted={submitted}/>
                    {(submitted && !providerName) &&
                    <div className="invalid-text">Please enter the category name</div>}

            </Form.Group>

            {/*<Form.Group as={Col} controlId="formGridAddress1">*/}
            {/*    <Form.Label >Provider Mobile</Form.Label>*/}

            {/*        <TextField type="text" name="mobile" value={mobile}*/}
            {/*                   onChange={handleChange}/>*/}

            {/*</Form.Group>*/}

            {/*<Form.Group as={Col} controlId="formGridAddress2">*/}
            {/*    <Form.Label >Provider Address</Form.Label>*/}

            {/*        <Textarea as="textarea" name="address" value={address}*/}
            {/*                  onChange={handleChange}/>*/}

            {/*</Form.Group>*/}
            <button type="submit" disabled={isSubmitted === 'pending'}
                    className='btn pharmacy-btn offset-md-5'>{isSubmitted === 'pending' ? "Submitting..." : "Submit"}</button>
        </form>
    )

}


export default AddProvider;