import {useEffect, useState} from "react";
import axios from "axios";
import config from "../../../Helpers/config.json";
import {errorMessages} from "../../../Helpers/ErrorMessages";
import {history} from "../../../Helpers/history";

function useReturnItems(actions,purchase_id=null) {
    const [rows, setRows] = useState([{product_name:'',product_id:'',batch_id:'',available_quantity:'',
        product_quantity:'',rate:'', discount:'', total:0, rtn:false,purchase_quantity:0,
        expire_date:'', cartoon:0,purchase_detail_id:''}]);
    const [data, setData] = useState({});
    const [totals, setTotals] = useState({total_discount: 0, grand_total_price: 0});
    const [state, setState] = useState({manufacturer_name: '', date: '', manufacturer_id: '', radio:0});
    const [details, setDetails] = useState('');
    const [submitted, setSubmitted] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState('idle');
    const [receipt_no, setReceiptNo] = useState('');
    const [openDialog, setOpenDialog] = useState(false);
    const [openReturn, setOpenReturn] = useState(false);




    useEffect(() => {
        if(!purchase_id){
            return
        }
        axios.get(`${config.epharmUrl}/Creturn/manufacturer_return_form`, {params:{purchase_id}}).then(response => {
            const data = response.data;
            const dt = !data ? {} : data;
            setData(dt)
        }).catch((err) => {
            errorMessages(err, null, actions)
        })
    }, [purchase_id])



    useEffect(() => {
        if(!purchase_id){
            return
        }
        const {purchase_all_data, manufacturer_name,manufacturer_id,date,} = data;
        const invoiceArr = purchase_all_data ?? []
        const arr = invoiceArr.map(item => ({
            ...item,  purchase_detail_id:item.batch_id, product_quantity: '', total: 0,available_quantity:item.avaialable_quantity,
            rtn: false, discount: '', rate:item.manufacturer_price, purchase_quantity:item.quantity
        }))
        setState({
            manufacturer_name: manufacturer_name ?? '', date:date ?? '', manufacturer_id: manufacturer_id ?? '',  radio:0
        })
        setRows(arr)
    }, [data])

    const handleChangeState = (event) =>{
        setState({...state, radio: event.target.value})
    }

    const handleChangeDetails = event => {
        setDetails(event.target.value)
    };

    const calculateTotal = (item) => {
        return +(item.product_quantity) * +item.rate;
    };

    const calculateDiscount = (item) => {
        let discount_value = !item.discount ? 0 : item.discount;
        let val = +(discount_value) / 100;
        return calculateTotal(item) * val;
    };

    const calculateTotalAmount = (arr) => {
        let total_discount = arr.filter(item=>item.rtn).reduce((acc, row) => {
            const discount = !row.discount ? 0 : row.discount;
            const product_quantity = !row.product_quantity ? 0 : row.product_quantity;
            const total = product_quantity * row.rate;
            const val = total * discount / 100;
            return acc + val
        }, 0);

        let grand_total_price = arr.reduce((acc, row) => {
            const dt = row.total ? row.total : 0
            return acc + dt;
        }, 0);

        setTotals({total_discount, grand_total_price});
    };

    const calculateFinalTotal = (item) => {
        return calculateTotal(item) - calculateDiscount(item);
    };


    const changeCheckValue = (e, index) => {
        const {checked} = e.target;
        const arr = rows.map((item, idx) => {
            if (idx === index) {
                return {...item, rtn: checked, total: calculateFinalTotal(item)}
            }
            return item
        })
        calculateTotalAmount(arr);
        setRows(arr)
    };


    const handleChangeQuantity = (event, index) => {
        const {value} = event.target;
        const arr = rows.map((item, idx) => {
            if (idx === index) {
                return {
                    ...item,
                    product_quantity: value,
                    total: calculateFinalTotal({
                        rate: item.rate, product_quantity: value, discount: item.discount
                    })
                }
            }
            return item
        })
        calculateTotalAmount(arr);
        setRows(arr)
    };

    const handleChangeDiscount = (event, index) => {
        const {value} = event.target;
        const arr = rows.map((item, idx) => {
            if (idx === index) {
                return {
                    ...item,
                    discount: value,
                    total: calculateFinalTotal({
                        rate: item.rate,
                        product_quantity: item.product_quantity,
                        discount: value
                    })
                }
            }
            return item
        })
        calculateTotalAmount(arr);
        setRows(arr)
    };

    const handleCloseDialog = () => {
        setOpenDialog(false)
    }



    const handleOpenReceipt = () =>{
        history.push(`/manufacturerinv/${receipt_no}`)
    }




    const handleSubmitReturn = (event) => {
        event.preventDefault();
        const {date, manufacturer_id,  radio} = state
        const {total_discount, grand_total_price} = totals;
        const arr = rows.filter(item=>item.rtn).map(item=>({
            product_id:item.product_id,ret_qty:+item.product_quantity, available_quantity:+item.available_quantity,
            product_quantity:+item.purchase_quantity, product_rate:+item.rate,total_price:+item.total,
            expire_date:item.expire_date ? item.expire_date : '', batch_id:item.batch_id, discount:+item.discount,
            purchase_id:item.purchase_id, manufacturer_id: item.manufacturer_id
        }))
        const params = {purchase_id, manufacturer_id, purchase_date:date,details, radio, grand_total_price,
        total_deduction:total_discount, products:arr}
        const checked = rows.some(item => item.rtn);
        const required = rows.filter(item => item.rtn).every(row => row.product_quantity)
        setSubmitted(true)
        if (checked && required) {
            setIsSubmitted('pending')
            axios.post(`${config.epharmUrl}/Creturn/return_manufacturers`, params).then(response => {
                const data = response.data;
                const dt = data ?? '';
                const ret_id = dt.return_id ? dt.return_id : ''
                setIsSubmitted('resolved')
                actions.snackbarActions.successSnackbar('Product(s) successfully returned')
                setReceiptNo(ret_id)
                setOpenDialog(true)
                setOpenReturn(false)
                // const obj = {'0':'/manufreturnlist/supplier', '1':'/wastagereturnlist/wastages', '2':'/damages/damages'}
                // history.push(`${obj[+radio]}`)
            }).catch(err => {
                setIsSubmitted('rejected')
                errorMessages(err, null, actions)
            });
        }
    }

    const closeSnackbar = () => {
        actions.snackbarActions.hideSnackBar()
    }

    const disabled = rows?.some(item => item.rtn);

    const isPending = isSubmitted === 'pending'
    const isResolved = isSubmitted === 'resolved'
    const isRejected = isSubmitted === 'rejected'

    const returnProps = {rows,data,totals, state, details,submitted,  receipt_no, isPending,
    isRejected, disabled,  handleChangeDiscount, handleChangeQuantity, handleChangeDetails, handleChangeState,
    handleSubmitReturn,  changeCheckValue}
    return {returnProps, isResolved,  setOpenDialog,
        openDialog, rows,handleCloseDialog,openReturn, setOpenReturn,
        handleOpenReceipt,closeSnackbar, setRows, setState}
}
export {useReturnItems}