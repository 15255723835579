import {useEffect, useState} from 'react';
import axios from 'axios';
import config from '../../../../Helpers/config.json'
import {errorMessages} from "../../../../Helpers/ErrorMessages";
import {useNewExpenseItem} from "./useNewExpenseItem";
import {history} from '../../../../Helpers/history';
import {useNewCOAccount} from "../../COA/hooks/useNewCOAccount";
import {dateConvert} from "../../../../Utils/ConvertDate";
import {useQuery} from "react-query";


export const useRecordExpenses = (actions) => {
    const [openExpense, setOpenExpense] = useState(false);
    const [expenses, setExpenses] = useState([{expense_item:'', receipt_no: '', amount:'', debit_ledger:'', credit_ledger:''}])
    const [expenseItems, setExpenseItems] = useState([]);
    const [submitted, setSubmitted] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState('idle');
    const [openExpenseDialog, setOpenExpenseDialog] = useState(false);
    const [receiptDialog, setReceiptDialog] = useState(false)
    const [voucher_id,setVoucherId] = useState("")
    const {itemProps} = useNewExpenseItem(actions);
    // const [ledgers, setLedgers] = useState([]);
    // const [creditLedgers, setCreditLedgers] = useState([]);
    const {isResolved:isItemResolved} = itemProps
    const balance_date = dateConvert()
    const [isBlocking, setIsBlocking] = useState(false)
    const [isBlockDialog, setIsBlockDialog] = useState(false)

    const {coaProps,openDialog:openLedger, handleOpenDialog:handleOpenLedger, handleCloseDialog:handleCloseLedger,} = useNewCOAccount(actions, balance_date);
    const {isResolved:isLedgerResolved} = coaProps

    useEffect(() => {
        (async () => {
            try {
                const res = await axios.get(`${config.epharmUrl}/accounting/expense_types`)
                let _data = res.data
                let dt = _data.data ?? [];
                let exp_types = dt.map(i => ({label: i.expense_type_name, value: i.id}))
                setExpenseItems(exp_types)
            } catch (e) {
                // errorMessages(e, null, actions)
            }
        })();
    }, [isItemResolved])


    async function fetchJournals() {
        const res = await axios.get(`${config.epharmUrl}/accounting/chart/journals`)
        let _data = res.data
        let dt = _data.data ?? [];
        return dt.map(i => ({label: i.head_name, value: i.id}))

    }


    const {data:ledgers} = useQuery('fetch-journals', fetchJournals, {
        onError:(err)=>{
            errorMessages(err, null, actions)
        },
    })

    async function fetchLedgerUnderCategory() {
        const res = await axios.get(`${config.epharmUrl}/accounting/ledgers_under_category`)
        let _data = res.data
        let dt = _data.data ?? [];
        return dt.map(i => ({label: i.head_name, value: i.id}))

    }


    const {data:creditLedgers} = useQuery('ledgers-under-category', fetchLedgerUnderCategory, {
        onError:(err)=>{
            errorMessages(err, null, actions)
        },
    })


    // useEffect(() => {
    //     (async () => {
    //         try {
    //             const res = await axios.get(`${config.epharmUrl}/accounting/chart/journals`)
    //             let _data = res.data
    //             let dt = _data.data ?? [];
    //             let arr = dt.map(i => ({label: i.head_name, value: i.id}))
    //             setLedgers(arr)
    //         } catch (e) {
    //             // errorMessages(e, null, actions)
    //         }
    //     })();
    // }, [isLedgerResolved])
    //
    // useEffect(() => {
    //     axios.get(`${config.epharmUrl}/accounting/ledgers_under_category`).then(res => {
    //         const data = res.data;
    //         const dt = data ? data : {};
    //         const arr = dt.data ? dt.data : []
    //         const list = arr.map(item => ({
    //             value: item.id,
    //             label: item.head_name
    //         }))
    //         setCreditLedgers(list)
    //     }).catch(err => {
    //         errorMessages(err, null, actions)
    //     })
    // }, [isLedgerResolved])

    const handleChangeExpenseItem = (value, index) => {
        const arr = expenses.map((item, idx)=>{
            if(index === idx){
                return {...item, expense_item:value}
            }
            return item
        })
        setExpenses(arr)
        setIsBlocking(true)
    }

    const handleChangeExpense = (event, index) => {
        if (event.name) {
            const name = event.name;
            const value = {value: event.value, label:event.label};
            const arr = expenses.map((item, idx)=>{
                if(index === idx){
                    return {...item, [name]:value}
                }
                return item
            })
            setExpenses(arr)
        } else {
            const {value, name} = event.target;
            const arr = expenses.map((item, idx)=>{
                if(index === idx){
                    return {...item, [name]:value}
                }
                return item
            })
            setExpenses(arr)
        }
        setIsBlocking(true)
    }

    const handleAddExpense = () =>{
        const obj = {expense_item:'', receipt_no: '', amount:'', debit_ledger:'', credit_ledger:''}
        setExpenses([...expenses, obj])
        setIsBlocking(true)
    }

    const handleRemoveExpense = (index) =>{
        const arr = expenses.filter((_, idx)=>index !== idx)
        setExpenses(arr)
        setIsBlocking(true)
    }

    const handleOpenExpense = () =>{
        setOpenExpense(true)
    }

    const handleResetForm = () =>{
        setOpenExpense(false)
        setIsBlockDialog(false)
        setIsBlocking(false)
        const exps = expenses.map(item=>({...item,expense_item:'', receipt_no:'', amount:'', debit_ledger:'', credit_ledger:''}))
        setExpenses(exps)


    }

    const handleCloseExpense = () =>{
        if(isBlocking){
            setIsBlockDialog(true)
        }else{
            setOpenExpense(false)
            setIsBlockDialog(false)
            setIsBlocking(false)
        }

    }


    const handleCloseExpenseDialog = () => {
        setOpenExpenseDialog(false)
    };

    const handleClose = () => {
        setOpenExpense(false)
        setOpenExpenseDialog(false)
    }

    const handleOpenReceiptDialog = () => {
        setReceiptDialog(true);
        setOpenExpenseDialog(false)
    }

    const handleCloseReceiptDialog = () => {
        setReceiptDialog(false);
        setOpenExpense(false)
    }

    const isNum = (v) => {
        return /\d/.test(v);
    }

    const handleSubmitExpense = (e) => {
        e.preventDefault();
       const dataArr = expenses.map(item => {
            const exp = item.expense_item ? item.expense_item : {};
            const value = exp.value ? exp.value : '';

            const new_dict = {
                related_expense_type: isNum(value) ? value : '',
                receipt_no:item.receipt_no, 
                amount:+item.amount,
                debit_ledger:item.debit_ledger['value']??'', 
                credit_ledger:item.credit_ledger['value']??''
            };

            if (isNum(value)) {
                return new_dict;
            } else {
                return {
                    ...new_dict,
                    expense_type_name: isNum(value) ? '' : value
                }
            }

        })
        const arr = expenses.every(item=>item.expense_item && item.receipt_no && item.amount && item.debit_ledger && item.credit_ledger);
        setSubmitted(true);
        if (arr) {
            setIsSubmitted('pending')
            axios.post(`${config.epharmUrl}/accounting/expenses/add`, dataArr).then((response) => {
                const data = response.data ?? {};
                const dt = data.data ? data.data : {}
                const vou_id = dt.voucher_id ? dt.voucher_id : '';
                actions.snackbarActions.successSnackbar('Expense recorded successfully')
                setIsSubmitted('resolved')
                setVoucherId(vou_id)
                const exps = expenses.map(item=>({expense_item:'', receipt_no:'', amount:'', debit_ledger:'', credit_ledger:''}))
                setExpenses(exps)
                setSubmitted(false)
                setOpenExpenseDialog(true)
            }).catch(err => {
                errorMessages(err, null, actions);
                setIsSubmitted('rejected')

            })
        }
    }
    
    const handlePrint = () => {
        history.push(`/expense-reciept/${voucher_id}`)
    };

    const createOption = (label) => ({
        label,
        value: label,
    });

    const handleCreateExpenseItem = (inputValue, index) => {
        const newOption = createOption(inputValue);
        setExpenseItems([newOption, ...expenseItems]);
        handleChangeExpenseItem(newOption, index);
    }

    const isPending = isSubmitted === 'pending'
    const isResolved = isSubmitted === 'resolved'
    const isRejected = isSubmitted === 'rejected'
    const ledgerProps = {...coaProps, openLedger, handleCloseLedger, handleOpenLedger}

    const expenseProps = {openExpense, expenses, expenseItems, submitted, handleChangeExpenseItem, handleCloseExpenseDialog,openExpenseDialog,
        handleAddExpense, handleCloseExpense,handleOpenExpense, handleCloseReceiptDialog, handleOpenReceiptDialog, receiptDialog, handleChangeExpense, handleSubmitExpense,handlePrint, handleClose,voucher_id,
        handleRemoveExpense, isPending, isRejected, isResolved, itemProps, actions, 
        ledgers, creditLedgers,ledgerProps,isBlocking,isBlockDialog,setOpenExpense,setIsBlockDialog,
        setIsBlocking, handleResetForm, handleCreateExpenseItem
    }

    return {expenseProps}
}
