import React, { useRef} from 'react';
import ReactToPrint from "react-to-print";
import {splitText, titleCase} from "../../Utils/titleCaseFunction";
import CompanyAddress from "../../Utils/CompanyAddress";
import ReusableDashboardHeader from "../../Containers/Dasboard/ReusableDashboardHeader";
import {history} from "../../Helpers/history";
import Card from "@material-ui/core/Card";
import companyAddress from "../../Helpers/companyAddress.json";



const MainInvoiceDetails = ({title, heading, address, user, headData, medicines, total_amount, 
    user_name,totalSummary, isLoading, isError, isSuccess}) => {
    const {name, user_address, mobile,} = user;
    const componentRef = useRef();
    return (
        <div>
            <ReusableDashboardHeader component={title} heading={heading}
                                     subHeading={title} link={history.location.pathname}/>
             {isLoading ? <div className="text-center mt-5"><img src="/images/listPreloader.gif"alt="loader"
                                    style={{width: '10%'}}/></div>:isSuccess ? <div className="invoice-card">
                <div className="text-right mb-2">
                    <ReactToPrint trigger={() =>
                        <button className="btn pharmacy-info-btn">Print</button>}
                                  content={() => componentRef.current}/>
                </div>
                <Card className="p-3">
                    <div ref={componentRef}>
                        <table className="table table-borderless table-sm">
                            <tr>
                                <td>
                                    <span className="label label-success-outline m-r-15 p-1 mb-2 "
                                          style={{
                                              border: "1px solid #389081",
                                              color: "#389081",
                                          }}> Billing From</span><br/>

                                        <CompanyAddress align='left'/>

                                </td>
                                <td>
                                    {address}
                                    <span className="label label-success-outline mb-2 p-1 m-r-15"
                                          style={{
                                              border: "1px solid #389081",
                                              color: "#389081",
                                              display: 'inline-block'
                                          }}> Billing To</span>
                                    <p style={{margin: 0}}>{name}</p>
                                    <p style={{margin: 0}}>{user_address ? splitText(user_address) : ''}</p>
                                    <p style={{margin: 0}}>{mobile}</p>
                                </td>
                            </tr>
                        </table>
                        <table className="table table-bordered table-sm mpeke-customer-table ">
                            <thead>
                            <tr>
                                {headData.map((item, index) => (<td key={index}><strong>{item}</strong></td>))}
                            </tr>
                            </thead>
                            <tbody>
                            {medicines.map((info, index) => {
                                return (
                                    <tr key={info.id}>
                                        <td>{info.product_name}</td>
                                        <td>{info.batch_id}</td>
                                        <td>{info.quantity}</td>
                                        <td>{info.discount}</td>
                                        <td>{info.rate}</td>
                                        <td>{info.amount}</td>
                                    </tr>
                                )
                            })}
                            <tr>
                                <td colSpan={headData.length-1} align="right">Sub Total</td>
                                <td>{total_amount}</td>
                            </tr>
                            </tbody>
                        </table>
                        {totalSummary}
                        <table className="table-sm table table-borderless">
                            <tr>
                                <td>
                                    <div style={{
                                        float: "left", width: "40%", textAlign: "center",
                                        marginTop: "100px"
                                    }}>
                                        <p style={{borderBottom: "1px solid #e4e5e7", margin: 0}}>{user_name}</p>
                                        <p>Received By</p>
                                    </div>
                                </td>
                                <td/>
                                <td>
                                    <div style={{
                                        float: "right", width: "40%", textAlign: "center",
                                        marginTop: "100px"
                                    }}>
                                        <p style={{borderBottom: "1px solid #e4e5e7", margin: 0}}>{user_name}</p>
                                        <p>Authorized By</p>
                                    </div>
                                </td>
                            </tr>
                        </table>
                    </div>
                </Card>
            </div>: isError ? <div className="text-center">The server did not return a valid response</div>:null}
        </div>
    );
}

export default MainInvoiceDetails;