import {convertDate,   titleCase} from "../../../Utils/titleCaseFunction";
import {useEffect, useState} from "react";
import axios from "axios";
import config from "../../../Helpers/config.json";
import {errorMessages} from "../../../Helpers/ErrorMessages";
import {useAddCustomer} from "../../Customer/CustomHooks/useAddCustomer";
import { v4 as uuidv4 } from 'uuid';

export const useReusableNewSales = (actions,invoice_number = '',is_cash=false) => {
    const defaultCustomer = is_cash ? {customer_name:'Walk-in Customer', customer_id:'1',} : ''
    const initialState = [{
        product_name: '', batch_id: '', batch_info: [], available_quantity: "", expiry: "", unit: "",
        product_quantity: "", product_rate: "", discount: "", total_price: "", discount_type: 1,related_product:'',
        total_discount: 0, tax: "", dosage: '', product_id: '', showProduct: false, isError:false,   uuid_id: uuidv4(),
    }]
    const [paytype, setPayType] = useState('');
    const [customer, setCustomer] = useState(defaultCustomer);
    const [showCustomer, setShowCustomer] = useState(false);
    const [customers, setCustomers] = useState([])
    const [rows, setRows] = useState(initialState)
    const [drugs, setDrugs] = useState([])
    const [state, setState] = useState({invoice_date: convertDate(), inva_details: ''});
    const [totals, setTotals] = useState({grand_total_price:0,n_total:0, change:0, due_amount:0, paid_amount:'',previous_amount:0,total_discount_amount:0})
    const [customerDetails, setCustomerDetails] = useState({credit_limit:0,isCreditError:false});
    const {customerProps} = useAddCustomer(actions, 'new-customer')
    const [prevProducts, setPrevProducts] = useState([])
    const [prevAmount, setPrevAmount] = useState(0)
    const [amountPaid, setAmountPaid] = useState(0)
    const [paymentType, setPaymentType] = useState('')
    const [isBlocking, setIsBlocking] = useState(false)

    const groupByProductId = (arr=[],product_details=[]) =>{
        let empty = []
        for (let j =0; j <product_details.length; j++){
            for (let i=0; i<arr.length; i++){
                if(product_details[j].product_id === arr[i].product_id){
                    let obj = {...product_details[j], ...arr[i]}
                    empty.push(obj)
                }
            }
        }
        return empty
    }

    // using the purchase_detail_id to compare now instead of batch_id
    const getBatchDetails = (b_id,batch_data=[]) =>{
        const arr = batch_data.filter(item=>item.purchase_detail_id === b_id).map(item=>({...item, expiry: item.expiry_date, available_quantity:item.stock}))
        return arr[0]
    }

    const handleGetCustomer = (event) =>{
        if (!event['innerText']) {
            setCustomer({customer_name:'',customer_id: ''})
            setShowCustomer(false)
            return;
        }
        const {innerText, value} = event;
        const id = value ? value.split('-')[0] : ''
        const limit = value ? value.split('-')[1] : 0
        setCustomer({customer_name: innerText,customer_id: id})
        setCustomerDetails({...customerDetails, credit_limit: parseInt(limit)})
        return axios.get(`${config.epharmUrl}/Cinvoice/outstanding_balance`,
            {params: {customer_id: id}})
    }

    useEffect(()=>{
        if (!invoice_number){
            return
        }
        const storage = JSON.parse(sessionStorage.getItem(invoice_number))
        const item = storage ? storage : {}
        const customer_details = item['customer'] ? item['customer'] : {}
        const product_details = item['products'] ? item['products'] : {}
        const obj = {innerText:customer_details.customer_name, value:`${customer_details.customer_id}-${customer_details.credit_limit}`}
        const amount_paid = customer_details?.amount_paid ? customer_details?.amount_paid : 0
        const pay_type = customer_details?.payment_type ? customer_details?.payment_type : 0

        if(!obj.innerText){
            return;
        }
        handleGetCustomer(obj).then(response => {
            const data = response.data;
            const dt = !data ? {} : data;
            const amount = dt.data ? dt.data : 0
            Promise.all(product_details.map((item)=> {
                return axios.post(`${config.epharmUrl}/Cinvoice/retrieve_product_data_inv`, null,
                    {params: {product_id: item.product_id}}).then((response)=> {
                       return response
                    })
            })).then(function(result) {
                const dt = result ? result : []
                const arr = dt.map(({data})=>data)
                const empty = groupByProductId(arr, product_details)
                const emp = empty.map(item=>{
                    return {
                    ...item,
                    ...getBatchDetails(item.batch_id, item.batch)
                }})

                const resultArr = groupByProductId(arr, emp)
                
                let productIds = resultArr.map(item=>{
                    const total = +item.quantity * +item.rate
                    const disc = item.discount ? item.discount : 0
                    const discount = total * (+disc / 100)
                    const final_total = total - discount

                    // get batch details : batch_id and purchase_detail_id
                    const get_current_batch_details = item.batch.find((ele) => ele.batch_id === item.batch_id);
                    const current_batch_details = {
                        'batch_id' : get_current_batch_details.batch_id??'', 'purchase_detail_id' : get_current_batch_details.purchase_detail_id??''
                    }

                    // const bArr = item?.batch?.map(({batch_id}) => batch_id)
                    return  {...item, product_id:item.product_id, batch_id:current_batch_details, product_quantity:item.quantity,
                        product_rate: item.rate,discount:item.discount, total_price:final_total, batch_info:item.batch,unit:item.unit, total_stock:item.total_product}
                })
                setPrevProducts(productIds)
                setPrevAmount(amount)
                setAmountPaid(amount_paid)
                setPaymentType(pay_type)

            })


        }).catch(error => {
            errorMessages(error, null, actions)
        });

    },[invoice_number])

    useEffect(()=>{
        if(!invoice_number){
            return
        }
        setRows(prevProducts)
        setPayType(paymentType)
        calculateTotalAmounts(prevProducts, amountPaid, prevAmount)

    },[prevAmount, prevProducts, amountPaid, paymentType])

    const handleChangePaymentMethod = (event) => {
        const {value} = event.target
        setPayType(value)
        setIsBlocking(true)
    };

    const handleChangeFormFields = event => {
        const {name, value} = event.target;
        setState({...state, [name]: value});
        setIsBlocking(true)
    };


    const handleChange = (event, index) => {
        const {value, name} = event.target;
        const arr = rows.map((item, idx) => {
            if (idx === index) {
                return {...item, [name]: value}
            }
            return value;
        })
        setRows(arr)
        setIsBlocking(true)
    };

    const calculateTotalAmounts = (arr, p_amount, prev_amount) => {
        const sum = arr.reduce((a, row) => {
            return Number(a + row.total_price);
        }, 0);
        const discount = arr.reduce((a, row) => {
            let total_apparent = +(row.product_rate * row.product_quantity);
            let dis = !row.discount ? 0 : +(row.discount * total_apparent);
            let disc = dis / 100;
            return a + disc;
        }, 0);
        const net_total = sum;//+(sum + prev_amount);
        const amount = +(net_total - p_amount);
        const final_amount = amount > 0 ? amount : 0;
        const change = +(p_amount - net_total);
        const final_change = change > 0 ? change : 0;
        setTotals({...totals,grand_total_price: sum, n_total: +net_total,due_amount: final_amount,change: final_change,
            total_discount_amount: +discount,paid_amount: p_amount, previous_amount: prev_amount})
    };


    const handleChangeQuantity = (event, index) => {
        const {value} = event.target;
        const {paid_amount,previous_amount} = totals
        const arr = rows.map((item, idx)=>{
            const total = +value * item.product_rate
            const discount = total * (+item.discount / 100)
            const final_total = total - discount
            if (index === idx){
                return {...item,product_quantity: value, total_price:final_total, isError: +value > item.available_quantity}
            }
            return item
        })
        setRows(arr)
        calculateTotalAmounts(arr, paid_amount,previous_amount)
        setIsBlocking(true)
    };

    const handleChangeDiscount = (event, index) => {
        const {paid_amount, previous_amount} = totals
        const {value} = event.target;
        const arr = rows.map((item, idx)=>{
            const total = +item.product_quantity * item.product_rate
            const discount = total * (+value / 100)
            const final_total = total - discount
            if (index === idx){
                return {...item,discount: value, total_price:final_total, isError: +value > item.available_quantity}
            }
            return item
        })
        setRows(arr)
        calculateTotalAmounts(arr, paid_amount, previous_amount)
        setIsBlocking(true)
    };

    const handleChangeBalance = (event) => {
        const {previous_amount} = totals
        const {value} = event.target;
        calculateTotalAmounts(rows,value, previous_amount)
        setIsBlocking(true)
    };

    const handleRetrieveCustomerInfo = event => {
       const customer_value = event.target ? event.target.value : event;
        setCustomer({...customer,customer_name: customer_value})
        if (customer_value !== '') {
            axios.get(`${config.epharmUrl}/Cinvoice/customer_autocomplete`, {params: {customer_id: titleCase(customer_value)}}).then(res => {
                const data = res.data;
                const dt = !data ? [] : data;
                const arr = dt.map(item=>({
                    value:`${item.customer_id}-${item.credit_limit}`,
                    label:item.customer_name
                }))
                setCustomers(arr)
            }).catch(error => {
                errorMessages(error, null, actions)
            })
            setShowCustomer(true)
        }else{
            setShowCustomer(false)
        }
        // setIsBlocking(true)

    };



    const handleClickCustomer = event => {
        const {paid_amount} = totals
        if(!event['innerText']){
            return;
        }
        handleGetCustomer(event).then(response => {
            const data = response.data;
            const dt = !data ? {} : data;
            const amount = dt.data ? dt.data : 0
            setTotals({...totals, previous_amount: amount})
            calculateTotalAmounts(rows, paid_amount, amount)
        }).catch(error => {
            errorMessages(error, null, actions)
        });
        setShowCustomer(false)
        // setIsBlocking(true)
    };

    const handleRetrieveDrugs = (eventTxt, index) => {
        let tmp = eventTxt.trim();
        if (tmp.includes('(')) {
            tmp = tmp.substring(0, tmp.indexOf('('));
        }
        const arr = rows.map((item, idx)=>{
            if (idx === index){
                if(tmp !== ''){
                    axios.get(`${config.epharmUrl}/Cinvoice/autocompleteproductsearch`, {params: {product_name: titleCase(tmp)}}).then(res => {
                        const data = res.data;
                        const dt = !data ? [] : data;
                        setDrugs(dt)
                    }).catch(error => {
                        errorMessages(error, null, actions)
                    })
                    return {...item,product_name: tmp, showProduct: true}
                }else{
                    return {...item, product_name:tmp, showProduct: false,batch_id: '', batch_info: [],
                        available_quantity: 0, product_rate: 0, product_quantity: '', expiry: '', unit: '', total_price: 0, discount: ''}
                }

            }
            return item
        })
        setRows(arr)
        setIsBlocking(true)
    };

    const handleClickDrug = (event, index) => {
        const {value, innerText} = event;
        if (value === '' || innerText === '') {return;}
        let arr = rows.map((item, idx)=>{
            if(idx === index){
                return {...item, showProduct: false, product_name: innerText, product_id: value}
            }
            return item
        })
        axios.post(`${config.epharmUrl}/Cinvoice/retrieve_product_data_inv`, null,
            {params: {product_id: value}}).then(response => {
            const data = response.data;
            const dt = !data ? {} : data;
            const batchArr = dt.batch ? dt.batch : [];
            if(batchArr.length > 0){
                const batches = batchArr.length > 0 ?  batchArr.map(({batch_id, purchase_detail_id}) => {return {purchase_detail_id, batch_id}}) : [];
                const batch = batches[0].purchase_detail_id ? batches[0].purchase_detail_id : ''
                arr = arr.map((item, idx)=>{
                    const batchObj = getBatchDetails(batch, batchArr)
                    const exp_date = batchObj.expiry ? batchObj.expiry : ''
                    const daysLeft = Math.floor((Date.parse(exp_date) - Date.now()) / (24 * 60 * 60 * 1000));
                    if(idx === index){
                        if(daysLeft < 0){
                            return {...item, available_quantity: '', expiry: '', expired:true, unit:dt.unit ? dt.unit : '',  batch_id: '', dosage: '',
                                total_stock: dt.total_product ? dt.total_product : 0,batch_info:batchArr,product_rate:dt.price}
                        }else {
                            return {...item, ...getBatchDetails(batch, batchArr), expired:false, batch_info:batchArr,  unit:dt.unit ? dt.unit : '',
                                total_stock: dt.total_product ? dt.total_product : 0, batch_id:batches[0], product_rate:dt.price}
                        }

                    }
                    return item
                })
                const isExpired = arr.every(item=>item.expired)
                if(isExpired){
                    actions.snackbarActions.infoSnackbar('Batch Expired, Please Choose another')
                }
                setRows(arr);
            }else {
                actions.snackbarActions.infoSnackbar('This product has no batches. Please choose another')
            }

        }).catch(error => {
            errorMessages(error, null, actions)
        });
        setIsBlocking(true)
    };

    // batch_value now holds the purchase_detail_id instead of batch_id
    const handleChangeBatch = (event, index) => {
        const batch_value = event.target ? event.target.value : event
        const arr = rows.map((item, idx)=>{
            const batchObj = getBatchDetails(batch_value, item.batch_info);
            const obj = batchObj ? batchObj : {};
            const exp_date = obj.expiry ? obj.expiry : '';
            const daysLeft = Math.floor((Date.parse(exp_date) - Date.now()) / (24 * 60 * 60 * 1000));
            if(idx === index){
                if(daysLeft < 0){
                    return {...item, available_quantity: '', expiry: '', expired:true,  batch_id: '', dosage: ''}
                } else {
                    return {...item, ...getBatchDetails(batch_value, item.batch_info), batch_id:obj, expired:false}
                }
            }
            return item
        })
        const isExpired = arr.every(item=>item.expired)
        if(isExpired){
            actions.snackbarActions.infoSnackbar('Batch Expired, Please Choose another')
        }
        setRows(arr)
        setIsBlocking(true)

    };

    const handleAddRow = () => {
        const item = {
            product_name: '', batch_id: '', batch_info: [], available_quantity: "", expiry: "",
            unit: "", product_quantity: "", product_rate: "", discount: 0, total_price: "", discount_type: 1,
            total_discount: 0, product_id: '', tax: 0, dosage: '', showProduct: false,   uuid_id: uuidv4(),
        };
        setRows([...rows, item])
        setIsBlocking(true)
    };

    const handleRemoveSpecificRow = (idx) => {
        const {paid_amount, previous_amount} = totals
        const arr = rows.filter((_, index) => index !== idx)
        setRows(arr)
        calculateTotalAmounts(arr, paid_amount, previous_amount)
        setIsBlocking(true)
    };




    const stateVariables = {initialState, paytype,
        customer, showCustomer,   rows,  state,customerProps,customerDetails,
        totals, customers, drugs, handleChangeFormFields,handleChangePaymentMethod,
         handleChange,  handleChangeBatch, handleAddRow, handleRemoveSpecificRow,   handleChangeQuantity,
        handleChangeDiscount, handleChangeBalance, handleRetrieveCustomerInfo, handleClickCustomer,
        handleRetrieveDrugs, handleClickDrug,isBlocking
    }

    const stateFunctions = {
        setRows,setCustomer,setTotals,setCustomerDetails, setIsBlocking
    }

    return {stateVariables, stateFunctions}
}