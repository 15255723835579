import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Label from "../../../Utils/FormInputs/Label";
import TextField from "../../../Utils/FormInputs/TextField";
import {CustomSelect} from "../../../Utils/FormInputs/SelectInput";
import React from "react";

function EditSetupForm({value, debit, credit, isPendingEdit, handleChangeCredit,
                           handleChangeDebit, handleEditLedger, item_name, accounts}) {
    return(
        <form autoComplete='off' onSubmit={handleEditLedger}>
            <Form.Group as={Col}>
                <Label name={item_name}/>
                <TextField readOnly value={value}/>
            </Form.Group>
            <Form.Group as={Col}>
                <Label name='Debit Ledger'/>
                <CustomSelect value={debit} options={accounts} onChange={handleChangeDebit}/>
            </Form.Group>
            <Form.Group as={Col}>
                <Label name='Credit Ledger'/>
                <CustomSelect value={credit} options={accounts} onChange={handleChangeCredit}/>
            </Form.Group>
            <button type='submit' disabled={isPendingEdit} className='btn btn-sm pharmacy-btn'>{isPendingEdit ? 'Submitting...' :'Submit'}</button>
        </form>
    )
}

export  {EditSetupForm}