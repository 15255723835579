import * as types from "../actionTypes";
import axios from "axios";
import config from "../../../Helpers/config";
import {history} from "../../../Helpers/history";
import {successSnackbar} from "../SnackbarActions/SnackbarActions";
import {errorMessages} from "../../../Helpers/ErrorMessages";


function category(categoryList) {
    return {
        type: types.MANAGE_CATEGORY,
        categoryList
    }
}

function manageMedicine(medicineList) {
    return {
        type: types.MANAGE_MEDICINE,
        medicineList
    }
}

function categoryForm(category) {
    return {
        type: types.CATEGORY_UPDATE_FORM,
        category
    }

}

function categoryDelete(category_id) {
    return {
        type: types.DELETE_CATEGORY,
        category_id
    }
}

function productDelete(product_id) {
    return {
        type: types.DELETE_PRODUCT,
        product_id
    }
}

function productUpdate(productUpdateDetails) {
    return {
        type: types.PRODUCT_UPDATE_FORM,
        productUpdateDetails
    }

}

function unitList(unitList) {
    return {
        type: types.RETRIEVE_UNIT_LIST,
        unitList
    }

}

function unitForm(unit) {
    return {
        type: types.UNIT_UPDATE_FORM,
        unit
    }

}

function unitDelete(id) {
    return {
        type: types.DELETE_UNIT,
        id
    }

}

function retrieveType(typeList) {
    return {
        type: types.RETRIEVE_MEDICINE_TYPES,
        typeList
    }

}

function deleteType(type_id) {
    return {
        type: types.DELETE_MEDICINE_TYPE,
        type_id
    }

}

function typeUpdateForm(medicineType) {
    return {
        type: types.MEDICINE_TYPE_UPDATE_FORM,
        medicineType
    }

}
function productDetails(productDetails) {
    return{
        type:types.RETRIEVE_PRODUCT_DETAILS,
        productDetails
    }

}
function addMedRequest(){
    return{
        type:types.MED_REQUEST,
    }
}
function addMedError(){
    return{
        type:types.MED_ERROR,
    }
}
function addMedSuccess(){
    return{
        type:types.MED_SUCCESS,
    }
}

function editMedRequest(){
    return {
        type:types.EDITMED_REQUEST,
    }
}
function editMedError(){
    return {
        type:types.EDITMED_ERROR,
    }
}
function editMedSuccess(){
    return {
        type:types.EDITMED_SUCCESS,
    }
}
// function medicinePurchaseData(purchaseData) {
//     return {
//         type:types.MEDICINE_PURCHASE_DATA,
//         purchaseData
//     }
//
// }
// function medicineSalesData(salesData) {
//     return {
//         type:types.MEDICINE_SALES_DATA,
//         salesData
//     }
//
// }
// function totalMedicine(medicineTotal) {
//     return{
//         type:types.TOTAL_MEDICINE_RECORDS,
//         medicineTotal
//     }
// }
// function medicineRequest() {
//     return {type:types.MEDICINE_REQUEST}
//
// }


export function retrieveProductDetails(product_id) {
    return function (dispatch) {
        // dispatch(medicineRequest());
        return axios.get(config.epharmUrl + "/Cproduct/product_details", {params:{product_id}})
            .then(response=>{
            const data = response.data;
            const dt = data ? data : {}
            dispatch(productDetails(dt));
        }).catch(error=>{
            errorMessages(error, dispatch, null)
        })
    }
}

export function updateMedicineType(formData) {
    return function (dispatch) {
        const configuration = {
            headers:{
                'content-Type':'multipart/form-data'
            }
        };
        return axios.post(config.epharmUrl + "/Cproduct/type_update", formData, configuration).then(()=>{
            dispatch(successSnackbar('Update Successful'))
        }).catch(error=>{
            errorMessages(error, dispatch, null)
        })

    }

}

export function medicineTypeForm(type_id) {
    return function (dispatch) {
        return axios.post(config.epharmUrl +  "/Cproduct/type_update_form", null,{params:{type_id}}).then(response => {
            const data = response.data;
            const dt = data ? data : {}
            dispatch(typeUpdateForm(dt));
        }).catch(error => {
            errorMessages(error, dispatch, null)
        })

    }

}

export function deleteMedicineType(type_id) {
    return function (dispatch) {
        return axios.post(config.epharmUrl + "/Cproduct/type_delete", null, {params:{type_id}}).then(() => {
            dispatch(deleteType(type_id));
            dispatch(successSnackbar('Medicine type deleted successfully'));

        }).catch(error => {
            errorMessages(error, dispatch, null)

        })
    };


}

export function retrieveMedicineTypes() {
    return function (dispatch) {
        // dispatch(medicineRequest());
        return axios.get(config.epharmUrl + "/Cproduct/typeindex").then(response => {
            const data = response.data
            const dt = data ? data : {};
            const list = dt.type_list ? dt.type_list : []
            list.reverse();
            dispatch(retrieveType(list));
        }).catch(error => {
            errorMessages(error, dispatch, null)
        })

    }

}

export function addMedicineType(formData) {
    return function (dispatch) {
        const configuration = {
            headers: {
                'content-Type': 'multipart/form-data'
            }
        };
        return axios.post(config.epharmUrl + "/Cproduct/insert_type", formData, configuration).then(() => {
            dispatch(successSnackbar('Medicine type added successfully'))
            dispatch(retrieveMedicineTypes())
        }).catch(error => {
           errorMessages(error, dispatch, null)

        })

    }

}

export function deleteUnit(id) {
    return function (dispatch) {
        return axios.get(config.epharmUrl + `/Cproduct/delete_unit/${id}`).then(() => {
            dispatch(unitDelete(id));
            dispatch(successSnackbar('Unit deleted Successfully'))
        }).catch(error => {
            errorMessages(error, dispatch, null)

        })

    }

}

export function updateUnit(formData) {
    return function (dispatch) {
        const configuration = {
            headers: {
                'content-Type': 'multipart/form-data'
            }
        };
        return axios.post(config.epharmUrl + "/Cproduct/unit_form", formData, configuration).then(() => {
            dispatch(successSnackbar('Unit Update Successful'));
            setTimeout(()=>{
                history.push('/unitlist')
            },1000);
        }).catch(error => {
           errorMessages(error, dispatch, null)

        })
    }

}

export function unitUpdateForm(formData) {
    return function (dispatch) {
        const configuration = {
            headers:{
                'content-Type':'multipart/form-data'
            }
        };
        return axios.post(config.epharmUrl + "/Cproduct/unit_form", formData,configuration).then(response => {
            const data = response.data;
            const dt = data ? data : {};
            const unit = dt.unit ? dt.unit : {}
            dispatch(unitForm(unit));
        }).catch(error => {
            errorMessages(error, dispatch, null)
        })

    }

}

export function retrieveUnitList() {
    return function (dispatch) {
        return axios.get(config.epharmUrl + "/Cproduct/unit_list").then(response => {
            const data = response.data;
            const dt = data ? data : {};
            const list = dt.unit ? dt.unit :[];
            dispatch(unitList(list))
        }).catch(error => {
            errorMessages(error,dispatch, null)
        })

    }

}

export function addUnit(formData) {
    return function (dispatch) {
        return axios.post(config.epharmUrl + "/Cproduct/unit_form", formData).then(() => {
            dispatch(successSnackbar('Unit saved successfully'));
            // retrieveUnitList()
        }).catch(error => {
            errorMessages(error, dispatch, null)
        })


    }

}

export function updateProduct(formData) {
    return function (dispatch) {
        dispatch(editMedRequest());
        const configuration = {
            headers: {
                'content-Type': 'multpart/form-data'
            }
        };
        return axios.post(config.epharmUrl + "/Cproduct/product_update", formData, configuration).then(response => {
            // const data = response.data;
            dispatch(editMedSuccess());
            dispatch(successSnackbar('Product updated succcessfully'));
            setTimeout(()=>{
                history.push('/managemedicine')
            },2000)

        }).catch(error => {
            dispatch(editMedError());
            errorMessages(error,dispatch,null)

        })

    }

}

export function productUpdateForm(product_id) {
    return function (dispatch) {
        return axios.get(config.epharmUrl + "/Cproduct/product_update_form", {params: {product_id}}).then(response => {
            const data = response.data;
            const dt = data ? data : {}
            dispatch(productUpdate(dt))
        }).catch(error => {
            errorMessages(error, dispatch, null)
        })

    }

}

export function deleteProduct(product_id) {
    return function (dispatch) {
        return axios.get(config.epharmUrl + `/Cproduct/product_delete/${product_id}`).then(() => {
            dispatch(productDelete(product_id));
            dispatch(successSnackbar('Product Successfully deleted'));
            dispatch(retrieveCategoryList())
        }).catch(error => {
            errorMessages(error, dispatch, null)

        })

    }

}

export function categoryUpdateForm(category_id) {
    return function (dispatch) {
        return axios.post(config.epharmUrl + "/Ccategory/category_update_form", null, {params: {category_id}}).then(response => {
            const data = response.data;
            const dt = data ? data :{}
            dispatch(categoryForm(dt))
        }).catch(error => {
           errorMessages(error, dispatch, null)
        })

    }

}

export function deleteCategory(category_id) {
    return function (dispatch) {
        return axios.post(config.epharmUrl + "/Ccategory/category_delete", null, {params: {category_id}}).then(response => {
            // const data = response.data;
            dispatch(categoryDelete(category_id));
            dispatch(successSnackbar('Category Deleted Successfully'))
        }).catch(error => {
            errorMessages(error, dispatch, null)
        })

    }

}

export function updateCategory(formData) {
    return function (dispatch) {
        const configuration = {
            headers: {
                'content-Type': 'multipart/form-data'
            }
        };
        return axios.post(config.epharmUrl + "/Ccategory/category_update", formData, configuration).then(response => {
            // const data = response.data;
            dispatch(successSnackbar('Category Updated Successfully'));
            setTimeout(() => {
                history.push("/managecategory")
            }, 1000)
        }).catch(error => {
           errorMessages(error, dispatch, null)
        })
    }

}

export function retrieveMedicineList(formData) {
    return function (dispatch) {
        const configuration = {
            headers: {
                'content-Type': 'multipart/form-data'
            }
        };
        return axios.post(config.epharmUrl + "/Cproduct/CheckProductList", formData, configuration).then(response => {
            const data = response.data;
            const dt = data ? data :{};
            const list = dt.aaData ? dt.aaData : []
            list.reverse();
            dispatch(manageMedicine(list));
        }).catch(error => {
            errorMessages(error, dispatch, null)
        })
    }

}

export function retrieveCategoryList() {
    return function (dispatch) {
        // dispatch(medicineRequest());
        return axios.get(config.epharmUrl + "/Ccategory/manage_category").then(res => {
            const data = res.data;
            const dt = data ? data : {};
            const list = dt.category_list ? dt.category_list : []
            dispatch(category(list))
        }).catch(error => {
            errorMessages(error, dispatch,null)
        })

    }

}

export function insertMedicineCategory(formData) {
    return function (dispatch) {
        const configuration = {
            headers: {
                "content-Type": "multipart/form-data"
            }
        };
        return axios.post(config.epharmUrl + "/Ccategory/insert_category", formData, configuration).then((res) => {
            dispatch(successSnackbar('Category Saved Successfully'));
            setTimeout(() => {
                history.push("/managecategory")
            }, 1000)
        }).catch(error => {
            errorMessages(error, dispatch, null)

        })
    }
}

export function insertMedicine(formData) {
    return function (dispatch) {
        const configuration = {
            headers: {
                "content-Type": "multipart/form-data"
            }
        };
        dispatch(addMedRequest());
        return axios.post(config.epharmUrl + "/Cproduct/insert_product", formData, configuration).then((res) => {
            dispatch(addMedSuccess());
            dispatch(successSnackbar('Medicine Saved Successfully'));
            setTimeout(() => {
                history.push("/managemedicine")
            }, 1000)
        }).catch(error => {
            dispatch(addMedError());
            errorMessages(error, dispatch, null)

        })
    }
}