import {useCallback, useEffect, useRef, useState} from "react";
import axios from "axios";
import config from "../../../Helpers/config.json";
import {errorMessages} from "../../../Helpers/ErrorMessages";
import {exportPDF} from "../../../Utils/exportPDF";
import {copyToClipboard} from "../../../Utils/copyToClipboard";
import {useAddCustomer} from "./useAddCustomer";
import {useFileUploader} from "./useFileUploader";


export const useManageCustomer = (actions, component) => {
    const storage = JSON.parse(sessionStorage.getItem('total_count'))
    const item_count = storage ? storage : 0
    const count = () => +item_count || 0
    const [openDialog, setOpenDialog] = useState(false);
    const [customer_id, setCustomerId] = useState('');
    const [customer_count, setCustomerCount] = useState(count);
    const [activePage, setActivePage] = useState(1);
    const [customers, setCustomers] = useState([]);
    const [loading, setLoading] = useState('idle');
    const [searchValue, setSearchValue] = useState('');
    const [openDeactivate, setOpenDeactivate] = useState(false);
    const [openDeposit, setOpenDeposit] = useState(false);
    const [status, setStatus] = useState('');
    const [deposit_id, setDepositId] = useState('');
    const [deposit_amount, setDepositAmount] = useState('');
    const [paymentType, setPaymentType] = useState('1');
    const [submitted, setSubmitted] = useState(false);
    const [isSubmitted,setIsSubmitted] = useState('idle')
    const [isDeactivate, setIsDeactivate] = useState('idle')
    const {customerProps, isResolved, providerProps} = useAddCustomer(actions )
    const [customer_category, setCategory] = useState('')
    const obj = {url:'Ccustomer/uploadCsv_Customer', download_url:'Ccustomer/customer_excel', file_name:'Customers'}

    const {uploadProps, isResolved:isResolvedUpload} = useFileUploader(actions, obj)


    const csvLink = useRef();


    const isDeactivateSuccess = isDeactivate === 'resolved';

    const customer_status = {
        manage_customer:1,
        deactivated_customers: 0,
    }

    const retrieveCustomers = useCallback(()=>{
        setLoading('loading')
        axios.get(`${config.epharmUrl}/Ccustomer/customers`, {params:{page_number:activePage, status:customer_status[component], search:searchValue, filter_by: customer_category.value}}).then(response => {
            const customers = response.data;
            const dt = !customers ? {} : customers;
            const arr = !dt.data ? {} : dt.data;
            const data = arr.customers ? arr.customers : [];
            const customer_count = arr.total_records ? arr.total_records : 0;
            if(activePage === 1){
                sessionStorage.setItem('total_count', JSON.stringify(customer_count))
                setCustomerCount(customer_count);
            }else {
                setCustomerCount(count);
            }
            setCustomers(data);
            setLoading('success');
        }).catch(error => {
            errorMessages(error, null, actions)
            setLoading('error')
        })
    },[activePage,isDeactivateSuccess,  isResolved, customer_category, isResolvedUpload, component, searchValue])

    useEffect(() => {
        retrieveCustomers()
    }, [retrieveCustomers])

    useEffect(()=>{
        setActivePage(1)
        setSearchValue('')
    },[component])


    const handleChange = (event) => {
        setSearchValue(event.target.value)
        // if(event.target.value !== ''){
        //     setLoading('loading')
        //     axios.get(`${config.epharmUrl}/Ccustomer/customer_list_search`, {params:{search:event.target.value}}).then(response => {
        //         const customers = response.data;
        //         const dt = !customers ? [] : customers;
        //         setCustomers(dt);
        //         setLoading('success');
        //     }).catch(error => {
        //         errorMessages(error, null, actions)
        //         setLoading('error')
        //     })
        // }else {
        //     retrieveCustomers()
        // }

    };

    const handleChangeCategory = (value) =>{
        setCategory(value);
    }

    const handleOpenDialog = (_id) => {
        setCustomerId(_id)
        setOpenDialog(true)
    };
    const handleCloseDialog = () => {
        setOpenDialog(false)
    };

    const downloadPDF = () => {
        const allCustomers = !customers ? [] : customers;
        exportPDF("All Customers", [["SL", "Customer Name", "Address", "Mobile", "Balance"]],
            allCustomers.map((customer, index) => [index += 1, customer.customer_name, customer.address, customer.mobile,
                customer.balance]), "Customers.pdf")
    };

    const copyTable = (componentRef) => {
        copyToClipboard(actions, 10, componentRef)
    };

    const handleCloseBar = () => {
        actions.snackbarActions.hideSnackBar();
    };

    const fetchData = () => {
        csvLink.current.link.click()
    };

    const handleOpenDeactivate = (_id, st) => {
        setCustomerId(_id)
        setStatus(st)
        setOpenDeactivate(true)
    };
    const handleCloseDeactivate = () => {
        setOpenDeactivate(false)
    };

    const deactivateCustomer = () => {
        const stat = status === 1 ? 0 : 1
        setIsDeactivate('pending')
        axios.post(`${config.epharmUrl}/Ccustomer/update_customer_status`, null,
            {params: {customer_id, status:stat}})
            .then(() => {
                actions.snackbarActions.successSnackbar('Customer status update successful');
                setOpenDeactivate(false)
                setCustomerId(customer_id);
                setStatus(stat)
                setIsDeactivate('resolved')
            }).catch(error => {
            errorMessages(error, null, actions)
            setIsDeactivate('rejected')
        })
    };

    const handlePageChange = (pageNumber) => {
        setActivePage(pageNumber)
    };

    const handleOpenDeposit = (customer_deposit_id) => {
        setDepositId(customer_deposit_id);
        setOpenDeposit(true);
    };
    const handleCloseDeposit = () => {
        setOpenDeposit(false)
    };
    const handleChangeDeposit = (event) => {
        setDepositAmount(event.target.value)
    };

    const handleSubmitDeposit = (event) => {
        event.preventDefault();
        const formData = new FormData();
        formData.append('customer_id', deposit_id);
        formData.append('deposit', deposit_amount);
        formData.append('paytype', paymentType);
        setSubmitted(true)
        if (deposit_amount){
            setIsSubmitted('pending')
            axios.post(`${config.epharmUrl}/Ccustomer/customer_deposit`, formData).then(() => {
                actions.snackbarActions.successSnackbar('Amount Deposited Successfully');
                setOpenDeposit(false)
                setIsSubmitted('resolved')
            }).catch(err => {
                errorMessages(err, null, actions);
                setIsSubmitted('rejected')
            })
        }
    };

    const isLoading = loading === 'loading'
    const isSuccess = loading === 'success'
    const isError = loading === 'error'

    return {openDialog, customer_id, customer_count, activePage, customers, loading, searchValue, openDeactivate,
        openDeposit, status, deposit_id, deposit_amount, paymentType, submitted, isSubmitted, csvLink,
        handleChange, handleOpenDialog, handleCloseDialog, downloadPDF, copyTable, handleCloseBar,
        fetchData, handleOpenDeactivate, handleCloseDeactivate, deactivateCustomer, handlePageChange,
        handleOpenDeposit, handleCloseDeposit, handleChangeDeposit, handleSubmitDeposit, customerProps,
    providerProps, customer_category, handleChangeCategory,isLoading, isSuccess, isError, uploadProps}
}