import React from 'react';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import TextField from '../FormInputs/TextField';
import Label from '../FormInputs/Label';
import {ErrorMessage} from "../styledComponents";
import * as mq from "../../styles/media-queries"
import styled from '@emotion/styled/macro'



const StyledForm  = styled.form({
    [mq.extraLarge]:{
        width:'80%'
    }
})

function DateTimeFilter({start_date,end_date, handleChange, submitted,handleSubmit,isPending,  children}) {
    return(
        <StyledForm onSubmit={handleSubmit}>
            <div className='row'>
                {children}
                <div className='col-lg-4'>
                    <Form.Group as={Row}>
                        <Label name='Start' sm={4} column type/>
                        <Col sm={7}>
                            <TextField type='date' name='start_date' value={start_date} onChange={handleChange}/>
                            {submitted && !start_date ? <ErrorMessage>This is a required field</ErrorMessage>: null}
                        </Col>
                    </Form.Group>
                </div>
                <div className='col-lg-4'>
                    <Form.Group as={Row}>
                        <Label name='End' sm={4} column type/>
                        <Col sm={6}>
                            <TextField type='date'  name='end_date' value={end_date} onChange={handleChange}/>
                            {end_date < start_date ? <ErrorMessage>End date shouldn't be less than start date</ErrorMessage> :   submitted && !end_date ? <ErrorMessage>This is a required field</ErrorMessage>: null}
                        </Col>
                        <Col sm={2}>
                            <button type='submit' className='btn btn-sm pharmacy-info-btn' disabled={isPending}>{isPending ? 'Searching...':'Search'}</button>
                        </Col>
                    </Form.Group>
                </div>
            </div>
        </StyledForm>
    ) 
}
export {DateTimeFilter}
