import {useEffect, useState} from "react";
import {useMuiPagination} from "../../../Containers/Dasboard/DashboardTables";
import axios from "axios";
import config from "../../../Helpers/config.json";
import {errorMessages} from "../../../Helpers/ErrorMessages";
import {useAddMedicineType} from "./useAddType";

export const useMedicineType = (actions) =>{
    const [type_id, setTypeId] = useState('');
    const [openDelete, setOpenDelete] = useState(false);
    const [types, setTypes] = useState([]);
    const [loading, setLoading] = useState(false);
    const {page, rowsPerPage, handleChangeRowsPerPage, handleChangePage} = useMuiPagination();
    const {isResolved, typeProps} = useAddMedicineType(actions)

    useEffect(() => {
        setLoading(true)
        axios.get(`${config.epharmUrl}/Cproduct/typeindex`).then(response => {
            const data = response.data
            const dt = data ? data : {};
            const list = dt.type_list ? dt.type_list : []
            setTypes(list);
            setLoading(false)
        }).catch(error => {
            //errorMessages(error, null, actions)
            setLoading(false);
        })
    }, [isResolved])


    const handleOpenDelete = (_id = '') => {
        setTypeId(_id)
        setOpenDelete(true)
    };

    const handleCloseDelete = () => {
        setOpenDelete(false);
        setTypeId('');
    }

    const deleteMedicineType = () => {
        axios.post(`${config.epharmUrl}/Cproduct/type_delete`, null, {params: {type_id}}).then(() => {
            actions.snackbarActions.successSnackbar('Medicine type deleted successfully');
            const arr = types.filter(item => item.type_id !== type_id)
            setTypes(arr)
            setOpenDelete(false)
        }).catch(error => {
            errorMessages(error, null, actions)
        })
    };


    const handleCloseSnackbar = () => {
        actions.snackbarActions.hideSnackBar()
    };

    return { openDelete, types, typeProps, loading, page, rowsPerPage,
        handleChangeRowsPerPage, handleChangePage,  handleOpenDelete,
        handleCloseDelete, deleteMedicineType, handleCloseSnackbar}
}