import React from 'react';
import {Link} from 'react-router-dom'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { makeStyles } from '@material-ui/core/styles';



const useStyles =  makeStyles((theme)=>({
    root: {
        fontSize:'14px',
    }
}));


const BackButton = ({to, text}) =>{
    const classes  =  useStyles()
    return (
        <Link to={to}>
            <ArrowBackIosIcon classes={{root:classes.root}}/>Back to {text}</Link>
    )
}
export {BackButton}