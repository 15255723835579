import React from 'react';
import PropTypes from 'prop-types';
import SvgIcon from '@material-ui/core/SvgIcon';
import { alpha, makeStyles, withStyles } from '@material-ui/core/styles';
import TreeItem from '@material-ui/lab/TreeItem';
import Collapse from '@material-ui/core/Collapse';
import TableCell from "@material-ui/core/TableCell";

const WINDOW_HEIGHT = window.innerHeight
const height = WINDOW_HEIGHT - 250

{/**
 Referenced from the Material UI v4 TreeView Demo
 */}

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        // maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
        overflow: 'auto',
        // maxHeight: height,
    },
    listItem:{
        paddingTop:0,
        paddingBottom:0,
    },
    sub:{
        paddingLeft: theme.spacing(4),
    },
    nested: {
        paddingLeft: theme.spacing(6),
    },
    nestedLi: {
        paddingLeft: theme.spacing(8),
    },
    nestedChild: {
        paddingLeft: theme.spacing(10),
    },
    parentText:{
        fontSize:'1.2rem',
        fontWeight:600
    },
    childText:{
        fontSize:'1rem',
    },
    smallText:{
        fontSize:'0.9rem'
    },
    tableCell:{
        border:0,
        padding:'10px',
        '':'1px solid rgb(181 177 177)'
    },
    coaCell:{
        borderBottom:'1px solid rgb(181 177 177)',
        padding:'10px 0 10px 10px',
        width:'100%'
    },
    table:{
        border:'1px solid rgb(223 219 219)',
        padding: '10px',
        borderRadius: '10px'
    },
    parentCell:{
        background:'rgb(243 243 243)'
    },
    container:{
        maxHeight: height,
    },
    iconContainer: {
        '& .close': {
            opacity: 0.3,
        },
    },
    group: {
        marginLeft: 7,
        paddingLeft: 18,
        borderLeft: `1px dashed ${alpha(theme.palette.text.primary, 0.4)}`,
    },
}));


function MinusSquare(props) {
    return (
        <SvgIcon fontSize="inherit" style={{ width: 14, height: 14 }} {...props}>
            {/* tslint:disable-next-line: max-line-length */}
            <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 11.023h-11.826q-.375 0-.669.281t-.294.682v0q0 .401.294 .682t.669.281h11.826q.375 0 .669-.281t.294-.682v0q0-.401-.294-.682t-.669-.281z" />
        </SvgIcon>
    );
}

function PlusSquare(props) {
    return (
        <SvgIcon fontSize="inherit" style={{ width: 14, height: 14 }} {...props}>
            {/* tslint:disable-next-line: max-line-length */}
            <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 12.977h-4.923v4.896q0 .401-.281.682t-.682.281v0q-.375 0-.669-.281t-.294-.682v-4.896h-4.923q-.401 0-.682-.294t-.281-.669v0q0-.401.281-.682t.682-.281h4.923v-4.896q0-.401.294-.682t.669-.281v0q.401 0 .682.281t.281.682v4.896h4.923q.401 0 .682.281t.281.682v0q0 .375-.281.669t-.682.294z" />
        </SvgIcon>
    );
}

function TransitionComponent(props) {
    return (
        <Collapse {...props} />
    );
}

TransitionComponent.propTypes = {
    /**
     * Show the component; triggers the enter or exit states
     */
    in: PropTypes.bool,
};

// const StyledTreeItem = withStyles((theme) => ({
//     iconContainer: {
//         '& .close': {
//             opacity: 0.3,
//         },
//     },
//     group: {
//         marginLeft: 7,
//         paddingLeft: 18,
//         borderLeft: `1px dashed ${alpha(theme.palette.text.primary, 0.4)}`,
//     },
// }))((props) => {});

const StyledTreeItem = (props) =>{
    const classes = useStyles()
    return (
        <TreeItem classes={{iconContainer:classes.iconContainer, group:classes.group}}
                  {...props}
                  // collapseIcon={<PlusSquare/>}
                  // expandIcon={<MinusSquare/>}
                  TransitionComponent={TransitionComponent} />
    )
}


const COATableCustomCell = ({children, ...props}) =>{
    const classes = useStyles();
    return(
        <TableCell className={classes.coaCell} {...props}>{children}</TableCell>
    )
}

export {useStyles,MinusSquare, PlusSquare, StyledTreeItem, TransitionComponent, COATableCustomCell}


