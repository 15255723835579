import React from "react";
import Form from "react-bootstrap/Form";


const Textarea = ({name,value,onChange,submitted=false, ...props}) =>{
    const isSubmitted = submitted && !value;
    return(
        <Form.Control style={{border: isSubmitted ? '1px solid red':''}}  as="textarea"
                      name={name} value={value} onChange={onChange}  className='form__control' {...props}/>
    )
};
export default Textarea;
