import React from 'react';
import TreeView from '@material-ui/lab/TreeView';
import TreeItem from '@material-ui/lab/TreeItem';
import { formatAmount } from '../../../Utils/formatNumbers';
import { history } from '../../../Helpers/history';
import TableRow from "@material-ui/core/TableRow";
import Table  from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import {useStyles,MinusSquare, PlusSquare, StyledTreeItem,  COATableCustomCell} from "../../../Utils/AccountingUtils/TreeViewComponents"
import {LoadingGif} from "../../../Utils/Loader";


const TreeLabel = ({str_type, nav, padding, isParent}) =>{
    const isStock = nav?.is_stock
    if (str_type === 'final_level') {
        return (
            <TableRow>
                <COATableCustomCell  width='580' onClick={()=>history.push(`/view_transactions/${nav.id}/${nav.head_code}-${nav.head_name}`)}
                                    style={{paddingLeft:padding,  cursor:'pointer'}}>
                    <span data-testid={`final_level-${nav.head_name}`}  style={{color: '#0065ff',   display:'inline-block'}}>{nav.head_name}</span></COATableCustomCell>
                <COATableCustomCell align='right'><strong>{ nav.balance ? formatAmount( nav.balance) : nav.balance }</strong></COATableCustomCell>
            </TableRow>
        )
    } else if (str_type === 'current_parent') {
        return (
            <TableRow>
                <COATableCustomCell  
                onClick={()=>{
                    if (isStock){
                        history.push(`/view_stock_transactions/${nav.id}/${nav.head_code}-${nav.head_name}/${isStock}`)
                    }
                }} style={{paddingLeft:padding,fontWeight : isParent ? 'bold':'', background:isParent ?  'rgb(243 243 243)':'',
                 color: isStock ? '#0065ff':'',cursor:'pointer'}}>
                    <span data-testid={`current_parent-${nav.head_name}`} style={{fontWeight : isParent ? 'bold':''}}>{nav.head_name}</span>
                </COATableCustomCell>
                <COATableCustomCell style={{background:isParent ?  'rgb(243 243 243)':''}}   align='right'><strong>{ nav.balance ? formatAmount(nav.balance) : nav.balance }</strong></COATableCustomCell>
            </TableRow>
        )
    } else {
        return (
            <TableRow>
                <COATableCustomCell data-testid={`parent-${nav.head_name}`} style={{paddingLeft:padding}} width='580'>{nav.head_name}</COATableCustomCell>
                <COATableCustomCell style={{ background:isParent ?  'rgb(243 243 243)':''}} align='right'><strong>{
                    nav.balance ? formatAmount(nav.balance) : nav.balance }</strong></COATableCustomCell>
            </TableRow>
        )
    }

}

export default function COATable({children,clickCategory, data}) {
    const classes = useStyles();

    const renderTree = children => {
        return children.map(nav => {
            const head_level = nav.head_level ? nav.head_level : 0;
            const padding  = head_level === 0 ? 4 :  head_level * 8;
            const category = nav.related_category ? nav.related_category : null;
            const isParent = !category;
            const isheadLevel = head_level >= 2;

            const obj = data[`${nav.id}_${nav.head_name}_${nav.is_category}`] ? data[`${nav.id}_${nav.head_name}_${nav.is_category}`] : {}


            const nav_children = obj.children ? obj.children : []
            const isLoading = obj['loading'] === 'loading'
            const isSuccess = obj['loading'] === 'success'
            const isError = obj['loading'] === 'error'
            const isUndefined = obj['loading'] === undefined

            const str_type = nav.is_final_level ? 'final_level' : 'current_parent'
            const childrenNodes =
                nav_children && nav_children.length > 0
                    ? renderTree(nav_children)
                    : [<div />];

            const label = (
                <>
                    {isUndefined && <TreeLabel {...{str_type, nav, isheadLevel, padding, isParent}}/> }
                    {isLoading ? <TableRow>
                        <COATableCustomCell>
                            <LoadingGif/>
                        </COATableCustomCell>
                    </TableRow>: null}
                    {isSuccess ? <TreeLabel {...{str_type, nav, isheadLevel, padding, isParent}}/> :  null }
                    {isError ? <TableRow>
                        <COATableCustomCell>
                            The server did not a valid response
                        </COATableCustomCell>
                    </TableRow>: null}
                </>
            )
            const isTrue = (!nav.is_final_level && nav.is_stock !== 1) || (nav.is_stock === undefined)
            if (isTrue){
                return (
                    <StyledTreeItem  key={`${nav.id}_${nav.head_name}_${nav.is_category}`}
                              nodeId={`${nav.id}_${nav.head_name}_${nav.is_category}`} label={label}>
                        {childrenNodes}
                    </StyledTreeItem>
                );
            }else {
                return <TreeLabel {...{str_type, nav, isheadLevel, padding, isParent}}/>
            }
        });
    };


    const table_head = (
        <TableRow>
            <COATableCustomCell><strong>Account</strong></COATableCustomCell>
            <COATableCustomCell align='right'><strong>Running Balances</strong></COATableCustomCell>
        </TableRow>
    )

    return (
        <Table className={classes.table} stickyHeader aria-label="sticky table">
            <TableHead>
                <StyledTreeItem label={table_head} nodeId={-1}/>
            </TableHead>
            <TableBody>
                <TreeView
                    className={classes.root}
                    defaultCollapseIcon={<MinusSquare />}
                    defaultExpandIcon={<PlusSquare />}
                    onNodeToggle={clickCategory}
                >
                    {renderTree(data.root)}
                </TreeView>
                {children}
            </TableBody>
        </Table>
    );
}
