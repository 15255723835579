import  {useEffect, useState} from "react";
import axios from "axios";
import config from "../../../Helpers/config.json";
import {errorMessages} from "../../../Helpers/ErrorMessages";
import {useAddPaymentTypes} from "./useAddPaymentTypes";

export const usePaymentTypes = (actions) => {
    const [paymentTypes, setPaymentTypes] = useState([]);
    const [loading, setLoading] = useState('idle');
    const [searchValue, setSearchValue] = useState('');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const {paymentProps, isResolved} = useAddPaymentTypes(actions)


    useEffect(() => {
        setLoading('loading')
        axios.get(`${config.epharmUrl}/Cinvoice/payment_types`).then(res => {
            const data = res.data;
            const dt = !data.data ? [] : data.data;
            setPaymentTypes(dt);
            setLoading('success');
        }).catch(error => {
            errorMessages(error, null, actions)
            setLoading('error')
        })
    }, [isResolved])


    const handleSearch = (searchValue) => {
        setSearchValue(searchValue)
    };


    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };



    const handleCloseBar = () => {
        actions.snackbarActions.hideSnackBar();
    };




    const isLoading = loading === 'loading';
    const isSuccess = loading === 'success';
    const isError = loading === 'error'

    return { paymentTypes, loading, searchValue, handleSearch,   handleCloseBar,
        paymentProps, isLoading, isSuccess, isError, page, rowsPerPage, handleChangePage,
        handleChangeRowsPerPage
    }
}