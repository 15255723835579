import React from 'react';
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import * as snackbarActions from "../../Redux/Actions/SnackbarActions/SnackbarActions";
import {history} from "../../Helpers/history";
import ReusableDashboardHeader from "../../Containers/Dasboard/ReusableDashboardHeader";
import Card from "@material-ui/core/Card";
import PharmacySnackbar from "../../Utils/Snackbars/SnackbarUtil";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Label from "../../Utils/FormInputs/Label";
import Col from "react-bootstrap/Col";
import TextField from "../../Utils/FormInputs/TextField";
import {dateStringConvert} from "../../Utils/titleCaseFunction";
import InvoicePrintDialog from "../Invoice/InvoicePrintDialog";
import {useReturnItems} from "./CustomHooks/useReturnItems";
import {ManufacturerReturnForm} from "./ManufacturerReturnForm";
import { RightAlignedContainer } from '../../Utils/styledComponents';

const headData = [{name:'Product'},{name:'Batch#'},{name:'Purchase Quantity'},
    {name:'Stock'}, {name:'Return Quantity'}, {name:'Unit Cost'},{name:`Discount(%)`},{name:'Total'},{name:'Check Return'},]


const ManufacturerReturnInvoice  = ({actions, snackbars, match:{params}}) => {
    const {purchase_id} = params
    const {returnProps, openDialog, handleCloseDialog,
        handleOpenReceipt, closeSnackbar} = useReturnItems(actions, purchase_id)
    const {state, totals} = returnProps

    const handleCancel = () =>{
        const {radio} = state;
        const obj = {'0':'/manufreturnlist/supplier', '1':'/wastagereturnlist/wastages', '2':'/damages/damages'}
        history.push(`${obj[+radio]}`)
    }
    const {message, variant, open} = snackbars;
    const {manufacturer_name, date} = state;
    const {total_discount, grand_total_price} = totals;

    const supplierDetails = (
        <div className="row">
            <div className="col-md-5">
                <Form.Group as={Row}>
                    <Label column sm={3} htmlFor='supplierName' name="Supplier Name"/>
                    <Col sm={8}>
                        <TextField type="text" value={manufacturer_name} id='supplierName' readOnly/>
                    </Col>
                </Form.Group>
                <Form.Group as={Row}>
                    <Label column sm={3} htmlFor='date' name='Date'/>
                    <Col sm={8}>
                        <TextField type="text" id='date'
                                   value={dateStringConvert(date)} readOnly/>
                    </Col>
                </Form.Group>
            </div>
            <div className="col-md-5">
                <Form.Group as={Row}>
                    <Label column sm={3} htmlFor='totalDeduction' name="Total Deduction"/>
                    <Col sm={8}>
                        <TextField type="number" value={total_discount} readOnly id='totalDeduction'/>
                    </Col>
                </Form.Group>
                <Form.Group as={Row}>
                    <Label column sm={3} htmlFor='netReturn' name='Net Return'/>
                    <Col sm={8}>
                        <TextField type="number" id='netReturn'
                                   value={grand_total_price} readOnly/>
                    </Col>
                </Form.Group>
            </div>
        </div>
    )


    return (
        <div className='journals'>
            <ReusableDashboardHeader component='Return' heading="Return"
                                     subHeading='Return' link={history.location.pathname}/>
            <Card className="p-3">
                <InvoicePrintDialog openDialog={openDialog} handleCloseDialog={handleCloseDialog}
                                    message="Receipt" text='receipt'>
                    <RightAlignedContainer>
                        <button onClick={handleOpenReceipt} type="button" className="btn pharmacy-info-btn mr-3">Yes</button>
                        <button onClick={handleCancel} type="button" className="btn pharmacy-grey-btn ">No</button>
                    </RightAlignedContainer>  
                </InvoicePrintDialog>
                <PharmacySnackbar open={open} message={message} variant={variant}
                                  handleCloseBar={closeSnackbar}/>
                <ManufacturerReturnForm {...{...returnProps, supplierDetails, headData}}/>

            </Card>
        </div>
    );

}

function mapStateToProps(state) {
    return {
        snackbars:state.snackbars
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            snackbarActions:bindActionCreators(snackbarActions, dispatch)
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ManufacturerReturnInvoice);