import React, {useEffect, useState} from 'react';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {CustomPagination, EnhancedTableHead, MuiPaper, EnhancedTableToolbar} from "../../Utils/Tables/CustomTable";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import {removeTime, titleCase} from "../../Utils/titleCaseFunction";
import ReusableDashboardHeader from "../../Containers/Dasboard/ReusableDashboardHeader";
import {history} from "../../Helpers/history";
import {Container} from "../../Utils/styledComponents"
import {BackButton} from "../../Utils/FormInputs/BackButton";
import {Link} from "react-router-dom";
import axios from "axios";
import config from "../../Helpers/config.json";
import {errorMessages} from "../../Helpers/ErrorMessages";
import {bindActionCreators} from "redux";
import * as snackbarActions from "../../Redux/Actions/SnackbarActions/SnackbarActions";
import {connect} from "react-redux";
import PharmacySnackbar from "../../Utils/Snackbars/SnackbarUtil";
import InputGroup from "react-bootstrap/InputGroup";
import { SelectItem } from '../../Utils/FormInputs/AutoCompleteInput';
import TextField from "../../Utils/FormInputs/TextField";
import Grid from "@material-ui/core/Grid";
import Dropdown from "react-bootstrap/Dropdown";
import Label from "../../Utils/FormInputs/Label"
import {useExcelReports} from "../Invoice/CustomHooks/useExcelReports";

const headData = [
    {id: 'medicine_name', numeric: false, disablePadding: false, label: 'Product Name'},
    {id: 'generic_name', numeric: false, disablePadding: false, label: 'Generic Name'},
    {id: 'strength', numeric: false, disablePadding: false, label: 'Strength'},
    {id: 'batch_id', numeric: false, disablePadding: false, label: 'Batch#'},
    {id: 'expiry_date', numeric: false, disablePadding: false, label: 'Expiry Date'},
    {id: 'stock', numeric: false, disablePadding: false, label: 'Stock'},
    {id: 'amount', numeric: false, disablePadding: false, label: 'Amount'},
];


const AgingReport = ({actions, snackbars}) => {
    const [state, setState] = useState({period:'days', period_value:7})
    const [data, setData] = useState({products:[], loading:'idle', total_count:0})
    const [activePage, setActivePage] = useState(1)
    const [submitted, setSubmitted] = useState(false)
    const [isSubmitted, setIsSubmitted] = useState('idle')
    const [searchValue, setSearchValue] = useState('');
    const {period, period_value} = state;
    const {products, loading, total_count} = data


    useEffect(() => {
        setData({...data,loading: 'loading'})
        const formData = new FormData();
        formData.append("draw", 1);
        formData.append("columns[0][data]", "sl");
        formData.append("columns[0][name]", "");
        formData.append("columns[0][searchable]", "true");
        formData.append("columns[0][orderable]", false);
        formData.append("columns[0][search][value]", "");
        formData.append("columns[0][search][regex]", false);
        formData.append("columns[1][data]", "product_id");
        formData.append("columns[1][name]", "");
        formData.append("columns[1][searchable]", true);
        formData.append("columns[1][orderable]", true);
        formData.append("columns[1][search][value]", "");
        formData.append('columns[1][search][regex]', false);
        formData.append("columns[2][data]", "batch_id");
        formData.append("columns[2][name]", "");
        formData.append("columns[2][searchable]", true);
        formData.append("columns[2][orderable]", false);
        formData.append("columns[2][search][value]", "");
        formData.append("columns[2][search][regex]", false);
        formData.append("columns[3][data]", "expeire_date");
        formData.append('columns[3][name]', "");
        formData.append("columns[3][searchable]", true);
        formData.append("columns[3][orderable]", false);
        formData.append("columns[3][search][value]", "");
        formData.append("columns[3][search][regex]", false);
        formData.append("columns[4][data]", "stock");
        formData.append("columns[4][name]", "");
        formData.append("columns[4][searchable]", true);
        formData.append("columns[4][orderable]", false);
        formData.append("columns[4][search][value]", "");
        formData.append("columns[4][search][regex]", false);
        formData.append("order[0][column]", 2);
        formData.append("order[0][dir]", "asc");
        formData.append("start", activePage);
        formData.append("length", 10);
        formData.append("search[value]", searchValue);
        formData.append("search[regex]", false);
        formData.append("start_date", '')
        formData.append("end_date", '')
        formData.append("aging_type", period);
        formData.append("aging_period", period_value);
        axios.post(`${config.epharmUrl}/Creport/checkExpireList`, formData).then(res => {
            const data = res.data;
            const dt = data ?? {};
            const arr = dt.aaData ?? [];
            const count = dt.iTotalRecords ?? 0;
            setData({products: arr, loading: 'success', total_count: count})
        }).catch(error => {
            errorMessages(error, null, actions)
            setData({...data,loading: 'error'})
        })
    }, [activePage, searchValue])

    const handleChange = (event) => {
        const {name, value} = event.target
        setState({...state,[name]:value});
    };


    const handleChangeSearch = (event) => {
        setSearchValue(event.target.value);
    };


    const handlePageChange = (pageNumber) => {
        setActivePage(pageNumber)
    };

    const handleSubmit = (event) =>{
        event.preventDefault()
        setSubmitted(true)
        if(period_value){
            setData({...data,loading: 'loading'})
            setIsSubmitted('pending')
            const formData = new FormData();
            formData.append("draw", 1);
            formData.append("columns[0][data]", "sl");
            formData.append("columns[0][name]", "");
            formData.append("columns[0][searchable]", "true");
            formData.append("columns[0][orderable]", false);
            formData.append("columns[0][search][value]", "");
            formData.append("columns[0][search][regex]", false);
            formData.append("columns[1][data]", "product_id");
            formData.append("columns[1][name]", "");
            formData.append("columns[1][searchable]", true);
            formData.append("columns[1][orderable]", true);
            formData.append("columns[1][search][value]", "");
            formData.append('columns[1][search][regex]', false);
            formData.append("columns[2][data]", "batch_id");
            formData.append("columns[2][name]", "");
            formData.append("columns[2][searchable]", true);
            formData.append("columns[2][orderable]", false);
            formData.append("columns[2][search][value]", "");
            formData.append("columns[2][search][regex]", false);
            formData.append("columns[3][data]", "expeire_date");
            formData.append('columns[3][name]', "");
            formData.append("columns[3][searchable]", true);
            formData.append("columns[3][orderable]", false);
            formData.append("columns[3][search][value]", "");
            formData.append("columns[3][search][regex]", false);
            formData.append("columns[4][data]", "stock");
            formData.append("columns[4][name]", "");
            formData.append("columns[4][searchable]", true);
            formData.append("columns[4][orderable]", false);
            formData.append("columns[4][search][value]", "");
            formData.append("columns[4][search][regex]", false);
            formData.append("order[0][column]", 2);
            formData.append("order[0][dir]", "asc");
            formData.append("start", activePage);
            formData.append("length", 10);
            formData.append("search[value]", searchValue);
            formData.append("search[regex]", false);
            formData.append("aging_type", period);
            formData.append("aging_period", period_value);
            axios.post(`${config.epharmUrl}/Creport/checkExpireList`, formData).then(res => {
                const data = res.data;
                const dt = data ?? {};
                const arr = dt.aaData ?? [];
                const count = dt.iTotalRecords ?? 0;
                setData({products: arr, loading: 'success', total_count: count})
                setIsSubmitted('resolved')
            }).catch(error => {
                errorMessages(error, null, actions)
                setData({...data,loading: 'error'})
                setIsSubmitted('rejected')
            })
        }

    }

    const currentObj = {file_name:'Aging Report', url:'Creport/expiry_aging_excel_report',params:{aging_type: period, aging_period:period_value,
    start_date:'', end_date:''}}

    const {exportExcel} = useExcelReports(actions, currentObj)

    const handleCloseBar = () => {
        actions.snackbarActions.hideSnackBar()
    };

    const isLoading = loading === 'loading'
    const isSuccess = loading === 'success'
    const isError = loading === 'error'

    const isPending = isSubmitted === 'pending'

    const periods = [{value: 'days', text: 'Days'}, {value: 'week', text: 'Weeks'}]
    const {open, message, variant} = snackbars;

    const text = {
        'days':'Day(s)',
        'week':'Week(s)'
    }
    return (
        <div className='journals'>
            <ReusableDashboardHeader component="Aging Report" heading="Reports"
                                     subHeading="Aging Report" link={history.location.pathname}/>
            <PharmacySnackbar open={open} message={message} variant={variant} handleCloseBar={handleCloseBar}/>
            <Container>
                <div className='mb-4'>
                    <BackButton text='Reports' to='/allreports'/>
                </div>
                <Grid container spacing={0}>
                    <Grid item xs={6} md={8}>
                        <form onKeyDown={(e)=>{if (e.key === 'Enter'){e.preventDefault();}}}>
                            <Form.Group as={Row} className="mb-2">
                                <Col sm={2} lg={2} xl={2} md={2}>
                                  <Label  name='Filter by:' column style={{textAlign:'right'}}/>
                                </Col>
                                <Col sm={4} lg={4} xl={4} md={4}> 
                                    <SelectItem onChange={handleChange}  label_text="label"
                                value={period}   value_text="value" name='period'
                                options={periods} />
                                </Col>
                                <Col sm={3} lg={3} xl={3} md={3}>
                                  
                                    <InputGroup className="mb-0">
                                        <TextField type="number"
                                                   name="period_value"
                                                   value={period_value}
                                                   onChange={handleChange}

                                        />
                                        <InputGroup.Text className='form__input__text' id="period_value">{text[period]}</InputGroup.Text>
                                    </InputGroup>
                                    {submitted && !period_value ?  <div className="invalid-text">Please input {text[period]} </div>: null}
                                </Col>
                                <Col sm={2} lg={2} xl={2} md={2}>
                                    <button disabled={isPending} type="submit" className='btn btn-sm pharmacy-info-btn' onClick={handleSubmit} >{isPending ? 'Filtering....':'Filter'}</button>
                                </Col>
                            </Form.Group>
                        </form>
                    </Grid>
                    <Grid item xs={6} md={2}/>
                    <Grid item xs={6} md={2}>
                        <div className='export-dropdown text-right'>
                            <Dropdown align='start'>
                                <Dropdown.Toggle variant="outline-secondary" id="dropdown-basic">
                                    Export As
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    {/*<Dropdown.Item onClick={()=>{}} style={{fontSize: '.85em'}}>PDF</Dropdown.Item>*/}
                                    <Dropdown.Item onClick={exportExcel}
                                                   style={{fontSize: '.85em'}}>Excel</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    </Grid>
                </Grid>

                <MuiPaper>
                    <EnhancedTableToolbar title='Aging Report'>
                        <Form.Control type="text" value={searchValue ? titleCase(searchValue) : ''}
                                      onChange={handleChangeSearch}
                                      className='form__control'
                                      placeholder="Search" style={{float: 'right'}}/>
                    </EnhancedTableToolbar>
                    <TableContainer>
                        <Table
                            aria-labelledby="tableTitle"
                            size='medium'
                            aria-label="enhanced table"
                        >
                            <EnhancedTableHead headCells={headData}/>
                                <TableBody>
                                    {isLoading ? <TableRow>
                                        <TableCell colSpan={headData.length} align="center"><img src="/images/listPreloader.gif"
                                                                                   alt="loader"
                                                                                   style={{width: '5%'}}/></TableCell>
                                    </TableRow>:null}
                                    {isSuccess ? products.length > 0 ? products.slice(0, 10)
                                        .map((d, index) => {
                                            return (
                                                <TableRow
                                                    hover
                                                    role="checkbox"
                                                    tabIndex={-1}
                                                    key={index}
                                                    className='mui-table-row'>
                                                    <TableCell>
                                                        <Link
                                                            to={{pathname: `/medicinedetails/${d.product_id}`}}
                                                            style={{color: "#09905c"}}>{titleCase(d.product_name)}</Link>
                                                    </TableCell>
                                                    <TableCell>
                                                        {d.generic_name}
                                                    </TableCell>
                                                    <TableCell>
                                                        {d.strength}
                                                    </TableCell>
                                                    <TableCell>
                                                        {d.batch_id}
                                                    </TableCell>
                                                    <TableCell>
                                                        {d.expeire_date ? removeTime(d.expeire_date) : ''}
                                                    </TableCell>
                                                    <TableCell>
                                                        {d.stock}
                                                    </TableCell>
                                                    <TableCell>
                                                        {d.total_cost}
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        }):<TableRow>
                                        <TableCell colSpan={headData.length} align="center">No records found</TableCell>
                                    </TableRow>:null }
                                    {isError ? <TableRow>
                                        <TableCell colSpan={headData.length} align="center">The server did not return a valid response</TableCell>
                                    </TableRow>:null }

                                </TableBody>

                        </Table>
                    </TableContainer>
                    <CustomPagination data={products} records={10} activePage={activePage} total_count={total_count}
                                      handleChangeNextPage={handlePageChange}/>
                </MuiPaper>
            </Container>

        </div>
    )
}

function mapStateToProps(state) {
    return {
        snackbars: state.snackbars
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            snackbarActions: bindActionCreators(snackbarActions, dispatch)
        }
    }

}

export default connect(mapStateToProps, mapDispatchToProps)(AgingReport);
