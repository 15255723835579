import {useEffect, useState} from "react";
import axios from "axios";
import config from "../../../Helpers/config.json";
import {useCloseLpo} from "./useCloseLpo";

export const usePurchaseOrders = (actions, status=false, setId, isResolved) =>{
    const [purchaseOrders, setPurchaseOrders] = useState([]);
    const [totalAmount, setTotalAmount] = useState(0);
    const [loading, setLoading] = useState(false);
    const [value, setValue] = useState('')
    const [search, setSearch] = useState('')
    const {isClosedOrder, closeProps} = useCloseLpo(actions)


    const calculateTotalAmount = (orders) => {
        const sum = orders.reduce((a, purchase) => {
            return +(a + purchase.grand_total);
        }, 0);
        setTotalAmount(sum)
    };

    useEffect(() => {
        setLoading(true)
        axios.get(`${config.epharmUrl}/Cpurchaseorder/retrieve_all_purchase_orders`, {params:{search}}).then(response => {
            const data = response.data;
            const dt = !data ? {} : data;
            const list = !dt.purchaseorder_list ? [] : dt.purchaseorder_list;
            const item = list[0] ? list[0] : {};
            const {local_purchase_order_id, status} = item;
            setPurchaseOrders(list);
            calculateTotalAmount(list);
            setId({lpo_id:local_purchase_order_id, lpo_status:status})
            setLoading(false)
        }).catch(error => {
            // errorMessages(error, null, actions);
            setLoading(false)
        })
    }, [status, isClosedOrder,search, isResolved])

    const handleChangeValue = (e) =>{
        setValue(e.target.value)

    }

    const handleChangeSearchValue = (e) =>{
        setSearch(e.target.value)

    }


    const filteredList = (arr) =>{
        return arr.filter((data) => {
            if (value === '')
                return data
            else if (String(data.status).includes(String(value))) {
                return data
            }
        })
    }

    const handleCloseBar = () => {
        actions.snackbarActions.hideSnackBar();
    };
    return {totalAmount,purchaseOrders,loading,handleCloseBar, value,handleChangeValue,filteredList, search, handleChangeSearchValue,closeProps}
}