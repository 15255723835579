import React from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import {Link} from "react-router-dom";

export function TabPanel({children, value, index, ...other}) {
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box>
                    <Typography>
                        {children}
                    </Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `scrollable-auto-tab-${index}`,
        'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
}

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        width: '100%',
        // backgroundColor: theme.palette.background.paper,
    },
    tabRoot:{
        textDecoration:'none !important',
        textTransform:'capitalize',
        [theme.breakpoints.up('sm')]: {
            minWidth: '130px',
        },
    },
    paper:{
        borderBottom:'1.5px solid #e1e6ef',
        background:'inherit'
    }
}));

export default function ReusableTabs({value, handleChange, tabHeading, children}) {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <AppBar position="static" color="default" elevation={1}>
                <Tabs
                    value={value}
                    onChange={handleChange}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="scrollable"
                    scrollButtons="auto"
                    aria-label="scrollable auto tabs example"

                >
                    {tabHeading.map((item,index)=>(
                        <Tab data-testid={item} label={item} {...a11yProps(index)}  classes={{root:classes.tabRoot}}/>
                    ))}
                </Tabs>
            </AppBar>
            {children}
        </div>
    );
}

export  function ReusableRouteTabs({value,  tabHeading}) {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <AppBar position="static"  className={classes.paper} elevation={0}>
                <Tabs
                    value={value}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="scrollable"
                    scrollButtons="auto"
                    aria-label="scrollable auto tabs example"

                >
                    {tabHeading.map((item,index)=>(
                        <Tab label={item.label} {...a11yProps(index)} component={Link} to={item.path}
                             classes={{root:classes.tabRoot}} value={item.path}/>
                    ))}
                </Tabs>
            </AppBar>
        </div>
    );
}
